import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import { useForm, Controller } from 'react-hook-form';
import Select2 from '../../components/select2/Select2';
import $, { data } from "jquery";

import '../../css/nhomquyen/add.css';
import '../../css/hanmuc/hanmuc.css';
import { PopupType, WPopup } from '../../components/Popup/Popup';
import { PopupSelectCriterial } from '../CongViecCuaToi/Popup';
import ScoringDA from '../../redux/middleware/api/Scoring/ScoringDA';
import { Ultis } from '../../core/utils';
export default function EditTieuchiKoCodinh() {
    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();
    const [data, setData] = useState({});
    const [confirmDel, setconfirmDel] = useState();
    let { id } = useParams();

    useEffect(() => {
        _InitData();
    }, []);
    const _InitData = async () => {
        const res = await ScoringDA.getTieuChiDetails(id);
        if (res != null) {
            setData(res);
        }

    }

    const handleEditList = () => {
        if (data.typeSelect === typeRange) {

        } else {

        }
    }

    const typeRange = "Range";
    const typePopup = "Popup";
    const typeCategory = "Category";

    return (
        <form onSubmit={handleSubmit()} autoComplete='off' className="w-frame w-form wbaseItem-value w-col e14e0b64-a97e-4309-84d4-c901a202b4da" level={2} cateid={128}>
            {
                confirmDel != null &&
                <WPopup
                    type={PopupType.WARNING}
                    title={"Xác nhận xóa giá trị khỏi tiêu chí"}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => {
                        setconfirmDel(null);
                    }}
                    cancelAction={() => {
                        setconfirmDel(null);
                    }}
                    cancelButton={true}
                    cancelTitle='Giữ lại'
                    message={'Bạn có chắc chắn muốn xóa ?'}
                />
            }
            <div className="w-frame wbaseItem-value w-col 55169930-5156-452b-a8d9-559b160d8fd0" level={3} cateid={140}>
                <div className="w-frame wbaseItem-value w-row e4c8db4f-a292-4630-840f-4321cea9a96c" level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col 61c5c3cd-5188-43c8-91b5-1fe7b03e3c39" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 4f044728-0e07-4e2f-aa4b-106a7f97b7af" level={6} cateid={140}>
                            <div className="w-text wbaseItem-value 631d5e3e-5ff1-42bb-9224-6d2189c8b545" level={7} cateid={139}>Chỉnh sửa tiêu chí</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row breadcrumb 86cc162c-67d1-4a5a-a8ef-8761295ca239" level={6} cateid={140}>
                            <button type="button" className="w-button wbaseItem-value w-row 29a214b1-e278-4d04-959e-1b70adc3b2a9" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value ecdef2e8-1693-46bd-99d2-d5bd00346b98" level={8} cateid={139}>Quản trị hệ thống</div>
                                <div className="w-svg wbaseItem-value 22474178-c66e-4f8b-8c41-88a03a8f8a10" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6"></path>
                                    </svg>
                                </div>
                            </button>
                            <button type="button" className="w-button wbaseItem-value w-row 7807c449-be3d-4411-97d2-3e93e42ae5a6" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value 9c18f47a-0aa3-4dad-86d7-e83232c6d711" level={8} cateid={139}>Tiêu chí</div>
                                <div className="w-svg wbaseItem-value 5384ce20-5697-4020-a917-6d5093852462" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6"></path>
                                    </svg>
                                </div>
                            </button>
                            <div className="w-text wbaseItem-value 924a1cb9-ccdd-4314-b624-d40f8f7661b1" level={7} cateid={139}>Chỉnh sửa</div>
                        </div>
                    </div>
                </div>
                <div className="w-frame wbaseItem-value w-col ee2b2d2c-8eee-4daa-b96e-d7fc7b406955" scroll="true" level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col f2bae906-3670-41a0-a977-fb7dc48b1d55" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 57e8967a-18fa-4213-87ea-7467a2618436" level={6} cateid={140}>
                            <div className="w-text wbaseItem-value d7713971-43be-4840-90db-212f89a383b7" level={7} cateid={139}>Thông tin tiêu chí</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 21fab225-9cef-43b4-8b9a-dc025c921424" wrap="wrap" level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col12-lg col12-md col24 col24-sm cea15270-db58-4d63-ac3f-b477c880d9e9" level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df" level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9} cateid={139}>Tên tiêu chí</div>
                                </div>
                                <div className="w-textformfield wbaseItem-value w-row eada5e8e-edbd-443b-9579-71cc44317df2" level={8} cateid={85}>
                                    <div className="wbaseItem-value 0bb5e726-0339-4344-aaf9-91be0c4c0dd0" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input name="name" placeholder="Tên tiêu chí" value={data.name} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col12-lg col12-md col24 col24-sm cea15270-db58-4d63-ac3f-b477c880d9e9" level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df" level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9} cateid={139}>Loại tiêu chí</div>
                                </div>
                                <div className="w-textformfield wbaseItem-value w-row eada5e8e-edbd-443b-9579-71cc44317df2" level={8} cateid={85} >
                                    <div className="wbaseItem-value 0bb5e726-0339-4344-aaf9-91be0c4c0dd0" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input name="type" placeholder="Loại tiêu chí" value="Không cố định" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col12-lg col12-md col24 col24-sm cea15270-db58-4d63-ac3f-b477c880d9e9" level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df" level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9} cateid={139}>Trọng số nhóm tiêu chí</div>
                                </div>
                                <div className="w-textformfield wbaseItem-value w-row eada5e8e-edbd-443b-9579-71cc44317df2" level={8} cateid={85}>
                                    <div className="wbaseItem-value 0bb5e726-0339-4344-aaf9-91be0c4c0dd0" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input name="weight" placeholder="Trọng số nhóm tiêu chí" value={data.weight} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col12-lg col12-md col24 col24-sm cea15270-db58-4d63-ac3f-b477c880d9e9"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df" level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9} cateid={139}>Mô tả</div>

                                </div>
                                <div className="w-textformfield wbaseItem-value w-row eada5e8e-edbd-443b-9579-71cc44317df2" level={8} cateid={85} >
                                    <div className="wbaseItem-value 0bb5e726-0339-4344-aaf9-91be0c4c0dd0" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input name="description" value={data.description} placeholder="Nhập mô tả" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="w-frame wbaseItem-value w-col 54a6b115-6273-45ee-9416-8827186841ec" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row a8c74288-b250-4c25-b92e-8eb7dfa853de" level={6} cateid={140}>
                            <div className="w-text wbaseItem-value a1271bc0-5ace-4f6d-b3c0-5ef9eb6338bc" level={7} cateid={139}>Danh sách</div>
                            <div className="w-frame wbaseItem-value w-row 3cd63587-ea54-451d-adb3-f2c0b3d0faac" level={10} cateid={140}>
                                <button type="button" onClick={handleEditList}
                                    className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                                    level={7} cateid={29}>
                                    <div className="w-svg wbaseItem-value a3e0f6f2-4d22-4977-ab6f-90d0568e534f" level={8}
                                        cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                                fill="#FFFFFFFF" />
                                        </svg>
                                    </div>
                                    <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3" level={8} cateid={139}>{data.typeSelect === typeRange ? "Thêm tiêu chí" : "Sửa danh sách"}</div>
                                </button>
                            </div>
                        </div>
                        <table className="w-table wbaseItem-value e2b5e8db-e084-45fa-afd8-3d00b4b3a809" type={0} name-field="Table" level={6} cateid={117}>
                            <tbody>
                                <tr className="table-row">
                                    <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row ce7c97d2-0955-4769-89b1-cc79f2aa896f" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 13465ac2-6654-4ae4-a081-4adee44c33dc" level={8} cateid={139}>STT</div>
                                        </div>
                                    </td>
                                    {
                                        data.typeSelect === typeRange ? [
                                            <td key="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ce7c97d2-0955-4769-89b1-cc79f2aa896f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 13465ac2-6654-4ae4-a081-4adee44c33dc" level={8} cateid={139}>Từ</div>
                                                </div>
                                            </td>,
                                            <td key="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 76b29adb-18d0-4631-b783-25247da0a59a" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 2af12727-5875-4ab7-94aa-e8453cfc1437" level={8} cateid={139}>Đến</div>
                                                </div>
                                            </td>
                                        ] : <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row ce7c97d2-0955-4769-89b1-cc79f2aa896f" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value 13465ac2-6654-4ae4-a081-4adee44c33dc" level={8} cateid={139}>Tên</div>
                                            </div>
                                        </td>
                                    }
                                    <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row c21901a5-43c1-4b4b-a3aa-847f9feabb13" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 0b7e5333-c385-474a-a021-98228919570d" level={8} cateid={139}>Điểm</div>
                                        </div>
                                    </td>
                                    <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row c21901a5-43c1-4b4b-a3aa-847f9feabb13" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 0b7e5333-c385-474a-a021-98228919570d" level={8} cateid={139}>Trọng số nhóm tiêu chí</div>
                                        </div>
                                    </td>
                                    <td id="1x6" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row c21901a5-43c1-4b4b-a3aa-847f9feabb13" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 0b7e5333-c385-474a-a021-98228919570d" level={8} cateid={139}>Điểm nhóm tiêu chí</div>
                                        </div>
                                    </td>
                                    <td id="1x7" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row c21901a5-43c1-4b4b-a3aa-847f9feabb13" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 0b7e5333-c385-474a-a021-98228919570d" level={8} cateid={139} style={{ textAlign: "center" }}>Action</div>
                                        </div>
                                    </td>
                                </tr>
                                {
                                    (data.scoringCriteriaRespDtoList ?? []).map((item, index) => {
                                        return <tr key={item.id} className="table-row">
                                            <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 1339efd5-c346-41c5-b3cb-d68cb8e3c5a6" level={7} cateid={140}>
                                                    {index + 1}
                                                </div>
                                            </td>
                                            {
                                                data.typeSelect === typeRange ? [
                                                    <td key="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 1339efd5-c346-41c5-b3cb-d68cb8e3c5a6" level={7} cateid={140}>
                                                            <div className="w-textformfield wbaseItem-value w-row eada5e8e-edbd-443b-9579-71cc44317df2" level={8} cateid={85} style={{ maxWidth: "200px" }}>
                                                                <div className="wbaseItem-value 0bb5e726-0339-4344-aaf9-91be0c4c0dd0" level={9} cateid={86}>
                                                                    <div className="textfield">
                                                                        <input
                                                                            defaultValue={Ultis.money(item.value.split(",")[0])}
                                                                            onChange={(ev) => { ev.target.value = ev.target.value.replace(/\D/g, "") }}
                                                                            onBlur={(ev) => ev.target.value = Ultis.money(item.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>,
                                                    <td key="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 1339efd5-c346-41c5-b3cb-d68cb8e3c5a6" level={7} cateid={140}>
                                                            <div className="w-textformfield wbaseItem-value w-row eada5e8e-edbd-443b-9579-71cc44317df2" level={8} cateid={85} style={{ maxWidth: "200px" }}>
                                                                <div className="wbaseItem-value 0bb5e726-0339-4344-aaf9-91be0c4c0dd0" level={9} cateid={86}>
                                                                    <div className="textfield">
                                                                        <input
                                                                            defaultValue={Ultis.money(item.value.split(",")[1])}
                                                                            onChange={(ev) => { ev.target.value = ev.target.value.replace(/\D/g, "") }}
                                                                            onBlur={(ev) => ev.target.value = Ultis.money(item.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                ] : <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 1339efd5-c346-41c5-b3cb-d68cb8e3c5a6" level={7} cateid={140}>
                                                        {/* {item.name} */}
                                                    </div>
                                                </td>}

                                            <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 1339efd5-c346-41c5-b3cb-d68cb8e3c5a6" level={7} cateid={140}>
                                                    <div className="w-textformfield wbaseItem-value w-row eada5e8e-edbd-443b-9579-71cc44317df2" level={8} cateid={85} style={{ maxWidth: "200px" }}>
                                                        <div className="wbaseItem-value 0bb5e726-0339-4344-aaf9-91be0c4c0dd0" level={9} cateid={86}>
                                                            <div className="textfield">
                                                                <input onChange={(ev) => {
                                                                    let splitVl = ev.target.value.replace(/[^\d|.]/g, "").split(".");
                                                                    if (splitVl[0] === "") splitVl.shift();
                                                                    if (splitVl.length > 0) {
                                                                        ev.target.value = splitVl[0] + (splitVl.length > 1 ? `.${splitVl.slice(1).join("").substring(0, 2)}` : "");
                                                                    } else {
                                                                        ev.target.value = "";
                                                                    }
                                                                }} defaultValue={item.point} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 1339efd5-c346-41c5-b3cb-d68cb8e3c5a6" level={7} cateid={140}>
                                                    {item.weightInGroup}
                                                </div>
                                            </td>
                                            <td id="2x6" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 1339efd5-c346-41c5-b3cb-d68cb8e3c5a6" level={7} cateid={140}>
                                                    {item.point * item.weightInGroup}
                                                </div>
                                            </td>
                                            <td id="2x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 807b9ae3-2b78-4fcd-9d68-893c1431822b" level={10} cateid={140} style={{ justifyContent: "center" }}>
                                                    <div onClick={() => { setconfirmDel(true) }} className="w-svg wbaseItem-value" level={11} cateid={115} style={{ width: "24px", height: "24px" }}>
                                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                            </path>
                                                            <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <div className="w-frame wbaseItem-value w-row a5650dcc-7a8c-43b6-a92e-13b1c4b2fc68" level={3} cateid={140}>
                <button onClick={() => { }} type="button" className="w-button wbaseItem-value w-row 0827b65b-f8dd-422d-9526-661a04e2e39b" level={4} cateid={29}>
                    <div className="w-text wbaseItem-value ed946f59-a67d-4391-8bd9-75ff48cd1b16" level={5} cateid={139}>Hủy</div>
                </button>
                <button type="submit" className="w-button wbaseItem-value w-row 99617da8-7d91-4e4d-b33b-f34e0eadac50" level={4} cateid={29}>
                    <div className="w-svg wbaseItem-value 4565f46e-45f7-418c-8968-a7510d4ee3bb" level={5} cateid={115}>
                        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M14.4893 3.5115C14.726 3.74818 14.726 4.13191 14.4893 4.36859L6.61053 12.2474C6.37385 12.4841 5.99011 12.4841 5.75343 12.2474L1.51101 8.00496C1.27433 7.76828 1.27433 7.38454 1.51101 7.14786C1.74769 6.91118 2.13142 6.91118 2.36811 7.14786L6.18198 10.9617L13.6322 3.5115C13.8689 3.27481 14.2526 3.27481 14.4893 3.5115Z"
                                fill="#FFFFFFFF">
                            </path>
                        </svg>
                    </div>
                    <div className="w-text wbaseItem-value bb1d2d55-76a9-4b06-b469-422a88dd411e" level={5}
                        cateid={139}>Lưu</div>
                </button>
            </div>
        </form>
    )
}