import { baseurl } from "../../../../config/ConfigAPI";
import { postData } from "../../../../core/BaseDA";

export default class DTIDA {
    static searchDTI = async (obj) => {
        const url = `${baseurl}dti/search_new`;
        const response = await postData(url, { obj: obj });

        if (response.code === "200") {
            return response.data;
        }
        return null;
    };

    static updateDTI = async (obj) => {
        const url = `${baseurl}dti/adjust`;
        const response = await postData(url, { obj: obj });

        if (response.code === "200") {
            return response.data;
        }
        return null;
    };

    static getDTI = async (obj) => {
        const url = `${baseurl}dti/get`;
        const response = await postData(url, { obj: obj });

        if (response.code === "200") {
            return response.data;
        }
        return null;
    };


    static tinhDTI = async (data) => {
        const url = `${baseurl}dti/get_initial_temp`;
        const response = await postData(url, { obj: data });
        return response;
    }
}