import './BoSungHoSo.css';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTaskDetail, hoSo_doneAction } from '../../../redux/middleware/api/congvieccuatoi/CongViecDA';
import Select2 from '../../../components/select2/Select2.js';
import { getPurposeList, getcareerList } from '../../../redux/middleware/api/Filter/Filter';
import { Ultis } from '../../../core/utils';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import ChuongTrinhVayDA from '../../../redux/middleware/api/W_ChuongTrinhVay/ChuongTrinhVayDA';
import LayoutCongViec, { ThongTinKhoiTao } from '../CommonView';
import FileDA from '../../../redux/middleware/api/FileUpload/FileDA';
import { PopupType, WPopup } from '../../../components/Popup/Popup';
import NotificationAlert from "react-notification-alert";
import PopupHoSoBanDau from '../ThamDinh-XuLy/PopupHoSoBanDau';
import PopupXemQuyTrinh from '../ThamDinh-XuLy/PopupXemQuyTrinh';
import PopupYeuCauBoSung from './PopupYeuCauBoSung';

export default function BoSungHoSo() {
    const { register, handleSubmit, control, setValue, getValues, setError, formState: { errors } } = useForm({ shouldFocusError: false });
    const { fields: fileHoSo, append: addFileHoSoEvent, remove: removeFileHoSoEvent } = useFieldArray({
        control,
        name: 'fileLoanApplication',
        keyName: 'GID',
    });

    const navigate = useNavigate();
    const notificationAlert = useRef();

    const location = useLocation();
    const [data, setData] = useState();
    const [settingData, setSettingData] = useState({});
    const [customerItem, setCustomerItem] = useState({});

    const [danhSachMucDich, setMucDich] = useState([]);
    const [danhSachNgheNghiep, setNgheNghiep] = useState([]);

    const [loanProgramItem, setLoanProgramItem] = useState();

    const [newNote, setNewNote] = useState("");
    const [errorNote, setErrorNote] = useState(false);
    const [nghiNgoLuaDao, setNghiNgoLuaDao] = useState(false);

    const [isDinhDangFile, setDinhDangFile] = useState(false);
    const [isKichThuocFile, setKichThuocFile] = useState(false);

    const [thoatKhoiTao, setThoatKhoiTao] = useState(false);

    const [showInitProfile, onShowInitProfile] = useState(false);
    const [showProcess, onShowProcess] = useState(false);
    const [showYeuCauBoSung, setShowYeuCauBoSung] = useState(false);

    const subId = location.search.replace("?", "");
    const _InitData = async () => {
        let res = await getTaskDetail({ id: subId });
        if (res) {
            setData(res);
            setCustomerItem(res['process-instance-variables'].khachHang);
            setSettingData(res['process-instance-variables']);

            const _settingData = res['process-instance-variables'].loanApplication;

            Object.keys(_settingData).forEach(fieldName => {
                setValue(fieldName, _settingData[fieldName]);
            });

            let _res = await ChuongTrinhVayDA.getDetailLoanProgramByCode(1, 100, [_settingData.maChuongTrinhVay]);
            if (_res != null) {
                setLoanProgramItem(_res.pageData[0].documentDetails);
                _settingData.fileLoanApplication.forEach((e) => e.data = _res.pageData[0].documentDetails);
                setValue('fileLoanApplication', _settingData.fileLoanApplication);
            }

            setNghiNgoLuaDao(_settingData.nghiNgoLuaDao === 1 ?? false);
        }
    }

    const getDanhSachMucDich = async (page, size, name) => {
        let res = await getPurposeList(1, 100, '');
        if (res != null) {
            setMucDich(res);
        }
    }
    const getDanhSachNgheNghiep = async (page, size, name) => {
        let res = await getcareerList(1, 100, '');
        if (res != null) {
            setNgheNghiep(res.content);
        }
    }

    // event scroll
    const [scrollIndex, setScroll] = useState(0);
    const clickScrollTo = (event) => {
        let index = [...event.target.parentElement.children].indexOf(event.target);
        let scrollElement = document.querySelector(`.wbaseItem-value[class*="acb89c5b-6578-4351-be1d-5b6423a60f68"]`);
        scrollElement.scrollTo({
            top: scrollElement.firstChild.children[index].offsetTop,
            behavior: "smooth",
        });
    }
    const handleScroll = (event) => {
        let scrollFrom = event.target.getBoundingClientRect().y;
        let scrollTo = [...event.target.firstChild.children].filter(e => {
            let eRect = e.getBoundingClientRect();
            return (eRect.y + eRect.height / 2) > scrollFrom;
        }).sort((a, b) => {
            let aRect = a.getBoundingClientRect();
            let bRect = b.getBoundingClientRect();
            return (aRect.y + aRect.height / 2) - (bRect.y + bRect.height / 2);
        });
        let index = [...event.target.firstChild.children].indexOf(scrollTo[0]);
        if (index !== scrollIndex) setScroll(index);
    }

    // check form validate and sibmit
    const onSumit = async (event) => {
        if (newNote.length > 0) {
            handleSendForm();
        } else {
            setErrorNote(true);
        }
    }

    useEffect(() => {
        _InitData();
        getDanhSachMucDich();
        getDanhSachNgheNghiep();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addFileHoSo = () => {
        let newFileData = [];
        if (loanProgramItem?.length > 0) {
            newFileData = loanProgramItem.map((item, index) => ({ name: item.documentName, id: item?.documentCode ?? index }))
        }

        let newFile = { isNew: true, data: newFileData }
        addFileHoSoEvent(newFile);
    }
    const deleteFileHoSo = (index) => {
        removeFileHoSoEvent(index);
    }

    const fileInputRefs = useRef([]);

    const handleBrowseClick = (index) => {
        fileInputRefs.current[index].click();
    };

    const allowedExtensions = ["doc", "pdf", "jpg", "jpeg", "xlsx"];

    const handleFileInputChange = async (event, index) => {
        const file = event.target.files[0];
        // const updatedSelectedFiles = [...selectedFiles];
        // updatedSelectedFiles[index] = file;
        // setSelectedFiles(updatedSelectedFiles);
        // TODO: call api upload file
        if (file != null) {
            //TODO: check type file, check dung luong 5mb
            if (file.size > 5 * 1024 * 1024) {
                setKichThuocFile(true);
                return;
            }
            let _listDot = file.name.split(".");
            if (!allowedExtensions.includes(_listDot[_listDot.length - 1])) {
                setDinhDangFile(true)
                return;
            }
            let formDataFile = new FormData();
            formDataFile.append('file', file);
            let data = getValues();
            formDataFile.append('fileCodeLoanProgram', data.fileLoanApplication[index].fileCodeLoanProgram)
            let res = await FileDA.uploadFile(formDataFile);
            if (res.code === "200") {
                const newList = [...fileHoSo]
                newList[index].id = res.data.id;
                newList[index].name = res.data.ten;
                newList[index].dateCreate = res.data.createdDate;
                newList[index].maNguoiTao = res.data.maNguoiTao;
                newList[index].tenNguoiTao = res.data.tenNguoiTao;
                newList[index].tenFileCtv = res.data.tenFileCtv;
                newList[index].maFileCtv = res.data.maFileCtv;
                newList[index].createdBy = res.data.createdBy;
                newList[index].path = res.data.path;
                newList[index].isRequired = res.data.isRequired;
                newList[index].isNew = false;
                setValue(`fileLoanApplication`, newList)
            }
        }
    };

    const ValidateDropdownFormField = ({ error, name, placeholder, validate, data, onChangeEvent }) => {
        return (
            <Controller
                shouldUnregister={false}
                name={name} control={control}  {...register(`${name}`)} style={{ order: 2 }} rules={{ required: true }}
                render={({ field }) => (
                    <div
                        className={`select2-custom ${error && 'helper-text'}`}
                        helper-text={error && validate}
                        placeholder={placeholder ?? ""}
                    >
                        <Select2 {...field}
                            data={data}
                            options={{ placeholder: placeholder }}
                            onChange={(event) => {
                                field.onChange(event);
                                if (onChangeEvent != null) onChangeEvent(event)
                                console.log(event.target.value)
                            }}
                        />
                    </div>
                )}
            />
        )
    }

    const FileItem = ({ item, index }) => {
        const maNhaCungCapFieldName = `fileLoanApplication[${index}].fileCodeLoanProgram`;
        return (
            <tr key={index} className="table-row">
                <td className="table-cell" rowSpan={1} colSpan={1}>
                    <div className="w-frame wbaseItem-value w-row 16806374-e86a-43a2-855a-6ab7c3beb3b2" wrap="nowrap" level={10} cateid={140}>
                        {
                            ValidateDropdownFormField({
                                error: errors[maNhaCungCapFieldName],
                                name: maNhaCungCapFieldName,
                                placeholder: "Chọn loại hồ sơ",
                                validate: "Vui lòng chọn loại hồ sơ",
                                data: item.data ?? [],
                                onChangeEvent: (ev) => {
                                    if (ev.target.value?.length > 0) {
                                        const newFileHoSo = [...fileHoSo];
                                        newFileHoSo[index].fileCodeLoanProgram = ev.target.value;
                                        setValue('fileLoanApplication', newFileHoSo);
                                    }
                                }
                            })
                        }
                    </div>
                </td>
                <td className="table-cell" rowSpan={1} colSpan={1}>
                    <input ref={(el) => (fileInputRefs.current[index] = el)}
                        type="file"
                        style={{ display: 'none' }}
                        name='file'
                        onChange={(event) => handleFileInputChange(event, index)}
                    />
                    <div className="w-frame wbaseItem-value w-row d34d0af9-f6eb-4a3d-9c11-30899416e434" wrap="nowrap" level={10} cateid={140}>
                        {
                            item.name?.length > 0 ?
                                <div className="w-text wbaseItem-value 26777ebc-b591-4902-8014-96bf9639613e" level={11} cateid={139}>
                                    {item.name}
                                </div> :
                                <button onClick={() => {
                                    if (item.fileCodeLoanProgram?.length > 0) {
                                        handleBrowseClick(index)
                                    } else {
                                        setError(`fileLoanApplication[${index}].fileCodeLoanProgram`, {
                                            type: 'manual',
                                            message: 'Hãy chọn loại hồ sơ',
                                        });
                                    }
                                }}
                                    type="button" className="w-button wbaseItem-value w-row b085459d-3c5e-4f02-b293-b43dcd2e6825" wrap="nowrap" name-field="Button" level={11} cateid={29}>
                                    <div className="w-svg wbaseItem-value b8aefd53-510f-4940-ab2b-a46c5fe69a3e" level={12} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 8.83333L12 3L17 8.83333H12.8333V16.3333H11.1667V8.83333H7Z" fill="#009944FF" />
                                            <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#009944FF" />
                                        </svg>
                                    </div>
                                    <div className="w-text wbaseItem-value 9bfa4e38-4b09-439f-b957-d7fea69801bf" name-field="Text" level={12} cateid={139}>Upload<br />
                                    </div>
                                </button>
                        }
                    </div>
                </td>
                <td id="5x3" className="table-cell" rowSpan={1} colSpan={1}>
                    <div className="w-frame wbaseItem-value w-row 08c51913-577f-4f51-ae89-c9b31435026f" wrap="nowrap" level={10} cateid={140} />
                </td>
                <td id="5x4" className="table-cell" rowSpan={1} colSpan={1}>
                    <div className="w-frame wbaseItem-value w-row 69716195-ac1a-464e-8067-acbef765c50d" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 6c31b2de-71c6-421a-8b42-c5be2a156950" level={11} cateid={139}>
                            {item.maNguoiTao} - {item.tenNguoiTao}
                        </div>
                    </div>
                </td>
                <td id="5x5" className="table-cell" rowSpan={1} colSpan={1}>
                    <div className="w-frame wbaseItem-value w-row 9031a328-6928-4886-a8d0-71729f818547" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 1f7ed55a-124e-41bb-87af-afda94099676" level={11} cateid={139}>
                            {item.dateCreate && new Date(item.dateCreate).toLocaleDateString("en-GB")}
                        </div>
                        <button type='button' onClick={() => deleteFileHoSo(index)} className="w-button w-svg wbaseItem-value 16928993-0f71-4751-9e9d-b0f0da06b71d" level={11} cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF" />
                                <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF" />
                            </svg>
                        </button>
                    </div>
                </td>
            </tr>
        )
    }
    const returnData = (action) => {
        let newData = getValues();
        let list_id;
        if (newData.fileLoanApplication?.length > 0)
            list_id = newData.fileLoanApplication
                .map((e) => e.id)
                .filter((id) => id != null && id !== undefined);
        else {
            list_id = []
        }

        if (newNote !== "") {
            let _newObjNote = {
                "maNguoiTao": settingData.loanApplication.saleUserName,
                "noiDung": newNote,
                "loai": newNote,
                "maLoai": newNote,
                "tenNguoiTao": settingData.loanApplication.saleName,
                "createdDate": null,
                "idHoSo": settingData.loanApplication.id,
            }
            settingData.loanApplication.noteLoanApplication.push(_newObjNote);
        }

        const mergeData = {
            ...settingData.loanApplication,
            ...newData,
        }

        mergeData.fileListId = list_id;
        const sendData = {
            action: action,
            loanApplicationRequest: {
                "fis.onboarding.process.model.jlos.dto.LoanApplicationDTO": {
                    ...mergeData
                },
            }
        }
        return sendData;
    }

    const handleSendForm = async () => {
        const sendData = returnData("saleSend");
        try {
            let res = await hoSo_doneAction(data["active-user-tasks"]["task-summary"][0]["task-id"], "sendIs", sendData);
            navigate('/admin/ho-so-cho-xu-ly')
            return res
        } catch (error) {
            // notify(notificationAlert, ERROR, error)
        }
    }
    const handleSaveForm = async () => {
        const sendData = returnData("saleSave");
        try {
            let res = await hoSo_doneAction(data["active-user-tasks"]["task-summary"][0]["task-id"], "save", sendData);
            navigate('/admin/ho-so-cho-xu-ly');
            return res
        } catch (error) {
            // notify(notificationAlert, ERROR, error)
        }
    }
    return (
        data != null ? (
            <>
                {
                    thoatKhoiTao &&
                    <WPopup
                        type={PopupType.WARNING}
                        title={"Hủy bổ sung hồ sơ"}
                        message="Các thông tin đã nhập sẽ không được lưu lại"
                        cancelTitle="Tiếp tục nhập"
                        confirmTitle='Xác nhận'
                        cancelAction={() => setThoatKhoiTao(false)}
                        confirmAction={() => { navigate('/admin/ho-so-cho-xu-ly') }}
                        cancelButton={true}
                    />
                }
                {
                    isKichThuocFile &&
                    <WPopup
                        type={PopupType.WARNING}
                        title={"Tệp tải lên quá lớn"}
                        message={<><span>Dung lượng tối đa cho phép 5MB.</span><span>Vui lòng kiểm tra lại.</span></>}
                        cancelTitle="Đóng"
                        cancelAction={() => setKichThuocFile(false)}
                        cancelButton={true}
                        confirmButton={false}
                    />
                }
                {
                    isDinhDangFile &&
                    <WPopup
                        type={PopupType.WARNING}
                        title={"Tệp tải lên chưa đúng định dạng"}
                        message={<><span>Định dạng file cho phép</span> <span>.doc, .docx,.xls, .xlsx, .jpg, .jpeg, .png, .pdf. </span><span>Vui lòng kiểm tra lại.</span></>}
                        cancelTitle="Đóng"
                        cancelAction={() => setDinhDangFile(false)}
                        cancelButton={true}
                        confirmButton={false}
                    />
                }

                <NotificationAlert ref={notificationAlert} />
                {
                    showInitProfile &&
                    <WPopup
                        popupContent={
                            <PopupHoSoBanDau
                                data={settingData}
                                closePopup={() => onShowInitProfile(false)}
                            />}
                    />
                }

                {
                    showProcess &&
                    <WPopup
                        popupContent={
                            <PopupXemQuyTrinh
                                closePopup={() => onShowProcess(false)}
                                code={subId}
                                loanApplicationId={settingData.loanApplication.id}
                            />
                        }
                    />
                }
                {
                    showYeuCauBoSung &&
                    <WPopup
                        popupContent={
                            <PopupYeuCauBoSung
                                data={settingData?.loanApplication?.ykienThamDinh}
                                closePopup={() => setShowYeuCauBoSung(false)}
                            />
                        }
                        cancelAction={() => setShowYeuCauBoSung(false)}
                    />
                }


                <form onSubmit={handleSubmit(onSumit)} className="w-frame wbaseItem-value w-col 25ab2e3f-47f7-486b-bc0a-996b90377309" wrap="nowrap" level={2} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col 8635406e-e3a5-4a72-ad24-4c179d82891f" wrap="nowrap" level={3} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 29b23bad-17b8-4a66-ae6f-f663975e0d01" wrap="nowrap" level={4} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col 4e4179a5-fbad-4628-a447-02c3a3712570" wrap="nowrap" level={5} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row aedf1f4b-8b9a-4ddf-83e3-f5282de389ef" wrap="nowrap" level={6} cateid={140}>
                                    <div className="w-text wbaseItem-value 30bed3df-265f-465f-a94d-a4948b4a1cfe" level={7} cateid={139}>Bổ sung hồ sơ</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-row breadcrumb 9286443d-805f-494e-a021-67ecc4cff926" wrap="nowrap" level={6} cateid={140}>
                                    <button type="button" className="w-button wbaseItem-value w-row d57200b3-066c-425c-85d3-05260df0fd2b" wrap="nowrap" name-field="Button" level={7} cateid={29}>
                                        <div className="w-text wbaseItem-value a6731bab-2ca1-4269-aa6f-ccc7dd7e18e1" name-field="Text" level={8} cateid={139}>Hồ sơ chờ xử lý</div>
                                        <div className="w-svg wbaseItem-value e0dce904-7b10-4923-8466-d218557cd9a9" level={8} cateid={115}>
                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6" />
                                            </svg>
                                        </div>
                                    </button>
                                    <div className="w-text wbaseItem-value a2e7b342-4c9c-4814-993c-975976f07780" level={7} cateid={139}>
                                        {settingData.id}
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row 34d4372e-7980-4c02-929f-42cde843f5f8" wrap="nowrap" level={5} cateid={140}>
                                <button onClick={() => { onShowInitProfile(true) }} type="button" className="w-button wbaseItem-value w-row e1ee50fd-8127-49f4-baee-868648aa5023" wrap="nowrap" name-field="Button" level={6} cateid={29}>
                                    <div className="w-svg wbaseItem-value d62cc0a5-1623-4179-8d3a-326bace6264a" level={7} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.4167 6.33333L9.91667 3H2.83333C2.61232 3 2.40036 3.0878 2.24408 3.24408C2.0878 3.40036 2 3.61232 2 3.83333V18.8333C2 19.4964 2.26339 20.1323 2.73223 20.6011C3.20107 21.0699 3.83696 21.3333 4.5 21.3333H19.5C20.163 21.3333 20.7989 21.0699 21.2678 20.6011C21.7366 20.1323 22 19.4964 22 18.8333V7.16667C22 6.94565 21.9122 6.73369 21.7559 6.57741C21.5996 6.42113 21.3877 6.33333 21.1667 6.33333H12.4167Z" fill="#28282999" />
                                        </svg>
                                    </div>
                                    <div className="w-text wbaseItem-value e85af9da-14f0-4022-b2dc-e2a3c39a9d91" name-field="Text" level={7} cateid={139}>Xem hồ sơ ban đầu</div>
                                </button>
                                <button onClick={() => { setShowYeuCauBoSung(true) }} type="button" className="w-button wbaseItem-value w-row 3180f958-1980-4ed5-9b56-b04b047f7f16" wrap="nowrap" name-field="Button" level={6} cateid={29}>
                                    <div className="w-svg wbaseItem-value 37dfd7a3-5d56-4595-9070-c6bc0715f664" level={7} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 3C6.5 3 2 6.75 2 11.3333C2 15.9167 6.5 19.6667 12 19.6667C12.8333 19.6667 13.6667 19.5833 14.5 19.4167L19.1667 21.25C19.25 21.25 19.3333 21.3333 19.5 21.3333C19.6667 21.3333 19.8333 21.25 20 21.1667C20.25 21 20.3333 20.6667 20.3333 20.4167L19.9167 16.5C21.25 15 22 13.25 22 11.4167C22 6.75 17.5 3 12 3Z" fill="#FC6B03FF" />
                                        </svg>
                                    </div>
                                    <div className="w-text wbaseItem-value 4e4e1bb0-1373-41cc-b0c1-ece9086e56c1" name-field="Text" level={7} cateid={139}>Xem yêu cầu bổ sung</div>
                                </button>
                                <button onClick={() => { onShowProcess(true) }} type="button" className="w-button wbaseItem-value w-row 8d942d7c-d8fa-4488-b6f5-775785dc206d" wrap="nowrap" name-field="Button" level={6} cateid={29}>
                                    <div className="w-svg wbaseItem-value 139be20f-a924-4242-bd29-157c7b8c50ad" level={7} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.5 7C13.875 7 15 5.875 15 4.5C15 3.125 13.875 2 12.5 2C11.125 2 10 3.125 10 4.5C10 5.875 11.125 7 12.5 7ZM12.5 9.5C11.125 9.5 10 10.625 10 12C10 13.375 11.125 14.5 12.5 14.5C13.875 14.5 15 13.375 15 12C15 10.625 13.875 9.5 12.5 9.5ZM12.5 17C11.125 17 10 18.125 10 19.5C10 20.875 11.125 22 12.5 22C13.875 22 15 20.875 15 19.5C15 18.125 13.875 17 12.5 17Z" fill="#009944FF" />
                                        </svg>
                                    </div>
                                    <div className="w-text wbaseItem-value ce12d312-1b5f-4e6b-8236-4be825776d0e" name-field="Text" level={7} cateid={139}>Xem quy trình</div>
                                </button>
                            </div>
                        </div>
                        {
                            <ThongTinKhoiTao loanId={settingData.loanApplication.id} />
                        }
                        <div className="w-frame wbaseItem-value w-col eb7bc88d-9fe7-466b-9fa2-b898b398a173" wrap="nowrap" level={4} cateid={140}>
                            <div className="w-frame wbaseItem-value w-row 4efd625b-36d1-4834-8340-d1ba8738d2a4" wrap="nowrap" level={5} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col e6429c9d-577e-440d-ab32-890b27a76413" wrap="nowrap" level={6} cateid={140}>
                                    <button type='button' onClick={clickScrollTo} className={`w-button wbaseItem-value w-row 0c5e9876-3efb-4f7a-bb50-7bf0ea3907f0 ${scrollIndex === 0 ? "selected-mini-sidebar-option" : ""}`} wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value d061cc70-edd2-461c-8ddd-3f49100e9675" level={8} cateid={139}>Thông tin eKYC khách hàng</div>
                                    </button>
                                    <button type='button' onClick={clickScrollTo} className={`w-button wbaseItem-value w-row 0c5e9876-3efb-4f7a-bb50-7bf0ea3907f0 ${scrollIndex === 1 ? "selected-mini-sidebar-option" : ""}`} wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 4140459e-c62d-432f-99c4-d3312ef12c5b" level={8} cateid={139}>Chương trình vay và sản phẩm</div>
                                    </button>
                                    <button type='button' onClick={clickScrollTo} className={`w-button wbaseItem-value w-row 0c5e9876-3efb-4f7a-bb50-7bf0ea3907f0 ${scrollIndex === 2 ? "selected-mini-sidebar-option" : ""}`} wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 30ea61de-8dd1-4177-9025-a91b0e961d8a" level={8} cateid={139}>Thông tin hàng hóa</div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        wrap="nowrap" level={7} cateid={140}
                                        className={`w-button wbaseItem-value w-row 0c5e9876-3efb-4f7a-bb50-7bf0ea3907f0 ${scrollIndex === 3 ? "selected-mini-sidebar-option" : ""} ${settingData.loanApplication.checkMucDichVay === 1 ? "additional-needed" : ""}`}
                                    >
                                        <div className="w-text wbaseItem-value f196539f-b371-4bf6-a61a-e3a8c8d562b6" level={8} cateid={139}>Thông tin khoản vay</div>
                                    </button>
                                    <button type='button' onClick={clickScrollTo} className={`w-button wbaseItem-value w-row 0c5e9876-3efb-4f7a-bb50-7bf0ea3907f0 ${scrollIndex === 4 ? "selected-mini-sidebar-option" : ""}`} wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value b6f3312d-c71a-4c62-9202-610ddf87084b" level={8} cateid={139}>Bảo hiểm</div>
                                    </button>
                                    <button type='button' onClick={clickScrollTo} className={`w-button wbaseItem-value w-row 0c5e9876-3efb-4f7a-bb50-7bf0ea3907f0 ${scrollIndex === 5 ? "selected-mini-sidebar-option" : ""}`} wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 00ee5012-2cd6-470b-965d-2c47500dc62a" level={8} cateid={139}>Sản phẩm bán kèm</div>
                                    </button>
                                    <button type='button' onClick={clickScrollTo} className={`w-button wbaseItem-value w-row 0c5e9876-3efb-4f7a-bb50-7bf0ea3907f0 ${scrollIndex === 6 ? "selected-mini-sidebar-option" : ""}`} wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value efb67f5a-df84-4ac9-8546-200354f8cb20" level={8} cateid={139}>Thông tin khách hàng bổ sung</div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        wrap="nowrap" level={7} cateid={140}
                                        className={`w-button wbaseItem-value w-row 0c5e9876-3efb-4f7a-bb50-7bf0ea3907f0 ${scrollIndex === 7 ? "selected-mini-sidebar-option" : ""}  ${settingData.loanApplication.checkNgheNghiep === 1 ? "additional-needed" : ""}`}
                                    >
                                        <div className="w-text wbaseItem-value b12f7f1e-1883-47d6-92b7-68e2572ce4c0" level={8} cateid={139}>Công việc khách hàng</div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        wrap="nowrap" level={7} cateid={140}
                                        className={`w-button wbaseItem-value w-row 0c5e9876-3efb-4f7a-bb50-7bf0ea3907f0 ${scrollIndex === 8 ? "selected-mini-sidebar-option" : ""} ${settingData.loanApplication.checkThuNhapThang === 1 ? "additional-needed" : ""}`}
                                    >
                                        <div className="w-text wbaseItem-value 7ebc677a-ab34-4973-a43e-efaffb05d3b0" level={8} cateid={139}>Tài chính khách hàng</div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        wrap="nowrap" level={7} cateid={140}
                                        className={`w-button wbaseItem-value w-row 0c5e9876-3efb-4f7a-bb50-7bf0ea3907f0 ${scrollIndex === 9 ? "selected-mini-sidebar-option" : ""} ${settingData.loanApplication.checkSoNguoiPhuThuoc === 1 ? "additional-needed" : ""}`}
                                    >
                                        <div className="w-text wbaseItem-value ccc0a82d-126d-4198-bf35-f20f0639f8b8" level={8} cateid={139}>Người liên quan</div>
                                    </button>
                                    <button type='button' onClick={clickScrollTo} className={`w-button wbaseItem-value w-row 0c5e9876-3efb-4f7a-bb50-7bf0ea3907f0 ${scrollIndex === 10 ? "selected-mini-sidebar-option" : ""}`} wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 89003114-fc5c-4070-8145-4f88e4b2114f" level={8} cateid={139}>Hồ sơ đính kèm</div>
                                    </button>
                                    <button type='button' onClick={clickScrollTo} className={`w-button wbaseItem-value w-row 0c5e9876-3efb-4f7a-bb50-7bf0ea3907f0 ${scrollIndex === 11 ? "selected-mini-sidebar-option" : ""}`} wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 6b40bd0b-fc83-4946-9e00-bad6eceb9903" level={8} cateid={139}>Ghi chú</div>
                                    </button>
                                </div>

                                <div onScroll={handleScroll} className="w-frame wbaseItem-value w-col acb89c5b-6578-4351-be1d-5b6423a60f68" wrap="nowrap" scroll="true" level={6} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-col ba2ef9d6-3cee-4e53-8c10-76383e60c5bd" wrap="nowrap" level={7} cateid={140}>
                                        {
                                            LayoutCongViec.EkycKhachHang(customerItem)
                                        }
                                        {
                                            LayoutCongViec.ChuongTrinhVayVaSanPham(settingData.loanApplication)
                                        }
                                        {
                                            LayoutCongViec.ThongTinHangHoa(settingData.loanApplication)
                                        }


                                        <div className="w-frame wbaseItem-value w-col 05c4bb9c-c9e7-4876-80d8-929056e5dfc6" wrap="nowrap" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 8a064dbc-9301-4ae8-a59b-9bd62e86ceb6" wrap="nowrap" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value e4c31a50-bbc6-4f2f-8051-3feb40334888" level={10} cateid={139}>Thông tin khoản vay</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row de6b918b-8231-4845-b660-231f98a7ad06" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 fedd4648-2eb0-4aed-acff-b31416db6c0c" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 5a18f19c-e1f8-4e3f-96d8-ba350f18f492" level={11} cateid={139}>Tỷ lệ % trả trước:</div>
                                                    <div className="w-text wbaseItem-value 76d8b7ea-bfaf-4434-9c9b-ba576cd3c719" level={11} cateid={139}>
                                                        {settingData.loanApplication.tyLeTraTruoc ?? 0} %
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 a6b321e0-b5c0-4dfe-85f3-930e6f41f22e" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7bffad9-2371-40b6-ab74-957eb7ee0a6e" level={11} cateid={139}>Số tiền trả trước:</div>
                                                    <div className="w-text wbaseItem-value d3436a8a-8fd9-463e-a386-a8596bf19a88" level={11} cateid={139}>
                                                        {Ultis.money(settingData.soTienTraTruoc)} VND
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 122f50dd-d855-4b64-84c5-fe548bb24654" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value f20eb238-5fa0-4c27-b3e7-411730663b1a" level={11} cateid={139}>Ngày hợp đồng dự kiến:</div>
                                                    <div className="w-text wbaseItem-value ed27a8fe-a6c5-46da-9d44-2f852dd5d581" level={11} cateid={139}>
                                                        {settingData.loanApplication.ngayHopDongDuKien ?? "_"}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 e44e10d9-a072-4405-ab48-06b1a0d77613" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 1a577fe5-99d0-4630-a176-06c0183537fd" level={11} cateid={139}>Ngày thanh toán hàng tháng:</div>
                                                    <div className="w-text wbaseItem-value 1167efb5-0a07-48b2-8d4d-8ae39588d2a7" level={11} cateid={139}>
                                                        {settingData.loanApplication.ngayThanhToanHangThang ?? "_"}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 2fa69caf-baca-4ea2-b3f0-ce1e969d66a9" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value c5296c34-bc97-478d-9683-e0014676f088" level={11} cateid={139}>Số tiền vay gốc:</div>
                                                    <div className="w-text wbaseItem-value 90d0aaff-5968-4c77-be86-60f1735412fd" level={11} cateid={139}>
                                                        {Ultis.money(settingData.loanApplication.soTienVayGoc)} VND
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 9cb653f7-7398-4c5d-8226-d75e6a3a4465" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 7bc7db74-9a8f-486f-8a0e-ae2ed2efcfe0" wrap="nowrap" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 7facbf57-257f-4a76-b139-271c0903cbd7" level={12} cateid={139}>Mục đích vay</div>
                                                        {
                                                            settingData.loanApplication.checkMucDichVay === 1 &&
                                                            <div className="w-text wbaseItem-value 571f26a7-50d0-41a5-b7e9-4663e4bd495d" level={12} cateid={139}>*</div>
                                                        }
                                                    </div>
                                                    {
                                                        settingData.loanApplication.checkMucDichVay === 1 ?
                                                            <div className={'select2-custom select2-bshs ' + (errors.maMucDichVay && 'helper-text')} helper-text={errors.maMucDichVay && 'Vui lòng mục đích vay'}>
                                                                <Controller
                                                                    name="maMucDichVay"
                                                                    control={control}
                                                                    // {...register("maMucDichVay")}
                                                                    style={{ order: 2 }}
                                                                    rules={{ required: true }}
                                                                    render={({ field }) => (
                                                                        <Select2 {...field}
                                                                            data={danhSachMucDich.map((item, _) => { return { name: item.name, id: item.code } })}
                                                                            options={{ placeholder: 'Chọn mục đích' }}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                            :
                                                            <div className="w-text wbaseItem-value 90d0aaff-5968-4c77-be86-60f1735412fd" level={11} cateid={139}>
                                                                {settingData.loanApplication.mucDichVay}
                                                            </div>
                                                    }
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 425b7b80-271d-46d3-8098-478770c38756" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value b60bfa72-cd80-4530-b496-b0c20ac974d5" level={11} cateid={139}>Kỳ hạn vay:</div>
                                                    <div className="w-text wbaseItem-value 5ea96b09-edc9-40da-bc78-06ad050b437e" level={11} cateid={139}>{settingData.loanApplication.kyHanVay} tháng</div>
                                                </div>

                                                <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col12-lg col24-md 11d6c991-902b-4705-879e-3225930e1407" wrap="nowrap" level={10} cateid={140}>
                                                    <label className="w-switch wbaseItem-value 789a2c4f-258c-4ee2-952e-4e8c751157c2" level={11} cateid={81}>
                                                        <input
                                                            checked={settingData.loanApplication.isThanhToanKhoanVayCu}
                                                            // onChange={(ev) => {
                                                            //     ev.target.value = !settingData.loanApplication.isThanhToanKhoanVayCu
                                                            //     handleChange(ev)
                                                            // }}
                                                            // type="checkbox"
                                                            name="isThanhToanKhoanVayCu" disabled />
                                                        <span className="slider" />
                                                    </label>
                                                    <div className="w-frame wbaseItem-value w-row 95d76814-e303-4d0d-8f48-da79fd479b53" wrap="nowrap" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 2f3445de-2608-4ab1-bafe-ece8898b2a96" level={12} cateid={139}>Khách hàng thực hiện thanh toán<br />khoản vay cũ</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-frame wbaseItem-value w-col d37adfa4-6b8d-4d9d-8d32-4998e802b2ba" wrap="nowrap" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row db89a21d-8ce5-452e-bae7-2280e0c98b75" wrap="nowrap" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value 5390338f-7a41-476c-9aa1-31f98c174f63" level={10} cateid={139}>Bảo hiểm</div>
                                                <div className="w-svg wbaseItem-value 95f9d142-1ad8-42a1-a59e-f459adea2e33" level={10} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M18.3899 15.7544L12 10.0693L5.61015 15.7544C5.24205 16.0819 4.65327 16.0819 4.28517 15.7544C3.90494 15.4161 3.90494 14.8584 4.28517 14.5201L11.3375 8.24562C11.7056 7.91813 12.2944 7.91813 12.6625 8.24562L19.7148 14.5201C20.0951 14.8584 20.0951 15.4161 19.7148 15.7544C19.3467 16.0819 18.7579 16.0819 18.3899 15.7544Z" fill="#28282999" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col 788298ad-5b34-4421-b53a-bb4df4eeaaff" wrap="nowrap" level={9} cateid={140}>
                                                {
                                                    settingData.loanApplication.loanApplicationInsurance?.length > 0 &&
                                                    settingData.loanApplication.loanApplicationInsurance.map((item, index) => {
                                                        return (
                                                            <div key={index} className="w-frame wbaseItem-value w-col 36d98ce1-e55f-47d4-84cc-b04fef34a122" wrap="nowrap" level={10} cateid={140}>
                                                                <div className="w-frame wbaseItem-value w-row 1a538676-8da8-4904-8521-143dc9ed9f67" wrap="nowrap" level={11} cateid={140}>
                                                                    <div className="w-text wbaseItem-value e1b90a8f-e38b-44d4-8de0-426583f95d83" level={12} cateid={139}>
                                                                        {item.loaiBaoHiem}
                                                                    </div>
                                                                </div>
                                                                <div className="w-frame wbaseItem-value w-row f77096d1-b927-411a-8781-6ae0bfc0b656" wrap="wrap" level={11} cateid={140}>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 9ce20ed8-58b5-4db8-8c8b-50a08eaf39ef" wrap="nowrap" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 9525b941-eb5c-428c-9a8f-0c0df2f7651d" level={13} cateid={139}>Nhà cung cấp:</div>
                                                                        <div className="w-text wbaseItem-value dcf6d4ba-4a0c-49b2-b7d1-b63fce29e7ae" level={13} cateid={139}>
                                                                            {item.nhaCungCap}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 b3b199b4-0d39-407f-92f4-ee49ea62475e" wrap="nowrap" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 4b6b3235-af15-4f96-8f48-22dd2e383e41" level={13} cateid={139}>Tên gói bảo hiểm:</div>
                                                                        <div className="w-text wbaseItem-value 55617230-ee12-4eaa-82f2-bcbb005b3dd5" level={13} cateid={139}>
                                                                            {item.tenBaoHiem}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 6f5c72e0-ea3f-4af7-88e1-c62bde540cf5" wrap="nowrap" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 688e2844-b308-4dcf-b7be-e458bfdcca44" level={13} cateid={139}>Hình thức thanh toán</div>
                                                                        <div className="w-text wbaseItem-value e0fbf380-896f-4406-a439-ea7da2c7cf23" level={13} cateid={139}>
                                                                            {item.hinhThucThanhToan}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 16db85a4-dc9b-4a41-adbb-d7c59b043612" wrap="nowrap" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 1aadf869-85c1-48e8-8fe6-27e43b8f8993" level={13} cateid={139}>Phí bảo hiểm tạm tính (VND):</div>
                                                                        <div className="w-text wbaseItem-value 89510f6f-7ebb-4cec-844b-32c157b4159c" level={13} cateid={139}>
                                                                            {Ultis.money(item.phiBaoHiem)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 e73432ff-65f3-4f15-9102-28f9675793e8" wrap="nowrap" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value e2875abe-e4b1-48ef-95cc-5ff7df43c5ff" level={13} cateid={139}>Chu kỳ bảo hiểm:</div>
                                                                        <div className="w-text wbaseItem-value a813a12d-d9e2-4515-b8b5-43d8d92d5da3" level={13} cateid={139}>
                                                                            {item.chuKyBaoHiem}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 61224421-d130-43c0-a640-a338314d537c" wrap="nowrap" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 8206f579-c85d-4220-856f-e1312f12b8bc" level={13} cateid={139}>Tỷ lệ bảo hiểm (%):</div>
                                                                        <div className="w-text wbaseItem-value dfe16b7c-2a8e-47ec-80f6-91fb5bc52a21" level={13} cateid={139}>
                                                                            {item.tyLeBaoHiem} %
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 ea903707-7619-4c8c-b8da-87fde05a7cd2" wrap="nowrap" level={12} cateid={140}>

                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 35abff1a-c4c8-42ed-9790-e3169fc813ef" wrap="nowrap" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 62173061-b743-42f2-a595-b8a21d6655e0" level={13} cateid={139}>Số tiền bảo hiểm tạm tính (VND):</div>
                                                                        <div className="w-text wbaseItem-value b83f87e3-426a-4679-bb00-8f1f82b6a3b3" level={13} cateid={139}>
                                                                            {Ultis.money(item.soTienBaoHiem)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <div className="w-frame wbaseItem-value w-row a157238d-c12e-40de-954b-33e209ac63ad" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 8d255667-bb72-4a59-a801-530a1a45383d" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value f2c1e82c-0ec5-4ef0-8d63-d0c6e7fe9928" level={11} cateid={139}>Tổng số tiền vay mua bảo hiểm:</div>
                                                    <div className="w-text wbaseItem-value c79d4384-7926-4be0-9e81-41c874bd1dd8" level={11} cateid={139}>
                                                        {Ultis.money(settingData.loanApplication.soTienBaoHiem)} VND
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-frame wbaseItem-value w-row 61107a14-ceac-430b-8718-c8c195a273d6" wrap="nowrap" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 1c4ff69f-f266-44dc-981e-939bed7f13ad" wrap="nowrap" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value 502b2841-1094-484c-b832-9a5259132142" level={10} cateid={139}>Tổng khoản vay:</div>
                                            </div>
                                            <div className="w-text wbaseItem-value e34fdfdd-67cd-4b12-b9da-879de1d4eb1f" level={9} cateid={139}>15.000.000 VND</div>
                                        </div>

                                        <div className="w-frame wbaseItem-value w-col 057a47bf-d47a-43ea-b0c4-b3f21e59b4f8" wrap="nowrap" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 874d620a-9454-4540-ba3c-f132512eebbb" wrap="nowrap" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value d96adaba-c6a5-43e2-a315-1b10513646bf" level={10} cateid={139}>Sản phẩm bán kèm</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row 56a9e09a-9e68-4b85-850a-e2f1227feca0" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 36ba408e-ac05-4637-84ad-e02724634c4c" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value c0f48c40-47f8-49da-a95c-f82f4880ed3a" level={11} cateid={139}>Loại thẻ:</div>
                                                    <div className="w-text wbaseItem-value cab7d163-355b-49aa-b875-1aa818d46a0b" level={11} cateid={139}>
                                                        {settingData.loanApplication.loaiTheDangKy}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 aa4a2444-d1e0-41c2-aaee-dbef587c5f11" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value db05738d-ac32-4ab5-9b58-81d76c52a04d" level={11} cateid={139}>Tên in trên thẻ:</div>
                                                    <div className="w-text wbaseItem-value 9f168c75-3ca5-4371-9f40-b3737ce4133c" level={11} cateid={139}>
                                                        {`${settingData.loanApplication.tenTheDangKy}`.toUpperCase()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            LayoutCongViec.ThongTinKhachHangBoSung(customerItem, settingData.loanApplication)
                                        }
                                        {/* {
                                        LayoutCongViec.CongViecKhachHang(settingData.loanApplication)
                                    } */}
                                        <div className="w-frame wbaseItem-value w-col a5591a9a-0d41-42f9-92ea-0b710c21286a" wrap="nowrap" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row b74966e4-409b-4180-b625-92ef7a25559c" wrap="nowrap" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value d929271c-9033-44b9-8083-40605cedb8ad" level={10} cateid={139}>Công việc khách hàng</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row e166651f-f3d9-43e5-a0bb-b75d8c78a65e" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 a03ca4ff-a7d3-4ce7-b9de-df2dad7e6976" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 536ce638-d93f-464b-9a46-6b93f7c07b93" level={11} cateid={139}>Nghề nghiệp:</div>
                                                    {
                                                        settingData.loanApplication.checkNgheNghiep === 1 ?
                                                            <div className={'select2-custom select2-bshs ' + (errors.maNgheNghiep && 'helper-text')} helper-text={errors.maNgheNghiep && 'Vui lòng chọn nghề nghiệp'}>
                                                                <Controller
                                                                    name="maNgheNghiep"
                                                                    control={control}
                                                                    {...register("maNgheNghiep")}
                                                                    style={{ order: 2 }}
                                                                    rules={{ required: true }}
                                                                    render={({ field }) => (
                                                                        <Select2 {...field}
                                                                            data={danhSachNgheNghiep.map((item, _) => { return { name: item.name, id: item.code } })}
                                                                            // value={settingData.maMucDichVay}
                                                                            options={{ placeholder: 'Chọn nghề nghệp' }}
                                                                        // name='maMucDichVay'
                                                                        // onChange={handleChange}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                            :
                                                            <div className="w-text wbaseItem-value 171cf531-8ce8-4124-a970-bcaae0cee579" level={11} cateid={139}>
                                                                {settingData.loanApplication.tenNgheNghiep ?? "_"}
                                                            </div>
                                                    }
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 9f6b65fc-3d77-48fd-9388-2f4ed7e64024" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value fb683699-a87e-4b60-a06c-f7a2b8245397" level={11} cateid={139}>Tên công ty/Nơi làm việc:</div>
                                                    <div className="w-text wbaseItem-value 9a6dc5e0-5b58-4887-8e6a-33f859383520" level={11} cateid={139}>
                                                        {settingData.loanApplication.noiLamViec ?? "_"}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 75146dc4-b9b6-4334-88e0-ef3d2b27b490" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 7038dda2-de92-4a4c-8f80-3605706614a7" level={11} cateid={139}>Địa chỉ nơi làm việc:</div>
                                                    <div className="w-text wbaseItem-value d334943f-d60d-4d78-ad5f-a11134070321" level={11} cateid={139}>
                                                        {settingData.loanApplication.dcNoiLamViec ?? "_"}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 ab008f69-d921-491c-845b-dbda627def8c" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 2ea43541-6967-40c5-960d-b1441c52fa35" level={11} cateid={139}>Chức vụ:</div>
                                                    <div className="w-text wbaseItem-value 0694ab29-0181-48a8-987d-f1dcf3c2dbaa" level={11} cateid={139}>
                                                        {settingData.loanApplication.tenChucVu ?? "_"}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 d8068a85-cb35-438f-850a-2a2556ace7f9" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 9e954fd7-bf16-4247-a313-b6ee1813d7b4" level={11} cateid={139}>Số điện thoại công ty:</div>
                                                    <div className="w-text wbaseItem-value 5d8c548c-5536-4c62-bd6e-2c8d0e2688a5" level={11} cateid={139}>
                                                        {settingData.loanApplication.dtNoiLamViec ?? "_"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {
                                        LayoutCongViec.TaiChinhKhachHang(settingData.loanApplication)
                                    } */}
                                        <div className="w-frame wbaseItem-value w-col c1e88fec-1137-434b-8e0a-07efb349c863" wrap="nowrap" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row b281f82e-6407-42ee-acfa-9bd59a3841c4" wrap="nowrap" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value e04521b5-4619-467b-8f33-8c2eb8502983" level={10} cateid={139}>Tài chính khách hàng</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row cba53c6c-f98d-4e76-9d16-ee78b3f9f646" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 08263607-663f-4b34-a703-1f695f7fb530" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 6bc157a5-77f2-470e-b607-5d219d47d415" level={11} cateid={139}>Thu nhập hàng tháng:</div>
                                                    {
                                                        settingData.loanApplication.checkThuNhapThang === 1 ?
                                                            <Controller
                                                                name='thuNhapThang'
                                                                control={control}
                                                                rules={{ required: true }}
                                                                render={({ field }) => (
                                                                    <div className={`custom-bosung w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 ${errors.thuNhapThang && 'helper-text'}`}
                                                                        helper-text={errors.thuNhapThang && "Vui lòng nhập thu nhập"}
                                                                        placeholder="Nhập thu nhập" wrap="nowrap" name-field="Status" level={8} cateid={85} style={{ order: 2 }}>
                                                                        <div className="wbaseItem-value 217b9f90-384d-4edc-845b-7db38fbdc754" name-field="Textfield" level={9} cateid={86}>
                                                                            <div className="textfield">
                                                                                <input {...field} placeholder="Nhập thu nhập"
                                                                                    onChange={(event) => {
                                                                                        field.onChange(event);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="w-text wbaseItem-value 672c0e54-75e0-45cb-b5c0-f912b0deadde" level={12} cateid={139}>
                                                                            VND
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            />
                                                            :
                                                            <div className="w-text wbaseItem-value 3acedae0-2fd6-49d9-abb7-619a928fa1c3" level={11} cateid={139}>
                                                                {Ultis.money(settingData.loanApplication.thuNhapThang)} VND
                                                            </div>
                                                    }
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 ecee5398-d62a-43e0-9702-dde6dd47e650" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value b7190dc0-c4c8-46e2-bd4b-37ec2b78b074" level={11} cateid={139}>Nguồn thu nhập khác:</div>
                                                    <div className="w-text wbaseItem-value 2654a24a-2b08-4dad-9957-6ea349da1457" level={11} cateid={139}>Nguồn 1</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 43f2f8ff-106a-4659-b8ae-1e2181e02c5c" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 9b5495b4-4b34-4ae7-89d7-fbce5454424c" level={11} cateid={139}>Thu nhập khác:</div>
                                                    <div className="w-text wbaseItem-value 18451a09-a629-4e6a-959a-6fded7423617" level={11} cateid={139}>
                                                        {Ultis.money(settingData.loanApplication.thuNhapKhac)} VND
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 fddf8956-9924-4fd1-bed3-7b38851bc786" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 5c2d4dad-1d55-4dc0-b124-8e6ebda62d2c" level={11} cateid={139}>Chi phí sinh hoạt hàng tháng:</div>
                                                    <div className="w-text wbaseItem-value 81598d8b-6dad-42c7-96ba-cba82a121d41" level={11} cateid={139}>
                                                        {Ultis.money(settingData.loanApplication.chiPhiSinhHoatHangThang)} VND
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 30452c7a-fdfb-469b-b759-131674e4a335" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 7d99bcf9-fc7f-4521-808d-5079d24a94c2" level={11} cateid={139}>Tổng thanh toán nợ vay hàng tháng:</div>
                                                    <div className="w-text wbaseItem-value cf28fc13-230a-4600-bb8a-d963edd51f32" level={11} cateid={139}>
                                                        {Ultis.money(settingData.loanApplication.tongThanhToanNoVayHangThang)} VND
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 a2544858-02f9-41db-a595-28ac264846e1" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 10ba07ab-e1f7-422c-a3d0-41a9f9f7796d" level={11} cateid={139}>Số người phụ thuộc:</div>
                                                    <div className="w-text wbaseItem-value 28d864ec-20c4-47fe-a054-f81742e65325" level={11} cateid={139}>
                                                        {settingData.loanApplication.soNguoiPhuThuoc}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 031fde9a-45a2-4c79-887f-7338e2a09eef" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 558f96f2-d109-4cba-bbe2-37bbe0359896" level={11} cateid={139}>Điểm DTI:</div>
                                                    <div className="w-text wbaseItem-value 392a1794-1caa-43d3-9d77-5fccfb7e66ee" level={11} cateid={139}>
                                                        {settingData.loanApplication.diemDTI}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 989b82cf-72de-4bc9-b480-f8535007007a" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value b57ac655-9e7e-4e98-96c3-576cf46ed1b2" level={11} cateid={139}>Kết quả DTI:</div>
                                                    <div className="w-text wbaseItem-value 867131c6-7580-4c31-bf3a-a7aba11ded3d" level={11} cateid={139}>
                                                        {settingData.loanApplication.ketQuaDTI}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            LayoutCongViec.ThongTinNguoiLienQuan(settingData.loanApplication)
                                        }

                                        <div className="w-frame wbaseItem-value w-col 44c0093e-ba76-478b-861d-4615affc8ecb" wrap="nowrap" level={8} cateid={140}>
                                            <div className="w-text wbaseItem-value 8f2d01d9-e474-483b-8c79-180b9b6f5a1f" level={9} cateid={139}>Hồ sơ đính kèm</div>
                                            <table className="w-table wbaseItem-value a370684d-d2f1-4a4b-b56e-85c1db3bb0f2" type={0} level={9} cateid={117}>
                                                <tbody>
                                                    <tr className="table-row">
                                                        <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 64a7262b-ec2e-471d-a064-d521e855833d" wrap="nowrap" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value c762f10d-e8d3-47d0-8e47-7a46d2f99efb" level={11} cateid={139}>Loại hồ sơ</div>
                                                            </div>
                                                        </td>
                                                        <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 9ca6b4dd-f44c-4c8b-9fa9-5013bd092b18" wrap="nowrap" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value b8cc57a3-f41a-49cc-8c66-c4c9f98fcb91" level={11} cateid={139}>Tệp đính kèm</div>
                                                            </div>
                                                        </td>
                                                        <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 3dcc17cd-cee4-4546-989d-0e5190f2c1fa" wrap="nowrap" level={10} cateid={140} />
                                                        </td>
                                                        <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 656a436f-2ac0-4c7d-a991-527e4b150e3a" wrap="nowrap" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value c0bc3ebf-f888-4a4a-97e2-f96c699f6063" level={11} cateid={139}>Người upload</div>
                                                            </div>
                                                        </td>
                                                        <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row b22b3c06-a086-4320-b739-05fa04d2f67c" wrap="nowrap" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value bde5fae2-fb2f-4141-99fc-d68118cf881b" level={11} cateid={139}>Thời gian upload</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        fileHoSo?.length > 0 &&
                                                        fileHoSo.map((item, index) => {
                                                            return (
                                                                item.isNew !== true ?
                                                                    <tr key={index} className={`table-row ${item.danhGiaThamDinh === 1 ? "file-bosung" : ""}`}>
                                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                                            <div className="w-frame wbaseItem-value w-row 2165c0ac-ebb6-41de-9bc6-81e69ece7955" wrap="nowrap" level={10} cateid={140}>
                                                                                <div className="w-text wbaseItem-value 38ad85d0-fbca-4a82-9d9a-0ce27f0480fc" level={11} cateid={139}>
                                                                                    {item.tenFileCtv ?? "_"}
                                                                                    {/* {loanProgramItem?.length > 0 ? loanProgramItem.find((e) => e.maFileCtv === item.maFileCtv)?.documentName ?? "" : "data chưa trả về dữ liệu"} */}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                                            <div className="w-frame wbaseItem-value w-row 4ae3a3fe-17f0-4e28-93db-989f157a6ae9" wrap="nowrap" level={10} cateid={140}>
                                                                                <div className="w-text wbaseItem-value 7c9ecb1d-48d6-4212-9f0b-2eebf8d09222" level={11} cateid={139}>
                                                                                    {item.ten}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td id="3x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                                            <div className="w-frame wbaseItem-value w-row 8889126c-236b-46c9-867f-72b06c5050fa" wrap="nowrap" level={10} cateid={140}>
                                                                                {
                                                                                    item.danhGiaThamDinh === 1 ?
                                                                                        <>
                                                                                            <div className="w-svg wbaseItem-value ef98762f-25d1-4681-8cc2-afd27ab9c7b6" level={11} cateid={115}>
                                                                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M7 10.5L12 16.3333L17 10.5H12.8333V3H11.1667V10.5H7Z" fill="#009944FF" fillOpacity="0.6" />
                                                                                                    <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#009944FF" fillOpacity="0.6" />
                                                                                                </svg>
                                                                                            </div>
                                                                                            <div className="w-svg wbaseItem-value 507ca567-8532-42c6-943f-1dbc38eeef66" level={11} cateid={115}>
                                                                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#E60012FF" />
                                                                                                </svg>
                                                                                            </div>
                                                                                        </> : <></>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                                            <div className="w-frame wbaseItem-value w-row a14ddabc-c3de-40c9-b769-8407f6fe4b9a" wrap="nowrap" level={10} cateid={140}>
                                                                                <div className="w-text wbaseItem-value 75402b82-ef12-4e44-af9f-84c09e0dc05e" level={11} cateid={139}>
                                                                                    {item.maNguoiTao ?? "_"} - {item.tenNguoiTao ?? "_"}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                                            <div className="w-frame wbaseItem-value w-row 299f46bf-68f7-42d2-9006-98af03a7631c" wrap="nowrap" level={10} cateid={140}>
                                                                                <div className="w-text wbaseItem-value b3f44db4-a3ee-4fc9-b546-aed957f4ca77" level={11} cateid={139}>
                                                                                    {item.dateCreate ? new Date(item.dateCreate).toLocaleDateString("en-GB") : "_"}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    :
                                                                    FileItem({ item: item, index: index })
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                            <div className="w-frame wbaseItem-value w-row 82412dfb-ac59-4c9e-9adb-4bcb6064a15a" wrap="nowrap" level={9} cateid={140}>
                                                <button onClick={addFileHoSo} type="button" className="w-button wbaseItem-value w-row 027a5517-38b0-474d-b9a5-9acd8022e548" wrap="nowrap" name-field="Button" level={10} cateid={29}>
                                                    <div className="w-svg wbaseItem-value 86c01e94-7829-42ba-b4ff-3696902de401" level={11} cateid={115}>
                                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z" fill="#FFFFFFFF" />
                                                        </svg>
                                                    </div>
                                                    <div className="w-text wbaseItem-value 45cebdf5-680b-4db0-903f-1546698d3acb" name-field="Text" level={11} cateid={139}>Thêm hồ sơ bổ sung<br />
                                                    </div>
                                                </button>
                                                <div className="w-frame wbaseItem-value w-row 389b943b-16d0-4bb5-b92c-bb41e9ef62d8" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-svg wbaseItem-value 8b60a96d-ced6-45c6-b6b4-81e623c0b6f6" level={11} cateid={115}>
                                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9971 9.34871 20.9426 6.80684 19.0679 4.9321C17.1932 3.05736 14.6513 2.00287 12 2V2ZM12.8567 17.6033H11.1358V10.3333H12.8567V17.6033ZM12.7142 8.04917C12.6192 8.13618 12.5077 8.20317 12.3863 8.24612C12.2649 8.28908 12.136 8.30712 12.0075 8.29917C11.8766 8.30787 11.7453 8.29023 11.6213 8.24729C11.4974 8.20435 11.3833 8.13698 11.2858 8.04917C11.1997 7.95647 11.1332 7.84737 11.0903 7.72839C11.0473 7.6094 11.0288 7.48297 11.0358 7.35667C11.0271 7.22742 11.0448 7.09775 11.0878 6.97555C11.1307 6.85335 11.1981 6.74117 11.2858 6.64583C11.3836 6.55841 11.4977 6.49129 11.6216 6.44837C11.7455 6.40545 11.8766 6.38759 12.0075 6.39583C12.136 6.38836 12.2647 6.40662 12.3861 6.44955C12.5074 6.49248 12.619 6.55921 12.7142 6.64583C12.8019 6.74117 12.8693 6.85335 12.9122 6.97555C12.9552 7.09775 12.9729 7.22742 12.9642 7.35667C12.9712 7.48297 12.9527 7.6094 12.9097 7.72839C12.8668 7.84737 12.8003 7.95647 12.7142 8.04917V8.04917Z"
                                                                fill="#367BE2FF" />
                                                        </svg>
                                                    </div>
                                                    <div className="w-text wbaseItem-value e1868d86-46e4-4c68-a0de-04e17d6e7b8b" level={11} cateid={139}>File upload có định dạng word, excel, .jpg, .jpeg, .png, .pdf và không vượt quá 5MB</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-frame wbaseItem-value w-col ace49407-b0b7-46aa-9677-634b64891b68" wrap="nowrap" level={8} cateid={140}>
                                            <div className="w-text wbaseItem-value 3c58aec5-3b14-48e8-a736-560618a485f0" level={9} cateid={139}>Ghi chú</div>

                                            <div className="w-frame wbaseItem-value w-col d4335d24-b36f-4a8b-95e0-b05b293e9580" wrap="nowrap" level={9} cateid={140}>
                                                {
                                                    settingData.loanApplication.noteLoanApplication?.length > 0 &&
                                                    settingData.loanApplication.noteLoanApplication.map((item, index) => {
                                                        return (
                                                            <div key={index} className="w-frame wbaseItem-value w-col 03b674fa-876a-4809-9d64-499328d488eb" wrap="nowrap" level={10} cateid={140}>
                                                                <div className="w-frame wbaseItem-value w-row 105a5306-c1b2-4111-aeeb-b95abe2b739e" wrap="nowrap" level={11} cateid={140}>
                                                                    <div className="w-frame wbaseItem-value w-row a887f9a4-5d0e-40f9-930e-68e76f0d9684" wrap="nowrap" level={12} cateid={140}>
                                                                        <div className="w-svg wbaseItem-value 49ae493c-4b58-47b0-89aa-c9901d1f6ac5" level={13} cateid={115}>
                                                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M16.9997 7C16.9997 9.76142 14.7611 12 11.9997 12C9.23826 12 6.99968 9.76142 6.99968 7C6.99968 4.23858 9.23826 2 11.9997 2C14.7611 2 16.9997 4.23858 16.9997 7Z" fill="#28282999" />
                                                                                <path d="M3.66667 19.222C3.66667 17.177 6.375 13.667 12 13.667C17.625 13.667 20.3333 17.177 20.3333 19.222V20.3337C20.3333 20.7757 20.1577 21.1996 19.8452 21.5122C19.5326 21.8247 19.1087 22.0003 18.6667 22.0003H5.33334C4.89131 22.0003 4.46739 21.8247 4.15483 21.5122C3.84227 21.1996 3.66667 20.7757 3.66667 20.3337V19.222Z" fill="#28282999" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className="w-text wbaseItem-value 096f9ae1-00d5-4e07-972c-2dddc228113f" level={13} cateid={139}>
                                                                            {item.maNguoiTao} - {item.tenNguoiTao}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-row 2c1a4494-06f3-439f-ba4a-88818b09ed4c" wrap="nowrap" level={12} cateid={140}>
                                                                        <div className="w-svg wbaseItem-value 64b9b9f4-4c9b-4a33-87ad-9c32569d7e00" level={13} cateid={115}>
                                                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M6.16667 11.1667C5.70643 11.1667 5.33333 11.5398 5.33333 12V12.8333C5.33333 13.2936 5.70643 13.6667 6.16667 13.6667H7.83333C8.29357 13.6667 8.66667 13.2936 8.66667 12.8333V12C8.66667 11.5398 8.29357 11.1667 7.83333 11.1667H6.16667Z" fill="#28282999" />
                                                                                <path d="M11.1667 11.1667C10.7064 11.1667 10.3333 11.5398 10.3333 12V12.8333C10.3333 13.2936 10.7064 13.6667 11.1667 13.6667H12.8333C13.2936 13.6667 13.6667 13.2936 13.6667 12.8333V12C13.6667 11.5398 13.2936 11.1667 12.8333 11.1667H11.1667Z" fill="#28282999" />
                                                                                <path d="M5.33333 16.1667C5.33333 15.7064 5.70643 15.3333 6.16667 15.3333H7.83333C8.29357 15.3333 8.66667 15.7064 8.66667 16.1667V17C8.66667 17.4602 8.29357 17.8333 7.83333 17.8333H6.16667C5.70643 17.8333 5.33333 17.4602 5.33333 17V16.1667Z" fill="#28282999" />
                                                                                <path d="M11.1667 15.3333C10.7064 15.3333 10.3333 15.7064 10.3333 16.1667V17C10.3333 17.4602 10.7064 17.8333 11.1667 17.8333H12.8333C13.2936 17.8333 13.6667 17.4602 13.6667 17V16.1667C13.6667 15.7064 13.2936 15.3333 12.8333 15.3333H11.1667Z" fill="#28282999" />
                                                                                <path d="M15.3333 12C15.3333 11.5398 15.7064 11.1667 16.1667 11.1667H17.8333C18.2936 11.1667 18.6667 11.5398 18.6667 12V12.8333C18.6667 13.2936 18.2936 13.6667 17.8333 13.6667H16.1667C15.7064 13.6667 15.3333 13.2936 15.3333 12.8333V12Z" fill="#28282999" />
                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.83333 2.83333C7.83333 2.3731 7.46024 2 7 2C6.53976 2 6.16667 2.3731 6.16667 2.83333V3.66667H4.5C3.11929 3.66667 2 4.78595 2 6.16667V18.6667C2 20.0474 3.11929 21.1667 4.5 21.1667H19.5C20.8807 21.1667 22 20.0474 22 18.6667V6.16667C22 4.78595 20.8807 3.66667 19.5 3.66667H17.8333V2.83333C17.8333 2.3731 17.4602 2 17 2C16.5398 2 16.1667 2.3731 16.1667 2.83333V3.66667H7.83333V2.83333ZM4.5 5.33333C4.03976 5.33333 3.66667 5.70643 3.66667 6.16667V7.83333H20.3333V6.16667C20.3333 5.70643 19.9602 5.33333 19.5 5.33333H17.8333C17.8333 5.79357 17.4602 6.16667 17 6.16667C16.5398 6.16667 16.1667 5.79357 16.1667 5.33333H7.83333C7.83333 5.79357 7.46024 6.16667 7 6.16667C6.53976 6.16667 6.16667 5.79357 6.16667 5.33333H4.5ZM4.5 19.5C4.03976 19.5 3.66667 19.1269 3.66667 18.6667V9.5H20.3333V18.6667C20.3333 19.1269 19.9602 19.5 19.5 19.5H4.5Z" fill="#28282999" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className="w-text wbaseItem-value 40ca03f9-4140-4fb9-af50-9a170d3532cb" level={13} cateid={139}>
                                                                            {item.createdDate}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-text wbaseItem-value 67ad9829-c0c9-41bc-a9a8-723a678a4751" level={11} cateid={139}>
                                                                    {item.noiDung}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                            <div className="w-frame wbaseItem-value w-col bbadb990-72cf-4cfe-9e3f-57ebc68e1bbd" wrap="nowrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col 3b296872-71b0-45bf-b5d8-1aca8ef5df8d" wrap="nowrap" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 8e419b1e-2d34-4316-8d70-b4be406c32c2" wrap="nowrap" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value cb1a02e7-93f2-4f05-a002-8a0395b903c4" level={12} cateid={139}>Ghi chú<br />
                                                        </div>
                                                        <div className="w-text wbaseItem-value b8dd5133-a99f-4fc2-b278-ece5edf45d2a" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {/* <div className="w-textformfield wbaseItem-value w-row 7200b924-8866-4628-b490-d3efb386d10e" wrap="nowrap" name-field="Textformfield" level={11} cateid={85} placeholder="Thêm ghi chú">
                                                        <textarea
                                                            style={{ resize: "none", width: "100%", height: "100%", border: 0, outline: 0, fontSize: "14px" }}
                                                            placeholder="Thêm ghi chú"
                                                        >
                                                        </textarea>
                                                    </div> */}
                                                    <div
                                                        className={`w-textformfield wbaseItem-value w-row 7200b924-8866-4628-b490-d3efb386d10e ${errorNote ? "validate-note-helper-text" : ""}`} wrap="nowrap"
                                                        name-field="Textformfield" level={11} cateid={85}
                                                        placeholder="Thêm ghi chú"
                                                    >
                                                        <textarea
                                                            // name="noiDung"
                                                            // {...register("noiDung")} rules={{ required: true }}
                                                            value={newNote}
                                                            style={{ resize: "none", width: "100%", height: "100%", border: 0, outline: 0, fontSize: "14px" }}
                                                            placeholder="Thêm ghi chú"
                                                            onChange={(ev) => {
                                                                setNewNote(ev.target.value);
                                                                if (ev.target.value.length > 0) {
                                                                    setErrorNote(false);
                                                                } else {
                                                                    setErrorNote(true);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 889608b7-30bb-43a2-93ec-2afc12ead84e" wrap="nowrap" level={10} cateid={140}>
                                                    <label
                                                        onClick={(ev) => {
                                                            setNghiNgoLuaDao(!nghiNgoLuaDao);
                                                            setSettingData({ ...settingData, "nghiNgoLuaDao": nghiNgoLuaDao === true ? 1 : 0 });
                                                        }}
                                                        htmlFor="input-a7fd1ccb-c394-4227-b84c-b5d3d5cd161e" className="w-switch wbaseItem-value a7fd1ccb-c394-4227-b84c-b5d3d5cd161e" value="true" name-field="Switch" level={11} cateid={81}>
                                                        <input checked={nghiNgoLuaDao} type="checkbox" name="Switch" disabled />
                                                        <span className="slider" />
                                                    </label>
                                                    <div className="w-text wbaseItem-value 5ff54020-1d07-454f-a39b-39024ee6a0d9" level={11} cateid={139}>*</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="w-frame wbaseItem-value w-col d469a654-5a99-4303-9082-5b765e3c047b" wrap="nowrap" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row b97b9ca5-fe18-427d-b05c-ff8bfa2e9c43" wrap="nowrap" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value f40bb5b9-0a3e-4aba-89c3-1db1a80c33e8" level={10} cateid={139}>Yêu cầu bổ sung/chỉnh sửa của thẩm định</div>
                                            </div>
                                            <div className="w-text wbaseItem-value 9c1cb3e7-28f6-4a3f-81b7-925dd815c072" level={9} cateid={139}>Bổ sung đầy đủ thông tin cá nhân, thông tin người thân. Bổ sung hồ sơ đính kèm.</div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row 89f61bb4-b459-43b6-985d-379c8581b739" wrap="nowrap" level={3} cateid={140}>
                        <button type="button" className="w-button wbaseItem-value w-row 2a1c98ea-0352-4ed8-a22f-3228df49bf6d" wrap="nowrap" name-field="Button" level={4} cateid={29}>
                            <div className="w-text wbaseItem-value bf71d2fe-fdaf-47ae-beb1-2503dce35ebf" name-field="Text" level={5} cateid={139}>Hủy</div>
                        </button>
                        <div className="w-frame wbaseItem-value w-row 49667bde-41d7-4091-a4b0-ec96b39e86e8" wrap="nowrap" level={4} cateid={140}>
                            <button type="button" onClick={handleSaveForm} className="w-button wbaseItem-value w-row ce2ef89c-8a22-4bc7-8d01-b295037fc89f" wrap="nowrap" name-field="Button" level={5} cateid={29}>
                                <div className="w-svg wbaseItem-value 22ad87d8-d876-4a1d-9f23-8218598b9e4c" level={6} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.1665 2H6.49997C5.83694 2 5.20106 2.26339 4.73223 2.73223C4.26339 3.20106 4 3.83694 4 4.49997V21.5831C3.99996 21.6577 4.01993 21.7309 4.05783 21.7951C4.09572 21.8593 4.15015 21.9122 4.21545 21.9482C4.28074 21.9842 4.35449 22.002 4.42902 21.9998C4.50355 21.9976 4.57612 21.9754 4.63916 21.9356L12.3332 17.0765L20.0273 21.9356C20.0938 21.9779 20.171 22.0002 20.2498 21.9998C20.3603 21.9998 20.4663 21.9559 20.5445 21.8777C20.6226 21.7996 20.6665 21.6936 20.6665 21.5831V4.49997C20.6665 3.83694 20.4031 3.20106 19.9343 2.73223C19.4654 2.26339 18.8295 2 18.1665 2V2Z" fill="#28282999" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value 731609fb-1966-41c9-a151-219c99983bd1" name-field="Text" level={6} cateid={139}>Lưu</div>
                            </button>
                            <button type="submit" className="w-button wbaseItem-value w-row 666ef899-ee03-4f63-b873-5742a79c1d11" wrap="nowrap" name-field="Button" level={5} cateid={29}>
                                <div className="w-svg wbaseItem-value 2f04e36b-c229-4ce8-aa49-8e77feeb2ba4" level={6} cateid={115}>
                                    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.4893 3.5115C14.726 3.74818 14.726 4.13191 14.4893 4.36859L6.61053 12.2474C6.37385 12.4841 5.99011 12.4841 5.75343 12.2474L1.51101 8.00496C1.27433 7.76828 1.27433 7.38454 1.51101 7.14786C1.74769 6.91118 2.13142 6.91118 2.36811 7.14786L6.18198 10.9617L13.6322 3.5115C13.8689 3.27481 14.2526 3.27481 14.4893 3.5115Z" fill="white" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value 7779ebee-3acb-4a6c-86b4-06c01bb3e3e7" name-field="Text" level={6} cateid={139}>Gửi</div>
                            </button>
                        </div>
                    </div>
                </form >
            </>
        ) : <div></div>
    )
}