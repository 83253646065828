import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GiaiNganDA, getTaskById, getTaskDetail } from '../../../redux/middleware/api/congvieccuatoi/CongViecDA';
import ChuongTrinhVayDA from '../../../redux/middleware/api/W_ChuongTrinhVay/ChuongTrinhVayDA';
import Select2 from '../../../components/select2/Select2';
import './YeuCauGiaiNgan.css';
import { Ultis } from '../../../core/utils';
import { useRef } from 'react';
import { FileStatus } from '../../../const/file-status';
import { PopupType, WPopup } from '../../../components/Popup/Popup';
import PopupXemQuyTrinh from '../ThamDinh-XuLy/PopupXemQuyTrinh';
import NotificationAlert from "react-notification-alert";
import { ERROR, notify } from '../../../core/AlertNoti';

export default function ModuleGiaiNgan() {
    const notificationAlert = useRef();
    // eslint-disable-next-line no-unused-vars
    const { handleSubmit, setValue, formState: { errors } } = useForm({ shouldFocusError: false });

    const location = useLocation();

    const [data, setData] = useState();

    const [settingData, setSettingData] = useState();
    const [listFileType, setListFileType] = useState([]);

    const [showProcess, onShowProcess] = useState(false);
    const [newNote, setNewNote] = useState("");

    const [thoatUi, setThoatUI] = useState(false);
    const [isDinhDangFile, setDinhDangFile] = useState(false);
    const [isKichThuocFile, setKichThuocFile] = useState(false);

    const [checkDocType, setCheckDocType] = useState(false);

    const subId = location.search.replace("?", "");

    const [actionXuLy, setActionXuLy] = useState(1);
    const [maAction, setMaAction] = useState("ADDITION");
    const [maTuChoi, setMaTuChoi] = useState("");

    const [base64ImageData, setBase64ImageData] = useState();

    const getContractInfo = async () => {
        let res = await getTaskDetail({ id: subId });
        if (res) {
            setData(res);
            const _settingData = res['process-instance-variables']?.loanApplication;
            if (_settingData != null) {
                setNewNote(_settingData?.saleGhiChuGiaiNganNhap)
                let resCodeList = await ChuongTrinhVayDA.getDetailLoanProgramByCode(1, 100, [_settingData?.maChuongTrinhVay]);
                if (resCodeList != null) {
                    setListFileType(resCodeList.pageData[0].documentDisbursementDetails);
                } else {
                    setListFileType([]);
                }

                await setSelected(_settingData?.id)

                Object.keys(_settingData).forEach(fieldName => {
                    setValue(fieldName, _settingData[fieldName]);
                });
                if (_settingData.disbursementDocuments.length === 0 && _settingData?.maTrangThaiHoSo !== FileStatus.XU_LY_GIAI_NGAN_CODE) {
                    res['process-instance-variables'].loanApplication.disbursementDocuments = [{
                        "createdDate": "",
                        "createdBy": "",
                        "deleted": 0,
                        "filename": "",
                        "docType": "",
                        "fileType": "",
                        "loanApplicationId": _settingData?.id
                    }]
                }
                setSettingData(res['process-instance-variables']);
                if (_settingData.maTrangThaiHoSo === FileStatus.XU_LY_GIAI_NGAN_CODE) {
                    getDocFileReview(res['process-instance-variables'], 0)
                }
            }
        }
    }

    const getDocFileReview = async (settingData, index) => {
        if (settingData?.loanApplication?.disbursementDocuments?.length > 0) {
            let resDoc = await GiaiNganDA.getDocumentFile(settingData?.loanApplication?.disbursementDocuments[index].id);

            setBase64ImageData(resDoc)
            // setBase64ImageData(base64toBlob(resDoc))
        }
    }
    const [taskSelected, setTaskSelected] = useState({})

    const setSelected = async (loanId) => {
        let selected = await getTaskById(loanId);
        setTaskSelected(selected);
    }

    const fileInputRefs = useRef();

    const allowedExtensions = ["docx", "doc", "pdf", "jpg", "jpeg", "xlsx", "png"]
    const handleFileInputChange = async (event, index) => {
        const file = event.target.files[index];
        if (file != null) {
            //TODO: check type file, check dung luong 5mb
            if (file.size > 5 * 1024 * 1024) {
                setKichThuocFile(true);
                return;
            }
            let _listDot = file.name.split(".");
            if (!allowedExtensions.includes(_listDot[_listDot.length - 1])) {
                setDinhDangFile(true)
                return;
            }
            let formDataFile = new FormData();
            formDataFile.append('document', file);

            let _disbursementDocuments = settingData?.loanApplication?.disbursementDocuments[0];

            let res = await GiaiNganDA.uploadFile(settingData?.loanApplication?.id, _disbursementDocuments.docType, formDataFile);
            debugger
            if (res.code === "200") {
                _disbursementDocuments.id = res.data.id;
                _disbursementDocuments.filename = res.data.filename;
                _disbursementDocuments.createdDate = res.data.createdDate;
                _disbursementDocuments.createdBy = res.data.createdBy;
                _disbursementDocuments.docType = res.data.docType;
                _disbursementDocuments.createdBy = res.data.createdBy;
                _disbursementDocuments.fileType = res.data.fileType;
                debugger
                let newData = { ...settingData };
                if (newData.loanApplication.disbursementDocuments?.length > 0) {
                    newData.loanApplication.disbursementDocuments[0] = _disbursementDocuments;
                }
                else {
                    newData.loanApplication.disbursementDocuments.push(_disbursementDocuments)
                }
                setSettingData(newData);
            }
        }
    };

    useEffect(() => {
        getContractInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = () => {
        handleSend();
    };
    const getDataCallApi = (action) => {
        let _data;
        if (settingData?.loanApplication?.maTrangThaiHoSo !== FileStatus.XU_LY_GIAI_NGAN_CODE) {
            _data = {
                "disbursementDto": {
                    "fis.onboarding.process.model.jlos.dto.DisbursementDTO": {
                        "saleGhiChuGiaiNgan": newNote,
                        "action": action,
                        "documentIds": settingData?.loanApplication?.disbursementDocuments[0].id !== undefined ? [settingData?.loanApplication?.disbursementDocuments[0].id] : []
                    }
                }
            }
        } else {
            _data = {
                "disbursementDto": {
                    "fis.onboarding.process.model.jlos.dto.DisbursementDTO": {
                        "rlaGhiChuYeuCauBoSungGiaiNgan": newNote,
                        "rlaGhiChuLyDoTuChoi": newNote,
                        "maLyDoTuChoi": maTuChoi,
                        "action": action
                    }
                }
            }
        }
        return _data;
    }
    const handleSave = async () => {
        let action = settingData?.loanApplication?.maTrangThaiHoSo === FileStatus.XU_LY_GIAI_NGAN_CODE ?
            "HANDLE" :
            settingData?.loanApplication?.maTrangThaiHoSo === FileStatus.YEU_CAU_GIAI_NGAN_CODE ?
                "REQUEST" :
                "ADDITION"
        let dataCallApi = getDataCallApi(action);
        const res = await GiaiNganDA.updateData(data['active-user-tasks']['task-summary'][0]['task-id'], dataCallApi);
        if (res.status === 201) {
            navigate('/admin/ho-so-cho-xu-ly');
        } else {
            notify(notificationAlert, ERROR, res?.message ?? res)
        }
    }

    const handleSend = async () => {
        let action = settingData?.loanApplication?.maTrangThaiHoSo === FileStatus.XU_LY_GIAI_NGAN_CODE ?
            maAction : "HANDLE";
        let dataCallApi = getDataCallApi(action);
        const res = await GiaiNganDA.updateData(data['active-user-tasks']['task-summary'][0]['task-id'], dataCallApi);
        if (res.status === 201) {
            navigate('/admin/ho-so-cho-xu-ly');
        } else {
            notify(notificationAlert, ERROR, res?.message ?? res)
        }
    }


    const getTenModule = (code) => {
        switch (code) {
            case FileStatus.BO_SUNG_GIAI_NGAN_CODE:
                return "Bổ sung Giải ngân";
            case FileStatus.YEU_CAU_GIAI_NGAN_CODE:
                return "Yêu cầu Giải ngân";
            case FileStatus.XU_LY_GIAI_NGAN_CODE:
                return "Xử lý Giải ngân";
            default:
        }
    }

    const navigate = useNavigate();

    return (
        data ?
            <>
                {
                    thoatUi &&
                    <WPopup
                        type={PopupType.WARNING}
                        title={"Hủy bổ sung hồ sơ"}
                        message="Các thông tin đã nhập sẽ không được lưu lại"
                        cancelTitle="Tiếp tục nhập"
                        confirmTitle='Xác nhận'
                        cancelAction={() => setThoatUI(false)}
                        confirmAction={() => { navigate('/admin/ho-so-cho-xu-ly') }}
                        cancelButton={true}
                    />
                }
                {
                    isKichThuocFile &&
                    <WPopup
                        type={PopupType.WARNING}
                        title={"Tệp tải lên quá lớn"}
                        message={<><span>Dung lượng tối đa cho phép 5MB.</span><span>Vui lòng kiểm tra lại.</span></>}
                        cancelTitle="Đóng"
                        cancelAction={() => setKichThuocFile(false)}
                        cancelButton={true}
                        confirmButton={false}
                    />
                }
                {
                    isDinhDangFile &&
                    <WPopup
                        type={PopupType.WARNING}
                        title={"Tệp tải lên chưa đúng định dạng"}
                        message={<><span>Định dạng file cho phép</span> <span>.doc, .docx,.xls, .xlsx, .jpg, .jpeg, .png, .pdf. </span><span>Vui lòng kiểm tra lại.</span></>}
                        cancelTitle="Đóng"
                        cancelAction={() => setDinhDangFile(false)}
                        cancelButton={true}
                        confirmButton={false}
                    />
                }
                {
                    showProcess &&
                    <WPopup
                        popupContent={
                            <PopupXemQuyTrinh
                                closePopup={() => onShowProcess(false)}
                                code={subId}
                                loanApplicationId={settingData?.loanApplication?.id}
                            />
                        }
                    />
                }
                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' className="w-frame w-form wbaseItem-value w-col e0edc87d-6071-4b88-a6e7-79e0e1cfdd2a" level={2} cateid={128}>
                    <NotificationAlert ref={notificationAlert} />
                    <div className="w-frame wbaseItem-value w-col a05a1728-2730-4729-a388-3d2f50e35c56" scroll="true" level={3} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col 771880fd-4b1e-4be4-a596-27798c32b5a4" level={4} cateid={140}>

                            <div className="w-frame wbaseItem-value w-row cec23bc7-317f-4d8d-a583-1fb0cc49fa62" level={5} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col 654f9674-8c01-44bb-925f-d0ba575cdb05" level={6} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row ff0659b8-ceab-4164-bbe2-0fa69d1b53d5" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value f01903c9-093c-439e-8e91-7b0d8cb7171a" level={8} cateid={139}>
                                            {getTenModule(settingData?.loanApplication?.maTrangThaiHoSo)}
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row breadcrumb ad99a232-e3a7-4e7f-9c91-0656562c5114" level={7} cateid={140}>
                                        <button type="button" className="w-button wbaseItem-value w-row c10fc5a8-75f6-44f0-9b07-5327ddac360a" level={8} cateid={29}>
                                            <div className="w-text wbaseItem-value ffe0bc66-e743-4267-b1b6-aa96b12ccf2e" level={9} cateid={139}>Hồ sơ chờ xử lý</div>
                                            <div className="w-svg wbaseItem-value ea8157a7-fcfa-4375-9774-cdcd7fc4d67d" level={9} cateid={115}>
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6" />
                                                </svg>
                                            </div>
                                        </button>
                                        <div className="w-text wbaseItem-value 262a6a11-48ce-43aa-9489-d8c3f7f4b6b9" level={8} cateid={139}>
                                            {settingData?.loanApplication?.id}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-frame wbaseItem-value w-row cf567528-9b07-45ef-bca7-e7ddc7f55737" level={6} cateid={140}>
                                    <button onClick={() => onShowProcess(true)} type="button" className="w-button wbaseItem-value w-row 23b1c672-10fc-44ab-98c4-606e5047d200" level={7} cateid={29}>
                                        <div className="w-svg wbaseItem-value 1bfbd5ff-9310-4676-a056-ce6b31c4f5e6" level={8} cateid={115}>
                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 5.33333V7H5.6V12H8V13.6667H5.6V18.6667H8V20.3333H4.8C4.3584 20.3333 4 19.96 4 19.5V2.83333C4 2.37333 4.3584 2 4.8 2C5.2416 2 5.6 2.37333 5.6 2.83333V5.33333H8Z" fill="#009944FF" />
                                                <path d="M10.3996 8.66699H19.1996C19.6412 8.66699 19.9996 8.29366 19.9996 7.83366V4.50033C19.9996 4.04033 19.6412 3.66699 19.1996 3.66699H10.3996C9.95801 3.66699 9.59961 4.04033 9.59961 4.50033V7.83366C9.59961 8.29366 9.95801 8.66699 10.3996 8.66699Z" fill="#009944FF" />
                                                <path d="M19.1996 22H10.3996C9.95801 22 9.59961 21.6275 9.59961 21.1667V17.8333C9.59961 17.3725 9.95801 17 10.3996 17H19.1996C19.6412 17 19.9996 17.3725 19.9996 17.8333V21.1667C19.9996 21.6275 19.6412 22 19.1996 22Z" fill="#009944FF" />
                                                <path d="M10.3996 15.333H19.1996C19.6412 15.333 19.9996 14.9605 19.9996 14.4997V11.1663C19.9996 10.7063 19.6412 10.333 19.1996 10.333H10.3996C9.95801 10.333 9.59961 10.7063 9.59961 11.1663V14.4997C9.59961 14.9605 9.95801 15.333 10.3996 15.333Z" fill="#009944FF" />
                                            </svg>
                                        </div>
                                        <div className="w-text wbaseItem-value c570a179-8a0b-4546-aae8-a98dd3c703bf" level={8} cateid={139}>Xem quy trình</div>
                                    </button>
                                </div>
                            </div>

                            <div className='w-frame wbaseItem-value w-col' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                                <div className="w-frame wbaseItem-value w-col 070d1f1f-51f2-4f1b-8cff-84b920414d72" level={5} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 50408b9c-fd11-4c86-8bf3-42b076084005" wrap="wrap" level={6} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md 16db5c77-a4e5-462e-ac95-7e79d0ebc15a" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 613c69e7-1adc-49bd-b5fc-2abfd6d6736b" level={8} cateid={139}>Trạng thái:</div>
                                            <div className="w-text wbaseItem-value d155c9ed-8c88-43bf-a8d0-03293822b4da" level={8} cateid={139}>
                                                {getTenModule(settingData?.loanApplication?.maTrangThaiHoSo)}
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md d3fdec50-a448-47a0-9db0-fa504d0c0da7" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value d40a1109-22ab-471d-b336-b12d67392f9c" level={8} cateid={139}>Người yêu cầu:</div>
                                            <div className="w-text wbaseItem-value 31dec71a-9523-4de8-8703-97f7094dfee6" level={8} cateid={139}>
                                                {taskSelected?.nguoiYeuCau ?? taskSelected?.createdBy}
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md 6c0aac30-7252-4008-907d-c747d5296e77" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 3edc8bf8-6cbf-4706-bc3f-da1ca8fd7e99" level={8} cateid={139}>Người xử lý:</div>
                                            <div className="w-text wbaseItem-value 2a626b95-1241-4e43-9753-dff8a2508226" level={8} cateid={139}>
                                                {/* {
                                                    settingData?.loanApplication?.maTrangThaiHoSo !== FileStatus.XU_LY_GIAI_NGAN_CODE ?
                                                        settingData.assignedSale.username
                                                        :
                                                        settingData.assignedRla.username
                                                } */}
                                                {taskSelected?.nguoiXuLy ?? taskSelected?.modifiedBy}
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md 43170458-6576-4de9-b775-ceb2970d9df4" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value ddc02a3e-e57f-45f7-8f26-ad199228f601" level={8} cateid={139}>Ngày khởi tạo:</div>
                                            <div className="w-text wbaseItem-value 5072ab20-cec7-4b42-ab24-079462117974" level={8} cateid={139}>
                                                {new Date(taskSelected?.ngayTaoYeuCau).toLocaleDateString()}</div>
                                        </div>
                                    </div>
                                </div>


                                <div className="w-frame wbaseItem-value w-col b05a7d4f-90ac-49a4-815e-139f648f3426" level={5} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row a4bf656d-aa13-4b4c-9798-e8510cee79af" level={6} cateid={140}>
                                        <div className="w-text wbaseItem-value 86fed3b4-f3e9-4edf-8715-8c403eb01b1e" level={7} cateid={139}>Thông tin hồ sơ giải ngân</div>
                                    </div>
                                    {
                                        settingData?.loanApplication?.maTrangThaiHoSo !== FileStatus.XU_LY_GIAI_NGAN_CODE &&
                                        <div className="w-frame wbaseItem-value w-row 529f5f19-c8f8-4af0-9697-a8ab1bef486a" wrap="wrap" level={6} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col8-xl col24-md 92914ed9-e20f-4233-8466-9881d79f9aeb" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value 441b5396-85e7-4fef-b261-3b727e274013" level={8} cateid={139}>Số CIF:</div>
                                                <div className="w-text wbaseItem-value f46915ce-69ac-4525-8cc7-42e7ec81e7fb" level={8} cateid={139}>
                                                    {settingData?.loanApplication?.khachHang.cif ?? "_"}
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col8-xl col24-md 2cd50ffc-c5df-4912-a601-ae194e81f189" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value 2481c0b4-e8e3-4529-a838-e1902fe9186c" level={8} cateid={139}>Số khung:</div>
                                                <div className="w-text wbaseItem-value 6c42debe-0934-4d01-8593-071cc53cd49e" level={8} cateid={139}>
                                                    {settingData?.loanApplication?.soKhung ?? "_"}
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col8-xl col24-md 04629b00-3db4-402c-a890-d3317f209309" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value 372aad81-e0f6-4352-93cb-eeb17d069d3d" level={8} cateid={139}>Số máy:</div>
                                                <div className="w-text wbaseItem-value f9d18073-4b6a-4b18-a659-e3065ec08195" level={8} cateid={139}>
                                                    {settingData?.loanApplication?.soMay ?? "_"}
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col8-xl col24-md 18d30017-88dd-4def-86d0-7b5c998d9a0c" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value 42f6032b-f8b0-4294-b4b8-42e2b4a3b80c" level={8} cateid={139}>Màu sắc</div>
                                                <div className="w-text wbaseItem-value a3249da0-ee8e-4e70-8e04-296c3549ede9" level={8} cateid={139}>
                                                    {settingData?.loanApplication?.mauSac ?? "_"}
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col8-xl col24-md b71a5467-7d73-4b83-9391-4c199c0a1b8b" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value f5e6b4fe-df78-4a1d-a516-e34558a8fadc" level={8} cateid={139}>Ngày thanh toán hàng tháng:<br />
                                                </div>
                                                <div className="w-text wbaseItem-value 24ca4788-4a25-41c9-9c7d-a40e3fdf3fd8" level={8} cateid={139}>
                                                    {settingData?.loanApplication?.ngayThanhToanHangThang ?? "_"}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        settingData?.loanApplication?.maTrangThaiHoSo === FileStatus.XU_LY_GIAI_NGAN_CODE &&
                                        <div className="w-frame wbaseItem-value w-col d5a3b0e9-39f7-4645-b9d5-7c2736c17227" level={6} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 7a6a97da-21e2-4799-a4ab-c87e5b780f2b" wrap="wrap" level={7} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 70d2eb6c-f8db-4afa-aea0-22863af14e7c" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value 7538e213-b8d7-4475-8446-aa710ee7d59f" level={9} cateid={139}>Số hợp đồng:</div>
                                                    <div className="w-text wbaseItem-value da0806fc-2c4e-4bf6-9289-71184ce4f500" level={9} cateid={139}>{settingData?.loanApplication?.idHopDong ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 13fd0ebd-f42e-44df-afa9-d6e2bd96607c" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value c335dc84-53f9-4043-bc6c-ca3acfd7a4d6" level={9} cateid={139}>Ngày ký hợp đồng:</div>
                                                    <div className="w-text wbaseItem-value 0a352a41-d077-474c-b09d-ec33c42bde4c" level={9} cateid={139}>{settingData?.loanApplication?.ngayHopDongDuKien ?? ""}</div> {/* ngayHopDongDuKien ||  ngayTaoHopDong ??????????????*/}
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 71fc2094-8d98-4eb7-a7d9-c85d7eb1be6a" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value 045ea076-b152-43ff-bc5d-24f9e59dd29a" level={9} cateid={139}>Số CIF</div>
                                                    <div className="w-text wbaseItem-value 9c50843b-1bd5-4f1f-87f7-8f8392d1755d" level={9} cateid={139}>{settingData?.loanApplication?.khachHang.cif ?? "_"}</div>
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row 815b158c-b942-40ec-8a7c-3ddc0c262fa8" wrap="wrap" level={7} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 39d70ee6-2475-4643-ad12-9a7ef47f127f" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value 0a63c036-f6c0-4dc2-b969-432119b9bd3c" level={9} cateid={139}>Họ tên:</div>
                                                    <div className="w-text wbaseItem-value 2f6f41ec-5d19-4d59-b8d4-0bf874f51b5c" level={9} cateid={139}>{settingData?.loanApplication?.khachHang.hoTen ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 e2e0757e-afae-4799-8625-a81a03fb3c2a" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value f9b5fc11-4473-4990-a51c-81f662b94a33" level={9} cateid={139}>Số điện thoại:</div>
                                                    <div className="w-text wbaseItem-value c6ce4c45-8990-4be7-a2bb-fb2231cd2e22" level={9} cateid={139}>{settingData?.loanApplication?.khachHang.dtDiDong ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 f06f03dc-79c1-4ba5-99fa-c93c0f2101b7" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value 31223d6c-f0df-4829-9de3-2ddd9c9483db" level={9} cateid={139}>Số CCCD:</div>
                                                    <div className="w-text wbaseItem-value af20d7cc-4dda-491d-a2fa-f97f55fb3cfc" level={9} cateid={139}>{settingData?.loanApplication?.khachHang.cmndHoChieu ?? "_"}</div>
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row dbfb1395-9b8c-41a5-8960-8bd5706ce8d2" wrap="wrap" level={7} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 4977bd2d-bfd9-4cb1-8aa0-cf60274fb893" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value bfd9e654-8b41-4767-be5f-a127e81fe63a" level={9} cateid={139}>Đại lý:</div>
                                                    <div className="w-text wbaseItem-value d0e7dc10-1139-482e-bfa8-38220b5b9b77" level={9} cateid={139}>{settingData?.loanApplication?.daiLy ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 a29d07b2-f1a9-488b-ae6d-9c89d514f91d" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value 1ddbdba2-e541-4a93-bf60-4b39b0a91e92" level={9} cateid={139}>Số khung</div>
                                                    <div className="w-text wbaseItem-value 5cc41bbd-1c80-4549-9053-78884c596fbb" level={9} cateid={139}>{settingData?.loanApplication?.soKhung ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 7c9fb25f-db47-4460-8bb8-43d1b8699672" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value 268b6e8d-6420-46cf-81c8-0a1d24861c4b" level={9} cateid={139}>Số máy:</div>
                                                    <div className="w-text wbaseItem-value 7ff4bc66-4dab-4348-8f4a-e8a9c8920cb7" level={9} cateid={139}>{settingData?.loanApplication?.soMay ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 cd2af39c-39ef-44cf-a49c-62cd07497207" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value a64abdc4-5537-46cc-9a06-fef743079a21" level={9} cateid={139}>Màu sắc:</div>
                                                    <div className="w-text wbaseItem-value b1add2dd-9272-43b1-9b2b-5c2c7eff380c" level={9} cateid={139}>{settingData?.loanApplication?.mauSac ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 b006e191-9ec9-428c-9643-685c3aba2f38" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value 2b0b20a0-6657-4327-afba-f0638ca87647" level={9} cateid={139}>Giá bán xe:</div>
                                                    <div className="w-text wbaseItem-value d29786cd-0dff-4f29-969a-5ee096c93d1c" level={9} cateid={139}>{settingData?.loanApplication?.giaXe ? Ultis.money(settingData?.loanApplication?.giaXe) : "_"} VND</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 d02c24e4-3627-49cb-868f-5d4fd98dfca6" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value 1b9ba656-f99c-4639-bde8-c05d00fad728" level={9} cateid={139}>Số tiền trả trước:</div>
                                                    <div className="w-text wbaseItem-value 97bacb81-fec7-4662-b656-8302faf9ad7a" level={9} cateid={139}>{settingData?.loanApplication?.soTienTraTruoc ? Ultis.money(settingData?.loanApplication?.soTienTraTruoc) : "_"} VND</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 a0efde59-49ff-49f2-88a0-68e29ab3aaba" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value f13677c4-169d-4546-89ed-6477e3626bf8" level={9} cateid={139}>Số tiền JIVF thanh toán:</div>
                                                    <div className="w-text wbaseItem-value e1a911b1-7aa7-49fd-add1-3c1648e906eb" level={9} cateid={139}>{Ultis.money(settingData?.loanApplication?.soTienVayGoc)} VND</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 73812db3-07e8-4f2a-bbe8-8eccbc2a3954" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value 770c6474-02a7-42bf-8348-759a49d713ec" level={9} cateid={139}>Tổng số tiền vay mua bảo hiểm:</div>
                                                    <div className="w-text wbaseItem-value ca8248fb-30ed-4432-af1b-2cf9dcd2ff1b" level={9} cateid={139}>{Ultis.money(settingData?.loanApplication?.soTienBaoHiem)} VND</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 3689aebe-70dc-4136-99c6-3415e44c260e" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value afa0662e-6ca0-4fef-bbba-58ff6023b894" level={9} cateid={139}>Tổng khoản vay:</div>
                                                    <div className="w-text wbaseItem-value 93babf46-8d7f-4557-b0ab-03e3999eb982" level={9} cateid={139}>{Ultis.money(settingData?.loanApplication?.soTienVayGoc + settingData?.loanApplication?.soTienBaoHiem)} VND</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 af810d8f-258b-4023-ab7d-33019750d424" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value 85222dfc-951f-42ee-b1b8-f5bea8128373" level={9} cateid={139}>Kỳ hạn:</div>
                                                    <div className="w-text wbaseItem-value 3d4f82bc-7013-40b7-8bb4-55620fe21312" level={9} cateid={139}>{settingData?.loanApplication?.kyHanVay ?? "_"} tháng</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 1ef442ec-3178-4476-8d4e-07f44686ec18" level={8} cateid={140}>
                                                    <div className="w-text wbaseItem-value 70d8acac-88c1-4ab6-a04e-a227a017a4ef" level={9} cateid={139}>Ngày thanh toán hàng tháng:</div>
                                                    <div className="w-text wbaseItem-value 360e6e89-7c37-47ec-94f9-18d070d29f80" level={9} cateid={139}>{settingData?.loanApplication?.ngayThanhToanHangThang ?? "_"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>

                                {/* Hồ sơ giải ngân || Bổ sung hồ sơ giải ngân */}
                                {
                                    settingData?.loanApplication?.maTrangThaiHoSo === FileStatus.XU_LY_GIAI_NGAN_CODE &&
                                    <div className="w-frame wbaseItem-value w-col 96bed85e-e343-4b3b-84b5-1169a60b7cb6" level={5} cateid={140}> {/* ??????????????? */}
                                        <div className="w-frame wbaseItem-value w-col d458e771-a754-497c-8726-8893f25ee1f9" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value c1ba4be9-6f52-47ff-9b77-dd30f5f09e59" level={7} cateid={139}>Hồ sơ giải ngân</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 9af1b61f-f7e7-4b7c-b7f9-1904621fb381" level={6} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-col 046f6b1f-c3b5-4e6d-a445-f6c8cb882a33" level={7} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col 39175ff9-6983-4ba3-87d4-ca2837705bff" level={8} cateid={140}>
                                                    {
                                                        settingData?.loanApplication?.disbursementDocuments?.length > 0 &&
                                                        settingData?.loanApplication?.disbursementDocuments.map((item, index) => {
                                                            return (
                                                                <button type='button' onClick={() => getDocFileReview(settingData, index)} key={index} className="w-button wbaseItem-value w-col 652ce781-fe70-4c99-87e4-090191af7304" level={9} cateid={140}>
                                                                    <div className="w-svg wbaseItem-value 6bc9ba28-4095-4a03-87ae-dc478fcef158" level={10} cateid={115}>
                                                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M13 9H18.5L13 3.5V9ZM6 2H14L20 8V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2ZM10.1 11.4C10.08 11.44 9.81 13.16 8 16.09C8 16.09 4.5 17.91 5.33 19.27C6 20.35 7.65 19.23 9.07 16.59C9.07 16.59 10.89 15.95 13.31 15.77C13.31 15.77 17.17 17.5 17.7 15.66C18.22 13.8 14.64 14.22 14 14.41C14 14.41 12 13.06 11.5 11.2C11.5 11.2 12.64 7.25 10.89 7.3C9.14 7.35 9.8 10.43 10.1 11.4ZM10.91 12.44C10.94 12.45 11.38 13.65 12.8 14.9C12.8 14.9 10.47 15.36 9.41 15.8C9.41 15.8 10.41 14.07 10.91 12.44ZM14.84 15.16C15.42 15 17.17 15.31 17.1 15.64C17.04 15.97 14.84 15.16 14.84 15.16ZM7.77 17C7.24 18.24 6.33 19 6.1 19C5.87 19 6.8 17.4 7.77 17ZM10.91 10.07C10.91 10 10.55 7.87 10.91 7.92C11.45 8 10.91 10 10.91 10.07Z" fill="#28282966" />
                                                                        </svg>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col 2237fc1a-fbcf-42e9-9882-a5b9f04f26aa" level={10} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 131d24d0-2a35-4c0b-b87c-57c40bc8df64" level={11} cateid={139}>
                                                                            {item?.docTypeName ?? "_"}
                                                                        </div>
                                                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }} className="w-text wbaseItem-value d61e93da-index163-458d-a5c3-ec1747817b5e" level={11} cateid={139}>
                                                                            {`${item?.filename}.${item?.fileType}`}
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value 892dc23b-477d-484d-9b1f-684c28e3a875" level={7} cateid={140}>
                                                {/* <iframe title='file' src={`${baseurl}/jlos/disbursement-document/44`} ></iframe> */}
                                                {
                                                    //TODO: check lại file 
                                                    base64ImageData &&
                                                    <iframe
                                                        title='img'
                                                        src={`data:application/pdf;base64,${base64ImageData}`}
                                                        // src={URL.createObjectURL(base64ImageData)}
                                                        // type="application/pdf"
                                                        width="100%"
                                                        height="100%"
                                                        toolbar="none"
                                                        style={{ backgroundColor: '#f2f2f2' }}
                                                    ></iframe>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/* import file */}
                                {
                                    settingData?.loanApplication?.maTrangThaiHoSo !== FileStatus.XU_LY_GIAI_NGAN_CODE &&
                                    <div className="w-frame wbaseItem-value w-col 4ef9af33-6d55-4824-a68f-45a18fb2a86b" level={5} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row 7dbce28f-7035-4aa7-ad57-0f6ef1570a76" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value adb5bc0e-4d66-44b7-9971-c7a1679052a3" level={7} cateid={139}>Bổ sung hồ sơ giải ngân</div>
                                        </div>
                                        <table className="w-table wbaseItem-value 1e948636-a235-4302-8bba-a49fa76718c4" type={0} level={6} cateid={117}>
                                            <tbody>
                                                <tr className="table-row">
                                                    <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row e4500d60-5da9-44ef-9c37-2065fbce8280" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value 95b23738-4ece-4181-83cc-ee994e35ca3b" level={8} cateid={139}>Loại hồ sơ</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 2056ef8c-a58d-4ca8-b4d5-1b616438184e" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value dac1c09f-0fe3-40b2-9e56-0c868e82b518" level={8} cateid={139}>Tệp đính kèm</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 6bdf1857-4979-4c2f-aa3a-6096853a707e" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value 5894638b-9925-428d-89b9-2c7bd1f9f214" level={8} cateid={139}>Người upload</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 2c31b3bd-5f57-4dd9-9875-837aa5977990" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value 32f821c7-95dd-4a2f-8a73-38b7373c1092" level={8} cateid={139}>Thời gian upload</div>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr className="table-row">
                                                    <td id="2x1" className="table-cell" rowSpan="1" colSpan="1">
                                                        <div
                                                            className={`w-frame wbaseItem-value w-row 87ad26ac-c724-4aae-9f8c-fa5db078892a`}
                                                            wrap="nowrap" level="7" cateid="140"
                                                        >
                                                            <div style={{ maxWidth: "none" }} className={'select2-custom ' + (checkDocType && 'helper-text')} helper-text={checkDocType === true ? 'Vui lòng chọn loại hồ sơ' : ""}>
                                                                <Select2
                                                                    value={settingData?.loanApplication?.disbursementDocuments[0].docType}
                                                                    data={listFileType.map((item, index) => ({ name: item.documentName, id: item?.documentCode ?? index }))}
                                                                    options={{ placeholder: "Chọn loại hồ sơ" }}
                                                                    onChange={(ev) => {
                                                                        if (ev.target.value?.length > 0) {
                                                                            setCheckDocType(false);

                                                                            let newData = { ...settingData };
                                                                            newData.loanApplication.disbursementDocuments[0].docType = ev.target.value;
                                                                            setSettingData(newData);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td id="2x2" className="table-cell" rowSpan="1" colSpan="1">
                                                        <div className="w-frame wbaseItem-value w-row 5b21265d-bc8f-4281-9514-7e76e0189b7a" wrap="nowrap" level="7" cateid="140">
                                                            <input ref={fileInputRefs}
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                name='file'
                                                                onChange={(event) => handleFileInputChange(event, 0)}
                                                            />
                                                            {
                                                                settingData?.loanApplication?.disbursementDocuments[0].filename !== "" ?
                                                                    <>
                                                                        <div style={{ order: 1, flexShrink: 1 }} className="w-frame wbaseItem-value w-row fefa6bf0-bac2-4d87-a056-970f6cd1a821" wrap="nowrap" level="7" cateid="140">
                                                                            <div className="w-text wbaseItem-value 5dee65b4-ffcb-49bf-9927-e7d9f89ff1b6" level="8" cateid="139">
                                                                                {settingData?.loanApplication?.disbursementDocuments[0].filename}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ order: 2 }} className='wbaseItem-value w-row'>
                                                                            <button
                                                                                onClick={() => {
                                                                                    fileInputRefs.current.click();
                                                                                }}
                                                                                type='button' className="w-svg wbaseItem-value 628d3e27-0619-452e-9b11-a8b26388e197" level={11} cateid={115}>
                                                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M7 8.83333L12 3L17 8.83333H12.8333V16.3333H11.1667V8.83333H7Z" fill="#009944FF" />
                                                                                    <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#009944FF" />
                                                                                </svg>
                                                                            </button>
                                                                            <button
                                                                                onClick={() => {
                                                                                    let _disbursementDocuments = settingData?.loanApplication?.disbursementDocuments[0];
                                                                                    for (let key in _disbursementDocuments) {
                                                                                        if (key !== "isNew")
                                                                                            _disbursementDocuments[key] = ''
                                                                                    }

                                                                                    let newData = { ...settingData };
                                                                                    newData.loanApplication.disbursementDocuments[0] = _disbursementDocuments;
                                                                                    setSettingData(newData);
                                                                                }}
                                                                                type='button' className="w-svg wbaseItem-value ff04bf15-db3d-44e2-8a27-b1578a338964" level={11} cateid={115}>
                                                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#E60012FF" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <button
                                                                        onClick={() => {
                                                                            if (settingData?.loanApplication?.disbursementDocuments[0].docType === "") {
                                                                                setCheckDocType(true);
                                                                            } else {
                                                                                fileInputRefs.current.click();
                                                                            }
                                                                        }}
                                                                        type="button"
                                                                        className="w-button wbaseItem-value w-row 9619c01c-5184-44b4-ba34-6aaf478dbfbb"
                                                                        wrap="nowrap" name-field="Button" level="8" cateid="29"
                                                                    >
                                                                        <div className="w-svg wbaseItem-value 88df088a-5c3b-4f25-8269-b28eafc16294" level="9" cateid="115">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M7 8.83333L12 3L17 8.83333H12.8333V16.3333H11.1667V8.83333H7Z" fill="#009944FF"></path>
                                                                                <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#009944FF"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="w-text wbaseItem-value f138d148-7fa3-42d0-94ca-cef1a4f49a2d" name-field="Text" level="9" cateid="139">Upload</div>
                                                                    </button>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td id="2x3" className="table-cell" rowSpan="1" colSpan="1">
                                                        <div className="w-frame wbaseItem-value w-row fefa6bf0-bac2-4d87-a056-970f6cd1a821" wrap="nowrap" level="7" cateid="140">
                                                            <div className="w-text wbaseItem-value 5dee65b4-ffcb-49bf-9927-e7d9f89ff1b6" level="8" cateid="139">
                                                                {settingData?.loanApplication?.disbursementDocuments[0]?.createdBy ?? ''}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td id="2x4" className="table-cell" rowSpan="1" colSpan="1">
                                                        <div className="w-frame wbaseItem-value w-row 2b8317af-5744-47fc-87b5-0214977569b4" wrap="nowrap" level="7" cateid="140">
                                                            <div className="w-text wbaseItem-value aa7012fb-8c84-45e6-8464-940eaa0928ae" level="8" cateid="139">
                                                                {settingData?.loanApplication?.disbursementDocuments[0].createdDate !== "" ? new Date(settingData?.loanApplication?.disbursementDocuments[0].createdDate).toLocaleDateString("en-GB") : ""}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                }



                                {/* Ghi chú */}
                                <div className="w-frame wbaseItem-value w-col 4c9dbf67-46b1-4895-a1e9-46b0a97efcb4" level={5} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row f58f9b71-5cc0-4860-9e74-5d0e99daa2d5" level={6} cateid={140}>
                                        <div className="w-text wbaseItem-value b70fa816-aba2-473e-afb7-70ac3f0c7227" level={7} cateid={139}>Ghi chú</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col 52ebd846-2f1f-4670-a82e-55abfbb17f8e" level={6} cateid={140}>
                                        {
                                            settingData?.loanApplication?.saleDisbursementNotes?.length &&
                                            settingData?.loanApplication?.saleDisbursementNotes.map((noteItem, index) =>
                                                <div key={index} className="w-frame wbaseItem-value w-col 4ec4f249-2099-43f3-940e-ffc694dcd6ce" level={7} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 2737891f-c6f0-4e2a-ad2c-a1145fd4d16f" level={8} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-row c9eea816-86db-48ba-bc54-33b685279057" level={9} cateid={140}>
                                                            <div className="w-svg wbaseItem-value b3255782-982d-4880-85d0-c7238a253e24" level={10} cateid={115}>
                                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.9997 7C16.9997 9.76142 14.7611 12 11.9997 12C9.23826 12 6.99968 9.76142 6.99968 7C6.99968 4.23858 9.23826 2 11.9997 2C14.7611 2 16.9997 4.23858 16.9997 7Z" fill="#28282999" />
                                                                    <path d="M3.66667 19.222C3.66667 17.177 6.375 13.667 12 13.667C17.625 13.667 20.3333 17.177 20.3333 19.222V20.3337C20.3333 20.7757 20.1577 21.1996 19.8452 21.5122C19.5326 21.8247 19.1087 22.0003 18.6667 22.0003H5.33334C4.89131 22.0003 4.46739 21.8247 4.15483 21.5122C3.84227 21.1996 3.66667 20.7757 3.66667 20.3337V19.222Z" fill="#28282999" />
                                                                </svg>
                                                            </div>
                                                            <div className="w-text wbaseItem-value b28117dc-6923-4fc7-8b7b-e3ee1a33330b" level={10} cateid={139}>{noteItem.maNguoiTao} - {noteItem.tenNguoiTao}</div>
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-row 2ce48198-0d8a-4727-a0f8-1eefeb747955" level={9} cateid={140}>
                                                            <div className="w-svg wbaseItem-value 1c5b17dc-5e8c-4c69-8bae-15d728fc3599" level={10} cateid={115}>
                                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M6.16667 11.1667C5.70643 11.1667 5.33333 11.5398 5.33333 12V12.8333C5.33333 13.2936 5.70643 13.6667 6.16667 13.6667H7.83333C8.29357 13.6667 8.66667 13.2936 8.66667 12.8333V12C8.66667 11.5398 8.29357 11.1667 7.83333 11.1667H6.16667Z" fill="#28282999" />
                                                                    <path d="M11.1667 11.1667C10.7064 11.1667 10.3333 11.5398 10.3333 12V12.8333C10.3333 13.2936 10.7064 13.6667 11.1667 13.6667H12.8333C13.2936 13.6667 13.6667 13.2936 13.6667 12.8333V12C13.6667 11.5398 13.2936 11.1667 12.8333 11.1667H11.1667Z" fill="#28282999" />
                                                                    <path d="M5.33333 16.1667C5.33333 15.7064 5.70643 15.3333 6.16667 15.3333H7.83333C8.29357 15.3333 8.66667 15.7064 8.66667 16.1667V17C8.66667 17.4602 8.29357 17.8333 7.83333 17.8333H6.16667C5.70643 17.8333 5.33333 17.4602 5.33333 17V16.1667Z" fill="#28282999" />
                                                                    <path d="M11.1667 15.3333C10.7064 15.3333 10.3333 15.7064 10.3333 16.1667V17C10.3333 17.4602 10.7064 17.8333 11.1667 17.8333H12.8333C13.2936 17.8333 13.6667 17.4602 13.6667 17V16.1667C13.6667 15.7064 13.2936 15.3333 12.8333 15.3333H11.1667Z" fill="#28282999" />
                                                                    <path d="M15.3333 12C15.3333 11.5398 15.7064 11.1667 16.1667 11.1667H17.8333C18.2936 11.1667 18.6667 11.5398 18.6667 12V12.8333C18.6667 13.2936 18.2936 13.6667 17.8333 13.6667H16.1667C15.7064 13.6667 15.3333 13.2936 15.3333 12.8333V12Z" fill="#28282999" />
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.83333 2.83333C7.83333 2.3731 7.46024 2 7 2C6.53976 2 6.16667 2.3731 6.16667 2.83333V3.66667H4.5C3.11929 3.66667 2 4.78595 2 6.16667V18.6667C2 20.0474 3.11929 21.1667 4.5 21.1667H19.5C20.8807 21.1667 22 20.0474 22 18.6667V6.16667C22 4.78595 20.8807 3.66667 19.5 3.66667H17.8333V2.83333C17.8333 2.3731 17.4602 2 17 2C16.5398 2 16.1667 2.3731 16.1667 2.83333V3.66667H7.83333V2.83333ZM4.5 5.33333C4.03976 5.33333 3.66667 5.70643 3.66667 6.16667V7.83333H20.3333V6.16667C20.3333 5.70643 19.9602 5.33333 19.5 5.33333H17.8333C17.8333 5.79357 17.4602 6.16667 17 6.16667C16.5398 6.16667 16.1667 5.79357 16.1667 5.33333H7.83333C7.83333 5.79357 7.46024 6.16667 7 6.16667C6.53976 6.16667 6.16667 5.79357 6.16667 5.33333H4.5ZM4.5 19.5C4.03976 19.5 3.66667 19.1269 3.66667 18.6667V9.5H20.3333V18.6667C20.3333 19.1269 19.9602 19.5 19.5 19.5H4.5Z" fill="#28282999" />
                                                                </svg>
                                                            </div>
                                                            <div className="w-text wbaseItem-value 356c8fae-7aa4-4273-b0f5-652235dcfae0" level={10} cateid={139}>{noteItem.createdDate !== undefined ? new Date(noteItem.createdDate).toLocaleDateString() : "_"}</div>
                                                        </div>
                                                    </div>
                                                    <div className="w-text wbaseItem-value 1d1f87ae-4f3c-4ebc-9d44-20b0c2a813c9" level={8} cateid={139}>{noteItem.noiDung ?? "_"}</div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        settingData?.loanApplication?.maTrangThaiHoSo !== FileStatus.XU_LY_GIAI_NGAN_CODE &&
                                        <div className="w-frame wbaseItem-value w-col 84e43022-3e6e-400e-937f-f028ac038039" level={6} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row f0a69b43-676c-4e62-a859-409dbf32ece4" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value 2b5433d0-5823-4d07-bc83-b606afc6cd27" level={8} cateid={139}>Thêm ghi chú</div>
                                            </div>
                                            <div className="w-textformfield wbaseItem-value w-row text-area-note-input" level={9} cateid={85} >
                                                <div className="wbaseItem-value" level={10} cateid={86}>
                                                    <div className="textfield">
                                                        <textarea
                                                            type="text"
                                                            value={newNote}
                                                            maxLength={2000}
                                                            onChange={(ev) => {
                                                                setNewNote(ev.target.value);
                                                            }}
                                                            cols="40" rows="5" placeholder="Nhập ghi chú" style={{ resize: "none", outline: 0, width: "100%", fontSize: "14px" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>



                                {/* Ý kiến giải ngân */}
                                {
                                    settingData?.loanApplication?.maTrangThaiHoSo === FileStatus.XU_LY_GIAI_NGAN_CODE &&
                                    <div className="w-frame wbaseItem-value w-col 52b4c502-5717-453a-a84b-b6bb92ff8fa6" level={5} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col dafacd71-fcdd-4aa8-ad6a-7e16a12a077b" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value 074bbd84-2409-455f-97c1-eff007f2e6a3" level={7} cateid={139}>Ý kiến giải ngân</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col 0544e53c-82bb-4a84-80c8-c80bfa20c6ef" level={6} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row e395554b-b225-4765-a60a-80e56ecc43c9" level={7} cateid={128}>
                                                <div className="w-frame wbaseItem-value w-row 77f21759-2db2-4e9c-a456-5f69324241f6" level={8} cateid={140}>
                                                    <label
                                                        onClick={() => {
                                                            setActionXuLy(1);
                                                            setMaAction("ADDITION");
                                                            setNewNote("Yêu cầu bổ sung chứng từ");
                                                            setMaTuChoi("");
                                                        }}
                                                        className="w-radio-btn wbaseItem-value nav-link 47771b0d-b1c9-4260-8eb4-a78c2774596c" name-field="a" level={9} cateid={90}>
                                                        <input style={{ pointerEvents: "none" }} checked={actionXuLy === 1 ?? false} type="radio" name="a" disabled />
                                                        <span className="checkmark" />
                                                    </label>
                                                    <div className="w-text wbaseItem-value e095a8b7-9a60-4ab9-b2ce-c5790ff6ef00" level={9} cateid={139}>Yêu cầu bổ sung</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-row f71c51b7-3cdf-4879-a08c-357a1b3bfd21" level={8} cateid={140}>
                                                    <label
                                                        onClick={() => {
                                                            setActionXuLy(2);
                                                            setMaAction("APPROVE");
                                                            setNewNote("");
                                                            setMaTuChoi("");
                                                        }}
                                                        className="w-radio-btn wbaseItem-value nav-link ccedc297-d17a-43a4-8db4-b5a59568f1a7" name-field="a" level={9} cateid={90}>
                                                        <input style={{ pointerEvents: "none" }} checked={actionXuLy === 2 ?? false} type="radio" name="a" disabled />
                                                        <span className="checkmark" />
                                                    </label>
                                                    <div className="w-text wbaseItem-value 77a43c7c-4d9a-49c3-95c7-8d56b2bc31f0" level={9} cateid={139}>Đồng ý</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-row 6b65d46a-d5b9-4ce3-a5b8-bacd5f2b4521" level={8} cateid={140}>
                                                    <label
                                                        onClick={() => {
                                                            setActionXuLy(3);
                                                            setMaAction("REJECT");
                                                            setNewNote("Phát hiện lừa đảo");
                                                            setMaTuChoi("R6");
                                                        }}
                                                        className="w-radio-btn wbaseItem-value nav-link 10ebe556-51d1-456f-9275-ca1e8e93f3eb" name-field="a" level={9} cateid={90}>
                                                        <input style={{ pointerEvents: "none" }} checked={actionXuLy === 3 ?? false} type="radio" name="a" disabled />
                                                        <span className="checkmark" />
                                                    </label>
                                                    <div className="w-text wbaseItem-value 59fa9556-2857-4364-9cf8-76b46f753401" level={9} cateid={139}>Từ chối</div>
                                                </div>
                                            </div>
                                            {
                                                actionXuLy === 1 ?
                                                    <div className="w-frame wbaseItem-value w-row e18a528a-1234-4549-a9f6-4c4a264e572f" level={7} cateid={140}>
                                                        <div className={`select2-custom`} >
                                                            <Select2
                                                                value={maAction}
                                                                data={[{ id: "ADDITION", name: "Bổ sung chứng từ" }, { id: "RECREATE_CONTRACT", name: "Cần tạo lại hợp đồng" },]}
                                                                options={{ placeholder: 'Chọn bước trả về' }}
                                                                onChange={(ev) => {
                                                                    if (ev.target.value?.length > 0) {
                                                                        setMaAction(ev.target.value);
                                                                        ev.target.value === "ADDITION" ?
                                                                            setNewNote("Yêu cầu bổ sung chứng từ") :
                                                                            setNewNote("Cần tạo lại hợp đồng")
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    actionXuLy === 3 ?
                                                        <div className="w-frame wbaseItem-value w-row e18a528a-1234-4549-a9f6-4c4a264e572f" level={7} cateid={140}>
                                                            <div className={'select2-custom'} >
                                                                < Select2
                                                                    value={maTuChoi}
                                                                    data={[{ id: "R6", name: "Phát hiện lừa đảo" },]}
                                                                    options={{ placeholder: 'Chọn lý do từ chối' }}
                                                                    onChange={(ev) => {
                                                                        if (ev.target.value?.length > 0) {
                                                                            setMaAction("REJECT")
                                                                            setMaTuChoi(ev.target.value);
                                                                            setNewNote("Phát hiện lừa đảo")
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                            }

                                            <div className="w-textformfield wbaseItem-value w-row text-area-note-input" level={9} cateid={85} >
                                                <div className="wbaseItem-value" level={10} cateid={86}>
                                                    <div className="textfield">
                                                        <textarea onChange={(ev) => { setNewNote(ev.target.value) }} value={newNote} cols="40" rows="5" placeholder="Nhập yêu cầu bổ sung" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="w-frame wbaseItem-value w-row 19b89cb6-48c3-40e4-b2ca-bda590d794ca" level={4} cateid={140}>
                            <button onClick={() => setThoatUI(true)} type='button' className="w-button w-text wbaseItem-value 0f7f96d5-96c0-49d8-866c-6c2126f0b4ce" level={5} cateid={139}>Thoát</button>
                            <div className="w-frame wbaseItem-value w-row c6cdce9f-4a24-4267-ace9-175f7aa0f288" level={5} cateid={140}>
                                <button
                                    onClick={handleSave}
                                    type="button" className="w-button wbaseItem-value w-row bf45f121-311b-4779-a2f6-a9e76cdc2dba" level={6} cateid={29}>
                                    <div className="w-svg wbaseItem-value a16b60a2-bf6e-49b7-86d0-bee67cb7eb7f" level={7} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.1665 2H6.49997C5.83694 2 5.20106 2.26339 4.73223 2.73223C4.26339 3.20106 4 3.83694 4 4.49997V21.5831C3.99996 21.6577 4.01993 21.7309 4.05783 21.7951C4.09572 21.8593 4.15015 21.9122 4.21545 21.9482C4.28074 21.9842 4.35449 22.002 4.42902 21.9998C4.50355 21.9976 4.57612 21.9754 4.63916 21.9356L12.3332 17.0765L20.0273 21.9356C20.0938 21.9779 20.171 22.0002 20.2498 21.9998C20.3603 21.9998 20.4663 21.9559 20.5445 21.8777C20.6226 21.7996 20.6665 21.6936 20.6665 21.5831V4.49997C20.6665 3.83694 20.4031 3.20106 19.9343 2.73223C19.4654 2.26339 18.8295 2 18.1665 2V2Z" fill="#28282999" />
                                        </svg>
                                    </div>
                                    <div className="w-text wbaseItem-value 7d3b298c-f6d8-410b-a7a5-62ceb9ceaefa" level={7} cateid={139}>Lưu</div>
                                </button>
                                <button type="submit" className="w-button wbaseItem-value w-row d35a4306-4fb7-45d9-87d8-edb4bf95d3f4" level={6} cateid={29}>
                                    <div className="w-svg wbaseItem-value a082a18f-48a9-41a2-bbfe-17b256a50052" level={7} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M21.7337 5.26627C22.0888 5.62129 22.0888 6.19689 21.7337 6.55192L9.91555 18.3701C9.56053 18.7251 8.98493 18.7251 8.6299 18.3701L2.26627 12.0065C1.91124 11.6514 1.91124 11.0758 2.26627 10.7208C2.62129 10.3658 3.19689 10.3658 3.55192 10.7208L9.27273 16.4416L20.4481 5.26627C20.8031 4.91124 21.3787 4.91124 21.7337 5.26627Z" fill="#FFFFFFFF" />
                                        </svg>
                                    </div>
                                    <div className="w-text wbaseItem-value 565305f4-3d5c-45e7-a4df-8b078a8441d1" level={7} cateid={139}>Gửi</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </form >
            </>
            :
            <div style={{ order: 3, padding: "8px 12px", boxSizing: "border-box" }}>Loading...</div>
    )
}