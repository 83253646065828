import { baseurl } from '../../../../config/ConfigAPI'
import {
  deleteFormDataFile,
  getData,
  postData,
  postFormDataFile
} from '../../../../core/BaseDA'

export default class FileDA {
  static uploadFile = async obj => {
    const url = `${baseurl}file`
    const response = await postFormDataFile(url, { obj: obj })
    return response
  }
  static deleteFile = async obj => {
    const url = `${baseurl}file`
    const response = await deleteFormDataFile(url, { obj: obj })
    return response
  }
  static getFile = async fileId => {
    const url = `${baseurl}file?idFile=${fileId}`
    // const response = await getData(url)
    // if (response.code === '200') {
    //   return response.data
    // }
    // return null
    debugger
    getData(url)
      .then(response => {
        // const disposition = response.headers.get('content-disposition')
        // filename = disposition.match(/filename=(.+)/)[1]
        return response.blob()
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        // a.download = filename
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove() //afterwards we remove the element again
        // callback({msg: 'ok'})
      })
  }
}
