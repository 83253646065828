import { Insertcustomers, baseurl, changeStatusCustomer, changeStatusUser, createUser, deleteUser, downLoadtempUser, exportUser, getAllUser, getAllcustomers, getDetailUser, getDetailcustomers, resetPassUser, ressetPasswordCustomer, updateUser, uploadUser } from "../../../../config/ConfigAPI";
import { deleteData, getData, getDataFile, postData, postFormDataFile, putData, putFormDataFile } from "../../../../core/BaseDA";

export const getListAllUser = async (page, size, query) => {
    var url = `${baseurl}${getAllUser}${query == null ? "" : query !== "" ? `${query}&pageNum=${page}&pageSize=${size}` : `?pageNum=${page}&pageSize=${size}`}`;
    url = encodeURI(url)
    debugger
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const getListAllCustomer = async (page, size, query) => {
    const url = `${baseurl}${getAllcustomers}${query !== "" ? `${query}&pageNum=${page}&pageSize=${size}` : `?pageNum=${page}&pageSize=${size}`}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getUserbyId = async (userId) => {
    const url = `${baseurl}${getDetailUser}?userId=${userId}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};


export const getCustomerbyId = async (ussername) => {
    const url = `${baseurl}${getDetailcustomers}?username=${ussername}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const createNewUser = async (obj) => {
    const url = `${baseurl}${createUser}`;
    const response = await postFormDataFile(url, { obj: obj });
    return response;
};
export const createNewCustomer = async (obj) => {
    const url = `${baseurl}${Insertcustomers}`;
    const response = await postFormDataFile(url, { obj: obj });
    return response;
};
export const EditUser = async (obj) => {
    const url = `${baseurl}${updateUser}`;
    const response = await putFormDataFile(url, { obj: obj });
    return response;
};


export const UpdateStatusUser = async (id, status) => {
    const url = `${baseurl}${changeStatusUser}?status=${status}&userId=${id}`;
    const response = await postData(url, { obj: null });
    return response;
};


export const UpdateStatusCustomer = async (id, status) => {
    const url = `${baseurl}${changeStatusCustomer}?status=${status}&username=${id}`;
    const response = await putData(url, { obj: null });
    return response;
};



export const resetPasswordUser = async (username) => {
    const url = `${baseurl}${resetPassUser}?username=${username}`;
    const response = await putData(url, { obj: null });
    return response;
};

export const resetPasswordCustomer = async (username, phone) => {
    const url = `${baseurl}${ressetPasswordCustomer}?username=${username}&phoneNumber=${phone}`;
    const response = await putData(url, { obj: null });
    return response;
};
export const ExportFileUser = async (query) => {
    const UrlFile = `${baseurl}${exportUser}${query ?? ""}`;
    const response = await getDataFile(UrlFile);
    return response;
};
export const ExportTemplate = async () => {
    const UrlFile = `${baseurl}${downLoadtempUser}`;
    const response = await getDataFile(UrlFile);
    return response;
};

export const UploadFileUser = async (obj) => {
    const url = `${baseurl}${uploadUser}`;
    const response = await postFormDataFile(url, { obj: obj });
    return response;
};
