import { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";


import '../../css/nhomquyen/masterpage.css';
import Pagination from '../../components/Pagination/Pagination';
import { NavLink, useLocation } from 'react-router-dom';
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import { HanmucUpdate, getListHanmuc, updateStatusLimit } from '../../redux/middleware/api/hanmuc/hanmucDA';
import { useForm, Controller } from 'react-hook-form';
import Select2 from '../../components/select2/Select2';
import { Ultis } from '../../core/utils';

export default function DSHanMuc() {
    const { register, handleSubmit, setValue, getValues, control } = useForm({});
    const location = useLocation();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState();
    const [pageDetails, setPageDetails] = useState({
        current: 1,
        size: 10
    });
    const notificationAlert = useRef();

    const _InitData = async (page, pageSize) => {
        const query = window.location.search;
        const response = await getListHanmuc(page, pageSize, query);
        if (response != null) {
            setData(response.content);
            setTotal(response.totalElements)
        }
    }

    const OnchangeSwitch = async (index) => {
        const respone = await updateStatusLimit(data[index].id, data[index].status === 1 ? 0 : 1);
        if (respone.code == '200') {
            // setData(data);
            setData((data) =>
                data.map((item) =>
                    item.id === data[index].id ? { ...item, status: data[index].status === 0 ? 1 : 0 } : item
                ));
        } else {
            notify(notificationAlert, ERROR, respone.message)
        }

    }

    const submitSearch = (ev) => {
        const searchParams = new URLSearchParams(location.search);
        if (ev.limitedCode > 0)
            searchParams.set('limitedCode', ev.limitedCode);
        else
            searchParams.delete('limitedCode');
        if (ev.limitedName > 0)
            searchParams.set('limitedName', ev.limitedName);
        else
            searchParams.delete('limitedName');
        if (ev.status)
            searchParams.set('status', ev.status);
        else
            searchParams.delete('status');
        window.history.replaceState(null, null, `?${searchParams.toString()}`);
        _InitData(pageDetails.current, pageDetails.size).then(_ => {
            if (ev.limitedCode) setValue("limitedCode", ev.limitedCode)
            if (ev.limitedName) setValue("limitedName", ev.limitedName)
            if (ev.status) setValue("status", ev.status)
        });
    }

    const renderHoSoByFilter = () => {
        let tbRow = [];
        let filterData = getValues();
        let list = data;
        if (filterData.limitedCode || filterData.limitedName || filterData.status) {
            list = list.filter(item => {
                let check = true;
                if (filterData.limitedCode) {
                    check = item.code.includes(filterData.limitedCode);
                }
                if (check && filterData.limitedName) {
                    check = Ultis.toSlug(item.name).includes(Ultis.toSlug(filterData.limitedName));
                }
                if (check && filterData.status) {
                    check = (filterData.status === "Hoạt động" && item.status === 1) || (filterData.status === "Không hoạt động" && item.status === 0);
                }
                return check;
            })
        }
        list.forEach((item, index) => {
            tbRow.push(
                <tr key={item.id} className="table-row">
                    <td className="table-cell" rowSpan={1} colSpan={1}>
                        <div className="w-frame wbaseItem-value w-row acbebf44-6c58-482d-86c8-31f3d1649f04" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 51d88187-e142-427e-9963-328e66e429f6" level={8} cateid={139}>{pageDetails.size * (pageDetails.current - 1) + index + 1}</div>
                        </div>
                    </td>
                    <td className="table-cell" rowSpan={1} colSpan={1}>
                        <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                            <NavLink to={location.pathname + '/' + item.id} className="w-text wbaseItem-value fd4011f7-127d-4174-8747-026e1bafb83d" level={8} cateid={139} >{item.code}</NavLink>
                        </div>
                    </td>
                    <td className="table-cell" rowSpan={1} colSpan={1}>
                        <div className="w-frame wbaseItem-value w-row fbf833df-8732-40b3-8c84-6647ac56d57a" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.name}</div>
                        </div>
                    </td>
                    <td className="table-cell" rowSpan={1} colSpan={1}>
                        <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                            <div className="w-frame wbaseItem-value w-row eadd2c5d-e811-4f3e-9934-2dacf0e9402a" level={2} cateid={29} style={{ backgroundColor: `${item.status === 1 ? "rgb(228, 249, 238)" : "var(--background-color-background-grey-light-2)"}` }}>
                                <div className="w-text wbaseItem-value 38662a33-df93-442d-830c-0d71ce5e816f" level={3} cateid={139} style={{ color: item.status === 1 ? `rgb(0, 153, 68)` : `#262626` }}>{item.status === 1 ? 'Active' : 'Deactive'}</div>
                            </div>
                        </div>
                    </td>
                    <td className="table-cell" rowSpan={1} colSpan={1}>
                        <div className="w-frame wbaseItem-value w-row 7bc608f0-fb0c-41d6-b607-4ea13b26e9f5" level={7} cateid={140}>
                            <label
                                onClick={() => { OnchangeSwitch(index) }}
                                className="w-switch wbaseItem-value 16f12539-f6fb-4363-849f-6b0b2ead064a" level={8} cateid={81}>
                                <input
                                    type='checkbox'
                                    checked={item.status === 1 ? true : false}
                                    name="Switch"
                                    disabled
                                />
                                <span className="slider" />
                            </label>
                        </div>
                    </td>

                </tr>
            )
        });
        return tbRow;
    }

    useEffect(() => {
        _InitData(pageDetails.current, pageDetails.size);
    }, []);

    return (
        <div className="w-frame wbaseItem-value w-col ea27d422-4911-447e-b1a0-61cc0db85b44" level={3} cateid={140}>
            <NotificationAlert ref={notificationAlert} />
            <div className="w-frame wbaseItem-value w-col 41d2ca9e-2b09-472a-88b5-b6c403239ba1" level={4} cateid={140}>
                <div className="w-frame wbaseItem-value w-row 352ce5fb-2db5-4098-bed5-df59ebef5761" level={5} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col b8eebb9f-5e29-4be1-a46d-a20fa0ace08b" level={6} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row aebfee05-4518-445f-aa7a-ad130cb67a5c" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value eaef1153-2a21-40db-b836-4b668238ec34" level={8} cateid={139}>Tìm kiếm hạn mức</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row breadcrumb 459f3bd3-9104-4e1c-ac5b-1690fdb622e8" level={7} cateid={140}>
                            <button type="button" className="w-button wbaseItem-value w-row 03005b3e-e132-4ece-a6c3-d67244fcbb0e" level={8} cateid={29}>
                                <div className="w-text wbaseItem-value abbc3ee8-b129-4537-a565-56ae71e0f086" level={9} cateid={139}>Quản trị hệ thống</div>
                                <div className="w-svg wbaseItem-value 9dac9b43-147b-4057-85ad-94ddba7400c1" level={9} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6" />
                                    </svg>
                                </div>
                            </button>
                            <div className="w-text wbaseItem-value 8aca59dd-0380-43a5-8cbd-0fdf01ed52b0" level={8} cateid={139}>Hạn mức</div>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit(submitSearch)} autoComplete='off' className="w-frame w-form wbaseItem-value w-col e3d1eec9-af12-4f81-98de-7b1013b2c45a" level={5} cateid={128}>
                    <div className="w-frame wbaseItem-value w-row b2ec1ec5-af32-4c54-923e-1bdda7a0008e" wrap="wrap" level={6} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 2429e32c-b23c-4fb4-86dd-5e2102fddad3" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 4ee89f5f-68f0-468e-843e-09d9a96bfa8b" level={8} cateid={139}>Mã hạn mức</div>
                            <div className="w-textformfield wbaseItem-value w-row dc1a2708-e1c6-4d7e-b90e-d2a8625a0bb5" level={8} cateid={85} placeholder="Nhập mã hạn mức">
                                <div className="wbaseItem-value 6769e680-9a6d-4aa6-8057-e127a10c8cfd" level={9} cateid={86}>
                                    <div className="textfield" style={{ marginTop: "2px" }}>
                                        <input maxLength={20} name="limitedCode" {...register("limitedCode", { onChange: (ev) => { ev.target.value = ev.target.value.trim() } })} placeholder="Nhập mã hạn mức" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 6156f408-3027-4da4-8839-a18ba6bc5467" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value e5b046d1-dcc2-4846-8cec-196e33e62f8c" level={8} cateid={139}>Tên hạn mức</div>
                            <div className="w-textformfield wbaseItem-value w-row 71eadcce-cb12-4862-b797-a38ea6beb51d" level={8} cateid={85} placeholder="Nhập tên hạn mức">
                                <div className="wbaseItem-value 8a2cb047-aaef-4cf4-aa60-7e239596bd52" level={9} cateid={86}>
                                    <div className="textfield" style={{ marginTop: "2px" }}>
                                        <input name="limitedName" {...register("limitedName")} placeholder="Nhập tên hạn mức" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 77769d60-55f3-4066-b5aa-eae222a0b63d" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 912e095d-5aa5-4cd7-b16b-6f244f51aac0" level={8} cateid={139}>Trạng thái</div>
                            <Controller
                                name="status"
                                control={control}
                                {...register("status")}
                                style={{ order: 2 }}
                                render={({ field }) => (
                                    <Select2 {...field}
                                        data={[{ id: 1, name: 'Active' }, { id: 0, name: 'Deactive' }]}
                                        options={{ placeholder: 'Chọn trạng thái' }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row 60eb7691-2d67-402e-a13f-6aba8d5dfe3c" level={6} cateid={140}>
                        <button type="button"
                            onClick={() => {
                                setValue("limitedCode", null);
                                setValue("limitedName", null);
                                setValue('status', null);
                                submitSearch({ status: null })
                            }}
                            className="w-button wbaseItem-value w-row 8c46835a-80fa-488b-979e-419f7ac8efec" level={7} cateid={29}>
                            <div className="w-svg wbaseItem-value cef48042-9809-4985-bddf-aa647f81640e" level={8} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.0313 2.00835C21.5288 2.07581 21.8774 2.53382 21.81 3.03135L21.0827 8.39498C21.0153 8.89173 20.5586 9.2402 20.0617 9.17395L14.6072 8.44668C14.1095 8.38032 13.7598 7.92309 13.8262 7.42541C13.8926 6.92774 14.3498 6.57809 14.8475 6.64445L18.3484 7.11124C16.8702 5.25663 14.5726 4.09064 12 4.09064C7.50208 4.09064 3.81818 7.77454 3.81818 12.2725C3.81818 12.7745 3.41117 13.1816 2.90909 13.1816C2.40701 13.1816 2 12.7745 2 12.2725C2 6.77038 6.49792 2.27246 12 2.27246C15.0381 2.27246 17.7813 3.61127 19.6033 5.77412L20.0083 2.78705C20.0757 2.28953 20.5338 1.94089 21.0313 2.00835Z" fill="#28282999" />
                                    <path d="M22 12.2733C22 11.7713 21.5929 11.3643 21.0909 11.3643C20.5888 11.3643 20.1818 11.7713 20.1818 12.2733C20.1818 16.7713 16.4979 20.4552 12 20.4552C9.42714 20.4552 7.12925 19.2889 5.6511 17.434L9.15251 17.9009C9.65018 17.9672 10.1074 17.6176 10.1738 17.1199C10.2401 16.6222 9.89048 16.165 9.39281 16.0986L3.93826 15.3714C3.44137 15.3051 2.98462 15.6536 2.91726 16.1503L2.18999 21.514C2.12253 22.0115 2.47117 22.4695 2.96869 22.537C3.46621 22.6044 3.92423 22.2558 3.99169 21.7583L4.39665 18.7716C6.21865 20.9345 8.96182 22.2733 12 22.2733C17.502 22.2733 22 17.7754 22 12.2733Z" fill="#28282999" />
                                </svg>
                            </div>
                            <div className="w-text wbaseItem-value fb0f0aba-9b42-4b9d-ba05-a185d998a8f7" level={8} cateid={139}>Xóa bộ lọc</div>
                        </button>
                        <button type="submit" className="w-button wbaseItem-value w-row 38b377c7-da56-44ff-93b4-d77b917d096f" level={7} cateid={29}>
                            <div className="w-svg wbaseItem-value 8e116fb4-b8c7-4c9d-ad7a-344a0edece09" level={8} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#FFFFFFFF" />
                                </svg>
                            </div>
                            <div className="w-text wbaseItem-value 14b619ba-00f8-4772-b32c-bc35ccb1174d" level={8} cateid={139}>Tìm kiếm</div>
                        </button>
                    </div>
                </form>
                <div className="w-frame wbaseItem-value w-col e8de9f90-0118-414b-bc69-da273a578779" level={5} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row 6ef2f09f-bb09-4a7f-bca4-ba5fe9a4682c" level={6} cateid={140}>
                        <div className="w-text wbaseItem-value af9be095-13e9-4645-8637-973ae192c9f6" level={7} cateid={139}>
                            Danh sách hạn mức</div>
                        <NavLink to={location.pathname + '/them-moi-han-muc'} type="button" className="nav-link w-button wbaseItem-value w-row fb37cd88-8ee8-40b0-a95a-b2d75b576b2b" level={7} cateid={29}>
                            <div className="w-svg wbaseItem-value 1710df3c-da56-4872-9f20-1bced4d4431e" level={8} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z" fill="#FFFFFFFF" />
                                </svg>
                            </div>
                            <div className="w-text wbaseItem-value 0e861cd1-5036-4fba-b559-b0ca36be7bb0" level={8} cateid={139}>Thêm hạn mức</div>
                        </NavLink>
                    </div>
                    <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                        <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>

                            <table className="w-table wbaseItem-value dbe0f04f-f73f-41cf-a94e-988260f1acdf" type={0} level={6} cateid={117}>
                                <tbody>
                                    <tr className="table-row">
                                        <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row e90e5935-90a6-40b5-a7d4-76d681ac32d2" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value 2485e51c-ff7e-44a9-a5d4-466e898c9988" level={8} cateid={139}>STT</div>
                                            </div>
                                        </td>
                                        <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row e064df7b-f2e0-49ef-a2fe-c4bc517fd403" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value c773fe31-0b32-4424-afa2-d9e81b4b309f" level={8} cateid={139}>Mã hạn mức</div>
                                            </div>
                                        </td>
                                        <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Tên hạn mức</div>
                                            </div>
                                        </td>
                                        <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Trạng thái</div>
                                            </div>
                                        </td>
                                        <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row d7bfebbd-090e-43bc-a49c-d11c6432a6c7" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value f491a7b4-eafa-4610-bee4-d82b5c97ff3e" level={8} cateid={139}>Action</div>
                                                {/* <div className="w-svg wbaseItem-value 7b48f46e-a820-427e-9bd2-e8cadc861ff3" level={8} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.48 2.2162C12.4217 2.1486 12.3484 2.09415 12.2654 2.05677C12.1824 2.01939 12.0918 2 12 2C11.9082 2 11.8176 2.01939 11.7346 2.05677C11.6516 2.09415 11.5783 2.1486 11.52 2.2162L6.12 9.08426C6.05314 9.16929 6.01243 9.2704 6.00242 9.37626C5.99242 9.48212 6.01351 9.58855 6.06334 9.68362C6.11318 9.77869 6.18978 9.85864 6.28456 9.91452C6.37935 9.9704 6.48857 10 6.6 10H17.4C17.5114 10 17.6207 9.9704 17.7154 9.91452C17.8102 9.85864 17.8868 9.77869 17.9367 9.68362C17.9865 9.58855 18.0076 9.48212 17.9976 9.37626C17.9876 9.2704 17.9469 9.16929 17.88 9.08426L12.48 2.2162Z" fill="#28282999" />
                                                        <path d="M11.52 21.7838C11.5783 21.8514 11.6516 21.9059 11.7346 21.9432C11.8176 21.9806 11.9082 22 12 22C12.0918 22 12.1824 21.9806 12.2654 21.9432C12.3484 21.9059 12.4217 21.8514 12.48 21.7838L17.88 14.9157C17.9469 14.8307 17.9876 14.7296 17.9976 14.6237C18.0076 14.5179 17.9865 14.4114 17.9367 14.3164C17.8868 14.2213 17.8102 14.1414 17.7154 14.0855C17.6207 14.0296 17.5114 14 17.4 14H6.6C6.48857 14 6.37935 14.0296 6.28456 14.0855C6.18978 14.1414 6.11318 14.2213 6.06334 14.3164C6.01351 14.4114 5.99242 14.5179 6.00242 14.6237C6.01243 14.7296 6.05314 14.8307 6.12 14.9157L11.52 21.7838Z" fill="#28282999" />
                                                    </svg>
                                                </div> */}
                                            </div>
                                        </td>
                                    </tr>
                                    {renderHoSoByFilter()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination
                        currentPage={pageDetails.current}
                        itemPerPage={pageDetails.size}
                        totalItem={total}
                        onChangePage={(page, size) => {
                            if (pageDetails.current !== page || pageDetails.size !== size) {
                                setPageDetails({ current: page, size: size });
                                _InitData(page, size);
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}