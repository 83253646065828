import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import { useForm, Controller } from 'react-hook-form';
import Select2 from '../../components/select2/Select2';

import '../../css/nhomquyen/add.css';
import { NhomquyenCreate, NhomquyenUpdate, getDetailNhomquyen, getListModue, } from '../../redux/middleware/api/nhomquyen/NhomQuyenDA';
import { PopupType, WPopup } from '../../components/Popup/Popup';
import { getgrouptype } from '../../redux/middleware/api/Filter/Filter';
export default function NhomQuyen() {
    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();

    const [moduleList, setmoduleList] = useState([]);
    const [grouptype, setgrouptype] = useState([]);
    const [data, setData] = useState({});
    const [isCreate, setConfirm] = useState();
    const [isCancel, setisCancel] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    let { id } = useParams();

    const code = useRef();
    const name = useRef();
    const status = useRef();
    const notificationAlert = useRef();

    useEffect(() => {
        getgroupTypeAll()
        _InitData();
    }, []);
    const _InitData = async () => {
        const response = await getListModue();
        if (response != null) {
            setmoduleList(response);
        }
        if (id) {
            const res = await getDetailNhomquyen(id);
            if (res != null) {
                setData(res);
                debugger;
                setValue('groupCode', res.groupCode)
                setValue('groupName', res.groupName)
                setValue('description', res.description)
                setValue('status', res.status)
                setValue('groupType', res.type)
                var lst = response.map((item) =>
                    res?.moduleIds?.some((a) => a === item.id) ? { ...item, check: true } : item
                );
                // lst.sort((a,b)=>a.check)
                lst.sort((a, b) => {
                    // Sort active items first, then inactive items
                    if (a.check === b.check) {
                        return 0;
                    }
                    return a.check ? -1 : 1;
                });
                setmoduleList(lst);
            }
        }


    }
    const OnchangeChecked = (index) => {
        setmoduleList((moduleList) =>
            moduleList.map((item) =>
                item.id === moduleList[index].id ? { ...item, check: !moduleList[index].check } : item
            ));
    }
    const onCancel = (val) => {
        ;
        setisCancel(val);

        if (val == true) {
            navigate('/admin/nhom-quyen');
        }
    }
    const getgroupTypeAll = async () => {
        const res = await getgrouptype();
        if (res != null) {
            debugger;
            setgrouptype(res)
        }
    }
    const onSumit = async (event) => {

        const lstId = moduleList.filter((a) => a.check).map((item) => item.id);

        const obj = {
            groupCode: event.groupCode,
            groupName: event.groupName,
            moduleId: lstId,
            status: event.status,
            description: event.description,
            groupType: event.groupType
        }
        if (id) {
            const res = await NhomquyenUpdate(id, obj);
            if (res.code === '200') {
                notify(notificationAlert, SUCCESS, 'Chỉnh sửa nhóm quyền thành công.')
                navigate('/admin/nhom-quyen');
            } else {
                notify(notificationAlert, ERROR, res.message);
            }
        } else {
            const res = await NhomquyenCreate(obj);
            if (res.code === '200') {
                notify(notificationAlert, SUCCESS, 'Thêm mới nhóm quyền thành công.')
                navigate('/admin/nhom-quyen');
            } else {
                notify(notificationAlert, ERROR, res.message);
            }
        }

    }
    return (
        <form onSubmit={handleSubmit(onSumit)} autoComplete='off' className="w-frame w-form wbaseItem-value w-col e14e0b64-a97e-4309-84d4-c901a202b4da" level={2}
            cateid={128}>
            <NotificationAlert ref={notificationAlert} />
            {
                isCancel == false &&
                <WPopup
                    type={PopupType.WARNING}
                    title={!id ? 'Hủy thêm mới nhóm quyền' : 'Hủy chỉnh sửa nhóm quyền'}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => onCancel(true)}
                    cancelAction={() => onCancel(null)}
                    cancelButton={true}
                    cancelTitle='Giữ lại'
                    message={'Những thông tin vừa bạn vừa nhập sẽ không được lưu lại'}
                />
            }
            <div className="w-frame wbaseItem-value w-col 55169930-5156-452b-a8d9-559b160d8fd0" level={3}
                cateid={140}>
                <div className="w-frame wbaseItem-value w-row e4c8db4f-a292-4630-840f-4321cea9a96c" level={4}
                    cateid={140}>
                    <div className="w-frame wbaseItem-value w-col 61c5c3cd-5188-43c8-91b5-1fe7b03e3c39" level={5}
                        cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 4f044728-0e07-4e2f-aa4b-106a7f97b7af"
                            level={6} cateid={140}>
                            <div className="w-text wbaseItem-value 631d5e3e-5ff1-42bb-9224-6d2189c8b545" level={7} cateid={139}>
                                {id ? 'Chi tiết ' : 'Thêm mới '} nhóm quyền&nbsp;</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row breadcrumb 86cc162c-67d1-4a5a-a8ef-8761295ca239"
                            level={6} cateid={140}>
                            <button type="button"
                                className="w-button wbaseItem-value w-row 29a214b1-e278-4d04-959e-1b70adc3b2a9"
                                name-field="Button" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value ecdef2e8-1693-46bd-99d2-d5bd00346b98" name-field="Text"
                                    level={8} cateid={139}>Quản trị hệ thống</div>
                                <div className="w-svg wbaseItem-value 22474178-c66e-4f8b-8c41-88a03a8f8a10" level={8}
                                    cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z"
                                            fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                            <button type="button"
                                className="w-button wbaseItem-value w-row 7807c449-be3d-4411-97d2-3e93e42ae5a6"
                                name-field="Button" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value 9c18f47a-0aa3-4dad-86d7-e83232c6d711" name-field="Text"
                                    level={8} cateid={139}>Nhóm quyền</div>
                                <div className="w-svg wbaseItem-value 5384ce20-5697-4020-a917-6d5093852462" level={8}
                                    cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z"
                                            fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                            <div className="w-text wbaseItem-value 924a1cb9-ccdd-4314-b624-d40f8f7661b1" level={7} cateid={139}>
                                {id ? 'Chi tiết nhóm quyền' : 'Thêm mới'}</div>
                        </div>
                    </div>
                </div>
                <div className="w-frame wbaseItem-value w-col ee2b2d2c-8eee-4daa-b96e-d7fc7b406955"
                    level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col f2bae906-3670-41a0-a977-fb7dc48b1d55" level={5}
                        cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 57e8967a-18fa-4213-87ea-7467a2618436"
                            level={6} cateid={140}>
                            <div className="w-text wbaseItem-value d7713971-43be-4840-90db-212f89a383b7" level={7} cateid={139}>
                                Thông tin nhóm quyền</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 21fab225-9cef-43b4-8b9a-dc025c921424" wrap="wrap"
                            level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm d9dc8dc9-8c5b-4681-973a-364163c17c83"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row 7e81084d-5b16-4968-a7c6-ef158e9c179d"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value f2285d87-a06c-4911-b28d-2df95eb2a10b" level={9}
                                        cateid={139}>Mã nhóm quyền</div>
                                    <div className="w-text wbaseItem-value 0db6357e-7422-4399-83d4-0a298d7b157a" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={"w-textformfield wbaseItem-value w-row e8d4f42c-9efd-47a7-9f46-6a11cd4462a1 " + (errors.groupCode && 'helper-text')}
                                    name-field="RoleID" level={8} cateid={85} helper-text={errors.groupCode && 'Nhập mã nhóm quyền'} placeholder="Nhập mã nhóm quyền">
                                    <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f"
                                        level={9} cateid={86}>
                                        <div className="textfield" style={{ marginTop: "2px" }}>
                                            <input maxLength={20} onInput={(ev) => ev.target.value = ev.target.value.trim()} name="groupCode"
                                                placeholder="Nhập mã nhóm quyền"  {...register("groupCode", { required: true })} />
                                            {/* {errors.groupCode && <span className='helper-text' ></span>} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm cea15270-db58-4d63-ac3f-b477c880d9e9"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Tên nhóm quyền</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={"w-textformfield wbaseItem-value w-row eada5e8e-edbd-443b-9579-71cc44317df2 " + (errors.groupName && 'helper-text')}
                                    name-field="RoleName" level={8} cateid={85} placeholder="Nhập tên nhóm quyền" helper-text={errors.groupName && ' Vui lòng nhập tên nhóm quyền'}>
                                    <div className="wbaseItem-value 0bb5e726-0339-4344-aaf9-91be0c4c0dd0"
                                        level={9} cateid={86}>
                                        <div className="textfield">
                                            <input maxLength={50} name="groupName"
                                                placeholder="Nhập tên nhóm quyền"  {...register("groupName", { required: true })} />


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row f37b5886-1fe9-419b-8248-1414fd5950f2"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value fe751ff7-2dba-488d-93d3-61003e9614eb" level={9}
                                        cateid={139}>Trạng thái</div>
                                    <div className="w-text wbaseItem-value fdb682b3-d2e0-4a6d-93f7-543d0c6b7052" level={9}
                                        cateid={139}>*</div>
                                </div>
                                {/* <div className={"w-textformfield wbaseItem-value w-row 3e5d9531-5f61-465f-b015-4ea3f6328036 "+ (errors.status  && 'helper-text')} helper-text={errors.status && 'Vui lòng chọn trạng thái'}
                                    name-field="Status" level={8} cateid={85} placeholder="Chọn trạng thái">
                                    <div className="wbaseItem-value 8a0ab00a-c0ba-47d7-bf50-c0dba42c46bb" 
                                        level={9} cateid={86}>
                                        <div className="textfield">
                                            <input id="textfield-8a0ab00a-c0ba-47d7-bf50-c0dba42c46bb" name="Status"
                                                placeholder="Chọn trạng thái" {...register("status", {required: true})} />
                                                {errors.status && <span className='error-span'>Vui lòng nhập mã nhóm quyền</span>}

                                        </div>
                                    </div>
                                    <div className="w-svg wbaseItem-value 19e52ee1-5039-4aa2-9527-9d68d1395474" level={9}
                                        cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z"
                                                fill="#28282999">
                                            </path>
                                        </svg>
                                    </div>
                                </div> */}
                                <div className={'select2-custom '}>
                                    <Controller
                                        name="status"
                                        {...register("status")}
                                        control={control}
                                        style={{ order: 2 }}
                                        // rules={{ required: true }}
                                        // helper-text={errors.orgId && 'Vui lòng chọn tổ chức'}
                                        render={({ field }) => (
                                            <Select2 {...field} 
                                            options={{placeholder:'Chọn trạng thái'}}
                                             data={[{ id: 1, name: 'Active' }, { id: 0, name: 'DeAvtice' }]} />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row f37b5886-1fe9-419b-8248-1414fd5950f2"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value fe751ff7-2dba-488d-93d3-61003e9614eb" level={9}
                                        cateid={139}>Loại quyền</div>
                                    <div className="w-text wbaseItem-value fdb682b3-d2e0-4a6d-93f7-543d0c6b7052" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={'select2-custom '}>
                                    <Controller
                                        name="groupType"
                                        {...register("groupType")}
                                        control={control}
                                        style={{ order: 2 }}
                                        render={({ field }) => (
                                            <Select2 {...field} isFirst={true} nameFirst={'Chọn loại quyền'} data={grouptype?.map((a,_)=>{return {id: a.code,name: a.name}})} />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Mô tả</div>

                                </div>
                                <div className={"w-textformfield wbaseItem-value w-row eada5e8e-edbd-443b-9579-71cc44317df2 " + (errors.description && 'helper-text')}
                                    name-field="RoleName" level={8} cateid={85} placeholder="Nhập tên nhóm quyền" helper-text={errors.description && ' Vui lòng nhập tên nhóm quyền'}>
                                    <div className="wbaseItem-value 0bb5e726-0339-4344-aaf9-91be0c4c0dd0"
                                        level={9} cateid={86}>
                                        <div className="textfield">
                                            <input maxLength={255} name="description"
                                                placeholder="Nhập tên nhóm quyền"  {...register("description")} />


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col ab4e0615-b2d9-4579-8c87-a9ab846d05f4" level={5}
                        cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 832029a6-517e-4c2f-8f2d-b733f0f2b1e9"
                            level={6} cateid={140}>
                            <div className="w-text wbaseItem-value 0967032f-451e-44eb-a648-6af85a3b9ed1" level={7} cateid={139}>
                                Danh sách quyền</div>
                        </div>
                        <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1, order: 3 }}>
                            <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>

                                <table className="w-table wbaseItem-value 711e581a-b519-472e-bcf3-e1337a541425" type={0}
                                    level={6} cateid={117}>
                                    <tbody>
                                        <tr className="table-row">
                                            <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row d29bcd93-d07b-42f4-a9a1-305f56e96c76"
                                                    level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 1d6b7411-7283-4f72-a24c-0ef2cbb3ecc2"
                                                        level={8} cateid={139}>App</div>
                                                </div>
                                            </td>
                                            <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 9806f7a1-1b9f-4ea8-b983-8139d4be6aad"
                                                    level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 0b799b01-f513-4b89-b6ba-103598dffbec"
                                                        level={8} cateid={139}>Feature code</div>
                                                </div>
                                            </td>
                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ec42cdd7-b4f7-4de4-b6d8-41120503525a"
                                                    level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 69df4156-9beb-4f8c-a0f4-ad4da65a7843"
                                                        level={8} cateid={139}>Chức năng</div>
                                                </div>
                                            </td>
                                            <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row c25a084f-354b-494a-99e8-f570b1a839cc"
                                                    level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value bee3d7d4-f6f2-40df-b1c2-809526c0f4ff"
                                                        level={8} cateid={139}>Chọn</div>
                                                </div>
                                            </td>
                                        </tr>
                                        {moduleList.map((item, index) => {
                                            return <tr key={item.id} className="table-row">
                                                <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row c9ba844c-65db-4222-b3cd-79811aea4fb7"
                                                        level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value 42c28ec9-9d7b-42cc-90b8-0838cb7c54c4"
                                                            level={8} cateid={139}>{item.workSpace}</div>
                                                    </div>
                                                </td>
                                                <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 52314eaa-4585-461c-b49c-383587c5a23f"
                                                        level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value 9e98ed09-f3f2-4b89-a522-4115522f039e"
                                                            level={8} cateid={139}>{item.moduleCode}</div>
                                                    </div>
                                                </td>
                                                <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 4e2549da-f62d-4bb0-bfa0-1a442ffb1a2c"
                                                        level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value fdcdab2c-07a8-4d70-b980-ec21b99f50d4"
                                                            level={8} cateid={139}>{item.moduleName}</div>
                                                    </div>
                                                </td>
                                                <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row a9ec3ebc-1c8c-4df5-a6be-6579bdcc3723"
                                                        level={7} cateid={140}>
                                                        <label className="w-check-box wbaseItem-value 4c37e9d6-cbbe-48bc-855f-0e38fd663ec8" level={8} cateid={79}>
                                                            <input onChange={() => OnchangeChecked(index)} type="checkbox" defaultChecked={item.check} name="View" />
                                                            <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                !id ? null : <div className="w-frame wbaseItem-value w-col 9eb18653-3703-4f95-a85b-74d4ca8ece6d" level={5} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row 46ce79f0-67dc-4f8c-9723-04842d3a41d6" level={6} cateid={140}>
                        <div className="w-text wbaseItem-value a6e5f17a-31da-467c-8796-0db6cf452e2f" level={7} cateid={139}>
                            Lịch sử</div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row 1e7979de-8c4b-44e4-8cd5-f8757b861613" wrap="wrap" level={6} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl 50140cd2-149a-4ead-b0fb-d3f80bfce450" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 1d33454e-07c2-4b49-83dc-02c3bbfa5e90" level={8} cateid={139}>Người tạo:</div>
                            <div className="w-text wbaseItem-value 82d8c317-da95-41d2-bd17-e5a1a4651a9d" level={8} cateid={139}>{data.createdBy}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 02200cc8-9be3-4ce3-9f17-28be0fced6f3" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 8b1c558c-3d19-4994-be37-503a66f5996b" level={8} cateid={139}>Ngày tạo:</div>
                            <div className="w-text wbaseItem-value 422aca98-e87e-4690-9e53-3ac8648e7e41" level={8} cateid={139}>{data.createdDate}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl e7040e4d-5db5-44b3-a879-e37e73c8e9c9" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value d692dff2-ab0c-4949-9fba-b6906bce6568" level={8} cateid={139}>Người thay đổi cuối:</div>
                            <div className="w-text wbaseItem-value 45defc28-b4bd-4186-bc28-a57e350ffd3f" level={8} cateid={139}>{data.modifiedBy}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl ad18ff4d-59d6-4ea4-ba2b-2721f6e549c4" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 907931d7-c4e2-41d0-8daf-1ddf0538948a" level={8} cateid={139}>Ngày thay đổi cuối:</div>
                            <div className="w-text wbaseItem-value dbb81084-bbd1-4d6f-b581-9e59651fb842" level={8} cateid={139}>{data.modifiedDate}</div>
                        </div>
                    </div>
                </div>
            }


            <div className="w-frame wbaseItem-value w-row a5650dcc-7a8c-43b6-a92e-13b1c4b2fc68" level={3}
                cateid={140}>
                <button onClick={() => onCancel(false)} type="reset" className="w-button wbaseItem-value w-row 0827b65b-f8dd-422d-9526-661a04e2e39b"
                    name-field="Button" level={4} cateid={29}>
                    <div className="w-text wbaseItem-value ed946f59-a67d-4391-8bd9-75ff48cd1b16" name-field="Text" level={5}
                        cateid={139}>Hủy</div>
                </button>
                <button type="submit" className="w-button wbaseItem-value w-row 99617da8-7d91-4e4d-b33b-f34e0eadac50"
                    name-field="Button" level={4} cateid={29}>
                    <div className="w-svg wbaseItem-value 4565f46e-45f7-418c-8968-a7510d4ee3bb" level={5} cateid={115}>
                        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M14.4893 3.5115C14.726 3.74818 14.726 4.13191 14.4893 4.36859L6.61053 12.2474C6.37385 12.4841 5.99011 12.4841 5.75343 12.2474L1.51101 8.00496C1.27433 7.76828 1.27433 7.38454 1.51101 7.14786C1.74769 6.91118 2.13142 6.91118 2.36811 7.14786L6.18198 10.9617L13.6322 3.5115C13.8689 3.27481 14.2526 3.27481 14.4893 3.5115Z"
                                fill="#FFFFFFFF">
                            </path>
                        </svg>
                    </div>
                    <div className="w-text wbaseItem-value bb1d2d55-76a9-4b06-b469-422a88dd411e" name-field="Text" level={5}
                        cateid={139}>{id ? 'Lưu' : 'Thêm mới'}</div>
                </button>
            </div>
        </form>
    )
}