import { Updatetieuchicodinh, baseurl, creategroup, detailgroup, getAllCriteriaByGroupId, listgroupall, rankList, rankupdate, updategroup, updateweightgroup } from "../../../../config/ConfigAPI";
import { deleteData, getData, postData, putData } from "../../../../core/BaseDA";

export default class ScoringDA {
    static getallRank = async () => {
        const url = `${baseurl}${rankList}`;
        const response = await getData(url);
        if (response.code === "200") {
            return response.data;
        }
        return null;
    };
    static getallgroup = async () => {
        const url = `${baseurl}${listgroupall}`;
        const response = await getData(url);
        if (response.code === "200") {
            return response.data;
        }
        return null;
    };
    static getallCriteriaBygroup = async (group, type) => {
        debugger;
        var query = '';
        if (group >= 0) {
            if (query) {
                query += '&alreadyInGroup=' + group;
            } else {
                query += '?alreadyInGroup=' + group;
            }
        }
        if (type) {
            if (query) {
                query += '&type=' + type;
            } else {
                query += '?type=' + type;
            }
        }
        const url = `${baseurl}${getAllCriteriaByGroupId}${query}`;
        const response = await getData(url);
        if (response.code === "200") {
            return response.data;
        }
        return null;
    };
    static EditRank = async (obj) => {
        const url = `${baseurl}${rankupdate}`;
        const response = await putData(url, { obj: obj });
        return response;
    };
    static Editgroup = async (obj, id) => {
        const url = `${baseurl}${updategroup}/${id}`;
        const response = await putData(url, { obj: obj });
        return response;
    };
    static UpdateCriterialFixvalue = async (obj) => {
        const url = `${baseurl}${Updatetieuchicodinh}`;
        const response = await postData(url, { obj: obj });
        return response;
    };
    static Editweightgroup = async (obj) => {
        const url = `${baseurl}${updateweightgroup}`;
        const response = await putData(url, { obj: obj });
        return response;
    };
    static addgroup = async (obj) => {
        const url = `${baseurl}${creategroup}`;
        const response = await postData(url, { obj: obj });
        return response;
    };
    static DelRank = async (id) => {
        const url = `${baseurl}${rankupdate}/${id}`;
        const response = await deleteData(url, { obj: null });
        return response;
    };
    static getgroupDetail = async (id) => {
        const url = `${baseurl}${detailgroup}/${id}`;
        const response = await getData(url);
        if (response.code === "200") {
            return response.data;
        }
        return null;
    };
    static getTieuChiDetails = async (id) => {
        const url = `${baseurl}scoring/criteria_value/get_by_scoring_criteria_id/${id}`;
        const response = await getData(url);
        if (response.code === "200") {
            return response.data;
        }
        return null;
    };
    static updateTieuChiKoCoDinh = async (id, { list = [] }) => {
        const url = `${baseurl}scoring/criteria_value/update/scoring_criteria_id/${id}`;
        const response = await postData(url, { obj: list });
        if(response.code === "200") {
            
        }
        return response;
    }
}   