import { getData, getDataFile, postData, postFormDataFile, putData, putHoSoData } from "../../../../core/BaseDA";
import { base_loginUrl, baseurl, containerId, gettaskall, jbpmHost } from "../../../../config/ConfigAPI";

export const getProcessTask = async (page, size, isReceived, query) => {
    const url = `${baseurl}${gettaskall}${query !== "" ? `${query}&pageNum=${page}&pageSize=${size}&isReceived=${isReceived}` : `?pageNum=${page}&pageSize=${size}&isReceived=${isReceived}`}`;
    const response = await getData(url);

    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const getTaskById = async (id) => {
    const url = `${baseurl}${gettaskall}?page=1&size=10&isReceived=1&loanApplicationCode=${id}`;
    const response = await getData(url);

    if (response.code === "200") {
        return response.data.content[0];
    }
    return null;
};

export const ngayHopDongDuKien = async (page, size, obj) => {
    const url = `${baseurl}monthly-payment-date/get-by-date?pageNum=${page}&pageSize=${size}`;
    const response = await postData(url, { obj: obj });
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const searchDTI = async (obj) => {
    const url = `${baseurl}dti/search_new`;
    const response = await postData(url, { obj: obj });

    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getTaskDetail = async ({ id }) => {
    const url = `${jbpmHost}/kie-server/services/rest/server/containers/${containerId}/processes/instances/${id}?withVars=true`;
    const response = await getData(url);
    return response;
}

export const getSubProcessTask = async ({ id }) => {
    const url = `${jbpmHost}/kie-server/services/rest/server/containers/${containerId}/processes/instances/${id}/processes`;
    const response = await getData(url);
    try {
        return response["process-instance"][0];
    } catch (error) {
        return null;
    }
}

export const getDTI = async ({ obj }) => {
    const url = `${baseurl}dti/get_additional_info`;
    const response = await postData(url, { obj: obj });
    return response;
}
export const hoSo_doneActionTemp = async (id, action, data) => {
    debugger
    const url = `${jbpmHost}/kie-server/services/rest/server/containers/JACCS-LOS_1.0.0-SNAPSHOT/tasks/${id}/states/completed?auto-progress=true`;
    data.action = action;
    if (action.includes("Send")) {
        data.r11A = {
            "fis.onboarding.process.model.cic.r11.R11AResponseDto": {
                "code": "",
                "message": "Dịch vụ R11A đã có phản hồi",
                "status": null,
                "productGwResponseDto": null,
                "productResponseDto": {
                    "h2H_TBLSP_DSTCTDQH": [
                        {
                            "masophieu": "20230818.R11A.010002845.9635",
                            "matctd": "01358003",
                            "ngaysl": "20210610",
                            "tentctd": "Ngân hàng TMCP Tiên Phong - Chi nhánh Hoàn Kiếm"
                        }
                    ],
                    "h2H_TBLSP_CTLOAIVAY": [
                        {
                            "masophieu": "20230818.R11A.010002845.9635",
                            "nhom3_VND": 0,
                            "duno_NHPT_VND": 0,
                            "noxau_KHAC_VND": 0,
                            "duno_THE_VND": 0,
                            "nhom1_VND": 813,
                            "loaivay": "03",
                            "nhom2_VND": 0,
                            "nhom4_VND": 0,
                            "nhom5_VND": 0,
                            "nhom4_USD": 0,
                            "nhom5_USD": 0,
                            "nhom2_USD": 0,
                            "noxau_KHAC_USD": 0,
                            "duno_NHPT_USD": 0,
                            "nhom3_USD": 0,
                            "duno_THE_USD": 0,
                            "nhom1_USD": 0
                        }
                    ],
                    "h2H_TBLSP_K1": {
                        "macic": "9939005972",
                        "masophieu": "20230818.R11A.010002845.9635",
                        "dkkd": null,
                        "socmt": "010002845,123456789124",
                        "tenkh": "Nguyễn Thị 2845",
                        "msthue": null,
                        "diachi": "Địa chỉ của khách hàng 2845",
                        "loaigtk": null,
                        "tgd_DAIDIENPL": null
                    },
                    "h2H_TBLSP_K3NX36": null,
                    "h2H_TBLSP_K3DN12": [
                        {
                            "masophieu": "20230818.R11A.010002845.9635",
                            "thang": "202307",
                            "dunovay": "-",
                            "tongduno": "-",
                            "dunothe": null
                        },
                        {
                            "masophieu": "20230818.R11A.010002845.9635",
                            "thang": "202306",
                            "dunovay": null,
                            "tongduno": null,
                            "dunothe": null
                        },
                        {
                            "masophieu": "20230818.R11A.010002845.9635",
                            "thang": "202305",
                            "dunovay": null,
                            "tongduno": null,
                            "dunothe": null
                        },
                        {
                            "masophieu": "20230818.R11A.010002845.9635",
                            "thang": "202304",
                            "dunovay": null,
                            "tongduno": null,
                            "dunothe": null
                        },
                        {
                            "masophieu": "20230818.R11A.010002845.9635",
                            "thang": "202303",
                            "dunovay": null,
                            "tongduno": null,
                            "dunothe": null
                        },
                        {
                            "masophieu": "20230818.R11A.010002845.9635",
                            "thang": "202302",
                            "dunovay": null,
                            "tongduno": null,
                            "dunothe": null
                        },
                        {
                            "masophieu": "20230818.R11A.010002845.9635",
                            "thang": "202301",
                            "dunovay": null,
                            "tongduno": null,
                            "dunothe": null
                        },
                        {
                            "masophieu": "20230818.R11A.010002845.9635",
                            "thang": "202212",
                            "dunovay": null,
                            "tongduno": null,
                            "dunothe": null
                        },
                        {
                            "masophieu": "20230818.R11A.010002845.9635",
                            "thang": "202211",
                            "dunovay": null,
                            "tongduno": null,
                            "dunothe": null
                        },
                        {
                            "masophieu": "20230818.R11A.010002845.9635",
                            "thang": "202210",
                            "dunovay": null,
                            "tongduno": null,
                            "dunothe": null
                        },
                        {
                            "masophieu": "20230818.R11A.010002845.9635",
                            "thang": "202209",
                            "dunovay": null,
                            "tongduno": null,
                            "dunothe": null
                        },
                        {
                            "masophieu": "20230818.R11A.010002845.9635",
                            "thang": "202208",
                            "dunovay": null,
                            "tongduno": null,
                            "dunothe": null
                        }
                    ],
                    "h2H_TBLSP_K3NGB": null,
                    "h2H_TBLSP_VAMC": null,
                    "h2H_TBLSP_K3N2_12": null,
                    "h2H_TBLSP_TTKHAC": null,
                    "h2H_TBLSP_NGUOITRACUU": {
                        "masophieu": "20230818.R11A.010002845.9635",
                        "diachi": "Lầu 15, toà nhà Centec số 72-74 Nguyễn Thị Minh Khai, phường 6, quận 3,  TP Hồ Chí Minh",
                        "donvitracuu": "Công ty Tài chính TNHH MTV Quốc tế Việt Nam JACCS",
                        "thoigianyc": "20230818 10:00",
                        "tentruycap": "h79808001jaccs",
                        "tt_TRALOI": "1",
                        "thoigiantl": "20230818 10:00"
                    },
                    "h2H_TBLSP_TRAIPHIEU": null
                }
            }
        }
    }
    let res = await putData(url, { obj: data });
    return res;
}
export const hoSo_doneAction = async (id, action, data) => {
    const url = `${jbpmHost}/kie-server/services/rest/server/containers/JACCS-LOS_1.0.0-SNAPSHOT/tasks/${id}/states/completed?auto-progress=true`;
    data.action = action;
    let res = await putData(url, { obj: data });
    return res;
}
export const delete_hoso = async (id) => {
    const url = `${jbpmHost}/kie-server/services/rest/server/containers/JACCS-LOS_1.0.0-SNAPSHOT/tasks/${id}/states/completed?auto-progress=true`;
    let res = await putData(url, { obj: {} });
    return res;
}

export const getSignPendingContractInfor = async ({ custId }) => {
    const url = `${base_loginUrl}eContract/searchInfo?sorts=modifiedDate,ASC`;
    const response = await postData(url, {
        obj: {
            "custId": custId
        }
    });
    return response;
}

export const getCreditHistories = async ({ cccd, phoneNo = "" }) => {
    const url = `${baseurl}flexcube/query-cus-credit-histories`;
    const response = await postData(url, {
        obj: {
            "idVal": cccd,
            "phoneNo": ""
        }
    });
    if (response.code === "200") {
        return response;
    }
    return null;
}

export const getScoring = async ({ obj }) => {
    const url = `${baseurl}scoring/get_scoring_score`;
    const response = await postData(url, { obj: obj });
    return response;
}

export const getQuestions = async () => {
    const url = `${baseurl}tham-dinh/questions`;
    const res = await getData(url);
    return res;
}

export const getCheckS37 = async ({ cccdCu }) => {
    const url = `${baseurl}tham-dinh/checkS37`;
    const res = await postData(url, { obj: { idCardOld: cccdCu } });
    if (res.code === "200") return res.data;
    return null;
}

export const getCheckPCB = async ({ obj }) => {
    const url = `${baseurl}tham-dinh/check_pcb`;
    const res = await postData(url, { obj: obj });
    if (res.code === "200") return res.data;
    return null;
}

export const getHoiTinR11 = async ({ diachi, cccd, tenKH }) => {
    const url = `${baseurl}tham-dinh/ask_r11A`;
    const res = await postData(url, {
        obj: { diaChi: diachi, soCMT: cccd, tenKH: tenKH }
        // obj: {
        //     "diaChi": "Địa chỉ của khách hàng 2845",
        //     "soCMT": "010002845",
        //     "tenKH": "Nguyễn Thị 2845"
        // }
    });
    if (res.code === "200") return res.data;
    return null;
}

export const getCheckR11 = async ({ diachi, cccd, tenKH }) => {
    const url = `${baseurl}tham-dinh/reply_r11A`;
    const r11HoiTinRes = await getHoiTinR11({ diachi: diachi, cccd: cccd, tenKH: tenKH });
    if (r11HoiTinRes) {
        const res = await postData(url, { obj: { maSoPhieu: r11HoiTinRes.maSoPhieu } });
        if (res.code === "200") return res.data;
    }
    return null;
}

export const layTongChiTieuTheoVung = async (code) => {
    const url = `${baseurl}location/living-expense-by-area?districtCode=${code}`
    const res = await getData(url);
    if (res.code === "200") {
        return res.data;
    } else {
        return null;
    }
}

// export const acceptFile = async (id) => {
//     const url = `${baseurl}ho-so/user-accept/${id}`;
//     const response = await putData(url);
//     return response;
// }
// export const cancelFile = async (id) => {
//     const url = `${baseurl}ho-so/user-cancel/${id}`;
//     const response = await putData(url);
//     return response;
// }
// export const asignFile = async (id, account) => {
//     const url = `${baseurl}ho-so/user-asign?idHoSo=${id}&targetUser=${account}`;
//     const response = await putData(url);
//     return response;
// }


export class GiaiNganDA {
    static updateData = async (taskId, obj) => {
        const url = `${jbpmHost}/kie-server/services/rest/server/containers/${containerId}/tasks/${taskId}/states/completed?auto-progress=true`;
        const response = await putHoSoData(url, { obj: obj });
        return response;
    }

    static uploadFile = async (loanId, docType, obj) => {
        const url = `${baseurl}disbursement-document?loanApplicationId=${loanId}&docType=${docType}`;
        const response = await postFormDataFile(url, { obj: obj });
        return response;
    }
    //TODO: check lại file 
    static getDocumentFile = async (docId) => {
        const url = `${baseurl}disbursement-document/${docId}`;
        try {
            const response = await getData(url);
            // if (response.code === "200") {
            //     const blob = new Blob([response.data], { type: "application/pdf" });
            //     return blob
            // } else {
            //     return null
            // }
            return response.data;
        } catch (error) {
            console.log("ERROR: ", error);
            return null;
        }
    }
}

export const getUserImage = async (cccd) => {
    const url = `${baseurl}khach-hangs/${cccd}/image/nfc`;
    const res = await getDataFile(url);
    debugger;
}