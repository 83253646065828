import { areaList, baseurl, orgtitleList, bankList, brandList, careerList, dealersList, districtsList, productList, wardsList, org, orglevelList, orgleadList, userGroupList } from "../../../../config/ConfigAPI";
import { getData } from "../../../../core/BaseDA";

export const getareaList = async (page, size, name) => {
    const url = `${baseurl}${areaList}?${name ? 'name=' + name : 'name='}&pageNum=${page}&pageSize=${size}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};


export const getbankList = async (page, size, name) => {
    const url = `${baseurl}${bankList}?${name ? 'name=' + name : 'name='}&pageNum=${page}&pageSize=${size}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getorgList = async (page, size, name) => {
    const url = `${baseurl}${org}?${name ? 'name=' + name : 'name='}&pageNum=${page}&pageSize=${size}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};


export const getbrandList = async (manufactureId, page, size, name) => {
    const url = `${baseurl}${brandList}/all?manufactureId=${manufactureId}&${name ? 'name=' + name : 'name='}&pageNum=${page}&pageSize=${size}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getorgLeadList = async (page, size, name) => {
    const url = `${baseurl}${orgleadList}?${name ? 'name=' + name : 'name='}&pageNum=${page}&pageSize=${size}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getcareerList = async (page, size, name) => {
    const url = `${baseurl}${careerList}?${name ? 'name=' + name : 'name='}&pageNum=${page}&pageSize=${size}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};


export const getdealersList = async (page, size, name) => {
    const url = `${baseurl}${dealersList}?${name ? 'name=' + name : 'name='}&pageNum=${page}&pageSize=${size}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const khoiTao_getDealerList = async () => {
    const url = `${baseurl}dealer/page`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};


export const getdistrictsList = async (page, size, code) => {
    const url = `${baseurl}${districtsList}?pageNum=${page}&pageSize=${size}&provinceCode=${code}&showAll=true`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getwardsList = async (page, size, code) => {
    const url = `${baseurl}${wardsList}?pageNum=${page}&pageSize=${size}&districtCode=${code}&showAll=true`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getbproductList = async (page, size, name) => {
    const url = `${baseurl}${productList}?${name ? 'name=' + name : 'name='}&pageNum=${page}&pageSize=${size}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};



export const getorgtitleList = async (page, size, name) => {
    const url = `${baseurl}${orgtitleList}?${name ? 'name=' + name : 'name='}&pageNum=${page}&pageSize=${size}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const getorglevelList = async () => {
    const url = `${baseurl}${orglevelList}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const getUsergroupList = async () => {
    const url = `${baseurl}${userGroupList}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const gettitleList = async (page, size) => {
    const url = `${baseurl}title/page?pageNum=${page}&pageSize=${size}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const getgrouptype = async () => {
    const url = `${baseurl}filter/group/type`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getProductCate = async (page, size, name) => {
    const url = `${baseurl}filter/product-category?${name ? 'name=' + name : 'name='}&pageNum=${page}&pageSize=${size}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getstatus = async ({ page = 1, size = 10, name = '', code = '', role = '', sort = '', isReceiver = '' }) => {
    const url = `${baseurl}trang-thai-ho-so?${name ? 'name=' + name : 'name='}&pageNum=${page}&pageSize=${size}&code=${code}&role=${role}&sort=${sort}`;
    // const url = `${baseurl}task/loan-application-status?${name ? 'name=' + name : 'name='}&isReceiver=${isReceiver}&pageNum=${page}&pageSize=${size}&code=${code}&role=${role}&sort=${sort}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getStatusListHoSo = async ({ isReceiver = '' }) => {
    const url = `${baseurl}task/loan-application-status?isReceiver=${isReceiver}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
}

export const getManufacturesList = async (page, size, name) => {
    const url = `${baseurl}filter/manufactures?pageNum=${page}&pageSize=${size}&name=${name}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data.content;
    }
    return [];
};

export const getPurposeList = async (page, size, name) => {
    const url = `${baseurl}filter/purpose?pageNum=${page}&pageSize=${size}&name=${name}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data.content;
    }
    return [];
};

// danh sách chương trình vay
export const getLoanProgramList = async (page, size, sort) => {
    const url = `${baseurl}loanprogram/get-all?pageNum=${page}&pageSize=${size}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data.pageData;
    }
    return [];
};

export const getEducationLevelList = async (page, size, name) => {
    const url = `${baseurl}filter/education-level?pageNum=${page}&pageSize=${size}&showAll=true`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return [];
};

export const getProvincesList = async (page, size, name) => {
    const url = `${baseurl}filter/provinces?pageNum=${page}&pageSize=${size}&showAll=true`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return [];
};

export const getresidentList = async (page, size) => {
    const url = `${baseurl}filter/resident-status?pageNum=${page}&pageSize=${size}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return [];
};
export const getListRelationship = async (page, size, type) => {
    const url = `${baseurl}filter/relationship?pageSize=${size}&pageNum=${page}&type=${type}`
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return [];
};
