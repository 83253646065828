import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import { useForm, Controller } from 'react-hook-form';
import Select2 from '../../components/select2/Select2';
import $ from "jquery";

import '../../css/nhomquyen/add.css';
import '../../css/hanmuc/hanmuc.css';
import { PopupType, WPopup } from '../../components/Popup/Popup';
import { HanmucCreate, HanmucUpdate, getDetailHanmuc } from '../../redux/middleware/api/hanmuc/hanmucDA';
import { getbproductList } from '../../redux/middleware/api/Filter/Filter';
import { Ultis } from '../../core/utils';
export default function ThemMoiHanMuc() {
    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();
    const inputRefs = useRef([]);
    const [limitDetail, setlimitDetail] = useState([]);
    const [lstProduct, setlstProduct] = useState([]);
    const [data, setData] = useState({});
    const [isCancel, setisCancel] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    let { id } = useParams();
    const notificationAlert = useRef();

    useEffect(() => {
        _InitData();
        getlistproduct();
    }, []);
    const _InitData = async () => {
        if (id) {
            const res = await getDetailHanmuc(id);
            if (res != null) {

                setData(res);
                setlimitDetail(res.limitDetails ?? []);
                setValue('name', res.name)
                setValue('code', res.code)
                setValue('description', res.description)
                setValue('status', res.status)
            }

        } else {
            setValue('status', 1)
        }

    }
    const changeValLstDetail = (event) => {
        const index = parseInt(event.target.getAttribute('data-index'));
        // event.target.parentNode.parentNode.parentNode.classList.remove('helper-text');
        // setlimitDetail((limitDetail) => limitDetail.map())
        if(!isNaN(event.target.value)) event.target.value = Ultis.money(event.target.value);

        setlimitDetail((limitDetail) =>
            limitDetail.map((item, a) =>
                a === index ? event.target.name.includes('productId') ? { ...item, ['productId']: event.target.value } : { ...item, [event.target.name]: event.target.value.replace(',', '') } : item
            ));
        var lstIndexInput = [];

        limitDetail.map((item, index) => {
            if (item.suggestedLimit == 0 && item.approvalLimit == 0) {
                lstIndexInput.push(index);
            }
        });
        lstIndexInput.forEach((a) => {
            $('.suggestedLimit_' + a).removeClass('helper-text');
            $('.approvalLimit_' + a).removeClass('helper-text');
        });

    }
    const getlistproduct = async (name) => {
        const response = await getbproductList(1, 100, name);
        if (response != null) {
            setlstProduct(response.content)
        }
    }
    const onDeleteLimit = (index) => {
        const updateData = [...limitDetail];
        updateData.splice(index, 1)
        setlimitDetail(updateData);
    }
    const onCancel = (val) => {
        setisCancel(val);
        if (val == true) {
            navigate('/admin/han-muc');
        }
    }
    const onSumit = async (event) => {

        var lstIndex = [];
        var lstIndexInput = [];
        limitDetail.map((item, index) => {
            if (item.productId === 0) {
                lstIndex.push(index);
            }
            if (item.suggestedLimit == 0 && item.approvalLimit == 0) {
                lstIndexInput.push(index);
            }
        })
        if (lstIndex.length > 0) {
            lstIndex.forEach((a) => {
                $('.productId_' + a).addClass('helper-text');
                // $('.productId_' + a).attr('helper-text', 'Yêu cầu chọn sản phẩm');
            });
        } else if (lstIndexInput.length > 0) {
            lstIndexInput.forEach((a) => {
                $('.suggestedLimit_' + a).addClass('helper-text');
                $('.approvalLimit_' + a).addClass('helper-text');
                notify(notificationAlert, ERROR, 'Yêu cầu nhập hạn mức đề xuất hoặc hạn mức phê duyệt')
                // $('.productId_' + a).attr('helper-text', 'Yêu cầu chọn sản phẩm');
            });
        } else {
            event.limitDetails = limitDetail;
            if (event.status) {
                event.status = parseInt(event.status)
            }
            if (id) {
                event.id = id;
                const res = await HanmucUpdate(event);
                if (res.code === '200') {
                    notify(notificationAlert, SUCCESS, 'Chỉnh sửa hạn mức thành công.')
                    navigate('/admin/han-muc');

                } else {
                    notify(notificationAlert, ERROR, res.message);
                }
            } else {
                const res = await HanmucCreate(event);
                if (res.code === '200') {
                    notify(notificationAlert, SUCCESS, 'Thêm mới hạn mức thành công.')
                    navigate('/admin/han-muc');
                } else {
                    notify(notificationAlert, ERROR, res.message);
                }
            }
        }





    }
    const onchangeInput = (event) => {

    }
    const addLimitDetail = () => {
        const updateData = [...limitDetail];
        updateData.push({
            approvalLimit: 0,
            id: 0,
            productId: 0,
            productName: '',
            suggestedLimit: 0
        })
        setlimitDetail(updateData);
    }
    return (
        <form onSubmit={handleSubmit(onSumit)} autoComplete='off' className="w-frame w-form wbaseItem-value w-col e14e0b64-a97e-4309-84d4-c901a202b4da" level={2}
            cateid={128}>
            <NotificationAlert ref={notificationAlert} />
            {
                isCancel == false &&
                <WPopup
                    type={PopupType.WARNING}
                    title={!id ? 'Hủy thêm mới hạn mức' : 'Hủy chỉnh sửa hạn mức'}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => onCancel(true)}
                    cancelAction={() => onCancel(null)}
                    cancelButton={true}
                    cancelTitle='Giữ lại'
                    message={'Những thông tin vừa bạn vừa nhập sẽ không được lưu lại'}
                />
            }
            <div className="w-frame wbaseItem-value w-col 55169930-5156-452b-a8d9-559b160d8fd0" level={3}
                cateid={140}>
                <div className="w-frame wbaseItem-value w-row e4c8db4f-a292-4630-840f-4321cea9a96c" level={4}
                    cateid={140}>
                    <div className="w-frame wbaseItem-value w-col 61c5c3cd-5188-43c8-91b5-1fe7b03e3c39" level={5}
                        cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 4f044728-0e07-4e2f-aa4b-106a7f97b7af" wrap="nowrap"
                            level={6} cateid={140}>
                            <div className="w-text wbaseItem-value 631d5e3e-5ff1-42bb-9224-6d2189c8b545" level={7} cateid={139}>
                                {id? 'Chỉnh sửa' : 'Thêm mới'} hạn mức&nbsp;</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row breadcrumb 86cc162c-67d1-4a5a-a8ef-8761295ca239"
                            level={6} cateid={140}>
                            <button type="button"
                                className="w-button wbaseItem-value w-row 29a214b1-e278-4d04-959e-1b70adc3b2a9" wrap="nowrap"
                                name-field="Button" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value ecdef2e8-1693-46bd-99d2-d5bd00346b98" name-field="Text"
                                    level={8} cateid={139}>Quản trị hệ thống</div>
                                <div className="w-svg wbaseItem-value 22474178-c66e-4f8b-8c41-88a03a8f8a10" level={8}
                                    cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z"
                                            fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                            <button type="button"
                                className="w-button wbaseItem-value w-row 7807c449-be3d-4411-97d2-3e93e42ae5a6" wrap="nowrap"
                                name-field="Button" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value 9c18f47a-0aa3-4dad-86d7-e83232c6d711" name-field="Text"
                                    level={8} cateid={139}>hạn mức</div>
                                <div className="w-svg wbaseItem-value 5384ce20-5697-4020-a917-6d5093852462" level={8}
                                    cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z"
                                            fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                            <div className="w-text wbaseItem-value 924a1cb9-ccdd-4314-b624-d40f8f7661b1" level={7} cateid={139}>
                            {id? 'Chỉnh sửa' : 'Thêm mới'}</div>
                        </div>
                    </div>
                </div>
                <div className="w-frame wbaseItem-value w-col ee2b2d2c-8eee-4daa-b96e-d7fc7b406955" scroll="true"
                    level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col f2bae906-3670-41a0-a977-fb7dc48b1d55" level={5}
                        cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 57e8967a-18fa-4213-87ea-7467a2618436" wrap="nowrap"
                            level={6} cateid={140}>
                            <div className="w-text wbaseItem-value d7713971-43be-4840-90db-212f89a383b7" level={7} cateid={139}>
                                Thông tin hạn mức</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 21fab225-9cef-43b4-8b9a-dc025c921424" wrap="wrap"
                            level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm d9dc8dc9-8c5b-4681-973a-364163c17c83"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row 7e81084d-5b16-4968-a7c6-ef158e9c179d"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value f2285d87-a06c-4911-b28d-2df95eb2a10b" level={9}
                                        cateid={139}>Mã hạn mức</div>
                                    <div className="w-text wbaseItem-value 0db6357e-7422-4399-83d4-0a298d7b157a" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={"w-textformfield wbaseItem-value w-row e8d4f42c-9efd-47a7-9f46-6a11cd4462a1 " + (errors.code && 'helper-text')}
                                    name-field="RoleID" level={8} cateid={85} helper-text={errors.code && 'Nhập mã hạn mức'} placeholder="Nhập mã hạn mức">
                                    <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f" name-field="Textfield"
                                        level={9} cateid={86}>
                                        <div className="textfield">
                                            <input id="textfield-ee5c5ee0-f72f-480e-9462-53f6362a2d1f" name="code"
                                                placeholder="Nhập mã hạn mức" maxLength={20} onInput={(ev) => ev.target.value = ev.target.value.trim()} {...register("code", { required: true })} />
                                            {/* {errors.code && <span className='helper-text' >
                                            </span>} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm cea15270-db58-4d63-ac3f-b477c880d9e9"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Tên hạn mức</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={"w-textformfield wbaseItem-value w-row eada5e8e-edbd-443b-9579-71cc44317df2 " + (errors.name && 'helper-text')}
                                    name-field="RoleName" level={8} cateid={85} placeholder="Nhập tên hạn mức" helper-text={errors.name && ' Vui lòng nhập tên hạn mức'}>
                                    <div className="wbaseItem-value 0bb5e726-0339-4344-aaf9-91be0c4c0dd0" name-field="Textfield"
                                        level={9} cateid={86}>
                                        <div className="textfield">
                                            <input id="textfield-0bb5e726-0339-4344-aaf9-91be0c4c0dd0" name="name"
                                                placeholder="Nhập tên hạn mức"  {...register("name", { required: true })} />


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm cea15270-db58-4d63-ac3f-b477c880d9e9"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Mô tả</div>

                                </div>
                                <div className={"w-textformfield wbaseItem-value w-row eada5e8e-edbd-443b-9579-71cc44317df2 "}
                                    name-field="RoleName" level={8} cateid={85} placeholder="Nhập mô tả">
                                    <div className="wbaseItem-value 0bb5e726-0339-4344-aaf9-91be0c4c0dd0" name-field="Textfield"
                                        level={9} cateid={86}>
                                        <div className="textfield">
                                            <input id="textfield-0bb5e726-0339-4344-aaf9-91be0c4c0dd0" name="description"
                                                placeholder="Nhập mô tả"   {...register("description")} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row f37b5886-1fe9-419b-8248-1414fd5950f2"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value fe751ff7-2dba-488d-93d3-61003e9614eb" level={9}
                                        cateid={139}>Trạng thái</div>
                                    <div className="w-text wbaseItem-value fdb682b3-d2e0-4a6d-93f7-543d0c6b7052" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={'select2-custom ' + (errors.status && 'helper-text')} helper-text={errors.status && ' Vui lòng chọn trạng thái'} >
                                    <Controller
                                        name="status"
                                        {...register("status")}
                                        control={control}
                                        style={{ order: 2 }}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select2 {...field} data={[{ id: 1, name: 'Active' }, { id: 0, name: 'DeActive' }]}
                                                options={{ placeholder: 'Chọn trạng thái' }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="w-frame wbaseItem-value w-col ab4e0615-b2d9-4579-8c87-a9ab846d05f4" level={5}
                        cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 832029a6-517e-4c2f-8f2d-b733f0f2b1e9" wrap="nowrap"
                            level={6} cateid={140}>
                            <div className="w-text wbaseItem-value 0967032f-451e-44eb-a648-6af85a3b9ed1" level={7} cateid={139}>
                                Danh sách</div>
                            <div className="w-frame wbaseItem-value w-row 3cd63587-ea54-451d-adb3-f2c0b3d0faac" level={10} cateid={140}>
                                <button type="button" onClick={()=>addLimitDetail()}
                                    className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                                    name-field="Button" level={7} cateid={29}>
                                    <div className="w-svg wbaseItem-value a3e0f6f2-4d22-4977-ab6f-90d0568e534f" level={8}
                                        cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                                fill="#FFFFFFFF" />
                                        </svg>
                                    </div>
                                    <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3"
                                        level={8} cateid={139}>Thêm</div>
                                </button>
                            </div>
                        </div>
                        <table className="w-table wbaseItem-value acdb17f7-7298-4da8-9589-dbe9ebc03d2d" type={0} level={9} cateid={117}>
                            <tbody>
                                <tr className="table-row">
                                    <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 98a29077-b149-4be9-90f6-10a9cbd1ddca" level={10} cateid={140}>
                                            <div className="w-text wbaseItem-value 659f6468-fe90-4dce-bc21-bb612eccd123" level={11} cateid={139}>Sản phẩm</div>
                                        </div>
                                    </td>
                                    <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row f9e32d7a-e4cc-4326-9119-392e96344559" level={10} cateid={140}>
                                            <div className="w-text wbaseItem-value bc9e8f64-c88f-4d95-baca-cf39b20eb113" level={11} cateid={139}>Hạn mức đề xuất</div>
                                        </div>
                                    </td>
                                    <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row ae22ea9e-077b-432c-bc1c-a9a57538c9fd" level={10} cateid={140}>
                                            <div className="w-text wbaseItem-value 87de7788-7f34-42fb-8b78-df6a4710564d" level={11} cateid={139}>Hạn mức phê duyệt</div>
                                        </div>
                                    </td>
                                    <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 5116fc61-435f-4195-a91d-55c670d3e7f2" level={10} cateid={140}>
                                        </div>
                                    </td>
                                </tr>

                                {limitDetail.map((item, index) => {
                                    return <tr key={item.id} className="table-row">
                                        <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 27a0c60c-9b7a-43ec-a713-a3d89589da97" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value 1fb7132e-3bf7-424b-b832-8f0f73847d52" level={11} cateid={139}>{index + 1}</div>
                                            </div>
                                        </td>
                                        <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row d5e0c5cf-02b9-42d5-a909-2959f22b7200" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value d56fe0b4-c9b1-4cf8-9fef-704fe17c8929" level={11} cateid={139}>{item.code}</div>
                                            </div>
                                        </td>
                                        <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row abe4b1fc-38a3-4c48-80f0-fe1e5bc2c05d" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value b728612a-b2fd-4a35-8ec6-1c98652a252c" level={11} cateid={139}>{item.name}</div>
                                            </div>
                                        </td>
                                        <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 61a3e312-f25a-4ac9-94ea-d8fb9ccfc65c" level={10} cateid={140}>
                                                <div onClick={() => onDeleteLimit(index)} className="w-svg wbaseItem-value 35b283d3-1bcd-42e0-b314-c75a02d7e19f" level={11} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                        </path>
                                                        <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                })
                                }

                            </tbody>
                        </table>
                    </div> */}
                    <div className="w-frame wbaseItem-value w-col 54a6b115-6273-45ee-9416-8827186841ec" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row a8c74288-b250-4c25-b92e-8eb7dfa853de" level={6} cateid={140}>
                            <div className="w-text wbaseItem-value a1271bc0-5ace-4f6d-b3c0-5ef9eb6338bc" level={7} cateid={139}>Danh sách</div>
                            <div className="w-frame wbaseItem-value w-row 3cd63587-ea54-451d-adb3-f2c0b3d0faac" level={10} cateid={140}>
                                <button type="button" onClick={() => addLimitDetail()}
                                    className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                                    name-field="Button" level={7} cateid={29}>
                                    <div className="w-svg wbaseItem-value a3e0f6f2-4d22-4977-ab6f-90d0568e534f" level={8}
                                        cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                                fill="#FFFFFFFF" />
                                        </svg>
                                    </div>
                                    <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3"
                                        level={8} cateid={139}>Thêm hạn mức</div>
                                </button>
                            </div>
                        </div>
                        <table className="w-table wbaseItem-value e2b5e8db-e084-45fa-afd8-3d00b4b3a809" type={0} level={6} cateid={117}>
                            <tbody>
                                <tr className="table-row">
                                    <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row ce7c97d2-0955-4769-89b1-cc79f2aa896f" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 13465ac2-6654-4ae4-a081-4adee44c33dc" level={8} cateid={139}>Sản phẩm</div>
                                            <div className="w-text wbaseItem-value 1ee4cdfa-5a1c-47a0-b360-e3bb1112e775" level={8} cateid={139}>*</div>
                                        </div>
                                    </td>
                                    <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row c21901a5-43c1-4b4b-a3aa-847f9feabb13" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 0b7e5333-c385-474a-a021-98228919570d" level={8} cateid={139}>Hạn mức đề xuất</div>
                                        </div>
                                    </td>
                                    <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 76b29adb-18d0-4631-b783-25247da0a59a" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 2af12727-5875-4ab7-94aa-e8453cfc1437" level={8} cateid={139}>Hạn mức phê duyệt</div>
                                        </div>
                                    </td>
                                    <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row f3db17fd-e591-4138-82d4-8d4e34d281a7" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value a3d1c899-e38b-4a2a-b94c-115fc03a7f51" level={8} cateid={139}>Xóa</div>
                                        </div>
                                    </td>
                                </tr>
                                {
                                    limitDetail.map((item, index) => {
                                        return <tr key={item.productId} className="table-row">
                                            <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row c7a93308-b15b-461a-acc7-3ce7383b3e88" level={7} cateid={140}>
                                                    {/* <div className="w-textformfield wbaseItem-value w-row 96dc92e9-738d-412d-b4ed-a846d7e936a9" name-field="Product" level={8} cateid={85} placeholder="Chọn sản phẩm" validate="[{&quot;Type&quot;:10,&quot;Message&quot;:&quot;Trường không được bỏ trống!&quot;}]">
                                                <div className="wbaseItem-value 2c265d99-9b8a-4583-aa7c-a376423f3e13" name-field="Textfield" level={9} cateid={86}>
                                                    <div className="textfield">
                                                        <input name="Product" placeholder="Chọn sản phẩm" />
                                                    </div>
                                                </div>
                                                <div className="w-svg wbaseItem-value 3f802213-e181-40e0-a0b3-f4638454f2ac" level={9} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z" fill="#28282999">
                                                        </path>
                                                    </svg>
                                                </div>
                                                
                                            </div> */}
                                                    <div className={`select2-custom productId_${index}`}>
                                                        <Controller
                                                            name={"productId_" + index}
                                                            // {...register("productId_" + index)}
                                                            control={control}
                                                            style={{ order: 2 }}
                                                            // rules={{ required: true }}
                                                            // helper-text={errors.productId && 'Vui lòng chọn khu vực'}
                                                            render={({ field }) => (
                                                                <Select2   {...field} isFirst={true} data={lstProduct} value={item.productId} data-index={index} nameFirst={'Chọn sản phẩm'} onChange={changeValLstDetail} />
                                                            )}
                                                        />
                                                    </div>

                                                </div>
                                            </td>
                                            <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 1339efd5-c346-41c5-b3cb-d68cb8e3c5a6" level={7} cateid={140}>
                                                    <div className={"w-textformfield wbaseItem-value w-row 13f1607e-350d-4c48-9fe4-c4a02a76c600 suggestedLimit_" + index} name-field="LimitSuggest" level={8} cateid={85} placeholder="Nhập hạn mức dề xuất" validate="[{&quot;Type&quot;:10,&quot;Message&quot;:&quot;Trường không được bỏ trống!&quot;}]">
                                                        <div className="wbaseItem-value 85355487-8751-4020-bec8-77905a8c2ff9" name-field="Textfield" level={9} cateid={86}>
                                                            <div className="textfield">
                                                                <input maxLength={30} name="suggestedLimit" data-index={index} onChange={(ev) => ev.target.value = ev.target.value.replace(/\D/g, "")} defaultValue={Ultis.money(item.suggestedLimit)} onBlur={changeValLstDetail} placeholder="Nhập hạn mức dề xuất" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 807b9ae3-2b78-4fcd-9d68-893c1431822b" level={7} cateid={140}>
                                                    <div className={"w-textformfield wbaseItem-value w-row cd078f37-eefd-458a-a035-dba84a46b5cf approvalLimit_" + index} name-field="LimitApproval" level={8} cateid={85} placeholder="Nhập hạn mức phê duyệt" validate="[{&quot;Type&quot;:10,&quot;Message&quot;:&quot;Trường không được bỏ trống!&quot;}]">
                                                        <div className="wbaseItem-value 1ce2e65d-ec91-4023-8066-0984b16b6a2a" name-field="Textfield" level={9} cateid={86}>
                                                            <div className="textfield">
                                                                <input maxLength={30} name="approvalLimit" data-index={index} defaultValue={Ultis.money(item.approvalLimit)} onChange={(ev) => ev.target.value = ev.target.value.replace(/\D/g, "")} onBlur={changeValLstDetail} placeholder="Nhập hạn mức phê duyệt" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row f3f00355-a123-41d8-82d7-0f0b8118a648" level={7} cateid={140}>
                                                    <div onClick={() => onDeleteLimit(index)} className="w-svg wbaseItem-value de717542-9eee-4ae6-81ce-952f97cfb2a3" level={8} cateid={115}>
                                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                            </path>
                                                            <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }


                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            {
                !id ? null : <div className="w-frame wbaseItem-value w-col 9eb18653-3703-4f95-a85b-74d4ca8ece6d" level={5} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row 46ce79f0-67dc-4f8c-9723-04842d3a41d6" level={6} cateid={140}>
                        <div className="w-text wbaseItem-value a6e5f17a-31da-467c-8796-0db6cf452e2f" level={7} cateid={139}>
                            Lịch sử</div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row 1e7979de-8c4b-44e4-8cd5-f8757b861613" wrap="wrap" level={6} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl 50140cd2-149a-4ead-b0fb-d3f80bfce450" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 1d33454e-07c2-4b49-83dc-02c3bbfa5e90" level={8} cateid={139}>Người tạo:</div>
                            <div className="w-text wbaseItem-value 82d8c317-da95-41d2-bd17-e5a1a4651a9d" level={8} cateid={139}>{data.createdBy}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 02200cc8-9be3-4ce3-9f17-28be0fced6f3" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 8b1c558c-3d19-4994-be37-503a66f5996b" level={8} cateid={139}>Ngày tạo:</div>
                            <div className="w-text wbaseItem-value 422aca98-e87e-4690-9e53-3ac8648e7e41" level={8} cateid={139}>{data.createdDate}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl e7040e4d-5db5-44b3-a879-e37e73c8e9c9" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value d692dff2-ab0c-4949-9fba-b6906bce6568" level={8} cateid={139}>Người thay đổi cuối:</div>
                            <div className="w-text wbaseItem-value 45defc28-b4bd-4186-bc28-a57e350ffd3f" level={8} cateid={139}>{data.modifiedBy}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl ad18ff4d-59d6-4ea4-ba2b-2721f6e549c4" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 907931d7-c4e2-41d0-8daf-1ddf0538948a" level={8} cateid={139}>Ngày thay đổi cuối:</div>
                            <div className="w-text wbaseItem-value dbb81084-bbd1-4d6f-b581-9e59651fb842" level={8} cateid={139}>{data.modifiedDate}</div>
                        </div>
                    </div>
                </div>
            }


            <div className="w-frame wbaseItem-value w-row a5650dcc-7a8c-43b6-a92e-13b1c4b2fc68" level={3}
                cateid={140}>
                <button onClick={() => onCancel(false)} type="button" className="w-button wbaseItem-value w-row 0827b65b-f8dd-422d-9526-661a04e2e39b"
                    name-field="Button" level={4} cateid={29}>
                    <div className="w-text wbaseItem-value ed946f59-a67d-4391-8bd9-75ff48cd1b16" level={5}
                        cateid={139}>Hủy</div>
                </button>
                <button type="submit" className="w-button wbaseItem-value w-row 99617da8-7d91-4e4d-b33b-f34e0eadac50"
                    name-field="Button" level={4} cateid={29}>
                    <div className="w-svg wbaseItem-value 4565f46e-45f7-418c-8968-a7510d4ee3bb" level={5} cateid={115}>
                        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M14.4893 3.5115C14.726 3.74818 14.726 4.13191 14.4893 4.36859L6.61053 12.2474C6.37385 12.4841 5.99011 12.4841 5.75343 12.2474L1.51101 8.00496C1.27433 7.76828 1.27433 7.38454 1.51101 7.14786C1.74769 6.91118 2.13142 6.91118 2.36811 7.14786L6.18198 10.9617L13.6322 3.5115C13.8689 3.27481 14.2526 3.27481 14.4893 3.5115Z"
                                fill="#FFFFFFFF">
                            </path>
                        </svg>
                    </div>
                    <div className="w-text wbaseItem-value bb1d2d55-76a9-4b06-b469-422a88dd411e" level={5}
                        cateid={139}>{id ? 'Lưu' : 'Thêm mới'}</div>
                </button>
            </div>
        </form>
    )
}