import { useNavigate } from "react-router-dom";

import DashboardSC from "../pages/DashBoard/Dashboard-SC";

import DanhSachHoSoVay from "../pages/HoSoVay/xemHoSo-DanhSachHoSo";

import HoSoChoXuLy from "../pages/CongViecCuaToi/HoSo-ChoXuLy";
import HoSoChoTiepNhan from "../pages/CongViecCuaToi/HoSo-ChoTiepNhan";
import HoSoVay from "../pages/HoSoVay";
import Temp from "../pages/HoSoVay/XemHoSo-Temp";
import NhomQuyen from "../pages/NhomQuyen/NhomQuyen";
import ThemMoiNhomQuyen from "../pages/NhomQuyen/ThemMoiNhomQuyen";
import UserList from "../pages/User/UserList";
import CreateAndEditUser from "../pages/User/CreateAndEditUser";

//HoSo
import BoSungHoSo from "../pages/CongViecCuaToi/HoSo-BoSung/BoSungHoSo";
import KhoiTaoHoSo from "../pages/CongViecCuaToi/HoSo-KhoiTao/KhoiTaoHoSo";
//
import ModuleThamDinh from "../pages/CongViecCuaToi/ThamDinh-XuLy/ModuleThamDinh";
import ModuleGiaiNgan from "../pages/CongViecCuaToi/Module-GiaiNgan/ModuleGiaiNgan";
import DSHanMuc from "../pages/HanMuc/DSHanMuc";
import DanhSachCocau from "../pages/CoCauToChuc/CoCau-ToChuc/CauTrucToChuc";
import UserListMobile from "../pages/UserMobile/UserListMobile";
import CreateAndEditUserMobile from "../pages/UserMobile/CreateAndEditUserMobile";
import ThemMoiHanMuc from "../pages/HanMuc/ThemMoiHanMuc";
import Baohiem from "../pages/BaoHiem/Baohiem";
import Themmoibaohiem from "../pages/BaoHiem/ThemMoiBaohiem";
import Chungtu from "../pages/Chungtu/Chungtu";
import Themmoichungtu from "../pages/Chungtu/ThemMoiChungTu";
import Daily from "../pages/Daily/Daily";
import Themmoidaily from "../pages/Daily/ThemMoiDaily";
import TaoHopDong from "../pages/CongViecCuaToi/HopDong-Tao/TaoHopDong";
import KyHopDong from "../pages/CongViecCuaToi/HopDong-Ky/KyHopDong";
import BlackList from "../pages/BlackList/BlackList";
import Themmoicocau from "../pages/CoCauToChuc/CoCau-ToChuc/Themmoicocau";
import Chuongtrinhvay from "../pages/W_ChuongTrinhVay/Chuongtrinhvay";
import ThemMoiChuongtrinhvay from "../pages/W_ChuongTrinhVay/ThemMoiChuongtrinhvay";
import DanhSachNhanHieu from "../pages/Nhanhieu/Nhanhieu";
import Xephang from "../pages/TieuChiScroing/Xephang";
import Nhomtieuchi from "../pages/TieuChiScroing/Nhomtieuchi";
import Editgroup from "../pages/TieuChiScroing/Editgroup";
import Tieuchi from "../pages/TieuChiScroing/Tieuchi";
import EditTieuchiCodinh from "../pages/TieuChiScroing/EditTieuchiCodinh";
import EditTieuchiKoCodinh from "../pages/TieuChiScroing/EditTieuChiKoCoDinh";

// export default function PrivateRoute() {
//     const [auth, setAuth] = useAuth();
//     const [ok, setOk] = useState(false);

//     const navigate = useNavigate();
//     useEffect(() => {

//     })

export const UserNavigate = () => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return { navigateTo };
};

var routes = [
  // Dashboard
  {
    path: "/dashboard-sc",
    name: "Dashboard",
    icon: "",
    component: <DashboardSC />,
    layout: "",
  },
  // Ho so vay
  {
    path: "/danh-sach-ho-so-vay",
    name: "Danh sách hồ sơ vay",
    icon: "",
    component: <DanhSachHoSoVay />,
    layout: "",
  },
  {
    path: "/danh-sach-ho-so-vay/:id",
    name: "",
    icon: "",
    component: <Temp />,
    layout: "",
  },
  // Cong viec
  {
    path: "/ho-so-cho-tiep-nhan",
    name: "",
    icon: "",
    component: <HoSoChoTiepNhan />,
    layout: "",
  },
  {
    path: "/ho-so-cho-xu-ly",
    name: "",
    icon: "",
    component: <HoSoChoXuLy />,
    layout: "",
  },
  // Cho xu ly
  {
    path: "/ho-so-cho-xu-ly/tao-hop-dong",
    name: "",
    icon: "",
    component: <TaoHopDong />,
    layout: "",
  },
  {
    path: "/ho-so-cho-xu-ly/ky-hop-dong",
    name: "",
    icon: "",
    component: <KyHopDong />,
    layout: "",
  },
  // tham dinh
  {
    path: "/ho-so-cho-xu-ly/xu-ly-tham-dinh",
    name: "",
    icon: "",
    component: <ModuleThamDinh />,
    layout: "",
  },
  {
    path: "/ho-so-cho-xu-ly/bo-sung-tham-dinh",
    name: "",
    icon: "",
    component: <ModuleThamDinh />,
    layout: "",
  },
  // {
  //   path: "/ho-so-cho-xu-ly/phe-duyet-tham-dinh",
  //   name: "",
  //   icon: "",
  //   component: <PheDuyetThamDinh />,
  //   layout: "",
  // },
  {
    path: "/ho-so-cho-xu-ly/cho-phe-duyet",
    name: "",
    icon: "",
    component: <ModuleThamDinh />,
    layout: "",
  },
  // giai ngan
  {
    path: "/ho-so-cho-xu-ly/yeu-cau-giai-ngan",
    name: "",
    icon: "",
    component: <ModuleGiaiNgan />,
    layout: "",
  },
  {
    path: "/ho-so-cho-xu-ly/bo-sung-giai-ngan",
    name: "",
    icon: "",
    component: <ModuleGiaiNgan />,
    layout: "",
  },
  {
    path: "/ho-so-cho-xu-ly/xu-ly-giai-ngan",
    name: "",
    icon: "",
    component: <ModuleGiaiNgan />,
    layout: "",
  },
  // Ho so
  {
    path: "/ho-so-cho-xu-ly/xem-ho-so-vay",
    name: "",
    icon: "",
    component: <KhoiTaoHoSo />,
    layout: "",
  },
  {
    path: "/ho-so-cho-xu-ly/bo-sung-ho-so",
    name: "",
    icon: "",
    component: <BoSungHoSo />,
    layout: "",
  },
  //
  {
    path: "/ho-so-vay",
    name: "",
    icon: "",
    component: <HoSoVay />,
    layout: "",
  },
  {
    path: "/nhom-quyen",
    name: "",
    icon: "",
    component: <NhomQuyen />,
    layout: "",
  },
  {
    path: "/nhom-quyen/:id",
    name: "",
    icon: "",
    component: <ThemMoiNhomQuyen />,
    layout: "",
  },
  {
    path: "/nhom-quyen/them-moi-nhom-quyen",
    name: "",
    icon: "",
    component: <ThemMoiNhomQuyen />,
    layout: "",
  },
  {
    path: "/user",
    name: "",
    icon: "",
    component: <UserList />,
    layout: "",
  },
  {
    path: "/user/:id",
    name: "",
    icon: "",
    component: <CreateAndEditUser />,
    layout: "",
  },
  {
    path: "/user/add-user",
    name: "",
    icon: "",
    component: <CreateAndEditUser />,
    layout: "",
  },
  {
    path: "/customer-mobile",
    name: "",
    icon: "",
    component: <UserListMobile />,
    layout: "",
  },
  {
    path: "/customer-mobile/:username",
    name: "",
    icon: "",
    component: <CreateAndEditUserMobile />,
    layout: "",
  },
  {
    path: "/han-muc",
    name: "",
    icon: "",
    component: <DSHanMuc />,
    layout: "",
  },
  {
    path: "/han-muc/them-moi-han-muc",
    name: "",
    icon: "",
    component: <ThemMoiHanMuc />,
    layout: "",
  },
  {
    path: "/han-muc/:id",
    name: "",
    icon: "",
    component: <ThemMoiHanMuc />,
    layout: "",
  },
  {
    path: "/bao-hiem",
    name: "",
    icon: "",
    component: <Baohiem />,
    layout: "",
  },
  {
    path: "/bao-hiem/them-moi-bao-hiem",
    name: "",
    icon: "",
    component: <Themmoibaohiem />,
    layout: "",
  },
  {
    path: "/bao-hiem/:id",
    name: "",
    icon: "",
    component: <Themmoibaohiem />,
    layout: "",
  },
  {
    path: "/chung-tu",
    name: "",
    icon: "",
    component: <Chungtu />,
    layout: "",
  },
  {
    path: "/chung-tu/:id",
    name: "",
    icon: "",
    component: <Themmoichungtu />,
    layout: "",
  },
  {
    path: "/chung-tu/them-moi-chung-tu",
    name: "",
    icon: "",
    component: <Themmoichungtu />,
    layout: "",
  },
  {
    path: "/dai-ly",
    name: "",
    icon: "",
    component: <Daily />,
    layout: "",
  },
  {
    path: "/dai-ly/them-moi-dai-ly",
    name: "",
    icon: "",
    component: <Themmoidaily />,
    layout: "",
  },
  {
    path: "/dai-ly/:id",
    name: "",
    icon: "",
    component: <Themmoidaily />,
    layout: "",
  },
  {
    path: "/co-cau-to-chuc",
    name: "",
    icon: "",
    component: <DanhSachCocau />,
    layout: "",
  },
  {
    path: "/co-cau-to-chuc/them-moi-co-cau-to-chuc",
    name: "",
    icon: "",
    component: <Themmoicocau />,
    layout: "",
  },
  {
    path: "/co-cau-to-chuc/:id",
    name: "",
    icon: "",
    component: <Themmoicocau />,
    layout: "",
  },
  {
    path: "/chuong-trinh-vay",
    name: "",
    icon: "",
    component: <Chuongtrinhvay />,
    layout: "",
  },
  {
    path: "/chuong-trinh-vay/them-moi-chuong-trinh-vay",
    name: "",
    icon: "",
    component: <ThemMoiChuongtrinhvay />,
    layout: "",
  },
  {
    path: "/chuong-trinh-vay/:id",
    name: "",
    icon: "",
    component: <ThemMoiChuongtrinhvay />,
    layout: "",
  },
  {
    path: "/blacklist",
    name: "",
    icon: "",
    component: <BlackList />,
    layout: "",
  },
  {
    path: "/nhan-hieu",
    name: "",
    icon: "",
    component: <DanhSachNhanHieu />,
    layout: "",
  },
  {
    path: "/xep-hang-tin-dung",
    name: "",
    icon: "",
    component: <Xephang />,
    layout: "",
  },
  {
    path: "/nhom-tieu-chi",
    name: "",
    icon: "",
    component: <Nhomtieuchi />,
    layout: "",
  },
  {
    path: "/nhom-tieu-chi/them-moi-nhom-tieu-chi",
    name: "",
    icon: "",
    component: <Editgroup />,
    layout: "",
  },
  {
    path: "/nhom-tieu-chi/cai-dat-nhom-tieu-chi/:id",
    name: "",
    icon: "",
    component: <Editgroup />,
    layout: "",
  },
  {
    path: "/cau-hinh-tieu-chi",
    name: "",
    icon: "",
    component: <Tieuchi />,
    layout: "",
  },
  {
    path: "/cau-hinh-tieu-chi/cai-dat-tieu-chi-co-dinh/:id",
    name: "",
    icon: "",
    component: <EditTieuchiCodinh />,
    layout: "",
  },
  {
    path: "/cau-hinh-tieu-chi/cai-dat-tieu-chi-khong-co-dinh/:id",
    name: "",
    icon: "",
    component: <EditTieuchiKoCodinh />,
    layout: "",
  },
];

// return auth?.token ? routes : <Navigate to="/login" replace />;
export default routes;