import { useEffect } from "react";
import { useState } from "react";
import { getQuestions } from "../../../redux/middleware/api/congvieccuatoi/CongViecDA";
import './XuLyThamDinh.css';
import "./PopupHoSoBanDau.css";

export default function PopupDanhSachCauHoi({ closePopup }) {
    const [quesList, setQuesList] = useState([]);

    const [scrollIndex, setScroll] = useState(0);
    const handleScroll = (event) => {
        let scrollFrom = event.target.getBoundingClientRect().y;
        let scrollTo = [...event.target.children].filter(e => {
            let eRect = e.getBoundingClientRect();
            return (eRect.y + eRect.height / 2) > scrollFrom;
        }).sort((a, b) => {
            let aRect = a.getBoundingClientRect();
            let bRect = b.getBoundingClientRect();
            return (aRect.y + aRect.height / 2) - (bRect.y + bRect.height / 2);
        });
        let index = [...event.target.children].indexOf(scrollTo[0]);
        if (index !== scrollIndex) setScroll(index);
    }

    const clickScrollTo = (event) => {
        let index = [...event.target.parentElement.children].indexOf(event.target);
        let scrollElement = document.querySelector(`.popup-overlay .wbaseItem-value[class*="e886086e-80b8-48db-80dc-0aae5d3651d3"]`);
        scrollElement.scrollTo({
            top: scrollElement.children[index].offsetTop,
            behavior: "smooth",
        });
    }

    useEffect(() => {
        getQuestions().then(res => {
            setQuesList(res.data ?? []);
        });
    }, [])

    return (<div className="w-frame wbaseItem-value w-col xl 457e0450-c053-45a8-b000-0559283c0292 no-trans" level={1} cateid={140} style={{margin: "24px 48px", height: "calc(100% - 48px)", borderRadius: "8px"}}>
        <div className="w-frame wbaseItem-value w-row 0addaca6-c4af-4ecc-8212-2221cc9e5ae2" level={2} cateid={140}>
            <div className="w-text wbaseItem-value a5098d30-ef6e-4b40-a73a-9d716c9b10b0" level={3} cateid={139}>Hồ sơ vay</div>
            <button className="w-button wbaseItem-value w-row" onClick={closePopup}>
                <div className="w-svg wbaseItem-value 3396a6f3-f485-4d14-b2e8-175ddbc32053" level={3} cateid={115}>
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" />
                    </svg>
                </div>
            </button>
        </div>
        <div className="w-frame wbaseItem-value w-row 37b1b527-0db7-4339-9107-63673bcbd828" level={5} cateid={140}>
            <div className="w-frame wbaseItem-value w-col 4628504d-0a66-4808-81de-0a7011c1b893" level={6} cateid={140}>
                {quesList.map((questGroup, i) =>
                    <button key={`btn-${i}`} type='button' onClick={clickScrollTo} className={"w-button wbaseItem-value w-row 94d4da85-a45b-4ec9-89f1-dc8cbbbd2cc3" + (scrollIndex === i ? " selected-mini-sidebar-option" : "")} level={7} cateid={140}>
                        <div className="w-text wbaseItem-value 4b6c130c-9423-4e4d-bdcb-23cad4d70d57" level={8} cateid={139}>{questGroup.name}</div>
                    </button>)}
            </div>
            <div onScroll={handleScroll} className="w-frame wbaseItem-value w-col e886086e-80b8-48db-80dc-0aae5d3651d3" scroll="true" level={6} cateid={140}>
                {
                    quesList.map((questGroup, i) =>
                        <div key={`block-${i}`} className="w-frame wbaseItem-value w-col 5d9a634d-7757-4ccc-a27e-2e8de1373523" level={8} cateid={140} style={{rowGap: "0"}}>
                            <div className="w-frame wbaseItem-value w-row 3a09baf3-b26b-4959-9926-c53b11ad441f" level={9} cateid={140}>
                                <div className="w-text wbaseItem-value b81f05ac-7222-40e3-b531-ad63edf992b5" level={10} cateid={139}>{questGroup.name}</div>
                            </div>
                            {
                                questGroup.questions.split("\r\n").map((quest, j) => <div key={`quest-${j}`} className="w-frame wbaseItem-value w-row question-text" style={{order: 2}} level={9} cateid={140}>
                                    <div className="w-text wbaseItem-value">&bull;</div>
                                    <div className="w-text wbaseItem-value">{quest}</div>
                                </div>)
                            }
                        </div>)
                }
            </div>
        </div>
        <div className="w-frame wbaseItem-value w-row 2904b8c6-533a-4111-ad13-d4a04777286d" level={2} cateid={140}>
            <div className="w-frame wbaseItem-value w-row 90d8588d-b0c5-4391-8325-adc7f57830c5" level={3} cateid={140}>
                <button type="button" onClick={closePopup} className="w-button wbaseItem-value w-row 85d115e5-1604-4d46-bbd8-e00cf61498ea" level={4} cateid={29}>
                    <div className="w-text wbaseItem-value 6ff28937-812d-4347-83eb-9bc0f15275e7" level={5} cateid={139}>Đóng</div>
                </button>
            </div>
        </div>
    </div >
    );
}