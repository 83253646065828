import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import './CauTrucToChuc.css';
import { NavLink, useLocation } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

import { useEffect, useState, useRef } from 'react';
import CocauTochucDA from '../../../redux/middleware/api/CoCauToChuc/CocauTochucDA';
import $ from 'jquery';
import Pagination from '../../../components/Pagination/Pagination';
import { ERROR, notify } from '../../../core/AlertNoti';


export default function DanhSachCocau() {
  const location = useLocation();
  const [treeData, setTreeData] = useState([]);
  const [ChildItem, setChildItem] = useState([]);
  const [ChildItemPage, setChildItemPage] = useState([]);
  const [parentItem, setparentItem] = useState();
  const [orgId, setorgId] = useState();
  const [loading, setloading] = useState(true);
  const [parentId, setparentId] = useState();

  // const [total, setTotal] = useState();
  // const [pageDetails, setPageDetails] = useState({
  //   current: 1,
  //   size: 10
  // });
  const notificationAlert = useRef();

  const renderTree = (parentID, level = 0) => {
    let tileList = [];
    for (let tileItem of treeData.filter(e => e.orgParentId === parentID)) {
      tileList.push(<div key={tileItem.id} className="w-tree wbaseItem-value w-row 92f43d0e-9f64-4b82-a967-12e7d64a6bba" level={7} cateid={241} style={{ marginLeft: `${16 * level}px`, width: `calc(100% - ${16 * level}px)` }} tree-height="hug">
        {treeTile(tileItem)}
        {renderTree(tileItem.id, level + 1)}
      </div >
      )
    }
    return tileList;
  }

  const treeTile = (item) => {
    item.toggleShow ??= false;
    return (<div onClick={onclickTitle} className={"tile-item " + (orgId === item.id ? 'active' : '')} data-id={item.id} >
      <button className="w-button w-row btn-tree-action" onClick={(ev) => {
        ev.stopPropagation();
        item.toggleShow = !item.toggleShow;
        if (document.activeElement === ev.target) {
          ev.target.closest(".w-tree").querySelectorAll(`.btn-tree-action`).forEach(btn => {
            if (ev.target !== btn)
              $(btn).trigger("click");
          });
          setTreeData([...treeData]);
        }
      }}>
        <FontAwesomeIcon color='#7e7e7e' icon={item.toggleShow ? faCaretDown : faCaretRight} />
      </button>
      <div className="children-value" >
        <div className="w-text wbaseItem-value 7b3f76d3-5d9e-4624-8d59-df59fbfa8a26" level={8} cateid={139}>
          {item.orgName ?? "_"}
        </div>
      </div>
    </div>);
  }

  useEffect(() => {
    _init();
  }, []);
  const _init = async () => {
    await _InitData("");
  }
  const _InitData = async (name) => {
    const res = await CocauTochucDA.getListOrg(name);
    debugger
    if (res != null) {
      var lst = res?.map((a,_)=>{return {...a,orgParentId: a.orgParentId ? a.orgParentId : 0}});
      setTreeData(lst)
      if(res?.some((a)=> !a.orgParentId)){
        setparentId(0)
      }else{
        res?.sort((a,b)=> (a.orgParentId < b.orgParentId) ?1 : -1);
        setparentId(res[0].orgParentId)
      }
      setloading(false)
      // setorgId(res[0]?.id)
      // setparentItem(res[0])
      // var lstChild = res.filter((a) => a.orgParentId === res[0]?.id);
      // const displayedItems = lstChild.slice(0, pageDetails.size);
      // setChildItemPage(displayedItems)
      // setTotal(lstChild.length)
      // setChildItem(lstChild)
    }
  }
  const onclickTitle = (event) => {
    $(".tile-item").removeClass('active');
    event.target.closest(".tile-item").classList.add('active');
    let idList = [...event.target.closest(".w-tree").querySelectorAll(".tile-item")].map(e => parseInt(e.getAttribute("data-id")));
    var lstChild = treeData.filter((a) => idList.includes(a.orgParentId));
    var parent = treeData.find((a) => a.id === idList[0]);
    setparentItem(parent);
    setChildItem(lstChild)
    // setTotal(lstChild.length)
    // const displayedItems = lstChild.slice(0, pageDetails.size);
    setChildItemPage(lstChild)
  }

  const onchangeSearch = async (event) => {
    const res = await CocauTochucDA.getListOrg(event.target.value);
    debugger;
    if (res != null) {
      var lst = res?.map((a,_)=>{return {...a,orgParentId: a.orgParentId ? a.orgParentId : 0}});
      setTreeData(lst)
      if(res?.some((a)=> !a.orgParentId)){
        setparentId(0)
      }else{
        res?.sort((a,b)=> (a.orgParentId > b.orgParentId) ?1 : -1);
        setparentId(res[0].orgParentId)
      }
      // setTreeData(res)
    }
  }
  const exportOrg = async () => {
    const res = await CocauTochucDA.ExportFile(parentItem?.id)
    if (res.status === 201) {
      var blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.target = '_blank'; // Open the link in a new tab/window
      link.download = 'Danhsach-tochuc.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      notify(notificationAlert, ERROR, res.message ?? 'Đã có lỗi xảy ra.')
    }
  }
  const renderTableChildItem = () => {
    let statusFilter = [...document.getElementsByName("rdSort")].find(e => e.checked)?.value ?? "";
    return ChildItemPage.filter(e => statusFilter.length === 0 || e.status == statusFilter).map((item, index) => {
      return (<tr className="table-row">
        <td id="2x1" className="table-cell" rowspan="1" colspan="1">
          <div
            className="w-frame wbaseItem-value w-row 77c7da4c-6406-425c-8034-11624da28a3f"
            level="9" cateid="140"       >
            <div
              className="w-text wbaseItem-value e7a02330-140c-4d38-9a24-9fea2c0a1bb6" level="10" cateid="139"         >
              {
                // pageDetails.size * (pageDetails.current - 1) + 
                index + 1}
            </div>
          </div>
        </td>
        <td id="2x2" className="table-cell" rowspan="1" colspan="1">
          <div
            className="w-frame wbaseItem-value w-row 5383b335-ed7a-42a0-99e9-44db5855d374"
            level="9" cateid="140"       >
            <NavLink to={location.pathname + '/' + item.id}
              className="w-text wbaseItem-value 982d16fe-8b8c-4f3d-b477-10be924d4fad" level="10" cateid="139"         >
              {item.orgCode}
            </NavLink>
          </div>
        </td>
        <td id="2x3" className="table-cell" rowspan="1" colspan="1">
          <div
            className="w-frame wbaseItem-value w-row ea0afdd7-fdd1-4e11-a9c7-7b1637a5b314"
            level="9" cateid="140"       >
            <div
              className="w-text wbaseItem-value d0775806-ebcb-42ea-a3de-8856c35d8f9e" level="10" cateid="139"         >
              {item.orgName}
            </div>
          </div>
        </td>
        <td id="2x4" className="table-cell" rowspan="1" colspan="1">
          <div
            className="w-frame wbaseItem-value w-row 45f0e651-ca52-420c-8575-111cfb0fbdb9"
            level="9" cateid="140"       >
            <div
              className="w-text wbaseItem-value fe350b1c-99f8-46cf-a4b7-e8342598f47d" level="10" cateid="139"         >
              {item.orgShortName}
            </div>
          </div>
        </td>
        <td id="2x5" className="table-cell" rowspan="1" colspan="1">
          <div
            className="w-frame wbaseItem-value w-row 0f6d6672-92b4-4104-81ac-61146d32f853"
            level="9" cateid="140"       >
            <div
              className="w-text wbaseItem-value 8dbb450d-fd08-4c03-93a0-f9c4ccc894af" level="10" cateid="139"         >
              {item.orgLevelName}
            </div>
          </div>
        </td>
        <td id="2x6" className="table-cell" rowspan="1" colspan="1">
          <div
            className="w-frame wbaseItem-value w-row 21c073ae-286c-4337-8179-5aa32c149663"
            level="9" cateid="140"       >
            <div
              className="w-text wbaseItem-value f3bf82bb-12f2-4c28-901e-61ec5e9d8e3c" level="10" cateid="139"         >
              {item.orgLead}
            </div>
          </div>
        </td>
        <td id="2x7" className="table-cell" rowspan="1" colspan="1">
          <div
            className="w-frame wbaseItem-value w-row c7464d7c-cdc5-4def-8e62-e6a779805abd"
            level="9" cateid="140"       >
            <div
              className="w-text wbaseItem-value 3dbe2b85-4e44-4aaf-9efa-b0c3af66fa71" level="10" cateid="139">{item.orgPurpose}
            </div>
          </div>
        </td>
        <td id="2x8" className="table-cell" rowspan="1" colspan="1">
          <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
            <div className="w-frame wbaseItem-value w-row eadd2c5d-e811-4f3e-9934-2dacf0e9402a" level={2} cateid={29} style={{ backgroundColor: `${item.status === 1 ? "rgb(228, 249, 238)" : "var(--background-color-background-grey-light-2)"}` }}>
              <div className="w-text wbaseItem-value 38662a33-df93-442d-830c-0d71ce5e816f" level={3} cateid={139} style={{ color: item.status === 1 ? null : `#262626` }}>{item.status === 1 ? 'Active' : 'Deactive'}</div>
            </div>
          </div>
        </td>
      </tr>)
    });
  }

  return (
    <div
      className="w-frame wbaseItem-value w-col 11585551-3457-47b8-8806-9a1507097605" level="3" cateid="140"    >
      <NotificationAlert ref={notificationAlert} />

      <div className="w-frame wbaseItem-value w-row ba053c92-0d3c-406c-b822-fb382780959d"
        level="4" cateid="140"      >
        <div
          className="w-frame wbaseItem-value w-col b0d0c195-8b8c-4717-bd12-a0da5fd5768c"
          level="5" cateid="140" >
          <div
            className="w-frame wbaseItem-value w-row c3c56e92-5880-4541-a4a0-22048812dede"
            level="6" cateid="140" >
            <div
              className="w-text wbaseItem-value 952b5d38-3f8c-4c91-87b0-433ce1618e6e" level="7" cateid="139" >
              Danh sách cơ cấu tổ chức
            </div>
          </div>
          <div
            className="w-frame wbaseItem-value w-row breadcrumb 30d6ba78-edf8-4d86-8d34-e1928a32a803"
            level="6" cateid="140" >
            <button
              type="button" className="w-button wbaseItem-value w-row 6db83589-e70e-4984-b2b3-5d04e04e6f78"
              name-field="Button" level="7" cateid="29" >
              <div className="w-text wbaseItem-value 275a05ba-fe4b-4632-80d8-37722c62f707" name-field="Text" level="8" cateid="139" >
                Quản trị hệ thống
              </div>
              <div className="w-svg wbaseItem-value 679ff436-6837-4124-9e70-08aebbfab12c" level="8" cateid="115" >
                <svg
                  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6" ></path>
                </svg>
              </div>
            </button>
            <div
              className="w-text wbaseItem-value 1f209f97-2f9f-4bbb-89e8-8474c1550e55" level="7" cateid="139" >
              Cơ cấu tổ chức
            </div>
          </div>
        </div>
        <div
          className="w-frame wbaseItem-value w-row 903c08ab-ca15-42ec-804e-2557006d81af"
          level="5" cateid="140" onClick={exportOrg}  >
          <button
            type="button" className="w-button wbaseItem-value w-row f3d11385-ddb3-426c-809e-08ba121fca53 event-click"
            name-field="Button" level="6" cateid="29"         >
            <div
              className="w-text wbaseItem-value 79f48a58-d0f8-4eca-b935-92fb607808d7" name-field="Text" level="7" cateid="139" >
              Export
            </div>
          </button>
        </div>
        <NavLink
          to={location.pathname + "/them-moi-co-cau-to-chuc"}

          className="w-frame wbaseItem-value w-row 903c08ab-ca15-42ec-804e-2557006d81af"
          level="5" cateid="140" >
          <button
            type="button" className="w-button wbaseItem-value w-row f3d11385-ddb3-426c-809e-08ba121fca53 event-click"
            name-field="Button" level="6" cateid="29" >
            <div
              className="w-svg wbaseItem-value 9b6e898d-172c-4de6-89c7-52a0894c0579" level="7" cateid="115" >
              <svg
                width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path
                  d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z" fill="#009944FF"></path>
              </svg>
            </div>
            <div
              className="w-text wbaseItem-value 79f48a58-d0f8-4eca-b935-92fb607808d7" name-field="Text" level="7" cateid="139" >
              Thêm tổ chức
            </div>
          </button>
        </NavLink>
      </div>
      <div className="w-frame wbaseItem-value w-row 441af62a-e046-442c-be35-3cc9ae38d2af" level="4" cateid="140" >
        <div
          className="w-frame wbaseItem-value w-col 7858631c-4778-4947-9d12-3a204876073e"
          level="5" cateid="140" >
          <div
            className="w-frame wbaseItem-value w-row 80a33036-1770-4804-bc7e-8b5f9f8dc699"
            level="6" cateid="140" >
            <div
              className="w-textformfield wbaseItem-value w-row 46ef9d10-6026-4440-992e-0f91b477b350"
              name-field="Textformfield" level="7" cateid="85" placeholder="Tìm kiếm tổ chức" >
              <div className="w-svg wbaseItem-value 5fb7e9dc-23a0-430d-8f4a-e076b828e94c" level="8" cateid="115" >
                <svg
                  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_502_135406)">
                    <path
                      fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#28282999" fillOpacity="0.8"   ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_502_135406">
                      <rect
                        width="24" height="24" rx="4" fill="#28282999"     ></rect>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="wbaseItem-value 0dd4aff6-641a-41fd-8a88-8df3f3904957" name-field="Textfield" level="8" cateid="86" >
                <div className="textfield">
                  <input maxLength={30} placeholder="Tìm kiếm tổ chức" onChange={onchangeSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-frame wbaseItem-value w-col 9ddc8e18-e84a-4816-8d9c-d503d4ffa963" scroll="true" level="6" cateid="140" >
            {loading === true ? null : renderTree(parentId)}
          </div>
        </div>
        <div
          className="w-frame wbaseItem-value w-col 62a2193c-4497-40f1-86bc-38b8183510d0" level="5" cateid="140" >
          {loading === true || !parentItem ? null : <div className="w-frame wbaseItem-value w-col 80553e67-fe2e-44f3-95da-17ed4c8fcb65" scroll="true" level="6" cateid="140" >
            <div className="w-frame wbaseItem-value w-col 2c3fee18-fbeb-42c1-9e49-6938a09ef5b7" level="7" cateid="140" >
              <div className="w-frame wbaseItem-value w-row ee8a7120-f629-40f1-99b0-8372fbc96106" level="8" cateid="140" >
                <div className="w-text wbaseItem-value 3ddb700c-48bd-4a60-ba34-c081115d1bb5" level="9" cateid="139">
                  {parentItem?.orgName}
                </div>
              </div>
              <div className="w-frame wbaseItem-value w-row d993b90f-8bfb-41c5-b54e-ece293d6f3a9" wrap="wrap" level="8" cateid="140">
                <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl cc48cb5e-4f16-4d4a-8b4c-d98a808ecce5"
                  level="9" cateid="140">
                  <div className="w-text wbaseItem-value 9ba94371-7d2f-4e26-8c30-0e9ab58a78c8" level="10" cateid="139" >
                    Mã đơn vị:
                  </div>
                  <div className="w-text wbaseItem-value 9b3293f2-0dbe-4f0c-8374-945e39c69e9f" level="10" cateid="139" >
                    {parentItem?.orgCode}
                  </div>
                </div>
                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 2996e874-6516-47fc-996e-a65b198d7ef2"
                  level="9" cateid="140">
                  <div className="w-text wbaseItem-value 223180d9-8750-4304-9e9e-b60f4db7596f" level="10" cateid="139" >
                    Cấp tổ chức:
                  </div>
                  <div className="w-text wbaseItem-value 61e426af-a37d-4c73-bdfc-957e2a48b9b2" level="10" cateid="139" >
                    {parentItem?.orgLevel}
                  </div>
                </div>
                <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl 356b9516-29fc-495f-9ad8-5c52b8a0a0f9"
                  level="9" cateid="140">
                  <div className="w-text wbaseItem-value 5f57ac56-1ef5-46a3-bbc7-46d0d2f3f282" level="10" cateid="139" >
                    Tên viết tắt:
                  </div>
                  <div className="w-text wbaseItem-value 0e464cc9-dd05-4788-9532-0a5a38273549" level="10" cateid="139" >
                    {parentItem?.orgShortName}
                  </div>
                </div>
                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 9a5f4f2b-9854-4667-9e0a-382721a58928"
                  level="9" cateid="140">
                  <div className="w-text wbaseItem-value 0039f383-e886-4f2a-b68a-4aac8c7c718a" level="10" cateid="139" >
                    Lĩnh vực hoạt động:
                  </div>
                  <div className="w-text wbaseItem-value 36193471-a1fe-40a4-88e9-f565ec9ab3ae" level="10" cateid="139" >
                    {parentItem?.orgPurpose}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-frame wbaseItem-value w-col b94dd398-5cb0-4e26-8bb1-58e97f17132b" level="7" cateid="140" >
              <div className="w-frame wbaseItem-value w-row d32aac52-a0d0-4106-aa12-7b714ee302c8"
                level="8" cateid="140" >
                <div className="w-text wbaseItem-value e7f6592a-4ce6-41f3-81b3-300d0fb07879" level="9" cateid="139">
                  Danh sách đơn vị trực thuộc
                </div>
              </div>
              <div className="w-frame wbaseItem-value w-row 0caa55f2-fb82-47f0-9361-b88707b6ddf3"
                level="8" cateid="140" >
                <div className="w-frame wbaseItem-value w-row 878901d6-622d-4b0c-885f-f839600300d0"
                  level="9" cateid="140">
                  <label
                    className="w-radio-btn wbaseItem-value bda3a5f8-0f44-4287-9ac0-a07a3697468b" level="10" cateid="90" ><input type="radio" onChange={() => setChildItemPage([...ChildItemPage])} name='rdSort' value="" defaultChecked /><span
                      className="checkmark" ></span></label>
                  <div className="w-text wbaseItem-value c1c5deef-c4d7-43cc-a2f9-20036910ac9a" level="10" cateid="139" >
                    Tất cả
                  </div>
                </div>
                <div className="w-frame wbaseItem-value w-row d3716edb-bb58-45fa-b70b-35a0fc554154"
                  level="9" cateid="140">
                  <label
                    className="w-radio-btn wbaseItem-value 002774ac-cb06-489f-a920-2f88069c857f" level="10" cateid="90"><input onChange={() => setChildItemPage([...ChildItemPage])} type="radio" name='rdSort' value="1" /><span
                      className="checkmark" ></span></label>
                  <div className="w-text wbaseItem-value b166a1ab-fc54-4aef-9489-321248c11b52" level="10" cateid="139" >
                    Active
                  </div>
                </div>
                <div className="w-frame wbaseItem-value w-row 3c0bc11d-53e8-4170-812b-c9df4222ff8f"
                  level="9" cateid="140">
                  <label className="w-radio-btn wbaseItem-value 1158e130-c52e-4706-8b88-e93139a2407c" level="10" cateid="90"  ><input onChange={() => setChildItemPage([...ChildItemPage])} type="radio" name='rdSort' value="0" /><span
                    className="checkmark" ></span></label>
                  <div className="w-text wbaseItem-value 7ee4aa61-aaab-4044-ab6a-60b56ec234de" level="10" cateid="139" >
                    Deactive
                  </div>
                </div>
              </div>
              <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>

                  <table className="w-table wbaseItem-value d2b8a059-d62c-4466-916f-f12bcb06bc4c" type="0" level="8" cateid="117" >
                    <tr className="table-row">
                      <td id="1x1" className="table-cell" rowspan="1" colspan="1">
                        <div className="w-frame wbaseItem-value w-row e5db31c1-7584-41d2-a8ca-deb56f411a1b"
                          level="9" cateid="140"   >
                          <div className="w-text wbaseItem-value dd767313-04d7-4179-a345-f2a4ec786ce8" level="10" cateid="139"     >
                            STT
                          </div>
                        </div>
                      </td>
                      <td id="1x2" className="table-cell" rowspan="1" colspan="1">
                        <div className="w-frame wbaseItem-value w-row bd60fbc3-ca7a-4ce2-a3a6-05532ee0c0f3"
                          level="9" cateid="140"   >
                          <div className="w-text wbaseItem-value cca16fc2-84e3-47cf-8a73-bc94ef652cef" level="10" cateid="139"     >
                            Mã đơn vị
                          </div>
                        </div>
                      </td>
                      <td id="1x3" className="table-cell" rowspan="1" colspan="1">
                        <div className="w-frame wbaseItem-value w-row 9000af92-9afb-4b89-88d6-a036234d5a36"
                          level="9" cateid="140"   >
                          <div className="w-text wbaseItem-value 7d4f0726-b628-40ef-8ac4-4899491046c9" level="10" cateid="139"     >
                            Tên đơn vị
                          </div>
                        </div>
                      </td>
                      <td id="1x4" className="table-cell" rowspan="1" colspan="1">
                        <div className="w-frame wbaseItem-value w-row c6af784a-6a66-4aa8-ba18-0731ab3675dd"
                          level="9" cateid="140"   >
                          <div className="w-text wbaseItem-value ca23a0a4-31c0-4769-a5f9-725c0a440b0e" level="10" cateid="139"     >
                            Tên viết tắt
                          </div>
                        </div>
                      </td>
                      <td id="1x5" className="table-cell" rowspan="1" colspan="1">
                        <div className="w-frame wbaseItem-value w-row 6367f1f1-8be3-47b1-b6d3-63990a90a533"
                          level="9" cateid="140"   >
                          <div className="w-text wbaseItem-value 7cc8e50e-8ec3-4e25-8eaf-33542a2ad39e" level="10" cateid="139"     >
                            Cấp tổ chức
                          </div>
                        </div>
                      </td>
                      <td id="1x6" className="table-cell" rowspan="1" colspan="1">
                        <div className="w-frame wbaseItem-value w-row 16c0b3fe-c08b-4d66-95a8-f2be4cffd709"
                          level="9" cateid="140"   >
                          <div className="w-text wbaseItem-value a2c65ac4-1961-45be-b4be-905068c779b8" level="10" cateid="139"     >
                            Trưởng đơn vị
                          </div>
                        </div>
                      </td>
                      <td id="1x7" className="table-cell" rowspan="1" colspan="1">
                        <div className="w-frame wbaseItem-value w-row b3f09483-3f8b-47bd-8729-a432f4906615"
                          level="9" cateid="140"   >
                          <div className="w-text wbaseItem-value 7fc89ef4-8d38-464a-ab03-049385cb80ff" level="10" cateid="139"     >
                            Lĩnh vực hoạt động<br />
                          </div>
                        </div>
                      </td>
                      <td id="1x8" className="table-cell" rowspan="1" colspan="1">
                        <div className="w-frame wbaseItem-value w-row dfc1cd73-be22-49b4-b3e6-46ccb29a5bf0"
                          level="9" cateid="140"   >
                          <div className="w-text wbaseItem-value e7157bba-ad28-4087-af64-efe925316516" level="10" cateid="139"     >
                            Trạng thái<br />
                          </div>
                        </div>
                      </td>
                    </tr>

                    {ChildItemPage.length <= 0 ?
                      <tr>
                        {/* <td></td> */}
                        <td colSpan={7}>Không có dữ liệu</td>
                      </tr>
                      : renderTableChildItem()
                    }

                  </table>
                </div>
              </div>
              {/* <Pagination
                currentPage={pageDetails.current}
                itemPerPage={pageDetails.size}
                totalItem={total}
                onChangePage={(page, size) => {
 if (pageDetails.current !== page || pageDetails.size !== size) {
   setPageDetails({ current: page, size: size });
 }
                }}
              /> */}
            </div>
          </div>}

        </div>
      </div>
    </div>
  )
}