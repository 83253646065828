import Select2 from '../../components/select2/Select2';
import { useEffect, useState, useRef } from 'react';
import NhanhieuDA from '../../redux/middleware/api/NhanHieu/NhanhieuDA';
import NotificationAlert from "react-notification-alert";
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import $ from 'jquery';
import { PopupType, WPopup } from '../../components/Popup/Popup';
import ScoringDA from '../../redux/middleware/api/Scoring/ScoringDA';

export default function CriterialList({ lst, callback_edit }) {
    const notificationAlert = useRef();
    const [ChildItemPage, setChildItemPage] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setChildItemPage(lst)
    }, []);
    const onFilter = (type) => {
        if (type == 0) {
            setChildItemPage(lst);
        }
        if (type == 1) {
            var updateItem = [...lst];
            setChildItemPage(updateItem.filter((a) => a.type == 1));
        }
        if (type == 2) {
            var updateItem = [...lst];
            setChildItemPage(updateItem.filter((a) => a.type == 2));
        }
    }
    const onEditItem = (index) => {
        if (ChildItemPage[index].type == 1) {
            navigate('/admin/cau-hinh-tieu-chi/cai-dat-tieu-chi-co-dinh/' + ChildItemPage[index].id);
        } else {
            navigate('/admin/cau-hinh-tieu-chi/cai-dat-tieu-chi-khong-co-dinh/' + ChildItemPage[index].id);
        }
    }
    return (<div class="w-frame wbaseItem-value w-col 80553e67-fe2e-44f3-95da-17ed4c8fcb65"
        level="6"
        cateid="140">
        <div
            class="w-frame wbaseItem-value w-col b94dd398-5cb0-4e26-8bb1-58e97f17132b"
            level="7"
            cateid="140"
        >
            <NotificationAlert ref={notificationAlert} />
            {/* {
                confirmDel == false &&
                <WPopup
                    type={PopupType.WARNING}
                    title={'Xóa nhóm tiêu chí'}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => {
                        setconfirmDel(true)
                        onDeleteItemReal()
                    }}
                    cancelAction={() => {
                        setconfirmDel(null)
                        setindexDel(null)
                    }}
                    cancelButton={true}
                    cancelTitle='Giữ lại'
                    message={'Bạn có chắc chắn muốn xóa ?'}
                />
            } */}
            <div
                class="w-frame wbaseItem-value w-col d32aac52-a0d0-4106-aa12-7b714ee302c8"

                level="8"
                cateid="140"
            >

                <div
                    class="w-text wbaseItem-value e7f6592a-4ce6-41f3-81b3-300d0fb07879"
                    level="9"
                    cateid="139"
                >
                    Danh sách Tiêu chí
                </div>
            </div>
            <div
                // to={location.pathname + "/them-moi-nhan-hieu"}
                style={{ order: 0 }}
                class="w-frame wbaseItem-value w-row 903c08ab-ca15-42ec-804e-2557006d81af"

                level="5"
                cateid="140"
            >
                <div
                    class="w-frame wbaseItem-value w-row 0caa55f2-fb82-47f0-9361-b88707b6ddf3"

                    level="8"
                    cateid="140"
                >
                    <div
                        class="w-frame wbaseItem-value w-row 878901d6-622d-4b0c-885f-f839600300d0"

                        level="9"
                        cateid="140"
                    >
                        <label
                            class="w-radio-btn wbaseItem-value bda3a5f8-0f44-4287-9ac0-a07a3697468b"
                            level="10"
                            cateid="90" onClick={() => onFilter(0)}
                        ><input type="radio" name='rdSort' value="" /><span
                            class="checkmark"
                        ></span></label>
                        <div
                            class="w-text wbaseItem-value c1c5deef-c4d7-43cc-a2f9-20036910ac9a"
                            level="10"
                            cateid="139"
                        >
                            Tất cả
                        </div>
                    </div>
                    <div
                        class="w-frame wbaseItem-value w-row 878901d6-622d-4b0c-885f-f839600300d0"

                        level="9"
                        cateid="140"
                    >
                        <label
                            class="w-radio-btn wbaseItem-value bda3a5f8-0f44-4287-9ac0-a07a3697468b"
                            level="10"
                            cateid="90" onClick={() => onFilter(1)}
                        ><input type="radio" name='rdSort' value="" /><span
                            class="checkmark"
                        ></span></label>
                        <div
                            class="w-text wbaseItem-value c1c5deef-c4d7-43cc-a2f9-20036910ac9a"
                            level="10"
                            cateid="139"
                        >
                            Cố định
                        </div>
                    </div>
                    <div
                        class="w-frame wbaseItem-value w-row 878901d6-622d-4b0c-885f-f839600300d0"

                        level="9"
                        cateid="140"
                    >
                        <label
                            class="w-radio-btn wbaseItem-value bda3a5f8-0f44-4287-9ac0-a07a3697468b"
                            level="10"
                            cateid="90" onClick={() => onFilter(2)}
                        ><input type="radio" name='rdSort' value="" /><span
                            class="checkmark"
                        ></span></label>
                        <div
                            class="w-text wbaseItem-value c1c5deef-c4d7-43cc-a2f9-20036910ac9a"
                            level="10"
                            cateid="139"
                        >
                            Không cố định
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>
                    <table class="w-table wbaseItem-value d2b8a059-d62c-4466-916f-f12bcb06bc4c groupcertical"
                        type="0"
                        level="8"
                        cateid="117"
                    >
                        <tr class="table-row">
                            <td id="1x1" class="table-cell" rowspan="1" colspan="1">
                                <div
                                    class="w-frame wbaseItem-value w-row e5db31c1-7584-41d2-a8ca-deb56f411a1b"

                                    level="9"
                                    cateid="140"
                                >
                                    <div
                                        class="w-text wbaseItem-value dd767313-04d7-4179-a345-f2a4ec786ce8"
                                        level="10"
                                        cateid="139"
                                    >
                                        STT
                                    </div>
                                </div>
                            </td>
                            <td id="1x2" class="table-cell" rowspan="1" colspan="1">
                                <div
                                    class="w-frame wbaseItem-value w-row bd60fbc3-ca7a-4ce2-a3a6-05532ee0c0f3"

                                    level="9"
                                    cateid="140"
                                >
                                    <div
                                        class="w-text wbaseItem-value cca16fc2-84e3-47cf-8a73-bc94ef652cef"
                                        level="10"
                                        cateid="139"
                                    >
                                        Tiêu chí
                                    </div>
                                </div>
                            </td>
                            <td id="1x3" class="table-cell" rowspan="1" colspan="1">
                                <div
                                    class="w-frame wbaseItem-value w-row 9000af92-9afb-4b89-88d6-a036234d5a36"

                                    level="9"
                                    cateid="140"
                                >
                                    <div
                                        class="w-text wbaseItem-value 7d4f0726-b628-40ef-8ac4-4899491046c9"
                                        level="10"
                                        cateid="139"
                                    >
                                        Loại
                                    </div>
                                </div>
                            </td>


                            <td id="1x1" class="table-cell" rowspan="1" colspan="1">
                                <div
                                    class="w-frame wbaseItem-value w-row e5db31c1-7584-41d2-a8ca-deb56f411a1b"

                                    level="9"
                                    cateid="140"
                                >
                                    <div
                                        class="w-text wbaseItem-value dd767313-04d7-4179-a345-f2a4ec786ce8"
                                        level="10"
                                        cateid="139"
                                    >
                                    </div>
                                </div>
                            </td>
                        </tr>

                        {ChildItemPage?.length <= 0 ?
                            <tr>
                                {/* <td></td> */}
                                <td colSpan={7}>Không có dữ liệu</td>
                            </tr>
                            :
                            ChildItemPage.map((item, index) => {
                                return <tr class="table-row">
                                    <td id="2x1" class="table-cell" rowspan="1" colspan="1">
                                        <div
                                            class="w-frame wbaseItem-value w-row 77c7da4c-6406-425c-8034-11624da28a3f"
                                            level="9"
                                            cateid="140"
                                        >
                                            <div
                                                class="w-text wbaseItem-value e7a02330-140c-4d38-9a24-9fea2c0a1bb6"
                                                level="10"
                                                cateid="139"
                                            >
                                                {index + 1}
                                            </div>
                                        </div>
                                    </td>
                                    <td id="2x2" class="table-cell" rowspan="1" colspan="1">
                                        <div
                                            class="w-frame wbaseItem-value w-row 5383b335-ed7a-42a0-99e9-44db5855d374"
                                            level="9"
                                            cateid="140">
                                            <div
                                                class="w-text wbaseItem-value 982d16fe-8b8c-4f3d-b477-10be924d4fad"
                                                level="10"
                                                cateid="139"
                                            >
                                                {item.name}
                                            </div>
                                        </div>
                                    </td>
                                    <td id="2x3" class="table-cell" rowspan="1" colspan="1">
                                        <div
                                            class="w-frame wbaseItem-value w-row ea0afdd7-fdd1-4e11-a9c7-7b1637a5b314"

                                            level="9"
                                            cateid="140"
                                        >
                                            <div
                                                class="w-text wbaseItem-value d0775806-ebcb-42ea-a3de-8856c35d8f9e"
                                                level="10"
                                                cateid="139"
                                            >
                                                {item.type === 1 ? 'Tiêu chí cố định' : 'Tiêu chí không có định'}
                                            </div>
                                        </div>
                                    </td>

                                    <td id="2x8" class="table-cell" rowspan="1" colspan="1">
                                        <div
                                            class="w-frame wbaseItem-value w-row addc6f63-a0ca-4725-a155-7a19fdadf3b9"
                                            level="9"
                                            cateid="140"
                                        >
                                            <div onClick={() => onEditItem(index)} title='Cài đặt nhóm tiêu chí'
                                                className="w-svg wbaseItem-value de717542-9eee-4ae6-81ce-952f97cfb2a3" level={8} cateid={115}>
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="Icon/filled/edit/edit">
                                                        <g id="Vector">
                                                            <path d="M13.507 14.6663H1.91272C1.75897 14.6663 1.61152 14.6053 1.5028 14.4965C1.39408 14.3878 1.33301 14.2404 1.33301 14.0866C1.33301 13.9329 1.39408 13.7854 1.5028 13.6767C1.61152 13.568 1.75897 13.5069 1.91272 13.5069H13.507C13.6608 13.5069 13.8082 13.568 13.917 13.6767C14.0257 13.7854 14.0868 13.9329 14.0868 14.0866C14.0868 14.2404 14.0257 14.3878 13.917 14.4965C13.8082 14.6053 13.6608 14.6663 13.507 14.6663Z" fill="#282829" fillOpacity="0.4" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M8.04959 3.65175L3.24201 8.45933C3.16768 8.53369 3.11498 8.62688 3.08955 8.7289L1.91272 12.3475L5.53131 11.1707C5.63333 11.1452 5.72651 11.0925 5.80088 11.0182L10.6085 6.21061L8.04959 3.65175Z" fill="#282829" fillOpacity="0.4" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M12.7575 3.24189L11.0183 1.50274C10.9096 1.39406 10.7622 1.33301 10.6085 1.33301C10.4547 1.33301 10.3073 1.39406 10.1986 1.50274L8.86931 2.83203L11.4282 5.39089L12.7575 4.0616C12.8661 3.95289 12.9272 3.80547 12.9272 3.65175C12.9272 3.49803 12.8661 3.3506 12.7575 3.24189Z" fill="#282829" fillOpacity="0.4" style={{ mixBlendMode: 'multiply' }} />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </td>

                                </tr>
                            })}

                    </table>
                </div>
            </div>
            {/* <Pagination

          pageCount={pageCount}
          action={handlePageClick}
          itemPerPage={itemsPerPage}
          totalItem={total}
          onChangePage={handleChangePage}
        /> */}
        </div>
    </div>
    )
}