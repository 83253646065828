import '../../css/xemHoSo-HoSoGiaiNgan.css';

export default function XemHoSoGiaiNgan({ hoso }) {
    return (
        <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={4} cateid={140} id="b5fd65e1-8835-4a4c-9626-3f9c5d8babb7">
            <div className="w-frame wbaseItem-value w-col" wrap="nowrap" scroll="true" level={5} cateid={140} id="b8a18014-aa93-4997-8baf-0e183d6c63af">
                <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={6} cateid={140} id="05eb75ef-1fd0-4595-83e6-b701f3dda561">
                    <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={7} cateid={140} id="87c5a00b-2e64-4c0e-b0c8-653c07da4bb4">
                        <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={8} cateid={140} id="a34f3305-81c1-4b5c-affc-fb29c0208e9b">
                            <div className="w-text wbaseItem-value" level={9} cateid={139} id="1b5d4be2-a7b0-4d4e-b4a7-ba32e187bb79">Thông tin hồ sơ giải ngân</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row" wrap="wrap" level={8} cateid={140} id="554fc069-223f-4d31-95d3-d2d26b7aeb37">
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col8-xl col24-md" wrap="nowrap" level={9} cateid={140} id="4e64614b-5169-4af8-afd5-d200662d041f">
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="bcb14e0c-ec0e-4034-a0d7-1ede9d1937f3">Số CIF:</div>
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="93901c09-4bff-48c8-ac69-f8017ff5daf4">
                                    {hoso.khachHang.cif ?? "_"}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col8-xl col24-md" wrap="nowrap" level={9} cateid={140} id="8c228322-4c8f-411d-8a4e-c92e1dde2016">
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="530552ae-114b-4ada-976f-e48e86614e7f">Số khung:</div>
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="026d42c6-7f46-46dd-a0c3-eab22f3553c9">
                                    {hoso.soKhung ?? "_"}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col8-xl col24-md" wrap="nowrap" level={9} cateid={140} id="d2b577f1-22e0-439e-9b45-77fc372cd206">
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="a74898d8-ba8e-4d60-9457-fb5d2db457bc">Số máy:</div>
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="b25bf697-192a-4ba6-a32e-859f7fce055a">
                                    {hoso.soMay ?? "_"}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col8-xl col24-md" wrap="nowrap" level={9} cateid={140} id="f37d4107-62ce-41af-9402-ba3410a16fe4">
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="32880d16-e43f-4721-976d-391c9398ab1f">Màu sắc</div>
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="7fb5ab72-61cb-434e-b666-f39c438d8075">
                                    {hoso.mauSac ?? "_"}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col8-xl col24-md" wrap="nowrap" level={9} cateid={140} id="24e6cc3c-d770-4cd0-b7d1-e26e59c58158">
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="5842b4f9-fa35-4945-a683-413803eec16c">Ngày thanh toán hàng tháng:<br /></div>
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="de905563-1cd9-46ce-a2fc-058b66c7c98b">
                                    {hoso.ngayThanhToanHangThang ?? "_"}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={7} cateid={140} id="0b779952-038f-460d-9780-a91fd93d9ebf">
                        <div className="w-text wbaseItem-value" level={8} cateid={139} id="7837d741-8f3d-4112-9452-77c8f03e9d68">Bổ sung hồ sơ giải ngân</div>
                        <table className="w-table wbaseItem-value" type={0} level={8} cateid={117} id="eb3466cc-8085-4f95-8f61-3a96632c02ae">
                            <tbody>
                                <tr className="table-row">
                                    <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="aa8279db-a511-4d67-bfa7-faf6292f7820">
                                            <div className="w-text wbaseItem-value" level={10} cateid={139} id="84a152c9-f19a-4f74-8bac-3148678e42a7">Loại hồ sơ</div>
                                        </div>
                                    </td>
                                    <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="6f8b30cc-f432-461f-b8ba-a931d247ff8c">
                                            <div className="w-text wbaseItem-value" level={10} cateid={139} id="e813fcd3-2890-4277-9888-5176d14ed4fd">Tệp đính kèm</div>
                                        </div>
                                    </td>
                                    <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="cb54202b-d90b-420d-8eec-c73131839731" />
                                    </td>
                                    <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="2a98cfd9-0d5e-4f3e-857f-5ad3ab5e5f58">
                                            <div className="w-text wbaseItem-value" level={10} cateid={139} id="16a8b8a3-3821-484c-a5f3-a91b8aacff46">Người upload</div>
                                        </div>
                                    </td>
                                    <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="809580d5-f020-475d-a991-3926642e585d">
                                            <div className="w-text wbaseItem-value" level={10} cateid={139} id="428706eb-381e-44a5-b62a-6697380d66cd">Thời gian upload</div>
                                        </div>
                                    </td>
                                </tr>
                                {
                                    hoso.disbursementDocuments?.length > 0 &&
                                    hoso.disbursementDocuments.map((item, index) => {
                                        return (
                                            <tr key={item.id} className="table-row">
                                                <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="3f38e59c-f27e-4503-a1b7-46ea977d396a">
                                                        <div className="w-text wbaseItem-value" level={10} cateid={139} id="0fdf09a1-0df9-4ced-a0ac-b67f6c3814ea">
                                                            {item.docTypeName}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="43ea0029-771b-47c9-a173-ebfe4c853e72">
                                                        <div className="w-text wbaseItem-value" level={10} cateid={139} id="4b7dee0a-59f1-411c-874e-739a846d58b9">
                                                            {`${item.filename}.${item.fileType}`}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="5cf208e1-e8c0-4898-81b2-e7c6364fd8ff">
                                                        <div className="w-svg wbaseItem-value" level={10} cateid={115} id="46c45cbc-2320-4178-95ef-b6964cb7ee26">
                                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7 10.5L12 16.3333L17 10.5H12.8333V3H11.1667V10.5H7Z" fill="#009944FF" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                                <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#009944FF" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="1de315e6-d132-4cf1-899a-61c3f6bade7d">
                                                        <div className="w-text wbaseItem-value" level={10} cateid={139} id="c76fae64-1c8b-44f3-b264-407925afdea3">
                                                            {item.createdBy}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="7cdef31f-c783-41c2-b46c-85cbafbc9fe8">
                                                        <div className="w-text wbaseItem-value" level={10} cateid={139} id="693c992e-3f39-49d5-9d5b-ca6e02b4ef60">
                                                            {item.createdDate ? new Date(item.createdDate).toLocaleDateString() : ""}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })

                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={7} cateid={140} id="5f791ee9-4cc6-4a8b-8ab7-94dae479da3d">
                        <div className="w-text wbaseItem-value" level={8} cateid={139} id="19209f86-689d-4a06-8473-ce7808401579">Ghi chú</div>
                        <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={8} cateid={140} id="9033a972-c974-499e-b525-a20d4769ceaf">
                            {
                                hoso.saleDisbursementNotes?.length > 0 &&
                                hoso.saleDisbursementNotes.map((item, index) => {
                                    return (
                                        <div key={item.id} className="w-frame wbaseItem-value w-col" wrap="nowrap" level={9} cateid={140} id="67664c44-2fb9-4fe2-8ab9-d2eca3854de3">
                                            <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={10} cateid={140} id="7838af55-058f-4a64-954a-45ff24571d8b">
                                                <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={11} cateid={140} id="b40c6e7c-8208-4658-99b7-0426bb66a39a">
                                                    <div className="w-svg wbaseItem-value" level={12} cateid={115} id="5ab4ec42-3403-421e-ab37-8775ab69d036">
                                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.9997 7C16.9997 9.76142 14.7611 12 11.9997 12C9.23826 12 6.99968 9.76142 6.99968 7C6.99968 4.23858 9.23826 2 11.9997 2C14.7611 2 16.9997 4.23858 16.9997 7Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M3.66667 19.222C3.66667 17.177 6.375 13.667 12 13.667C17.625 13.667 20.3333 17.177 20.3333 19.222V20.3337C20.3333 20.7757 20.1577 21.1996 19.8452 21.5122C19.5326 21.8247 19.1087 22.0003 18.6667 22.0003H5.33334C4.89131 22.0003 4.46739 21.8247 4.15483 21.5122C3.84227 21.1996 3.66667 20.7757 3.66667 20.3337V19.222Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                                                        </svg>
                                                    </div>
                                                    <div className="w-text wbaseItem-value" level={12} cateid={139} id="74a4a413-1749-4ba7-8053-fe334b256738">
                                                        {item.createdBy}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={11} cateid={140} id="cbea95c0-84d6-481a-8c83-3476fcf2bd0a">
                                                    <div className="w-svg wbaseItem-value" level={12} cateid={115} id="c4d072ca-f265-4fc0-8d08-9a47b1d052f8">
                                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.16667 11.1667C5.70643 11.1667 5.33333 11.5398 5.33333 12V12.8333C5.33333 13.2936 5.70643 13.6667 6.16667 13.6667H7.83333C8.29357 13.6667 8.66667 13.2936 8.66667 12.8333V12C8.66667 11.5398 8.29357 11.1667 7.83333 11.1667H6.16667Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M11.1667 11.1667C10.7064 11.1667 10.3333 11.5398 10.3333 12V12.8333C10.3333 13.2936 10.7064 13.6667 11.1667 13.6667H12.8333C13.2936 13.6667 13.6667 13.2936 13.6667 12.8333V12C13.6667 11.5398 13.2936 11.1667 12.8333 11.1667H11.1667Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M5.33333 16.1667C5.33333 15.7064 5.70643 15.3333 6.16667 15.3333H7.83333C8.29357 15.3333 8.66667 15.7064 8.66667 16.1667V17C8.66667 17.4602 8.29357 17.8333 7.83333 17.8333H6.16667C5.70643 17.8333 5.33333 17.4602 5.33333 17V16.1667Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M11.1667 15.3333C10.7064 15.3333 10.3333 15.7064 10.3333 16.1667V17C10.3333 17.4602 10.7064 17.8333 11.1667 17.8333H12.8333C13.2936 17.8333 13.6667 17.4602 13.6667 17V16.1667C13.6667 15.7064 13.2936 15.3333 12.8333 15.3333H11.1667Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M15.3333 12C15.3333 11.5398 15.7064 11.1667 16.1667 11.1667H17.8333C18.2936 11.1667 18.6667 11.5398 18.6667 12V12.8333C18.6667 13.2936 18.2936 13.6667 17.8333 13.6667H16.1667C15.7064 13.6667 15.3333 13.2936 15.3333 12.8333V12Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.83333 2.83333C7.83333 2.3731 7.46024 2 7 2C6.53976 2 6.16667 2.3731 6.16667 2.83333V3.66667H4.5C3.11929 3.66667 2 4.78595 2 6.16667V18.6667C2 20.0474 3.11929 21.1667 4.5 21.1667H19.5C20.8807 21.1667 22 20.0474 22 18.6667V6.16667C22 4.78595 20.8807 3.66667 19.5 3.66667H17.8333V2.83333C17.8333 2.3731 17.4602 2 17 2C16.5398 2 16.1667 2.3731 16.1667 2.83333V3.66667H7.83333V2.83333ZM4.5 5.33333C4.03976 5.33333 3.66667 5.70643 3.66667 6.16667V7.83333H20.3333V6.16667C20.3333 5.70643 19.9602 5.33333 19.5 5.33333H17.8333C17.8333 5.79357 17.4602 6.16667 17 6.16667C16.5398 6.16667 16.1667 5.79357 16.1667 5.33333H7.83333C7.83333 5.79357 7.46024 6.16667 7 6.16667C6.53976 6.16667 6.16667 5.79357 6.16667 5.33333H4.5ZM4.5 19.5C4.03976 19.5 3.66667 19.1269 3.66667 18.6667V9.5H20.3333V18.6667C20.3333 19.1269 19.9602 19.5 19.5 19.5H4.5Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                                                        </svg>
                                                    </div>
                                                    <div className="w-text wbaseItem-value" level={12} cateid={139} id="93e6ab1f-d4a3-4a71-9c24-98f40bb92945">
                                                        {item.createdDate ? new Date(item.createdDate).toLocaleDateString() : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-text wbaseItem-value" level={10} cateid={139} id="6752a76e-b063-44c1-b748-12992df86ae2">
                                                {item.content}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}