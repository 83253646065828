import { useState } from "react";
import { Ultis } from "../../core/utils"
import { getTaskById } from "../../redux/middleware/api/congvieccuatoi/CongViecDA";
import { useEffect } from "react";

export function ThongTinKhoiTao({ loanId }) {
    const [taskSelected, setTaskSelected] = useState()

    const setSelected = async () => {
        let selected = await getTaskById(loanId);
        setTaskSelected(selected);
    }

    useEffect(() => {
        setSelected();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        taskSelected !== undefined ?
            <div className="w-frame wbaseItem-value w-col 1278d40a-85d0-47cf-b524-f1abf3375ebe" wrap="nowrap" level={5} cateid={140}>
                <div className="w-frame wbaseItem-value w-row 79bd1f1b-6184-4caf-baba-2a647b4f5b62" wrap="wrap" level={6} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md a6314b75-5cb9-417e-a423-77b54cc924b0" wrap="nowrap" level={7} cateid={140}>
                        <div className="w-text wbaseItem-value b6cbb65f-5765-445e-997d-f260d6b3ace0" level={8} cateid={139}>Trạng thái:</div>
                        <div className="w-text wbaseItem-value 2524340a-7e1e-43af-8488-90717b91f609" level={8} cateid={139}>{taskSelected.status.name}</div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md c327f708-c8f7-44b3-bfd6-eb123ccf0637" wrap="nowrap" level={7} cateid={140}>
                        <div className="w-text wbaseItem-value 185750a3-4776-4390-acf9-30d6c8e193e2" level={8} cateid={139}>Người yêu cầu:</div>
                        <div className="w-text wbaseItem-value be84b181-db60-4af9-b7d1-4f35b7e0935a" level={8} cateid={139}>
                            {taskSelected.nguoiYeuCau ?? taskSelected.createdBy}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md d9eaadd1-d494-4fd5-ad50-e59f492c1232" wrap="nowrap" level={7} cateid={140}>
                        <div className="w-text wbaseItem-value f24e1fd6-973f-4f19-ab07-9e710f74d728" level={8} cateid={139}>Người xử lý:</div>
                        <div className="w-text wbaseItem-value 9e11ebcc-5400-4088-a77e-8499d90237b0" level={8} cateid={139}>
                            {taskSelected.nguoiXuLy ?? taskSelected.modifiedBy}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md b1de3ff4-b716-41eb-ba91-731798a0f638" wrap="nowrap" level={7} cateid={140}>
                        <div className="w-text wbaseItem-value 307db80a-641b-4bca-83b3-2cbc47ce199a" level={8} cateid={139}>Ngày khởi tạo:</div>
                        <div className="w-text wbaseItem-value dd0c5077-a617-4ee3-a0df-15f71ea3de44" level={8} cateid={139}>
                            {Ultis.formatDateTime(taskSelected.ngayTaoYeuCau)}
                        </div>
                    </div>
                </div>
            </div> : <></>
    )
}

export default class LayoutCongViec {
    static EkycKhachHang = (item) => {
        return (
            <div className="section w-frame wbaseItem-value w-col 2a3c1afe-5014-48ec-a0b3-c4e3eb31e5a6" wrap="nowrap" level={8} cateid={140}>
                <div className="w-frame wbaseItem-value w-row d63b1e18-b09e-402e-9edc-06f443909208" wrap="nowrap" level={9} cateid={140}>
                    <div className="w-text wbaseItem-value 81fe85de-3ba2-404e-a58e-e32c4f2a5758" level={10} cateid={139}>Thông tin eKYC khách hàng</div>
                    <div className="w-frame wbaseItem-value w-row 8696abee-e41a-43dd-bb0a-9244d2adf1a6" wrap="nowrap" level={10} cateid={140}>
                        {
                            item?.checkEKyc === true ?
                                <button type="button" className="w-button wbaseItem-value w-row b477c47f-14f2-490c-9d2b-dbbc262bb43d" wrap="nowrap" name-field="Button" level={11} cateid={29}>
                                    <div className="w-svg wbaseItem-value 1c042023-0eff-4b11-b268-d3e481f08b5d" level={12} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.22093 21L1 13.8069L4.2907 10.529L8.22093 14.4556L19.7093 3L23 6.27799L8.22093 21Z" fill="#009944FF" />
                                        </svg>
                                    </div>
                                    <div className="w-text wbaseItem-value 08d38a36-023c-4941-9ff3-7c08a0d51a9f" name-field="Text" level={12} cateid={139}>eKYC</div>
                                </button> :
                                <></>
                        }
                        {/* {
                            item?.checkBCA === true ?
                                <button type="button" className="w-button wbaseItem-value w-row 4051ff97-ef40-4785-bf85-db21a797a966" wrap="nowrap" name-field="Button" level={11} cateid={29}>
                                    <div className="w-svg wbaseItem-value 50780402-935d-426e-a179-46ccf0948600" level={12} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.22093 21L1 13.8069L4.2907 10.529L8.22093 14.4556L19.7093 3L23 6.27799L8.22093 21Z" fill="#009944FF" />
                                        </svg>
                                    </div>
                                    <div className="w-text wbaseItem-value 7cf68fd8-4434-4c09-b8ec-b4841b4efcad" name-field="Text" level={12} cateid={139}>BCA</div>
                                </button> :
                                <></>
                        }
                        {
                            item?.checkCIC === true ?
                                <button type="button" className="w-button wbaseItem-value w-row 4051ff97-ef40-4785-bf85-db21a797a966" wrap="nowrap" name-field="Button" level={11} cateid={29}>
                                    <div className="w-svg wbaseItem-value 50780402-935d-426e-a179-46ccf0948600" level={12} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.22093 21L1 13.8069L4.2907 10.529L8.22093 14.4556L19.7093 3L23 6.27799L8.22093 21Z" fill="#009944FF" />
                                        </svg>
                                    </div>
                                    <div className="w-text wbaseItem-value 7cf68fd8-4434-4c09-b8ec-b4841b4efcad" name-field="Text" level={12} cateid={139}>CIC</div>
                                </button> :
                                <> </>
                        } */}
                    </div>
                </div>
                <div className="w-frame wbaseItem-value w-row 78ac4320-fbe7-4283-88c8-84346994da17" wrap="nowrap" level={9} cateid={140}>
                    <div className="w-rect wbaseItem-value bd4b945d-5cf6-4d4f-9503-c3dd21aec9c8" level={10} cateid={138} >
                        <img src={item?.pathNFCResult} alt="" />
                    </div>
                    <div className="w-frame wbaseItem-value w-row 165e9a35-6ed5-4d72-8455-b8d582d9ab0c" wrap="wrap" level={10} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 3b56f506-f2a8-4a00-842c-de48d04938d8" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col 472d0315-d5c2-4136-8bcd-84f6f9edff86" wrap="nowrap" level={12} cateid={140}>
                                <div className="w-text wbaseItem-value 280e5b96-7662-4871-8de5-c83298274db9" level={13} cateid={139}>Họ và tên:</div>
                                <div className="w-text wbaseItem-value 22a976c4-a629-4641-83ce-e656ec5e613a" level={13} cateid={139}>
                                    {item?.hoTen ?? <span>_</span>}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col 94f7cbc1-a39b-4802-b4b6-5426da07a773" wrap="nowrap" level={12} cateid={140}>
                                <div className="w-text wbaseItem-value 673c844c-ae33-41e9-97ed-32922a6c51b3" level={13} cateid={139}>Giới tính:</div>
                                <div className="w-text wbaseItem-value f0276607-ac2b-4fd8-8bc5-33d90732b05e" level={13} cateid={139}>
                                    {item?.gioiTinh ?? <span>_</span>}
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md a1b9888f-e4de-494f-8d4e-02f9cb71217e" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col 6801e7b5-4ebb-4ca8-aefa-220fb9b3bcc8" wrap="nowrap" level={12} cateid={140}>
                                <div className="w-text wbaseItem-value 2afbb7b6-3bb1-40d2-a485-7ffbffc38243" level={13} cateid={139}>Ngày sinh:</div>
                                <div className="w-text wbaseItem-value deea964c-ee1c-45e3-8e59-512282ebd0c4" level={13} cateid={139}>
                                    {item?.ngaySinh ?? <span>_</span>}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col 270b5587-c6f0-49a1-81c4-45d4511fa4af" wrap="nowrap" level={12} cateid={140}>
                                <div className="w-text wbaseItem-value bc56a86e-d74c-42a0-8894-d7abd8361f06" level={13} cateid={139}>Số CMND đã được cấp:</div>
                                <div className="w-text wbaseItem-value 176a4734-1304-422b-a549-83ddee777950" level={13} cateid={139}>
                                    {item?.cccdCu ?? <span>_</span>}
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 837fe1c6-891f-4c35-ad16-0f4dafc2c450" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col 50d3f97d-c216-413e-942a-c854090c5d65" wrap="nowrap" level={12} cateid={140}>
                                <div className="w-text wbaseItem-value 663957f8-4d10-4d7c-819e-74179f8bea01" level={13} cateid={139}>Quốc tịch:</div>
                                <div className="w-text wbaseItem-value 0778391c-5ca0-4ed2-b298-38159a64dce6" level={13} cateid={139}>
                                    {item?.quocTich ?? <span>_</span>}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col 26fa1e78-e75a-4d06-9edf-fa22d2003f45" wrap="nowrap" level={12} cateid={140}>
                                <div className="w-text wbaseItem-value d4e80a61-9ef2-4d6c-9c2f-a1469397394e" level={13} cateid={139}>Quê quán:</div>
                                <div className="w-text wbaseItem-value 1d273428-9b32-4ab7-8e2b-5b6df10a12d3" level={13} cateid={139}>
                                    {item?.queQuan ?? <span>_</span>}
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 9f28f7b2-070b-4824-a8d0-e0fa6168b8d4" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col 6573a8ea-8112-4333-98f5-36ddab10a808" wrap="nowrap" level={12} cateid={140}>
                                <div className="w-text wbaseItem-value 27d13b2d-9dfd-41be-9dda-cbdf677e34d5" level={13} cateid={139}>Số CCCD:</div>
                                <div className="w-text wbaseItem-value 47951909-3a2b-41fa-84e5-1b29928a315e" level={13} cateid={139}>
                                    {item?.cmndHoChieu ?? <span>_</span>}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col 2d8ae80d-df6d-49bc-8136-258caa272e65" wrap="nowrap" level={12} cateid={140}>
                                <div className="w-text wbaseItem-value 016668dc-da24-4533-962b-2c18a711eb1c" level={13} cateid={139}>Ngày cấp:</div>
                                <div className="w-text wbaseItem-value d69ba158-3493-4d17-a9d2-b72a304dac62" level={13} cateid={139}>
                                    {/* {new Date(item?.ngayCap).toLocaleDateString("en-GB")}  */}
                                    {item?.ngayCap ?? "_"}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col 105e2bfd-5b33-4ce4-a36d-b0dc4a9ab59b" wrap="nowrap" level={12} cateid={140}>
                                <div className="w-text wbaseItem-value 890fe068-1417-4140-a805-b232db450af5" level={13} cateid={139}>Ngày hết hạn:</div>
                                <div className="w-text wbaseItem-value e28b62d1-4f3a-46c3-9221-cd24ecb5c4d0" level={13} cateid={139}>
                                    {/* {new Date(item?.ngayHetHan).toLocaleDateString("en-GB")} */}
                                    {item?.ngayHetHan ?? "_"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    static ChuongTrinhVayVaSanPham = (item) => {
        return (
            <div className="w-frame wbaseItem-value w-col 1f7144db-3cd7-4614-9c41-7a6358727df1" wrap="nowrap" level={8} cateid={140}>
                <div className="w-frame wbaseItem-value w-row e38312a0-d216-4f8e-8c5a-11ee9a7b37f4" wrap="nowrap" level={9} cateid={140}>
                    <div className="w-text wbaseItem-value 4da405ee-1fae-4f63-aaad-2a954a2a16fb" level={10} cateid={139}>Chương trình vay và sản phẩm</div>
                </div>
                <div className="w-frame wbaseItem-value w-row 6cde6fc5-bdd9-4e32-b876-67239d61f093" wrap="wrap" level={9} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 a2826a7c-5b97-44c5-a4c7-77f3af085064" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 88aed03c-fa95-4154-8c5e-a7a8741ada58" level={11} cateid={139}>Nhóm sản phẩm vay:</div>
                        <div className="w-text wbaseItem-value 1ab3725d-bf91-42bd-8545-341ab41967f7" level={11} cateid={139}>
                            {item?.nhomSanPham ?? "_"}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 c7bc8f6a-3e75-458f-9ab5-525c8ead1b5f" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 5ba54032-6f31-4922-aa93-f8f20dec3df0" level={11} cateid={139}>Sản phẩm vay:</div>
                        <div className="w-text wbaseItem-value d847732f-1c12-4ec2-9382-5d3b3e0c88d3" level={11} cateid={139}>
                            {item?.sanPham ?? "_"}
                        </div>
                    </div>
                    <div style={{ order: 3 }} className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 a0ffd898-3923-4461-8184-fc01edddb218" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value f7f4b015-33a2-4448-b596-e9d8952e8135" level={11} cateid={139}>Chương trình vay:</div>
                        <div className="w-text wbaseItem-value 99464fb7-87c9-4888-b9f1-a3f9c016d6d0" level={11} cateid={139}>
                            {item?.chuongTrinhVay ?? "_"}
                        </div>
                    </div>
                    <div style={{ order: 4 }} className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 8a36f8a8-066e-4fe1-b57f-c1450acc43d5" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 15070bb7-9431-4d00-b13f-6f6c04da0378" level={11} cateid={139}>Đại lý:</div>
                        <div className="w-text wbaseItem-value 0c0d1d8c-05b8-42a7-82e1-c8306a9550d5" level={11} cateid={139}>
                            {item?.daiLy ?? "_"}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    static ThongTinHangHoa = (item) => {
        return (
            <div className="w-frame wbaseItem-value w-col d2d4ba53-8694-449a-b910-14b3174c0e5a" wrap="nowrap" level={8} cateid={140}>
                <div className="w-frame wbaseItem-value w-row 043fedc1-a9f9-4927-a8b9-56d2d64601bf" wrap="nowrap" level={9} cateid={140}>
                    <div className="w-text wbaseItem-value ae560273-7196-41f5-8fb7-f9ac832edf45" level={10} cateid={139}>Thông tin hàng hóa</div>
                </div>
                <div className="w-frame wbaseItem-value w-row 52bb76a8-e155-4d2a-a188-1ac1edcc908e" wrap="wrap" level={9} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 6197812f-767b-48c9-8ae0-e2be0d227363" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value b7f738a8-431b-4241-8349-19074bf24195" level={11} cateid={139}>Nhà sản xuất:</div>
                        <div className="w-text wbaseItem-value ad38104e-5855-42c3-906f-ce1a32e44493" level={11} cateid={139}>
                            {item?.nhaSanXuat ?? "_"}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 3fe8d8ce-831f-4d47-9ebb-43bba8f4ea85" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 5f48e4c3-7516-4d91-ac2e-6796d03a0c0b" level={11} cateid={139}>Nhãn hiệu:</div>
                        <div className="w-text wbaseItem-value 576c52ec-48b5-4751-a4b0-7768ef710a7c" level={11} cateid={139}>
                            {item?.nhanHieu ?? "_"}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 2ce6db52-f82a-45c7-afd3-7506810cec30" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 3551f840-4f0f-4f9b-a6a1-8f8c803e3201" level={11} cateid={139}>Năm sản xuất:</div>
                        <div className="w-text wbaseItem-value 514d307d-8341-49c2-8ae3-b8b186a73c8c" level={11} cateid={139}>
                            {item?.namSanXuat ?? "_"}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 a3598e66-f877-4074-be7f-2307d3bec8fb" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 5ce6d8bb-c0ea-4bae-9f35-da4dd56b70d8" level={11} cateid={139}>Màu sắc:</div>
                        <div className="w-text wbaseItem-value efa31d5c-83f0-4ea8-b990-095e66388b66" level={11} cateid={139}>
                            {item?.mauSac ?? "_"}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 81565acc-aa14-419f-a527-99f6215c7b80" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 2572dfbd-c9aa-43fc-bdaf-d26e2c2e6045" level={11} cateid={139}>Giá bán xe:</div>
                        <div className="w-text wbaseItem-value 4320ad61-e585-4bec-be47-fdc93eaf48a2" level={11} cateid={139}>
                            {Ultis.money(item?.giaXe)} VND
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 7c281a93-8f7a-4c4c-b14c-732bc06b840c" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 261897bc-ff77-4a48-a57b-34cd3ed5b2db" level={11} cateid={139}>Phụ tùng phụ kiện kèm theo:</div>
                        <div className="w-text wbaseItem-value 5a46ad5d-69b5-483b-a4ef-5d4f39d8c959" level={11} cateid={139}>
                            {item?.phuTung ?? "_"}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 2dfaf3c3-3bab-45c2-a35b-82cd8c26a7fe" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 7a7b5b45-90c7-45bf-ae32-024722452b9e" level={11} cateid={139}>Giá bán phụ tùng phụ kiện:</div>
                        <div className="w-text wbaseItem-value 6005a553-3bf5-4eda-83c3-78500c450507" level={11} cateid={139}>
                            {Ultis.money(item?.giaPhuTung)} VND
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 1b280207-f5d6-4c64-9a52-80aa68b46240" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 4aaf82b4-e0d6-4bbe-a3e2-07a021b6fea7" level={11} cateid={139}>Tổng giá bán (VND):</div>
                        <div className="w-text wbaseItem-value bd7015d2-da3c-4d16-995f-05bb0db97768" level={11} cateid={139}>
                            {(Ultis.money((item?.giaPhuTung + item?.giaXe)))} VND
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    static ThongTinKhachHangBoSung = (ekyc, item) => {
        return (
            <div className="w-frame wbaseItem-value w-col d7b45f33-1362-415a-ab72-9cd6f3588052" wrap="nowrap" level={8} cateid={140}>
                <div className="w-frame wbaseItem-value w-row b0071c57-dfac-4fca-877c-1de3074f6472" wrap="nowrap" level={9} cateid={140}>
                    <div className="w-text wbaseItem-value 67b80dc0-1af5-4913-a318-0d7ef9b3551b" level={10} cateid={139}>Thông tin khách hàng bổ sung</div>
                </div>
                <div className="w-frame wbaseItem-value w-col 9a6f6282-3933-45f8-80d7-b7696857b4a9" wrap="nowrap" level={9} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row 5543c236-a5b6-46be-bd0c-c9f112432441" wrap="wrap" level={10} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 b8bec16b-8936-4db0-b25e-cb570c5e2d0c" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value 36dd2f6d-f83e-4714-a9e6-22fa6ec58bc9" level={12} cateid={139}>Tên thường gọi ở nhà:</div>
                            <div className="w-text wbaseItem-value a6d76ce3-093b-48f4-8241-eb23586cb546" level={12} cateid={139}>
                                {item?.tenONha}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 7c711514-3d53-4ca3-9f18-fd45fdaa5284" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value 07ed8a40-3880-4b4a-a41c-9bf362569dc3" level={12} cateid={139}>Trình độ học vấn:</div>
                            <div className="w-text wbaseItem-value a05afa4a-cb91-46f5-ae16-0b95b88dd50f" level={12} cateid={139}>
                                {item?.tenTrinhDoHocVan}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 35640dcf-1100-4329-bfdc-07b32977a0b6" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value 432bdc2d-c3ae-4adc-899e-c1d7c1fd7051" level={12} cateid={139}>Số điện thoại di động:</div>
                            <div className="w-text wbaseItem-value 222494e5-d615-4e05-b58f-e0db43d66a2a" level={12} cateid={139}>
                                {ekyc.dtDiDong}
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row 5aef306d-66e9-43ed-a3ba-ae0e1e41482f" wrap="wrap" level={10} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 884f6f30-7c83-4020-9ac0-c01ed405b4e2" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value ba133bb2-7d42-4ae5-a922-a87edb097da0" level={12} cateid={139}>Địa chỉ thường trú:</div>
                            <div className="w-text wbaseItem-value 384ad42e-f482-4860-a932-a5e9f642053b" level={12} cateid={139}>
                                {ekyc.dcThuongTru}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 1fc7e42f-5354-4c80-8e9f-3d9f9d89d483" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value 7fc6fa02-e550-4de0-b957-2cc280c94cdd" level={12} cateid={139}>Tỉnh/Thành phố:</div>
                            <div className="w-text wbaseItem-value 45dde818-c21f-4884-b7db-5a08cc186577" level={12} cateid={139}>
                                {item?.addressProvincesName ?? "_"}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 ca380a69-1908-4c4b-94d1-f613bd07db02" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value 625934c0-d772-487a-a2a3-8ddc6f01538c" level={12} cateid={139}>Quận/Huyện:</div>
                            <div className="w-text wbaseItem-value be41da71-242f-45a7-86f5-92816d235cc8" level={12} cateid={139}>
                                {item?.addressDistrictName ?? "_"}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 6d124eac-0d40-4a84-ac3e-565569a78db3" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value e6d5e1d8-10ed-4bdd-9d7c-e232aac3bcf2" level={12} cateid={139}>Phường/Xã:</div>
                            <div className="w-text wbaseItem-value dde1f417-1fe7-4155-82f1-8d5d2d32a9d6" level={12} cateid={139}>
                                {item?.addressWardsName ?? "_"}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 957fae5e-7ccd-46ca-a4a2-47891ad8f0d3" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value 376687cf-4d6f-462c-ac9a-224fd9bef74f" level={12} cateid={139}>Nơi ở hiện tại:</div>
                            <div className="w-text wbaseItem-value 1b70e822-d788-476a-b77a-1a4c4b41265d" level={12} cateid={139}>
                                {item?.dcTamTru}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 3453c53f-8377-407a-9e41-92fc527be48e" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value b6116ad0-4fc3-43be-b2c8-d9a47078bc7e" level={12} cateid={139}>Tỉnh/Thành phố:</div>
                            <div className="w-text wbaseItem-value 7733b597-1dbf-4228-bb8c-cec4682a7c51" level={12} cateid={139}>
                                {item?.currentAddressProvincesName}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 c402e28c-f913-4ee4-8dd5-c25a94c1dacc" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value 412b3c3c-0536-43cb-a35b-4ed6affd7c8f" level={12} cateid={139}>Quận/Huyện:</div>
                            <div className="w-text wbaseItem-value 4289e435-eac7-4592-9878-5165b66bb109" level={12} cateid={139}>
                                {item?.currentAddressDistrictName}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 87a381af-beee-470b-80fb-d45f5ae63260" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value ee28e646-e7b0-4b7f-831e-ea30c4a2f179" level={12} cateid={139}>Phường/Xã:</div>
                            <div className="w-text wbaseItem-value a440e6d0-9a96-4921-b893-18b3bdd80eb1" level={12} cateid={139}>
                                {item?.currentAddressWardsName}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 a093799a-07a1-4c9c-a4dd-06c0040b2a07" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value 45ceef08-c867-41ec-8165-b93b57ed9466" level={12} cateid={139}>Thời gian cư trú:</div>
                            <div className="w-text wbaseItem-value 1781c71b-2546-4641-b932-b19bdd7b33c5" level={12} cateid={139}>
                                {item?.namCuTru} năm {item?.thangCuTru} tháng
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 aae1a4da-e9e1-48f9-ad77-6ee30e154fa4" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value a2fcdd68-bc19-4ebe-87a5-b7200ad3f083" level={12} cateid={139}>Tình trạng cư trú:</div>
                            <div className="w-text wbaseItem-value 78c12170-0935-47ec-a73c-19fdff65d719" level={12} cateid={139}>
                                {item?.ttNoiCuTru}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    static CongViecKhachHang = (item) => {
        return (
            <div className="w-frame wbaseItem-value w-col a5591a9a-0d41-42f9-92ea-0b710c21286a" wrap="nowrap" level={8} cateid={140}>
                <div className="w-frame wbaseItem-value w-row b74966e4-409b-4180-b625-92ef7a25559c" wrap="nowrap" level={9} cateid={140}>
                    <div className="w-text wbaseItem-value d929271c-9033-44b9-8083-40605cedb8ad" level={10} cateid={139}>Công việc khách hàng</div>
                </div>
                <div className="w-frame wbaseItem-value w-row e166651f-f3d9-43e5-a0bb-b75d8c78a65e" wrap="wrap" level={9} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 a03ca4ff-a7d3-4ce7-b9de-df2dad7e6976" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 536ce638-d93f-464b-9a46-6b93f7c07b93" level={11} cateid={139}>Nghề nghiệp:</div>
                        <div className="w-text wbaseItem-value 171cf531-8ce8-4124-a970-bcaae0cee579" level={11} cateid={139}>
                            {item?.tenNgheNghiep ?? "_"}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 9f6b65fc-3d77-48fd-9388-2f4ed7e64024" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value fb683699-a87e-4b60-a06c-f7a2b8245397" level={11} cateid={139}>Tên công ty/Nơi làm việc:</div>
                        <div className="w-text wbaseItem-value 9a6dc5e0-5b58-4887-8e6a-33f859383520" level={11} cateid={139}>
                            {item?.noiLamViec ?? "_"}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 75146dc4-b9b6-4334-88e0-ef3d2b27b490" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 7038dda2-de92-4a4c-8f80-3605706614a7" level={11} cateid={139}>Địa chỉ nơi làm việc:</div>
                        <div className="w-text wbaseItem-value d334943f-d60d-4d78-ad5f-a11134070321" level={11} cateid={139}>
                            {item?.dcNoiLamViec ?? "_"}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 ab008f69-d921-491c-845b-dbda627def8c" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 2ea43541-6967-40c5-960d-b1441c52fa35" level={11} cateid={139}>Chức vụ:</div>
                        <div className="w-text wbaseItem-value 0694ab29-0181-48a8-987d-f1dcf3c2dbaa" level={11} cateid={139}>
                            {item?.tenChucVu ?? "_"}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 d8068a85-cb35-438f-850a-2a2556ace7f9" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 9e954fd7-bf16-4247-a313-b6ee1813d7b4" level={11} cateid={139}>Số điện thoại công ty:</div>
                        <div className="w-text wbaseItem-value 5d8c548c-5536-4c62-bd6e-2c8d0e2688a5" level={11} cateid={139}>
                            {item?.dtNoiLamViec ?? "_"}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    static TaiChinhKhachHang = (item) => {
        return (
            <div className="w-frame wbaseItem-value w-col c1e88fec-1137-434b-8e0a-07efb349c863" wrap="nowrap" level={8} cateid={140}>
                <div className="w-frame wbaseItem-value w-row b281f82e-6407-42ee-acfa-9bd59a3841c4" wrap="nowrap" level={9} cateid={140}>
                    <div className="w-text wbaseItem-value e04521b5-4619-467b-8f33-8c2eb8502983" level={10} cateid={139}>Tài chính khách hàng</div>
                </div>
                <div className="w-frame wbaseItem-value w-row cba53c6c-f98d-4e76-9d16-ee78b3f9f646" wrap="wrap" level={9} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 08263607-663f-4b34-a703-1f695f7fb530" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 6bc157a5-77f2-470e-b607-5d219d47d415" level={11} cateid={139}>Thu nhập hàng tháng:</div>
                        <div className="w-text wbaseItem-value 3acedae0-2fd6-49d9-abb7-619a928fa1c3" level={11} cateid={139}>
                            {Ultis.money(item?.thuNhapThang)} VND
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 ecee5398-d62a-43e0-9702-dde6dd47e650" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value b7190dc0-c4c8-46e2-bd4b-37ec2b78b074" level={11} cateid={139}>Nguồn thu nhập khác:</div>
                        <div className="w-text wbaseItem-value 2654a24a-2b08-4dad-9957-6ea349da1457" level={11} cateid={139}>Nguồn 1</div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 43f2f8ff-106a-4659-b8ae-1e2181e02c5c" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 9b5495b4-4b34-4ae7-89d7-fbce5454424c" level={11} cateid={139}>Thu nhập khác:</div>
                        <div className="w-text wbaseItem-value 18451a09-a629-4e6a-959a-6fded7423617" level={11} cateid={139}>
                            {Ultis.money(item?.thuNhapKhac)} VND
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 fddf8956-9924-4fd1-bed3-7b38851bc786" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 5c2d4dad-1d55-4dc0-b124-8e6ebda62d2c" level={11} cateid={139}>Chi phí sinh hoạt hàng tháng:</div>
                        <div className="w-text wbaseItem-value 81598d8b-6dad-42c7-96ba-cba82a121d41" level={11} cateid={139}>
                            {Ultis.money(item?.chiPhiSinhHoatHangThang)} VND
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 30452c7a-fdfb-469b-b759-131674e4a335" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 7d99bcf9-fc7f-4521-808d-5079d24a94c2" level={11} cateid={139}>Tổng thanh toán nợ vay hàng tháng:</div>
                        <div className="w-text wbaseItem-value cf28fc13-230a-4600-bb8a-d963edd51f32" level={11} cateid={139}>
                            {Ultis.money(item?.tongThanhToanNoVayHangThang)} VND
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 a2544858-02f9-41db-a595-28ac264846e1" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 10ba07ab-e1f7-422c-a3d0-41a9f9f7796d" level={11} cateid={139}>Số người phụ thuộc:</div>
                        <div className="w-text wbaseItem-value 28d864ec-20c4-47fe-a054-f81742e65325" level={11} cateid={139}>
                            {item?.soNguoiPhuThuoc}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 031fde9a-45a2-4c79-887f-7338e2a09eef" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 558f96f2-d109-4cba-bbe2-37bbe0359896" level={11} cateid={139}>Điểm DTI:</div>
                        <div className="w-text wbaseItem-value 392a1794-1caa-43d3-9d77-5fccfb7e66ee" level={11} cateid={139}>
                            {item?.diemDTI}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 989b82cf-72de-4bc9-b480-f8535007007a" wrap="nowrap" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value b57ac655-9e7e-4e98-96c3-576cf46ed1b2" level={11} cateid={139}>Kết quả DTI:</div>
                        <div className="w-text wbaseItem-value 867131c6-7580-4c31-bf3a-a7aba11ded3d" level={11} cateid={139}>
                            {item?.ketQuaDTI}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    static ThongTinNguoiLienQuan = (item) => {
        return (
            <div className="w-frame wbaseItem-value w-col aeb28ef2-a915-48d6-aa1d-864ac70b800e" wrap="nowrap" level={8} cateid={140}>
                <div className="w-frame wbaseItem-value w-row aa43c5c1-b3c2-47ba-bebd-24e466daee04" wrap="nowrap" level={9} cateid={140}>
                    <div className="w-text wbaseItem-value c1b3b5a8-1d89-4cb4-ab8c-21e231a694a5" level={10} cateid={139}>Thông tin người liên quan</div>
                </div>
                <div className="w-frame wbaseItem-value w-col 9f717afd-330e-42df-a286-951fd649db4e" wrap="nowrap" level={9} cateid={140}>
                    <div className="w-text wbaseItem-value 1ad90a98-e825-41c0-8fdd-5c49a49a038f" level={10} cateid={139}>Người liên hệ trong trường hợp cần thực hiện nghĩa vụ theo hợp đồng</div>
                    <div className="w-frame wbaseItem-value w-row bf9a51ff-590f-482c-8213-330f14f4a321" wrap="wrap" level={10} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 8839bc3b-1125-4701-a1cc-17e776136a19" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value 9d5ad127-ab2f-43d0-9285-b53a806ce275" level={12} cateid={139}>Họ tên:</div>
                            <div className="w-text wbaseItem-value 745f15f7-648f-4176-bc77-402c4aec01fd" level={12} cateid={139}>
                                {item?.hoTenNguoiLienHe1}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 14e12a85-8949-4919-b442-dc5a435abdbf" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value 21866840-517b-42a7-ac98-288d4aa9bcfa" level={12} cateid={139}>Mối quan hệ:</div>
                            <div className="w-text wbaseItem-value c9633b04-afc3-4479-bd49-702c52b6125d" level={12} cateid={139}>
                                {item?.qhNguoiLienHe1}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 e3197eb1-76f7-42b0-a607-4579f4e3de8d" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value bf4148b4-9590-4a4a-8e0b-f4ea42c52855" level={12} cateid={139}>Số điện thoại:</div>
                            <div className="w-text wbaseItem-value cee12984-0794-4418-9d74-389318b461f7" level={12} cateid={139}>
                                {item?.dtNguoiLienHe1}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-frame wbaseItem-value w-col e8a88fa0-5ef0-4d00-8a11-a3006f497bfb" wrap="nowrap" level={9} cateid={140}>
                    <div className="w-text wbaseItem-value 96111b5a-7305-417d-88f5-43258b8a35fa" level={10} cateid={139}>Người liên hệ trong trường hợp khẩn cấp</div>
                    <div className="w-frame wbaseItem-value w-row 8361c7c8-7d17-4b9b-ac57-1053589622cf" wrap="wrap" level={10} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 a54b6368-749f-46b7-979a-571ab37e1c2a" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value ddd168db-ee6d-43d1-ba78-fb5add2b4c21" level={12} cateid={139}>Họ tên:</div>
                            <div className="w-text wbaseItem-value 83eb7828-aebb-4ce9-a071-b85596bcd916" level={12} cateid={139}>
                                {item?.hoTenNguoiLienHe2}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 caf269db-b49a-49bc-b23b-90e3844029d6" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value 81757474-b9ef-4e1a-8a9a-634faeb58284" level={12} cateid={139}>Mối quan hệ:</div>
                            <div className="w-text wbaseItem-value aee16494-e6c1-4442-8033-694262fb8ffc" level={12} cateid={139}>
                                {item?.qhNguoiLienHe2}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 e4fef434-02c0-4ca4-afe0-431f95e57a06" wrap="nowrap" level={11} cateid={140}>
                            <div className="w-text wbaseItem-value 44596f8c-b7cf-4f13-abae-0238a00734ad" level={12} cateid={139}>Số điện thoại:</div>
                            <div className="w-text wbaseItem-value bf50af9d-3376-4ac0-878d-f050664548c6" level={12} cateid={139}>
                                {item?.dtNguoiLienHe2}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}