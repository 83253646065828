import '../../css/HoSo-ChoTiepNhan.css';

import $ from 'jquery';
import { useEffect, useState } from 'react';
import { PopupError, PopupInfo, PopupTransfer } from './Popup';
import { useLocation, useNavigate } from 'react-router-dom';
import { FileStatus } from '../../const/file-status';
import Pagination from '../../components/Pagination/Pagination';
import { getProcessTask } from '../../redux/middleware/api/congvieccuatoi/CongViecDA';
import { getStatusListHoSo, khoiTao_getDealerList } from '../../redux/middleware/api/Filter/Filter';
import Select2 from '../../components/select2/Select2';
import NotificationAlert from "react-notification-alert";
import { useRef } from 'react';
import { useForm } from 'react-hook-form';

export default function HoSoChoTiepNhan() {
    const { handleSubmit, formState: { errors } } = useForm({ shouldFocusError: false });
    const [selectedCode, setSelectedCode] = useState();

    const notificationAlert = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    const handle_acceptFile = (loanCode) => {
        setSelectedCode(loanCode);
        setShowInfo(!showInfo);
    }
    const handle_transferFile = (loanCode) => {
        setSelectedCode(loanCode);
        setShowTransfer(!showTransfer);
    }
    const handle_refuseFile = (loanCode) => {
        setSelectedCode(loanCode);
        setShowError(!showError);
    }


    const [showError, setShowError] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [showTransfer, setShowTransfer] = useState(false);

    const [data, setData] = useState({});
    const [pageDetails, setPageDetails] = useState({
        current: 1,
        size: 10
    });

    // eslint-disable-next-line no-unused-vars
    const _InitData = async (page, pageSize) => {
        const query = window.location.search;
        const response = await getProcessTask(page, pageSize, 0, query);
        if (response != null) {
            setData(response);
        }
    }

    const [customerName, setcustomerName] = useState(searchParams.get('customerName') || "");
    const [cccd, setcccd] = useState(searchParams.get('cccd') || "");
    const [phoneNumber, setphoneNumber] = useState(searchParams.get('phoneNumber') || "");
    const [dealerId, setdealerId] = useState(searchParams.get('dealerId') || "");
    const [loanApplicationCode, setloanApplicationCode] = useState(searchParams.get('loanApplicationCode') || "");
    const [cif, setcif] = useState(searchParams.get('cif') || "");
    const [contractNumber, setcontractNumber] = useState(searchParams.get('contractNumber') || "");
    const [statusCode, setstatusCode] = useState(searchParams.get('statusCode') || "");

    const customerNameChange = (event) => {
        setcustomerName(event.target.value);
    };
    const cccdChange = (event) => {
        const inputText = event.target.value.replace(/[^0-9]/g, '');
        setcccd(inputText);
    };
    const phoneNumberChange = (event) => {
        const inputText = event.target.value.replace(/[^0-9]/g, '');
        setphoneNumber(inputText);
    };
    const dealerIdChange = (event) => {
        setdealerId(event.target.value);
    };
    const loanApplicationCodeChange = (event) => {
        const inputText = event.target.value.replace(/[^0-9]/g, '');
        setloanApplicationCode(inputText);
    };
    const cifChange = (event) => {
        const inputText = event.target.value.replace(/[^0-9]/g, '');
        setcif(inputText);
    };
    const contractNumberChange = (event) => {
        const inputText = event.target.value.replace(/[^0-9]/g, '');
        setcontractNumber(inputText);
    };
    const statusCodeChange = (event) => {
        setstatusCode(event.target.value);
    };

    const clearFormValue = () => {
        setcustomerName('');
        setcccd('');
        setphoneNumber('');
        setdealerId('');
        setcustomerName('');
        setloanApplicationCode('');
        setcif('');
        setcontractNumber('');
        setstatusCode('');

        navigate(`/admin/ho-so-cho-tiep-nhan`);
        _InitData(pageDetails.current, pageDetails.size);
    }

    const handleFindData = (event) => {
        const params = new URLSearchParams();
        if (customerName?.length > 0)
            params.append('customerName', customerName);
        if (cccd?.length > 0)
            params.append('cccd', cccd);
        if (phoneNumber?.length > 0)
            params.append('phoneNumber', phoneNumber);
        if (dealerId?.length > 0)
            params.append('dealerId', dealerId);
        if (loanApplicationCode?.length > 0)
            params.append('loanApplicationCode', loanApplicationCode);
        if (cif?.length > 0)
            params.append('cif', cif);
        if (contractNumber?.length > 0)
            params.append('contractNumber', contractNumber);
        if (statusCode?.length > 0)
            params.append('statusCode', statusCode);

        navigate(`/admin/ho-so-cho-tiep-nhan?${params.toString()}`);
        _InitData(pageDetails.current, pageDetails.size);
    };



    // Filter
    const [list_dealer, setListDealer] = useState([]);
    const [list_status, setListStatus] = useState([]);

    const get_ListDealer = async () => {
        let data = await khoiTao_getDealerList();
        setListDealer(data.pageData);
    }
    const get_Status = async () => {
        let data = await getStatusListHoSo({ isReceiver: 1 });
        if (data)
            setListStatus(data);
    }

    const callback_action = (code) => {
        if (code === "200") {
            data.content = data.content.filter((e) => e.loanApplicationCode !== loanApplicationCode);
            setData(data);
        }
        setShowInfo(false)
        setShowError(false)
        setShowTransfer(false)

    }

    useEffect(() => {
        get_ListDealer();
        get_Status();
        $('body').on('click', '.close-popup, .popup-overlay', function (ev) {
            if ($('.close-popup').is(ev.target) || $('.popup-overlay').is(ev.target)) {
                setShowError(false);
                setShowInfo(false);
                setShowTransfer(false);
            }
        });
        _InitData(pageDetails.current, pageDetails.size);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="w-frame wbaseItem-value w-col a24c3fe2-4bd6-4569-9ca4-feb9e2b5960d" level={2} cateid={140}>
            <NotificationAlert ref={notificationAlert} />
            {
                showInfo &&
                <PopupInfo
                    notificationAlert={notificationAlert}
                    code={selectedCode}
                    callback_action={callback_action}
                >
                </PopupInfo>
            }

            {
                showError &&
                <PopupError
                    notificationAlert={notificationAlert}
                    code={selectedCode}
                    callback_action={callback_action}
                >
                </PopupError>
            }

            {
                showTransfer &&
                <PopupTransfer
                    notificationAlert={notificationAlert}
                    code={selectedCode}
                    callback_action={callback_action}
                >
                </PopupTransfer>
            }

            <div className="w-frame wbaseItem-value w-col ce3db2dc-439a-4f57-8072-136690975e89" scroll="true" level={4} cateid={140}>
                <div className="w-frame wbaseItem-value w-row f4e01814-40a1-4b1c-82fd-318827a74f9a" level={5} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col 93627d03-bad0-47a0-9abd-731f9fede814" level={6} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 68cf116e-daff-45db-a92a-780fc167b314" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 1ad21e6e-936a-41e3-8e96-342405c99f5c" level={8} cateid={139}>Hồ sơ chờ tiếp nhận</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row breadcrumb 8b08a921-f150-407e-927e-f8159a1c33da" level={7} cateid={140}>
                            <button type="button" className="w-button wbaseItem-value w-row 6f51dd31-2f47-49ab-b0f4-371771407cee" name-field="Button" level={8} cateid={29}>
                                <div className="w-text wbaseItem-value 061e15dc-3fb4-4677-9d2b-69c4fd418e6e" name-field="Text" level={9} cateid={139}>Công việc của tôi</div>
                                <div className="w-svg wbaseItem-value 673fa068-3349-49fe-9896-04404431823a" level={9} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6" />
                                    </svg>
                                </div>
                            </button>
                            <div className="w-text wbaseItem-value e9e9f1b5-c755-4b6f-9535-862730fb9b8d" level={8} cateid={139}>Hồ sơ chờ tiếp nhận</div>
                        </div>
                    </div>
                </div>
                {/* */}
                <form onSubmit={handleSubmit(handleFindData)} className="w-frame wbaseItem-value w-col d960e250-857b-4795-88da-5fdaa360e071" level={5} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row 7d798435-6191-4e40-85bd-0a013fa42d03" wrap="wrap" level={6} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 365809ab-cdd5-45b3-93e8-58d093cb4078" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value d979172d-5c1a-43fa-b723-c9ed6791c5d4" level={8} cateid={139}>Tên khách hàng</div>
                            <div
                                className={`w-textformfield wbaseItem-value w-row 2b200f82-4f26-4592-b5be-76145baf8393` + (errors.customerName && ' helper-text')}
                                helper-text={errors.customerName && 'Tên khách hàng không vượt quá 200 ký tự'}
                                name-field="Textformfield"
                                placeholder="Nhập tên khách hàng" level={8} cateid={85}
                            >
                                <div className="wbaseItem-value d100f93c-8da1-4a1d-9e1d-6d9cfafcaf64" name-field="Textfield" level={9} cateid={86}>
                                    <div className="textfield">
                                        <input
                                            maxLength={200}
                                            onChange={customerNameChange}
                                            value={customerName}
                                            name="customerName"
                                            placeholder="Nhập tên khách hàng"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm cf75a9db-a18c-45c2-8f6d-96706a0db3a4" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 814f41c9-ccfb-409d-b8d9-d114d4b9e2bd" level={8} cateid={139}>Số CCCD</div>
                            <div
                                className={`w-textformfield wbaseItem-value w-row f23f9aee-e311-4a33-b4ba-5919fe3612a2` + (errors.cccd && ' helper-text')}
                                helper-text={errors.cccd && 'CCCD không được vượt quá 12 số'}
                                name-field="Textformfield" placeholder="Nhập tên số CCCD" level={8} cateid={85}>
                                <div className="wbaseItem-value 2351c11f-3753-4cd2-9865-2d64593705b9" name-field="Textfield" level={9} cateid={86}>
                                    <div className="textfield">
                                        <input
                                            type="text"
                                            inputMode="numeric"
                                            onChange={(ev) => {
                                                if (ev.target.value.length <= 12) {
                                                    console.log(ev.target.value.length)
                                                    cccdChange(ev)
                                                }
                                            }}
                                            value={cccd}
                                            name="cccd"
                                            placeholder="Nhập tên số CCCD"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 8187ef03-8bdd-4813-a05f-8841fdc92e14" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 237cc06e-540e-4681-8d62-09472b19a8f5" level={8} cateid={139}>Số điện thoại</div>
                            <div className="w-textformfield wbaseItem-value w-row 6fac6dc9-cdfd-427e-a72b-33cd1d7d14ac" name-field="Textformfield" placeholder="Nhập số điện thoại" level={8} cateid={85}>
                                <div className="wbaseItem-value 2249b6ea-e735-4c86-a163-86f600f87aa5" name-field="Textfield" level={9} cateid={86}>
                                    <div className="textfield">
                                        <input
                                            type="text"
                                            inputMode="numeric"
                                            onChange={(ev) => {
                                                if (ev.target.value.length <= 10) {
                                                    phoneNumberChange(ev)
                                                }
                                            }}
                                            value={phoneNumber}
                                            name="phoneNumber"
                                            placeholder="Nhập số điện thoại"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 02bf4923-a84e-410a-8efd-91bc3b54c15e" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value fa4e1f2e-a655-4ace-a7a9-2b0f92586851" level={8} cateid={139}>Đại lý</div>
                            <Select2
                                style={{ order: 2 }}
                                data={list_dealer.map((item, _) => { return { name: item.dealerName, id: item.dealerCode } })}
                                value={dealerId}
                                options={
                                    {
                                        placeholder: 'Chọn đại lý',
                                    }
                                }
                                name='dealerId'
                                onChange={dealerIdChange}
                            />
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 03061b73-a836-4bdf-bc9e-ce70febe3f15" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value c8adfdbe-db6d-4875-bf13-b368d19adeaa" level={8} cateid={139}>Mã hồ sơ</div>
                            <div className="w-textformfield wbaseItem-value w-row 654b3b30-54c2-4d97-b206-8038b4d14c28" name-field="Textformfield" placeholder="Nhập mã hồ sơ" level={8} cateid={85}>
                                <div className="wbaseItem-value 5c23caff-74ca-494a-af97-7860e71a91c6" name-field="Textfield" level={9} cateid={86}>
                                    <div className="textfield">
                                        <input
                                            type="text"
                                            inputMode="numeric"
                                            onChange={(ev) => {
                                                if (ev.target.value.length <= 11 && !ev.target.value.startsWith("0")) {
                                                    loanApplicationCodeChange(ev)
                                                }
                                            }}
                                            value={loanApplicationCode}
                                            name="loanApplicationCode"
                                            placeholder="Nhập mã hồ sơ"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm fe2cadbc-e4c2-45c0-b629-fb42be7cf4e5" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value d0e0bd5b-bdfe-4e29-9a68-1be50f52d962" level={8} cateid={139}>Số CIF</div>
                            <div className="w-textformfield wbaseItem-value w-row 9dcaa912-bc77-4263-b0e4-bb27600b77d0" name-field="Textformfield" placeholder="Nhập số CIF" level={8} cateid={85}>
                                <div className="wbaseItem-value dc38dc18-3676-4692-baf6-471f72b223e2" name-field="Textfield" level={9} cateid={86}>
                                    <div className="textfield">
                                        <input
                                            type="text"
                                            inputMode="numeric"
                                            onChange={(ev) => {
                                                if (ev.target.value.length <= 10) {
                                                    cifChange(ev)
                                                }
                                            }}
                                            value={cif}
                                            name="cif"
                                            placeholder="Nhập số CIF"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 6269b58d-6015-4bec-bfae-698ab959358b" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 1738f942-d1d3-4fc5-8509-d4c4e338bcf5" level={8} cateid={139}>Số hợp đồng</div>
                            <div className="w-textformfield wbaseItem-value w-row 4acef830-2404-48ce-869d-51d0c8fa0777" name-field="Textformfield" placeholder="Nhập số hợp đồng" level={8} cateid={85}>
                                <div className="wbaseItem-value 0a1db023-a0e5-4e4e-88c7-1b8f47023383" name-field="Textfield" level={9} cateid={86}>
                                    <div className="textfield">
                                        <input
                                            type="text"
                                            inputMode="numeric"
                                            onChange={(ev) => {
                                                if (ev.target.value.length <= 20 && !ev.target.value.startsWith("0")) {
                                                    contractNumberChange(ev)
                                                }
                                            }}
                                            value={contractNumber}
                                            name="contractNumber"
                                            placeholder="Nhập số hợp đồng"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 1c716f71-17e7-47ff-a98a-62a2c321ba25" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 88958b7e-ea7c-4a24-8c1d-0a94aacc4ebc" level={8} cateid={139}>Trạng thái</div>
                            <Select2
                                style={{ order: 2 }}
                                data={list_status.map((item, _) => { return { name: item.name, id: item.code } })}
                                value={statusCode}
                                options={{ placeholder: 'Chọn trạng thái' }}
                                name='statusCode'
                                onChange={statusCodeChange}
                            />
                            {/* <div className="w-textformfield wbaseItem-value w-row 6619dab8-0c32-4b60-adf7-f74bebeb6daa" name-field="Textformfield" placeholder="Trạng thái" level={8} cateid={85}>
                                    <div className="wbaseItem-value 9f5c1a52-70f3-406e-a764-cc379b85d86d" name-field="Textfield" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input
                                                id="textfield-9f5c1a52-70f3-406e-a764-cc379b85d86d"
                                                onChange={statusCodeChange}
                                                value={statusCode}
                                                name="statusCode"
                                                placeholder="Trạng thái"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-svg wbaseItem-value 9292a16e-01ff-4b50-983d-0ed8989a86d5" level={9} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z" fill="white" />
                                        </svg>
                                    </div>
                                </div> */}
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row 8cc58507-0a43-4311-a431-c12f6a0fa8ec" level={6} cateid={140}>
                        <button onClick={() => clearFormValue()} type="button" className="w-button wbaseItem-value w-row 4ce54e7b-97f9-4890-918f-d72ac36700eb" name-field="Button" level={7} cateid={29}>
                            <div className="w-svg wbaseItem-value 176e0844-8609-4a9a-a03b-b582b076a388" level={8} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.0313 2.00835C21.5288 2.07581 21.8774 2.53382 21.81 3.03135L21.0827 8.39498C21.0153 8.89173 20.5586 9.2402 20.0617 9.17395L14.6072 8.44668C14.1095 8.38032 13.7598 7.92309 13.8262 7.42541C13.8926 6.92774 14.3498 6.57809 14.8475 6.64445L18.3484 7.11124C16.8702 5.25663 14.5726 4.09064 12 4.09064C7.50208 4.09064 3.81818 7.77454 3.81818 12.2725C3.81818 12.7745 3.41117 13.1816 2.90909 13.1816C2.40701 13.1816 2 12.7745 2 12.2725C2 6.77038 6.49792 2.27246 12 2.27246C15.0381 2.27246 17.7813 3.61127 19.6033 5.77412L20.0083 2.78705C20.0757 2.28953 20.5338 1.94089 21.0313 2.00835Z" fill="#28282999" />
                                    <path d="M22 12.2733C22 11.7713 21.5929 11.3643 21.0909 11.3643C20.5888 11.3643 20.1818 11.7713 20.1818 12.2733C20.1818 16.7713 16.4979 20.4552 12 20.4552C9.42714 20.4552 7.12925 19.2889 5.6511 17.434L9.15251 17.9009C9.65018 17.9672 10.1074 17.6176 10.1738 17.1199C10.2401 16.6222 9.89048 16.165 9.39281 16.0986L3.93826 15.3714C3.44137 15.3051 2.98462 15.6536 2.91726 16.1503L2.18999 21.514C2.12253 22.0115 2.47117 22.4695 2.96869 22.537C3.46621 22.6044 3.92423 22.2558 3.99169 21.7583L4.39665 18.7716C6.21865 20.9345 8.96182 22.2733 12 22.2733C17.502 22.2733 22 17.7754 22 12.2733Z" fill="#28282999" />
                                </svg>
                            </div>
                            <div className="w-text wbaseItem-value 8b93daea-de23-40ce-9824-b08025c16cb8" name-field="Text" level={8} cateid={139}>Xóa bộ lọc</div>
                        </button>
                        <button type="submit" className="w-button wbaseItem-value w-row cbba93b8-036e-4cd4-a94f-d25bbab01785" name-field="Button" level={7} cateid={29}>
                            <div className="w-svg wbaseItem-value 74701d01-57d4-4eb8-ad25-233b3e03ac7b" level={8} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#FFFFFFFF" />
                                </svg>
                            </div>
                            <div className="w-text wbaseItem-value f816a792-1e12-4e33-8502-19ec1311ea01" name-field="Text" level={8} cateid={139}>Tìm kiếm</div>
                        </button>
                    </div>
                </form>
                <div className="w-frame wbaseItem-value w-col fc369344-d9d8-4eab-bee1-94fce859ccc7" level={5} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row 1d8d6a04-53ff-46c3-87fb-0d4c8568ec0d" level={6} cateid={140}>
                        <div className="w-text wbaseItem-value 7adeb0a7-4344-4d34-8724-5e6f8df3dcc4" level={7} cateid={139}>Danh sách kết quả</div>
                        <div className="w-frame wbaseItem-value w-row 0ef46e31-4af4-4b4b-9b9a-64eec3133efb" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value 54368f73-0d68-477e-810b-98623942a257" level={6} cateid={139}>Ngừng tiếp nhận hồ sơ</div>
                            <label className="w-switch wbaseItem-value a6e97769-2e1a-4888-8045-a63e94925bc2" level={6} cateid={81}>
                                <input type="checkbox" onChange={(ev) => { }} checked={true} name="" />
                                <span className="slider">
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className='w-frame wbaseItem-value w-col' scroll="true" style={{ height: "100%", flex: 1, width: "100%" }}>
                        <div className='custom-table row' style={{ overflow: "auto hidden" }}>
                            <div className='table'>
                                <div className='custom-table-row row header'>
                                    <div className='tbl-data'>STT</div>
                                    <div className='tbl-data'>Mã hồ sơ</div>
                                    <div className='tbl-data'>Tên khách hàng</div>
                                    <div className='tbl-data'>Số CCCD</div>
                                    <div className='tbl-data'>Số điện thoại</div>
                                    <div className='tbl-data'>Đại lý</div>
                                    <div className='tbl-data'>Số CIF</div>
                                    <div className='tbl-data'>Số hợp đồng</div>
                                    <div className='tbl-data'>Trạng thái</div>
                                </div>
                                {
                                    data?.content != null &&
                                    data.content.map((item, index) => {
                                        return (
                                            <div key={item.loanApplicationCode} className='custom-table-row row'>
                                                <div className='tbl-data'>{pageDetails.size * (pageDetails.current - 1) + index + 1}</div>
                                                <div className='tbl-data'>{item.loanApplicationCode}</div>
                                                <div className='tbl-data'>{item.customerName}</div>
                                                <div className='tbl-data'>{item.citizenId}</div>
                                                <div className='tbl-data'>{item.phoneNumber}</div>
                                                <div className='tbl-data'>{item.dealerInfo?.name}</div>
                                                <div className='tbl-data'>{item.cif}</div>
                                                <div className='tbl-data'>{item.contractNumber}</div>
                                                <div className='tbl-data'>{FileStatus.get_statusCode(item.status?.code)}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='action-container'>
                                <div className='action-data-row header'>

                                </div>
                                {
                                    data?.content != null &&
                                    data.content.map((item, index) => {
                                        return (
                                            <div key={item.loanApplicationCode} className='action-data-row row'>
                                                <button onClick={() => handle_acceptFile(item.loanApplicationCode)} type="button" className="w-button wbaseItem-value w-row 8e14d33f-9381-4ee4-a495-c041cc82ad6a" name-field="Button" level={8} cateid={29}>
                                                    <div className="w-text wbaseItem-value 14a2d3e1-94f5-432e-80d5-ce43892eca70" name-field="Text" level={9} cateid={139}>Tiếp nhận</div>
                                                </button>
                                                <button onClick={() => handle_transferFile(item.loanApplicationCode)} type="button" className="w-button wbaseItem-value w-row b639b207-3baf-4fcd-88e7-5877bb7b4e92" name-field="Button" level={8} cateid={29}>
                                                    <div className="w-text wbaseItem-value eaea30de-9499-48b3-96ae-3649103f7b04" name-field="Text" level={9} cateid={139}>Chuyển hồ sơ</div>
                                                </button>
                                                <button onClick={() => handle_refuseFile(item.loanApplicationCode)} type="button" className="w-button wbaseItem-value w-row e97a4469-d6b1-4b7c-9194-20afe083c0cd" name-field="Button" level={8} cateid={29}>
                                                    <div className="w-text wbaseItem-value db3dda49-e1bd-4036-bf0e-1da74ad56197" name-field="Text" level={9} cateid={139}>Từ chối</div>
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <Pagination
                        currentPage={pageDetails.current}
                        itemPerPage={pageDetails.size}
                        totalItem={data.totalElements}
                        onChangePage={(page, size) => {
                            if (pageDetails.current !== page || pageDetails.size !== size) {
                                setPageDetails({ current: page, size: size });
                                _InitData(page, size);
                            }
                        }}
                    />
                </div>
            </div>
        </div >
    );
}