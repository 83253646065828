import { AddStaffOrgManager, ChangeStatusOrgManager, CreateOrgManager, DetailOrgManager, StaffOrgManager, UpdateOrgManager, baseurl, exportOrgManager, getAllOrgManager, removeStaffOrgManager } from "../../../../config/ConfigAPI";
import { getData, getDataFile, postData, putData } from "../../../../core/BaseDA";

export default class CocauTochucDA {
    static getListOrg = async (search = "") => {
        const url = `${baseurl}${getAllOrgManager}${search !== "" ? `?search=${search?.trim()}` : ""}`;
        const response = await getData(url);
        if (response.code === "200") {
            return response.data;
        }
        return null;
    };
    static getDetailOrg = async (orgId) => {
        const url = `${baseurl}${DetailOrgManager}/${orgId}`;
        const response = await getData(url);
        if (response.code === "200") {
            return response.data;
        }
        return null;
    };
    static UpdateOrg = async (orgId, obj) => {
        const url = `${baseurl}${UpdateOrgManager}/${orgId}`;
        const response = await putData(url, { obj: obj });
        return response
    };
    static UpdateStatusOrg = async (id, status) => {
        var obj = {
            orgId: id,
            status: status
        }
        const url = `${baseurl}${ChangeStatusOrgManager}`;
        const response = await putData(url, { obj: obj });
        return response
    };
    static CreateOrg = async (obj) => {
        const url = `${baseurl}${CreateOrgManager}`;
        const response = await postData(url, { obj: obj });
        return response
    };
    static StaffAdd = async (id, lstUser) => {
        var obj = {
            orgId: id,
            userIds: lstUser
        }
        const url = `${baseurl}${AddStaffOrgManager}`;
        const response = await postData(url, { obj: obj });
        return response
    };
    static removeStaff = async (userId) => {
        const url = `${baseurl}${removeStaffOrgManager}?userId=${userId}`;
        const response = await putData(url, { obj: null });
        return response
    };
    static ExportFile = async (orgId) => {
        var query = '?allChild=1';
        if(orgId){
            if(query){
                query+='&orgId=' + orgId + '&exportAll=0'
            }
        }else{
            query+='&exportAll=1'
        }
        const UrlFile = `${baseurl}${exportOrgManager}${query}`;
        const response = await getDataFile(UrlFile);
        return response;


    };
}
