import { baseurl, listhosovay, hosovay } from "../../../../config/ConfigAPI";
import { getData } from "../../../../core/BaseDA";

export const getListHoSovay = async (page, size, query) => {
    const url = `${baseurl}${listhosovay}${query !== "" ? `${query}&pageNum=${page}&pageSize=${size}` : `?pageNum=${page}&pageSize=${size}`}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getDetailHoSovay = async (id) => {
    const url = `${baseurl}${hosovay}/${id}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};