import '../css/HoSoVay.css';


export default function HoSoVay() {

    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <div className="w-frame wbaseItem-value w-col xl 457e0450-c053-45a8-b000-0559283c0292 w-page" wrap="nowrap" level={1} cateid={140}>
            <div className="w-frame wbaseItem-value w-row 0addaca6-c4af-4ecc-8212-2221cc9e5ae2" wrap="nowrap" level={2} cateid={140}>
                <div className="w-text wbaseItem-value a5098d30-ef6e-4b40-a73a-9d716c9b10b0" level={3} cateid={139}>Hồ sơ vay</div>
                <div onClick={() => handleGoBack()} className="w-svg wbaseItem-value 3396a6f3-f485-4d14-b2e8-175ddbc32053" level={3} cateid={115}><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" />
                </svg>
                </div>
            </div>
            <div className="w-frame wbaseItem-value w-col 33a45da3-fd04-4085-9998-f5df7fd5fabd" wrap="nowrap" scroll="true" level={2} cateid={140}>
                <div className="w-frame wbaseItem-value w-col bb2b1659-03d6-4709-ba78-6bae23640e20" wrap="nowrap" level={3} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col 804f268d-2e9d-4d41-b80a-634fe887ec9c" wrap="nowrap" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 6abea923-479f-46a8-81c4-6b06b2b04aa9" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value c1670252-91fd-4338-aa6a-dec06e638f08" level={6} cateid={139}>Thông tin eKYC khách hàng</div>
                            <div className="w-frame wbaseItem-value w-row eef7fc69-1c02-45b6-9ea5-8e6e493437fc" wrap="nowrap" level={6} cateid={140}>
                                <button type="button" className="w-button wbaseItem-value w-row fdaa41e5-ac81-404f-bc36-794e2d44662c" wrap="nowrap" name-field="Button" level={7} cateid={29}>
                                    <div className="w-svg wbaseItem-value f16207f6-6687-43f8-a64a-c8a6ee8cc8dc" level={8} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.22093 21L1 13.8069L4.2907 10.529L8.22093 14.4556L19.7093 3L23 6.27799L8.22093 21Z" fill="#009944FF" />
                                        </svg>
                                    </div>
                                    <div className="w-text wbaseItem-value a2fda238-14d4-4a66-9dcc-c70556047037" name-field="Text" level={8} cateid={139}>eKYC</div>
                                </button>
                                <button type="button" className="w-button wbaseItem-value w-row d2ebebbe-def7-45e8-a451-7c3b9af99fd8" wrap="nowrap" name-field="Button" level={7} cateid={29}>
                                    <div className="w-svg wbaseItem-value 3516a2a9-2213-438b-993b-f959c6791d8f" level={8} cateid={115}><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.22093 21L1 13.8069L4.2907 10.529L8.22093 14.4556L19.7093 3L23 6.27799L8.22093 21Z" fill="#009944FF" />
                                    </svg>
                                    </div>
                                    <div className="w-text wbaseItem-value 8e265f1a-1686-4eb7-9599-a2274d6b501e" name-field="Text" level={8} cateid={139}>CIC</div>
                                </button></div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row f646894a-a671-456e-b08d-9b347b7690e4" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-rect wbaseItem-value 623987ff-b255-46b9-80b1-5371533adb8d" level={6} cateid={138} />
                            <div className="w-frame wbaseItem-value w-row b9d5e8d4-de8d-4797-b8c8-755df13386c5" wrap="wrap" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-md col12-lg 3a21a887-9351-40ab-9216-cd8e5000e434" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-col 68384f1d-20d7-4bc7-82fa-0d6cfd9db92b" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 2718a314-f5df-4257-bb9f-b1aca7a55f25" level={9} cateid={139}>Họ và tên:</div>
                                        <div className="w-text wbaseItem-value f4f3edc0-acc0-4fb7-be3c-7763b37d7b39" level={9} cateid={139}>Nguyễn Hoàng Duy</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col c7009f07-7778-4e88-8861-44328528566e" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value a2e95023-12cd-4b71-91b2-374fafdc2054" level={9} cateid={139}>Giới tính:</div>
                                        <div className="w-text wbaseItem-value 568235b2-3cfa-4f55-93f5-f632f1ab12c4" level={9} cateid={139}>Nam</div>
                                    </div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-md col12-lg 2b25b6fc-f301-46fd-9ca3-1ecc209676fc" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-col 90e96406-63e7-4015-84de-b1cda8ed6464" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value fb71656a-faa1-4fa2-83e9-92f58abf868f" level={9} cateid={139}>Số CMND đã được cấp:</div>
                                        <div className="w-text wbaseItem-value 0026b0dc-63e8-40b7-b34d-255d4e508515" level={9} cateid={139}>1234567890</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col f00a534b-2f16-4f41-a389-be035d79757f" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value cb50b607-4d06-448e-8f83-24f909f800f1" level={9} cateid={139}>Ngày sinh:</div>
                                        <div className="w-text wbaseItem-value 76db6efe-3427-41a4-b409-20f2eaf2ea0c" level={9} cateid={139}>11/11/2001</div>
                                    </div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-md col12-lg 87df5786-9760-47d4-abdd-7d37bb02db75" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-col 6f669f13-6974-49ff-bc82-bfec570aeba4" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 726675d8-69e4-4f46-b7f9-9b7321e0c93f" level={9} cateid={139}>Quốc tịch:</div>
                                        <div className="w-text wbaseItem-value 6e9104c4-bcfc-4bf2-8119-8c2fad6218b2" level={9} cateid={139}>Việt Nam</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col 92866027-48cc-4471-ae25-1d1607657f50" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 77c5c126-62ee-4d1f-9e83-29b6a60cbc23" level={9} cateid={139}>Quê quán:</div>
                                        <div className="w-text wbaseItem-value cec3b47e-7380-48cc-b706-61073dc97a43" level={9} cateid={139}>11 Thái Hà, Đống Đa, Hà Nội</div>
                                    </div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-md col12-lg 3c2f91ed-117f-46b7-ab77-788f2d9c904d" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-col d1f6863f-92f6-40cb-9a86-c34f5e339497" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 685e71af-ff1c-40a3-bf15-04102a866f70" level={9} cateid={139}>Số CCCD:</div>
                                        <div className="w-text wbaseItem-value a858e22f-c465-4507-a2ad-a88f4920b87d" level={9} cateid={139}>1234567890</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col f5bbb633-69e7-42c0-8688-7bb44a5892ec" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 67b5f9e6-96e5-428f-b507-c16ad54f6e59" level={9} cateid={139}>Ngày cấp:</div>
                                        <div className="w-text wbaseItem-value fc7e3334-ce9e-42f2-aee5-d619d2b2d971" level={9} cateid={139}>11/11/2001</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col 256653b5-f072-42c1-a0b0-3bd5dc6d307a" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 3a6b0f96-0de9-4e66-b3e4-2e52dd77f544" level={9} cateid={139}>Ngày hết hạn:</div>
                                        <div className="w-text wbaseItem-value f5c3c109-e064-415c-8053-53b56debb630" level={9} cateid={139}>11/11/2001</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col 42d58e4d-dcab-4dad-baf0-2525c45d264c" wrap="nowrap" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 932b4a91-1357-41ff-99d7-8df33f7b7171" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value 9e50cde6-94a1-4013-8d85-d85657baf1e2" level={6} cateid={139}>Chương trình vay và sản phẩm</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 6b81d6ac-99e3-49d7-bc6d-8faa0c763c88" wrap="wrap" level={5} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 2166a5e8-449d-4530-9d3d-0ed376908556" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 2fe19cbc-437f-4e72-b37d-e91eb754b0a1" level={7} cateid={139}>Nhóm sản phẩm vay:</div>
                                <div className="w-text wbaseItem-value 73d17dee-14dd-4060-a1ab-384b00350771" level={7} cateid={139}>Nhóm sản phẩm 1</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 f5726ae9-9393-436b-8c87-a5034c95b8e9" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 0605ee17-248b-4094-a424-fe920138c914" level={7} cateid={139}>Sản phẩm vay:</div>
                                <div className="w-text wbaseItem-value 5205a609-4588-43ba-9403-edd703aaee9b" level={7} cateid={139}>Sản phẩm 1</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 12d416f6-ac62-4071-826b-5aa3a1a732d2" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 582dc3f2-25d1-408c-9b11-ad32d1da5876" level={7} cateid={139}>Đại lý:</div>
                                <div className="w-text wbaseItem-value e78c3e18-2c6b-41f1-86f3-328b13a9c802" level={7} cateid={139}>Hà Nội</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 140e19c8-8856-4147-824e-24b05c7336f3" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 6952158d-047b-4c36-a3fb-99191fd35a28" level={7} cateid={139}>Chương trình vay:</div>
                                <div className="w-text wbaseItem-value 508a96bd-66ab-47fe-abd1-7e0e5295eaa8" level={7} cateid={139}>Chương trình vay 1</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col 2a737884-baf9-4b1f-b550-06ba01b9f3f1" wrap="nowrap" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 8bdc070a-19fb-47f2-a144-8fc9606dc69b" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value abb267ac-7979-40c0-8586-2882e08fd6e0" level={6} cateid={139}>Thông tin hàng hóa</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 18411f9d-6d56-41cb-9b12-2dd8f78de13f" wrap="wrap" level={5} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 5d9a4e75-e6e2-4c72-90c1-a952f99d9336" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value c6709c68-46e5-47c8-b1de-9a27f3e4b428" level={7} cateid={139}>Nhà sản xuất:</div>
                                <div className="w-text wbaseItem-value 0b871248-41e6-458e-8db0-689f1f3cf03f" level={7} cateid={139}>NSX 1</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 8cef1b72-788b-425a-bbb9-6c4961c6e2da" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 9915f186-8f2f-429f-b5d2-c78a4a7f22b3" level={7} cateid={139}>Nhãn hiệu:</div>
                                <div className="w-text wbaseItem-value 2a74e159-ca8a-4017-99fd-8b0fab3a85ca" level={7} cateid={139}>Nhãn hiệu 1</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 f1b4d6fc-e92b-449a-8d95-f7dec755d894" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 10bb6210-1700-49b5-8cdc-72551bdcdfb4" level={7} cateid={139}>Năm sản xuất:</div>
                                <div className="w-text wbaseItem-value 9fe94fc5-133b-49c6-afc6-5537578d4844" level={7} cateid={139}>NSX</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 a693abc5-231e-4073-b699-6c112026ba0b" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value da0788e0-baee-42db-9d84-95a8556fd419" level={7} cateid={139}>Màu sắc:</div>
                                <div className="w-text wbaseItem-value 03415167-b891-44f6-b498-75a7a0adc4c9" level={7} cateid={139}>Đỏ</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 58d79464-9c99-460f-ae4a-f72a16d7b138" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 45e4f28e-3faf-4ef9-97dc-b9154b801d5e" level={7} cateid={139}>Giá bán xe:</div>
                                <div className="w-text wbaseItem-value a8f3c087-6d63-4619-92fb-c825a26e5f87" level={7} cateid={139}>15.000.000&nbsp;VND</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 329e3821-adad-42f3-b952-edbde57ea7ce" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 67f8a728-2c64-4165-9f2b-55c02eeebf36" level={7} cateid={139}>Phụ tùng phụ kiện kèm theo:</div>
                                <div className="w-text wbaseItem-value 0ef2eff1-6198-4b3e-8a37-c3e733b237a4" level={7} cateid={139}>Phụ kiện</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 4572152a-3d17-40fb-851d-eb5a9de611fb" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 2add6b83-b98f-4b76-a4d5-9e56fffa3736" level={7} cateid={139}>Giá bán phụ tùng phụ kiện:</div>
                                <div className="w-text wbaseItem-value 838e2947-4910-46a5-83d0-a059f4e28b53" level={7} cateid={139}>500.000&nbsp;VND</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col 92725285-e75f-4acf-9050-a55a2266da50" wrap="nowrap" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 67a7cda6-f1f5-462b-a433-70df5d3ae798" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value 32edc58b-e77f-4cfb-9883-fe8494972b41" level={6} cateid={139}>Thông tin khoản vay</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 89d82f26-23a0-4507-b008-4d7e8a05e834" wrap="wrap" level={5} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm ea370775-fd6a-4671-9b50-9f77a5264912" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value a827e400-de6a-4dca-b9b6-9a35ca54c500" level={7} cateid={139}>Giá bán xe:</div>
                                <div className="w-text wbaseItem-value acc0781a-1ecc-4b3b-99bd-080fe27df138" level={7} cateid={139}>15.000.000&nbsp;VND</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm 4748cfbf-d40c-4936-9b6c-b4702de98ef9" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 25145e70-77d8-456d-b17c-095fecea597e" level={7} cateid={139}>Phụ tùng phụ kiện kèm theo:</div>
                                <div className="w-text wbaseItem-value a7de3f89-595c-4217-a75e-47e60bc38b09" level={7} cateid={139}>Phụ kiện</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 3c5631d5-11b3-4582-9b66-110bd0789e87" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value b738844d-e04f-46e3-9516-b856b057cb66" level={7} cateid={139}>Giá bán phụ tùng phụ kiện:</div>
                                <div className="w-text wbaseItem-value 602fd496-b5fa-4629-8768-50351b28ee5c" level={7} cateid={139}>500.000&nbsp;VND</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm 3d54b87c-0598-41c8-ad20-698a3d718bac" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 107159f7-b35f-4381-a23e-9e849809067b" level={7} cateid={139}>Tổng giá bán:</div>
                                <div className="w-text wbaseItem-value 98e74832-28e6-4048-aeca-00b912bba1e5" level={7} cateid={139}>15.000.000&nbsp;VND</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm b35f6a73-1d72-4417-b8c6-f0b7aace89ec" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 69957f59-40a2-4653-8983-523528d37821" level={7} cateid={139}>Tổng giá bán:</div>
                                <div className="w-text wbaseItem-value f4201d0b-c0d3-4a19-a9ed-339ee3e6614e" level={7} cateid={139}>15.000.000&nbsp;VND</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm b79108f3-c7dd-4194-a5c3-594eaa121664" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 16efebd2-770d-4696-a62d-3de6e5533f72" level={7} cateid={139}>Số tiền vay gốc:</div>
                                <div className="w-text wbaseItem-value 03a42fde-4d14-4d70-8277-b40eb9993002" level={7} cateid={139}>15.000.000&nbsp;VND</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm d0065d80-741b-47cd-b504-6249bc05aef6" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 86aa42d6-e38b-4e8f-af82-1be9ba08f264" level={7} cateid={139}>Mục đích vay:</div>
                                <div className="w-text wbaseItem-value 3e945c8c-8d3c-4c87-aa0b-2ac4395b4b78" level={7} cateid={139}>Mua xe</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm 233b3a7c-cded-4735-8962-99397d1f3605" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 357503f6-8520-44c7-b6ef-a7d7e4f1b762" level={7} cateid={139}>Kỳ hạn vay:</div>
                                <div className="w-text wbaseItem-value 2e016a47-9e2b-4d44-90dc-620dfab6d18a" level={7} cateid={139}>36 tháng</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm 3052fc5c-139e-4aae-80c7-7eed7161fdb1" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 62fce6b0-d728-4a96-8991-69b5b1251ac1" level={7} cateid={139}>Tỷ lệ % trả trước:</div>
                                <div className="w-text wbaseItem-value 95e98606-4987-4cf2-8e1d-a0ef3fef6b9e" level={7} cateid={139}>5%</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm 885ed4d9-5f26-4c71-b957-ad8911c361ac" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 6bbfa0e7-a5e9-4aa2-a156-715ab78b44c5" level={7} cateid={139}>Số tiền trả trước:</div>
                                <div className="w-text wbaseItem-value 045dfeee-b3a0-4c3b-b2f2-ff9429d790b1" level={7} cateid={139}>500.000&nbsp;VND</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm 11bc17b4-c0dd-4bbe-9f03-5ff14956ed21" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 66c58223-c146-4516-8880-bd7226f73ed4" level={7} cateid={139}>Ngày hợp đồng dự kiến:</div>
                                <div className="w-text wbaseItem-value e44c1e04-03c1-478f-ac73-5e20e27bfcf6" level={7} cateid={139}>11/11/2001</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm 38ffd6b2-d9ff-4c63-ba5c-ed466de34476" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value a85cda59-6eaf-4ec2-9236-5eefe70c9fe7" level={7} cateid={139}>Ngày thanh toán hàng tháng:</div>
                                <div className="w-text wbaseItem-value 2f7308fb-6a82-49e2-8c67-eb671605787b" level={7} cateid={139}>21</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col 32c1403c-258c-4baf-b7b8-22db70ce278b" wrap="nowrap" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 51f36543-3e6f-4533-9469-9a008663a73e" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value a946da0b-1573-40b0-8e65-9935c03b093a" level={6} cateid={139}>Bảo hiểm</div>
                            <div className="w-svg wbaseItem-value c877b301-aab8-42b0-83c5-e03d00a876f7" level={6} cateid={115}><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.3899 15.7544L12 10.0693L5.61015 15.7544C5.24205 16.0819 4.65327 16.0819 4.28517 15.7544C3.90494 15.4161 3.90494 14.8584 4.28517 14.5201L11.3375 8.24562C11.7056 7.91813 12.2944 7.91813 12.6625 8.24562L19.7148 14.5201C20.0951 14.8584 20.0951 15.4161 19.7148 15.7544C19.3467 16.0819 18.7579 16.0819 18.3899 15.7544Z" fill="#28282999" />
                            </svg>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col c854e968-d7bf-4131-8d29-575cf9b8ff53" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col f700a2e7-f63c-438f-bdfd-850e0fc6bc08" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row 55af822e-0772-44f9-8cbf-e876ef3937b7" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value dd70390c-0850-4c3a-88d3-d807fee912f4" level={8} cateid={139}>Bảo hiểm bắt buộc</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-row 22bb1758-0589-4965-b70d-8debe97e001c" wrap="wrap" level={7} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 b28f1199-8e36-4708-af8a-6cfb12040529" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value f63a723f-4d1d-435f-8cfc-ef6955549a73" level={9} cateid={139}>Nhà cung cấp:</div>
                                        <div className="w-text wbaseItem-value 265824cb-20b9-437f-ae01-2f226679049d" level={9} cateid={139}>VIC</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 9a7d8be5-a0a8-4bed-b998-326e0dcda8c0" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 3388a476-f315-418f-a677-2e84ece99102" level={9} cateid={139}>Loại sản phẩm:</div>
                                        <div className="w-text wbaseItem-value c8f9cf9b-431c-4630-9e9e-6dfdcb02b0c5" level={9} cateid={139}>Bảo hiểm điện tử</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 83f130d8-5d62-4459-97de-42615dbf38ee" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value aba48b00-4a35-4c4c-81a3-176ed083687d" level={9} cateid={139}>Tên sản phẩm:</div>
                                        <div className="w-text wbaseItem-value 387fc019-10f2-4bf6-a4fb-4ca2b6fc4489" level={9} cateid={139}>Bảo hiểm điện tử</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 a08a824b-ba38-428c-9a0d-56f09d8708a7" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 3e2885ec-ab6f-4d2c-a290-9aaa164435e6" level={9} cateid={139}>Phí bảo hiểm tạm tính (VND):</div>
                                        <div className="w-text wbaseItem-value 2e27d502-1581-4391-af40-c32bb0ec08ae" level={9} cateid={139}>15.000.000</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 4f1efbe5-5520-45c2-808c-19cc06a250c9" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 8e2b5866-784e-4cdb-adf7-46ebcf73d2fc" level={9} cateid={139}>Bảo hiểm theo:</div>
                                        <div className="w-text wbaseItem-value 79ac991f-a4dc-4037-9e03-bc9791afc65e" level={9} cateid={139}>Giá trị sản phẩm</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 939078d9-75b6-4b8b-bb86-8f72c76f4e17" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 3651fcc5-0138-4b20-b7bf-a55355d933e1" level={9} cateid={139}>Tỷ lệ bảo hiểm (%):</div>
                                        <div className="w-text wbaseItem-value 77567a6a-8375-4cb1-9f2c-50b7e07e6443" level={9} cateid={139}>15</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 b8f874ca-aebe-496e-976e-eb3e9d96bd93" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 88099c12-9e3e-40ce-8264-f6022a914375" level={9} cateid={139}>Hình thức thanh toán:</div>
                                        <div className="w-text wbaseItem-value e3807289-fcf2-457b-badd-e9555c4b7631" level={9} cateid={139}>Trực tiếp</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 d7dbc9e7-8114-4faf-97fd-66ddaa5a4a02" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value bd113cbd-976f-4231-962e-b67c38e88f66" level={9} cateid={139}>Số tiền bảo hiểm tạm tính (VND):</div>
                                        <div className="w-text wbaseItem-value ce2db3d9-c3c8-47cb-8f46-7a93f6d4550a" level={9} cateid={139}>15.000.000</div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col 20eb0e93-c151-41b8-8410-e4b45ad11104" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row e9d0843a-bf09-4074-9058-a3b4e53f4a09" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value d0a1eaac-3d79-4ab5-a399-de2c04a9d746" level={8} cateid={139}>Bảo hiểm bắt buộc</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-row 0c5658a9-e4f1-4f8f-90ca-2569b3e5ddef" wrap="wrap" level={7} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 bc97321f-1139-4bfc-9e44-4578799d23fa" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 1bdeaf80-ea56-455e-a005-046789154a42" level={9} cateid={139}>Nhà cung cấp:</div>
                                        <div className="w-text wbaseItem-value a1733dd6-4559-4d58-9410-e353002d7635" level={9} cateid={139}>VIC</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 f54a20d6-bfb4-430d-86fd-a2404a5b710d" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 696ead13-2c5a-444d-b6b5-2dc18f3218b7" level={9} cateid={139}>Loại sản phẩm:</div>
                                        <div className="w-text wbaseItem-value 08f9454c-7b23-4264-bf27-d42be637b195" level={9} cateid={139}>Bảo hiểm điện tử</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 fb26b782-b21c-4f6a-adef-441d56adaa96" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value dff0bb3a-4ada-4456-adff-62bd9794a799" level={9} cateid={139}>Tên sản phẩm:</div>
                                        <div className="w-text wbaseItem-value 14699077-ea14-484a-9cd5-fb065b6b86b8" level={9} cateid={139}>Bảo hiểm điện tử</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 d1ca9eb4-72b9-458d-bb69-57eb52965024" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 011af7ab-6d94-4398-9ffc-761039929fd5" level={9} cateid={139}>Phí bảo hiểm tạm tính (VND):</div>
                                        <div className="w-text wbaseItem-value efad5ccd-9595-4658-9f4f-0246d4a04f16" level={9} cateid={139}>15.000.000</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 3ac0be55-cdd7-4255-9b61-287d1bfd40f4" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 028ffa2d-a1e4-4e64-8b5f-0866cdcec414" level={9} cateid={139}>Bảo hiểm theo:</div>
                                        <div className="w-text wbaseItem-value 126bae4e-5009-4e3f-a25a-839cd761b884" level={9} cateid={139}>Giá trị sản phẩm</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 8d75b393-3329-41b1-9a9b-7f8acfeac3b5" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value fb832b4d-5843-40b6-a3a9-98939a18c8ad" level={9} cateid={139}>Tỷ lệ bảo hiểm (%):</div>
                                        <div className="w-text wbaseItem-value 7115764a-4f32-4ad1-875a-830c7d035b0b" level={9} cateid={139}>15</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 0f86d723-f3c2-4182-8f89-234b3dd899a5" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value cd3ebd8f-6c92-4d0e-9637-3fed6866e2b8" level={9} cateid={139}>Hình thức thanh toán:</div>
                                        <div className="w-text wbaseItem-value 591e15e8-7e22-4af5-81df-b9e6ddd23abd" level={9} cateid={139}>Trực tiếp</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 e90f6544-2d2c-48e0-8a59-3c814487274b" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 835cc16e-45e1-4140-ba96-5fec526717ad" level={9} cateid={139}>Số tiền bảo hiểm tạm tính (VND):</div>
                                        <div className="w-text wbaseItem-value 6cdabeb1-3a63-4bcd-a6d2-75e92e5f1d79" level={9} cateid={139}>15.000.000</div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col a876fd1f-0048-4692-bdaa-d8114d6f4547" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row 3d717c14-c285-48e4-94ed-e992a0dc4186" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 6dd8ddd2-dff3-4147-ac7b-a24674ae382b" level={8} cateid={139}>Bảo hiểm tự nguyện</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-row ba721b12-fabe-4394-a115-ac03572f8bab" wrap="wrap" level={7} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 406b5171-4ea5-4e25-9a41-ae3c3e5cf27d" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value f65a4448-c138-4c22-89dd-5e9769409733" level={9} cateid={139}>Nhà cung cấp:</div>
                                        <div className="w-text wbaseItem-value a64de7f7-a2de-4a03-be7b-d22a0fa86455" level={9} cateid={139}>VIC</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 7f6f746d-8cb4-4f03-bbca-f11963828fbf" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value a986f02f-c9bd-45f7-86e4-aece903c42d0" level={9} cateid={139}>Loại sản phẩm:</div>
                                        <div className="w-text wbaseItem-value 63b704e7-8488-4ebe-8a7b-f35c37b0def3" level={9} cateid={139}>Bảo hiểm điện tử</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 9db93bd2-f8cc-44dc-a44b-1b04b3034cb3" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 0a46cfcf-8f6b-4e54-8433-ca03c16eb06f" level={9} cateid={139}>Tên sản phẩm:</div>
                                        <div className="w-text wbaseItem-value e0d185c9-ae65-428f-922d-97188294c318" level={9} cateid={139}>Bảo hiểm điện tử</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 a77c4632-9429-4f1e-9b9c-83bc55ca9c77" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value da6274a8-94c0-4add-8564-b90a625c5c50" level={9} cateid={139}>Phí bảo hiểm tạm tính (VND):</div>
                                        <div className="w-text wbaseItem-value c538d0bd-20bc-46ac-ad3a-c52c21196273" level={9} cateid={139}>15.000.000</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 922f9db7-e305-45bf-ac95-7d7b0b10a996" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value eb2e55ed-ec2c-4c54-acf5-d24244267a0f" level={9} cateid={139}>Bảo hiểm theo:</div>
                                        <div className="w-text wbaseItem-value 54823a70-8722-48e4-a459-0a239b48b930" level={9} cateid={139}>Giá trị sản phẩm</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 30dfbcc6-59ad-4f1c-b3a3-0f97a826baf6" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value f22141eb-5de7-4be1-afa3-63a765cfe018" level={9} cateid={139}>Tỷ lệ bảo hiểm (%):</div>
                                        <div className="w-text wbaseItem-value d73b948c-e33b-485e-a355-da09410bc380" level={9} cateid={139}>15</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 063901df-5d67-49ab-9b14-ce37b1421b64" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value b1210381-3ddf-4f10-aa0e-d5eb215ab4c6" level={9} cateid={139}>Hình thức thanh toán:</div>
                                        <div className="w-text wbaseItem-value 42eaefb4-6eec-464a-9f11-c72d83d69c58" level={9} cateid={139}>Trực tiếp</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 48064e96-ec86-4ecf-b6bf-e6cb7c5b4265" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value cca1a835-cb00-4867-9f87-d857e8f80f70" level={9} cateid={139}>Số tiền bảo hiểm tạm tính (VND):</div>
                                        <div className="w-text wbaseItem-value 34db2fe8-deeb-43bf-bfb4-af01037d43b4" level={9} cateid={139}>15.000.000</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 521358bb-52f0-4497-862f-243d35089872" wrap="wrap" level={5} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 3a190e72-f628-4279-bb29-741e277715db" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 03515329-c371-40e8-a87b-e65b40ff7d33" level={7} cateid={139}>Tổng số tiền vay mua bảo hiểm:</div>
                                <div className="w-text wbaseItem-value 1117d134-2ed5-44b7-bbbd-7d07179e7eef" level={7} cateid={139}>15.000.000 VND</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row b81192e1-5818-4dc5-9c6f-c37d8e0f1a82" wrap="nowrap" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row fedcdd8a-b591-4028-beef-f0350290bcba" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value b0355b59-4c9a-47c2-8d14-451a572aa23e" level={6} cateid={139}>Tổng khoản vay:</div>
                        </div>
                        <div className="w-text wbaseItem-value 567577ff-baa4-4864-ba11-ac8e01dfa389" level={5} cateid={139}>15.000.000 VND</div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col 1f15b136-4913-45b4-9c51-46b574e3f53c" wrap="nowrap" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 5ae541e4-1355-47e9-9a69-0c874562d589" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value b75da64d-ed96-4be1-9317-9e7dbb7ba90b" level={6} cateid={139}>Sản phẩm bán kèm</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 23264fd5-f7bd-43f6-b22e-8c699cd9730a" wrap="wrap" level={5} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 0369e7a9-29bb-4501-8ab3-13bf2e6a71c7" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 5dde4eb8-0f6a-4f3d-999b-9d48b42e57fd" level={7} cateid={139}>Loại thẻ:</div>
                                <div className="w-text wbaseItem-value 6f4e2377-6150-4968-853b-f819e631ad4f" level={7} cateid={139}>Thẻ tín dụng</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 7724f75b-975d-454c-8d02-303f4211817d" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value bf45fad0-c0d5-4cbf-b3bf-e081a2775ce5" level={7} cateid={139}>Tên in trên thẻ:</div>
                                <div className="w-text wbaseItem-value 776b446c-07f9-4d84-8265-7927c33dddbc" level={7} cateid={139}>Nguyễn Văn A</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col ff654c59-1eda-4bf2-a440-4a3875af1545" wrap="nowrap" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col 0baf0975-fae9-4d5b-aa87-d6c12f043c88" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-frame wbaseItem-value w-row 8c78dc56-0014-4abf-9c43-5a749bf6126a" wrap="wrap" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 6b524074-84d8-4299-b9df-229f7f918296" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 076b83ef-0afe-4481-9c64-f2116256950b" level={8} cateid={139}>Tên thường gọi ở nhà:</div>
                                    <div className="w-text wbaseItem-value 25b6d2ad-c671-4e3c-8f45-b3b72db4f4f1" level={8} cateid={139}>Nguyễn Hoàng Duy&nbsp;</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 ec6c8ab8-2b82-4ade-b7b0-41480518ee5e" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value b73d2eb0-741e-435f-85cf-d6afc745f40f" level={8} cateid={139}>Trình độ học vấn:</div>
                                    <div className="w-text wbaseItem-value a2b4bba4-63de-4075-ae73-575df85189dc" level={8} cateid={139}>Đại học</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 e88c4122-0718-48ee-9563-f170de5b4507" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value cb668c47-aa1f-442e-8416-ac46924a03b0" level={8} cateid={139}>Số điện thoại di động:</div>
                                    <div className="w-text wbaseItem-value 5bc18e9f-e716-4652-9cac-aff80ef32236" level={8} cateid={139}>0123456789</div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row 96f4c869-0610-4fcc-ab8d-34851d7abdc9" wrap="wrap" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 25351709-bfbb-45b4-b3f8-dc36cf659624" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 379a8647-1064-4db2-b3eb-6b4ec6629dff" level={8} cateid={139}>Địa chỉ thường trú:</div>
                                    <div className="w-text wbaseItem-value 75570ea5-e90b-44c8-8af0-2d12a356c8e5" level={8} cateid={139}>11 Thái Hà</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 36cee9c7-8f6b-4469-8f1d-a964c4445388" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 5169ac35-fd94-4316-8cba-1a166998d691" level={8} cateid={139}>Tỉnh/Thành phố:</div>
                                    <div className="w-text wbaseItem-value faf768b5-fbef-4dd0-9b2b-7ef14a0642cf" level={8} cateid={139}>Hà Nội</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 daf61360-df20-4f2a-94b6-b5abc3c16229" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value ff8d80b7-1da9-48d2-9d09-b49663f0bafa" level={8} cateid={139}>Quận/Huyện:</div>
                                    <div className="w-text wbaseItem-value 7bd3a195-f6c3-4f2e-8d2c-86fea7c4c0b6" level={8} cateid={139}>Đống Đa</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 cbd83a20-5346-49e7-92d0-9e683595a7e2" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 86ed7ed3-9963-4112-8d5d-b0dc402ef2d3" level={8} cateid={139}>Phường/Xã:</div>
                                    <div className="w-text wbaseItem-value 2c7df10f-c0ea-48d8-8383-98fe355aaf03" level={8} cateid={139}>Trung Liệt</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 b3a49fa6-be79-454a-9d4b-d9581cd5deed" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 6a510009-5cf5-48c0-bdae-dfb173fa878d" level={8} cateid={139}>Nơi ở hiện tại:</div>
                                    <div className="w-text wbaseItem-value a892a1f1-c87f-409f-aac2-3c78567f21c2" level={8} cateid={139}>11 Thái Hà</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 8df79322-cae8-4014-a1ef-f3a7dfb417ee" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 27e33925-6e86-4b60-9060-222192c1fcf2" level={8} cateid={139}>Tỉnh/Thành phố:</div>
                                    <div className="w-text wbaseItem-value 8db22bf1-9a0c-4948-a9f3-935f0912933e" level={8} cateid={139}>Hà Nội&nbsp; &nbsp; &nbsp;</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 5005ce19-e8e8-43b2-bc37-d6ebee1c4a67" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value ab78d77d-68f4-4fe2-a5f7-6c39104215b3" level={8} cateid={139}>Quận/Huyện:</div>
                                    <div className="w-text wbaseItem-value 54133917-675c-4527-96df-ba58c97298ce" level={8} cateid={139}>Đống Đa</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 e424fce8-447e-417e-933a-037794435ef8" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 82e054c9-f056-4de8-9a83-bd30a100b1a5" level={8} cateid={139}>Phường/Xã:</div>
                                    <div className="w-text wbaseItem-value 598b8367-9cb6-4b16-a131-4c46361cc73b" level={8} cateid={139}>Trung Liệt</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 c1392638-bb5b-4d8f-9846-9be446bf2211" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value d7597118-fd73-4259-a78f-fcb425a348e4" level={8} cateid={139}>Thời gian cư trú:</div>
                                    <div className="w-text wbaseItem-value 041315c3-24bb-4a3d-952a-b2741dbe73a5" level={8} cateid={139}>1 năm 6 tháng</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 9c77a89c-3be6-4f8e-a0a8-ab0f8c37875f" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value c12875e6-c74e-419d-bd84-ea8cc65d5fc0" level={8} cateid={139}>Tình trạng cư trú:</div>
                                    <div className="w-text wbaseItem-value 422815a9-df50-4344-88bf-658050f25245" level={8} cateid={139}>Đang ở</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 7c077d74-c554-4f1c-b530-099f9fee1133" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value de50ffed-c84f-47f8-b483-8c9f8129c40a" level={6} cateid={139}>Thông tin khách hàng bổ sung</div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col 28fbe4ad-36fb-4f56-bb3f-082a013f1c74" wrap="nowrap" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 22d4dca8-6c7a-48ba-9cb8-c6d43d351fb8" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value dcc438d1-7669-4588-b60a-121842d41530" level={6} cateid={139}>Công việc khách hàng</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row d2af7cb0-859d-4265-b562-2f24a921fe99" wrap="wrap" level={5} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 d821eab6-92a0-4565-b602-14dd32bb7333" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 657f2e1a-624c-4e26-9f55-b9f6cc6da5e0" level={7} cateid={139}>Nghề nghiệp:</div>
                                <div className="w-text wbaseItem-value 8aa1bfbc-8ff5-4bce-a661-2a24ccabd521" level={7} cateid={139}>Nghề nghiệp</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 290f0b41-8334-46af-8b26-ddfbf00a4603" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 5c48cdfa-2abc-4486-9ba9-d36fc8eb70e2" level={7} cateid={139}>Tên công ty/Nơi làm việc:</div>
                                <div className="w-text wbaseItem-value e9c2fd8b-b552-4310-9648-c0f2f19dc214" level={7} cateid={139}>Tên công ty</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 059c11ee-3b9a-4f4f-b2fc-398b09fb136a" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 54a5f23a-b893-4c22-935b-073b6e43050a" level={7} cateid={139}>Địa chỉ nơi làm việc:</div>
                                <div className="w-text wbaseItem-value f5785131-7913-48fa-bdd0-853f1b7769dd" level={7} cateid={139}>Hà Nội</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 7ab0c8f6-d1d6-418b-ac8f-672536603274" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 70803e72-9a1e-42af-9a15-99ca508cfc21" level={7} cateid={139}>Chức vụ:</div>
                                <div className="w-text wbaseItem-value 42a55182-85a9-4ec8-9892-c845d78ef0bd" level={7} cateid={139}>Chức vụ</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 0880f5bc-521a-437b-a18a-ceb883679452" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 4e4e7760-0eab-495c-9915-011052f64fb4" level={7} cateid={139}>Số điện thoại công ty:</div>
                                <div className="w-text wbaseItem-value 6279033e-9f42-477a-bc38-fb066d50bf32" level={7} cateid={139}>0123456789</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col c8a3cdd2-a0f9-4100-a1a3-a96d5f33a24e" wrap="nowrap" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row a77d2bb1-3749-4e05-bcb9-9fad7c45ba1e" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value d78da129-2b66-45ad-9d8f-60a35c394e5e" level={6} cateid={139}>Tài chính khách hàng</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 2e3fd8f8-c637-430a-b2c6-59d9252ae651" wrap="wrap" level={5} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 26e03abd-80f8-42ab-813e-f070863d5efc" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value f3deb39e-0977-492e-befe-90cbbb83c377" level={7} cateid={139}>Thu nhập hàng tháng:</div>
                                <div className="w-text wbaseItem-value f5d3b73d-992c-4ac1-a100-d227903decef" level={7} cateid={139}>15.000.000&nbsp;VND</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 8fcc8184-5f94-4174-86e7-e0377b2f299a" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value f8b345f4-0632-49c3-b91b-906ac8905e98" level={7} cateid={139}>Nguồn thu nhập khác:</div>
                                <div className="w-text wbaseItem-value b9de065f-e1b0-4238-a409-144cb1888b4e" level={7} cateid={139}>Nguồn 1</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 29f77020-0926-42e6-8b07-e6b4889cfb0d" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 529beb79-f071-4abb-9188-ac6ba0c7a5bb" level={7} cateid={139}>Thu nhập khác:</div>
                                <div className="w-text wbaseItem-value 28c654c2-a42d-4d71-92bd-1c179e065aa4" level={7} cateid={139}>500.000 VND</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 307a41aa-20a9-43a3-bc8a-64586eea8425" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value c71ba687-25b7-4479-976d-17083f096489" level={7} cateid={139}>Chi phí sinh hoạt hàng tháng:</div>
                                <div className="w-text wbaseItem-value 5df89013-c605-4581-b514-39891faa8789" level={7} cateid={139}>15.500.000 VND</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 b7029e53-0106-4bd9-9c88-2c8ce410ae07" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value c704ceeb-ff36-4cdd-af74-18d43078dcb6" level={7} cateid={139}>Tổng thanh toán nợ vay hàng tháng:</div>
                                <div className="w-text wbaseItem-value acfcdd1f-429a-41ec-93a3-b9feca600bba" level={7} cateid={139}>15.500.000 VND</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 8059f68e-bd3f-466c-9b69-9b19261a4d58" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 309dee0c-4af3-4971-9299-ddc2421cf918" level={7} cateid={139}>Số người phụ thuộc:</div>
                                <div className="w-text wbaseItem-value e0259db1-aace-466d-bd59-e4714bc64a67" level={7} cateid={139}>2</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 9a9f24f3-6e5a-4764-a356-6e9d8bd4e1c9" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 533914b7-97bd-4132-8ea5-9efc04228db4" level={7} cateid={139}>Điểm DTI:</div>
                                <div className="w-text wbaseItem-value 4b1d84b2-b3c0-4518-beee-d86ec1b37334" level={7} cateid={139}>80</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 1037c3d9-ce7d-4d1a-a59c-cb9d38188797" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 1f7f08a3-e2fe-4bad-9f8d-cc1b29889b06" level={7} cateid={139}>Kết quả DTI:</div>
                                <div className="w-text wbaseItem-value 473ecec6-5a3b-4023-9c88-85db3eec71b1" level={7} cateid={139}>80</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col 5cf27d44-4322-4e6e-9865-1e1ba6a20248" wrap="nowrap" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 9c4b9a6d-7ce7-4470-add5-50e10188d7b5" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value d9856a45-ce5c-45fd-b6a3-25ab18269570" level={6} cateid={139}>Người liên quan</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col fc5e8672-d7a0-448a-a453-91a74b8da593" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-frame wbaseItem-value w-row 90e3dddb-ac6d-4207-a4f6-03e5160a236e" wrap="wrap" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 6645ff52-4146-4306-b774-0f74394cd5e6" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value f7ca2843-3e8c-4f68-8009-1bb327d89c2b" level={8} cateid={139}>Tình trạng hôn nhân:</div>
                                    <div className="w-text wbaseItem-value 252c483a-09a1-449f-9651-24c4ea985e57" level={8} cateid={139}>Đã kết hôn</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col 1405109e-10cc-4e62-9c7f-486c42ef6b88" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value 206f3c7d-1781-4a8a-99b5-475c56c2e86e" level={6} cateid={139}>Thông tin vợ/chồng</div>
                            <div className="w-frame wbaseItem-value w-row 17843773-f40e-4241-982d-17527c37b6a9" wrap="wrap" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm 71ecdd6f-784b-4d84-bb57-ef3e568c2c6f" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 85fa07a3-c8b8-4e7e-86fb-3616a4a6f85c" level={8} cateid={139}>Họ tên vợ/chồng:</div>
                                    <div className="w-text wbaseItem-value e15833e8-8cc2-4449-bc7b-5333bc50f0d9" level={8} cateid={139}>Nguyễn Văn A</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm b470e2cc-1dec-4d5f-b0a6-cb25c7aff1dd" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 9e767537-4de1-47c1-a5d1-4d3612cfb205" level={8} cateid={139}>Số CCCD:</div>
                                    <div className="w-text wbaseItem-value 5dd4ddfc-0d98-40b0-8c90-174600b3c9d5" level={8} cateid={139}>1234567890</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm fee09027-d5bc-406e-961a-2c74ef96d223" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value fd07e0e9-9fbb-4002-883c-aac89b43cb8c" level={8} cateid={139}>Nghề nghiệp:</div>
                                    <div className="w-text wbaseItem-value f55035f7-5169-4962-9eb5-6a3e019db3cb" level={8} cateid={139}>15.500.000 VND</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm 1b85da3d-b9d9-4cda-8691-3e8851686b56" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 61a6851b-4055-40d2-875d-5f5b825cc5a9" level={8} cateid={139}>Số điện thoại:</div>
                                    <div className="w-text wbaseItem-value 5bb86e88-03c6-45f0-8407-233369f32d5a" level={8} cateid={139}>1234567890</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm 9d0bd124-e199-4071-a70d-d799fc4132cd" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 82df4773-af25-4004-a861-7d5d4714fbb1" level={8} cateid={139}>Thu nhập hàng tháng:</div>
                                    <div className="w-text wbaseItem-value 7e4461ed-e1fc-4774-96b4-cd122f596456" level={8} cateid={139}>500.000 VND</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col12-md col24-sm 1c803a1a-8a12-4c8c-a299-9842c9855937" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 8fcc9e3e-0891-4563-bfd9-f7d746356c48" level={8} cateid={139}>Thông tin CIC S37:</div>
                                    <div className="w-text wbaseItem-value 8296079c-22b0-45d2-81d9-088d42f06f39" level={8} cateid={139}>Thông tin CIC S37 tại đây</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col 022f1759-8565-44df-9c3f-2161237b8262" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value fba80200-92d4-48a7-bbf9-351bb2b8bb05" level={6} cateid={139}>Người đi cùng</div>
                            <div className="w-frame wbaseItem-value w-row 0fe3a209-7951-40f3-b251-fe6acf8b0437" wrap="wrap" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 74fb26fa-b500-4cbd-adf0-34d67df12214" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 9d698611-f29d-4b19-8fef-2c42622d11b4" level={8} cateid={139}>Họ tên:</div>
                                    <div className="w-text wbaseItem-value e5aa154d-3142-4d78-8b09-fc9e457fc1e6" level={8} cateid={139}>Nguyễn Văn A</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 865ee30a-2e87-48c3-b867-e8839be01dd9" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 5669fa70-7cd3-4889-8b16-7f5b910ac690" level={8} cateid={139}>Mối quan hệ:</div>
                                    <div className="w-text wbaseItem-value 0ef93ba9-cb4e-4229-9a8b-68b58bd87741" level={8} cateid={139}>Anh trai</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 c760a8e6-e50f-4234-bb6e-f7201ff4e527" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 8df9a8d4-ac83-4e7c-8306-0a40594d7702" level={8} cateid={139}>Số điện thoại:</div>
                                    <div className="w-text wbaseItem-value 5952f161-c9eb-4a82-ab49-0960a85adfe4" level={8} cateid={139}>1234567890</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col 55debd62-4bef-4dad-a2c9-c44f1806016c" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value 13ff9c0d-70de-4535-a876-8071019a0ab0" level={6} cateid={139}>Thông tin người thân</div>
                            <div className="w-frame wbaseItem-value w-row 4a66f592-c6c9-4a7e-8bd3-45b983e8c0b2" wrap="wrap" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 dd53c27f-1061-4f08-ac2a-a53a5140a961" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value a576eb19-0afd-4b37-bff6-d60fa600f54c" level={8} cateid={139}>Người thân 2:</div>
                                    <div className="w-text wbaseItem-value 732894ca-d630-4f98-983b-8b6b57eb3d66" level={8} cateid={139}>Nguyễn Văn A</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 ace42014-8ecd-409f-97ac-f79fda14b04c" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 972d1840-468b-4dcd-aeb0-3dab9e87ad2e" level={8} cateid={139}>Mối quan hệ:</div>
                                    <div className="w-text wbaseItem-value c14972dd-de68-4cbb-8bc2-62e5ca2c1756" level={8} cateid={139}>Anh trai</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 cd34a651-2f19-42ff-875f-4cc79b29731a" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 2d7b88dc-8d3c-4204-85ad-fe5d69bce28d" level={8} cateid={139}>Số điện thoại:</div>
                                    <div className="w-text wbaseItem-value 76cef0ef-6287-4f32-b10d-41be3de69e53" level={8} cateid={139}>1234567890</div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row 89039bbd-94ce-41e8-a800-decff25b2e98" wrap="wrap" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 5f8ee20c-f940-47e3-b21d-3029ec84ef34" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value bcfc94e1-d398-4147-bf9e-cd1d2d76fe5f" level={8} cateid={139}>Người thân 1:</div>
                                    <div className="w-text wbaseItem-value 9f65b17a-14c5-4bf8-bde8-b34834d9f80b" level={8} cateid={139}>Nguyễn Văn A</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 c69252d5-f7cf-4cde-a47e-faeaeb991535" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 07e4dd45-885e-41e2-986f-8d02386d5b90" level={8} cateid={139}>Mối quan hệ:</div>
                                    <div className="w-text wbaseItem-value 1242203c-6d30-49a8-ac57-7f067991f5e6" level={8} cateid={139}>Anh trai</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 06f641ef-89f6-4541-b454-61e7894e497c" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 408addfb-73f5-48a6-8d31-76d416b63691" level={8} cateid={139}>Số điện thoại:</div>
                                    <div className="w-text wbaseItem-value e4180766-cfe2-4c42-9818-a4733b77703f" level={8} cateid={139}>1234567890</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col 4900ba7d-7222-414d-86aa-111fb6ac6989" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value 5291fb9f-24a8-4950-b958-68f39a92c7c2" level={6} cateid={139}>Người đứng tên đăng ký xe</div>
                            <div className="w-frame wbaseItem-value w-row 05c9dbfb-51d2-4190-b108-10b4b5a7171e" wrap="wrap" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 b2747e3d-c2d5-4d2e-bf91-a09fcf3d6701" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 21452ad9-30a1-4f31-b8ed-0b216d5844ec" level={8} cateid={139}>Họ tên:</div>
                                    <div className="w-text wbaseItem-value f312a558-fabe-4fc7-af86-1e7c516936ef" level={8} cateid={139}>Nguyễn Văn A</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 845372f7-0e85-40bc-aed3-0b06601381d2" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 96f510d5-cfc8-42e3-be68-6d4ad4bd33d4" level={8} cateid={139}>Tên trường học:</div>
                                    <div className="w-text wbaseItem-value f7f7471a-1c20-4cee-9474-384ef5ee32c7" level={8} cateid={139}>ABC</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 10c42ee5-348a-48fb-a22b-b6b1c3d96223" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 68e39aeb-dcd1-4752-ba6c-01a6ffb656df" level={8} cateid={139}>Mối quan hệ:</div>
                                    <div className="w-text wbaseItem-value 1b47ad38-db09-4197-b652-d186eeed6d7d" level={8} cateid={139}>Anh trai</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 3eaccd09-d419-4181-a02c-3ceb4219ead2" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value dc16dc5d-b2e1-40e3-b55a-715fb139f6d8" level={8} cateid={139}>Số điện thoại:</div>
                                    <div className="w-text wbaseItem-value 27d4b1e9-8f21-4079-8dc2-b2488eb36b6e" level={8} cateid={139}>1234567890</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 04426a34-a46a-45c4-9fe5-84eee8cce537" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value cb7f84a2-cb4b-4706-801f-5df79bcabf20" level={8} cateid={139}>Số CCCD:</div>
                                    <div className="w-text wbaseItem-value cd8423c8-cdd6-4de0-8b79-8519127b11c5" level={8} cateid={139}>1234567890</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col a6223bc1-2b22-4b81-a4f6-e39bdc8dc4a7" wrap="nowrap" level={4} cateid={140}>
                        <div className="w-text wbaseItem-value de587514-4c94-4aa7-8c51-06ab51362165" level={5} cateid={139}>Hồ sơ đính kèm</div>
                        <table className="w-table wbaseItem-value b35b65dc-783c-4ad9-9d1a-f99aec998041" type={0} level={5} cateid={117}>
                            <tbody><tr className="table-row">
                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row 1e557909-eafd-4144-9965-895f97d1f4ab" wrap="nowrap" level={6} cateid={140}>
                                        <div className="w-text wbaseItem-value 2f554efd-23a0-4d19-b3f5-206313e12db5" level={7} cateid={139}>Loại hồ sơ</div>
                                    </div>
                                </td>
                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row 936bbec1-7be0-411d-abef-b752fc3a55ac" wrap="nowrap" level={6} cateid={140}>
                                        <div className="w-text wbaseItem-value 84ff51ed-3484-41dd-b2d1-011e54b11ecc" level={7} cateid={139}>Tệp đính kèm</div>
                                    </div>
                                </td>
                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row 7af4c44b-904b-40bf-a910-aba436868993" wrap="nowrap" level={6} cateid={140} />
                                </td>
                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row c745071d-d118-4956-b544-1483825e08ff" wrap="nowrap" level={6} cateid={140}>
                                        <div className="w-text wbaseItem-value f57550b8-274a-4b12-9378-82b28d4f4350" level={7} cateid={139}>Người upload</div>
                                    </div>
                                </td>
                                <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row a8b88120-b553-45c0-9d74-6f744e446ea5" wrap="nowrap" level={6} cateid={140}>
                                        <div className="w-text wbaseItem-value dd600fe3-a5b4-4a03-aec9-5e3dfaf4f746" level={7} cateid={139}>Thời gian upload</div>
                                    </div>
                                </td>
                            </tr>
                                <tr className="table-row">
                                    <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 19abb703-c4cb-4994-9879-341ff0ae2112" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value 4b9bc02f-67a5-4444-b69f-7c03b2b0fb92" level={7} cateid={139}>Hồ sơ 1</div>
                                        </div>
                                    </td>
                                    <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 481b9075-9895-4658-b7ee-192c9e842cdf" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value 82df6906-4f24-4254-9456-f5d75b3c2d08" level={7} cateid={139}>ho_so_1.pdf</div>
                                        </div>
                                    </td>
                                    <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 8763a3b5-84db-41c0-b0c7-07ce0bb8785f" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-svg wbaseItem-value 5061f391-096e-4163-a204-690ae6226474" level={7} cateid={115}><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 10.5L12 16.3333L17 10.5H12.8333V3H11.1667V10.5H7Z" fill="#009944FF" fillOpacity="0.6" />
                                                <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#009944FF" fillOpacity="0.6" />
                                            </svg>
                                            </div>
                                        </div>
                                    </td>
                                    <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row fc416449-a488-4b45-9971-386c5fb173b1" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value fd9af904-05a3-467a-b66c-562c2b26072e" level={7} cateid={139}>JIVF001234 - Trần Bình An</div>
                                        </div>
                                    </td>
                                    <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 39b67b36-ffc0-4a45-949a-1d335720c85f" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value 3cc68ae7-92cc-4f9f-8ff3-6b27b3487507" level={7} cateid={139}>07/05/2016 15:06</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="table-row">
                                    <td id="3x1" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row d8d85ff4-beef-4c38-8ce8-b3589bb874bb" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value cd75e289-f883-4a18-a97f-23d6c648fab3" level={7} cateid={139}>Hồ sơ 1</div>
                                        </div>
                                    </td>
                                    <td id="3x2" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 1e6c7336-a04b-41f3-80a2-8a762c29e350" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value 3a5d5e7d-948e-487f-a31f-61b2b1511ed4" level={7} cateid={139}>ho_so_1.pdf</div>
                                        </div>
                                    </td>
                                    <td id="3x3" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 81b83e0f-4e7d-443d-b150-ba9e7ddf5047" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-svg wbaseItem-value 4d347f14-2d8c-4761-a22c-008b126b46c2" level={7} cateid={115}><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 10.5L12 16.3333L17 10.5H12.8333V3H11.1667V10.5H7Z" fill="#009944FF" fillOpacity="0.6" />
                                                <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#009944FF" fillOpacity="0.6" />
                                            </svg>
                                            </div>
                                        </div>
                                    </td>
                                    <td id="3x4" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 3fa39c14-2dce-49b3-a741-59dcec97873c" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value 6240f64f-cb01-4938-85fb-b4fced31b715" level={7} cateid={139}>JIVF001234 - Trần Bình An</div>
                                        </div>
                                    </td>
                                    <td id="3x5" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row e69d6643-67d3-4d8a-8140-ba83b08f9f18" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value ddee60fc-ede8-4645-bf4f-9990566fc884" level={7} cateid={139}>07/05/2016 15:06</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="table-row">
                                    <td id="4x1" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 5719ec05-1c49-4624-8848-4f9094f03589" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value 54d7c182-5bb3-47e9-b010-24a75deea75e" level={7} cateid={139}>Hồ sơ 1</div>
                                        </div>
                                    </td>
                                    <td id="4x2" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row a6de727c-35d3-496d-8f00-63dbe48af579" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value 231aa911-d362-4619-a419-a23dea865055" level={7} cateid={139}>ho_so_1.pdf</div>
                                        </div>
                                    </td>
                                    <td id="4x3" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row e693167d-f5e8-4bda-9309-3225843d2fd1" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-svg wbaseItem-value 6e6255c4-fe9d-4900-9e26-4994f44e6a48" level={7} cateid={115}><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 10.5L12 16.3333L17 10.5H12.8333V3H11.1667V10.5H7Z" fill="#009944FF" fillOpacity="0.6" />
                                                <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#009944FF" fillOpacity="0.6" />
                                            </svg>
                                            </div>
                                        </div>
                                    </td>
                                    <td id="4x4" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 0d13dde5-3d18-4e74-9b09-dd3838e3e073" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value 99e86bbd-baef-421c-a00c-f124837bcacf" level={7} cateid={139}>JIVF001234 - Trần Bình An</div>
                                        </div>
                                    </td>
                                    <td id="4x5" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 3d089e0d-4ca5-48f1-8367-8a433d733ec0" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value d2197e96-7caf-43ea-9234-320258932239" level={7} cateid={139}>07/05/2016 15:06</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="table-row">
                                    <td id="5x1" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 9e606a22-415d-4dfe-ac47-e699ae00b14e" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value e498d548-b821-441e-8bf7-49e8b6a5ee77" level={7} cateid={139}>Hồ sơ 1</div>
                                        </div>
                                    </td>
                                    <td id="5x2" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row bacdee02-fd23-4452-b75c-bd90be9ec95b" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value 3d6d0add-dce6-467b-b9bc-4e198f770670" level={7} cateid={139}>ho_so_1.pdf</div>
                                        </div>
                                    </td>
                                    <td id="5x3" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row de1aa936-25a3-4e07-a070-bf31a76584a0" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-svg wbaseItem-value 9069619a-37d7-4076-a4e3-633055cd5f82" level={7} cateid={115}><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 10.5L12 16.3333L17 10.5H12.8333V3H11.1667V10.5H7Z" fill="#009944FF" fillOpacity="0.6" />
                                                <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#009944FF" fillOpacity="0.6" />
                                            </svg>
                                            </div>
                                        </div>
                                    </td>
                                    <td id="5x4" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row c4ad69a5-8758-4bc9-8cfc-8dde21eff011" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value 047508d6-e76f-42ba-9974-973024dfd65e" level={7} cateid={139}>JIVF001234 - Trần Bình An</div>
                                        </div>
                                    </td>
                                    <td id="5x5" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row c7931df9-1353-4743-9c82-068d4cd7faa8" wrap="nowrap" level={6} cateid={140}>
                                            <div className="w-text wbaseItem-value f83eafea-97d0-4e8a-8992-e29ace626117" level={7} cateid={139}>07/05/2016 15:06</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div className="w-frame wbaseItem-value w-col a9550fb5-70b5-4c26-9146-fdbfe51682d3" wrap="nowrap" level={4} cateid={140}>
                        <div className="w-text wbaseItem-value bf19c988-2776-4bd0-9c28-79ec59914c23" level={5} cateid={139}>Ghi chú</div>
                        <div className="w-frame wbaseItem-value w-col da7f59bb-922b-46ee-b593-b301a595b32f" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col cccbbfe3-e7e0-415b-95ed-9a557ba50e16" wrap="nowrap" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row 1c397f94-e9a6-4caf-9712-79b7a7ae6d75" wrap="nowrap" level={7} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row f465186b-ee25-4f4a-99a1-e497ce83f2e2" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-svg wbaseItem-value 4fbf4176-7555-499e-91df-1d3dfd293344" level={9} cateid={115}><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.9997 7C16.9997 9.76142 14.7611 12 11.9997 12C9.23826 12 6.99968 9.76142 6.99968 7C6.99968 4.23858 9.23826 2 11.9997 2C14.7611 2 16.9997 4.23858 16.9997 7Z" fill="#28282999" />
                                            <path d="M3.66667 19.222C3.66667 17.177 6.375 13.667 12 13.667C17.625 13.667 20.3333 17.177 20.3333 19.222V20.3337C20.3333 20.7757 20.1577 21.1996 19.8452 21.5122C19.5326 21.8247 19.1087 22.0003 18.6667 22.0003H5.33334C4.89131 22.0003 4.46739 21.8247 4.15483 21.5122C3.84227 21.1996 3.66667 20.7757 3.66667 20.3337V19.222Z" fill="#28282999" />
                                        </svg>
                                        </div>
                                        <div className="w-text wbaseItem-value b3d9f0bc-a3bd-467c-a2c6-7810f47782aa" level={9} cateid={139}>nguoiupload@gmail.com</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 12262092-3b25-480b-9e02-868c3cff3a24" wrap="nowrap" level={8} cateid={140}>
                                        <div className="w-svg wbaseItem-value de8903a7-c0c4-4e70-a446-903952e0c149" level={9} cateid={115}><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.16667 11.1667C5.70643 11.1667 5.33333 11.5398 5.33333 12V12.8333C5.33333 13.2936 5.70643 13.6667 6.16667 13.6667H7.83333C8.29357 13.6667 8.66667 13.2936 8.66667 12.8333V12C8.66667 11.5398 8.29357 11.1667 7.83333 11.1667H6.16667Z" fill="#28282999" />
                                            <path d="M11.1667 11.1667C10.7064 11.1667 10.3333 11.5398 10.3333 12V12.8333C10.3333 13.2936 10.7064 13.6667 11.1667 13.6667H12.8333C13.2936 13.6667 13.6667 13.2936 13.6667 12.8333V12C13.6667 11.5398 13.2936 11.1667 12.8333 11.1667H11.1667Z" fill="#28282999" />
                                            <path d="M5.33333 16.1667C5.33333 15.7064 5.70643 15.3333 6.16667 15.3333H7.83333C8.29357 15.3333 8.66667 15.7064 8.66667 16.1667V17C8.66667 17.4602 8.29357 17.8333 7.83333 17.8333H6.16667C5.70643 17.8333 5.33333 17.4602 5.33333 17V16.1667Z" fill="#28282999" />
                                            <path d="M11.1667 15.3333C10.7064 15.3333 10.3333 15.7064 10.3333 16.1667V17C10.3333 17.4602 10.7064 17.8333 11.1667 17.8333H12.8333C13.2936 17.8333 13.6667 17.4602 13.6667 17V16.1667C13.6667 15.7064 13.2936 15.3333 12.8333 15.3333H11.1667Z" fill="#28282999" />
                                            <path d="M15.3333 12C15.3333 11.5398 15.7064 11.1667 16.1667 11.1667H17.8333C18.2936 11.1667 18.6667 11.5398 18.6667 12V12.8333C18.6667 13.2936 18.2936 13.6667 17.8333 13.6667H16.1667C15.7064 13.6667 15.3333 13.2936 15.3333 12.8333V12Z" fill="#28282999" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.83333 2.83333C7.83333 2.3731 7.46024 2 7 2C6.53976 2 6.16667 2.3731 6.16667 2.83333V3.66667H4.5C3.11929 3.66667 2 4.78595 2 6.16667V18.6667C2 20.0474 3.11929 21.1667 4.5 21.1667H19.5C20.8807 21.1667 22 20.0474 22 18.6667V6.16667C22 4.78595 20.8807 3.66667 19.5 3.66667H17.8333V2.83333C17.8333 2.3731 17.4602 2 17 2C16.5398 2 16.1667 2.3731 16.1667 2.83333V3.66667H7.83333V2.83333ZM4.5 5.33333C4.03976 5.33333 3.66667 5.70643 3.66667 6.16667V7.83333H20.3333V6.16667C20.3333 5.70643 19.9602 5.33333 19.5 5.33333H17.8333C17.8333 5.79357 17.4602 6.16667 17 6.16667C16.5398 6.16667 16.1667 5.79357 16.1667 5.33333H7.83333C7.83333 5.79357 7.46024 6.16667 7 6.16667C6.53976 6.16667 6.16667 5.79357 6.16667 5.33333H4.5ZM4.5 19.5C4.03976 19.5 3.66667 19.1269 3.66667 18.6667V9.5H20.3333V18.6667C20.3333 19.1269 19.9602 19.5 19.5 19.5H4.5Z" fill="#28282999" />
                                        </svg>
                                        </div>
                                        <div className="w-text wbaseItem-value cdd218e0-c412-44c5-b5d9-7ca1c6ff2015" level={9} cateid={139}>01/06/2023</div>
                                    </div>
                                </div>
                                <div className="w-text wbaseItem-value 9c9e3036-bfda-4d67-9ce5-dcbd32ba4e9a" level={7} cateid={139}>Bổ sung đầy đủ thông tin cá nhân, thông tin người thân. Hồ sơ đính kèm.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-frame wbaseItem-value w-row 2904b8c6-533a-4111-ad13-d4a04777286d" wrap="nowrap" level={2} cateid={140}>
                <div className="w-frame wbaseItem-value w-row 90d8588d-b0c5-4391-8325-adc7f57830c5" wrap="nowrap" level={3} cateid={140}><button type="button" className="w-button wbaseItem-value w-row 85d115e5-1604-4d46-bbd8-e00cf61498ea" wrap="nowrap" name-field="Button" level={4} cateid={29}>
                    <div className="w-text wbaseItem-value 6ff28937-812d-4347-83eb-9bc0f15275e7" name-field="Text" level={5} cateid={139}>Đóng</div>
                </button></div>
            </div>
        </div>
    )
}