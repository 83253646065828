import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import { useForm, Controller } from 'react-hook-form';
import Select2 from '../../components/select2/Select2';
import '../../css/nhomquyen/add.css';
import { PopupType, WPopup } from '../../components/Popup/Popup';
import { Createdocuments, Editdocuments, UploadFiledocuments, changestatusDoc, getListdocuments, getgroupdocument, gettypedocument } from '../../redux/middleware/api/documents/documentsDA';
export default function Themmoichungtu() {
    const { register, handleSubmit, control, setValue, getValues, formState: { errors } } = useForm({ shouldFocusError: false });
    const [data, setData] = useState({});
    const [documentTypeCodeList, setdocumentTypeCodeList] = useState([]);
    const [documentGroupCodeList, setdocumentGroupCodeList] = useState([]);
    const [isCancel, setisCancel] = useState();
    const [filePath, setfilePath] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const fileInputRef = useRef();
    const handleFileChange = async (event) => {
        debugger;
        const file = event.target.files[0];
        // setfilePath(file.name)
        // setfileAvatar(file)
        if (file) {
            // const reader = new FileReader();
            // reader.onloadend = () => {
            //     setfileImg(reader.result);
            // };
            // reader.readAsDataURL(file);
            var formdata = new FormData();
            formdata.append('file', file);
            var res = await UploadFiledocuments(formdata);
            debugger;
            if (res.code == '200') {
                setValue('filePath', [...event.target.files]);
                setfilePath(res.data.ten);

            }

        }
    };
    const handleBrowseClick = () => {
        fileInputRef.current.click(); // Trigger the click event of the hidden file input
    };
    let { id } = useParams();
    const notificationAlert = useRef();

    useEffect(() => {
        getAlltype()
        getAllgroup()
        _InitData();
    }, []);

    const _InitData = async () => {
        if (id) {

            const obj = {
                'documentCode': id
            }
            const res = await getListdocuments(1, 1, obj);
            debugger;
            if (res != null) {
                setData(res.pageData[0]);
                setValue('documentCode', res.pageData[0].losDocumentDetail?.documentCode)
                setValue('documentName', res.pageData[0].losDocumentDetail?.documentName)
                setValue('status', res.pageData[0].losDocumentDetail?.status)
                setValue('documentTypeCode', res.pageData[0].losDocumentDetail?.documentTypeCode)
                setValue('documentGroupCode', res.pageData[0].losDocumentDetail?.documentGroupCode)
                setValue('description', res.pageData[0].losDocumentDetail?.description)
                setfilePath(res.pageData[0].losDocumentDetail?.filePath)
            }
        }
    }
    const getAlltype = async () => {
        const response = await gettypedocument(1, 100,);
        if (response != null) {
            setdocumentTypeCodeList(response.pageData);
        }
    }
    const getAllgroup = async () => {
        const response = await getgroupdocument(1, 100,);
        if (response != null) {
            setdocumentGroupCodeList(response.pageData);
        }
    }
    const onCancel = (val) => {
        setisCancel(val);
        if (val === true) {
            navigate('/admin/chung-tu');
        }
    }
    const onSumit = async (event) => {
        if (filePath) {
            event.filePath = filePath;
            if (event.status) {
                event.status = parseInt(event.status)
            }
            if (data.losDocumentDetail?.id && data.losDocumentDetail?.id !== 0) {
                debugger;
                event.id = data.losDocumentDetail?.id;
                const res = await Editdocuments(event, data.losDocumentDetail?.id);
                if (res.code === '200') {
                    await notify(notificationAlert, SUCCESS, 'Chỉnh sửa Chứng từ thành công.')
                    navigate('/admin/chung-tu');
                } else {
                    notify(notificationAlert, ERROR, res.message);
                }
            } else {

                const res = await Createdocuments(event);
                debugger;
                if (res.code === '200') {
                    navigate('/admin/chung-tu');
                    notify(notificationAlert, SUCCESS, 'Thêm mới Chứng từ thành công.')

                } else {
                    notify(notificationAlert, ERROR, res.message);
                }
            }
        } else {
            notify(notificationAlert, ERROR, 'Bạn chưa upload File chứng từ');
        }
    }
    const OnchangeSwitch = async () => {
        const respone = await changestatusDoc(data.losDocumentDetail?.documentCode);
        if (respone.code == '200') {
            // var tmp = data[index].losDocumentDetail;
            setData(prevData => ({
                ...prevData,
                losDocumentDetail: {
                    ...prevData.losDocumentDetail,
                    status: prevData.losDocumentDetail.status === 1 ? 0 : 1,
                },
            }));
            setValue('status', data.losDocumentDetail?.status === 1 ? 0 : 1)
        } else {
            notify(notificationAlert, ERROR, respone.message)
        }
    }
    return (
        <form onSubmit={handleSubmit(onSumit)} autoComplete='off' className="w-frame w-form wbaseItem-value w-col e14e0b64-a97e-4309-84d4-c901a202b4da" wrap="nowrap" level={2}
            cateid={128}>
            <NotificationAlert ref={notificationAlert} />
            {
                isCancel == false &&
                <WPopup
                    type={PopupType.WARNING}
                    title={!id ? 'Hủy thêm mới Chứng từ' : 'Hủy chỉnh sửa Chứng từ'}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => onCancel(true)}
                    cancelAction={() => onCancel(null)}
                    cancelButton={true}
                    cancelTitle='Giữ lại'
                    message={'Những thông tin vừa bạn vừa nhập sẽ không được lưu lại'}
                />
            }
            <div className="w-frame wbaseItem-value w-col 55169930-5156-452b-a8d9-559b160d8fd0" wrap="nowrap" level={3}
                cateid={140}>
                <div className="w-frame wbaseItem-value w-row e4c8db4f-a292-4630-840f-4321cea9a96c" wrap="nowrap" level={4}
                    cateid={140}>
                    <div className="w-frame wbaseItem-value w-col 61c5c3cd-5188-43c8-91b5-1fe7b03e3c39" wrap="nowrap" level={5}
                        cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 4f044728-0e07-4e2f-aa4b-106a7f97b7af" wrap="nowrap"
                            level={6} cateid={140}>
                            <div className="w-text wbaseItem-value 631d5e3e-5ff1-42bb-9224-6d2189c8b545" level={7} cateid={139}>
                                {id ? 'Chỉnh sửa' : 'Thêm mới'} Chứng từ&nbsp;</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row breadcrumb 86cc162c-67d1-4a5a-a8ef-8761295ca239"
                            wrap="nowrap" level={6} cateid={140}>
                            <button type="button"
                                className="w-button wbaseItem-value w-row 29a214b1-e278-4d04-959e-1b70adc3b2a9" wrap="nowrap"
                                name-field="Button" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value ecdef2e8-1693-46bd-99d2-d5bd00346b98" name-field="Text"
                                    level={8} cateid={139}>Quản trị hệ thống</div>
                                <div className="w-svg wbaseItem-value 22474178-c66e-4f8b-8c41-88a03a8f8a10" level={8}
                                    cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z"
                                            fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                            <button type="button"
                                className="w-button wbaseItem-value w-row 7807c449-be3d-4411-97d2-3e93e42ae5a6" wrap="nowrap"
                                name-field="Button" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value 9c18f47a-0aa3-4dad-86d7-e83232c6d711" name-field="Text"
                                    level={8} cateid={139}>Chứng từ</div>
                                <div className="w-svg wbaseItem-value 5384ce20-5697-4020-a917-6d5093852462" level={8}
                                    cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z"
                                            fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                            <div className="w-text wbaseItem-value 924a1cb9-ccdd-4314-b624-d40f8f7661b1" level={7} cateid={139}>
                                {id ? 'Chỉnh sửa' : 'Thêm mới'}</div>
                        </div>
                    </div>
                    {
                        id ? <div className="w-frame wbaseItem-value w-row 0ef46e31-4af4-4b4b-9b9a-64eec3133efb" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value 54368f73-0d68-477e-810b-98623942a257" level={6} cateid={139}>{data?.losDocumentDetail?.status == 1 ? 'Đang hoạt động' : 'Không hoạt động'}</div>
                            <label onClick={OnchangeSwitch} htmlFor="input-a6e97769-2e1a-4888-8045-a63e94925bc2" className="w-switch wbaseItem-value a6e97769-2e1a-4888-8045-a63e94925bc2" value="true" name-field="ControlStatus" level={6} cateid={81}>
                                <input type="checkbox" disabled checked={data?.losDocumentDetail?.status == 1 ? true : false} id="input-a6e97769-2e1a-4888-8045-a63e94925bc2" name="ControlStatus" />
                                <span className="slider">
                                </span>
                            </label>
                        </div> : null
                    }

                </div>
                <div className="w-frame wbaseItem-value w-col ee2b2d2c-8eee-4daa-b96e-d7fc7b406955" wrap="nowrap" scroll="true"
                    level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col f2bae906-3670-41a0-a977-fb7dc48b1d55" wrap="nowrap" level={5}
                        cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 57e8967a-18fa-4213-87ea-7467a2618436" wrap="nowrap"
                            level={6} cateid={140}>
                            <div className="w-text wbaseItem-value d7713971-43be-4840-90db-212f89a383b7" level={7} cateid={139}>
                                Thông tin Chứng từ</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 21fab225-9cef-43b4-8b9a-dc025c921424" wrap="wrap"
                            level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm d9dc8dc9-8c5b-4681-973a-364163c17c83"
                                wrap="nowrap" level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row 7e81084d-5b16-4968-a7c6-ef158e9c179d"
                                    wrap="nowrap" level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value f2285d87-a06c-4911-b28d-2df95eb2a10b" level={9}
                                        cateid={139}>Mã Chứng từ</div>
                                    <div className="w-text wbaseItem-value 0db6357e-7422-4399-83d4-0a298d7b157a" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={"w-textformfield wbaseItem-value w-row e8d4f42c-9efd-47a7-9f46-6a11cd4462a1 " + (errors.documentCode && 'helper-text')}
                                    wrap="nowrap" name-field="RoleID" level={8} cateid={85} helper-text={errors.documentCode && 'Nhập mã Chứng từ'} placeholder="Nhập mã Chứng từ">
                                    <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f" name-field="Textfield"
                                        level={9} cateid={86}>
                                        <div className="textfield">
                                            <input id="textfield-ee5c5ee0-f72f-480e-9462-53f6362a2d1f" name="documentCode"
                                                placeholder="Nhập mã Chứng từ" maxLength={20} {...register("documentCode", { required: true })} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm cea15270-db58-4d63-ac3f-b477c880d9e9"
                                wrap="nowrap" level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    wrap="nowrap" level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Tên Chứng từ</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={"w-textformfield wbaseItem-value w-row eada5e8e-edbd-443b-9579-71cc44317df2 " + (errors.documentName && 'helper-text')}
                                    wrap="nowrap" name-field="RoleName" level={8} cateid={85} placeholder="Nhập tên Chứng từ" helper-text={errors.documentName && ' Vui lòng nhập tên Chứng từ'}>
                                    <div className="wbaseItem-value 0bb5e726-0339-4344-aaf9-91be0c4c0dd0" name-field="Textfield"
                                        level={9} cateid={86}>
                                        <div className="textfield">
                                            <input id="textfield-0bb5e726-0339-4344-aaf9-91be0c4c0dd0" name="documentName"
                                                placeholder="Nhập tên Chứng từ"  {...register("documentName", { required: true })} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                wrap="nowrap" level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    wrap="nowrap" level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Loại Chứng từ</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={'select2-custom ' + (errors.documentTypeCode && 'helper-text')} helper-text={errors.documentTypeCode && 'Vui lòng chọn loại Chứng từ'}>
                                    <Controller
                                        name="documentTypeCode"
                                        control={control}
                                        {...register("documentTypeCode")}
                                        style={{ order: 2 }}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select2 {...field} isFirst={true} nameFirst={'Chọn loại Chứng từ'}
                                                data={documentTypeCodeList.map((item, _) => { return { id: item.documentTypeCode, name: item.documentTypeName } })}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                wrap="nowrap" level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    wrap="nowrap" level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Trạng thái</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={'select2-custom ' + (errors.status && 'helper-text')} helper-text={errors.status && 'Vui lòng chọn trạng thái'}>
                                    <Controller
                                        name="status"
                                        {...register("status")}
                                        control={control}
                                        rules={{ required: true }}
                                        style={{ order: 2 }}
                                        render={({ field }) => (
                                            <Select2 {...field} data={[{ id: 1, name: 'Hoạt động' }, { id: 0, name: 'Không hoạt động' }]}
                                                options={{
                                                    placeholder: 'Chọn trạng thái'
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                wrap="nowrap" level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    wrap="nowrap" level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Nhóm Chứng từ</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={'select2-custom ' + (errors.documentGroupCode && 'helper-text')} helper-text={errors.documentGroupCode && 'Vui lòng chọn nhóm Chứng từ'}>
                                    <Controller
                                        name="documentGroupCode"
                                        control={control}
                                        {...register("documentGroupCode")}
                                        style={{ order: 2 }}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select2 {...field} isFirst={true} nameFirst={'Chọn nhóm Chứng từ'}
                                                data={documentGroupCodeList.map((item, _) => { return { id: item.documentGroupCode, name: item.documentGroupName } })}

                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                wrap="nowrap" level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    wrap="nowrap" level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>File mẫu chứng từ</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                {/* { getValues("imageProfile")?.length > 0 ? 
                                                <div className='w-frame wbaseItem-value w-row 6ac84289-acb3-4274-819b-ee8371f76a61'>
                                                    <div className="w-text wbaseItem-value e6d7d81e-044b-4c01-a317-0ea7a0e746a1" level={11} cateid={139} style={{ color: "#367be2", marginTop: "2px" }}>{getValues("imageProfile")[0].name}</div>
                                                    <button type='button' onClick={() => {
                                                        setValue("imageProfile", []);
                                                        // setEnabledEdit(false);
                                                    }} className='w-button wbaseItem-value w-row 048f37f4-3754-433e-a810-b27ee519f8d2' style={{ backgroundColor: "transparent" }}>
                                                        <div className="w-svg wbaseItem-value b77c8d2c-435b-4b57-bf0f-5c6c035ca28d" level={11} cateid={115}>
                                                            <svg width={20} height={20} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.48302 13.1195C3.51435 13.5394 3.70291 13.932 4.01105 14.2189C4.31918 14.5058 4.72421 14.666 5.14524 14.6673H10.8541C11.2752 14.666 11.6802 14.5058 11.9883 14.2189C12.2965 13.932 12.485 13.5394 12.5164 13.1195L13.0408 5.77843H2.95858L3.48302 13.1195Z" fill="#E60012" />
                                                                <path d="M14.1108 3.55621H10.7775V1.88954C10.7775 1.7422 10.7189 1.60089 10.6147 1.4967C10.5105 1.39252 10.3692 1.33398 10.2219 1.33398H5.77745C5.63011 1.33398 5.4888 1.39252 5.38462 1.4967C5.28043 1.60089 5.2219 1.7422 5.2219 1.88954V3.55621H1.88856C1.74122 3.55621 1.59991 3.61474 1.49573 3.71893C1.39154 3.82311 1.33301 3.96442 1.33301 4.11176C1.33301 4.2591 1.39154 4.40041 1.49573 4.5046C1.59991 4.60879 1.74122 4.66732 1.88856 4.66732H14.1108C14.2581 4.66732 14.3994 4.60879 14.5036 4.5046C14.6078 4.40041 14.6663 4.2591 14.6663 4.11176C14.6663 3.96442 14.6078 3.82311 14.5036 3.71893C14.3994 3.61474 14.2581 3.55621 14.1108 3.55621ZM6.33301 2.4451H9.66634V3.55621H6.33301V2.4451Z" fill="#E60012" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                </div>:
                                <div className="w-frame wbaseItem-value w-row 53d53a87-96d8-4184-9ca3-f07f6fd08dde" wrap="nowrap" level={9} cateid={140}>
                                    <input
                                        {...register("imageProfile")}
                                        type="file"
                                        name='imageProfile'
                                        placeholder='Upload File'
                                        className="w-button wbaseItem-value w-row 048f37f4-3754-433e-a810-b27ee519f8d2"
                                        wrap="nowrap"
                                        name-field="Button"
                                        level={10}
                                        cateid={29}
                                        style={{ display: "none" }}
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                    />

                                    <div
                                        onClick={handleBrowseClick}
                                        className='nav-link w-button wbaseItem-value w-row 048f37f4-3754-433e-a810-b27ee519f8d2'>
                                        <div className="w-svg wbaseItem-value b77c8d2c-435b-4b57-bf0f-5c6c035ca28d" level={11} cateid={115}>
                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 8.83333L12 3L17 8.83333H12.8333V16.3333H11.1667V8.83333H7Z" fill="#28282999">
                                                </path>
                                                <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#28282999">
                                                </path>
                                            </svg>
                                        </div>
                                        <div className="w-text wbaseItem-value e6d7d81e-044b-4c01-a317-0ea7a0e746a1" name-field="Text" level={11} cateid={139}>Upload File</div>
                                        <div className="w-text wbaseItem-value e6d7d81e-044b-4c01-a317-0ea7a0e746a1" name-field="Text" level={11} cateid={139}>{filePath}</div>
                                    </div>
                                </div>
} */}
                                {
                                    filePath ?
                                        <div className='w-frame wbaseItem-value w-row 6ac84289-acb3-4274-819b-ee8371f76a61'>
                                            <div className="w-text wbaseItem-value e6d7d81e-044b-4c01-a317-0ea7a0e746a1" level={11} cateid={139} style={{ color: "#367be2", marginTop: "2px" }}>{filePath}</div>
                                            <button type='button' onClick={() => {
                                                setfilePath();
                                                // setEnabledEdit(false);
                                            }} className='w-button wbaseItem-value w-row 048f37f4-3754-433e-a810-b27ee519f8d2' style={{ backgroundColor: "transparent" }}>
                                                <div className="w-svg wbaseItem-value b77c8d2c-435b-4b57-bf0f-5c6c035ca28d" level={11} cateid={115}>
                                                    <svg width={20} height={20} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.48302 13.1195C3.51435 13.5394 3.70291 13.932 4.01105 14.2189C4.31918 14.5058 4.72421 14.666 5.14524 14.6673H10.8541C11.2752 14.666 11.6802 14.5058 11.9883 14.2189C12.2965 13.932 12.485 13.5394 12.5164 13.1195L13.0408 5.77843H2.95858L3.48302 13.1195Z" fill="#E60012" />
                                                        <path d="M14.1108 3.55621H10.7775V1.88954C10.7775 1.7422 10.7189 1.60089 10.6147 1.4967C10.5105 1.39252 10.3692 1.33398 10.2219 1.33398H5.77745C5.63011 1.33398 5.4888 1.39252 5.38462 1.4967C5.28043 1.60089 5.2219 1.7422 5.2219 1.88954V3.55621H1.88856C1.74122 3.55621 1.59991 3.61474 1.49573 3.71893C1.39154 3.82311 1.33301 3.96442 1.33301 4.11176C1.33301 4.2591 1.39154 4.40041 1.49573 4.5046C1.59991 4.60879 1.74122 4.66732 1.88856 4.66732H14.1108C14.2581 4.66732 14.3994 4.60879 14.5036 4.5046C14.6078 4.40041 14.6663 4.2591 14.6663 4.11176C14.6663 3.96442 14.6078 3.82311 14.5036 3.71893C14.3994 3.61474 14.2581 3.55621 14.1108 3.55621ZM6.33301 2.4451H9.66634V3.55621H6.33301V2.4451Z" fill="#E60012" />
                                                    </svg>
                                                </div>
                                            </button>
                                        </div> : <div className={"w-upload-file-field w-frame wbaseItem-value w-row 6ac84289-acb3-4274-819b-ee8371f76a61 " + (errors.filePath && 'helper-text')} helper-text={errors.filePath && 'Vui lòng tải tệp lên'} level={10} cateid={140}>
                                            <input
                                                {...register("filePath", { required: true })}
                                                type="file"
                                                name='filePath'
                                                style={{ width: 0, height: 0, position: "absolute" }}
                                                onChange={handleFileChange}
                                            // onChange={onBlurCheckEnabledEdit}
                                            />
                                            <button type='button' onClick={(ev) => {
                                                ev.preventDefault();
                                                ev.target.previousElementSibling.showPicker();
                                            }} className='w-button wbaseItem-value w-row 048f37f4-3754-433e-a810-b27ee519f8d2'>
                                                <div className="w-svg wbaseItem-value b77c8d2c-435b-4b57-bf0f-5c6c035ca28d" level={11} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7 8.83333L12 3L17 8.83333H12.8333V16.3333H11.1667V8.83333H7Z" fill="#28282999">
                                                        </path>
                                                        <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#28282999">
                                                        </path>
                                                    </svg>
                                                </div>
                                                <div className="w-text wbaseItem-value e6d7d81e-044b-4c01-a317-0ea7a0e746a1" level={11} cateid={139}>Tải ảnh lên</div>
                                            </button>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 5dd7e22c-dea6-4e12-b61c-56fa7ed57b13" wrap="wrap" level={9} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- note-form-container" wrap="nowrap" level={10} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row 46659001-49de-4e3a-b256-961540ab2cf5" wrap="nowrap" level={11} cateid={140}>
                                    <div className="w-text wbaseItem-value f34de7ec-746a-4682-a8d8-2cedf0e92f82" level={12} cateid={139}>Mô tả</div>
                                </div>
                                <textarea {...register("description")}
                                    className="note-form"
                                    rows={3}
                                    value={data?.description}
                                    name='description'
                                    placeholder="Nhập văn bản..."
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                !id ? null : <div className="w-frame wbaseItem-value w-col 9eb18653-3703-4f95-a85b-74d4ca8ece6d" wrap="nowrap" level={5} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row 46ce79f0-67dc-4f8c-9723-04842d3a41d6" wrap="nowrap" level={6} cateid={140}>
                        <div className="w-text wbaseItem-value a6e5f17a-31da-467c-8796-0db6cf452e2f" level={7} cateid={139}>
                            Lịch sử</div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row 1e7979de-8c4b-44e4-8cd5-f8757b861613" wrap="wrap" level={6} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl 50140cd2-149a-4ead-b0fb-d3f80bfce450" wrap="nowrap" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 1d33454e-07c2-4b49-83dc-02c3bbfa5e90" level={8} cateid={139}>Người tạo:</div>
                            <div className="w-text wbaseItem-value 82d8c317-da95-41d2-bd17-e5a1a4651a9d" level={8} cateid={139}>{data?.losDocumentDetail?.createdBy}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 02200cc8-9be3-4ce3-9f17-28be0fced6f3" wrap="nowrap" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 8b1c558c-3d19-4994-be37-503a66f5996b" level={8} cateid={139}>Ngày tạo:</div>
                            <div className="w-text wbaseItem-value 422aca98-e87e-4690-9e53-3ac8648e7e41" level={8} cateid={139}>{new Date(data?.losDocumentDetail?.createdDate).toLocaleDateString('en-GB')}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl e7040e4d-5db5-44b3-a879-e37e73c8e9c9" wrap="nowrap" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value d692dff2-ab0c-4949-9fba-b6906bce6568" level={8} cateid={139}>Người cập nhật:</div>
                            <div className="w-text wbaseItem-value 45defc28-b4bd-4186-bc28-a57e350ffd3f" level={8} cateid={139}>{data?.losDocumentDetail?.modifiedBy}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl ad18ff4d-59d6-4ea4-ba2b-2721f6e549c4" wrap="nowrap" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 907931d7-c4e2-41d0-8daf-1ddf0538948a" level={8} cateid={139}>Ngày cập nhật:</div>
                            <div className="w-text wbaseItem-value dbb81084-bbd1-4d6f-b581-9e59651fb842" level={8} cateid={139}>{new Date(data?.losDocumentDetail?.modifiedDate).toLocaleDateString('en-GB')}</div>
                        </div>
                    </div>
                </div>
            }


            <div className="w-frame wbaseItem-value w-row a5650dcc-7a8c-43b6-a92e-13b1c4b2fc68" wrap="nowrap" level={3}
                cateid={140}>
                <button onClick={() => onCancel(false)} type="reset" className="w-button wbaseItem-value w-row 0827b65b-f8dd-422d-9526-661a04e2e39b"
                    wrap="nowrap" name-field="Button" level={4} cateid={29}>
                    <div className="w-text wbaseItem-value ed946f59-a67d-4391-8bd9-75ff48cd1b16" name-field="Text" level={5}
                        cateid={139}>Hủy</div>
                </button>
                <button type="submit" className="w-button wbaseItem-value w-row 99617da8-7d91-4e4d-b33b-f34e0eadac50"
                    wrap="nowrap" name-field="Button" level={4} cateid={29}>
                    <div className="w-svg wbaseItem-value 4565f46e-45f7-418c-8968-a7510d4ee3bb" level={5} cateid={115}>
                        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M14.4893 3.5115C14.726 3.74818 14.726 4.13191 14.4893 4.36859L6.61053 12.2474C6.37385 12.4841 5.99011 12.4841 5.75343 12.2474L1.51101 8.00496C1.27433 7.76828 1.27433 7.38454 1.51101 7.14786C1.74769 6.91118 2.13142 6.91118 2.36811 7.14786L6.18198 10.9617L13.6322 3.5115C13.8689 3.27481 14.2526 3.27481 14.4893 3.5115Z"
                                fill="#FFFFFFFF">
                            </path>
                        </svg>
                    </div>
                    <div className="w-text wbaseItem-value bb1d2d55-76a9-4b06-b469-422a88dd411e" name-field="Text" level={5}
                        cateid={139}>{id ? 'Lưu' : 'Thêm mới'}</div>
                </button>
            </div>
        </form>
    )
}