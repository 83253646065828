import Select2 from '../../components/select2/Select2';
import { useEffect, useState, useRef } from 'react';
import NhanhieuDA from '../../redux/middleware/api/NhanHieu/NhanhieuDA';
import NotificationAlert from "react-notification-alert";
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import $ from 'jquery';
import { PopupType, WPopup } from '../../components/Popup/Popup';
import Pagination from '../../components/Pagination/Pagination';

export default function Manufacture({ lst, callback_edit, delete_callback }) {
    const [isAddAction, setisAddAction] = useState(false);
    const [confirmDel, setconfirmDel] = useState();
    const [indexDel, setindexDel] = useState(false);
    const notificationAlert = useRef();
    const [ChildItemPage, setChildItemPage] = useState([]);
    const [pageDetails, setPageDetails] = useState({
        current: 1,
        size: 10
    });
    useEffect(() => {
        setChildItemPage(lst)
    }, []);
    const AddNewManu = () => {
        var item = {
            description: null,
            name: null,
            status: 1,
            isNew: true
        };
        setChildItemPage([item, ...ChildItemPage])
        setisAddAction(true)
    }
    const SaveNewManu = async () => {
        debugger;
        var item = ChildItemPage.find((a) => a.isNew === true);
        var check = false;

        if (!item.name) {
            $('.name_0').addClass('helper-text')
            check = true;

        } else if (!item.name.trim()) {
            $('.name_0').addClass('helper-text')
            check = true;
        }

        // if (!item.description.trim()) {
        //     $('.description_0').addClass('helper-text')
        //     check = true;

        // }
        if (!item.status) {
            $('.status_0').addClass('helper-text')
            check = true;

        }
        if (check)
            return;

        const res = await NhanhieuDA.AddManuFacture(item);
        if (res.code == '200') {
            notify(notificationAlert, SUCCESS, 'Thêm mới nhãn hiệu thành công');
            setChildItemPage((ChildItemPage) =>
                ChildItemPage.map((item) =>
                    item.id === ChildItemPage[0].id ? { ...item, isNew: false } : item
                ));
            setisAddAction(false)
            // add_callback()
        } else {
            notify(notificationAlert, ERROR, res.message);
        }
    }
    const onchangeFieldList = (event) => {
        const index = parseInt(event.target.getAttribute('data-index'));
        if (event.target.value) {
            $('.' + event.target.name + '_' + index).removeClass('helper-text')
        }
        debugger;
        setChildItemPage((ChildItemPage) =>
            ChildItemPage.map((item, a) =>
                a === index ? { ...item, [event.target.name]: event.target.value } : item
            ));

    }
    const onDeleteItem = (index) => {
        const updatedItems = [...ChildItemPage];
        updatedItems.splice(index, 1);
        setChildItemPage(updatedItems);
    }
    const onDeleteItemReal = async () => {
        if (indexDel >= 0) {
            var res = await NhanhieuDA.DelBranch(ChildItemPage[indexDel].id);
            if (res.code == '200') {
                const updatedItems = [...ChildItemPage];
                updatedItems.splice(indexDel, 1);
                setChildItemPage(updatedItems);
                setindexDel(null)
                delete_callback(ChildItemPage[indexDel].id);
            } else {
                setindexDel(null)
                notify(notificationAlert, SUCCESS, res.message)
            }
        }
    }
    const onFilter = (type) => {
        if (type == 0) {
            setChildItemPage(lst);
            setPageDetails({current: 1, size: pageDetails.size})
        }
        if (type == 1) {
            var updateItem = [...lst];
            setChildItemPage(updateItem.filter((a) => a.status == 1));
            setPageDetails({current: 1, size: pageDetails.size})
        }
        if (type == 2) {
            var updateItem = [...lst];
            setChildItemPage(updateItem.filter((a) => a.status == 0));
            setPageDetails({current: 1, size: pageDetails.size})
        }
    }
    const onEditItem = (index) => {
        // setChildItemPage((ChildItemPage) =>
        //   ChildItemPage.map((item) =>
        //     item.id === ChildItemPage[index].id ? { ...item, isNew: true } : item
        //   ));

        callback_edit(ChildItemPage[index].id)
    }
    return (<div class="w-frame wbaseItem-value w-col 80553e67-fe2e-44f3-95da-17ed4c8fcb65"
        level="6"
        cateid="140">
        <div class="w-frame wbaseItem-value w-col b94dd398-5cb0-4e26-8bb1-58e97f17132b" level="7" cateid="140" >
            <NotificationAlert ref={notificationAlert} />
            {
                confirmDel == false &&
                <WPopup
                    type={PopupType.WARNING}
                    title={'Xóa nhà sản xuất'}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => {
                        setconfirmDel(true)
                        onDeleteItemReal()
                    }}
                    cancelAction={() => {
                        setconfirmDel(null)
                        setindexDel(null)
                    }}
                    cancelButton={true}
                    cancelTitle='Giữ lại'
                    message={'Bạn có chắc chắn muốn xóa ?'}
                />
            }
            <div class="w-frame wbaseItem-value w-row d32aac52-a0d0-4106-aa12-7b714ee302c8" level="8" cateid="140" >
                <div class="w-text wbaseItem-value e7f6592a-4ce6-41f3-81b3-300d0fb07879" level="9" cateid="139" >
                    Danh sách nhà sản xuất
                </div>
                <div
                    // to={location.pathname + "/them-moi-nhan-hieu"}
                    class="w-frame wbaseItem-value w-row 903c08ab-ca15-42ec-804e-2557006d81af"
                    level="5"
                    cateid="140"
                >
                    {
                        isAddAction == true ? <button onClick={SaveNewManu}
                            type="button"
                            class="w-button wbaseItem-value w-row f3d11385-ddb3-426c-809e-08ba121fca53 event-click"
                            level="6"
                            cateid="29"
                        >
                            <div class="w-svg wbaseItem-value 9b6e898d-172c-4de6-89c7-52a0894c0579" level="7" cateid="115" >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <path d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z" fill="#009944FF" ></path>
                                </svg>
                            </div>
                            <div class="w-text wbaseItem-value 79f48a58-d0f8-4eca-b935-92fb607808d7" level="7" cateid="139" >Lưu</div>
                        </button> : <button onClick={AddNewManu}
                            type="button"
                            class="w-button wbaseItem-value w-row f3d11385-ddb3-426c-809e-08ba121fca53 event-click"
                            level="6"
                            cateid="29"
                        >
                            <div
                                class="w-svg wbaseItem-value 9b6e898d-172c-4de6-89c7-52a0894c0579"
                                level="7"
                                cateid="115"
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                        fill="#009944FF"
                                    ></path>
                                </svg>
                            </div>
                            <div
                                class="w-text wbaseItem-value 79f48a58-d0f8-4eca-b935-92fb607808d7"

                                level="7"
                                cateid="139"
                            >
                                Thêm
                            </div>
                        </button>
                    }

                </div>
            </div>
            <div class="w-frame wbaseItem-value w-row 0caa55f2-fb82-47f0-9361-b88707b6ddf3" level="8" cateid="140" >
                <div class="w-frame wbaseItem-value w-row 878901d6-622d-4b0c-885f-f839600300d0" level="9" cateid="140" >
                    <label class="w-radio-btn wbaseItem-value bda3a5f8-0f44-4287-9ac0-a07a3697468b" level="10" cateid="90" onClick={() => onFilter(0)} >
                        <input type="radio" name='rdSort' value="" />
                        <span class="checkmark" ></span>
                    </label>
                    <div class="w-text wbaseItem-value c1c5deef-c4d7-43cc-a2f9-20036910ac9a" level="10" cateid="139" >
                        Tất cả
                    </div>
                </div>
                <div class="w-frame wbaseItem-value w-row d3716edb-bb58-45fa-b70b-35a0fc554154" level="9" cateid="140" >
                    <label class="w-radio-btn wbaseItem-value 002774ac-cb06-489f-a920-2f88069c857f" level="10" cateid="90" onClick={() => onFilter(1)} >
                        <input type="radio" name='rdSort' value="1" />
                        <span class="checkmark" ></span>
                    </label>
                    <div class="w-text wbaseItem-value b166a1ab-fc54-4aef-9489-321248c11b52" level="10" cateid="139">
                        Hoạt động
                    </div>
                </div>
                <div class="w-frame wbaseItem-value w-row 3c0bc11d-53e8-4170-812b-c9df4222ff8f" level="9" cateid="140" >
                    <label class="w-radio-btn wbaseItem-value 1158e130-c52e-4706-8b88-e93139a2407c" level="10" cateid="90" onClick={() => onFilter(2)} >
                        <input type="radio" name='rdSort' value="0" />
                        <span class="checkmark" ></span>
                    </label>
                    <div class="w-text wbaseItem-value 7ee4aa61-aaab-4044-ab6a-60b56ec234de" level="10" cateid="139" >
                        Ngừng hoạt động
                    </div>
                </div>

            </div>
            <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>
                    <table class="w-table wbaseItem-value d2b8a059-d62c-4466-916f-f12bcb06bc4c" type="0" level="8" cateid="117" >
                        <tr class="table-row">
                            <td id="1x1" class="table-cell" rowspan="1" colspan="1">
                                <div class="w-frame wbaseItem-value w-row e5db31c1-7584-41d2-a8ca-deb56f411a1b" level="9" cateid="140" >
                                    <div class="w-text wbaseItem-value dd767313-04d7-4179-a345-f2a4ec786ce8" level="10" cateid="139" >STT</div>
                                </div>
                            </td>
                            <td id="1x2" class="table-cell" rowspan="1" colspan="1">
                                <div class="w-frame wbaseItem-value w-row bd60fbc3-ca7a-4ce2-a3a6-05532ee0c0f3" level="9" cateid="140" >
                                    <div class="w-text wbaseItem-value cca16fc2-84e3-47cf-8a73-bc94ef652cef" level="10" cateid="139" >Nhà sản xuất</div>
                                </div>
                            </td>
                            <td id="1x3" class="table-cell" rowspan="1" colspan="1">
                                <div class="w-frame wbaseItem-value w-row 9000af92-9afb-4b89-88d6-a036234d5a36" level="9" cateid="140" >
                                    <div class="w-text wbaseItem-value 7d4f0726-b628-40ef-8ac4-4899491046c9" level="10" cateid="139" >Mô tả</div>
                                </div>
                            </td>
                            <td id="1x4" class="table-cell" rowspan="1" colspan="1">
                                <div class="w-frame wbaseItem-value w-row c6af784a-6a66-4aa8-ba18-0731ab3675dd" level="9" cateid="140" >
                                    <div class="w-text wbaseItem-value ca23a0a4-31c0-4769-a5f9-725c0a440b0e" level="10" cateid="139" >Trạng thái</div>
                                </div>
                            </td>
                            <td id="1x5" class="table-cell" rowspan="1" colspan="1">
                                <div class="w-frame wbaseItem-value w-row e5db31c1-7584-41d2-a8ca-deb56f411a1b" level="9" cateid="140" >
                                    <div class="w-text wbaseItem-value dd767313-04d7-4179-a345-f2a4ec786ce8" level="10" cateid="139" >
                                    </div>
                                </div>
                            </td>
                        </tr>

                        {ChildItemPage?.length <= 0 ?
                            <tr>
                                {/* <td></td> */}
                                <td colSpan={7}>Không có dữ liệu</td>
                            </tr>
                            :
                            ChildItemPage.slice(pageDetails.size * (pageDetails.current - 1), pageDetails.size * (pageDetails.current - 1) + pageDetails.size).map((item, index) => {
                                return item.isNew == true ?
                                    <tr key={index} className="table-row">
                                        <td id="2x1" class="table-cell" rowspan="1" colspan="1">
                                            <div
                                                class="w-frame wbaseItem-value w-row 77c7da4c-6406-425c-8034-11624da28a3f"
                                                level="9"
                                                cateid="140"
                                            >
                                                <div
                                                    class="w-text wbaseItem-value e7a02330-140c-4d38-9a24-9fea2c0a1bb6"
                                                    level="10"
                                                    cateid="139"
                                                >
                                                    {pageDetails.size * (pageDetails.current - 1) + index + 1}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 1339efd5-c346-41c5-b3cb-d68cb8e3c5a6" level={7} cateid={140}>
                                                <div className={"w-textformfield wbaseItem-value w-row 13f1607e-350d-4c48-9fe4-c4a02a76c600 name_" + index} name-field="LimitSuggest" level={8} cateid={85} placeholder="Nhập nhà sản xuất" >
                                                    <div className="wbaseItem-value 85355487-8751-4020-bec8-77905a8c2ff9" name-field="Textfield" level={9} cateid={86}>
                                                        <div className="textfield">
                                                            <input name="name" autoComplete='off' maxLength={50} data-index={index} onChange={onchangeFieldList} value={item.name} placeholder="Nhập nhà sản xuất" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 807b9ae3-2b78-4fcd-9d68-893c1431822b" level={7} cateid={140}>
                                                <div className={"w-textformfield wbaseItem-value w-row cd078f37-eefd-458a-a035-dba84a46b5cf description_" + index} name-field="LimitApproval" level={8} cateid={85} placeholder="Nhập mô tả" >
                                                    <div className="wbaseItem-value 1ce2e65d-ec91-4023-8066-0984b16b6a2a" name-field="Textfield" level={9} cateid={86}>
                                                        <div className="textfield">
                                                            <input name="description" autoComplete='off' maxLength={255} data-index={index} onChange={onchangeFieldList} value={item.description} placeholder="Nhập mô tả" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row c7a93308-b15b-461a-acc7-3ce7383b3e88" level={7} cateid={140}>
                                                <div className={`select2-custom status_${index}`}>
                                                    <Select2 onChange={onchangeFieldList} name="status" data={[{ id: 1, name: 'Hoạt động' }, { id: 0, name: 'Ngừng hoạt động' }]} value={item.status} data-index={index} options={{ placeholder: 'Chọn trạng thái' }} />
                                                </div>

                                            </div>
                                        </td>
                                        <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row f3f00355-a123-41d8-82d7-0f0b8118a648" level={7} cateid={140}>
                                                <div onClick={() => onDeleteItem(index)}
                                                    className="w-svg wbaseItem-value 3d5d2356-bf4e-4d4c-a639-5ca33740a4f5" level={11} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="Grey">
                                                        </path>
                                                        <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="Grey">
                                                        </path>
                                                    </svg>
                                                </div>

                                            </div>

                                        </td>
                                    </tr>
                                    : <tr class="table-row" key={index}>
                                        <td id="2x1" class="table-cell" rowspan="1" colspan="1">
                                            <div class="w-frame wbaseItem-value w-row 77c7da4c-6406-425c-8034-11624da28a3f" level="9" cateid="140" >
                                                <div class="w-text wbaseItem-value e7a02330-140c-4d38-9a24-9fea2c0a1bb6" level="10" cateid="139" >
                                                    {pageDetails.size * (pageDetails.current - 1) + index + 1}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="2x2" class="table-cell" rowspan="1" colspan="1">
                                            <div class="w-frame wbaseItem-value w-row 5383b335-ed7a-42a0-99e9-44db5855d374" level="9" cateid="140">
                                                <div class="w-text wbaseItem-value 982d16fe-8b8c-4f3d-b477-10be924d4fad" level="10" cateid="139" >{item.name}</div>
                                            </div>
                                        </td>
                                        <td id="2x3" class="table-cell" rowspan="1" colspan="1">
                                            <div class="w-frame wbaseItem-value w-row ea0afdd7-fdd1-4e11-a9c7-7b1637a5b314" level="9" cateid="140" >
                                                <div class="w-text wbaseItem-value d0775806-ebcb-42ea-a3de-8856c35d8f9e" level="10" cateid="139" >{item.description}</div>
                                            </div>
                                        </td>
                                        <td id="2x4" class="table-cell" rowspan="1" colspan="1">
                                            <div class="w-frame wbaseItem-value w-row ea0afdd7-fdd1-4e11-a9c7-7b1637a5b314" level="9" cateid="140" >
                                                <div class="w-text wbaseItem-value d0775806-ebcb-42ea-a3de-8856c35d8f9e" level="10" cateid="139" >
                                                    {item.status == 1 ? 'Hoạt động' : 'Ngừng hoạt động'}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="2x5" class="table-cell" rowspan="1" colspan="1">
                                            <div class="w-frame wbaseItem-value w-row addc6f63-a0ca-4725-a155-7a19fdadf3b9" level="9" cateid="140" >
                                                <div onClick={() => {
                                                    setindexDel(index)
                                                    setconfirmDel(false)
                                                }}
                                                    className="w-svg wbaseItem-value 3d5d2356-bf4e-4d4c-a639-5ca33740a4f5" level={11} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="Grey">
                                                        </path>
                                                        <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="Grey">
                                                        </path>
                                                    </svg>
                                                </div>
                                                <div
                                                    onClick={() => onEditItem(index)}
                                                    className="w-svg wbaseItem-value de717542-9eee-4ae6-81ce-952f97cfb2a3" level={8} cateid={115}>
                                                    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="Icon/filled/edit/edit">
                                                            <g id="Vector">
                                                                <path d="M13.507 14.6663H1.91272C1.75897 14.6663 1.61152 14.6053 1.5028 14.4965C1.39408 14.3878 1.33301 14.2404 1.33301 14.0866C1.33301 13.9329 1.39408 13.7854 1.5028 13.6767C1.61152 13.568 1.75897 13.5069 1.91272 13.5069H13.507C13.6608 13.5069 13.8082 13.568 13.917 13.6767C14.0257 13.7854 14.0868 13.9329 14.0868 14.0866C14.0868 14.2404 14.0257 14.3878 13.917 14.4965C13.8082 14.6053 13.6608 14.6663 13.507 14.6663Z" fill="#282829" fillOpacity="0.4" style={{ mixBlendMode: 'multiply' }} />
                                                                <path d="M8.04959 3.65175L3.24201 8.45933C3.16768 8.53369 3.11498 8.62688 3.08955 8.7289L1.91272 12.3475L5.53131 11.1707C5.63333 11.1452 5.72651 11.0925 5.80088 11.0182L10.6085 6.21061L8.04959 3.65175Z" fill="#282829" fillOpacity="0.4" style={{ mixBlendMode: 'multiply' }} />
                                                                <path d="M12.7575 3.24189L11.0183 1.50274C10.9096 1.39406 10.7622 1.33301 10.6085 1.33301C10.4547 1.33301 10.3073 1.39406 10.1986 1.50274L8.86931 2.83203L11.4282 5.39089L12.7575 4.0616C12.8661 3.95289 12.9272 3.80547 12.9272 3.65175C12.9272 3.49803 12.8661 3.3506 12.7575 3.24189Z" fill="#282829" fillOpacity="0.4" style={{ mixBlendMode: 'multiply' }} />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                            })}

                    </table>
                </div>
            </div>
            <Pagination
                currentPage={pageDetails.current}
                itemPerPage={pageDetails.size}
                totalItem={ChildItemPage.length}
                onChangePage={(page, size) => {
                    if (pageDetails.current !== page || pageDetails.size !== size) {
                        setPageDetails({ current: page, size: size });
                    }
                }}
            />
        </div></div >)
}