import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getSignPendingContractInfor, getTaskDetail } from '../../../redux/middleware/api/congvieccuatoi/CongViecDA';
import { Ultis } from '../../../core/utils';
import './KyHopDong.css';
import { PopupType, WPopup } from '../../../components/Popup/Popup';
import PopupXemQuyTrinh from '../ThamDinh-XuLy/PopupXemQuyTrinh';
import PopupHoSoBanDau from '../ThamDinh-XuLy/PopupHoSoBanDau';
import LayoutCongViec, { ThongTinKhoiTao } from '../CommonView';
import ContractDA from '../../../redux/middleware/api/HopDong/ContractDA';

export default function KyHopDong() {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState();
    const [settingData, setSettingData] = useState({});
    const [contractData, setContractData] = useState({});

    const [showProcess, onShowProcess] = useState(false);
    const [showHoSoBanDau, setShowHoSoBanDau] = useState(false);
    const [thoatPage, setShowThoatPage] = useState(false);

    const [tienBaoHiem, setTienBaoHiem] = useState(0);

    const [srcHopDong, setSrcHopDong] = useState();

    const subId = location.search.replace("?", "");
    const getContractInfo = async () => {
        let res = await getTaskDetail({ id: subId });
        if (res) {
            let resSignInfor = await getSignPendingContractInfor({ custId: res['process-instance-variables'].loanApplication.idKhach });
            setData(res);
            setSettingData(res['process-instance-variables']);
            if (resSignInfor) {
                setContractData(resSignInfor.data.content[0])
            }
            if (res['process-instance-variables'].loanApplication.loanApplicationInsurance?.length > 0) {
                res['process-instance-variables'].loanApplication.loanApplicationInsurance.map((e) => {
                    let _tong = 0;
                    if (e?.maChuKyBaoHiem === "TTBH1") { //bao hiem gop vao khoang vay
                        _tong += e.soTienBaoHiem;
                    }
                    setTienBaoHiem(_tong);
                    return 0;
                })
            }

            let hopDong = await ContractDA.layHopDong(res['process-instance-variables'].loanApplication.id);
            if (hopDong) {
                setSrcHopDong(hopDong.iframe);
            }
        }
    }

    useEffect(() => {
        getContractInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        data != null ?
            <div className="w-frame wbaseItem-value w-col bea7db40-0541-4207-a4b7-9133b285a161" level={2} cateid={140}>
                {
                    thoatPage &&
                    <WPopup
                        type={PopupType.WARNING}
                        title={"Hủy bổ sung hồ sơ"}
                        message="Các thông tin đã nhập sẽ không được lưu lại"
                        cancelTitle="Tiếp tục nhập"
                        confirmTitle='Xác nhận'
                        cancelAction={() => setShowThoatPage(false)}
                        confirmAction={() => { navigate('/admin/ho-so-cho-xu-ly') }}
                        cancelButton={true}
                    />
                }
                {
                    showProcess &&
                    <WPopup
                        popupContent={
                            <PopupXemQuyTrinh
                                closePopup={() => onShowProcess(false)}
                                code={subId}
                                loanApplicationId={settingData.loanApplication.id}
                            />
                        }
                    />
                }
                {
                    showHoSoBanDau &&
                    <WPopup
                        popupContent={
                            <PopupHoSoBanDau
                                data={settingData}
                                closePopup={() => setShowHoSoBanDau(false)}
                            />
                        }
                        cancelAction={() => setShowHoSoBanDau(false)}
                    />
                }

                <div className="w-frame wbaseItem-value w-col b836fd62-9546-4285-a2eb-01f29a9122e1" level={3} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row 1533163d-14ee-4ddf-8a24-f8328ddf93a1" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col 87b70f5f-3e00-4962-8ca0-ccc0274167e9" level={5} cateid={140}>
                            <div className="w-frame wbaseItem-value w-row 976e0283-99d8-4517-bc77-c0d63c6a3a77" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 31012ac4-f20e-45c1-95d9-5a08e13103cd" level={7} cateid={139}>Ký hợp đồng</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row breadcrumb 775ccabd-a305-41f6-b92f-4541da049488" level={6} cateid={140}>
                                <button type="button" className="w-button wbaseItem-value w-row d542f719-e823-4fc1-a711-5ecaa1bf0662" level={7} cateid={29}>
                                    <div className="w-text wbaseItem-value 9ee915f4-bd1a-41ee-a56e-d40669675064" level={8} cateid={139}>Hồ sơ chờ xử lý</div>
                                    <div className="w-svg wbaseItem-value 81814b40-a93b-45d8-89c9-be5dfedd6a1d" level={8} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6" />
                                        </svg>
                                    </div>
                                </button>
                                <div className="w-text wbaseItem-value aa0691a7-04b5-48c6-9f9c-f66d76037d5f" level={7} cateid={139}>{settingData.idHoSo}</div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row fd65d896-189f-497f-85a3-6968c0294592" level={5} cateid={140}>
                            <button onClick={() => setShowHoSoBanDau(true)} type="button" className="w-button wbaseItem-value w-row f847d0a5-6835-44dd-9c2c-32314d09cacf" level={6} cateid={29}>
                                <div className="w-svg wbaseItem-value d1c0339c-1877-41d1-918e-5b3a65106ad1" level={7} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.4167 6.33333L9.91667 3H2.83333C2.61232 3 2.40036 3.0878 2.24408 3.24408C2.0878 3.40036 2 3.61232 2 3.83333V18.8333C2 19.4964 2.26339 20.1323 2.73223 20.6011C3.20107 21.0699 3.83696 21.3333 4.5 21.3333H19.5C20.163 21.3333 20.7989 21.0699 21.2678 20.6011C21.7366 20.1323 22 19.4964 22 18.8333V7.16667C22 6.94565 21.9122 6.73369 21.7559 6.57741C21.5996 6.42113 21.3877 6.33333 21.1667 6.33333H12.4167Z" fill="#28282999" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value eadfa460-20e8-4468-9c61-0cc0639ef0b1" level={7} cateid={139}>Xem hồ sơ ban đầu</div>
                            </button>
                            <button onClick={() => onShowProcess(true)} type="button" className="w-button wbaseItem-value w-row 85c9c5bf-a0f3-48d9-a2ba-1c1edc1e27b2" level={6} cateid={29}>
                                <div className="w-svg wbaseItem-value cf2c51cc-9b2d-4b79-97ae-51f52747fc9c" level={7} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 5.33333V7H5.6V12H8V13.6667H5.6V18.6667H8V20.3333H4.8C4.3584 20.3333 4 19.96 4 19.5V2.83333C4 2.37333 4.3584 2 4.8 2C5.2416 2 5.6 2.37333 5.6 2.83333V5.33333H8Z" fill="#009944FF" />
                                        <path d="M10.3996 8.66699H19.1996C19.6412 8.66699 19.9996 8.29366 19.9996 7.83366V4.50033C19.9996 4.04033 19.6412 3.66699 19.1996 3.66699H10.3996C9.95801 3.66699 9.59961 4.04033 9.59961 4.50033V7.83366C9.59961 8.29366 9.95801 8.66699 10.3996 8.66699Z" fill="#009944FF" />
                                        <path d="M19.1996 22H10.3996C9.95801 22 9.59961 21.6275 9.59961 21.1667V17.8333C9.59961 17.3725 9.95801 17 10.3996 17H19.1996C19.6412 17 19.9996 17.3725 19.9996 17.8333V21.1667C19.9996 21.6275 19.6412 22 19.1996 22Z" fill="#009944FF" />
                                        <path d="M10.3996 15.333H19.1996C19.6412 15.333 19.9996 14.9605 19.9996 14.4997V11.1663C19.9996 10.7063 19.6412 10.333 19.1996 10.333H10.3996C9.95801 10.333 9.59961 10.7063 9.59961 11.1663V14.4997C9.59961 14.9605 9.95801 15.333 10.3996 15.333Z" fill="#009944FF" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value dfe74552-28c9-4b0f-aa88-fb11eb5ba0a9" level={7} cateid={139}>Xem quy trình</div>
                            </button>
                        </div>
                    </div>
                    {
                        <ThongTinKhoiTao loanId={settingData.loanApplication.id} />
                    }
                    <div className="w-frame wbaseItem-value w-row b472496b-ebd6-466f-84c9-5abc03667eeb" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col b344685b-7aab-4fec-a5a9-e5519c6f4648" scroll="true" level={5} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col 622decb3-9d70-488c-b64a-7dd1b41e78ff" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col 39e8acc0-16a0-4bcb-af99-cc83d63cf5eb" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 1bdef860-6e3e-4b65-bea9-58b8d7209d9d" level={8} cateid={139}>Thông tin hợp đồng</div>
                                    <div className="w-rect wbaseItem-value 1ab83fac-0870-4cab-9a56-68e0c00ff700" level={8} cateid={138} />
                                </div>
                                <div className="w-frame wbaseItem-value w-col db73581f-171b-490b-9f2e-bb5fca26dad7" level={7} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 0fbc361f-28a3-4054-8abf-6f4b343ab067" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 0961b8be-aa23-4ed7-88bf-b9bd74323e15" level={9} cateid={139}>Số hợp đồng:</div>
                                        <div className="w-text wbaseItem-value 2c8a0c43-7c55-4184-8c20-d38ace9e69dc" level={9} cateid={139}>{settingData.loanApplication.idHopDong ?? "_"}</div>
                                    </div>
                                    <div className="w-text wbaseItem-value 95f6832d-0765-47e8-9a65-bb3e76d5af9d" level={8} cateid={139}>Thông tin khách hàng</div>
                                    <div className="w-frame wbaseItem-value w-row aa25cc9d-0a35-4a79-8c37-6dfe265cfc60" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value b72988f4-b368-4724-abfd-6e6ce2f92d3d" level={9} cateid={139}>Họ tên:</div>
                                        <div className="w-text wbaseItem-value 6a552fae-e414-4ea5-bc2f-1127947256f5" level={9} cateid={139}>{contractData.customerFullName}</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 31cc2776-4e5b-422f-b885-4a9a5bea8a05" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 16eee6d8-ead0-40ab-9f70-b7284a6d5d51" level={9} cateid={139}>Số CIF:</div>
                                        <div className="w-text wbaseItem-value d7be4439-12d4-4960-b37a-6fd40de6fa77" level={9} cateid={139}>{contractData.khachHang?.cmndHoChieu ?? "_"}</div>
                                    </div>
                                    <div className="w-text wbaseItem-value 7354f785-8ab1-46c9-b801-22bf34276865" level={8} cateid={139}>Nội dung khoản vay</div>
                                    <div className="w-frame wbaseItem-value w-row 64332b73-137e-4bf6-bf98-8504cfb5f3cf" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 1113f5f1-f7a9-4ee7-acda-f0d43db134a1" level={9} cateid={139}>Số CCCD:</div>
                                        <div className="w-text wbaseItem-value bb820852-a1a7-4992-8452-d874e3575dcb" level={9} cateid={139}>{contractData.khachHang?.cmndHoChieu ?? "_"}</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row b953e905-052f-47db-bf52-a1ba5af11390" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 8a14d6f5-b741-45aa-bc8d-a073bb7a08de" level={9} cateid={139}>Đại lý:</div>
                                        <div className="w-text wbaseItem-value bde26185-5c81-45d2-a602-ff0c95e442c4" level={9} cateid={139}>{settingData.loanApplication.daiLy ?? "_"}</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row a889fe63-b78d-4ebf-bf2c-116e32bed114" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 664b552b-b4d5-4969-ac77-98878d436528" level={9} cateid={139}>Kỳ hạn vay:</div>
                                        <div className="w-text wbaseItem-value f9b5213e-b923-4eb0-b30a-950ed8640583" level={9} cateid={139}>{settingData.loanApplication.kyHanVay ?? "_"} tháng</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row cae8ff8d-02c0-41c3-aae6-0b1d92fea735" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 0afd9d87-fe84-4cfb-94ee-318f5fbacbd5" level={9} cateid={139}>Tổng khoản vay:</div>
                                        <div className="w-text wbaseItem-value 37e437c1-4f58-4496-adf2-ed1ba4aad024" level={9} cateid={139}>{Ultis.money(settingData.loanApplication?.soTienVayGoc ?? 0 + settingData.loanApplication?.soTienBaoHiem ?? 0)} VND</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 6ae8c7d1-baa6-4e58-bb33-9ad167d452c0" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 0c3ffe92-440f-4589-a0eb-b20a482fa0f6" level={9} cateid={139}>Số tiền vay gốc:</div>
                                        <div className="w-text wbaseItem-value 74d06345-666c-4513-b895-57c50eee4b21" level={9} cateid={139}>{Ultis.money(settingData.loanApplication?.soTienVayGoc ?? 0)} VND</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row ef673cf7-815a-4a5c-acc4-a0479b6be05c" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 93169d0f-8927-4871-a141-9f6e040be1d9" level={9} cateid={139}>Tổng số tiền vay mua bảo hiểm:</div>
                                        <div className="w-text wbaseItem-value 8af220e1-e371-4cd8-8041-a966dfb6b80f" level={9} cateid={139}>{tienBaoHiem} VND</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 48e23919-04db-4fe5-9a3c-b4daeb1599d6" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 3cfe30b4-74a2-47c2-bbee-6c1f08242cc4" level={9} cateid={139}>Giá bán xe:</div>
                                        <div className="w-text wbaseItem-value 498a2340-867c-4a1c-a169-46eddaf7ee3c" level={9} cateid={139}>{settingData.loanApplication?.tongGiaBan ? Ultis.money(settingData.loanApplication?.tongGiaBan) : "_"} VND</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 863bdfc8-89db-4cf4-a96b-501f591122ce" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value e2042d5e-be86-41a4-9851-a118b7abbd54" level={9} cateid={139}>Ngày thanh toán hàng tháng:</div>
                                        <div className="w-text wbaseItem-value 364c650b-294d-49d6-ae64-5d2734f3a23e" level={9} cateid={139}>{settingData.loanApplication?.ngayThanhToanHangThang ?? "_"}</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 12667106-7c2c-494c-af58-e52285427bd7" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value dc9d43cd-ed5f-4aec-87a7-6ee55aa4df4a" level={9} cateid={139}>Số khung:</div>
                                        <div className="w-text wbaseItem-value 38e1eb4f-675a-48d6-8f61-0e5f6a8c1fad" level={9} cateid={139}>{settingData.loanApplication?.soKhung ?? "_"}</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 803279fd-b75c-446d-b5d7-8b438147df3d" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value b8501282-f355-49da-9442-ea4a22d343a8" level={9} cateid={139}>Số máy:</div>
                                        <div className="w-text wbaseItem-value c80e49c0-0367-4aeb-9392-b7715eb35330" level={9} cateid={139}>{settingData.loanApplication?.soMay ?? "_"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame w-stack wbaseItem-value 263a8d9f-ed2e-4b0b-8e8a-ed2e1cb2fe66" level={5} cateid={140} >
                            {
                                srcHopDong != null &&
                                <iframe
                                    title='contract view'
                                    src={srcHopDong}
                                    width={"100%"}
                                    height={"100%"}
                                >

                                </iframe>
                            }
                        </div>
                    </div>
                </div>
                <div className="w-frame wbaseItem-value w-row 9dc830f9-6f4e-4317-bf31-b1811f5719c4" level={3} cateid={140}>
                    <button onClick={() => setShowThoatPage(true)} className="w-button wbaseItem-value dcb46287-78e9-4ee9-9bd7-4c27f821d655" level={4} cateid={139}>Thoát</button>
                    <div className="w-frame wbaseItem-value w-row efc8297d-6805-4a38-9a2c-1f8e3efc7426" level={4} cateid={140}>
                        <button onClick={() => { window.open(srcHopDong) }} type="button" className="w-button wbaseItem-value w-row 79f4cf2d-8744-4521-9a79-d46b36cd0723" level={5} cateid={29}>
                            <div className="w-svg wbaseItem-value 63b343e7-8233-4c34-b021-388c86ec8a30" level={6} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.7338 5.90271L18.0973 2.26618C17.9268 2.09575 17.6956 2 17.4546 2C17.2135 2 16.9823 2.09575 16.8118 2.26618L14.2726 4.80538L19.1946 9.72742L21.7338 7.18822C21.9043 7.01773 22 6.78653 22 6.54546C22 6.30439 21.9043 6.07319 21.7338 5.90271Z" fill="#FFFFFFFF" />
                                    <path d="M12.9871 6.0909L4.08395 14.994C3.98432 15.0939 3.90931 15.2156 3.86485 15.3495L2.04658 20.8043C2.00109 20.9409 1.98868 21.0863 2.0104 21.2287C2.03211 21.371 2.08731 21.5061 2.17147 21.6229C2.25562 21.7398 2.36632 21.8349 2.49445 21.9006C2.62257 21.9663 2.76446 22.0006 2.90844 22.0007C3.00614 22.0007 3.10318 21.9847 3.19573 21.9534L8.65051 20.1352C8.7844 20.0907 8.9061 20.0157 9.00598 19.9161L17.9091 11.0129L12.9871 6.0909Z" fill="#FFFFFFFF" />
                                </svg>
                            </div>
                            <div className="w-text wbaseItem-value 0f39688d-92f5-4c36-9e60-297a5a03e1e5" level={6} cateid={139}>Ký hợp đồng</div>
                        </button>
                    </div>
                </div>
            </div> : <div></div>
    )
}