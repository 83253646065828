import axios from 'axios';
import { base_headers, file_headers } from "../config/ConfigAPI";

const ERROR_AUTHEN = "401";

export const postData = async (url, { obj }) => {
    try {
        const response = await axios({
            method: "POST",
            url: url,
            data: obj ?? {},
            // responseType: "json",
            headers: base_headers(),
        });
        if (response.status === 200) {
            if (response.data.code === ERROR_AUTHEN) {
                window.location = '/login';
            }
            return response.data;
        }
    } catch (error) {
        console.error('Failed to POST data:', error);
        throw error;
    }
};

export const postDataFile = async (url, { obj }) => {
    try {
        const response = await axios({
            method: "POST",
            url: url,
            data: obj ?? {},
            // responseType: "json",
            responseType: "blob",
            headers: base_headers(),
        });
        if (response.status === 200) {
            if (response.data.code === ERROR_AUTHEN) {
                window.location = '/login';
            }
            return response.data;
        }
    } catch (error) {
        console.error('Failed to POST data:', error);
        throw error;
    }
};

export const postFormDataFile = async (url, { obj }) => {
    try {
        const response = await axios({
            method: "POST",
            url: url,
            data: obj,
            // responseType: "blob",
            headers: file_headers(),
        });
        if (response.status === 200) {
            if (response.data.code === ERROR_AUTHEN) {
                window.location = '/login';
            }
            return response.data;
        }
    } catch (error) {
        console.error('Failed to POST data:', error);
        throw error;
    }
};
export const deleteFormDataFile = async (url, { obj }) => {
    try {
        const response = await axios({
            method: "DELETE",
            url: url,
            data: obj,
            // responseType: "blob",
            headers: base_headers('multipart/form-data'),
        });
        if (response.status === 200) {
            if (response.data.code === ERROR_AUTHEN) {
                window.location = '/login';
            }
            return response.data;
        }
    } catch (error) {
        console.error('Failed to POST data:', error);
        throw error;
    }
};
export const putFormDataFile = async (url, { obj }) => {
    try {
        const response = await axios({
            method: "PUT",
            url: url,
            data: obj,
            // responseType: "blob",
            headers: base_headers('multipart/form-data'),
        });
        if (response.status === 200) {
            if (response.data.code === ERROR_AUTHEN) {
                window.location = '/login';
            }
            return response.data;
        }
    } catch (error) {
        console.error('Failed to POST data:', error);
        throw error;
    }
};

export const putData = async (url, { obj }) => {
    try {
        const response = await axios({
            method: "put",
            url: url,
            data: obj,
            // responseType: "json",
            headers: base_headers(),
        });
        if (response.status === 401) {
            window.location = '/login';
        }
        if (response.status === 200 || response.status === 201) {
            if (response.data.code === ERROR_AUTHEN) {
                window.location = '/login';
            }
            return response.data;
        }
    } catch (error) {
        console.error('Failed to POST data:', error);
        throw error;
    }
};

export const putHoSoData = async (url, { obj }) => {
    try {
        debugger
        const response = await axios({
            method: "put",
            url: url,
            data: obj,
            // responseType: "json",
            headers: base_headers(),
        });
        if (response.status === 401) {
            window.location = '/login';
        }
        if (response.status === 200 || response.status === 201) {
            if (response.data.code === ERROR_AUTHEN) {
                window.location = '/login';
            }
            return response;
        }
    } catch (error) {
        console.error('Failed to POST data:', error);
        throw error;
    }
};
export const deleteData = async (url, { obj }) => {
    // var headers = { 'Content-type': 'application/json' };
    try {
        const response = await axios({
            method: "DELETE",
            url: url,
            data: obj,
            // responseType: "json",
            headers: base_headers(),
        });
        if (response.status === 200) {
            if (response.data.code === ERROR_AUTHEN) {
                window.location = '/login';
            }
            return response.data;
        }

    } catch (error) {
        console.error('Failed to DELETE data:', error);
        throw error;
    }
};

export const getData = async (url) => {
    // var headers = { 'Content-type': 'application/json' };
    try {
        console.log(url);
        const response = await axios({
            method: "GET",
            url: url,
            headers: base_headers(),
        });
        if (response.status === ERROR_AUTHEN) {
            window.location = '/login';
        }

        if (response.status === 200) {
            if (response.data.code === ERROR_AUTHEN) {
                window.location = '/login';
            }
            return response.data;
        }
        else {
            console.error('Failed to fetch data:', response.status);
            return null;
        }

    } catch (error) {
        console.error('Failed to fetch data:', error);
        throw error;
    }
};
export const getDataFile = async (url) => {
    // var headers = { 'Content-type': 'application/json' };
    try {
        console.log(url);
        const response = await axios({
            method: "GET",
            url: url,
            headers: base_headers(),
            responseType: "blob",
        });

        if (response.status === ERROR_AUTHEN) {
            window.location = '/login';
        }

        if (response.status === 201) {
            return response;
        }
        else if (response.status === 200) {
            return response;
        } else {
            console.error('Failed to fetch data:', response.status);
            return null;
        }

    } catch (error) {
        console.error('Failed to fetch data:', error);
        throw error;
    }
};

