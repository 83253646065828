import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import $ from "jquery";
import Menu from '../components/layout/Menu'
import Header from '../components/layout/Header'
import routes from '../router/router';
import { useEffect } from "react";
import setResponsivePage from "../core/handleData";



function JlosLayout() {
    const navigate = useNavigate();

    const checkToken = () => {
        const token = localStorage.getItem('token');
        return token !== null && token !== undefined;
    };

    const resizePage = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
            let framePage = entry.target;
            setResponsivePage(framePage);
        });
    });

    useEffect(() => {
        if (!checkToken()) {
            navigate('/login');
        } else {
            resizePage.observe(document.getElementById("f19dabae-13c1-4154-9139-a2217d2a61bc"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="w-frame wbaseItem-value w-row xxl w-page" level={1} cateid={140} id="f19dabae-13c1-4154-9139-a2217d2a61bc">
            <Menu />
            <div className="w-frame wbaseItem-value w-col" level={2} cateid={140} id="39d357d3-bcc1-4d4f-b887-4ad68c34cee3">
                <Header />
                <Routes>
                    {routes.map((prop, key) => {
                        return (
                            <Route
                                path={prop.path}
                                element={prop.component}
                                key={key}
                                exact
                            />
                        );
                    })}
                </Routes>
            </div>
        </div>
    )
}
export default JlosLayout;
