import { NavLink, useNavigate } from 'react-router-dom';
import './ChangePassword.css';
import React, { useState } from 'react';
import { handleChangePassword } from '../../../redux/middleware/api/Login/LoginDA';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function ChangePassword() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({ username: '', phoneNumber: '' });
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmitChangePassword = async (event) => {
        event.preventDefault();

        const usernameValue = formData.username;
        const phoneNumberValue = formData.password;

        if (usernameValue?.length > 0 && phoneNumberValue?.length > 0) {
            setLoading(true);
            let response = await handleChangePassword({ userName: usernameValue, phoneNumber: phoneNumberValue });
            if (response.code === 200) {
                setLoading(false);
                // localStorage.setItem("userItem", response);
                // localStorage.setItem("token", response.token);
                navigate("/");
            }

            // setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />

            <div className="w-frame wbaseItem-value w-row xxl d73d1936-bdf7-4c9a-ad31-2e5442ae59c0 w-page" wrap="nowrap" level={1} cateid={140}>
                <div className="w-svg wbaseItem-value cbd4c896-7c65-478f-8590-479ba3a1a38a" level={2} cateid={115} ></div>
                <form onSubmit={handleFormSubmitChangePassword} autoComplete='off' className="w-frame w-form wbaseItem-value w-col 21b58757-6255-43ad-807a-08d23596f8d8" wrap="nowrap" level={2} cateid={128}>
                    <div className="w-svg wbaseItem-value 10800fbc-f3a1-4b8b-bfce-c5a829278e44" level={3} cateid={115}>
                        <svg width={114} height={32} viewBox="0 0 114 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_8_19697)">
                                <path d="M46.8466 16.1337C46.8466 7.69857 53.6825 0.870117 62.127 0.870117H70.7725L67.9577 8.0333H62.194C57.7037 8.0333 54.0847 11.6484 54.0847 16.1337C54.0847 20.6191 57.7037 24.2341 62.194 24.2341H68.8289V31.4643H62.194C53.6825 31.3973 46.8466 24.5689 46.8466 16.1337Z" fill="#009944FF" />
                                <path d="M40.0777 24.2343L34.8501 11.113L26.9419 31.3975H19.1676L30.4939 3.01255C31.2311 1.27197 32.9066 0 34.8501 0C36.7937 0 38.4022 1.27197 39.1394 3.01255L47.8519 24.7699C49.1923 28.0502 46.5785 31.3975 43.6297 31.3975H30.3598L33.1747 24.1674H40.0777V24.2343Z" fill="#009944FF" />
                                <path d="M62.1279 20.7531C64.6818 20.7531 66.7522 18.685 66.7522 16.1339C66.7522 13.5828 64.6818 11.5146 62.1279 11.5146C59.5739 11.5146 57.5035 13.5828 57.5035 16.1339C57.5035 18.685 59.5739 20.7531 62.1279 20.7531Z" fill="#E60012FF" />
                                <path d="M67.9576 16.1337C67.9576 7.69857 74.7936 0.870117 83.2381 0.870117H91.8836L89.0687 8.0333H83.3051C78.8148 8.0333 75.1957 11.6484 75.1957 16.1337C75.1957 20.6191 78.8148 24.2341 83.3051 24.2341H89.94V31.4643H83.3051C74.7936 31.3973 67.9576 24.5689 67.9576 16.1337Z" fill="#009944FF" />
                                <path d="M78.6145 16.1339C78.6145 13.59 80.6921 11.5146 83.2388 11.5146C85.7856 11.5146 87.8632 13.59 87.8632 16.1339C87.8632 18.6778 85.7856 20.7531 83.2388 20.7531C80.6921 20.7531 78.6145 18.6778 78.6145 16.1339Z" fill="#E60012FF" />
                                <path d="M0 21.2887V20.3515H7.17108V21.2887C7.17108 23.2301 8.71252 24.7699 10.6561 24.7699C12.5996 24.7699 14.1411 23.2301 14.1411 21.2887C14.1411 20.1506 14.1411 0 14.1411 0H21.3122C21.3122 0 21.3122 20.3515 21.3122 21.2887C21.3122 27.1799 16.5538 32 10.5891 32C4.75838 32 0 27.1799 0 21.2887Z" fill="#009944FF" />
                                <path d="M91.6151 31.3978V24.2346H104.55C105.823 24.2346 106.829 23.2304 106.829 21.9584C106.829 20.6865 105.823 19.6823 104.55 19.6823H100.194C94.9661 19.6823 90.8109 15.4647 90.8109 10.3099C90.8109 5.08813 95.0331 0.9375 100.194 0.9375H112.793L109.844 8.03373H100.127C98.9203 8.03373 97.915 9.03792 97.915 10.2429C97.915 11.448 98.9203 12.4521 100.127 12.4521H104.483C109.71 12.4521 114 16.6697 114 21.9584C114 27.1802 109.777 31.4647 104.483 31.4647H91.6151V31.3978Z" fill="#009944FF" />
                            </g>
                            <defs>
                                <clipPath id="clip0_8_19697">
                                    <rect width={114} height={32} fill="#000000FF" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="w-frame wbaseItem-value w-col fffaf2b1-c04d-4386-8b71-c50a365b2b53" wrap="nowrap" level={3} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col 080be1bc-94b7-4103-9fb3-bea4e8e6246a" wrap="nowrap" name-field="Frame" level={4} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col d26d4ae8-1cbc-4f84-8d6b-946bcf0a188a" wrap="nowrap" name-field="Frame" level={5} cateid={140}>
                                <div className="w-text wbaseItem-value d4e33d62-b3ec-4229-8bf4-c2187237e308" name-field="Text" level={6} cateid={139}>Đổi mật khẩu</div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col 9e7f85d6-28c2-4ed3-b982-1e8cf78af628" wrap="nowrap" level={4} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col 455b13f4-49a1-4d39-b3b2-56ac23f627dd" wrap="nowrap" level={5} cateid={140}>
                                <div className="w-textformfield wbaseItem-value w-row 72e79b50-4a7c-4c18-a64b-a8480e759888" wrap="nowrap" name-field="OldPassword" level={6} cateid={85} placeholder="Nhập mật khẩu đã gửi đến số điện thoại của bạn">
                                    <div className="wbaseItem-value 5b759a0f-78db-48d6-83d5-6848e6926213" name-field="Textfield" level={7} cateid={86}>
                                        <div className="textfield">
                                            <input id="textfield-5b759a0f-78db-48d6-83d5-6848e6926213" name="OldPassword" placeholder="Nhập mật khẩu đã gửi đến số điện thoại của bạn" type="password" />
                                        </div>
                                        <div className="suffix-btn-txtfd eye-icon">
                                            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.535 10.5207C20.9132 9.64509 20.2186 8.82344 19.4587 8.0645L16.1085 11.4147C16.1408 11.6043 16.16 11.7958 16.166 11.988C16.166 13.0928 15.7271 14.1524 14.9458 14.9337C14.1645 15.715 13.1049 16.1539 12.0001 16.1539C11.8079 16.1479 11.6164 16.1287 11.4268 16.0964L8.71316 18.81C9.7535 19.25 10.8705 19.4799 12.0001 19.4866C16.7559 19.4866 20.2527 15.269 21.5358 13.4544C21.838 13.0249 22.0002 12.5125 22 11.9874C21.9999 11.4623 21.8374 10.95 21.535 10.5207Z" fill="#282829" fillOpacity="0.6" />
                                                <path d="M12.0001 4.48931C7.24426 4.48931 3.75156 8.70688 2.46429 10.5216C2.16215 10.9508 2 11.463 2 11.988C2 12.5129 2.16215 13.0251 2.46429 13.4544C3.56373 15.0216 4.90982 16.4003 6.45023 17.5369L9.0531 14.9341C8.6662 14.5472 8.35929 14.088 8.14989 13.5825C7.94048 13.0771 7.83268 12.5354 7.83264 11.9882C7.83257 10.8833 8.27143 9.8236 9.05268 9.04223C9.83393 8.26087 10.8936 7.82186 11.9985 7.82178C13.1035 7.82171 14.1632 8.26057 14.9445 9.04182L9.0531 14.9341L17.5524 6.43562C15.952 5.21981 14.0093 4.53883 12.0001 4.48931Z" fill="#282829" fillOpacity="0.6" />
                                                <path d="M2.83505 21.9861C2.67029 21.9861 2.50924 21.9372 2.37226 21.8457C2.23527 21.7541 2.12851 21.624 2.06546 21.4718C2.00242 21.3196 1.98592 21.1521 2.01805 20.9905C2.05018 20.8289 2.12951 20.6804 2.24599 20.5639L20.576 2.23388C20.7331 2.08211 20.9436 1.99813 21.1621 2.00003C21.3805 2.00193 21.5895 2.08955 21.744 2.24403C21.8985 2.39851 21.9861 2.60748 21.988 2.82594C21.9899 3.0444 21.9059 3.25486 21.7541 3.412L3.42411 21.742C3.2679 21.8983 3.05601 21.9861 2.83505 21.9861Z" fill="#282829" fillOpacity="0.6" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col cd8e9149-43ca-4dc4-b91b-368223f0dc8b" wrap="nowrap" level={6} cateid={140}>
                                    <div className="w-textformfield wbaseItem-value w-row 6583588d-5068-4096-89d9-113c5ace325b" wrap="nowrap" name-field="NewPassword" level={7} cateid={85} placeholder="Nhập mật khẩu mới">
                                        <div className="wbaseItem-value 4218e0df-2267-4da9-8102-717ae73734dd" name-field="Textfield" level={8} cateid={86}>
                                            <div className="textfield">
                                                <input
                                                    id="textfield-4218e0df-2267-4da9-8102-717ae73734dd"
                                                    name="NewPassword"
                                                    placeholder="Nhập mật khẩu mới"
                                                    type="password"
                                                    onChange={handleChange}
                                                    value={formData.username}
                                                />
                                            </div>
                                            <div className="suffix-btn-txtfd eye-icon">
                                                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.535 10.5207C20.9132 9.64509 20.2186 8.82344 19.4587 8.0645L16.1085 11.4147C16.1408 11.6043 16.16 11.7958 16.166 11.988C16.166 13.0928 15.7271 14.1524 14.9458 14.9337C14.1645 15.715 13.1049 16.1539 12.0001 16.1539C11.8079 16.1479 11.6164 16.1287 11.4268 16.0964L8.71316 18.81C9.7535 19.25 10.8705 19.4799 12.0001 19.4866C16.7559 19.4866 20.2527 15.269 21.5358 13.4544C21.838 13.0249 22.0002 12.5125 22 11.9874C21.9999 11.4623 21.8374 10.95 21.535 10.5207Z" fill="#282829" fillOpacity="0.6" />
                                                    <path d="M12.0001 4.48931C7.24426 4.48931 3.75156 8.70688 2.46429 10.5216C2.16215 10.9508 2 11.463 2 11.988C2 12.5129 2.16215 13.0251 2.46429 13.4544C3.56373 15.0216 4.90982 16.4003 6.45023 17.5369L9.0531 14.9341C8.6662 14.5472 8.35929 14.088 8.14989 13.5825C7.94048 13.0771 7.83268 12.5354 7.83264 11.9882C7.83257 10.8833 8.27143 9.8236 9.05268 9.04223C9.83393 8.26087 10.8936 7.82186 11.9985 7.82178C13.1035 7.82171 14.1632 8.26057 14.9445 9.04182L9.0531 14.9341L17.5524 6.43562C15.952 5.21981 14.0093 4.53883 12.0001 4.48931Z" fill="#282829" fillOpacity="0.6" />
                                                    <path d="M2.83505 21.9861C2.67029 21.9861 2.50924 21.9372 2.37226 21.8457C2.23527 21.7541 2.12851 21.624 2.06546 21.4718C2.00242 21.3196 1.98592 21.1521 2.01805 20.9905C2.05018 20.8289 2.12951 20.6804 2.24599 20.5639L20.576 2.23388C20.7331 2.08211 20.9436 1.99813 21.1621 2.00003C21.3805 2.00193 21.5895 2.08955 21.744 2.24403C21.8985 2.39851 21.9861 2.60748 21.988 2.82594C21.9899 3.0444 21.9059 3.25486 21.7541 3.412L3.42411 21.742C3.2679 21.8983 3.05601 21.9861 2.83505 21.9861Z" fill="#282829" fillOpacity="0.6" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-text wbaseItem-value 294fce77-b0f4-406f-956d-4ffd7fce5935" level={7} cateid={139}>- Có ít nhất 8 ký tự<br />- Bao gồm chữ, chữ số và ký tự đặt biệt<br />- Có ít nhất một chữ cái viết hoa</div>
                                </div>
                                <div className="w-textformfield wbaseItem-value w-row 3f04f00d-6e3b-420a-8c51-530fbbcff457" wrap="nowrap" name-field="ConfirmPass" level={6} cateid={85} placeholder="Nhập mật khẩu mới">
                                    <div className="wbaseItem-value 85487768-61b6-4da0-b163-c0a0a637fd70" name-field="Textfield" level={7} cateid={86}>
                                        <div className="textfield">
                                            <input
                                                id="textfield-85487768-61b6-4da0-b163-c0a0a637fd70"
                                                name="ConfirmPass"
                                                placeholder="Nhập mật khẩu mới"
                                                type="password"
                                                onChange={handleChange}
                                                value={formData.username}
                                            />
                                        </div>
                                        <div className="suffix-btn-txtfd eye-icon">
                                            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.535 10.5207C20.9132 9.64509 20.2186 8.82344 19.4587 8.0645L16.1085 11.4147C16.1408 11.6043 16.16 11.7958 16.166 11.988C16.166 13.0928 15.7271 14.1524 14.9458 14.9337C14.1645 15.715 13.1049 16.1539 12.0001 16.1539C11.8079 16.1479 11.6164 16.1287 11.4268 16.0964L8.71316 18.81C9.7535 19.25 10.8705 19.4799 12.0001 19.4866C16.7559 19.4866 20.2527 15.269 21.5358 13.4544C21.838 13.0249 22.0002 12.5125 22 11.9874C21.9999 11.4623 21.8374 10.95 21.535 10.5207Z" fill="#282829" fillOpacity="0.6" />
                                                <path d="M12.0001 4.48931C7.24426 4.48931 3.75156 8.70688 2.46429 10.5216C2.16215 10.9508 2 11.463 2 11.988C2 12.5129 2.16215 13.0251 2.46429 13.4544C3.56373 15.0216 4.90982 16.4003 6.45023 17.5369L9.0531 14.9341C8.6662 14.5472 8.35929 14.088 8.14989 13.5825C7.94048 13.0771 7.83268 12.5354 7.83264 11.9882C7.83257 10.8833 8.27143 9.8236 9.05268 9.04223C9.83393 8.26087 10.8936 7.82186 11.9985 7.82178C13.1035 7.82171 14.1632 8.26057 14.9445 9.04182L9.0531 14.9341L17.5524 6.43562C15.952 5.21981 14.0093 4.53883 12.0001 4.48931Z" fill="#282829" fillOpacity="0.6" />
                                                <path d="M2.83505 21.9861C2.67029 21.9861 2.50924 21.9372 2.37226 21.8457C2.23527 21.7541 2.12851 21.624 2.06546 21.4718C2.00242 21.3196 1.98592 21.1521 2.01805 20.9905C2.05018 20.8289 2.12951 20.6804 2.24599 20.5639L20.576 2.23388C20.7331 2.08211 20.9436 1.99813 21.1621 2.00003C21.3805 2.00193 21.5895 2.08955 21.744 2.24403C21.8985 2.39851 21.9861 2.60748 21.988 2.82594C21.9899 3.0444 21.9059 3.25486 21.7541 3.412L3.42411 21.742C3.2679 21.8983 3.05601 21.9861 2.83505 21.9861Z" fill="#282829" fillOpacity="0.6" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" className={`w-button wbaseItem-value w-row 1cb34785-6590-455a-a933-f56029690686 ${loading ? " loading" : ""}`} wrap="nowrap" name-field="Button" level={5} cateid={29}>
                                <div className="w-text wbaseItem-value d6c8683f-8f67-454f-b7d4-841b7334dcac" name-field="Text" level={6} cateid={139}>Đổi mật khẩu</div>
                                <FontAwesomeIcon className='loading-icon' icon={faSpinner} spin style={{ color: "white", order: 2 }} />
                            </button>
                            <div className="w-frame wbaseItem-value w-row f97025f1-bd14-4b93-85f6-3bde731577f7" wrap="nowrap" level={5} cateid={140}>
                                <NavLink to='/login' type="button" className="nav-link w-button wbaseItem-value w-row 2850e392-ed4e-4617-9fc2-d0578a44f8ff" wrap="nowrap" name-field="Button" level={6} cateid={29}>
                                    <div className="w-text wbaseItem-value 4ec584b8-8c2f-4c85-a46f-5209a8d41aa8" name-field="Text" level={7} cateid={139}>Quay lại đăng nhập</div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}