// export const baseurl = 'http://10.14.107.5:8091/jlos/';
export const baseurl = 'https://jlos-service-uat.idcheck.xplat.online/jlos/';
export const base_loginUrl = 'https://jlos-service-uat.idcheck.xplat.online/';
export const contractUrl = 'https://jlos-service-uat.idcheck.xplat.online/';
export const jbpmHost = "https://jlos-jbpm-uat.idcheck.xplat.online";
export const containerId = "JACCS-LOS_1.0.0-SNAPSHOT";


export const base_headers2 = (contenttype) => {
    return {
        "token": localStorage.getItem("token"),
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": contenttype != null ? contenttype : 'application/json',
        // "Accept": "application/json",
        "language": "vi"
    }
}
export const base_headers = (contenttype) => {
    return {
        "token": localStorage.getItem("token"),
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": contenttype != null ? contenttype : 'application/json',
        "Accept": "application/json",
        "language": "vi"
    }
}
export const file_headers = () => {
    return {
        "token": localStorage.getItem("token"),
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": 'multipart/form-data',
        "language": "vi"
    }
}

//
//
export const gettaskall = 'task/all';
export const hosovay = 'ho-so'
export const listhosovay = 'ho-so/search'

//nhom quyen
export const listnhomquyen = 'system-management/groups/all'
export const listmodule = 'system-management/groups/module'

export const createnhomquyen = 'system-management/groups/create'
export const deletenhomquyen = 'system-management/groups/delete'
export const updatenhomquyen = 'system-management/groups/update'
export const updateStatusnhomquyen = 'system-management/groups/change-status'
export const detailnhomquyen = 'system-management/groups/detail'

//nguoi dung
export const getAllUser = 'system-management/user/all'
export const createUser = 'system-management/user/create'
export const updateUser = 'system-management/user/update'
export const deleteUser = 'system-management/user/delete'
export const changeStatusUser = 'system-management/user/change-status'
export const getDetailUser = 'system-management/user/detail'
export const uploadUser = 'system-management/user/upload'
export const templateuploadUser = 'system-management/user/template-upload'
export const resetPassUser = 'system-management/user/reset-password'
export const exportUser = 'system-management/user/export'
export const downLoadtempUser = 'system-management/user/template-upload'


//nguoi dung mobile
export const getAllcustomers = 'customers/all'
export const Insertcustomers = 'customers'
export const getDetailcustomers = 'customers/detail'
export const changeStatusCustomer = 'customers/change-status'
export const ressetPasswordCustomer = 'customers/reset-password'

//hạn mức
export const listHanmuc = 'limit';
export const detailHanmuc = 'limit/detail';
export const updateStatusHanmuc = 'limit/change-status';

// bao hiem
export const ChangestatusInsurance = 'insurance/change-status'
export const getallInsurance = 'insurance/search'
export const InsertInsurance = 'insurance/create'
export const UpdateInsurance = 'insurance/update'
export const getalltypeInsurance = 'insurance/get-all-insurance-type'
export const getallsuplierInsurance = 'insurance/get-all-insurance-supplier'
export const getallperiodInsurance = 'insurance/get-all-insurance-period'
export const getallpaymenttypeInsurance = 'insurance/get-all-insurance-payment-type'
export const getallchargedtypeInsurance = 'insurance/get-all-insurance-charged-type'
//chung tu
export const getalldocument = 'document/search'
export const Insertdocument = 'document/create'
export const Updatedocument = 'document/update'
export const ChangeStatusdocument = 'document/change-status'
export const getalltypedocument = 'document/get-all-type'
export const getallgroupdocument = 'document/get-all-group'
export const Uploaddocument = 'file/upload'

// dai ly
export const getalldealer = 'dealer/search'
export const Createdealer = 'dealer/create'
export const Updatedealer = 'dealer/update'
export const ChangeStatusdealer = 'dealer/change-status'
export const Prioritydealer = 'dealer/get-all-priortize-level'
export const StatusUpdatedealer = 'dealer/get-all-status-update'
export const Systemdealer = 'system-dealer/get-all-active'
export const disbursementtypedealer = 'orther/get-all-disbursement-type'
export const groupProvinceTreedealer = 'dealer/get-all-dealer-group-province-tree'
export const branchdealer = 'orther/get-all-branh-by-bank'
export const brankdealer = 'orther/get-all-bank'
// chuon trinh vay

export const getallloanprogram = 'loanprogram/search'
export const Createloanprogram = 'loanprogram/create'
export const Updateloanprogram = 'loanprogram/update'
export const UpdateStatusloanprogram = 'loanprogram/change-status'
export const facilityList = 'facility/search'
export const Dailyapdung = 'dealer/get-all-dealer-group-province'

//black list
export const getalllBlackList = 'customer-blacklists/search'
export const ImportBlackList = 'customer-blacklists/import'
export const ExportBlackList = 'customer-blacklists/export'
export const templateblacklist = 'customer-blacklists/template-import'
export const BlackList = 'customer-blacklists'
export const reasonBlackList = 'customer-blacklists/droplist-reason'


//cơ cấu tổ chức
export const getAllOrgManager = 'system-management/org/all-no-tree'
export const ChangeStatusOrgManager = 'system-management/org/change-status'
export const CreateOrgManager = 'system-management/org/create'
export const UpdateOrgManager = 'system-management/org/update'
export const DetailOrgManager = 'system-management/org/detail'
export const StaffOrgManager = 'system-management/org/staff'
export const AddStaffOrgManager = 'system-management/org/staff/add'
export const removeStaffOrgManager = 'system-management/org/staff/remove'
export const exportOrgManager = 'system-management/org/export'

//nhãn hiệu
export const ListBranchManager = 'brands/all'
export const addmanufactureManager = 'brands/add-manufacture'
export const ChangeStatusBranchManager = 'brands/change-status'
export const DeleteBranchManager = 'brands/delete'
export const UpdateBranchManager = 'brands/update-brand'
export const UpdateCodeBranchManager = 'brands/update-brand-code'
export const UpdateBranchnameManager = 'brands/update-brand-name'
export const UpdateManufactureBranchManager = 'brands/update-manufacture'

//scoring
export const rankList = 'scoring/rank/get_all'
export const rankupdate = 'scoring/rank/update'
export const rankudelete = 'scoring/rank/delete'
export const listgroupall = 'scoring/group/get_all'
export const creategroup = 'scoring/group/create'
export const deletegroup = 'scoring/group/delete'
export const detailgroup = 'scoring/group/detail'
export const updategroup = 'scoring/group/update'
export const updateweightgroup = 'scoring/group/update_weight'
export const getAllCriteriaByGroupId = 'scoring/criteria/get_all'
export const getCriterialValuebyCriterialId = 'scoring/criteria_value/get_by_scoring_criteria_id'
export const CriterialbyId = 'scoring/criteria_value/get_by_scoring_criteria_id'
export const Updatetieuchicodinh = 'scoring/criteria_value/update_fix/scoring_criteria_value'
export const Updatetieuchikocodinh = 'scoring/criteria_value/update/scoring_criteria_id'

//filter
export const areaList = 'filter/area';
export const bankList = 'filter/bank';
export const brandList = 'filter/brand';
export const careerList = 'filter/career';
export const dealersList = 'filter/dealers';
export const districtsList = 'filter/districts';
export const productList = 'filter/product';
export const orgtitleList = 'filter/org-title';
export const orglevelList = 'filter/org-level';
export const userGroupList = 'filter/user-group';
export const orgleadList = 'filter/org/lead';
export const manufacturesList = 'filter/manufactures';
export const productCategoryList = 'filter/product-category';
export const provincesList = 'filter/provinces';
export const wardsList = 'filter/wards';
export const org = 'filter/org';