import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import { useForm, Controller } from 'react-hook-form';
import Select2 from '../../components/select2/Select2';

import '../../css/nhomquyen/add.css';
import { PopupType, WPopup } from '../../components/Popup/Popup';
import { getallInsuranceperiod, getallInsurancesuplier, getallInsurancetype, getallInsurancepaymenttype, getallInsurancechargedtype, CreateInsurance, getListInsurance, EditInsurance, ChangestatusInsur } from '../../redux/middleware/api/insurance/insuranceDA';
import { getbproductList } from '../../redux/middleware/api/Filter/Filter';
export default function Themmoibaohiem() {
    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({
        shouldFocusError: false
    });
    const [data, setData] = useState({});
    const [supplierList, setsupplierList] = useState([]);
    const [InsuranceTypeList, setInsuranceTypeList] = useState([]);
    const [InsurancePeriodList, setInsurancePeriodList] = useState([]);
    const [productList, setproductList] = useState([]);

    const [chargedTypeList, setchargedTypeList] = useState([]);
    const [paymentTypeList, setpaymentTypeList] = useState([]);
    const [isCancel, setisCancel] = useState();
    const [Kieutinhphi, setKieutinhphi] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    let { id } = useParams();
    const notificationAlert = useRef();

    useEffect(() => {
        _InitData();
        getAllsupplier()
        getAllinsuracetype()
        getAllinsuracePeriod()
        getAllpaymenttype()
        getAllchargedtype()
        getAllproduct()
    }, []);

    const getAllsupplier = async () => {
        const response = await getallInsurancesuplier(1, 100,);

        if (response != null) {
            setsupplierList(response.pageData);
        }
    }
    const getAllpaymenttype = async () => {
        const response = await getallInsurancepaymenttype(1, 100,);
        if (response != null) {
            setpaymentTypeList(response.pageData);
        }
    }
    const getAllinsuracetype = async () => {
        const response = await getallInsurancetype(1, 100,);
        if (response != null) {
            setInsuranceTypeList(response.pageData);
        }
    }

    const getAllchargedtype = async () => {
        const response = await getallInsurancechargedtype(1, 100,);

        if (response != null) {
            setchargedTypeList(response.pageData);
        }
    }
    const getAllproduct = async () => {
        const response = await getbproductList(1, 100,);
        if (response != null) {
            setproductList(response.content);
        }
    }
    const getAllinsuracePeriod = async () => {
        const response = await getallInsuranceperiod(1, 100,);
        if (response != null) {
            setInsurancePeriodList(response.pageData);
        }
    }
    const onchangeKieutinhphi = (event) => {
        debugger;
        console.log(event.target.value)
        if (event.target.value) {
            setKieutinhphi(event.target.value)
            setValue('chargedType', event.target.value)

        }
    }
    
    const OnchangeSwitch = async () => {
        const respone = await ChangestatusInsur(data.losInsuranceDetail?.insuranceCode);
        if (respone.code == '200') {
            setData(prevData => ({
                ...prevData,
                losInsuranceDetail: {
                    ...prevData.losInsuranceDetail,
                    status: prevData.losInsuranceDetail.status === 1 ? 0 : 1,
                },
            }));
            setValue('status', data.losInsuranceDetail?.status === 1 ? 0 : 1)
        } else {
            notify(notificationAlert, ERROR, respone.message)
        }
    }
    const _InitData = async () => {
        if (id) {

            const obj = {
                insuranceCode: id
            }
            const res = await getListInsurance(1, 1, obj);

            if (res != null) {
                setData(res.pageData[0]);
                debugger;
                setValue('insuranceCode', res.pageData[0].losInsuranceDetail.insuranceCode)
                setValue('insuranceName', res.pageData[0].losInsuranceDetail.insuranceName)
                setValue('status', res.pageData[0].losInsuranceDetail.status)
                setValue('insuranceSupplier', res.pageData[0].losInsuranceDetail.insuranceSupplier)
                setValue('insuranceType', res.pageData[0].losInsuranceDetail.insuranceType)
                setValue('insurancePeriod', res.pageData[0].losInsuranceDetail.insurancePeriod)
                setValue('chargedType', res.pageData[0].losInsuranceDetail.chargedType)
                setValue('paymentType', res.pageData[0].losInsuranceDetail.paymentType)
                setValue('product', res.pageData[0].losInsuranceDetail.product)
                setValue('feeInsuranceRate', res.pageData[0].losInsuranceDetail.feeInsuranceRate)
                setValue('feeInsurance', res.pageData[0].losInsuranceDetail.feeInsurance)
                setValue('rateCovered', res.pageData[0].losInsuranceDetail.rateCovered)
            }
        }
    }
    const onCancel = (val) => {
        setisCancel(val);
        if (val == true) {
            navigate('/admin/bao-hiem');
        }
    }
    const onSumit = async (event) => {
        debugger;
        if (data?.losInsuranceDetail?.id && data?.losInsuranceDetail?.id !== 0) {
            event.id = data.losInsuranceDetail.id;
            event.insuranceName = event.insuranceName?.trim();
            const res = await EditInsurance(event, data.losInsuranceDetail.id);
            if (res.code === '200') {
                notify(notificationAlert, SUCCESS, 'Chỉnh sửa gói bảo hiểm thành công.')
                navigate('/admin/bao-hiem');
            } else {
                notify(notificationAlert, ERROR, res.message);
            }
        } else {
            const res = await CreateInsurance(event);
            if (res.code === '200') {
                notify(notificationAlert, SUCCESS, 'Thêm mới gói bảo hiểm thành công.')
                navigate('/admin/bao-hiem');
            } else {
                notify(notificationAlert, ERROR, res.message);
            }
        }
    }
    return (
        <form onSubmit={handleSubmit(onSumit)} autoComplete='off' className="w-frame w-form wbaseItem-value w-col e14e0b64-a97e-4309-84d4-c901a202b4da" level={2}
            cateid={128}>
            <NotificationAlert ref={notificationAlert} />
            {
                isCancel === false &&
                <WPopup
                    type={PopupType.WARNING}
                    title={!id ? 'Hủy thêm mới gói bảo hiểm' : 'Hủy chỉnh sửa gói bảo hiểm'}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => onCancel(true)}
                    cancelAction={() => onCancel(null)}
                    cancelButton={true}
                    cancelTitle='Giữ lại'
                    message={'Những thông tin vừa bạn vừa nhập sẽ không được lưu lại'}
                />
            }
            <div className="w-frame wbaseItem-value w-col 55169930-5156-452b-a8d9-559b160d8fd0" level={3}
                cateid={140}>
                <div className="w-frame wbaseItem-value w-row e4c8db4f-a292-4630-840f-4321cea9a96c" level={4}
                    cateid={140}>
                    <div className="w-frame wbaseItem-value w-col 61c5c3cd-5188-43c8-91b5-1fe7b03e3c39" level={5}
                        cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 4f044728-0e07-4e2f-aa4b-106a7f97b7af"
                            level={6} cateid={140}>
                            <div className="w-text wbaseItem-value 631d5e3e-5ff1-42bb-9224-6d2189c8b545" level={7} cateid={139}>
                                {id ? 'Chỉnh sửa' : 'Thêm mới'} gói bảo hiểm&nbsp;</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row breadcrumb 86cc162c-67d1-4a5a-a8ef-8761295ca239"
                            level={6} cateid={140}>
                            <button type="button"
                                className="w-button wbaseItem-value w-row 29a214b1-e278-4d04-959e-1b70adc3b2a9"
                                level={7} cateid={29}>
                                <div className="w-text wbaseItem-value ecdef2e8-1693-46bd-99d2-d5bd00346b98" name-field="Text"
                                    level={8} cateid={139}>Quản trị hệ thống</div>
                                <div className="w-svg wbaseItem-value 22474178-c66e-4f8b-8c41-88a03a8f8a10" level={8}
                                    cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z"
                                            fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                            <button type="button"
                                className="w-button wbaseItem-value w-row 7807c449-be3d-4411-97d2-3e93e42ae5a6"
                                level={7} cateid={29}>
                                <div className="w-text wbaseItem-value 9c18f47a-0aa3-4dad-86d7-e83232c6d711" name-field="Text"
                                    level={8} cateid={139}>Gói bảo hiểm</div>
                                <div className="w-svg wbaseItem-value 5384ce20-5697-4020-a917-6d5093852462" level={8}
                                    cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z"
                                            fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                            <div className="w-text wbaseItem-value 924a1cb9-ccdd-4314-b624-d40f8f7661b1" level={7} cateid={139}>
                                {id ? 'Chỉnh sửa' : 'Thêm mới'}</div>
                        </div>                        
                    </div>
                    {
                        id ? <div className="w-frame wbaseItem-value w-row 0ef46e31-4af4-4b4b-9b9a-64eec3133efb" wrap="nowrap" level={5} cateid={140}>
                            <div className="w-text wbaseItem-value 54368f73-0d68-477e-810b-98623942a257" level={6} cateid={139}>{data?.losInsuranceDetail?.status == 1 ? 'Đang hoạt động' : 'Không hoạt động'}</div>
                            <label onClick={OnchangeSwitch} htmlFor="input-a6e97769-2e1a-4888-8045-a63e94925bc2" className="w-switch wbaseItem-value a6e97769-2e1a-4888-8045-a63e94925bc2" value="true" name-field="ControlStatus" level={6} cateid={81}>
                                <input type="checkbox" disabled checked={data?.losInsuranceDetail?.status == 1 ? true : false} id="input-a6e97769-2e1a-4888-8045-a63e94925bc2" name="ControlStatus" />
                                <span className="slider">
                                </span>
                            </label>
                        </div> : null
                    }
                </div>
                <div className="w-frame wbaseItem-value w-col ee2b2d2c-8eee-4daa-b96e-d7fc7b406955" scroll="true"
                    level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col f2bae906-3670-41a0-a977-fb7dc48b1d55" level={5}
                        cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 57e8967a-18fa-4213-87ea-7467a2618436" level={6} cateid={140}>
                            <div className="w-text wbaseItem-value d7713971-43be-4840-90db-212f89a383b7" level={7} cateid={139}>Thông tin gói bảo hiểm</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 21fab225-9cef-43b4-8b9a-dc025c921424" wrap="wrap"
                            level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm d9dc8dc9-8c5b-4681-973a-364163c17c83"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row 7e81084d-5b16-4968-a7c6-ef158e9c179d"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value f2285d87-a06c-4911-b28d-2df95eb2a10b" level={9}
                                        cateid={139}>Mã gói bảo hiểm</div>
                                    <div className="w-text wbaseItem-value 0db6357e-7422-4399-83d4-0a298d7b157a" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={"w-textformfield wbaseItem-value w-row e8d4f42c-9efd-47a7-9f46-6a11cd4462a1 " + (errors.insuranceCode && 'helper-text')}
                                    name-field="RoleID" level={8} cateid={85} helper-text={errors.insuranceCode && 'Nhập mã gói bảo hiểm'} placeholder="Nhập mã gói bảo hiểm">
                                    <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f" name-field="Textfield"
                                        level={9} cateid={86}>
                                        <div className="textfield">
                                            <input id="textfield-ee5c5ee0-f72f-480e-9462-53f6362a2d1f" name="insuranceCode"
                                                placeholder="Nhập mã gói bảo hiểm" onInput={(ev) => ev.target.value = ev.target.value.trim()} {...register("insuranceCode", { required: true })} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm cea15270-db58-4d63-ac3f-b477c880d9e9"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Tên gói bảo hiểm</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={"w-textformfield wbaseItem-value w-row eada5e8e-edbd-443b-9579-71cc44317df2 " + (errors.insuranceName && 'helper-text')}
                                    name-field="RoleName" level={8} cateid={85} placeholder="Nhập tên gói bảo hiểm" helper-text={errors.insuranceName && ' Vui lòng nhập tên gói bảo hiểm'}>
                                    <div className="wbaseItem-value 0bb5e726-0339-4344-aaf9-91be0c4c0dd0" name-field="Textfield"
                                        level={9} cateid={86}>
                                        <div className="textfield">
                                            <input id="textfield-0bb5e726-0339-4344-aaf9-91be0c4c0dd0" name="insuranceName"
                                                placeholder="Nhập tên gói bảo hiểm"  {...register("insuranceName", { required: true })} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Nhà cung cấp</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={'select2-custom ' + (errors.insuranceSupplier && 'helper-text')} helper-text={errors.insuranceSupplier && 'Vui lòng chọn nhà cung cấp'}>
                                    <Controller
                                        name="insuranceSupplier"
                                        control={control}
                                        {...register("insuranceSupplier")}
                                        style={{ order: 2 }}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select2 {...field}
                                                data={supplierList?.map((item, _) => { return { id: item.insuranceSupplierCode, name: item.insuranceSupplierName } })}
                                                options={{ placeholder: 'Chọn nhà cung cấp' }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Loại bảo hiểm</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={'select2-custom ' + (errors.insuranceType && 'helper-text')} helper-text={errors.insuranceType && 'Vui lòng chọn loại bảo hiểm'}>
                                    <Controller
                                        name="insuranceType"
                                        control={control}
                                        {...register("insuranceType")}
                                        style={{ order: 2 }}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select2 {...field}
                                                data={InsuranceTypeList?.map((item, _) => { return { id: item.insuranceTypeCode, name: item.insuranceTypeName } })}
                                                options={{ placeholder: 'Chọn loại bảo hiểm' }}

                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Chu kỳ bảo hiểm</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={'select2-custom ' + (errors.insurancePeriod && 'helper-text')} helper-text={errors.insurancePeriod && 'Vui lòng chọn chu kỳ bảo hiểm'}>
                                    <Controller
                                        name="insurancePeriod"
                                        control={control}
                                        {...register("insurancePeriod")}
                                        style={{ order: 2 }}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select2 {...field}
                                                data={InsurancePeriodList?.map((item, _) => { return { id: item.insurancePeriodCode, name: item.insurancePeriodName } })}
                                                options={{ placeholder: 'Chọn chu kỳ bảo hiểm' }}

                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Kiểu tính phí</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={'select2-custom ' + (errors.chargedType && 'helper-text')} helper-text={errors.chargedType && 'Vui lòng chọn Kiểu tính phí'}>
                                    <Controller
                                        name="chargedType"
                                        control={control}
                                        {...register("chargedType")}
                                        style={{ order: 2 }}
                                        rules={{ required: true }}

                                        render={({ field }) => (
                                            <Select2 {...field}
                                                // data={chargedTypeList.map((item, _) => { return { id: item.chargedTypeCode, name: item.chargedTypeName } })}
                                                data={chargedTypeList?.map((item, _) => { return { id: item.chargedTypeCode, name: item.chargedTypeName } })}
                                                options={{ placeholder: 'Chọn kiểu tính phí' }}
                                                onChange={onchangeKieutinhphi}

                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Tỷ lệ phí (%)</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.feeInsuranceRate && 'helper-text')} helper-text={errors.feeInsuranceRate && errors.feeInsuranceRate.message} style={{ order: 2 }} name-field="Status" level={8} cateid={85} placeholder="nhập tỷ lệ phí">
                                    <div className="wbaseItem-value 217b9f90-384d-4edc-845b-7db38fbdc754" name-field="Textfield" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input name="feeInsuranceRate" disabled={Kieutinhphi !== 'KTP1'} type='number'
                                                // {...register('feeInsuranceRate', {
                                                //     required: 'Vui lòng nhập tỷ lệ phí',
                                                //     pattern: {
                                                //         value: /^-?\d{1,20}(\.\d{1,2})?$/,
                                                //         message: 'Tỷ lệ phí không đúng định dạng',
                                                //     },
                                                // })} 
                                                {...register('feeInsuranceRate', {
                                                    validate: (value) => {
                                                        if (Kieutinhphi === 'KTP1') {
                                                            console.log(value)
                                                            if (!value) {
                                                                return 'Vui lòng nhập tỷ lệ phí';
                                                            }
                                                            if (!(/^-?\d{1,20}(\.\d{1,2})?$/).test(value)) {
                                                                return 'Tỷ lệ phí không đúng định dạng';
                                                            }
                                                        }
                                                        return true; // Nếu trường không cần validate, trả về true
                                                    },
                                                })}
                                                placeholder="Nhập tỷ lệ phí" />
                                        </div>
                                    </div>
                                    <div className="w-svg wbaseItem-value d225bad7-7f04-48da-9e11-9e4572a1f06a" level={9} cateid={115}>
                                        %
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Phí bảo hiểm</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.feeInsurance && 'helper-text')} helper-text={errors.feeInsurance && errors.feeInsurance.message} style={{ order: 2 }} name-field="Status" level={8} cateid={85} placeholder="nhập phí bảo hiểm">
                                    <div className="wbaseItem-value 217b9f90-384d-4edc-845b-7db38fbdc754" name-field="Textfield" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input name="feeInsurance" placeholder="Nhập phí bảo hiểm" type='number' disabled={Kieutinhphi !== 'KTP2'}
                                                // ref={register('feeInsurance', {
                                                //     required: 'Vui lòng nhập phí bảo hiểm',
                                                //     pattern: {
                                                //         value: /^[+]?\d*\.?\d+$/,
                                                //         message: 'Số tiền không đúng định dạng',
                                                //     },
                                                // })}
                                                {...register('feeInsurance', {
                                                    validate: (value) => {
                                                        if (Kieutinhphi === 'KTP2') {
                                                            if (!value) {
                                                                return 'Vui lòng nhập phí bảo hiểm';
                                                            }
                                                            if (!(/^[+]?\d*\.?\d+$/).test(value)) {
                                                                return 'Số tiền không đúng định dạng';
                                                            }
                                                        }
                                                        return true; // Nếu trường không cần validate, trả về true
                                                    },
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-svg wbaseItem-value d225bad7-7f04-48da-9e11-9e4572a1f06a" level={9} cateid={115}>
                                        VNĐ
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Tỷ lệ (%) số tiền được bảo hiểm</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.rateCovered && 'helper-text')} helper-text={errors.rateCovered && errors.rateCovered.message} style={{ order: 2 }} name-field="Status" level={8} cateid={85} placeholder="Tỷ lệ số tiền được bảo hiểm">
                                    <div className="wbaseItem-value 217b9f90-384d-4edc-845b-7db38fbdc754" name-field="Textfield" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input name="rateCovered" type='number' {...register('rateCovered', {
                                                required: 'Vui lòng nhập tỷ lệ số tiền bảo hiểm',
                                                pattern: {
                                                    value: /^-?\d{1,20}(\.\d{1,2})?$/,
                                                    message: 'Tỷ lệ không đúng định dạng',
                                                },
                                            })} placeholder="Nhập tỷ lệ số tiền được bảo hiểm" />
                                        </div>
                                    </div>
                                    <div className="w-svg wbaseItem-value d225bad7-7f04-48da-9e11-9e4572a1f06a" level={9} cateid={115}>
                                        %
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Hình thức thanh toán</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={'select2-custom ' + (errors.paymentType && 'helper-text')} helper-text={errors.paymentType && 'Vui lòng chọn Hình thức thanh toán'}>
                                    <Controller
                                        name="paymentType"
                                        control={control}
                                        {...register("paymentType")}
                                        style={{ order: 2 }}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select2 {...field}
                                                data={paymentTypeList?.map((item, _) => { return { id: item.losInsurancePaymentTypeCode, name: item.losInsurancePaymentTypeName } })}
                                                options={{ placeholder: 'Chọn hình thức thanh toán' }}

                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Sản phẩm áp dụng</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={'select2-custom ' + (errors.product && 'helper-text')} helper-text={errors.product && 'Vui lòng chọn Sản phẩm áp dụng'}>
                                    <Controller
                                        name="product"
                                        control={control}
                                        {...register("product")}
                                        style={{ order: 2 }}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select2 {...field}
                                                data={productList?.map((item, _) => { return { id: item.code, name: item.name } })}
                                                options={{ placeholder: 'Chọn Sản phẩm áp dụng' }}


                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 889570b9-4a79-451c-bbc2-6a7d86535126"
                                level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row b4fd16e4-08de-460b-b4c4-ece9531494df"
                                    level={8} cateid={140}>
                                    <div className="w-text wbaseItem-value 3621107e-bc9a-4d3c-83d9-d80347491d12" level={9}
                                        cateid={139}>Trạng thái</div>
                                    <div className="w-text wbaseItem-value a511b9e1-4835-4274-97ea-b3a371d591f8" level={9}
                                        cateid={139}>*</div>
                                </div>
                                <div className={'select2-custom ' + (errors.status && 'helper-text')} helper-text={errors.status && 'Vui lòng chọn trạng thái'}>
                                    <Controller
                                        name="status"
                                        {...register("status")}
                                        control={control}
                                        rules={{ required: true }}
                                        style={{ order: 2 }}
                                        render={({ field }) => (
                                            <Select2 {...field} data={[{ id: 1, name: 'Hoạt động' }, { id: 0, name: 'Không hoạt động' }]}
                                                options={{
                                                    placeholder: 'Chọn trạng thái'
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                !id ? null : <div className="w-frame wbaseItem-value w-col 9eb18653-3703-4f95-a85b-74d4ca8ece6d" level={5} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row 57e8967a-18fa-4213-87ea-7467a2618436" level={6} cateid={140}>
                        <div className="w-text wbaseItem-value d7713971-43be-4840-90db-212f89a383b7" level={7} cateid={139}>Lịch sử</div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row 1e7979de-8c4b-44e4-8cd5-f8757b861613" wrap="wrap" level={6} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl 50140cd2-149a-4ead-b0fb-d3f80bfce450" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 1d33454e-07c2-4b49-83dc-02c3bbfa5e90" level={8} cateid={139}>Người tạo:</div>
                            <div className="w-text wbaseItem-value 82d8c317-da95-41d2-bd17-e5a1a4651a9d" level={8} cateid={139}>{data?.losInsuranceDetail?.createdBy}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 02200cc8-9be3-4ce3-9f17-28be0fced6f3" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 8b1c558c-3d19-4994-be37-503a66f5996b" level={8} cateid={139}>Ngày tạo:</div>
                            <div className="w-text wbaseItem-value 422aca98-e87e-4690-9e53-3ac8648e7e41" level={8} cateid={139}>{data?.losInsuranceDetail?.createdDate}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl e7040e4d-5db5-44b3-a879-e37e73c8e9c9" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value d692dff2-ab0c-4949-9fba-b6906bce6568" level={8} cateid={139}>Người thay đổi cuối:</div>
                            <div className="w-text wbaseItem-value 45defc28-b4bd-4186-bc28-a57e350ffd3f" level={8} cateid={139}>{data?.losInsuranceDetail?.modifiedBy}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl ad18ff4d-59d6-4ea4-ba2b-2721f6e549c4" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 907931d7-c4e2-41d0-8daf-1ddf0538948a" level={8} cateid={139}>Ngày thay đổi cuối:</div>
                            <div className="w-text wbaseItem-value dbb81084-bbd1-4d6f-b581-9e59651fb842" level={8} cateid={139}>{data?.losInsuranceDetail?.modifiedDate}</div>
                        </div>
                    </div>
                </div>
            }


            <div className="w-frame wbaseItem-value w-row a5650dcc-7a8c-43b6-a92e-13b1c4b2fc68" level={3}
                cateid={140}>
                <button onClick={() => onCancel(false)} type="reset" className="w-button wbaseItem-value w-row 0827b65b-f8dd-422d-9526-661a04e2e39b"
                    level={4} cateid={29}>
                    <div className="w-text wbaseItem-value ed946f59-a67d-4391-8bd9-75ff48cd1b16" level={5}
                        cateid={139}>{location.pathname.split("/").pop() === "them-moi-bao-hiem" ? "Hủy" : "Hủy"}</div>
                </button>
                <button type="submit" className="w-button wbaseItem-value w-row 99617da8-7d91-4e4d-b33b-f34e0eadac50"
                    level={4} cateid={29}>
                    <div className="w-svg wbaseItem-value 4565f46e-45f7-418c-8968-a7510d4ee3bb" level={5} cateid={115}>
                        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M14.4893 3.5115C14.726 3.74818 14.726 4.13191 14.4893 4.36859L6.61053 12.2474C6.37385 12.4841 5.99011 12.4841 5.75343 12.2474L1.51101 8.00496C1.27433 7.76828 1.27433 7.38454 1.51101 7.14786C1.74769 6.91118 2.13142 6.91118 2.36811 7.14786L6.18198 10.9617L13.6322 3.5115C13.8689 3.27481 14.2526 3.27481 14.4893 3.5115Z"
                                fill="#FFFFFFFF">
                            </path>
                        </svg>
                    </div>
                    <div className="w-text wbaseItem-value bb1d2d55-76a9-4b06-b469-422a88dd411e" level={5}
                        cateid={139}>{id ? 'Lưu' : 'Thêm mới'}</div>
                </button>
            </div>
        </form>
    )
}