import { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import Select2 from '../../components/select2/Select2';

import { useForm, Controller } from 'react-hook-form';

import '../../css/nhomquyen/masterpage.css';
import Pagination from '../../components/Pagination/Pagination';
import { NavLink, useLocation } from 'react-router-dom';
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import { changestatusDoc, getListdocuments, gettypedocument } from '../../redux/middleware/api/documents/documentsDA';
import ScoringDA from '../../redux/middleware/api/Scoring/ScoringDA';

import $ from 'jquery';


export default function Chungtu() {
    // const { register, handleSubmit, setValue, control, formState: { errors } } = useForm(
    //     {
    //     }
    // );
    const location = useLocation();
    const [data, setData] = useState([]);
    const [dataTmp, setDataTmp] = useState([]);
    const [isEdit, setisEdit] = useState(false);
    // const [isEdit, setisEdit] = useState(false);
    const notificationAlert = useRef();
    const _InitData = async () => {
        const res = await ScoringDA.getallRank();
        if (res != null) {
            setData(res)
            setDataTmp(res)
        }
    }
    useEffect(() => {
        _InitData();
    }, []);
    const onAddrank = () => {
        var item =
        {
            rank: null,
            description: null,
            minPoint: null,
            maxPoint: null,
            status: null,
            isEdit: true
        };
        setDataTmp([...dataTmp, item])
    }
    const EditState = () => {
        var tmp = dataTmp?.map((item) => { return { ...item, isEdit: true } });
        setDataTmp(tmp)
        setisEdit(true)

    }
    const onchangeField = (event) => {

        const index = parseInt(event.target.getAttribute('data-index'));
        var classname = event.target.name + '_' + index;

        if (event.target.name === 'minPoint' || event.target.name === 'maxPoint') {
            if (event.target.name === 'minPoint' && dataTmp[index].maxPoint && event.target.value <= dataTmp[index].maxPoint) {
                // $(event.target.name + '_'+index).addClass('helper-text');
                $('.' + classname).removeClass('helper-text');
            }
            if (event.target.name === 'maxPoint' && dataTmp[index].minPoint && event.target.value >= dataTmp[index].minPoint) {
                // $(event.target.name + '_'+index).addClass('helper-text');
                $('.' + classname).removeClass('helper-text');
            }
        }
        if (event.target.value) {
            $('.' + classname).removeClass('helper-text');
        }
        setDataTmp((dataTmp) =>
            dataTmp.map((item, a) =>
                a === index ? { ...item, [event.target.name]: event.target.value } : item
            ));
    }
    function CheckNum(num) {

        num = '' + num;
        const decimalIndex = num.indexOf('.');
        if (decimalIndex !== -1) {
            if (num.split('.')[1].length > 2) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }


    }
    const onSaverank = async () => {

        var check = false;
        var checkDuplicate = false;
        const frequencyMap = {};

        // var rex = new RegExp('/^\d+(\.\d{0,2})?$/');
        var lstduplicate = [];
        const uniqueSet = new Set();

        dataTmp.map((a, index) => {
            if (!a.description) {
                $('.description_' + index).addClass('helper-text');
                check = true;
            }
            if (!a.rank) {
                $('.rank_' + index).addClass('helper-text');
                check = true;
            } else {
                if (uniqueSet.has(a.rank)) {
                    a.index = index;
                    lstduplicate.push(a);
                    checkDuplicate = true;
                } else {
                    uniqueSet.add(a.rank);
                }
            }
            if (a.minPoint == null || a.minPoint === "") {
                $('.minPoint_' + index).addClass('helper-text');
                check = true;
            } else
                if (a.minPoint < -100 || a.minPoint > 100) {
                    // $(event.target.name + '_'+index).addClass('helper-text');
                    $('.minPoint_' + index).addClass('helper-text');
                    notify(notificationAlert, ERROR, 'Vui lòng nhập dải điểm từ >= -100 hoặc <= 100')
                    check = true;
                }
                else if (!CheckNum(a.minPoint)) {
                    // notify(notificationAlert, ERROR, 'Không được phép nhập sau số thập phân quá 2 ký tự')
                    $('.minPoint_' + index).addClass('helper-text');
                    check = true;
                }
            if (a.maxPoint == null || a.maxPoint === "") {
                $('.maxPoint_' + index).addClass('helper-text');
                check = true;
            } else
                if (a.maxPoint > 100 || a.maxPoint < -100) {
                    $('.maxPoint_' + index).addClass('helper-text');
                    notify(notificationAlert, ERROR, 'Vui lòng nhập dải điểm đến <= 100 hoặc >= -100')
                    check = true;
                }
                else if (!CheckNum(a.maxPoint)) {
                    // notify(notificationAlert, ERROR, 'Không được phép nhập sau số thập phân quá 2 ký tự')
                    $('.maxPoint_' + index).addClass('helper-text');
                    check = true;
                }
        })
        debugger;
        if (lstduplicate.length > 0) {
            lstduplicate.map((a, index) => {                
                $('.rank_' + a.index).addClass('helper-text');
            })
        }
        if (check || checkDuplicate)
            return;
        else {
            const res = await ScoringDA.EditRank(dataTmp);
            debugger;
            if (res.code == '200') {
                notify(notificationAlert, SUCCESS, 'Cập nhật dữ liệu thành công.')
                setData(res.data)
                setDataTmp(res.data)
                setisEdit(false)
                // window.location.reload()
            } else {
                notify(notificationAlert, ERROR, res.message)
            }
        }
    }
    const onDeleterealData = async (index) => {
        if (dataTmp[index].isEdit) {
            var updateItem = [...dataTmp];
            updateItem.splice(index, 1);
            setDataTmp(updateItem)
        }
    }
    return (
        <div className="w-frame wbaseItem-value w-col ea27d422-4911-447e-b1a0-61cc0db85b44" wrap="nowrap" level={3} cateid={140}>
            <NotificationAlert ref={notificationAlert} />
            <div className="w-frame wbaseItem-value w-col 41d2ca9e-2b09-472a-88b5-b6c403239ba1" wrap="nowrap" scroll="true" level={4} cateid={140}>
                <div className="w-frame wbaseItem-value w-col e8de9f90-0118-414b-bc69-da273a578779" wrap="nowrap" level={5} cateid={140}>
                    <div style={{ order: 0 }} className="w-frame wbaseItem-value w-row 6ef2f09f-bb09-4a7f-bca4-ba5fe9a4682c" wrap="nowrap" level={6} cateid={140}>
                        <div className="w-text wbaseItem-value af9be095-13e9-4645-8637-973ae192c9f6" level={7} cateid={139}>Danh sách xếp hạng tín dụng</div>
                        <button onClick={isEdit ? onSaverank : EditState} type="button" className="nav-link w-button wbaseItem-value w-row fb37cd88-8ee8-40b0-a95a-b2d75b576b2b" wrap="nowrap" name-field="Button" level={7} cateid={29}>
                            <div className="w-svg wbaseItem-value 1710df3c-da56-4872-9f20-1bced4d4431e" level={8} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z" fill="#FFFFFFFF" />
                                </svg>
                            </div>
                            <div className="w-text wbaseItem-value 0e861cd1-5036-4fba-b559-b0ca36be7bb0" name-field="Text" level={8} cateid={139}>{isEdit ? 'Lưu' : 'Chỉnh sửa xếp hạng'}</div>
                        </button>
                        {
                            isEdit ? <button onClick={onAddrank} type="button" className="nav-link w-button wbaseItem-value w-row fb37cd88-8ee8-40b0-a95a-b2d75b576b2b" wrap="nowrap" name-field="Button" level={7} cateid={29}>

                                <div className="w-text wbaseItem-value 0e861cd1-5036-4fba-b559-b0ca36be7bb0" name-field="Text" level={8} cateid={139}>Thêm xếp hạng</div>
                            </button> : null
                        }

                        {
                            isEdit ? <button onClick={() => {
                                // var tmp = data.map((item) => { return { ...item, isEdit: false } });
                                setDataTmp(data)
                                setisEdit(false)
                            }} type="button" className="nav-link w-button wbaseItem-value w-row fb37cd88-8ee8-40b0-a95a-b2d75b576b2b" wrap="nowrap" name-field="Button" level={7} cateid={29}>

                                <div className="w-text wbaseItem-value 0e861cd1-5036-4fba-b559-b0ca36be7bb0" name-field="Text" level={8} cateid={139}>Hủy</div>
                            </button> : null
                        }


                    </div>
                    <table className="w-table wbaseItem-value dbe0f04f-f73f-41cf-a94e-988260f1acdf" type={0} level={6} cateid={117}>
                        <tbody>
                            <tr className="table-row">
                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row e90e5935-90a6-40b5-a7d4-76d681ac32d2" wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 2485e51c-ff7e-44a9-a5d4-466e898c9988" level={8} cateid={139}>STT</div>
                                    </div>
                                </td>
                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row e064df7b-f2e0-49ef-a2fe-c4bc517fd403" wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value c773fe31-0b32-4424-afa2-d9e81b4b309f" style={{textAlign:'center'}} level={8} cateid={139}>Xếp hạng</div>
                                    </div>
                                </td>
                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Kết quả</div>
                                    </div>
                                </td>
                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" style={{textAlign:'left'}} level={8} cateid={139}>Dải điểm từ</div>
                                    </div>
                                </td>
                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" style={{textAlign:'left'}} level={8} cateid={139}>Dải điểm đến</div>
                                    </div>
                                </td>
                                {/* <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Sản phẩm</div>
                                    </div>
                                </td> */}
                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" wrap="nowrap" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>{isEdit == false ? '' : 'Hành động'}</div>
                                    </div>
                                </td>
                            </tr>
                            {
                                dataTmp.map((item, index) => {
                                    return (item.isEdit === true ?
                                        <tr key={index} className="table-row">
                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row acbebf44-6c58-482d-86c8-31f3d1649f04" wrap="nowrap" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 51d88187-e142-427e-9963-328e66e429f6" level={8} cateid={139}>{index + 1}</div>
                                                </div>
                                            </td>
                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 1339efd5-c346-41c5-b3cb-d68cb8e3c5a6" wrap="nowrap" level={7} cateid={140}>
                                                    <div className={"w-textformfield wbaseItem-value w-row 13f1607e-350d-4c48-9fe4-c4a02a76c600 rank_" + index} wrap="nowrap" name-field="LimitSuggest" level={8} cateid={85} placeholder="Xếp hạng">
                                                        <div className="wbaseItem-value 85355487-8751-4020-bec8-77905a8c2ff9" name-field="Textfield" level={9} cateid={86}>
                                                            <div className="textfield">
                                                                <input name="rank" data-index={index} maxLength={50} value={item.rank} onChange={onchangeField} placeholder="Xếp hạng" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row c7a93308-b15b-461a-acc7-3ce7383b3e88" wrap="nowrap" level={7} cateid={140}>
                                                    <div className={`select2-custom description_${index}`}>
                                                        <Select2
                                                            name='description'
                                                            data={[
                                                                { id: 'Phê duyệt ngay', name: 'Phê duyệt ngay' },
                                                                { id: 'Được xét NOCALL', name: 'Được xét NOCALL' },
                                                                { id: 'Xác minh đơn giản', name: 'Xác minh đơn giản' },
                                                                { id: 'Thẩm định qua điện thoại', name: 'Thẩm định qua điện thoại' },
                                                                { id: 'Từ chối ngay', name: 'Từ chối ngay' }
                                                            ]}
                                                            value={item.description} options={{ placeholder: 'Chọn kết quả' }} data-index={index} onChange={onchangeField} />
                                                    </div>

                                                </div>
                                            </td>
                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 1339efd5-c346-41c5-b3cb-d68cb8e3c5a6" wrap="nowrap" level={7} cateid={140}>
                                                    <div className={"w-textformfield wbaseItem-value w-row 13f1607e-350d-4c48-9fe4-c4a02a76c600 minPoint_" + index} wrap="nowrap" name-field="LimitSuggest" level={8} cateid={85} placeholder="Điểm từ">
                                                        <div className="wbaseItem-value 85355487-8751-4020-bec8-77905a8c2ff9" name-field="Textfield" level={9} cateid={86}>
                                                            <div className="textfield">
                                                                <input name="minPoint" min={-100} type='number' data-index={index} value={item.minPoint} onChange={onchangeField} placeholder="Điểm từ" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 1339efd5-c346-41c5-b3cb-d68cb8e3c5a6" wrap="nowrap" level={7} cateid={140}>
                                                    <div className={"w-textformfield wbaseItem-value w-row 13f1607e-350d-4c48-9fe4-c4a02a76c600 maxPoint_" + index} wrap="nowrap" name-field="LimitSuggest" level={8} cateid={85} placeholder="Điểm đến">
                                                        <div className="wbaseItem-value 85355487-8751-4020-bec8-77905a8c2ff9" name-field="Textfield" level={9} cateid={86}>
                                                            <div className="textfield">
                                                                <input name="maxPoint" max={100} type='number' data-index={index} value={item.maxPoint} onChange={onchangeField} placeholder="Điểm đến" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            {/* <td className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" wrap="nowrap" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.product}</div>
                                                </div>
                                            </td> */}
                                            <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row f39af48d-1e41-41ee-b2d0-de37d7a8fffb" wrap="nowrap" level={10} cateid={140}>
                                                    <div
                                                        onClick={() => onDeleterealData(index)}
                                                        className="w-svg wbaseItem-value 3d5d2356-bf4e-4d4c-a639-5ca33740a4f5" level={11} cateid={115}>
                                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                            </path>
                                                            <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                        :
                                        <tr key={item.losDocumentDetail?.id} className="table-row">
                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row acbebf44-6c58-482d-86c8-31f3d1649f04" wrap="nowrap" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 51d88187-e142-427e-9963-328e66e429f6" level={8} cateid={139}>{index + 1}</div>
                                                </div>
                                            </td>
                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" wrap="nowrap" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.rank}</div>
                                                </div>
                                            </td>

                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" wrap="nowrap" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.description}</div>
                                                </div>
                                            </td>
                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" wrap="nowrap" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.minPoint}</div>
                                                </div>
                                            </td>
                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" wrap="nowrap" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.maxPoint}</div>
                                                </div>
                                            </td>
                                            {/* <td className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" wrap="nowrap" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.product}</div>
                                                </div>
                                            </td> */}
                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                {
                                                    isEdit ? <div className="w-frame wbaseItem-value w-row f39af48d-1e41-41ee-b2d0-de37d7a8fffb" wrap="nowrap" level={10} cateid={140}>
                                                        <div
                                                            onClick={() => onDeleterealData(index)}
                                                            className="w-svg wbaseItem-value 3d5d2356-bf4e-4d4c-a639-5ca33740a4f5" level={11} cateid={115}>
                                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                                </path>
                                                                <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                                </path>
                                                            </svg>
                                                        </div>
                                                    </div> : <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" wrap="nowrap" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}></div>
                                                    </div>
                                                }

                                            </td>

                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>


                </div>
            </div>
        </div>
    )
}