import { useEffect, useState } from "react";
import "./PopupXemQuyTrinh.css";
import "./PopupChonThaoTac.css";
import Select2 from "../../../components/select2/Select2";

export default function PopupChonThaoTac({ khachangCif, closePopup, gifList = [], confirm }) {
    const [option, setOption] = useState();
    const [cifUpdate, setCifUpdate] = useState();
    useEffect(() => { }, [])

    return (<div className="w-frame wbaseItem-value w-col no-trans" level={1} cateid={140} style={{ borderRadius: "8px", backgroundColor: "white" }}>
        <div className="w-frame wbaseItem-value w-row 7f531114-47da-4654-8d27-2a55792430eb" level={2} cateid={140}>
            <div className="w-text wbaseItem-value d2f88554-b49c-4aaf-aaea-6b79b82e0c9c" level={3} cateid={139}>Bạn muốn thực hiện thao tác nào?</div>
            <button className="w-button wbaseItem-value w-row" onClick={closePopup}>
                <div className="w-svg wbaseItem-value 323b8f9f-82df-47a9-bb29-6fe838ffcc59" level={3} cateid={115}>
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" />
                    </svg>
                </div>
            </button>
        </div>
        <div className="w-frame wbaseItem-value w-col body-popup">
            <div onClick={() => setOption(1)} className={"option-tile w-frame wbaseItem-value w-row" + (khachangCif ? "" : " disabled")}>
                <div className="w-text wbaseItem-value d2f88554-b49c-4aaf-aaea-6b79b82e0c9c" level={3} cateid={139}>Tạo khoản vay</div>
                <label className="w-radio-btn wbaseItem-value bda3a5f8-0f44-4287-9ac0-a07a3697468b" level="10" cateid="90" >
                    <input type="radio" disabled name='option' checked={option === 1} />
                    <span className="checkmark" ></span>
                </label>
            </div>
            <div className={"option-tile w-frame wbaseItem-value w-col" + (gifList.length > 0 ? "" : " disabled")} onClick={() => setOption(2)}>
                <div className="w-frame wbaseItem-value w-row" style={{ width: "100%" }}>
                    <div className="w-text wbaseItem-value d2f88554-b49c-4aaf-aaea-6b79b82e0c9c" level={3} cateid={139}>Cập nhật CIF và tạo khoản vay</div>
                    <label className="w-radio-btn wbaseItem-value bda3a5f8-0f44-4287-9ac0-a07a3697468b" level="10" cateid="90" >
                        <input type="radio" disabled name='option' checked={option === 2} />
                        <span className="checkmark" ></span>
                    </label>
                </div>
                <div className={'select2-custom'}>
                    <Select2 name="gif"
                        data={gifList.map(e => { return { id: e, name: e } })}
                        options={{ placeholder: 'Chọn số CIF' }}
                        onChange={(ev) => setCifUpdate(ev.target.value)}
                    />
                </div>
            </div>
            <div onClick={() => setOption(3)} className={"option-tile w-frame wbaseItem-value w-row" + (gifList.length > 0 ? " disabled" : "")}>
                <div className="w-text wbaseItem-value d2f88554-b49c-4aaf-aaea-6b79b82e0c9c" level={3} cateid={139}>Tạo CIF mới và tạo khoản vay</div>
                <label className="w-radio-btn wbaseItem-value bda3a5f8-0f44-4287-9ac0-a07a3697468b" level="10" cateid="90" >
                    <input type="radio" disabled name='option' checked={option === 3} />
                    <span className="checkmark" ></span>
                </label>
            </div>
        </div>
        <div className="w-frame wbaseItem-value w-row 6505fc7a-9dfe-4729-abb4-b08c456eb902" level={2} cateid={140}>
            <div className="w-frame wbaseItem-value w-row 1a595341-f453-4eeb-b7ba-e67154913f08" level={3} cateid={140}>
                <button type="button" onClick={() => { confirm(option, option === 1 ? khachangCif : cifUpdate); }} className="w-button wbaseItem-value w-row fc4c4113-4940-47ec-8bae-a7f488157632" level={4} cateid={29} style={{ backgroundColor: option == null ? "#f7f7f7" : "rgb(0, 153, 68)", pointerEvents: option == null ? "none" : "auto", }}>
                    <div className="w-text wbaseItem-value 0176e5e2-7fe2-40ec-bb13-434cc40f4b0a" level={5} cateid={139} style={{ color: "white" }}>Xác nhận</div>
                </button>
            </div>
        </div>
    </div>)
}