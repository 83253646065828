
export const SUCCESS = 'success';
export const ERROR = 'danger';
export const notify = async(notificationAlert, type,msg) => {
    var options = {};
    options = {
      place: "tr",
      message: (msg),
      type: type,
      autoDismiss:3
    };
    notificationAlert.current.notificationAlert(options);
  };