import { ChangeStatusdocument, Insertdocument, Updatedocument, Uploaddocument, baseurl, getalldocument, getallgroupdocument, getalltypedocument } from "../../../../config/ConfigAPI";
import { getData, postData, postFormDataFile, putData } from "../../../../core/BaseDA";

export const getListdocuments = async (page, size, obj) => {
    const url = `${baseurl}${getalldocument}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
    const response = await postData(url, {obj: obj});
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const gettypedocument = async (page, size) => {
    const url = `${baseurl}${getalltypedocument}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const getgroupdocument = async (page, size) => {
    const url = `${baseurl}${getallgroupdocument}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const UploadFiledocuments = async (obj) => {    
    const url = `${baseurl}${Uploaddocument}`;
    const response = await postFormDataFile(url, {obj: obj});
    return response;
};

export const Createdocuments = async (obj) => {
    
    const url = `${baseurl}${Insertdocument}`;
    const response = await postData(url, {obj: obj});
    return response;
};

export const Editdocuments = async (obj,id) => {
    
    const url = `${baseurl}${Updatedocument}/${id}`;
    const response = await putData(url, {obj: obj});
    return response;
};

export const changestatusDoc = async (code) => {
    const url = `${baseurl}${ChangeStatusdocument}?code=${code}`;
    const response = await putData(url, {obj: null});
    return response;
};
