import '../../css/user/adduser.css';
import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';
import { EditUser, createNewUser, getUserbyId } from '../../redux/middleware/api/quanlynguoidung/userDA';
import { getListModue, getListNhomquyen } from '../../redux/middleware/api/nhomquyen/NhomQuyenDA';
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import NotificationAlert from "react-notification-alert";
import { PopupType, WPopup } from '../../components/Popup/Popup';
import { getProvincesList, getareaList, getbankList, getorgList, getorgtitleList } from '../../redux/middleware/api/Filter/Filter';
import Select2 from '../../components/select2/Select2';
import { PopupSelectAgent, PopupSelectHanmuc, PopupSelectProduct } from '../CongViecCuaToi/Popup';
import { getValue } from '@testing-library/user-event/dist/utils';
export default function CreateAndEditUser() {
    const { register, handleSubmit, control, setValue, getValues, formState: { errors } } = useForm({
        shouldFocusError: false
    });
    const notificationAlert = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const [isCancel, setisCancel] = useState();
    const [fileAvatar, setfileAvatar] = useState();
    const [fileImg, setfileImg] = useState();
    let { id } = useParams();
    const [scrollIndex, setScroll] = useState(0);
    const [data, setData] = useState({});
    const [isLeader, setLeader] = useState(false);
    const [isViewdata, setViewdata] = useState(false);
    const [isAdmin, setAdmin] = useState(false);
    const [moduleList, setmoduleList] = useState([]);
    const [bankList, setbankList] = useState([]);
    const [groupModuleList, setgroupModuleList] = useState([]);
    const [limitList, setlimitList] = useState([]);
    const [orgList, setorgList] = useState([]);
    const [orgTitleList, setorgTitleList] = useState([]);
    const [areaList, setareaList] = useState([]);
    const [dealersList, setdealersList] = useState([]);
    const [productList, setproductList] = useState([]);
    const [showPopupLimit, setPopupLimit] = useState(false);
    const [showPopupAgent, setPopupAgent] = useState(false);
    const [showPopupProduct, setPopupProduct] = useState(false);
    // const [productList, setproductList] = useState([]);    

    // const options = orgList.map((item, _) => ({
    //     value: item,
    //     label: item.name,

    // }));
    // const _selectedOption = {
    //     value: selected,
    //     label: selected.name
    // }

    const onCancel = (val) => {

        setisCancel(val);

        if (val == true) {
            navigate('/admin/user');
        }
    }
    const _InitData = async () => {
        if (id) {
            const res = await getUserbyId(id);
            if (res != null) {
                setgroupModuleList((groupModuleList) =>
                    groupModuleList?.map((item) =>
                        res.groups?.some((a) => a.id === item.id) ? { ...item, check: true } : item
                    ));
                setmoduleList((moduleList) =>
                    moduleList?.map((item) =>
                        res.userPermission?.some((a) => a.id === item.id) ? { ...item, check: true } : item
                    ));
                // setlimitList(ré);
                debugger;
                setlimitList(res.loanLimits ?? []);
                setdealersList(res.dealers ?? []);
                setproductList(res.products ?? []);
                setData(res);
                setValue("fullName", res.fullName)
                setValue("phoneNumber", res.phoneNumber)
                setValue("username", res.username)
                setValue("email", res.email)
                setValue("staffCode", res.staffCode)
                setValue("orgId", res.orgInfo.orgId)
                setValue("orgTitle", res.orgTitle?.id)
                if (res.areaInfo)
                    setValue("areaId", res.areaInfo.code)
                setValue("password", res.password)
                setValue("status", res.status)
                setValue("lastDayChangePassword", res.lastDayChangePassword)
                setValue("description", res.description)
                setValue("bankAccountName", res.bankAccountName)
                setValue("bankAccountNumber", res.bankAccountNumber)
                setValue("bankId", res.bankId)
                setValue("description", res.description)
                setLeader(res.orgLead === 1 ? true : false)
                setViewdata(res.orgSupervisor === 1 ? true : false)
                setAdmin(res.isAdmin === 1 ? true : false)

            }
        }else{
            setValue('status',1)
        }
    }
    const getAllmoduleList = async () => {
        const response = await getListModue();
        if (response != null) {
            setmoduleList(response);
        }
    }
    const getAllgroupModule = async () => {
        const response = await getListNhomquyen(1, 100, '?status=1');
        if (response != null) {
            setgroupModuleList(response.content);
        }
    }

    const getAllorgTitle = async (name) => {
        const response = await getorgtitleList(1, 100, name);;
        if (response != null) {
            setorgTitleList(response.content);
        }
    }

    const getAllbankList = async (name) => {
        const response = await getbankList(1, 100, name);;
        if (response != null) {
            setbankList(response.content);
        }
    }

    const getAllareaList = async () => {
        // const response = await getareaList(1, 100,);

        // if (response != null) {
        //     setareaList(response.content);
        // }
        const response = await getProvincesList(1, 100);
        if (response != null) {
            debugger;
            setareaList(response.content);
        }
    }


    const getAllorgList = async () => {
        const response = await getorgList(1, 100,);

        if (response != null) {
            setorgList(response.content);
        }
    }
    const changeSwitchLeader = () => {
        setLeader(!isLeader);
        if (isLeader == false) {
            setViewdata(true)
        }
    }
    const changeisView = () => {
        setViewdata(!isViewdata);
    }
    const changeisadmin = () => {
        setAdmin(!isAdmin);
    }
    const OnchangeChecked = (index) => {
        setmoduleList((moduleList) =>
            moduleList.map((item) =>
                item.id === moduleList[index].id ? { ...item, check: !moduleList[index].check } : item
            ));
    }
    const OnchangeCheckedgroup = (index) => {
        setgroupModuleList((groupModuleList) =>
            groupModuleList.map((item) =>
                item.id === groupModuleList[index].id ? { ...item, check: !groupModuleList[index].check } : item
            ));
    }
    const onDeleteLimit = (index) => {
        const updatedItems = [...limitList];
        updatedItems.splice(index, 1);
        setlimitList(updatedItems);
    }
    const onDeleteAgent = (index) => {
        const updatedItems = [...dealersList];
        updatedItems.splice(index, 1);
        setdealersList(updatedItems);
    }
    const onDeleteProduct = (index) => {
        const updatedItems = [...productList];
        updatedItems.splice(index, 1);
        setproductList(updatedItems);
    }
    useEffect(() => {

        getAllmoduleList();
        getAllgroupModule();
        getAllorgTitle();
        getAllareaList();
        getAllorgList();
        getAllbankList();
        _InitData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitForm = async (event) => {

        const lstId = moduleList?.filter((a) => a.check).map((item) => item.id);
        const lstgroupId = groupModuleList?.filter((a) => a.check).map((item) => item.id);
        const lstlimitId = limitList?.map((item) => item.id);
        const lstdealersId = dealersList?.map((item) => item.id);
        const lstproductsId = productList?.map((item) => item.id);
        // event.groupIds = lstgroupId;
        // event.moduleIds = lstId;
        // event.orgLead = isLeader;
        // event.orgSupervisor = isViewdata;
        // event.dealerIds = 1233;
        // event.isAdmin = isAdmin;
        // event.image = fileAvatar;
        // event.productIds = lstproductsId;
        // event.dealerIds = lstdealersId;
        // event.loanLimitIds = lstlimitId;
        // event.areaId = parseInt(event.areaId)
        // event.orgId = parseInt(event.orgId )
        // event.orgTitle = parseInt(event.orgTitle )

        const formData = new FormData();
        // formData.append('areaId', event.areaId)
        if (event.bankAccountName)
            formData.append('bankAccountName', event.bankAccountName)
        if (event.bankAccountNumber)
            formData.append('bankAccountNumber', event.bankAccountNumber)
        if (event.bankId) {
            formData.append('bankId', parseInt(event.bankId))
        }
        if (event.status) {
            formData.append('status', parseInt(event.status))
        }
        formData.append('dealerIds', lstdealersId ?? [])
        formData.append('description', event.description)
        formData.append('areaId', event.areaId)
        formData.append('email', event.email)
        formData.append('fullName', event.fullName)
        formData.append('groupIds', lstgroupId ?? [])
        formData.append('loanLimitIds', lstlimitId ?? [])
        formData.append('moduleIds', lstId ?? [])
        formData.append('orgLead', isLeader ? 1 : 0)
        formData.append('isAdmin', isAdmin ? 1 : 0)
        formData.append('orgSupervisor', isViewdata ? 1 : 0)
        formData.append('orgTitle', parseInt(event.orgTitle))
        formData.append('password', event.password)
        formData.append('phoneNumber', event.phoneNumber)
        formData.append('productIds', lstproductsId ?? [])
        formData.append('staffCode', event.staffCode)
        formData.append('username', event.username)
        if (fileAvatar) {
            formData.append('image', fileAvatar);

        }
        formData.append('orgId', parseInt(event.orgId))
        debugger;
        if (id) {
            event.userId = id;
            formData.append('userId', id)

            const respone = await EditUser(formData);
            if (respone.code === '200') {
                notify(notificationAlert, SUCCESS, 'Chỉnh sửa người dùng thành công.')
                // navigate('/admin/user');
            } else {
                notify(notificationAlert, ERROR, respone.message)
            }
        } else {
            const respone = await createNewUser(formData);
            if (respone.code === '200') {
                notify(notificationAlert, SUCCESS, 'Thêm mới người dùng thành công.')
                navigate('/admin/user');
            } else {
                notify(notificationAlert, ERROR, respone.message)
            }
        }
    };
    const setInputVal = (event) => {

        setData({ ...data, [event.target.name]: event.target.value });
    }

    const scrollToContent = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const fileInputRef = useRef();
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setfileAvatar(file)
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setfileImg(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleBrowseClick = () => {
        fileInputRef.current.click(); // Trigger the click event of the hidden file input
    };

    const handleScroll = (event) => {
        let scrollFrom = event.target.getBoundingClientRect().y;
        let scrollTo = [...event.target.firstChild.children].filter(e => {
            let eRect = e.getBoundingClientRect();
            return (eRect.y + eRect.height / 2) > scrollFrom;
        }).sort((a, b) => {
            let aRect = a.getBoundingClientRect();
            let bRect = b.getBoundingClientRect();
            return (aRect.y + aRect.height / 2) - (bRect.y + bRect.height / 2);
        });
        let index = [...event.target.firstChild.children].indexOf(scrollTo[0]);
        if (index !== scrollIndex) setScroll(index);
    }

    const clickScrollTo = (event) => {
        let index = [...event.target.parentElement.children].indexOf(event.target);
        let scrollElement = document.querySelector(`.wbaseItem-value[class*="5e84800a-fa74-4776-a146-f94ba5f2b773"]`);
        scrollElement.scrollTo({
            top: scrollElement.firstChild.children[index].offsetTop,
            behavior: "smooth",
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off' className="w-frame w-form wbaseItem-value w-col d2252dc0-e647-40a4-aa76-55ec06600a9f" level={2} cateid={128}>
            <NotificationAlert ref={notificationAlert} />
            {
                isCancel === false &&
                <WPopup
                    type={PopupType.WARNING}
                    title={!id ? 'Hủy thêm mới người dùng' : 'Hủy chỉnh sửa người dùng'}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => onCancel(true)}
                    cancelAction={() => onCancel(null)}
                    cancelButton={true}
                    cancelTitle='Giữ lại'
                    message={'Những thông tin vừa bạn vừa nhập sẽ không được lưu lại'}
                />
            }
            {
                showPopupLimit &&
                <PopupSelectHanmuc
                    code={limitList}
                    callback_action={
                        function (lstLimit) {
                            setlimitList(lstLimit)
                            setPopupLimit(false)
                        }
                    }
                    onClose={function () {
                        setPopupLimit(false)
                    }}
                />
            }
            {
                showPopupAgent &&
                <PopupSelectAgent
                    code={dealersList}
                    callback_action={
                        function (dealersList) {
                            setdealersList(dealersList)
                            setPopupAgent(false)
                        }
                    }
                    onClose={function () {
                        setPopupAgent(false)
                    }}
                />
            }
            {
                showPopupProduct &&
                <PopupSelectProduct
                    code={productList}
                    callback_action={
                        function (dealersList) {
                            setproductList(dealersList)
                            setPopupProduct(false)
                        }
                    }
                    onClose={function () {
                        setPopupProduct(false)
                    }}
                />
            }
            <div className="w-frame wbaseItem-value w-col 795a2ade-6baa-44a8-b3e5-a9213019177a" level={3} cateid={140}>
                <div className="w-frame wbaseItem-value w-row ad29caa7-a94a-4fe9-944a-8dde459e8284" level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col 8600343d-6462-40af-856a-3bbf97867a85" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row ad1e5980-a6c8-4455-87c5-d8588869974a" level={6} cateid={140}>
                            <div className="w-text wbaseItem-value a8d58b8f-c3fd-4caf-8cec-4bce84889658" level={7} cateid={139}>
                                {id ? 'Chỉnh sửa thông tin người dùng hệ thống' : 'Thêm mới người dùng hệ thống'}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row breadcrumb 803e7b28-cce3-4d11-9e4b-81afbe93b4b4" level={6} cateid={140}>
                            <button type="button" className="w-button wbaseItem-value w-row 69e574d8-7ea9-4bd3-b0aa-a19e32788052" name-field="Button" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value 2eb05000-ebfb-4431-a66a-11627398f179" name-field="Text" level={8} cateid={139}>Quản lý người dùng hệ thống</div>
                                <div className="w-svg wbaseItem-value 850c9e2f-3dbc-4bfc-b07f-8e9a8d7776bf" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                            <div className="w-text wbaseItem-value a2a92a9e-0a9b-4741-a4e8-d07b2e0bff9b" level={7} cateid={139}>
                                {id ? 'Chỉnh sửa' : 'Thêm mới'}</div>
                        </div>
                    </div>
                    {/* <div className="w-frame wbaseItem-value w-row 0ef46e31-4af4-4b4b-9b9a-64eec3133efb" level={5} cateid={140}>
                        <div className="w-text wbaseItem-value 54368f73-0d68-477e-810b-98623942a257" level={6} cateid={139}>Đang
                            hoạt động</div>
                        <label htmlFor="input-a6e97769-2e1a-4888-8045-a63e94925bc2" className="w-switch wbaseItem-value a6e97769-2e1a-4888-8045-a63e94925bc2" value="true" name-field="ControlStatus" level={6} cateid={81}>
                            <input type="checkbox" disabled checked={data?.status === 1 ? true : false} id="input-a6e97769-2e1a-4888-8045-a63e94925bc2" name="ControlStatus" />
                            <span className="slider">
                            </span>
                        </label>
                    </div> */}
                </div>
                <div className="w-frame wbaseItem-value w-col eef47bed-52fd-43a0-abdd-9b605e0d38b7" level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row b609857d-fa09-4bc6-8ae7-4d6160ca44b5" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col 5f32b365-1f5f-4bd6-8ea6-dd85a9436ddf" level={6} cateid={140}>
                            {["Thông tin chung", "Tài khoản", "Nhóm quyền", "Quyền", "Hạn mức", "Đại lý", "Sản phẩm"].map((title, index) =>
                                <button key={`btn-${index}`} type='button' onClick={clickScrollTo} className={"w-button wbaseItem-value w-row 94d4da85-a45b-4ec9-89f1-dc8cbbbd2cc3" + (scrollIndex === index ? " selected-mini-sidebar-option" : "")} level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 4b6c130c-9423-4e4d-bdcb-23cad4d70d57" level={8} cateid={139}>{title}</div>
                                </button>)}
                        </div>
                        <div onScroll={handleScroll} className="w-frame wbaseItem-value w-col 5e84800a-fa74-4776-a146-f94ba5f2b773" scroll="true" level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col 80e9c491-bf4b-430b-bb79-a1ac92fea1bf" level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col 362d74d6-1ecb-40bb-a50d-58420a0490d9" level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 685900b1-b11e-45bc-80ca-2efa31e64214" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value a497858e-2333-43a9-8e82-878b9393f5c4" level={10} cateid={139}>Thông tin chung</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 53d53a87-96d8-4184-9ca3-f07f6fd08dde" level={9} cateid={140}>
                                        <div className="w-rect wbaseItem-value 6c5a0a59-1339-4291-b9b2-70413bce76c1" style={{ order: 0 }} level={10} cateid={138}>
                                            <img src={fileImg} alt='' />
                                        </div>

                                        <input
                                            {...register("imageProfile")}
                                            type="file"
                                            name='imageProfile'
                                            placeholder='Tải ảnh lên'
                                            className="w-button wbaseItem-value w-row 048f37f4-3754-433e-a810-b27ee519f8d2"
                                            wrap="nowrap"
                                            name-field="Button"
                                            level={10}
                                            cateid={29}
                                            style={{ display: "none" }}
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                        />

                                        <div
                                            onClick={handleBrowseClick}
                                            className='nav-link w-button wbaseItem-value w-row 048f37f4-3754-433e-a810-b27ee519f8d2'>
                                            <div className="w-svg wbaseItem-value b77c8d2c-435b-4b57-bf0f-5c6c035ca28d" level={11} cateid={115}>
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7 8.83333L12 3L17 8.83333H12.8333V16.3333H11.1667V8.83333H7Z" fill="#28282999">
                                                    </path>
                                                    <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#28282999">
                                                    </path>
                                                </svg>
                                            </div>
                                            <div className="w-text wbaseItem-value e6d7d81e-044b-4c01-a317-0ea7a0e746a1" name-field="Text" level={11} cateid={139}>Tải ảnh lên</div>

                                        </div>
                                        {/* <div className="w-text wbaseItem-value e6d7d81e-044b-4c01-a317-0ea7a0e746a1" name-field="Text" level={11} cateid={139}>{fileAvatar?.name}</div> */}
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 8e00996b-8c11-4866-b75c-d8ad64519f8e" wrap="wrap" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 303bb059-9c36-41da-a86c-036fbac3bddd" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6456431d-807f-4b00-9f3b-9e0bb7d73c4b" level={12} cateid={139}>Họ và tên</div>
                                                <div className="w-text wbaseItem-value 3fff4b86-7df6-4c0a-9751-30d88146f1b8" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row f6920f59-0dd6-4da4-bc86-0c1a77141167 " + (errors.fullName && ' helper-text')} helper-text={errors.fullName && 'Vui lòng nhập họ và tên'} level={11} cateid={85} placeholder="Nhập họ và tên">
                                                <div className="wbaseItem-value a2d6ba06-c61a-46f5-855e-8ebb925bd706" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={50} {...register("fullName", {
                                                            required: true, onBlur: (ev) => {
                                                                ev.target.value = ev.target.value.trim();
                                                                document.querySelector(".userName-fullName").value = getValues("username") + "-" + ev.target.value;
                                                            }
                                                        })} name="fullName" placeholder="Nhập họ và tên" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c729a445-fdf5-464b-aac5-08d9b7c5650e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 46659001-49de-4e3a-b256-961540ab2cf5" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value f34de7ec-746a-4682-a8d8-2cedf0e92f82" level={12} cateid={139}>Tên đăng nhập</div>
                                                <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 41385f1b-a7ed-40c2-ae0c-37627fbd2cf0 " + (errors.username && ' helper-text')} helper-text={errors.username && 'Vui lòng nhập tên đăng nhập'} level={11} cateid={85} placeholder="Nhập tên đăng nhập">
                                                <div className="wbaseItem-value 00dc0846-a902-4631-9a29-2584c7f11969" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={20} name="username" {...register("username", {
                                                            required: true, onBlur: (ev) => {
                                                                ev.target.value = ev.target.value.trim();
                                                                document.querySelector(".userName-fullName").value = ev.target.value + "-" + getValues("fullName");
                                                            }
                                                        })} placeholder="Nhập tên đăng nhập" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 066194d9-d876-4aee-9378-e673b594070e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row d9ebfebb-51ec-4aa9-a0f4-30d65d8d334c" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 621bedc1-b67f-4eba-9361-064b2aa6f60d" level={12} cateid={139}>Số điện thoại</div>
                                                <div className="w-text wbaseItem-value df9612a0-c5fb-430c-8511-f59abd9d1039" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 273b6f61-d8ff-45b8-ac25-d8a2b47993b6 " + (errors.phoneNumber && ' helper-text')} helper-text={errors.phoneNumber && 'Vui lòng nhập số điện thoại'} name-field="PhoneNumber" level={11} cateid={85} placeholder="Nhập số điện thoại">
                                                <div className="wbaseItem-value bca63eff-847f-4591-9eee-7c44b4662dee" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={10} name="PhoneNumber" {...register("phoneNumber", {
                                                            required: true,
                                                            pattern: {
                                                                value: /^((\+84[1-9][0-9]{7}[0-9]{0,2})|(0[1-9][0-9]{8,9})|([1-9]([0-9]{7}|[0-9]{9})))$/,
                                                                message: 'Điện thoại không đúng định dạng',
                                                            },
                                                            onChange: (ev) => { ev.target.value = ev.target.value.replace(/\D/g, "") }
                                                        })} placeholder="Nhập số điện thoại" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c246b023-98b0-456e-8972-c0b962d0429f" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row b628a82a-7327-4458-ad76-bb6b97427e43" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value fa40384f-696e-486c-8f4d-714c85d75638" level={12} cateid={139}>Mã nhân viên</div>
                                                <div className="w-text wbaseItem-value a49c81c0-0cf7-45c1-b737-ae120e2b0c81" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row fcb7ea62-8898-4e82-8e2c-46a52340a33f " + (errors.staffCode && ' helper-text')} helper-text={errors.staffCode && 'Vui lòng nhập mã nhân viên'} name-field="EmployeeID" level={11} cateid={85} placeholder="Nhập mã nhân viên">
                                                <div className="wbaseItem-value 0efdac7a-9468-440b-83b8-9709693d637b" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={20} name="staffCode" {...register("staffCode", { required: true, onChange: (ev) => { ev.target.value = ev.target.value.replaceAll(" ", "") } })} placeholder="Nhập mã nhân viên" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c4933c4a-c094-4595-8cb9-3d6e96c03050" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row e9511926-d208-4129-8033-e3e90de29876" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 9f32aa1f-9bee-4da5-ab7c-a33d214fd999" level={12} cateid={139}>Email</div>
                                                <div className="w-text wbaseItem-value f00c6c6f-9ea4-4e72-af93-308c46280e32" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 6a2d4585-b654-4eb5-806f-fd2a244d648e " + (errors.email && ' helper-text')} helper-text={errors.email && errors.email.message} name-field="Email" level={11} cateid={85} placeholder="Nhập email">
                                                <div className="wbaseItem-value fdd8e725-bfa9-4e73-903f-fa77428ff62a" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={100} name="Email" {...register("email", {
                                                            required: 'Vui lòng nhập email',
                                                            pattern: {
                                                                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                                                message: 'Email không đúng định dạng',
                                                            },
                                                        })} placeholder="Nhập email" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 4dbb1795-28b5-4191-bfe5-d9f0d1009d99" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row e7dedea5-3d0e-43ca-86b9-fdc636530c9c" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 5455e896-536c-4f75-9940-a8ba2129423d" level={12} cateid={139}>Thuộc tổ chức</div>
                                                <div className="w-text wbaseItem-value 44f27e63-094b-41c9-afad-ae322fe10545" level={12} cateid={139}>*</div>
                                            </div>
                                            {/* <div className={"w-textformfield wbaseItem-value w-row 280be334-28f7-41d2-8a3b-b0b637eb4cc1 " + (errors.orgId && ' helper-text')} helper-text={errors.orgId && 'Vui lòng chọn tổ chức'} name-field="Organization" level={11} cateid={85} placeholder="Chọn tổ chức">
                                                <div className="wbaseItem-value 77099bbf-4016-4ad8-a6f7-00ae1706bc80" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-77099bbf-4016-4ad8-a6f7-00ae1706bc80" name="orgId" value={data?.orgInfo?.orgName} {...register("orgId", { required: true })} placeholder="Chọn tổ chức" />
                                                    </div>
                                                </div>
                                                <div className="w-svg wbaseItem-value 79be04b0-5b01-4400-bbde-5ac34eda673d" level={12} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z" fill="#28282999">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div> */}
                                            <div className={'select2-custom ' + (errors.orgId && 'helper-text')} helper-text={errors.orgId && 'Vui lòng chọn tổ chức'}>
                                                <Controller
                                                    name="orgId"
                                                    control={control}
                                                    {...register("orgId")}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select2 {...field}
                                                            options={{ placeholder: 'Chọn tổ chức' }}
                                                            data={orgList} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md ab2b5f8b-beef-457a-82f9-1c32fa9b0937" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 93378579-893b-42d1-885e-47a7daf22575" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 3aa75cb4-d516-4512-8a57-488a84e02845" level={12} cateid={139}>Chức danh</div>


                                                <div className="w-text wbaseItem-value 3d93a89f-9c73-4fe4-b006-1ff601c0da90" level={12} cateid={139}>*</div>
                                            </div>
                                            {/* <div className={"w-textformfield wbaseItem-value w-row f15cd44c-5bd2-4ae2-af18-335012af13d2 " + (errors.orgTitle && 'helper-text')} helper-text={errors.orgTitle && 'Vui lòng chọn chức danh'} name-field="Title" level={11} cateid={85} placeholder="Chọn chức danh">
                                                <div className="wbaseItem-value 51d90441-b486-4b2c-899d-7a63822c3f37" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-51d90441-b486-4b2c-899d-7a63822c3f37" name="orgTitle" value={data?.orgTitle?.name} {...register("orgTitle", { required: true })} placeholder="Chọn chức danh" />
                                                    </div>
                                                </div>
                                                <div className="w-svg wbaseItem-value d3e01b74-6b2a-4fc8-acce-1043b67a6e1a" level={12} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z" fill="#28282999">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div> */}
                                            <div className={'select2-custom ' + (errors.orgTitle && 'helper-text')} helper-text={errors.orgTitle && 'Vui lòng chọn chức danh'}>
                                                <Controller
                                                    name="orgTitle"
                                                    control={control}
                                                    {...register("orgTitle")}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select2 {...field}
                                                            options={{ placeholder: 'Chọn chức danh' }}

                                                            data={orgTitleList} />
                                                    )}
                                                />
                                            </div>

                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 463b107b-5a9a-4f32-ab1d-c414a4b6fc6b" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 8f81731a-767f-45a6-9514-b6637601963f" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6b5a32fd-a9f2-41a8-9334-f3b14a1506f7" level={12} cateid={139}>Khu vực</div>
                                                <div className="w-text wbaseItem-value c7bf7acf-b51d-49f4-91dd-2a513cd68184" level={12} cateid={139}>*</div>
                                            </div>
                                            {/* <div className={"w-textformfield wbaseItem-value w-row 1e326350-a61e-4ff3-8ecd-d65ecfba7bc5 " + (errors.areaId && "helper-text")} helper-text={errors.areaId && 'Vui lòng chọn khu vực'} name-field="Position" level={11} cateid={85} placeholder="Chọn khu vực">
                                                <div className="wbaseItem-value 743633d1-9951-4946-9310-f90f2785f956" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-743633d1-9951-4946-9310-f90f2785f956" name="areaId" value={data?.areaInfo?.areaName} {...register("areaId", { required: true })} placeholder="Chọn khu vực" />
                                                    </div>
                                                </div>
                                                <div className="w-svg wbaseItem-value 52066362-c02a-455f-9ba4-79057c7c1d99" level={12} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z" fill="#28282999">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div> */}
                                            <div className={'select2-custom ' + (errors.areaId && 'helper-text')} helper-text={errors.areaId && 'Vui lòng chọn khu vực'}>
                                                <Controller
                                                    name="areaId"
                                                    {...register("areaId")}
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select2 {...field} options={{ placeholder: 'Chọn khu vực' }}
                                                            data={areaList.map((a, _) => { return { id: a.code, name: a.name } })} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 00134998-6925-4788-8420-076328f168a5" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row 6ac84289-acb3-4274-819b-ee8371f76a61" level={10} cateid={140}>
                                            <label onClick={() => changeSwitchLeader()} htmlFor="input-572b3d8d-32fd-4f70-8c60-83113b1dadcf" className="w-switch wbaseItem-value 572b3d8d-32fd-4f70-8c60-83113b1dadcf" value="true" name-field="Leader" level={11} cateid={81}>
                                                <input type="checkbox" checked={isLeader ? true : false} disabled id="input-572b3d8d-32fd-4f70-8c60-83113b1dadcf" name="isOcgLead" />
                                                <span className="slider">
                                                </span>
                                            </label>
                                            <div className="w-text wbaseItem-value 120fafde-018b-4799-8209-cf33be81d8d1" level={11} cateid={139}>Là trưởng bộ phận</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 46c032bc-2702-411e-8d82-0428a3378958" level={10} cateid={140}>
                                            <label onClick={() => changeisView()} htmlFor="input-ead4b9b8-068b-4cbd-81cd-fa69240f3747" className="w-switch wbaseItem-value ead4b9b8-068b-4cbd-81cd-fa69240f3747" value="true" name-field="Role" level={11} cateid={81}>
                                                <input type="checkbox" disabled checked={isViewdata ? true : false} id="input-ead4b9b8-068b-4cbd-81cd-fa69240f3747" name="isOrgSupervisor" />
                                                <span className="slider">
                                                </span>
                                            </label>
                                            <div className="w-text wbaseItem-value dfed25fd-9bde-4eac-b456-516785c0c4fa" level={11} cateid={139}>Có quyền xem dữ liệu toàn bộ tổ chức</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 46c032bc-2702-411e-8d82-0428a3378958" level={10} cateid={140}>
                                            <label onClick={() => changeisadmin()} htmlFor="input-ead4b9b8-068b-4cbd-81cd-fa69240f3747" className="w-switch wbaseItem-value ead4b9b8-068b-4cbd-81cd-fa69240f3747" value="true" name-field="Role" level={11} cateid={81}>
                                                <input type="checkbox" disabled checked={isAdmin ? true : false} id="input-ead4b9b8-068b-4cbd-81cd-fa69240f3747" name="isAdmin" />
                                                <span className="slider">
                                                </span>
                                            </label>
                                            <div className="w-text wbaseItem-value dfed25fd-9bde-4eac-b456-516785c0c4fa" level={11} cateid={139}>là Admin</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 46c032bc-2702-411e-8d82-0428a3378958" level={10} cateid={140}>
                                            {/* <label onClick={() => changeisadmin()} htmlFor="input-ead4b9b8-068b-4cbd-81cd-fa69240f3747" className="w-switch wbaseItem-value ead4b9b8-068b-4cbd-81cd-fa69240f3747" value="true" name-field="Role" level={11} cateid={81}>
                                                <input type="checkbox" disabled checked={isAdmin ? true : false} id="input-ead4b9b8-068b-4cbd-81cd-fa69240f3747" name="isAdmin" />
                                                <span className="slider">
                                                </span>
                                            </label>
                                            <div className="w-text wbaseItem-value dfed25fd-9bde-4eac-b456-516785c0c4fa" level={11} cateid={139}>là Admin</div> */}
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 5dd7e22c-dea6-4e12-b61c-56fa7ed57b13" wrap="wrap" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c729a445-fdf5-464b-aac5-08d9b7c5650e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 46659001-49de-4e3a-b256-961540ab2cf5" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value f34de7ec-746a-4682-a8d8-2cedf0e92f82" level={12} cateid={139}>Mật khẩu</div>
                                                <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 41385f1b-a7ed-40c2-ae0c-37627fbd2cf0 " + (errors.password && ' helper-text')} helper-text={errors.password && 'Vui lòng nhập mật khẩu'} name-field="Username" level={11} cateid={85} placeholder="Nhập mật khẩu">
                                                <div className="wbaseItem-value 00dc0846-a902-4631-9a29-2584c7f11969" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={50} type='password' name="password" {...register("password", { required: true })} placeholder="Nhập mật khẩu" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Trạng thái hoạt động</div>
                                            </div>
                                            {/* <div className="w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a" name-field="Textformfield" level={11} cateid={85} placeholder="Chọn trạng thái hoạt động">
                                                <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-4bc29d32-f178-4161-b6b4-773502e35a80" name="status" {...register("status")} placeholder="Chọn trạng thái hoạt động" />
                                                    </div>
                                                </div>
                                                <div className="w-svg wbaseItem-value 3d41c63e-7415-4352-982c-de4cbcccf174" level={12} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z" fill="#28282999">
                                                        </path>
                                                    </svg>
                                                </div>

                                            </div> */}
                                            <div className={'select2-custom '}>
                                                <Controller
                                                    name="status"
                                                    {...register("status")}
                                                    
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    // rules={{ required: true }}
                                                    // helper-text={errors.orgId && 'Vui lòng chọn tổ chức'}
                                                    render={({ field }) => (
                                                        <Select2 {...field} disabled options={{ placeholder: 'Chọn trạng thái' }}
                                                            data={[{ id: 1, name: 'Active' }, { id: 0, name: 'DeActive' }]} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Alias</div>
                                            </div>
                                            <div className="w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a" name-field="Textformfield" level={11} cateid={85} placeholder="Nhập tên tài khoản">
                                                <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input placeholder="[Tên đăng nhập]-[Họ và tên]" className='userName-fullName' />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {!id ? null : <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div style={{ order: 0 }} className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Ngày đổi mật khẩu gần nhất</div>

                                            </div>
                                            <div className="w-textformfield wbaseItem-value w-row" level={9} cateid={85} id="54e5d651-53d5-43d6-b61d-ffa9b01c9bd3" style={{ backgroundColor: "#f7f7f7" }}>
                                                <div className="wbaseItem-value" level={10} cateid={86} id="d37be69e-c37b-4962-9031-b8ea9938843d" type="date">
                                                    <div className="textfield">
                                                        <input disabled value={data?.lastDayChangePassword ? new Date(data?.lastDayChangePassword).toLocaleDateString('en-GB') : ''} name="lastDayChangePassword" />
                                                    </div>
                                                    <div className="suffix-btn-txtfd calendar-icon"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                                        <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                        <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                        <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                        <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                        <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                        <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                        <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                        <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                    </svg></div>
                                                </div>
                                            </div>
                                        </div>}

                                        {/* {
                                            !id ? null : <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1b0e8e3f-b6dc-44cc-a670-fa569ff480d1" level={10} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-row 7df52930-6064-4fb1-a35b-1f8ef550dda1" level={11} cateid={140}>
                                                    <div className="w-text wbaseItem-value 4b8c9eb7-e98f-4f5b-ac59-2156886a8a7d" level={12} cateid={139}>Ngày đổi mật khẩu gần nhất</div>
                                                </div>
                                                <div className="w-textformfield wbaseItem-value w-row 31791366-a5c4-43f1-9909-215ddcc58b6c" name-field="ChangeDate" level={11} cateid={85} placeholder>
                                                    <div className="wbaseItem-value 237479f8-444d-4b94-bf11-9dddfcbae097" level={12} cateid={86}>
                                                        <div className="textfield">
                                                            <input id="textfield-237479f8-444d-4b94-bf11-9dddfcbae097" name="ChangeDate" placeholder />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            !id ? null :
                                                <button type="button" className="w-button wbaseItem-value w-row col- col6-xxl col6-xl col12-lg col24-md ac5ec015-206c-4dbb-9cab-89c543fa5aed" name-field="Button" level={10} cateid={29}>
                                                    <div className="w-text wbaseItem-value 681868a2-ffec-4cb8-9bbe-7167aa413ff5" name-field="Text" level={11} cateid={139}>Reset mật khẩu<br />
                                                    </div>
                                                </button>
                                        } */}
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 5dd7e22c-dea6-4e12-b61c-56fa7ed57b13" wrap="wrap" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- note-form-container" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 46659001-49de-4e3a-b256-961540ab2cf5" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value f34de7ec-746a-4682-a8d8-2cedf0e92f82" level={12} cateid={139}>Ghi chú</div>
                                            </div>
                                            <textarea maxLength={255} {...register("description")}
                                                className="note-form"
                                                rows={3}
                                                // value={data?.description}
                                                name='description'
                                                placeholder="Nhập văn bản..."
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col 34ad728f-9eee-42f0-9645-6fe6f5f3c2c8" level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 1a8621e1-6b9f-4de1-9d7c-aa75f74a71b3" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 2743937a-9d86-445b-ab90-fd44775c165f" level={10} cateid={139}>Tài khoản</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 5dd7e22c-dea6-4e12-b61c-56fa7ed57b13" wrap="wrap" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c729a445-fdf5-464b-aac5-08d9b7c5650e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 46659001-49de-4e3a-b256-961540ab2cf5" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value f34de7ec-746a-4682-a8d8-2cedf0e92f82" level={12} cateid={139}>Số tài khoản</div>
                                                {/* <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div> */}
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 41385f1b-a7ed-40c2-ae0c-37627fbd2cf0 "} name-field="Username" level={11} cateid={85} placeholder="Nhập số tài khoản">
                                                <div className="wbaseItem-value 00dc0846-a902-4631-9a29-2584c7f11969" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-00dc0846-a902-4631-9a29-2584c7f11969" name="bankAccountNumber" {...register("bankAccountNumber")} placeholder="Nhập số tài khoản" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Tên tài khoản</div>

                                            </div>
                                            <div className="w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a" name-field="Textformfield" level={11} cateid={85} placeholder="Nhập tên tài khoản">
                                                <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-4bc29d32-f178-4161-b6b4-773502e35a80" name="bankAccountName" {...register("bankAccountName")} placeholder="Nhập tên tài khoản" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Ngân hàng</div>

                                            </div>
                                            {/* <div className="w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a" name-field="Textformfield" level={11} cateid={85} placeholder="Chọn Ngân hàng">
                                                <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-4bc29d32-f178-4161-b6b4-773502e35a80" name="bankId" {...register("bankId")} placeholder="Chọn Ngân hàng" />
                                                    </div>
                                                </div>
                                                <div className="w-svg wbaseItem-value 3d41c63e-7415-4352-982c-de4cbcccf174" level={12} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z" fill="#28282999">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div> */}
                                            <div className={'select2-custom '}>

                                                <Controller
                                                    name="bankId"
                                                    {...register("bankId")}
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    // rules={{ required: true }}
                                                    // helper-text={errors.orgId && 'Vui lòng chọn tổ chức'}
                                                    render={({ field }) => (
                                                        <Select2 {...field} options={{ placeholder: 'Chọn ngân hàng' }}
                                                            data={bankList} />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        {/* {
                                            !id ? null : <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1b0e8e3f-b6dc-44cc-a670-fa569ff480d1" level={10} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-row 7df52930-6064-4fb1-a35b-1f8ef550dda1" level={11} cateid={140}>
                                                    <div className="w-text wbaseItem-value 4b8c9eb7-e98f-4f5b-ac59-2156886a8a7d" level={12} cateid={139}>Ngày đổi mật khẩu gần nhất</div>
                                                </div>
                                                <div className="w-textformfield wbaseItem-value w-row 31791366-a5c4-43f1-9909-215ddcc58b6c" name-field="ChangeDate" level={11} cateid={85} placeholder>
                                                    <div className="wbaseItem-value 237479f8-444d-4b94-bf11-9dddfcbae097" level={12} cateid={86}>
                                                        <div className="textfield">
                                                            <input id="textfield-237479f8-444d-4b94-bf11-9dddfcbae097" name="ChangeDate" placeholder />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            !id ? null :
                                                <button type="button" className="w-button wbaseItem-value w-row col- col6-xxl col6-xl col12-lg col24-md ac5ec015-206c-4dbb-9cab-89c543fa5aed" name-field="Button" level={10} cateid={29}>
                                                    <div className="w-text wbaseItem-value 681868a2-ffec-4cb8-9bbe-7167aa413ff5" name-field="Text" level={11} cateid={139}>Reset mật khẩu<br />
                                                    </div>
                                                </button>
                                        } */}
                                    </div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col 4020760b-84ef-4b85-8bb3-e788ded3d299" level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row a5b10dd5-be02-459e-8ca9-d2c1eac23a9b" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 4d7b6dd2-4b71-4688-9223-394c75555b3a" level={10} cateid={139}>Nhóm quyền</div>
                                    </div>
                                    <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                                        <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>
                                            <table className="w-table wbaseItem-value b4130bfc-5ed4-4a69-a0a7-e467c2e51acd" type={0} level={9} cateid={117}>
                                                <tbody>
                                                    <tr className="table-row">
                                                        <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 05c53c57-2e26-46a2-a157-4e230d860992" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 4847c833-d7af-4fb0-b9df-346aab7a2a87" level={11} cateid={139}>STT</div>
                                                            </div>
                                                        </td>
                                                        <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 3075f97b-e808-446d-876f-20726601c73f" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value a4f4771b-15cf-43e7-b097-515accbd067d" level={11} cateid={139}>Mã nhóm quyền</div>
                                                            </div>
                                                        </td>
                                                        <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 40083d3d-40ff-469b-9bcd-bdedc106f73a" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value da77403d-1020-4cde-ad85-26bd2227ec89" level={11} cateid={139}>Tên nhóm quyền</div>
                                                            </div>
                                                        </td>
                                                        <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 2cf0f346-5df2-4f01-b6b2-f590a3b39578" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value c4f87ca8-722b-402a-818a-f0ce5ba15b2b" level={11} cateid={139}>Chọn</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        groupModuleList.map((item, index) => {
                                                            return (<tr className="table-row">
                                                                <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                                    <div className="w-frame wbaseItem-value w-row d8da84bc-fed2-40c9-9e83-a39395d69054" level={10} cateid={140}>
                                                                        <div className="w-text wbaseItem-value e983534b-d6ae-4cb9-b002-859a13a36c8d" level={11} cateid={139}>{index + 1}</div>
                                                                    </div>
                                                                </td>
                                                                <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                                    <div className="w-frame wbaseItem-value w-row 94f11329-391b-4246-989b-9569c50458ad" level={10} cateid={140}>
                                                                        <div className="w-text wbaseItem-value cfc8fff8-ed9d-4e21-acbb-b1f4a3f2a9c1" level={11} cateid={139}>{item.groupCode}</div>
                                                                    </div>
                                                                </td>
                                                                <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                                    <div className="w-frame wbaseItem-value w-row 1422e989-d889-4971-8e01-037e96cdca01" level={10} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 3ee92184-53a9-45b5-8ddb-eabeb8dda7a3" level={11} cateid={139}>{item.groupName}</div>
                                                                    </div>
                                                                </td>
                                                                <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                                    <div className="w-frame wbaseItem-value w-row f133066c-2090-49a5-8203-593c1801a71f" level={10} cateid={140}>
                                                                        <label onClick={() => OnchangeCheckedgroup(index)} className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                                            <input type="checkbox" defaultChecked={item.check ? true : false} name="Pick" />
                                                                            <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                            </tr>)
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col 1bdb358d-51c4-4012-912f-3074ce833527" level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row e5e66494-c4d0-4971-9e96-69303f64cdfb" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 8d73e60d-692b-4ada-ad75-f802bf1437bc" level={10} cateid={139}>Quyền</div>
                                    </div>
                                    <table className="w-table wbaseItem-value 57481e74-6cc4-46c7-8ef9-7940be03049b" type={0} level={9} cateid={117}>
                                        <tbody>
                                            <tr className="table-row">
                                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 833d1086-744f-4e5b-bce6-8a23fb416c16" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 6401335e-c0cd-4161-9c44-2361b14ced54" level={11} cateid={139}>STT</div>
                                                    </div>
                                                </td>
                                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row c98fcf6d-8bd5-4c10-aa89-b18ad0cd05c1" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value f35c4d36-9c5d-4b24-9bf0-bdf353d12cee" level={11} cateid={139}>App</div>
                                                    </div>
                                                </td>
                                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 8125c8f0-5bdc-46c0-a2d0-81574895ac4f" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value c76117f7-b1c2-4874-8a27-53d9f3da9d5d" level={11} cateid={139}>Feature code</div>
                                                    </div>
                                                </td>
                                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Chức năng</div>
                                                    </div>
                                                </td>
                                                <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 0975bd8f-79a6-4fa0-90fd-a91edd730be8" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 4a31279c-f933-4412-9f5e-c1f4d6823ddc" level={11} cateid={139}>Chọn</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                moduleList.map((item, index) => {
                                                    return (<tr key={item.id} className="table-row">
                                                        <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 214917be-544a-42cb-adf6-71b0c475192b" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value f5157e3e-2efd-449e-abfb-6f8365d654ba" level={11} cateid={139}>{index + 1}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{item.workSpace}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 38132c92-0034-4609-aba9-ebe04081b688" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 064662ca-e57d-411f-914c-dfe947b7043b" level={11} cateid={139}>{item.moduleCode}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 2e5ee414-5c2e-4fd6-9d1a-df751f426721" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 05f829f1-b942-4b43-a7be-2a6167d653c8" level={11} cateid={139}>{item.moduleName}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 35b1bfd5-04ac-412e-ac69-a88954aa0d51" level={10} cateid={140}>
                                                                <label className="w-check-box wbaseItem-value a82a857e-1521-49ee-b58b-41e30a4e0ee5" level={11} cateid={79}>
                                                                    <input onChange={() => OnchangeChecked(index)} type="checkbox" defaultChecked={item.check} name="View" />
                                                                    <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-frame wbaseItem-value w-col 707c8a0a-64e3-4f3b-8de9-f051185c882a" level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 80cab5fe-d641-4d90-9a27-4ec2cf1cf61f" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value fe7b84f2-fb6d-4707-b841-252c212ab7e0" level={10} cateid={139}>Hạn mức</div>
                                        <div className="w-frame wbaseItem-value w-row 3cd63587-ea54-451d-adb3-f2c0b3d0faac" level={10} cateid={140}>
                                            {/* <div className="w-textformfield wbaseItem-value w-row d8334888-49c8-4720-a30a-06410186ead6" level={11} cateid={85} placeholder="Tìm hạn mức">
                                                <div className="w-svg wbaseItem-value 7661abc5-9f04-4fb7-a63c-54c650642372" level={12} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#28282999">
                                                        </path>
                                                    </svg>
                                                </div>
                                                <div className="wbaseItem-value 69adf84c-195c-4e13-bac9-c9cc43f8f7b5" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-69adf84c-195c-4e13-bac9-c9cc43f8f7b5" placeholder="Tìm hạn mức" />
                                                    </div>
                                                </div>
                                                <div className="w-svg wbaseItem-value 48880528-06a6-4ae8-ae18-1c6458453043" level={12} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z" fill="#28282999">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <button type="button" className="w-button wbaseItem-value w-row 9291e88d-33b0-4c3d-b3e3-595aa90b4524" name-field="Button" level={11} cateid={29}>
                                                <div className="w-text wbaseItem-value 9c36457b-4721-48a6-adc3-853a96aadcda" name-field="Text" level={12} cateid={139}>Thêm<br />
                                                </div>
                                            </button> */}
                                            <button type="button" onClick={() => setPopupLimit(true)}
                                                className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                                                name-field="Button" level={7} cateid={29}>
                                                <div className="w-svg wbaseItem-value a3e0f6f2-4d22-4977-ab6f-90d0568e534f" level={8}
                                                    cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                                            fill="#FFFFFFFF" />
                                                    </svg>
                                                </div>
                                                <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3"
                                                    name-field="Text" level={8} cateid={139}>Thêm</div>
                                            </button>
                                        </div>
                                    </div>
                                    <table className="w-table wbaseItem-value acdb17f7-7298-4da8-9589-dbe9ebc03d2d" type={0} level={9} cateid={117}>
                                        <tbody>
                                            <tr className="table-row">
                                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 98a29077-b149-4be9-90f6-10a9cbd1ddca" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 659f6468-fe90-4dce-bc21-bb612eccd123" level={11} cateid={139}>STT</div>
                                                    </div>
                                                </td>
                                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row f9e32d7a-e4cc-4326-9119-392e96344559" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value bc9e8f64-c88f-4d95-baca-cf39b20eb113" level={11} cateid={139}>Mã hạn mức</div>
                                                    </div>
                                                </td>
                                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row ae22ea9e-077b-432c-bc1c-a9a57538c9fd" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 87de7788-7f34-42fb-8b78-df6a4710564d" level={11} cateid={139}>Tên hạn mức</div>
                                                    </div>
                                                </td>
                                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 5116fc61-435f-4195-a91d-55c670d3e7f2" level={10} cateid={140}>
                                                    </div>
                                                </td>
                                            </tr>
                                            {limitList.map((item, index) => {
                                                return <tr key={item.id} className="table-row">
                                                    <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 27a0c60c-9b7a-43ec-a713-a3d89589da97" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 1fb7132e-3bf7-424b-b832-8f0f73847d52" level={11} cateid={139}>{index + 1}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row d5e0c5cf-02b9-42d5-a909-2959f22b7200" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value d56fe0b4-c9b1-4cf8-9fef-704fe17c8929" level={11} cateid={139}>{item.code}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row abe4b1fc-38a3-4c48-80f0-fe1e5bc2c05d" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value b728612a-b2fd-4a35-8ec6-1c98652a252c" level={11} cateid={139}>{item.name}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 61a3e312-f25a-4ac9-94ea-d8fb9ccfc65c" level={10} cateid={140}>
                                                            <div onClick={() => onDeleteLimit(index)} className="w-svg wbaseItem-value 35b283d3-1bcd-42e0-b314-c75a02d7e19f" level={11} cateid={115}>
                                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                                    </path>
                                                                    <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            })}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-frame wbaseItem-value w-col 826aba44-1ea9-483c-b9b0-677dadbc8288" level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 2e393b3c-659b-46f9-9213-57f67c453bc4" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 5d74da7a-1579-4cc3-a895-8afb6aa077cd" level={10} cateid={139}>Đại lý</div>
                                        <div className="w-frame wbaseItem-value w-row 46fb0a4b-b188-4e68-a22a-40c88ff8dc71" level={10} cateid={140}>
                                            {/* <div className="w-textformfield wbaseItem-value w-row 8608de10-3a0b-460b-bd02-7daa7e330fbc" level={11} cateid={85} placeholder="Tìm lại đại lý">
                                                <div className="w-svg wbaseItem-value 4ebb371d-8444-4a25-880c-7da3b4c28bb4" level={12} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#28282999">
                                                        </path>
                                                    </svg>
                                                </div>
                                                <div className="wbaseItem-value 7e29fb6d-0f32-4410-a911-845cc65b4ee6" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-7e29fb6d-0f32-4410-a911-845cc65b4ee6" placeholder="Tìm lại đại lý" />
                                                    </div>
                                                </div>
                                                <div className="w-svg wbaseItem-value a81c6a34-548a-48ba-b769-779f53349a77" level={12} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z" fill="#28282999">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div> */}
                                            {/* <div className="w-svg wbaseItem-value 2592c61a-086e-4228-91d1-092fb8b35d29" level={6} cateid={115}>
                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                    fill="#FFFFFFFF" />
                            </svg>
                        </div>
                                            <button type="button" className="w-button wbaseItem-value w-row 82ad7d6e-d836-4a98-8832-de0125ce221c" name-field="Button" level={11} cateid={29}>
                                                <div className="w-text wbaseItem-value b837fe33-f7f5-4345-bb0c-a38f5b797632" name-field="Text" level={12} cateid={139}>Thêm<br />
                                                </div>
                                            </button> */}
                                            <button type="button" onClick={() => setPopupAgent(true)}
                                                className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                                                name-field="Button" level={7} cateid={29}>
                                                <div className="w-svg wbaseItem-value a3e0f6f2-4d22-4977-ab6f-90d0568e534f" level={8}
                                                    cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                                            fill="#FFFFFFFF" />
                                                    </svg>
                                                </div>
                                                <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3"
                                                    name-field="Text" level={8} cateid={139}>Thêm</div>
                                            </button>
                                        </div>
                                    </div>
                                    <table className="w-table wbaseItem-value 32b8f7e8-5237-4d18-8d8c-9f6362b09c55" type={0} level={9} cateid={117}>
                                        <tbody>
                                            <tr className="table-row">
                                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 4a106c3d-ff27-483d-8d0e-6b28c3da8e64" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 846a0d51-af18-4e96-b04f-4937b5d8e314" level={11} cateid={139}>STT</div>
                                                    </div>
                                                </td>
                                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row f9392ba7-8529-406b-8674-ce379efbaf08" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 3fd7dce1-2887-42e8-8207-0610daa627d6" level={11} cateid={139}>Mã đại lý</div>
                                                    </div>
                                                </td>
                                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row bd519971-215b-4976-98e1-8661c34d3d47" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value dffdde43-be90-40a7-8dc8-2b193cdeb80b" level={11} cateid={139}>Tên đại lý</div>
                                                    </div>
                                                </td>
                                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 4fe398fc-aca2-42db-814b-c2529492b826" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value f6bd29e0-ef19-41bb-bef7-476af7347b1b" level={11} cateid={139}>Địa chỉ</div>
                                                    </div>
                                                </td>
                                                <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row b93dd6c3-d37a-440b-8c0e-407d8f650b47" level={10} cateid={140}>
                                                    </div>
                                                </td>
                                            </tr>
                                            {dealersList.map((item, index) => {
                                                return <tr key={item.id} className="table-row">
                                                    <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row c43f3880-4310-427a-ba07-f6e122f7651c" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value ae20dc1e-4423-4b02-a019-b20f4c1d6e57" level={11} cateid={139}>{index + 1}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row d0df66d2-d204-42fd-974f-e544f34f080a" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value be667f07-64e3-42bc-8847-652f44a9cbd3" level={11} cateid={139}>{item.code}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row cf8fcd2d-1493-4ae7-8c71-497893931378" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value f237436e-5a8d-473c-a009-dcc35a9a53c7" level={11} cateid={139}>{item.name}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 0a499110-76ad-4639-8812-384b690dad3b" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 9e878c94-2ec9-4842-9c68-744270fc5a26" level={11} cateid={139}>89, Hung Vuong, Binh Thanh, TP. Ho Chi Minh
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row f39af48d-1e41-41ee-b2d0-de37d7a8fffb" level={10} cateid={140}>
                                                            <div onClick={() => onDeleteAgent(index)} className="w-svg wbaseItem-value 3d5d2356-bf4e-4d4c-a639-5ca33740a4f5" level={11} cateid={115}>
                                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                                    </path>
                                                                    <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-frame wbaseItem-value w-col 08e6c444-4949-44bd-8cc0-d66a3e063aa8" level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 11e00671-0000-4cc4-bb05-2dd99f1d6890" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 6a4f7e21-ecb7-41ce-ad50-3ce57621a8ca" level={10} cateid={139}>Sản phẩm</div>
                                        <div className="w-frame wbaseItem-value w-row b4193382-88a8-41bb-ac2d-611213f39d30" level={10} cateid={140}>
                                            {/* <div className="w-textformfield wbaseItem-value w-row d7799a6e-3287-4a7c-8b14-3ac4ae5cc926" level={11} cateid={85} placeholder="Tìm sản phẩm">
                                                <div className="w-svg wbaseItem-value bf5a5c88-32f3-49a3-ad1f-bc8bb2fa7a4e" level={12} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#28282999">
                                                        </path>
                                                    </svg>
                                                </div>
                                                <div className="wbaseItem-value d44f3727-8136-45b3-9f60-544ec3e0f65f" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-d44f3727-8136-45b3-9f60-544ec3e0f65f" placeholder="Tìm sản phẩm" />
                                                    </div>
                                                </div>
                                                <div className="w-svg wbaseItem-value 7f930f0d-2e13-4813-a939-6e997985095f" level={12} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z" fill="#28282999">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <button type="button" className="w-button wbaseItem-value w-row 37125194-0e33-4156-bf3b-5823f7d1d206" name-field="Button" level={11} cateid={29}>
                                                <div className="w-text wbaseItem-value a5da147d-9c1c-49b1-9e5b-80453f510586" name-field="Text" level={12} cateid={139}>Thêm<br />
                                                </div>
                                            </button> */}
                                            <button onClick={() => setPopupProduct(true)} type="button"
                                                className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                                                name-field="Button" level={7} cateid={29}>
                                                <div className="w-svg wbaseItem-value a3e0f6f2-4d22-4977-ab6f-90d0568e534f" level={8}
                                                    cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                                            fill="#FFFFFFFF" />
                                                    </svg>
                                                </div>
                                                <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3"
                                                    name-field="Text" level={8} cateid={139}>Thêm</div>
                                            </button>
                                        </div>
                                    </div>
                                    <table className="w-table wbaseItem-value 2473318e-6f20-49c4-a40c-f9a49dadf37e" type={0} level={9} cateid={117}>
                                        <tbody>
                                            <tr className="table-row">
                                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 0e9b0217-068c-47ae-9da8-132a032ffc67" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 1406ac0b-6034-4bfe-ba3e-2db85df1bea7" level={11} cateid={139}>STT</div>
                                                    </div>
                                                </td>
                                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 3cfcfc81-8ff7-4364-8cf0-e4b4e8226fce" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 0105ce6c-dce0-4d2d-836a-9d5372b12a6e" level={11} cateid={139}>Mã sản phẩm</div>
                                                    </div>
                                                </td>
                                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 799ebc1f-8df4-4d0b-be79-e263634e828f" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 30e91bfa-280d-445c-ad14-98c56bbf5e09" level={11} cateid={139}>Tên sản phẩm</div>
                                                    </div>
                                                </td>
                                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 631cac04-9b8e-4faf-b2af-b320caeeda6a" level={10} cateid={140}>
                                                    </div>
                                                </td>
                                            </tr>
                                            {productList.map((item, index) => {
                                                return <tr key={item.id} className="table-row">
                                                    <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 9b6315f2-7837-41de-8b9a-c3496b3f095b" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 52232709-9d0d-4b8e-b5a2-57d5ad6052ad" level={11} cateid={139}>{index + 1}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 94bdd991-9b56-4b89-8362-b97a104953e0" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 67231142-5678-4634-90d1-8eb2aeacfbbf" level={11} cateid={139}>{item.code}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 0aa692f6-7ab4-4d10-a53c-0faf7a437328" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 55605472-cde9-48f9-b0e4-ff1a54b9a028" level={11} cateid={139}>{item.name}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 355493e9-31f0-4c3a-8539-6799f39afcd7" level={10} cateid={140}>
                                                            <div onClick={() => onDeleteProduct(index)} className="w-svg wbaseItem-value 35c465ae-37f9-4a41-8b0c-5dc3446d1314" level={11} cateid={115}>
                                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                                    </path>
                                                                    <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                                {
                                    !id ? null : <div className="w-frame wbaseItem-value w-col 626e9d18-582b-465d-8eb2-45f4cc7f0ae4" level={8} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row 3319e5e4-02f1-40d5-87fe-73153c181d98" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value 900f5c9e-2d75-4fa6-9cf3-b3eaea695116" level={10} cateid={139}>Lịch sử</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 11118142-662b-4709-a44b-5a2cd91a48a8" wrap="wrap" level={9} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl e276a3d2-65d8-4c1c-b518-d02ef3889d20" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value 68b71902-cd86-4180-9336-6c4fc65395f2" level={11} cateid={139}>Người tạo:</div>
                                                <div className="w-text wbaseItem-value 2f808d9c-b47e-48ca-8133-6d67588a7315" level={11} cateid={139}>{data?.createdBy}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 7588ad57-7567-43fe-8a9f-49cfaa1172bb" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value 28129997-3b4e-432b-88a1-a80b26bbae9d" level={11} cateid={139}>Ngày tạo:</div>
                                                <div className="w-text wbaseItem-value a82a9d49-6425-4d05-8ba2-7c67c0e48f96" level={11} cateid={139}>{data?.createdDate}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl 4be20552-4cee-4471-b9dd-b5ab48f0fcc6" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value bb8eaed1-2c37-484b-83ed-c9734e37df31" level={11} cateid={139}>Người thay đổi cuối:</div>
                                                <div className="w-text wbaseItem-value b9981858-2cd7-44f9-ab7d-bdfd81ff035d" level={11} cateid={139}>{data?.modifiedBy}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 40e66f79-36e0-4978-b62e-aedd5daeaf5d" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value a176e037-444e-4c7e-9dcd-678813be183a" level={11} cateid={139}>Ngày thay đổi cuối:</div>
                                                <div className="w-text wbaseItem-value b25cb0a6-a796-4253-a37f-091689218413" level={11} cateid={139}>{data?.modifiedDate}</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-frame wbaseItem-value w-row de68687b-95f6-4c9f-a6e9-31f6165ced53" level={3} cateid={140}>
                <button onClick={() => onCancel(false)} type="button" className="w-button wbaseItem-value w-row 624030d1-8e78-4a06-acef-f3df67b08e8b" name-field="Button" level={4} cateid={29}>
                    <div className="w-text wbaseItem-value fec51101-55a6-46c8-91b0-d00b44ded0b5" name-field="Text" level={5} cateid={139}>Thoát</div>
                </button>
                {/* <div className="w-frame wbaseItem-value w-row e7817577-026d-4088-b330-1e29b66d873b" level={4} cateid={140}>
                    <button type="submit" className="w-button wbaseItem-value w-row ecfdb3a7-19e7-4a28-8b4d-670404f2c6c8" name-field="Button" level={5} cateid={29}>
                        <div className="w-svg wbaseItem-value 2592c61a-086e-4228-91d1-092fb8b35d29" level={6} cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.7337 5.26627C22.0888 5.62129 22.0888 6.19689 21.7337 6.55192L9.91555 18.3701C9.56053 18.7251 8.98493 18.7251 8.6299 18.3701L2.26627 12.0065C1.91124 11.6514 1.91124 11.0758 2.26627 10.7208C2.62129 10.3658 3.19689 10.3658 3.55192 10.7208L9.27273 16.4416L20.4481 5.26627C20.8031 4.91124 21.3787 4.91124 21.7337 5.26627Z" fill="#FFFFFFFF">
                                </path>
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value 4820f7f2-d5f5-4a4b-8ad6-1eaa19c3334e" name-field="Text" level={6} cateid={139}>Lưu</div>
                    </button>
                </div> */}
                <div className="w-frame wbaseItem-value w-row e7817577-026d-4088-b330-1e29b66d873b" level={4} cateid={140}>
                    <button type="submit" className="w-button wbaseItem-value w-row ecfdb3a7-19e7-4a28-8b4d-670404f2c6c8" name-field="Button" level={5} cateid={29}>
                        <div className="w-svg wbaseItem-value 2592c61a-086e-4228-91d1-092fb8b35d29" level={6} cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.7337 5.26627C22.0888 5.62129 22.0888 6.19689 21.7337 6.55192L9.91555 18.3701C9.56053 18.7251 8.98493 18.7251 8.6299 18.3701L2.26627 12.0065C1.91124 11.6514 1.91124 11.0758 2.26627 10.7208C2.62129 10.3658 3.19689 10.3658 3.55192 10.7208L9.27273 16.4416L20.4481 5.26627C20.8031 4.91124 21.3787 4.91124 21.7337 5.26627Z" fill="#FFFFFFFF">
                                </path>
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value 4820f7f2-d5f5-4a4b-8ad6-1eaa19c3334e" name-field="Text" level={6} cateid={139}>Lưu</div>
                    </button>
                </div>
            </div>
        </form>


    )
}