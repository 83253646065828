import '../../css/xemHoSo-HopDongTinDung.css';

export default function XemHoSoHopDongTinDung({ hoso }) {
    return (
        <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={4} cateid={140} id="773232cd-aa39-4ab9-b069-55c5e20f2689">
            <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={5} cateid={140} id="a9e5b8dc-ddb2-4410-af34-fba42b5e0411">
                <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={6} cateid={140} id="4a12318b-ea25-4915-95b4-58e5b821e76a">
                    <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={7} cateid={140} id="3c02ee8f-a663-4f37-a9a8-35615e3bf231">
                        <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={8} cateid={140} id="a63859e4-e053-4bab-a027-2ce4c25984d7">
                            <div className="w-text wbaseItem-value" level={9} cateid={139} id="3f3ba411-e87c-40e0-878c-3cd0e6879830">Thông tin hợp đồng</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={8} cateid={140} id="88a5f7d2-e331-4094-a462-58346cb76ebd">
                            <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="8cb44def-f3d3-4c6f-a54d-d7ce4c5a346f">
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="32494370-7819-4b01-8b2d-a139a41a5234">Số CIF:</div>
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="24f856e2-5fa5-46d0-814c-e21ee9fc0070">
                                    {hoso.khachHang.cif ?? "_"}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="a6ca44e1-9f98-4cb4-8d27-3ca59a164e81">
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="aec6ed0a-4903-433f-85dc-b45617ca8036">Số hợp đồng:</div>
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="f12bb50c-7177-4cb4-9d88-59a11a611011">
                                    {hoso.idHopDong ?? "_"}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="c7e02a02-69eb-4d43-b29c-731fb5a32ea3">
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="c02e9b9b-4b53-4f4d-a4df-f0b69ade4f61">Họ tên KH:</div>
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="b6b8fb8b-8ba6-4739-8e94-50bb2c56c468">
                                    {hoso.khachHang.hoTen ?? "_"}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="726c8870-b02d-4cab-9fde-9d4445513d80">
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="8439e243-4281-484a-9ff1-9424d6bfc6c3">Số khung:</div>
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="53581f4b-eed0-4754-b940-d164afbc758e">
                                    {hoso.soKhung ?? "_"}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="b12fca99-91dd-4a87-835b-2fdb34fb4309">
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="70cd93c9-042c-45fb-b7ef-45f39e47185f">Số máy:</div>
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="beff6817-3b56-4838-8fe2-2c126aa58b08">
                                    {hoso.soMay ?? "_"}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="87d82007-ec54-417f-b874-e08fa375bb49">
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="c1b0b212-3457-4de1-8ac7-3a8d56b5be0c">Màu sắc:</div>
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="246806ae-7f93-47ae-84bc-94a1f6669b79">
                                    {hoso.mauSac ?? "_"}
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={9} cateid={140} id="53ce1601-c6d9-48c3-abae-02c2044d135f">
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="48bd4cff-592b-4dac-9c86-9a95e78bcd60">Ngày thanh toán hàng tháng:</div>
                                <div className="w-text wbaseItem-value" level={10} cateid={139} id="e99fe07a-e466-4f38-9dfc-a01f5c6b546c">
                                    {hoso.ngayThanhToanHangThang ?? "_"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-frame w-stack wbaseItem-value" level={6} cateid={140} id="22b5f0b1-2a86-48ab-94a9-316daace5e2e" />
            </div>
        </div>
    );
}