import { ChangeStatusdealer, Createdealer, Prioritydealer, StatusUpdatedealer, Updatedealer, baseurl, getalldealer,disbursementtypedealer,brankdealer,branchdealer, Systemdealer } from "../../../../config/ConfigAPI";
import { getData, postData, putData } from "../../../../core/BaseDA";

export const getListDealer = async (page, size, obj) => {
    const url = `${baseurl}${getalldealer}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
    const response = await postData(url, {obj: obj});
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getPriorityDealer = async (page, size) => {
    const url = `${baseurl}${Prioritydealer}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
    const response = await getData(url,);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const getStatusUpdateDealer = async (page, size) => {
    const url = `${baseurl}${StatusUpdatedealer}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
    const response = await getData(url,);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const getdisbursementtypeDealer = async (page, size) => {
    
    const url = `${baseurl}${disbursementtypedealer}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
    const response = await getData(url,);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const getbrankdealer = async () => {
    const url = `${baseurl}${brankdealer}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const getbranchdealer = async (name) => {
    const url = `${baseurl}${branchdealer}?tctdName=${name}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const getsystemealer = async (page, size) => {
    const url = `${baseurl}${Systemdealer}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const UpdateDealer = async (obj,id) => {
    const url = `${baseurl}${Updatedealer}/${id}`;
    const response = await putData(url, {obj: obj});    
    return response;
};
export const UpdateStatusDealer = async (obj) => {
    const url = `${baseurl}${ChangeStatusdealer}`;
    const response = await putData(url, {obj: obj});    
    return response;
};

export const createDealer = async (obj) => {
    const url = `${baseurl}${Createdealer}`;
    const response = await postData(url, {obj: obj});    
    return response;
};