import '../../css/user/adduser.css';
import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';
import { EditUser, createNewUser, getUserbyId } from '../../redux/middleware/api/quanlynguoidung/userDA';
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import NotificationAlert from "react-notification-alert";
import { PopupType, WPopup } from '../../components/Popup/Popup';
import { getProvincesList, getUsergroupList, getbankList, getbproductList, getbrandList, getdistrictsList, getorgList, getwardsList, } from '../../redux/middleware/api/Filter/Filter';
import Select2 from '../../components/select2/Select2';
import { PopupSelectAgent, PopupSelectHanmuc, PopupSelectProduct, PopupSelectloan } from '../CongViecCuaToi/Popup';
import { UpdateDealer, UpdateStatusDealer, createDealer, getListDealer, getPriorityDealer, getbranchdealer, getbrankdealer, getdisbursementtypeDealer, getsystemealer } from '../../redux/middleware/api/daily/dailyDA';
import { getListModue } from '../../redux/middleware/api/nhomquyen/NhomQuyenDA';

export default function Themmoidaily() {
    const { register, handleSubmit, control, getValues, setValue, formState: { errors } } = useForm(
        {
            shouldFocusError: false
        }
    );
    const notificationAlert = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const [isCancel, setisCancel] = useState();
    let { id } = useParams();
    const [scrollIndex, setScroll] = useState(0);
    const [data, setData] = useState({});
    const [provincesList, setprovincesList] = useState([]);
    const [provinceCode, setprovinceCode] = useState();
    const [LoaigiainganList, setLoaigiainganList] = useState([]);
    const [priorityList, setpriorityList] = useState([]);
    const [districtList, setdistrictList] = useState([]);
    const [wardsList, setwardsList] = useState([]);
    const [orgList, setorgList] = useState([]);
    const [bankList, setbankList] = useState([]);
    const [branchList, setbranchList] = useState([]);
    const [productList, setproductList] = useState([]);
    const [SystemList, setSystemList] = useState([]);
    const [loanList, setloanList] = useState([]);
    const [SystemItem, setSystemItem] = useState();

    const [showPopupProduct, setPopupProduct] = useState(false);
    const onCancel = (val) => {
        setisCancel(val);
        if (val === true) {
            navigate('/admin/dai-ly');
        }
    }
    const _InitData = async () => {
        if (id) {
            var obj = {
                "dealerCode": id ?? "",

            }
            const res = await getListDealer(1, 1, obj);

            if (res != null) {
                debugger;
                setData(res.pageData[0]);
                const response = await getdistrictsList(1, 100, res.pageData[0]?.data.provinceCode);
                if (response != null) {
                    setdistrictList(response.content);
                    setValue("districtCode", res.pageData[0]?.data?.districtCode);

                }
                const response1 = await getwardsList(1, 100, res.pageData[0]?.data.districtCode);
                if (response1 != null) {
                    setwardsList(response1.content);
                    setValue("wardCode", res.pageData[0]?.data.wardCode);
                }

                // const response2 = await getwardsList(1, 100, res.pageData[0]?.data.districtCode);
                //                 if (response1 != null) {
                //                     setwardsList(response1.content);
                //                     setValue("wardCode", res.pageData[0]?.data.wardCode);
                //                 }
                const response2 = await getbranchdealer(res.pageData[0]?.data.bankCode);
                if (response2 != null) {

                    setbranchList(response2);
                    setValue("bankCode", res.pageData[0]?.data.bankCode);
                }
                debugger;
                setValue("dealerCode", res.pageData[0]?.data?.dealerCode);
                setValue("dealerName", res.pageData[0]?.data?.dealerName);
                setValue("note", res.pageData[0]?.data?.note);
                setValue("address", res.pageData[0]?.data?.address);
                setValue("bankBranch", res.pageData[0]?.data?.bankBranch);
                setValue("bankCode", res.pageData[0]?.data?.bankCode);
                setValue("beneficiaryName", res.pageData[0]?.data?.beneficiaryName);
                setValue("beneficiaryAccountNumber", res.pageData[0]?.data?.beneficiaryAccountNumber);
                setValue("bankProvince", res.pageData[0]?.data?.bankProvince);
                setValue("phoneNumber", res.pageData[0]?.data?.phoneNumber);
                setValue("disbursementType", res.pageData[0]?.data?.disbursementType);
                setValue("status", res.pageData[0]?.data?.status);
                setValue("groupOwnerCode", res.pageData[0]?.data?.groupOwnerCode);
                setValue("priority", res.pageData[0]?.data?.priority);
                setValue("productCode", res.pageData[0]?.data?.productCode);
                setValue("provinceCode", res.pageData[0]?.data?.provinceCode);
                setValue("statusUpdate", res.pageData[0]?.data?.statusUpdate);
                setValue("systemCode", res.pageData[0]?.data?.systemCode);
                setloanList(res.pageData[0]?.data?.losLoanProgramDetails)
            }
        }
    }
    const getAllorgList = async () => {
        const response = await getorgList(1, 100,);
        debugger
        if (response != null) {
            setorgList(response.content);
        }

    }
    const getAllSystem = async () => {
        const response = await getsystemealer(1, 100,);
        debugger
        if (response != null) {
            setSystemList(response);
        }

    }
    const getAllproduct = async () => {
        const response = await getbproductList(1, 100,);

        if (response != null) {
            setproductList(response.content);
        }
    }

    const getPriority = async () => {
        const response = await getPriorityDealer(1, 100);
        if (response != null) {
            setpriorityList(response.pageData);
        }
    }
    const getProvince = async () => {

        const response = await getProvincesList(1, 100);
        debugger;
        if (response != null) {

            setprovincesList(response.content);
        }
    }
    const onchangeCity = async (event) => {
        if (event.target.value && event.target.name == 'provinceCode') {
            setValue('provinceCode', event.target.value)
            const response = await getdistrictsList(1, 100, event.target.value);
            if (response != null) {
                setdistrictList(response.content);
            }
        }
    }
    const onchangeDistrict = async (event) => {
        if (event.target.value && event.target.name == 'districtCode') {
            setValue('districtCode', event.target.value)
            const response = await getwardsList(1, 100, event.target.value);
            if (response != null) {
                setwardsList(response.content);
            }
        }

    }
    const OnchangeChecked = (index) => {
        // setmoduleList((moduleList) =>
        //     moduleList.map((item) =>
        //         item.id === moduleList[index].id ? { ...item, check: !moduleList[index].check } : item
        //     ));
    }
    const OnchangeSwitch = async () => {
        var obj = {
            code: data.data?.dealerCode,
            status: data.data.status === 1 ? 0 : 1
        };
        const respone = await UpdateStatusDealer(obj);

        if (respone.code == '200') {
            setData(prevData => ({
                ...prevData,
                data: {
                    ...prevData.data,
                    status: prevData.data.status === 1 ? 0 : 1,
                },
            }));
            setValue('status', data.data?.status === 1 ? 0 : 1)

        } else {
            notify(notificationAlert, ERROR, respone.message)
        }
    }
    const onDeleteloan = (index) => {
        const updatedItems = [...loanList];
        updatedItems.splice(index, 1);
        setloanList(updatedItems);
    }
    const getAllbankList = async () => {
        const response = await getbrankdealer();
        if (response != null) {
            setbankList(response);
        }
    }
    const getAllLoaigiaingan = async (name) => {
        const response = await getdisbursementtypeDealer(1, 100, name);;
        if (response != null) {
            setLoaigiainganList(response.pageData);
        }
    }
    // const getAllbranchList = async (name) => {
    //     const response = await getbranchdealer(name);;
    //     if (response != null) {
    //         setbranchList(response.content);
    //     }
    // }
    const onchangebank = async (event) => {

        if (event.target.value && event.target.name == 'bankCode') {
            setValue('bankCode', event.target.value)
            const response = await getbranchdealer(event.target.value);
            debugger;
            if (response != null) {
                setbranchList(response);
            }
        }


    }
    const onchangebankbranch = async (event) => {
        if (event.target.value && event.target.name == 'bankBranch') {
            setValue('bankBranch', event.target.value)
            debugger;
            var branch = branchList.find((a) => a.id == event.target.value);
            setValue('bankProvince', branch?.provinceName)
            setprovinceCode(branch?.province)
        }


    }
    useEffect(() => {
        _Init()
    }, []);
    const _Init = async () => {
        await getAllLoaigiaingan()
        await getAllorgList();
        await getAllproduct();
        await getProvince()
        await getAllSystem()
        await getAllbankList()
        await getPriority()
        //  await getAllbranchList()
        await _InitData();
    }
    const onSubmitForm = async (event) => {
        // event.listLoanProgramCode = loanList.map((a,_)=> a.data.loanProgramCode);
        if (event.status) {
            event.status = parseInt(event.status)
        }
        debugger;
        if (id) {
            event.id = data?.data?.id;
            var obj = {
                data: event,
                listLoanProgramCode: loanList ? loanList?.map((a, _) => a.data.loanProgramCode) : []
            };
            const respone = await UpdateDealer(obj, data?.data?.id);
            if (respone.code === '200') {
                notify(notificationAlert, SUCCESS, 'Chỉnh sửa đại lý thành công.')
                navigate('/admin/dai-ly');
            } else {
                notify(notificationAlert, ERROR, respone.message)
            }
        } else {
            var obj = {
                data: event,
                listLoanProgramCode: loanList ? loanList?.map((a, _) => a.data.loanProgramCode) : []

            };

            const respone = await createDealer(obj);
            if (respone.code === '200') {
                notify(notificationAlert, SUCCESS, 'Thêm mới đại lý thành công.')
                navigate('/admin/dai-ly');
            } else {
                notify(notificationAlert, ERROR, respone.message)
            }
        }
    };
    const handleScroll = (event) => {
        let scrollFrom = event.target.getBoundingClientRect().y;
        let scrollTo = [...event.target.firstChild.children].filter(e => {
            let eRect = e.getBoundingClientRect();
            return (eRect.y + eRect.height / 2) > scrollFrom;
        }).sort((a, b) => {
            let aRect = a.getBoundingClientRect();
            let bRect = b.getBoundingClientRect();
            return (aRect.y + aRect.height / 2) - (bRect.y + bRect.height / 2);
        });
        let index = [...event.target.firstChild.children].indexOf(scrollTo[0]);
        if (index !== scrollIndex) setScroll(index);
    }

    const clickScrollTo = (event) => {
        let index = [...event.target.parentElement.children].indexOf(event.target);
        let scrollElement = document.querySelector(`.wbaseItem-value[class*="5e84800a-fa74-4776-a146-f94ba5f2b773"]`);
        scrollElement.scrollTo({
            top: scrollElement.firstChild.children[index].offsetTop,
            behavior: "smooth",
        });
    }
    return (
        <form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off' className="w-frame w-form wbaseItem-value w-col d2252dc0-e647-40a4-aa76-55ec06600a9f" level={2} cateid={128}>
            <NotificationAlert ref={notificationAlert} />
            {
                isCancel == false &&
                <WPopup
                    type={PopupType.WARNING}
                    title={!id ? 'Hủy thêm mới đại lý' : 'Hủy chỉnh sửa đại lý'}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => onCancel(true)}
                    cancelAction={() => onCancel(null)}
                    cancelButton={true}
                    cancelTitle='Giữ lại'
                    message={'Những thông tin vừa bạn vừa nhập sẽ không được lưu lại'}
                />
            }

            {
                showPopupProduct &&
                <PopupSelectloan
                    code={loanList}
                    callback_action={
                        function (loan) {
                            setloanList(loan)
                            setPopupProduct(false)
                        }
                    }
                    onClose={function () {
                        setPopupProduct(false)
                    }}
                />
            }
            <div className="w-frame wbaseItem-value w-col 795a2ade-6baa-44a8-b3e5-a9213019177a" level={3} cateid={140}>
                <div className="w-frame wbaseItem-value w-row ad29caa7-a94a-4fe9-944a-8dde459e8284" level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col 8600343d-6462-40af-856a-3bbf97867a85" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row ad1e5980-a6c8-4455-87c5-d8588869974a" level={6} cateid={140}>
                            <div className="w-text wbaseItem-value a8d58b8f-c3fd-4caf-8cec-4bce84889658" level={7} cateid={139}>
                                {id ? 'Chỉnh sửa thông tin đại lý hệ thống' : 'Thêm mới đại lý hệ thống'}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row breadcrumb 803e7b28-cce3-4d11-9e4b-81afbe93b4b4" level={6} cateid={140}>
                            <button type="button" className="w-button wbaseItem-value w-row 69e574d8-7ea9-4bd3-b0aa-a19e32788052" name-field="Button" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value 2eb05000-ebfb-4431-a66a-11627398f179" name-field="Text" level={8} cateid={139}>Quản lý đại lý hệ thống</div>
                                <div className="w-svg wbaseItem-value 850c9e2f-3dbc-4bfc-b07f-8e9a8d7776bf" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                            <div className="w-text wbaseItem-value a2a92a9e-0a9b-4741-a4e8-d07b2e0bff9b" level={7} cateid={139}>
                                {id ? 'Chỉnh sửa' : 'Thêm mới'}</div>
                        </div>
                    </div>
                    {id ? <div className="w-frame wbaseItem-value w-row 0ef46e31-4af4-4b4b-9b9a-64eec3133efb" level={5} cateid={140}>
                        <div className="w-text wbaseItem-value 54368f73-0d68-477e-810b-98623942a257" level={6} cateid={139}>{data?.data?.status === 1 ? 'Đang hoạt động' : 'Không hoạt động'}</div>
                        <label onClick={OnchangeSwitch} htmlFor="input-a6e97769-2e1a-4888-8045-a63e94925bc2" className="w-switch wbaseItem-value a6e97769-2e1a-4888-8045-a63e94925bc2" value="true" name-field="ControlStatus" level={6} cateid={81}>
                            <input type="checkbox" disabled checked={data?.data?.status === 1 ? true : false} id="input-a6e97769-2e1a-4888-8045-a63e94925bc2" name="ControlStatus" />
                            <span className="slider">
                            </span>
                        </label>
                    </div> : null}

                </div>
                <div className="w-frame wbaseItem-value w-col eef47bed-52fd-43a0-abdd-9b605e0d38b7" level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row b609857d-fa09-4bc6-8ae7-4d6160ca44b5" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col 5f32b365-1f5f-4bd6-8ea6-dd85a9436ddf" level={6} cateid={140}>
                            {
                                ["Thông tin chung", "Tài khoản thụ hưởng","Chương trình vay"].map((title, index) =>
                                    <button key={`btn-${index}`} type='button' onClick={clickScrollTo} className={"w-button wbaseItem-value w-row 94d4da85-a45b-4ec9-89f1-dc8cbbbd2cc3" + (scrollIndex === index ? " selected-mini-sidebar-option" : "")} level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 4b6c130c-9423-4e4d-bdcb-23cad4d70d57" level={8} cateid={139}>{title}</div>
                                    </button>)
                            }
                        </div>
                        <div onScroll={handleScroll} className="w-frame wbaseItem-value w-col 5e84800a-fa74-4776-a146-f94ba5f2b773" scroll="true" level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col 80e9c491-bf4b-430b-bb79-a1ac92fea1bf" level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col 362d74d6-1ecb-40bb-a50d-58420a0490d9" level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 1a8621e1-6b9f-4de1-9d7c-aa75f74a71b3" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 2743937a-9d86-445b-ab90-fd44775c165f" level={10} cateid={139}>Thông tin chung</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 8e00996b-8c11-4866-b75c-d8ad64519f8e" wrap="wrap" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 303bb059-9c36-41da-a86c-036fbac3bddd" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6456431d-807f-4b00-9f3b-9e0bb7d73c4b" level={12} cateid={139}>Mã đại lý</div>
                                                <div className="w-text wbaseItem-value 3fff4b86-7df6-4c0a-9751-30d88146f1b8" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row f6920f59-0dd6-4da4-bc86-0c1a77141167 " + (errors.dealerCode && ' helper-text')} helper-text={errors.dealerCode && 'Vui lòng nhập mã đại lý'} name-field="dealerCode" level={11} cateid={85} placeholder="Nhập mã đại lý">
                                                <div className="wbaseItem-value a2d6ba06-c61a-46f5-855e-8ebb925bd706" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input {...register("dealerCode", { required: true })} name="dealerCode" placeholder="Nhập mã đại lý" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 303bb059-9c36-41da-a86c-036fbac3bddd" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6456431d-807f-4b00-9f3b-9e0bb7d73c4b" level={12} cateid={139}>Tên đại lý</div>
                                                <div className="w-text wbaseItem-value 3fff4b86-7df6-4c0a-9751-30d88146f1b8" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row f6920f59-0dd6-4da4-bc86-0c1a77141167 " + (errors.dealerName && ' helper-text')} helper-text={errors.dealerName && 'Vui lòng nhập tên đại lý'} name-field="dealerCode" level={11} cateid={85} placeholder="Nhập tên đại lý">
                                                <div className="wbaseItem-value a2d6ba06-c61a-46f5-855e-8ebb925bd706" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input {...register("dealerName", { required: true })} name="dealerName" placeholder="Nhập tên đại lý" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 066194d9-d876-4aee-9378-e673b594070e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row d9ebfebb-51ec-4aa9-a0f4-30d65d8d334c" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 621bedc1-b67f-4eba-9361-064b2aa6f60d" level={12} cateid={139}>Số điện thoại</div>
                                                <div className="w-text wbaseItem-value df9612a0-c5fb-430c-8511-f59abd9d1039" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 273b6f61-d8ff-45b8-ac25-d8a2b47993b6 " + (errors.phoneNumber && ' helper-text')} helper-text={errors.phoneNumber && errors.phoneNumber.message} name-field="PhoneNumber" level={11} cateid={85} placeholder="Nhập số điện thoại">
                                                <div className="wbaseItem-value bca63eff-847f-4591-9eee-7c44b4662dee" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input name="phoneNumber" {...register("phoneNumber", {
                                                            required: 'Vui lòng nhập số điện thoại',
                                                            onChange: (ev) => { ev.target.value = ev.target.value.replace(/\D/, ""); },
                                                            pattern: {
                                                                value: /^((\+84[1-9][0-9]{7}[0-9]{0,2})|(0[1-9][0-9]{8,9})|([1-9]([0-9]{7}|[0-9]{9})))$/,
                                                                message: 'Số điện thoại không đúng định dạng',
                                                            }
                                                        })} placeholder="Nhập số điện thoại" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 066194d9-d876-4aee-9378-e673b594070e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row d9ebfebb-51ec-4aa9-a0f4-30d65d8d334c" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 621bedc1-b67f-4eba-9361-064b2aa6f60d" level={12} cateid={139}>Loại giải ngân</div>
                                                <div className="w-text wbaseItem-value df9612a0-c5fb-430c-8511-f59abd9d1039" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={'select2-custom ' + (errors.disbursementType && 'helper-text')} helper-text={errors.disbursementType && 'Vui lòng chọn loại giải ngân'}>
                                                <Controller
                                                    name="disbursementType"
                                                    control={control}
                                                    {...register("disbursementType")}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    helper-text={errors.disbursementType && 'Vui lòng chọn loại giải ngân'}
                                                    render={({ field }) => (
                                                        <Select2 {...field} isFirst={true} nameFirst={'Chọn loại giải ngân'}
                                                            data={LoaigiainganList.map((a) => { return { id: a.disbursementTypeCode, name: a.disbursementTypeName } })}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 066194d9-d876-4aee-9378-e673b594070e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row d9ebfebb-51ec-4aa9-a0f4-30d65d8d334c" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 621bedc1-b67f-4eba-9361-064b2aa6f60d" level={12} cateid={139}>Địa chỉ</div>
                                                <div className="w-text wbaseItem-value df9612a0-c5fb-430c-8511-f59abd9d1039" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 273b6f61-d8ff-45b8-ac25-d8a2b47993b6 " + (errors.address && ' helper-text')} helper-text={errors.address && 'Vui lòng nhập địa chỉ'} name-field="address" level={11} cateid={85} placeholder="Nhập địa chỉ">
                                                <div className="wbaseItem-value bca63eff-847f-4591-9eee-7c44b4662dee" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-bca63eff-847f-4591-9eee-7c44b4662dee" name="address" {...register("address", {
                                                            required: true,
                                                        })} placeholder="Nhập địa chỉ" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md ab2b5f8b-beef-457a-82f9-1c32fa9b0937" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 93378579-893b-42d1-885e-47a7daf22575" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 3aa75cb4-d516-4512-8a57-488a84e02845" level={12} cateid={139}>Tỉnh/thành phố</div>
                                                <div className="w-text wbaseItem-value 3d93a89f-9c73-4fe4-b006-1ff601c0da90" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={'select2-custom ' + (errors.provinceCode && 'helper-text')} helper-text={errors.provinceCode && 'Vui lòng chọn tỉnh thành'}>
                                                <Controller
                                                    name="provinceCode"
                                                    control={control}
                                                    {...register("provinceCode")}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select2 {...field} isFirst={true} nameFirst={'Chọn tỉnh thành'}
                                                            data={provincesList.map((item, _) => { return { id: item.code, name: item.name } })}

                                                            onChange={(e) => onchangeCity(e)}


                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 463b107b-5a9a-4f32-ab1d-c414a4b6fc6b" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 8f81731a-767f-45a6-9514-b6637601963f" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6b5a32fd-a9f2-41a8-9334-f3b14a1506f7" level={12} cateid={139}>Quận/Huyện</div>
                                                <div className="w-text wbaseItem-value c7bf7acf-b51d-49f4-91dd-2a513cd68184" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={'select2-custom ' + (errors.districtCode && 'helper-text')} helper-text={errors.districtCode && 'Vui lòng chọn quận huyện'}>
                                                <Controller
                                                    name="districtCode"
                                                    {...register("districtCode")}
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select2 {...field} isFirst={true} nameFirst={'Chọn quận huyện'}
                                                            data={districtList.map((item, _) => { return { id: item.code, name: item.name } })}
                                                            onChange={(e) => onchangeDistrict(e)}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 463b107b-5a9a-4f32-ab1d-c414a4b6fc6b" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 8f81731a-767f-45a6-9514-b6637601963f" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6b5a32fd-a9f2-41a8-9334-f3b14a1506f7" level={12} cateid={139}>Phường/xã</div>
                                                <div className="w-text wbaseItem-value c7bf7acf-b51d-49f4-91dd-2a513cd68184" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={'select2-custom ' + (errors.wardCode && 'helper-text')} helper-text={errors.wardCode && 'Vui lòng chọn phường xã'}>
                                                <Controller
                                                    name="wardCode"
                                                    {...register("wardCode")}
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select2 {...field} isFirst={true} nameFirst={'Chọn phường xã'}
                                                            data={wardsList.map((item, _) => { return { id: item.code, name: item.name } })} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 463b107b-5a9a-4f32-ab1d-c414a4b6fc6b" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 8f81731a-767f-45a6-9514-b6637601963f" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6b5a32fd-a9f2-41a8-9334-f3b14a1506f7" level={12} cateid={139}>Tổ chức quản lý</div>
                                                <div className="w-text wbaseItem-value c7bf7acf-b51d-49f4-91dd-2a513cd68184" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={'select2-custom ' + (errors.groupOwnerCode && 'helper-text')} helper-text={errors.groupOwnerCode && 'Vui lòng tổ chức quản lý'}>
                                                <Controller
                                                    name="groupOwnerCode"
                                                    {...register("groupOwnerCode")}
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select2 {...field} isFirst={true} nameFirst={'Chọn tổ chức quản lý'} data={orgList.map((a, _) => { return { id: a.id, name: a.code } })} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 463b107b-5a9a-4f32-ab1d-c414a4b6fc6b" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 8f81731a-767f-45a6-9514-b6637601963f" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6b5a32fd-a9f2-41a8-9334-f3b14a1506f7" level={12} cateid={139}>Độ ưu tiên</div>
                                                <div className="w-text wbaseItem-value c7bf7acf-b51d-49f4-91dd-2a513cd68184" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={'select2-custom ' + (errors.priority && 'helper-text')} helper-text={errors.priority && 'Vui lòng chọn độ ưu tiên'}>
                                                <Controller
                                                    name="priority"
                                                    {...register("priority")}
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select2 {...field} isFirst={true} nameFirst={'Chọn độ ưu tiên'}
                                                            data={priorityList.map((item, _) => { return { id: item.code, name: item.name } })}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 463b107b-5a9a-4f32-ab1d-c414a4b6fc6b" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 8f81731a-767f-45a6-9514-b6637601963f" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6b5a32fd-a9f2-41a8-9334-f3b14a1506f7" level={12} cateid={139}>Sản phẩm</div>
                                                <div className="w-text wbaseItem-value c7bf7acf-b51d-49f4-91dd-2a513cd68184" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={'select2-custom ' + (errors.productCode && 'helper-text')} helper-text={errors.productCode && 'Vui lòng chọn sản phẩm'}>
                                                <Controller
                                                    name="productCode"
                                                    {...register("productCode")}
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select2 {...field} isFirst={true} nameFirst={'Chọn sản phẩm'} data={productList.map((a, _) => { return { id: a.code, name: a.name } })} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 463b107b-5a9a-4f32-ab1d-c414a4b6fc6b" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 8f81731a-767f-45a6-9514-b6637601963f" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6b5a32fd-a9f2-41a8-9334-f3b14a1506f7" level={12} cateid={139}>Trạng thái</div>
                                                <div className="w-text wbaseItem-value c7bf7acf-b51d-49f4-91dd-2a513cd68184" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={'select2-custom ' + (errors.status && 'helper-text')} helper-text={errors.status && 'Vui lòng chọn trạng thái'}>
                                                <Controller
                                                    name="status"
                                                    {...register("status")}
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    // helper-text={errors.disbursementType && 'Vui lòng chọn tổ chức'}
                                                    render={({ field }) => (
                                                        <Select2 {...field} 
                                                        options={{placeholder: 'Chọn trạng thái'}}
                                                        data={[{ id: 1, name: 'Hoạt động' }, { id: 0, name: 'không hoạt động' }]} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 463b107b-5a9a-4f32-ab1d-c414a4b6fc6b" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 8f81731a-767f-45a6-9514-b6637601963f" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6b5a32fd-a9f2-41a8-9334-f3b14a1506f7" level={12} cateid={139}>Mã hệ thống</div>
                                                {/* <div className="w-text wbaseItem-value c7bf7acf-b51d-49f4-91dd-2a513cd68184" level={12} cateid={139}>*</div> */}
                                            </div>
                                            <div className={'select2-custom ' + (errors.systemCode && 'helper-text')} helper-text={errors.systemCode && 'Vui lòng chọn hệ thống'}>
                                                <Controller
                                                    name="systemCode"
                                                    {...register("systemCode")}
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    // helper-text={errors.disbursementType && 'Vui lòng chọn tổ chức'}
                                                    render={({ field }) => (
                                                        <Select2 {...field} 
                                                        options={{placeholder: 'Chọn hệ thống'}}
                                                        data={SystemList.map((a,_)=>{return {id: a.systemCode,name: a.systemCode}})}
                                                        onChange={(event)=>{
                                                            if(event.target.value){
                                                                var item = SystemList.find((a)=>a.systemCode === event.target.value);
                                                                setValue('systemName', item.systemName)
                                                            }
                                                        }}
                                                         />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 463b107b-5a9a-4f32-ab1d-c414a4b6fc6b" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 8f81731a-767f-45a6-9514-b6637601963f" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6b5a32fd-a9f2-41a8-9334-f3b14a1506f7" level={12} cateid={139}>Tên hệ thống</div>
                                                {/* <div className="w-text wbaseItem-value c7bf7acf-b51d-49f4-91dd-2a513cd68184" level={12} cateid={139}>*</div> */}
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row f6920f59-0dd6-4da4-bc86-0c1a77141167 "} name-field="dealerCode" level={11} cateid={85} placeholder="Tên hệ thống">
                                                <div className="wbaseItem-value a2d6ba06-c61a-46f5-855e-8ebb925bd706" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input {...register("systemName")} disabled name="systemName" placeholder="Tên hệ thống" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="w-frame wbaseItem-value w-col 34ad728f-9eee-42f0-9645-6fe6f5f3c2c8" level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 1a8621e1-6b9f-4de1-9d7c-aa75f74a71b3" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 2743937a-9d86-445b-ab90-fd44775c165f" level={10} cateid={139}>Tài khoản thụ hưởng</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 5dd7e22c-dea6-4e12-b61c-56fa7ed57b13" wrap="wrap" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c729a445-fdf5-464b-aac5-08d9b7c5650e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 46659001-49de-4e3a-b256-961540ab2cf5" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value f34de7ec-746a-4682-a8d8-2cedf0e92f82" level={12} cateid={139}>Tên chủ tài khoản</div>
                                                <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 41385f1b-a7ed-40c2-ae0c-37627fbd2cf0 " + (errors.beneficiaryName && 'helper-text')} helper-text={errors.beneficiaryName && 'Vui lòng nhập tên chủ tài khoản'} name-field="Username" level={11} cateid={85} placeholder="Nhập tên chủ tài khoản">
                                                <div className="wbaseItem-value 00dc0846-a902-4631-9a29-2584c7f11969" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-00dc0846-a902-4631-9a29-2584c7f11969" name="beneficiaryName" {...register("beneficiaryName", { required: true })} placeholder="Nhập tên chủ tài khoản" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Số tài khoản</div>
                                                <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div>


                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a " + (errors.beneficiaryAccountNumber && 'helper-text')} helper-text={errors.beneficiaryAccountNumber && 'Vui lòng nhập số tài khoản'} name-field="Textformfield" level={11} cateid={85} placeholder="Nhập số tài khoản">
                                                <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-4bc29d32-f178-4161-b6b4-773502e35a80" name="beneficiaryAccountNumber" type='number' {...register("beneficiaryAccountNumber", { required: true })} placeholder="Nhập số tài khoản" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Ngân hàng</div>
                                                <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={'select2-custom ' + (errors.bankCode && 'helper-text')} helper-text={errors.bankCode && 'Vui lòng chọn ngân hàng'}>
                                                <Controller
                                                    name="bankCode"
                                                    {...register("bankCode")}
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    // helper-text={errors.disbursementType && 'Vui lòng chọn tổ chức'}
                                                    render={({ field }) => (
                                                        <Select2 {...field} isFirst={true} nameFirst={'Chọn ngân hàng'}
                                                            data={bankList.map((a, _) => { return { id: a.tctdName, name: a.tctdFullName } })}
                                                            onChange={(e) => onchangebank(e)}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Chi nhánh</div>
                                                <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={'select2-custom ' + (errors.bankBranch && 'helper-text')} helper-text={errors.bankBranch && 'Vui lòng chọn chi nhánh'}>
                                                <Controller
                                                    name="bankBranch"
                                                    {...register("bankBranch")}
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select2 {...field} isFirst={true} nameFirst={'Chọn chi nhánh'}
                                                            data={branchList.map((a) => { return { id: a.id, name: a.branhName } })}
                                                            onChange={(e) => onchangebankbranch(e)}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Tỉnh thành của ngân hàng</div>
                                                <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div>
                                            </div>
                                            {/* <div className={'select2-custom ' + (errors.bankProvince && 'helper-text')} helper-text={errors.bankProvince && 'Vui lòng chọn tỉnh thành của ngân hàng'}>
                                                <Controller
                                                    name="bankProvince"
                                                    {...register("bankProvince")}
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select2 {...field} isFirst={true} nameFirst={'Chọn tỉnh thành của ngân hàng'}
                                                            data={provincesList.map((item, _) => { return { id: item.code, name: item.name } })}
                                                        />
                                                    )}
                                                />
                                            </div> */}
                                            <div className={"w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a " + (errors.bankProvince && 'helper-text')} helper-text={errors.bankProvince && 'Vui lòng chọn tỉnh thành của ngân hàng'} name-field="Textformfield" level={11} cateid={85} placeholder="Chọn tỉnh thành của ngân hàng">
                                                <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-4bc29d32-f178-4161-b6b4-773502e35a80" name="bankProvince" {...register("bankProvince", { required: true })} placeholder="Chọn tỉnh thành của ngân hàng" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Ghi chú</div>
                                                {/* <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div> */}


                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a " + (errors.note && 'helper-text')} helper-text={errors.note && 'Vui lòng nhập ghi chú'} name-field="Textformfield" level={11} cateid={85} placeholder="Nhập ghi chú">
                                                <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-4bc29d32-f178-4161-b6b4-773502e35a80" name="note" type='text' {...register("note")} placeholder="Nhập ghi chú" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col 707c8a0a-64e3-4f3b-8de9-f051185c882a" level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 80cab5fe-d641-4d90-9a27-4ec2cf1cf61f" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value fe7b84f2-fb6d-4707-b841-252c212ab7e0" level={10} cateid={139}>Chương trình vay</div>
                                        <div className="w-frame wbaseItem-value w-row 3cd63587-ea54-451d-adb3-f2c0b3d0faac" level={10} cateid={140}>
                                            <button type="button" onClick={() => setPopupProduct(true)}
                                                className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                                                name-field="Button" level={7} cateid={29}>
                                                <div className="w-svg wbaseItem-value a3e0f6f2-4d22-4977-ab6f-90d0568e534f" level={8}
                                                    cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                                            fill="#FFFFFFFF" />
                                                    </svg>
                                                </div>
                                                <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3"
                                                    name-field="Text" level={8} cateid={139}>Thêm mới</div>
                                            </button>
                                        </div>
                                    </div>
                                    <table className="w-table wbaseItem-value acdb17f7-7298-4da8-9589-dbe9ebc03d2d" type={0} level={9} cateid={117}>
                                        <tbody>
                                            <tr className="table-row">
                                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 98a29077-b149-4be9-90f6-10a9cbd1ddca" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 659f6468-fe90-4dce-bc21-bb612eccd123" level={11} cateid={139}>STT</div>
                                                    </div>
                                                </td>
                                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row f9e32d7a-e4cc-4326-9119-392e96344559" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value bc9e8f64-c88f-4d95-baca-cf39b20eb113" level={11} cateid={139}>Mã chương trình</div>
                                                    </div>
                                                </td>
                                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row ae22ea9e-077b-432c-bc1c-a9a57538c9fd" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 87de7788-7f34-42fb-8b78-df6a4710564d" level={11} cateid={139}>Tên chương trình</div>
                                                    </div>
                                                </td>
                                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 5116fc61-435f-4195-a91d-55c670d3e7f2" level={10} cateid={140}>
                                                        Hành động
                                                    </div>
                                                </td>
                                            </tr>
                                            {loanList?.map((item, index) => {
                                                return <tr key={item.data?.id} className="table-row">
                                                    <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 27a0c60c-9b7a-43ec-a713-a3d89589da97" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 1fb7132e-3bf7-424b-b832-8f0f73847d52" level={11} cateid={139}>{index + 1}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row d5e0c5cf-02b9-42d5-a909-2959f22b7200" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value d56fe0b4-c9b1-4cf8-9fef-704fe17c8929" level={11} cateid={139}>{item.data?.loanProgramCode}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row abe4b1fc-38a3-4c48-80f0-fe1e5bc2c05d" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value b728612a-b2fd-4a35-8ec6-1c98652a252c" level={11} cateid={139}>{item.data?.loanProgramName}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 61a3e312-f25a-4ac9-94ea-d8fb9ccfc65c" level={10} cateid={140}>
                                                            <div onClick={() => onDeleteloan(index)} className="w-svg wbaseItem-value 35b283d3-1bcd-42e0-b314-c75a02d7e19f" level={11} cateid={115}>
                                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                                    </path>
                                                                    <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            })}

                                        </tbody>
                                    </table>
                                </div>
                                {
                                    !id ? null : <div style={{ order: 4 }} className="w-frame wbaseItem-value w-col 626e9d18-582b-465d-8eb2-45f4cc7f0ae4" level={8} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row 3319e5e4-02f1-40d5-87fe-73153c181d98" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value 900f5c9e-2d75-4fa6-9cf3-b3eaea695116" level={10} cateid={139}>Lịch sử</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 11118142-662b-4709-a44b-5a2cd91a48a8" wrap="wrap" level={9} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl e276a3d2-65d8-4c1c-b518-d02ef3889d20" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value 68b71902-cd86-4180-9336-6c4fc65395f2" level={11} cateid={139}>Người tạo:</div>
                                                <div className="w-text wbaseItem-value 2f808d9c-b47e-48ca-8133-6d67588a7315" level={11} cateid={139}>{data?.data?.createdBy}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 7588ad57-7567-43fe-8a9f-49cfaa1172bb" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value 28129997-3b4e-432b-88a1-a80b26bbae9d" level={11} cateid={139}>Ngày tạo:</div>
                                                <div className="w-text wbaseItem-value a82a9d49-6425-4d05-8ba2-7c67c0e48f96" level={11} cateid={139}>{data?.data?.createdDate ? new Date(data?.data?.createdDate).toLocaleDateString('en-GB') : ''}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl 4be20552-4cee-4471-b9dd-b5ab48f0fcc6" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value bb8eaed1-2c37-484b-83ed-c9734e37df31" level={11} cateid={139}>Người cập nhật:</div>
                                                <div className="w-text wbaseItem-value b9981858-2cd7-44f9-ab7d-bdfd81ff035d" level={11} cateid={139}>{data?.data?.modifiedBy}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 40e66f79-36e0-4978-b62e-aedd5daeaf5d" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value a176e037-444e-4c7e-9dcd-678813be183a" level={11} cateid={139}>Ngày cập nhật:</div>
                                                <div className="w-text wbaseItem-value b25cb0a6-a796-4253-a37f-091689218413" level={11} cateid={139}>{data?.data?.modifiedDate ? new Date(data?.data?.modifiedDate).toLocaleDateString('en-GB') : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-frame wbaseItem-value w-row de68687b-95f6-4c9f-a6e9-31f6165ced53" level={3} cateid={140}>
                <button onClick={() => onCancel(false)} type="button" className="w-button wbaseItem-value w-row 624030d1-8e78-4a06-acef-f3df67b08e8b" name-field="Button" level={4} cateid={29}>
                    <div className="w-text wbaseItem-value fec51101-55a6-46c8-91b0-d00b44ded0b5" name-field="Text" level={5} cateid={139}>Hủy</div>
                </button>
                {/* <div className="w-frame wbaseItem-value w-row e7817577-026d-4088-b330-1e29b66d873b" level={4} cateid={140}>
                    <button type="submit" className="w-button wbaseItem-value w-row ecfdb3a7-19e7-4a28-8b4d-670404f2c6c8" name-field="Button" level={5} cateid={29}>
                        <div className="w-svg wbaseItem-value 2592c61a-086e-4228-91d1-092fb8b35d29" level={6} cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.7337 5.26627C22.0888 5.62129 22.0888 6.19689 21.7337 6.55192L9.91555 18.3701C9.56053 18.7251 8.98493 18.7251 8.6299 18.3701L2.26627 12.0065C1.91124 11.6514 1.91124 11.0758 2.26627 10.7208C2.62129 10.3658 3.19689 10.3658 3.55192 10.7208L9.27273 16.4416L20.4481 5.26627C20.8031 4.91124 21.3787 4.91124 21.7337 5.26627Z" fill="#FFFFFFFF">
                                </path>
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value 4820f7f2-d5f5-4a4b-8ad6-1eaa19c3334e" name-field="Text" level={6} cateid={139}>Lưu</div>
                    </button>
                </div> */}
                <div className="w-frame wbaseItem-value w-row e7817577-026d-4088-b330-1e29b66d873b" level={4} cateid={140}>
                    <button type="submit" className="w-button wbaseItem-value w-row ecfdb3a7-19e7-4a28-8b4d-670404f2c6c8" name-field="Button" level={5} cateid={29}>
                        <div className="w-svg wbaseItem-value 2592c61a-086e-4228-91d1-092fb8b35d29" level={6} cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.7337 5.26627C22.0888 5.62129 22.0888 6.19689 21.7337 6.55192L9.91555 18.3701C9.56053 18.7251 8.98493 18.7251 8.6299 18.3701L2.26627 12.0065C1.91124 11.6514 1.91124 11.0758 2.26627 10.7208C2.62129 10.3658 3.19689 10.3658 3.55192 10.7208L9.27273 16.4416L20.4481 5.26627C20.8031 4.91124 21.3787 4.91124 21.7337 5.26627Z" fill="#FFFFFFFF">
                                </path>
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value 4820f7f2-d5f5-4a4b-8ad6-1eaa19c3334e" name-field="Text" level={6} cateid={139}>Lưu</div>
                    </button>
                </div>
            </div>
        </form>


    )
}