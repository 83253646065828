import Pagination from '../../components/Pagination/Pagination';
import '../../css/user/user.css';
import { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import { NavLink, useLocation } from 'react-router-dom';
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import { UpdateStatusUser, getListAllUser } from '../../redux/middleware/api/quanlynguoidung/userDA';
import { getListNhomquyen } from '../../redux/middleware/api/nhomquyen/NhomQuyenDA';
import { getorgList, getorgtitleList } from '../../redux/middleware/api/Filter/Filter';
import Select2 from '../../components/select2/Select2';
import { Ultis } from '../../core/utils';
import { useForm, Controller } from 'react-hook-form';

import BlackListDA from '../../redux/middleware/api/BlackList/BlackListDA';
import { PopupUploadBlackList } from '../CongViecCuaToi/Popup';

export default function BlackList() {
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({});
    const location = useLocation();
    const fileimport = useRef();
    const [query1, setquery] = useState([]);

    const [data, setData] = useState([]);
    const [isShowpopUpload, setisShowpopUpload] = useState(false);
    const [reasonList, setreasonList] = useState([]);
    const [createdByList, setcreatedByList] = useState([]);
    const [total, setTotal] = useState();
    const [pageDetails, setPageDetails] = useState({
        current: 1,
        size: 10
    });
    const notificationAlert = useRef();

    const _InitData = async (page, pageSize, query) => {
        if (!query) {
            query = query1;
        } else {
            setquery(query)
        }
        const customerName = new URLSearchParams(query).get('customerName');
        const cifNo = new URLSearchParams(query).get('cifNo');
        const customerId = new URLSearchParams(query).get('customerId');
        const reasonType = new URLSearchParams(query).get('reasonType');
        const importType = new URLSearchParams(query).get('importType');
        const createdDateTo = new URLSearchParams(query).get('createdDateTo');
        const createdDateFrom = new URLSearchParams(query).get('createdDateFrom');
        const createdBy = new URLSearchParams(query).get('createdBy');

        const response = await BlackListDA.getListBlackList(page, pageSize, `?${query?.toString()}`);
        setValue('customerName', customerName);
        setValue('cifNo', cifNo);
        setValue('customerId', customerId);
        setValue('reasonType', reasonType);
        setValue('importType', importType);
        setValue('createdDateTo', createdDateTo);
        setValue('createdDateFrom', createdDateFrom);
        setValue('createdBy', createdBy);
        if (response != null) {
            setData(response.pageData);
            setTotal(response.total)
        }
    }

    const OnDelete = async (index) => {

        const id = data[index].customerId;
        const respone = await BlackListDA.DelBlackList(id);
        if (respone.code === '200') {
            var updatedItems = [...data];
            updatedItems.splice(index, 1);
            setData(updatedItems)

        } else {
            notify(notificationAlert, ERROR, respone.message)
        }

    }
    const getListReason = async () => {
        const res = await BlackListDA.getListreasonBlackList();
        if (res != null) {
            setreasonList(res)
        }
    }
    const allUserCreate = async () => {
        debugger;
        const res = await getListAllUser(1, 100, "");
        if (res != null) {
            setcreatedByList(res.content)
        }
    }
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        getListReason()
        allUserCreate()
        _InitData(pageDetails.current, pageDetails.size, searchParams);
    }, []);
    const onSubmitForm = (event) => {
        const searchParams = new URLSearchParams(location.search)

        if (event.customerName) {
            searchParams.set('customerName', event.customerName);
        } else {
            searchParams.delete('customerName')
        }
        if (event.cifNo)
            searchParams.set('cifNo', event.cifNo);
        else
            searchParams.delete('cifNo')

        if (event.customerId)
            searchParams.set('customerId', event.customerId);
        else
            searchParams.delete('customerId')
        if (event.reasonType)
            searchParams.set('reasonType', event.reasonType);
        else
            searchParams.delete('reasonType')
        if (event.createdDateTo)
            searchParams.set('createdDateTo', event.createdDateTo);
        else
            searchParams.delete('createdDateTo')
        if (event.importType)
            searchParams.set('importType', event.importType);
        else
            searchParams.delete('importType')
        if (event.createBy)
            searchParams.set('createBy', event.createBy);
        else
            searchParams.delete('createBy')
        if (event.createdDateFrom)
            searchParams.set('createdDateFrom', event.createdDateFrom);
        else
            searchParams.delete('createdDateFrom')

            setquery(searchParams)
        window.history.replaceState(null, null, `?${searchParams.toString()}`);
        _InitData(pageDetails.current, pageDetails.size, searchParams);
    }
    const ressetForm = () => {
        setValue('customerName', null);
        setValue('cifNo', null);
        setValue('customerId', null);
        setValue('reasonType', null);
        setValue('createdDateTo', null);
        setValue('importType', null);
        setValue('createBy', null);
        setValue('createdDateFrom', null);
    }
    const importFile = () => {

        // fileimport.current.click()

    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            var fileUpload = new FormData();
            fileUpload.append('fileUpload', file);
            const res = await BlackListDA.UploadFileBlackList(fileUpload);
            if (res.code === '200') {
                notify(notificationAlert, SUCCESS, 'Upload File thành công.')
            } else {
                notify(notificationAlert, ERROR, res.message ?? 'Đã có lỗi xảy ra.')
            }
        }
    };

    const ExportFile = async () => {
        const res = await BlackListDA.ExportFileBlackList(location.search)
        debugger;
        if (res.status === 201) {
            var blob = new Blob([res.data], {
                type: res.headers["content-type"],
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.target = '_blank'; // Open the link in a new tab/window
            link.download = 'Danhsach-blackList.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            notify(notificationAlert, ERROR, res.message ?? 'Đã có lỗi xảy ra.')
        }
        // if (res.code === '200') {
        //     notify(notificationAlert, SUCCESS, 'Export File thành công.')
        // } else {
        //     notify(notificationAlert, ERROR, res.message ?? 'Đã có lỗi xảy ra.')

        // }

    }
    return <div className="w-frame wbaseItem-value w-col 6c272e27-d8cf-4f9f-9a1d-21aa30586215" level={3}
        cateid={140}>
        <NotificationAlert ref={notificationAlert} />
        {isShowpopUpload && <PopupUploadBlackList
            notificationAlert={notificationAlert}
            callback_action={
                function () {
                    setisShowpopUpload(false)
                }
            }
            callback_closed={function(){
                setisShowpopUpload(false)
            }}
        />}

        <div className="w-frame wbaseItem-value w-col ac9373c9-1468-40e8-901a-9ef767660d7a" scroll="true" level={4} cateid={140}>
            <div className="w-frame wbaseItem-value w-row e5a16764-a41a-41c2-bb63-9b7798d760c3"
                level={5} cateid={140}>
                <div className="w-frame wbaseItem-value w-col 72d56605-c909-4320-8521-e0dd023109d2"
                    level={6} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row dae32852-212b-4656-9630-9c2a99a23671"
                        level={7} cateid={140}>
                        <div className="w-text wbaseItem-value 7b628ee8-df4d-4722-a551-90fbcaebcda8" level={8}
                            cateid={139}>Tìm kiếm Danh sách đen</div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row breadcrumb 5079aa9c-748c-4ae7-9ca2-3dbd1d833a87"
                        level={7} cateid={140}>
                        <button type="button"
                            className="w-button wbaseItem-value w-row 697c50de-7d5d-41d3-b617-0c3741d1bc24"
                            level={8} cateid={29}>
                            <div className="w-text wbaseItem-value 29185097-d932-4fa0-960c-eb863e5fe0f1"
                                level={9} cateid={139}>Quản trị hệ thống</div>
                            <div className="w-svg wbaseItem-value 2bb71249-6468-4ca9-9918-a13e38ecf880"
                                level={9} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z"
                                        fill="#28282999" fillOpacity="0.6" />
                                </svg>
                            </div>
                        </button>
                        <div className="w-text wbaseItem-value b1d398af-a673-4ea7-a4af-6831b0411e15" level={8}
                            cateid={139}>Quản lý danh sách đen</div>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off' className="w-frame w-form wbaseItem-value w-col b721ebd4-7990-4086-98b3-6ec8f5aa41bc"
                level={5} cateid={128}>
                <div className="w-frame wbaseItem-value w-row e4175e48-4d2e-49a7-b4f4-927e559458a1" wrap="wrap"
                    level={6} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 8739a82e-730d-4399-9c99-77799fdfd4a1"
                        level={7} cateid={140}>
                        <div className="w-text wbaseItem-value c34e4304-2103-4001-843c-5d9b8e57f72d" level={8}
                            cateid={139}>Tên khách hàng</div>
                        <div className="w-textformfield wbaseItem-value w-row f439fd9e-f87f-4c68-ab98-dff2e0c2c44c"
                            name-field="RoleID" level={8} cateid={85}
                            placeholder="Nhập tên khách hàng">
                            <div className="wbaseItem-value 215d6150-22b8-4005-b3d8-049f8762b450"
                                name-field="Textfield" level={9} cateid={86}>
                                <div className="textfield">
                                    <input id="textfield-215d6150-22b8-4005-b3d8-049f8762b450" name="customerName" {...register('customerName')}
                                        placeholder="Nhập tên khách hàng" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 8739a82e-730d-4399-9c99-77799fdfd4a1"
                        level={7} cateid={140}>
                        <div className="w-text wbaseItem-value c34e4304-2103-4001-843c-5d9b8e57f72d" level={8}
                            cateid={139}>Số CMND/CCCD</div>
                        <div className="w-textformfield wbaseItem-value w-row f439fd9e-f87f-4c68-ab98-dff2e0c2c44c"
                            name-field="RoleID" level={8} cateid={85}
                            placeholder="Nhập số cmnd/cccd">
                            <div className="wbaseItem-value 215d6150-22b8-4005-b3d8-049f8762b450"
                                name-field="Textfield" level={9} cateid={86}>
                                <div className="textfield">
                                    <input id="textfield-215d6150-22b8-4005-b3d8-049f8762b450" name="customerId" {...register('customerId')}
                                        placeholder="Nhập số cmnd/cccd" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm f1fa51fe-e167-46e0-9f04-897733680195"
                        level={7} cateid={140}>
                        <div className="w-text wbaseItem-value c3a67008-efbc-42fc-98d3-9787640e278f" level={8}
                            cateid={139}>Mã khách hàng</div>
                        <div className="w-textformfield wbaseItem-value w-row ac565359-b7cc-4a6b-b21a-bccd0bd0b1f0"
                            name-field="EmployeeID" level={8} cateid={85}
                            placeholder="Nhập mã khách hàng">
                            <div className="wbaseItem-value 23336832-81df-4b13-ae98-ac81100c8775"
                                name-field="Textfield" level={9} cateid={86}>
                                <div className="textfield">
                                    <input id="textfield-23336832-81df-4b13-ae98-ac81100c8775" name="cifNo"  {...register('cifNo')}
                                        placeholder="Nhập mã khách hàng" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm a8a861c9-968b-4406-96a5-1845b356a424"
                        level={7} cateid={140}>
                        <div className="w-text wbaseItem-value a8afe34e-e3d9-4209-8b04-da2f0f8c7acd" level={8}
                            cateid={139}>Số giấy tờ pháp lý</div>
                        <div className="w-textformfield wbaseItem-value w-row 0823d802-baa5-4f32-a0f6-6a0568e18f20"
                            name-field="customerId" level={8} cateid={85}
                            placeholder="Số giấy từ pháp lý">
                            <div className="wbaseItem-value 87992e90-5f00-497e-97fd-a7ccdd901c24"
                                name-field="Textfield" level={9} cateid={86}>
                                <div className="textfield">
                                    <input id="textfield-87992e90-5f00-497e-97fd-a7ccdd901c24" name="customerId" {...register('customerId')}
                                        placeholder="Số giấy từ pháp lý" />
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 42e98ce9-bc6b-4d6f-bc47-7a87b70db228"
                        level={7} cateid={140}>
                        <div className="w-text wbaseItem-value e7c160e0-c242-457f-bf73-809fbf2a2dd3" level={8}
                            cateid={139}>Lý do</div>


                        <Controller
                            name="reasonType"
                            control={control}
                            {...register("reasonType")}
                            style={{ order: 2 }}
                            render={({ field }) => (
                                <Select2 {...field}
                                    data={reasonList.map((e, _) => { return { id: e, name: e } })}
                                    options={{
                                        placeholder: 'chọn lý do'
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col12-lg col12-md col24 col24-sm 42e98ce9-bc6b-4d6f-bc47-7a87b70db228" level={7} cateid={140}>

                        <div className="w-frame wbaseItem-value w-row" level={8} cateid={140} id="676792c9-5f4a-45bd-88ce-db3e2dd536a0">
                            <div className="w-text wbaseItem-value" level={8} cateid={139} id="ad483c96-1a4e-4eda-ac8d-9360ed0e6722">Ngày tạo từ ngày</div>
                            <div className="w-text wbaseItem-value" level={9} cateid={139} id="ebfc245d-bfc0-4f47-9b0c-d7fd80a8e9b6">&nbsp;&nbsp;</div>
                            <div className="w-text wbaseItem-value" level={8} cateid={139} id="ad483c96-1a4e-4eda-ac8d-9360ed0e6722">Đến ngày</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row" level={8} cateid={140} id="676792c9-5f4a-45bd-88ce-db3e2dd536a0">
                            <div className="w-textformfield wbaseItem-value w-row" name-field="createdDateFrom" level={9} cateid={85} id="54e5d651-53d5-43d6-b61d-ffa9b01c9bd3" placeholder="Từ ngày">
                                <div className="wbaseItem-value" name-field="Textfield" level={10} cateid={86} id="d37be69e-c37b-4962-9031-b8ea9938843d" type="date">
                                    <div className="textfield">
                                        <input name="createdDateFrom" {...register("createdDateFrom")} placeholder="Từ ngày" />
                                    </div>
                                    <div className="suffix-btn-txtfd calendar-icon"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                        <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" />
                                        <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" />
                                        <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" />
                                        <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                        <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                        <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" />
                                        <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" />
                                        <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                    </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="w-text wbaseItem-value" level={9} cateid={139} id="ebfc245d-bfc0-4f47-9b0c-d7fd80a8e9b6">-</div>
                            <div className="w-textformfield wbaseItem-value w-row" name-field="createdDateTo" level={9} cateid={85} id="e6b07ff0-db76-400a-835f-1c9671ea0e1c" placeholder="Đến ngày">
                                <div className="wbaseItem-value" name-field="Textfield" level={10} cateid={86} id="6da7e7b2-aded-438e-b121-5127229e93c0" type="date" >
                                    <div className="textfield"><input name="createdDateTo" {...register("createdDateTo")} placeholder="Đến ngày" /></div>
                                    <div className="suffix-btn-txtfd calendar-icon"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                        <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" />
                                        <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" />
                                        <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" />
                                        <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                        <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                        <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" />
                                        <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" />
                                        <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                    </svg></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 42e98ce9-bc6b-4d6f-bc47-7a87b70db228"
                        level={7} cateid={140}>
                        <div className="w-text wbaseItem-value e7c160e0-c242-457f-bf73-809fbf2a2dd3" level={8}
                            cateid={139}>Người tạo</div>
                        <Controller
                            name="createdBy"
                            control={control}
                            {...register("createdBy")}
                            style={{ order: 2 }}
                            render={({ field }) => (
                                <Select2 {...field}
                                    data={createdByList.map((e, _) => { return { id: e.username, name: e.fullName } })}
                                    options={{
                                        placeholder: 'Chọn người tạo'
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 42e98ce9-bc6b-4d6f-bc47-7a87b70db228"
                        level={7} cateid={140}>
                        <div className="w-text wbaseItem-value e7c160e0-c242-457f-bf73-809fbf2a2dd3" level={8}
                            cateid={139}>Nguồn tạo</div>
                        <Controller
                            name="importType"
                            control={control}
                            {...register("importType")}
                            style={{ order: 2 }}
                            render={({ field }) => (
                                <Select2 {...field}
                                    data={[{ id: 'jLOS', name: 'jLOS' }, { id: 'Upload', name: 'Upload' }, { id: 'Flexcube', name: 'Flexcube' }]}
                                    options={{
                                        placeholder: 'chọn nguồn tạo'
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="w-frame wbaseItem-value w-row b45d26d7-cc6f-4d0d-b743-ff5d3951a493"
                    level={6} cateid={140}>
                    <button type="button" onClick={ressetForm}
                        className="w-button wbaseItem-value w-row 01df81f9-ab23-4de9-a2b7-ddd1c163c199"
                        level={7} cateid={29}>
                        <div className="w-svg wbaseItem-value 2e7370de-b936-4b12-9d10-1a6280b9d506" level={8}
                            cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M21.0313 2.00835C21.5288 2.07581 21.8774 2.53382 21.81 3.03135L21.0827 8.39498C21.0153 8.89173 20.5586 9.2402 20.0617 9.17395L14.6072 8.44668C14.1095 8.38032 13.7598 7.92309 13.8262 7.42541C13.8926 6.92774 14.3498 6.57809 14.8475 6.64445L18.3484 7.11124C16.8702 5.25663 14.5726 4.09064 12 4.09064C7.50208 4.09064 3.81818 7.77454 3.81818 12.2725C3.81818 12.7745 3.41117 13.1816 2.90909 13.1816C2.40701 13.1816 2 12.7745 2 12.2725C2 6.77038 6.49792 2.27246 12 2.27246C15.0381 2.27246 17.7813 3.61127 19.6033 5.77412L20.0083 2.78705C20.0757 2.28953 20.5338 1.94089 21.0313 2.00835Z"
                                    fill="#28282999" />
                                <path
                                    d="M22 12.2733C22 11.7713 21.5929 11.3643 21.0909 11.3643C20.5888 11.3643 20.1818 11.7713 20.1818 12.2733C20.1818 16.7713 16.4979 20.4552 12 20.4552C9.42714 20.4552 7.12925 19.2889 5.6511 17.434L9.15251 17.9009C9.65018 17.9672 10.1074 17.6176 10.1738 17.1199C10.2401 16.6222 9.89048 16.165 9.39281 16.0986L3.93826 15.3714C3.44137 15.3051 2.98462 15.6536 2.91726 16.1503L2.18999 21.514C2.12253 22.0115 2.47117 22.4695 2.96869 22.537C3.46621 22.6044 3.92423 22.2558 3.99169 21.7583L4.39665 18.7716C6.21865 20.9345 8.96182 22.2733 12 22.2733C17.502 22.2733 22 17.7754 22 12.2733Z"
                                    fill="#28282999" />
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value f0397efc-4cc1-4e36-8315-d0676e7fdf1a"
                            level={8} cateid={139}>Xóa bộ lọc</div>
                    </button>
                    <button type="submit"
                        className="w-button wbaseItem-value w-row 6b399b04-820f-4233-98be-e7cb8f6951de"
                        level={7} cateid={29}>
                        <div className="w-svg wbaseItem-value e78d1a07-f59d-4c7d-addb-a67b40192d7c" level={8}
                            cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z"
                                    fill="#FFFFFFFF" />
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value 131937ed-ca0a-4c01-8d22-9f6e2da034f2"
                            level={8} cateid={139}>Tìm kiếm</div>
                    </button>
                </div>
            </form>
            <div className="w-frame wbaseItem-value w-col 0cd55978-a21c-44a6-8b39-19dd03771da9"
                level={5} cateid={140}>
                <div className="w-frame wbaseItem-value w-row 88f91b77-e3fb-48e9-a4f7-f2e0417f2f77"
                    level={6} cateid={140}>
                    <div className="w-text wbaseItem-value 427d16c1-99b5-49b8-8d14-ff5411c96372" level={7}
                        cateid={139}>Danh sách Danh sách đen</div>
                    <button onClick={ExportFile} type="button"
                        className="w-button wbaseItem-value w-row 93c1f8de-5697-48eb-970d-44af199ccba7"
                        level={7} cateid={29}>
                        <div className="w-svg wbaseItem-value e5d3faae-f976-479a-a5a9-1b60d6207043" level={8}
                            cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10.5L12 16.3333L17 10.5H12.8333V3H11.1667V10.5H7Z"
                                    fill="#28282999" />
                                <path
                                    d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z"
                                    fill="#28282999" />
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value a801ffae-4724-44f0-b178-cf2f1db8bfe4"
                            level={8} cateid={139}>Export</div>
                    </button>
                    <button onClick={() => {
                        setisShowpopUpload(true)
                    }} type="button"
                        className="w-button wbaseItem-value w-row 90ea2198-645a-4a1d-a790-b3cd65c9cc76"
                        level={7} cateid={29}>
                        <div className="w-svg wbaseItem-value ee3df05b-bf73-4b9c-80e2-0c3848b2ea07" level={8}
                            cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 8.83333L12 3L17 8.83333H12.8333V16.3333H11.1667V8.83333H7Z"
                                    fill="#28282999" />
                                <path
                                    d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z"
                                    fill="#28282999" />
                            </svg>
                        </div>
                        {/* <input type='file' ref={fileimport} name='fileimp' style={{ display: 'none' }} onChange={handleFileChange} /> */}
                        <div className="w-text wbaseItem-value 652cfb58-5a7e-4182-b0a2-13f7458fedaf"
                            level={8} cateid={139}>Upload</div>
                    </button>

                </div>
                <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                    <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>
                        <table className="w-table wbaseItem-value 248f4c77-5f2a-4eb0-99bd-a17b17d8b856" type={0}
                            level={6} cateid={117}>
                            <tbody>
                                <tr className="table-row">
                                    <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 096c1a97-489c-4e0d-aa36-7ddaff04bb11"
                                            level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 2c03f5b3-5f9d-4d48-8c77-8290f2667af8"
                                                level={8} cateid={139}>STT</div>
                                        </div>
                                    </td>
                                    <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row ffc26590-b353-4888-950a-51f6353e7fb3"
                                            level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 9bc4b77e-92ef-4e90-914c-a6b0c203dd2b"
                                                level={8} cateid={139}>Tên khách hàng</div>
                                        </div>
                                    </td>
                                    <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 92047b9f-64c5-4c8d-b462-eee5792be6ce"
                                            level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 5e4345e1-d77d-4f8d-9c34-74bf6f740837"
                                                level={8} cateid={139}>Số CCCD/CMND</div>
                                        </div>
                                    </td>
                                    <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 029ef3a4-22e4-45ad-b208-253297dd02b4"
                                            level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 5bb0dce2-bbf5-40da-8371-b434e65229ab"
                                                level={8} cateid={139}>Số CIF</div>
                                        </div>
                                    </td>
                                    <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row b65008d0-9757-4fe3-b009-8e5db84bf789"
                                            level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 0cd8f1e8-2f6b-49fc-842a-0ebc037f1b13"
                                                level={8} cateid={139}>Lý do</div>
                                        </div>
                                    </td>
                                    <td id="1x6" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 57116de3-e977-419b-8cb8-099766e3f794"
                                            level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 991ed2e9-3d2d-4796-8b4b-fceaecf4a4ab"
                                                level={8} cateid={139}>Ngày từ chối</div>
                                        </div>
                                    </td>
                                    <td id="1x7" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 5d693d4b-3ac9-4c91-a385-7ecccd413256"
                                            level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 14786bc3-5bfc-4aa5-8fb1-a8c99db80e60"
                                                level={8} cateid={139}>Thời gian khóa còn lại</div>
                                        </div>
                                    </td>
                                    <td id="1x7" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 5d693d4b-3ac9-4c91-a385-7ecccd413256"
                                            level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 14786bc3-5bfc-4aa5-8fb1-a8c99db80e60"
                                                level={8} cateid={139}>Nguồn</div>
                                        </div>
                                    </td>
                                    <td id="1x7" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 5d693d4b-3ac9-4c91-a385-7ecccd413256"
                                            level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 14786bc3-5bfc-4aa5-8fb1-a8c99db80e60"
                                                level={8} cateid={139}>Người tạo</div>
                                        </div>
                                    </td>
                                    <td id="1x7" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row 5d693d4b-3ac9-4c91-a385-7ecccd413256"
                                            level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value 14786bc3-5bfc-4aa5-8fb1-a8c99db80e60"
                                                level={8} cateid={139}>Ngày tạo</div>
                                        </div>
                                    </td>
                                    <td id="1x8" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row f993f144-38aa-4874-92ff-fee257b23cd2"
                                            level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value e61020b0-eae1-43f2-9894-d091cceec01c"
                                                level={8} cateid={139}>Hành động</div>
                                        </div>
                                    </td>
                                </tr>
                                {
                                    data.map((item, index) => {
                                        return (<tr className="table-row" key={item.customerId}>
                                            <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 42cb3f4e-1f65-4c06-80bc-8340e2dac94c"
                                                    level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d6a5fbfa-25c8-4a57-a301-9510ec6369b1"
                                                        level={8} cateid={139}>{pageDetails.size * (pageDetails.current - 1) + index + 1}</div>
                                                </div>
                                            </td>
                                            <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row e05adea9-2a82-4ed2-9e53-9d8074ff2dba"
                                                    level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 5251a191-4d3a-4152-b711-ca7ca1eab948"
                                                        level={8} cateid={139}>{item.customerName}</div>
                                                </div>
                                            </td>
                                            <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 5ada852f-146b-4458-a985-a448ec19bd31"
                                                    level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 4c39cd03-1e0e-4b86-b44a-7078f90c7e18"
                                                        level={8} cateid={139}>{item.customerId}</div>
                                                </div>
                                            </td>
                                            <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 0e4151d7-3fe4-4bc0-b203-bd6bf54b7964"
                                                    level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 22af8b54-3aec-485c-a9a1-d47ccda7c584"
                                                        level={8} cateid={139}>{item.cifNo}</div>
                                                </div>
                                            </td>
                                            <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 506d8a16-afd2-41e2-9e17-dd4e449604b7"
                                                    level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value caa69f60-1e11-44ce-9e51-df966c811d49"
                                                        level={8} cateid={139}>{item.reason}</div>
                                                </div>
                                            </td>
                                            <td id="2x6" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 4b0dfe68-2cfc-498a-a46f-dc2c9fd9d04a"
                                                    level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 997b6a86-3bab-41fc-a63d-a079dbf74c17"
                                                        level={8} cateid={139}>{new Date(item.blacklistDate).toLocaleDateString('en-GB')}</div>
                                                </div>
                                            </td>
                                            <td id="2x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 051993c9-2809-4f78-ad77-80293d6252a6"
                                                    level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 47323d44-5077-427e-878e-2cc8dbe905e0"
                                                        level={8} cateid={139}>{Ultis.DatetimeSubtractionString(item.blacklistDate, item.expiredDate)} ngày</div>
                                                </div>
                                            </td>
                                            <td id="2x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 051993c9-2809-4f78-ad77-80293d6252a6"
                                                    level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 47323d44-5077-427e-878e-2cc8dbe905e0"
                                                        level={8} cateid={139}>{item.importType}</div>
                                                </div>
                                            </td>
                                            <td id="2x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 051993c9-2809-4f78-ad77-80293d6252a6"
                                                    level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 47323d44-5077-427e-878e-2cc8dbe905e0"
                                                        level={8} cateid={139}>{item.createdBy}</div>
                                                </div>
                                            </td>
                                            <td id="2x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 051993c9-2809-4f78-ad77-80293d6252a6"
                                                    level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 47323d44-5077-427e-878e-2cc8dbe905e0"
                                                        level={8} cateid={139}>{new Date(item.createdOn).toLocaleDateString('en-GB')}</div>
                                                </div>
                                            </td>
                                            <td id="2x8" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div onClick={() => OnDelete(index)} className="w-frame wbaseItem-value w-row e1b0ae35-869a-495a-a32c-2945a1c300e4" level={7} cateid={140}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                        </path>
                                                        <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </td>
                                        </tr>)
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination
                    currentPage={pageDetails.current}
                    itemPerPage={pageDetails.size}
                    totalItem={total}
                    onChangePage={(page, size) => {
                        if (pageDetails.current !== page || pageDetails.size !== size) {
                            setPageDetails({ current: page, size: size });
                            _InitData(page, size);
                        }
                    }}
                />
            </div>
        </div>
    </div>
}