import { baseurl, createnhomquyen, deletenhomquyen, detailnhomquyen, listmodule, listnhomquyen, updateStatusnhomquyen, updatenhomquyen, } from "../../../../config/ConfigAPI";
import { deleteData, getData, postData, putData } from "../../../../core/BaseDA";
export const getListNhomquyen = async (page, size, query) => {
    const url = `${baseurl}${listnhomquyen}${query ? `${query}&pageNum=${page ?? 0}&pageSize=${size ?? 0}` : `?pageNum=${page ?? 0}&pageSize=${size ?? 0}`}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const getListModue = async () => {
    const url = `${baseurl}${listmodule}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const NhomquyenCreate = async (obj) => {
    const url = `${baseurl}${createnhomquyen}`;
    const response = await postData(url, { obj: obj });
    return response;
};

export const NhomquyenDelete = async (id) => {
    const url = `${baseurl}${deletenhomquyen}?id=${id}`;
    const response = await deleteData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const getDetailNhomquyen = async (id) => {

    const url = `${baseurl}${detailnhomquyen}?id=${id}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const NhomquyenUpdate = async (id, obj) => {
    const url = `${baseurl}${updatenhomquyen}?groupId=${id}`;
    const response = await putData(url, { obj: obj });
    return response;
};

export const NhomquyenUpdateStatus = async (id, status) => {
    const url = `${baseurl}${updateStatusnhomquyen}?groupId=${id}&status=${status}`;
    const response = await putData(url, { obj: null });
    return response;
};