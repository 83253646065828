import '../../css/user/adduser.css';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { useForm, Controller } from 'react-hook-form';
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import NotificationAlert from "react-notification-alert";
import { PopupType, WPopup } from '../../components/Popup/Popup';
import { getProductCate, getbproductList, } from '../../redux/middleware/api/Filter/Filter';
import Select2 from '../../components/select2/Select2';
import { PopupSelecBrand, PopupSelecDocument, PopupSelecInsurance, PopupSelectUser } from '../CongViecCuaToi/Popup';
import { getPriorityDealer } from '../../redux/middleware/api/daily/dailyDA';
import $ from 'jquery';
import loanprogramDA from '../../redux/middleware/api/W_ChuongTrinhVay/loanprogramDA';
import CocauTochucDA from '../../redux/middleware/api/CoCauToChuc/CocauTochucDA';
import NhanhieuDA from '../../redux/middleware/api/NhanHieu/NhanhieuDA';
import { Ultis } from '../../core/utils';
import { UploadFiledocuments } from '../../redux/middleware/api/documents/documentsDA';
import { BrandType } from '../../const/enum';
import { parse } from 'date-fns';

export default function ThemMoiChuongtrinhvay() {
    const { register, handleSubmit, control, clearErrors, setError, setValue, getValues, formState: { errors } } = useForm({
        shouldFocusError: false,
        mode: "all",
        defaultValues: {
            "minimumPayment": "500,000",
            "minimumAgeLimit": "18",
            "maximumAge": "60",
            "status": 1,
            "interestType": "D11"
        }
    });
    const notificationAlert = useRef();
    const navigate = useNavigate();
    const [isCancel, setisCancel] = useState();
    const [isNoDataFaci, setisNoDataFaci] = useState();
    const [scrollIndex, setScroll] = useState(0);
    let { id } = useParams();
    const [data, setData] = useState({});
    const [isAPreferentialLoanProgram, setAPreferentialLoanProgram] = useState(false);
    const [switchStatus, setSwitchStatus] = useState(1);
    const [productList, setproductList] = useState([]);
    const [insuranceTNList, setinsuranceTNList] = useState([]);
    const [insuranceTCList, setinsuranceTCList] = useState([]);
    const [confirmDel, setconfirmDel] = useState();

    const [documentTNList, setdocumentTNList] = useState([]);
    const [documentTCList, setdocumentTCList] = useState([]);

    const [userList, setuserList] = useState([]);
    const [treeDataOrg, setTreeDataORG] = useState([]);
    const [treeDataAgent, setTreeDataAgent] = useState([]);
    const [treeDataBranch, setTreeDataBranch] = useState([]);
    const [brandListAll, setbrandListAll] = useState([]);
    const [priorityLevelList, setpriorityLevelList] = useState([]);
    const [productCateList, setproductCateList] = useState([]);
    const [filePath, setfilePath] = useState();
    const [faciList, setfaciList] = useState([]);
    const [facilityInfor, setFacilityInfor] = useState();
    const [checkType, setInterest] = useState(true);
    const [CheckAllFaci, setCheckAllFaci] = useState(false);

    const [showPopupInsurance, setPopupInsurance] = useState(false);
    const [showPopupBranch, setPopupBranch] = useState(false);
    const [showPopupInsuranceTN, setPopupInsuranceTN] = useState(false);
    const [showPopupDocument, setPopupDocument] = useState(false);
    const [showPopupDocumentTN, setPopupDocumentTN] = useState(false);
    const [showPopupUser, setPopupUser] = useState(false);
    const [interesttype, setinteresttype] = useState();
    const [enabledEdit, setEnabledEdit] = useState(false);

    const getFaciList = async () => {
        var obj = {};
        if (checkType == true) {
            obj.promotion_code = "";
            obj.mod_no = 1;
            obj.product_code = getValues('productCode')
            obj.ude_value = getValues('ỉnterestYear')
        } else {
            obj.promotion_code = "";
            obj.mod_no = 2;
            obj.product_code = getValues('productCode')
            obj.fixed_rate = getValues('ỉnterestYear1')
            obj.fixed_period = getValues('period1')
            obj.intrate_ratecode = getValues('ỉnterestYear2')
        }
        const res = await loanprogramDA.getListFaci(1, 100, obj);
        if (res != null) {
            if (res.length > 0) {
            } else {
                setisNoDataFaci(false)
            }
        } else {
            setisNoDataFaci(false)
        }
    }
    const OnchangeCheckedFacility = (index) => {
        setfaciList((faciList) =>
            faciList.map((item) =>
                item.id === faciList[index].id ? { ...item, check: !faciList[index].check } : item
            ));
    }
    const OnchangeCheckedAll = () => {
        if (CheckAllFaci) {
            setCheckAllFaci(false)
        } else {
            setCheckAllFaci(true)
        }
        setfaciList((faciList) =>
            faciList.map((item) => { return { ...item, check: !CheckAllFaci } }
            ));
    }
    const getAllproduct = async () => {
        const response = await getbproductList(1, 100,);
        if (response != null) {
            setproductList(response.content);
        }
    }
    const getAllproductCate = async () => {
        const response = await getProductCate(1, 100,);
        if (response != null) {
            setproductCateList(response.content);
        }
    }
    const getPriority = async () => {
        const response = await getPriorityDealer(1, 100);
        if (response != null) {
            setpriorityLevelList(response.pageData);
        }
    }
    // const ontest = () => {
    //     var tmp = $('.interestType').val();
    //     if (tmp == 'D11') {
    //         setInterest(true)
    //     }
    //     if (tmp == 'D22') {
    //         setInterest(false)
    //     }
    // }
    const onCancel = (val) => {
        setisCancel(val);
        if (val == true) {
            navigate('/admin/user');
        }
    }
    const _InitData = async () => {
            getListBrand();
            let resDealer;
            let resOrg;
            if (id) {
            var obj = {
                effectiveDate: "",
                expirationDate: "",
                loanProgramCode: id,
                loanProgramName: "",
                productCategoryCode: "",
                productCode: ""
            }
            const res = await loanprogramDA.getListLoan(1, 1, obj);
            if (res != null) {
                let dataRes = res.pageData[0];
                // setproductList(res.products);
                console.log(dataRes)
                setData(dataRes);
                
                setValue("product", dataRes.data.product);
                setValue("productCode", dataRes.data.productCode);
                setValue("productCategory", dataRes.data.productCategory);
                setValue("productCategoryCode", dataRes.data.productCategoryCode);
                setValue("priorityLevel", dataRes.data.priorityLevel);
                setValue('loanProgramCode', dataRes.data.loanProgramCode)
                setValue('loanProgramName', dataRes.data.loanProgramName)
                setValue('description', dataRes.data.description)
                setValue('minimumPrepaymentPercent', dataRes.data.minimumPrepaymentPercent)
                setValue('minimumPayment', Ultis.money(dataRes.data.minimumPayment))
                setValue('minimumLoanAmount', Ultis.money(dataRes.data.minimumLoanAmount))
                setValue('maximumLoanAmountTotal', Ultis.money(dataRes.data.maximumLoanAmountTotal))
                setValue('maximumLoanAmount', Ultis.money(dataRes.data.maximumLoanAmount))
                setValue('minimumAgeLimit', dataRes.data.minimumAgeLimit)
                setValue('maximumAge', dataRes.data.maximumAge)
                setValue('status', dataRes.data.status)
                setSwitchStatus(dataRes.data.status)
                setfilePath(dataRes.data.filePath)
                setAPreferentialLoanProgram(dataRes.data.isAPreferentialLoanProgram === 1)
                setValue('effectiveDate', new Date(dataRes.data.effectiveDate).toLocaleDateString('en-GB'))
                setValue('expirationDate', new Date(dataRes.data.expirationDate).toLocaleDateString('en-GB'))
                setValue('disbursementExpiryDate', new Date(dataRes.data.disbursementExpiryDate).toLocaleDateString('en-GB'))
                var lstInsuTC = (dataRes.insuranceDetails ?? []).filter((a) => a.insuranceType === 'L1')
                var lstInsuTN = (dataRes.insuranceDetails ?? []).filter((a) => a.insuranceType === 'L2')
                setinsuranceTCList(lstInsuTC)
                setinsuranceTNList(lstInsuTN)
                if (dataRes.documentDetails)
                    setdocumentTCList(dataRes.documentDetails)
                if (dataRes.documentDisbursementDetails)
                    setdocumentTNList(dataRes.documentDisbursementDetails)
                if (dataRes.brandManagements)
                    setTreeDataBranch(dataRes.brandManagements)
                if (dataRes.userInfos)
                    setuserList(dataRes.userInfos)
                if (dataRes.facilityMas?.length > 0) {
                    let faciInfor = dataRes.facilityMas[0];
                    if (faciInfor.modNo === 1) {
                        faciInfor.udeValue = dataRes.facilityDets?.length > 0 ? dataRes.facilityDets.find(e => e.udeId === "INTEREST_RATE")?.udeValue : null;
                        setValue("ỉnterestYear", faciInfor.udeValue);
                    } else {
                        setValue("ỉnterestYear1", faciInfor.fixedRate);
                        setValue("period1", faciInfor.fixedPeriod);
                        setValue("ỉnterestYear2", faciInfor.intrateRatecode);
                    }
                    setValue("interestType", `D${faciInfor.modNo}${faciInfor.modNo}`);
                    setFacilityInfor(faciInfor);
                }
                resDealer = await loanprogramDA.TreeDealer();
                resOrg = await CocauTochucDA.getListOrg();
                if (resOrg != null) {
                    if (dataRes.orgManagements?.length > 0) {
                        setTreeDataORG([...resOrg.map(e => {
                            if (dataRes.orgManagements.some(item => item.id === e.id)) e.Checked = true;
                            return e;
                        })])
                    } else {
                        setTreeDataORG(resOrg)
                    }
                }
                if (resDealer != null) {
                    if (dataRes.dealerDetails?.length > 0) {
                        setTreeDataAgent([...resDealer.map(e => {
                            if (dataRes.dealerDetails.some(item => item.dealerCode === e.code)) e.Checked = true;
                            return e;
                        })])
                    } else {
                        setTreeDataAgent(resDealer)
                    }
                }
            }
        } else {
            resDealer = await loanprogramDA.TreeDealer();
                resOrg = await CocauTochucDA.getListOrg();
                setTreeDataORG(resOrg)
                setTreeDataAgent(resDealer)
            }
    }

    const getListBrand = async () => {
        let res = await NhanhieuDA.getListbranch();
        if (res) {
            setbrandListAll(res);
        }
    }

    useEffect(() => {
        getAllproduct()
        getAllproductCate()
        getPriority()
        _InitData()
    }, []);

    const onSubmitForm = async (event) => {
        if (!id && !enabledEdit) {
            onBlurCheckEnabledEdit(event);
        } else {
            const from = parse(event.effectiveDate, 'dd/MM/yyyy', new Date());
            const to = parse(event.expirationDate, 'dd/MM/yyyy', new Date());
            const orther = parse(event.disbursementExpiryDate, 'dd/MM/yyyy', new Date());
            if (to < from) {
                setError('effectiveDate', {
                    message: 'Vui lòng nhập ngày hiệu lực hợp lệ'
                })
                setError('expirationDate', {
                    message: 'Vui lòng nhập ngày hết hiệu lực hợp lệ'
                })
                notify(notificationAlert, ERROR, 'Ngày hiệu lực phải nhỏ hơn ngày hết hiệu lực')
                return;
            }
            if (orther < to) {
                setError('disbursementExpiryDate', { message: 'Vui lòng nhập ngày hiệu lực giải ngân hợp lệ' })
                setError('expirationDate', { message: 'Vui lòng nhập ngày hết hiệu lực hợp lệ' })
                notify(notificationAlert, ERROR, 'Ngày giải ngân phải lớn hơn ngày hết hiệu lực')
                return;
            }
            let body = {
                "data": {
                    "loanProgramCode": event.loanProgramCode,
                    "loanProgramName": event.loanProgramName,
                    "productCategoryCode": event.productCategoryCode,
                    "productCategory": productCateList.find(e => e.code === event.productCategoryCode)?.name,
                    "productCode": event.productCode,
                    "product": productList.find(e => e.code === event.productCode)?.name,
                    "description": event.description,
                    "status": parseInt(event.status),
                    "minimumPrepaymentPercent": parseFloat(event.minimumPrepaymentPercent),
                    "maximumPrepaymentPercent": 100.0,
                    "minimumPayment": parseFloat((''+ (event.minimumPayment ?? 0)).replaceAll(",", "")),
                    "minimumLoanAmount": parseFloat((''+ (event.minimumLoanAmount ?? 0)).replaceAll(",", "")),
                    "maximumLoanAmount": parseFloat((''+ (event.maximumLoanAmount ?? 0)).replaceAll(",", "")),
                    "maximumLoanAmountTotal": parseFloat((''+ (event.maximumLoanAmountTotal ?? 0)).replaceAll(",", "")),
                    "minimumAgeLimit": parseInt(event.minimumAgeLimit),
                    "maximumAge": parseInt(event.maximumAge),
                    "priorityLevel": event.priorityLevel,
                    "filePath": filePath,
                    "isAPreferentialLoanProgram": isAPreferentialLoanProgram ? 1 : 0,
                    "effectiveDate": Ultis.StringDatetoStringDate(event.effectiveDate, 'dd-MM-yyyy'),
                    "expirationDate": Ultis.StringDatetoStringDate(event.expirationDate, 'dd-MM-yyyy'),
                    "disbursementExpiryDate": Ultis.StringDatetoStringDate(event.disbursementExpiryDate, 'dd-MM-yyyy')
                },
                "listDealerCode": treeDataAgent.filter(e => e.Checked).map((e) => e.code),
                "listDocumentCode": documentTCList.map((e) => e.documentCode),
                "listDocumentDisbursementCode": documentTNList.map((e) => e.documentCode),
                "listFacilityCode": faciList.filter((e) => e.check).map((e) => e.id),
                "listInsuranceCode": [...insuranceTCList, ...insuranceTNList].map((e) => e.insuranceCode),
                "listProductModelCode": [],
                "listStaffCode": userList.map((a) => a.staffCode),
                "listOrgCode": treeDataOrg.filter((a) => a.Checked).map((a) => a.orgCode),
                "listBrandManagementId": treeDataBranch.filter(e => e.type === BrandType.BRAND_NAME).map(e => e.id)
            }
            if (id) {
                // console.log()
                // body.data.modifiedBy = id;
                // body.data.modifiedDate = (new Date().toLocaleDateString("en-GB")).split("/").reverse().join("-") + " 12:00:00";
                const respone = await loanprogramDA.Updateloan(body, data.data.id);
                if (respone.code === '200') {
                    notify(notificationAlert, SUCCESS, 'Chỉnh sửa chương trình vay thành công.')
                    // window.location.reload()
                    navigate('/admin/chuong-trinh-vay');
                } else if (respone.code == '208') {
                    setError("loanProgramCode", {
                        message: "Mã chương trình vay đã tồn tại"
                    })
                    notify(notificationAlert, ERROR, "Mã chương trình vay đã tồn tại")
                } else {
                    notify(notificationAlert, ERROR, respone.message)
                }
            } else {
                const respone = await loanprogramDA.createloan(body);
                if (respone.code === '200') {
                    notify(notificationAlert, SUCCESS, 'Thêm mới chương trình vay thành công.')
                    navigate('/admin/chuong-trinh-vay');
                } else if (respone.code == '208') {
                    setError("loanProgramCode", {
                        message: "Mã chương trình vay đã tồn tại"
                    })
                    notify(notificationAlert, ERROR, "Mã chương trình vay đã tồn tại");
                } else {
                    notify(notificationAlert, ERROR, respone.message);
                }
            }
        }
    };

    const onDeleteNhanHieu = (index) => {
        const updatedItems = [...treeDataBranch];
        updatedItems.splice(index, 1);
        setTreeDataBranch(updatedItems);
    }

    const onDeleteTC = (index) => {
        const updatedItems = [...insuranceTCList];
        updatedItems.splice(index, 1);
        setinsuranceTCList(updatedItems);
    }

    const onDeleteTN = (index) => {
        const updatedItems = [...insuranceTNList];
        updatedItems.splice(index, 1);
        setinsuranceTNList(updatedItems);
    }

    const onDeleteDocumentTC = (index) => {
        const updatedItems = [...documentTCList];
        updatedItems.splice(index, 1);
        setdocumentTCList(updatedItems);
    }

    const onDeleteDocumentTN = (index) => {
        const updatedItems = [...documentTNList];
        updatedItems.splice(index, 1);
        setdocumentTNList(updatedItems);
    }

    const onDeleteUser = (index) => {
        const updatedItems = [...userList];
        updatedItems.splice(index, 1);
        setuserList(updatedItems);
    }

    const onchangeSearchOrg = async (event) => {
        const res = await CocauTochucDA.getListOrg(event.target.value);
        if (res != null) {
            setTreeDataORG(res)
        }
    }

    const renderTreeOrg = (parentID, level = 0) => {
        let tileList = [];
        for (let tileItem of treeDataOrg.filter(e => e.orgParentId === parentID)) {
            tileList.push(<div key={tileItem.id} className="w-table-tree w-frame wbaseItem-value w-col a4bc4d95-db6a-4621-b51f-815c529b2d64" level={10} cateid={140} >
                {treeTileOrg(tileItem, level)}
                {renderTreeOrg(tileItem.id, level + 1)}
            </div>
            )
        }
        return tileList;
    }

    const renderTreeAgent = (parentID, level = 0) => {
        let tileList = [];
        for (let tileItem of treeDataAgent.filter(e => e.parentCode === parentID)) {
            tileList.push(<div key={tileItem.code} className="w-table-tree w-frame wbaseItem-value w-col a4bc4d95-db6a-4621-b51f-815c529b2d64" level={10} cateid={140} >
                {treeTileAgent(tileItem, level)}
                {renderTreeAgent(tileItem.code, level + 1)}
            </div>
            )
        }
        return tileList;
    }
    const OnchangeSwitch = async () => {
        var obj = {
            code: data.data.loanProgramCode,
            status: data.data.status === 1 ? 0 : 1
        };
        const respone = await loanprogramDA.UpdateStatusloan(obj);
        if (respone.code === '200') {
            setData(prevData => ({
                ...prevData,
                data: {
                    ...prevData.data,
                    status: prevData.data.status === 1 ? 0 : 1,
                },
            }));
            setValue('status', data.data.status === 1 ? 0 : 1)
        } else {
            notify(notificationAlert, ERROR, respone.message)
        }
    }
    const onBlurCheckEnabledEdit = async (ev) => {
        let initInputData = ev;
        if (initInputData.productCategoryCode &&
            initInputData.productCategoryCode.length > 0 &&
            initInputData.productCode &&
            initInputData.productCode.length > 0 &&
            initInputData.loanProgramCode &&
            initInputData.loanProgramCode.length > 0 &&
            initInputData.loanProgramName &&
            initInputData.loanProgramName.length > 0 &&
            initInputData.minimumPrepaymentPercent &&
            initInputData.minimumPrepaymentPercent.length > 0 &&
            initInputData.minimumPayment &&
            initInputData.minimumPayment.length > 0 &&
            initInputData.minimumLoanAmount &&
            initInputData.minimumLoanAmount.length > 0 &&
            initInputData.minimumAgeLimit &&
            initInputData.minimumAgeLimit.length > 0 &&
            initInputData.minimumAgeLimit &&
            initInputData.minimumAgeLimit.length > 0 &&
            initInputData.effectiveDate &&
            initInputData.effectiveDate.length > 0 &&
            initInputData.expirationDate &&
            initInputData.expirationDate.length > 0 &&
            initInputData.disbursementExpiryDate &&
            initInputData.disbursementExpiryDate.length > 0
        ) {
            setEnabledEdit(true);
        } else {
            setEnabledEdit(false);
        }
    }

    const handleScroll = (event) => {
        let scrollFrom = event.target.getBoundingClientRect().y;
        let scrollTo = [...event.target.firstChild.children].filter(e => {
            let eRect = e.getBoundingClientRect();
            return (eRect.y + eRect.height / 2) > scrollFrom;
        }).sort((a, b) => {
            let aRect = a.getBoundingClientRect();
            let bRect = b.getBoundingClientRect();
            return (aRect.y + aRect.height / 2) - (bRect.y + bRect.height / 2);
        });
        let index = [...event.target.firstChild.children].indexOf(scrollTo[0]);
        if (index !== scrollIndex) setScroll(index);
    }

    const clickScrollTo = (event) => {
        let index = [...event.target.parentElement.children].indexOf(event.target);
        let scrollElement = document.querySelector(`.wbaseItem-value[class*="5e84800a-fa74-4776-a146-f94ba5f2b773"]`);
        scrollElement.scrollTo({
            top: scrollElement.firstChild.children[index].offsetTop,
            behavior: "smooth",
        });
    }

    const treeTileOrg = (item, level) => {
        item.toggleShow ??= false;
        let children = treeDataOrg.filter(e => e.orgParentId === item.id);
        if (children.length > 0) item.Checked = children.every(e => e.Checked);
        return (<div className="w-table-tree-tile w-frame wbaseItem-value w-row 845afed4-bf44-4fea-b02c-61e0e1d3293b" level={11} cateid={140} data-id={item.id} >
            <div className="w-frame wbaseItem-value w-row c1df5f11-e5b2-458a-b5aa-52a31cbc6f9b" level={12} cateid={140} >
                <button type='button' onClick={() => {
                    item.toggleShow = !item.toggleShow;
                    setTreeDataORG([...treeDataOrg])
                }} className="w-button w-row btn-table-tree-action" style={{ marginLeft: `${20 * level}px`, marginRight: "4px" }}>
                    <FontAwesomeIcon icon={item.toggleShow ? faCaretDown : faCaretRight} />
                </button>
                <label className="w-check-box wbaseItem-value 5e69c38f-99a2-4648-8f9c-9efae85dff12" level={12} cateid={79}>
                    <input type="checkbox" onChange={(ev) => {
                        let tileList = [...ev.target.closest(".w-table-tree").querySelectorAll(".w-table-tree-tile")].map(e => parseInt(e.getAttribute("data-id")));
                        treeDataOrg.filter(e => tileList.includes(e.id)).forEach(e => e.Checked = ev.target.checked);
                        setTreeDataORG([...treeDataOrg]);
                    }} checked={item.Checked} />
                    <svg width="20" height="20">
                        <path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF">
                        </path>
                    </svg>
                </label>
                <div className="w-text wbaseItem-value 099680da-6b48-4ca2-af6e-ce35b5c368e6" level={13} cateid={139} >{item.orgName}</div>
            </div>
        </div>);
    }

    const treeTileAgent = (item, level) => {
        item.toggleShow ??= false;
        let children = treeDataAgent.filter(e => e.parentCode === item.code);
        if (children.length > 0) item.Checked = children.every(e => e.Checked);
        return (<div className="w-table-tree-tile w-frame wbaseItem-value w-row 845afed4-bf44-4fea-b02c-61e0e1d3293b" level={11} cateid={140} data-id={item.code} >
            <div className="w-frame wbaseItem-value w-row c1df5f11-e5b2-458a-b5aa-52a31cbc6f9b" level={12} cateid={140} >
                <button type='button' onClick={() => {
                    item.toggleShow = !item.toggleShow;
                    setTreeDataAgent([...treeDataAgent])
                }} className="w-button w-row btn-table-tree-action" style={{ marginLeft: `${20 * level}px`, marginRight: "4px" }}>
                    <FontAwesomeIcon icon={item.toggleShow ? faCaretDown : faCaretRight} />
                </button>
                <label className="w-check-box wbaseItem-value 5e69c38f-99a2-4648-8f9c-9efae85dff12" level={12} cateid={79}>
                    <input type="checkbox" onChange={(ev) => {
                        let tileList = [...ev.target.closest(".w-table-tree").querySelectorAll(".w-table-tree-tile")].map(e => e.getAttribute("data-id"));
                        treeDataAgent.filter(e => tileList.includes(e.code)).forEach(e => e.Checked = ev.target.checked);
                        setTreeDataAgent([...treeDataAgent]);
                    }} checked={item.Checked} />
                    <svg width="20" height="20">
                        <path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF">
                        </path>
                    </svg>
                </label>
                <div className="w-text wbaseItem-value 099680da-6b48-4ca2-af6e-ce35b5c368e6" level={13} cateid={139} >{item.name}</div>
            </div>
        </div>);
    }

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off' className="w-frame w-form wbaseItem-value w-col d2252dc0-e647-40a4-aa76-55ec06600a9f" level={2} cateid={128}>
            <NotificationAlert ref={notificationAlert} />
            {
                isCancel === false &&
                <WPopup
                    type={PopupType.WARNING}
                    title={!id ? 'Hủy thêm mới người dùng' : 'Hủy chỉnh sửa người dùng'}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => onCancel(true)}
                    cancelAction={() => onCancel(null)}
                    cancelButton={true}
                    cancelTitle='Giữ lại'
                    message={'Những thông tin vừa bạn vừa nhập sẽ không được lưu lại'}
                />
            }
            {
                isNoDataFaci === false &&
                <WPopup
                    type={PopupType.WARNING}
                    title={'Không có dữ liệu'}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => setisNoDataFaci(true)}
                    message={'không có dữ liệu Facility '}
                />
            }
            {
                confirmDel != null &&
                <WPopup
                    type={PopupType.WARNING}
                    title={`Xóa ` + (() => {
                        switch (confirmDel.type) {
                            case 0: // xóa nhãn hiệu
                                return confirmDel.data.name + " khỏi danh sách nhãn hiệu áp dụng";
                                break;
                            case 1: // xóa BHTC
                                return "gói bảo hiểm " + confirmDel.data.insuranceName;
                                break;
                            case 2: // xóa nhân viên
                                return confirmDel.data.fullName + " khỏi danh sách nhân viên không áp dụng";
                                break;
                            case 3: // xóa chứng từ hồ sơ vay
                                return "chứng từ hồ sơ vay " + confirmDel.data.documentName;
                                break;
                            case 4: // xóa chứng từ giải ngân
                                return "chứng từ giải ngân " + confirmDel.data.documentName;
                                break;
                            case 5: // xóa BHTN
                                return "gói bảo hiểm " + confirmDel.data.insuranceName;
                                break;
                            default:
                                break;
                        }
                    })()}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => {
                        switch (confirmDel.type) {
                            case 0:
                                onDeleteNhanHieu(treeDataBranch.findIndex(e => e.id === confirmDel.data.id));
                                break;
                            case 1:
                                onDeleteTC(insuranceTCList.findIndex(e => e.id === confirmDel.data.id));
                                break;
                            case 2:
                                onDeleteUser(userList.findIndex(e => e.id === confirmDel.data.id));
                                break;
                            case 3:
                                onDeleteDocumentTC(documentTCList.findIndex(e => e.id === confirmDel.data.id));
                                break;
                            case 4:
                                onDeleteDocumentTN(documentTNList.findIndex(e => e.id === confirmDel.data.id));
                                break;
                            case 5:
                                onDeleteTN(insuranceTNList.findIndex(e => e.id === confirmDel.data.id));
                                break;
                            default:
                                break;
                        }
                        setconfirmDel(null);
                    }}
                    cancelAction={() => {
                        setconfirmDel(null);
                    }}
                    cancelButton={true}
                    cancelTitle='Giữ lại'
                    message={'Bạn có chắc chắn muốn xóa ?'}
                />
            }
            {
                showPopupInsurance &&
                <PopupSelecInsurance
                    code={insuranceTCList}
                    callback_action={
                        function (a) {
                            setPopupInsurance(false)
                            setinsuranceTCList(a.map(e => e.losInsuranceDetail))
                        }
                    }
                    onClose={function () {
                        setPopupInsurance(false)
                    }}
                />
            }
            {
                showPopupInsuranceTN &&
                <PopupSelecInsurance
                    code={insuranceTNList}
                    callback_action={
                        function (a) {
                            setinsuranceTNList(a.map(e => e.losInsuranceDetail))
                            setPopupInsuranceTN(false)
                        }
                    }
                    onClose={function () {
                        setPopupInsuranceTN(false)
                    }}
                />
            }
            {
                showPopupDocument &&
                <PopupSelecDocument
                    code={documentTCList}
                    callback_action={
                        function (a) {
                            setdocumentTCList(a.map(e => e.losDocumentDetail))
                            setPopupDocument(false)
                        }
                    }
                    onClose={function () {
                        setPopupDocument(false)
                    }}
                />
            }
            {
                showPopupDocumentTN &&
                <PopupSelecDocument
                    code={documentTNList}
                    callback_action={
                        function (a) {
                            setdocumentTNList(a.map(e => e.losDocumentDetail))
                            setPopupDocumentTN(false)
                        }
                    }
                    onClose={function () {
                        setPopupDocumentTN(false)
                    }}
                />
            }
            {
                showPopupUser &&
                <PopupSelectUser
                    code={userList}
                    callback_action={
                        function (a) {
                            setuserList(a)
                            setPopupUser(false)
                        }
                    }
                    refnoti={notificationAlert}
                    onClose={function () {
                        setPopupUser(false)
                    }}
                    isCocau={false}
                    lstorgId={[]} //TODO: cần truyền list user khi select tại box tổ chức
                />
            }
            {
                showPopupBranch &&
                <PopupSelecBrand
                    listBrandAll={brandListAll.map(e => {
                        if (treeDataBranch.some(item => item.id === e.id)) e.check = true;
                        return e;
                    })}
                    callback_action={
                        function (a) {
                            setTreeDataBranch(a);
                            setPopupBranch(false)
                        }
                    }
                    onClose={function () {
                        setPopupBranch(false)
                    }}
                />
            }
            <div className="w-frame wbaseItem-value w-col 795a2ade-6baa-44a8-b3e5-a9213019177a" level={3} cateid={140}>
                <div className="w-frame wbaseItem-value w-row ad29caa7-a94a-4fe9-944a-8dde459e8284" level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col 8600343d-6462-40af-856a-3bbf97867a85" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row ad1e5980-a6c8-4455-87c5-d8588869974a" level={6} cateid={140}>
                            <div className="w-text wbaseItem-value a8d58b8f-c3fd-4caf-8cec-4bce84889658" level={7} cateid={139}>
                                {id ? 'Chỉnh sửa thông tin chương trình vay' : 'Thêm mới chương trình vay'}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row breadcrumb 803e7b28-cce3-4d11-9e4b-81afbe93b4b4" level={6} cateid={140}>
                            <button type="button" className="w-button wbaseItem-value w-row 69e574d8-7ea9-4bd3-b0aa-a19e32788052" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value 2eb05000-ebfb-4431-a66a-11627398f179" level={8} cateid={139}>Quản trị hệ thống</div>
                                <div className="w-svg wbaseItem-value 850c9e2f-3dbc-4bfc-b07f-8e9a8d7776bf" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value 2eb05000-ebfb-4431-a66a-11627398f179" level={8} cateid={139}>Chương trình vay</div>
                                <div className="w-svg wbaseItem-value 850c9e2f-3dbc-4bfc-b07f-8e9a8d7776bf" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                            <div className="w-text wbaseItem-value a2a92a9e-0a9b-4741-a4e8-d07b2e0bff9b" level={7} cateid={139} style={{ marginTop: "2px" }}>
                                {id ? 'Chỉnh sửa' : 'Thêm mới'}</div>
                        </div>
                    </div>
                    {id ? <div className="w-frame wbaseItem-value w-row 0ef46e31-4af4-4b4b-9b9a-64eec3133efb" level={5} cateid={140}>
                        <div className="w-text wbaseItem-value 54368f73-0d68-477e-810b-98623942a257" level={6} cateid={139}>{data?.data?.status === 1 ? 'Active' : 'Deactive'}</div>
                        <label className="w-switch wbaseItem-value a6e97769-2e1a-4888-8045-a63e94925bc2" level={6} cateid={81}>
                            <input type="checkbox" onChange={(ev) => {
                                OnchangeSwitch()
                            }} checked={data?.data?.status === 1 ? true : false} />
                            <span className="slider">
                            </span>
                        </label>
                    </div> : null}
                </div>
                <div className="w-frame wbaseItem-value w-col eef47bed-52fd-43a0-abdd-9b605e0d38b7" level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row b609857d-fa09-4bc6-8ae7-4d6160ca44b5" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col 5f32b365-1f5f-4bd6-8ea6-dd85a9436ddf" level={6} cateid={140}>
                            {["Thông tin chung", ...(!id && !enabledEdit ? [] : ["Ký hạn và lãi suất", "Nhãn hiệu", "Bảo hiểm", "Tổ chức và đại lý áp dụng", "Chứng từ"])].map((title, index) =>
                                <button key={`btn-${index}`} type='button' onClick={clickScrollTo} className={"w-button wbaseItem-value w-row 94d4da85-a45b-4ec9-89f1-dc8cbbbd2cc3" + (scrollIndex === index ? " selected-mini-sidebar-option" : "")} level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 4b6c130c-9423-4e4d-bdcb-23cad4d70d57" level={8} cateid={139}>{title}</div>
                                </button>)}
                        </div>
                        <div onScroll={handleScroll} className="w-frame wbaseItem-value w-col 5e84800a-fa74-4776-a146-f94ba5f2b773" scroll="true" level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col 80e9c491-bf4b-430b-bb79-a1ac92fea1bf" level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col 362d74d6-1ecb-40bb-a50d-58420a0490d9" level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 685900b1-b11e-45bc-80ca-2efa31e64214" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value a497858e-2333-43a9-8e82-878b9393f5c4" level={10} cateid={139}>Thông tin chung</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 8e00996b-8c11-4866-b75c-d8ad64519f8e" wrap="wrap" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 303bb059-9c36-41da-a86c-036fbac3bddd" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6456431d-807f-4b00-9f3b-9e0bb7d73c4b" level={12} cateid={139}>Danh mục sản phẩm</div>
                                                <div className="w-text wbaseItem-value 3fff4b86-7df6-4c0a-9751-30d88146f1b8" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={'select2-custom productCategoryCode ' + (errors.productCategoryCode && 'helper-text')} helper-text={errors.productCategoryCode && 'Vui lòng chọn danh mục sản phẩm'}>
                                                <Controller
                                                    name="productCategoryCode"
                                                    control={control}
                                                    {...register("productCategoryCode", {
                                                        required: true
                                                    })}
                                                    style={{ order: 2 }}
                                                    // rules={}
                                                    render={({ field }) => (
                                                        <Select2 {...field}
                                                            data={productCateList.map((item, _) => { return { id: item.code, name: item.name } })}
                                                            options={{ placeholder: 'Chọn danh mục sản phẩm' }}
                                                            onChange={(ev) => {
                                                                setValue(ev.target.name, ev.target.value);
                                                                if (ev.target.value?.length > 0 && errors[ev.target.name]) clearErrors(ev.target.name);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 303bb059-9c36-41da-a86c-036fbac3bddd" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6456431d-807f-4b00-9f3b-9e0bb7d73c4b" level={12} cateid={139}>Sản phẩm</div>
                                                <div className="w-text wbaseItem-value 3fff4b86-7df6-4c0a-9751-30d88146f1b8" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={'select2-custom productCode ' + (errors.productCode && 'helper-text')} helper-text={errors.productCode && 'Vui lòng chọn sản phẩm'}>
                                                <Controller
                                                    name="productCode"
                                                    control={control}
                                                    {...register("productCode", { required: true })}
                                                    style={{ order: 2 }}
                                                    render={({ field }) => (
                                                        <Select2 {...field}
                                                            data={productList.map((item, _) => { return { id: item.code, name: item.name } })}
                                                            options={{ placeholder: 'Chọn sản phẩm' }}
                                                            onChange={(ev) => {
                                                                setValue(ev.target.name, ev.target.value);
                                                                if (ev.target.value?.length > 0 && errors[ev.target.name]) clearErrors(ev.target.name);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>

                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row b628a82a-7327-4458-ad76-bb6b97427e43" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value fa40384f-696e-486c-8f4d-714c85d75638" level={12} cateid={139}>Mã chương trình</div>
                                                <div className="w-text wbaseItem-value a49c81c0-0cf7-45c1-b737-ae120e2b0c81" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row fcb7ea62-8898-4e82-8e2c-46a52340a33f " + (errors.loanProgramCode && ' helper-text')} helper-text={errors.loanProgramCode && (errors.loanProgramCode.message.length > 0 ? errors.loanProgramCode.message : 'Vui lòng nhập mã chương trình')} level={11} cateid={85}>
                                                <div className="wbaseItem-value 0efdac7a-9468-440b-83b8-9709693d637b" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={20} name="loanProgramCode" onInput={(ev) => { ev.target.value = ev.target.value.trim() }} {...register("loanProgramCode", {
                                                            required: 'Vui lòng nhập mã chương trình',
                                                            pattern: {
                                                                value: /^[a-zA-Z0-9]*$/,
                                                                message: 'Mã chương trình không đúng định dạng',
                                                            },
                                                        })} placeholder="Nhập mã chương trình" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 46659001-49de-4e3a-b256-961540ab2cf5" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value f34de7ec-746a-4682-a8d8-2cedf0e92f82" level={12} cateid={139}>Tên chương trình</div>
                                                <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 41385f1b-a7ed-40c2-ae0c-37627fbd2cf0 " + (errors.loanProgramName && ' helper-text')} helper-text={errors.loanProgramName && 'Vui lòng nhập tên chương trình'} level={11} cateid={85}>
                                                <div className="wbaseItem-value 00dc0846-a902-4631-9a29-2584c7f11969" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={50} name="loanProgramName" {...register("loanProgramName", { required: true })} placeholder="Nhập tên chương trình" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row e9511926-d208-4129-8033-e3e90de29876" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 9f32aa1f-9bee-4da5-ab7c-a33d214fd999" level={12} cateid={139}>% trả trước tối thiểu</div>
                                                <div className="w-text wbaseItem-value a49c81c0-0cf7-45c1-b737-ae120e2b0c81" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 6a2d4585-b654-4eb5-806f-fd2a244d648e " + (errors.minimumPrepaymentPercent && ' helper-text')} helper-text={errors.minimumPrepaymentPercent && 'Vui lòng nhập % trả trước tối thiểu'} level={11} cateid={85}>
                                                <div className="wbaseItem-value fdd8e725-bfa9-4e73-903f-fa77428ff62a" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input name="minimumPrepaymentPercent"
                                                            {...register("minimumPrepaymentPercent", {
                                                                required: true,
                                                                onChange: (ev) => {
                                                                    let splitVl = ev.target.value.replace(/[^\d|.]/g, "").split(".");
                                                                    if (splitVl[0] === "") splitVl.shift();
                                                                    if (splitVl.length > 0) {
                                                                        ev.target.value = splitVl[0] + (splitVl.length > 1 ? `.${splitVl.slice(1).join("").substring(0, 2)}` : "");
                                                                    } else {
                                                                        ev.target.value = "";
                                                                    }
                                                                },
                                                            })}
                                                            placeholder="Nhập % trả trước tối thiểu" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row e9511926-d208-4129-8033-e3e90de29876" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 9f32aa1f-9bee-4da5-ab7c-a33d214fd999" level={12} cateid={139}>Khoản thanh toán tối thiểu</div>
                                                <div className="w-text wbaseItem-value a49c81c0-0cf7-45c1-b737-ae120e2b0c81" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 6a2d4585-b654-4eb5-806f-fd2a244d648e " + (errors.minimumPayment && ' helper-text')} helper-text={errors.minimumPayment && 'Vui lòng nhập khoản thanh toán tối thiểu'} level={11} cateid={85} >
                                                <div className="wbaseItem-value fdd8e725-bfa9-4e73-903f-fa77428ff62a" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={30} name="minimumPayment"
                                                            {...register("minimumPayment", {
                                                                required: true,
                                                                onChange: (ev) => {
                                                                    ev.target.value = ev.target.value.replace(/\D/g, "")
                                                                },
                                                                onBlur: (ev) => {
                                                                    ev.target.value = Ultis.money(ev.target.value);
                                                                    onBlurCheckEnabledEdit(ev);
                                                                }
                                                            })}
                                                            placeholder="Nhập Khoản thanh toán tối thiểu" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row e9511926-d208-4129-8033-e3e90de29876" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 9f32aa1f-9bee-4da5-ab7c-a33d214fd999" level={12} cateid={139}>Số tiền vay gốc tối thiểu</div>
                                                <div className="w-text wbaseItem-value a49c81c0-0cf7-45c1-b737-ae120e2b0c81" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 6a2d4585-b654-4eb5-806f-fd2a244d648e " + (errors.minimumLoanAmount && ' helper-text')} helper-text={errors.minimumLoanAmount && 'Vui lòng nhập số tiền vay gốc tối thiểu'} level={11} cateid={85}>
                                                <div className="wbaseItem-value fdd8e725-bfa9-4e73-903f-fa77428ff62a" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={30} name="minimumLoanAmount"
                                                            {...register("minimumLoanAmount", {
                                                                required: true,
                                                                onChange: (ev) => {
                                                                    ev.target.value = ev.target.value.replace(/\D/g, "")
                                                                },
                                                                onBlur: (ev) => {
                                                                    ev.target.value = Ultis.money(ev.target.value);
                                                                }
                                                            })}
                                                            placeholder="Nhập số tiền vay gốc tối thiểu" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row e9511926-d208-4129-8033-e3e90de29876" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 9f32aa1f-9bee-4da5-ab7c-a33d214fd999" level={12} cateid={139}>Số tiền vay gốc tối đa</div>
                                                <div className="w-text wbaseItem-value a49c81c0-0cf7-45c1-b737-ae120e2b0c81" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 6a2d4585-b654-4eb5-806f-fd2a244d648e " + (errors.maximumLoanAmount && ' helper-text')} helper-text={errors.maximumLoanAmount && 'Vui lòng nhập số tiền vay gốc tối đa'} level={11} cateid={85}>
                                                <div className="wbaseItem-value fdd8e725-bfa9-4e73-903f-fa77428ff62a" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={30} name="maximumLoanAmount"
                                                            {...register("maximumLoanAmount", {
                                                                required: true,
                                                                onChange: (ev) => {
                                                                    ev.target.value = ev.target.value.replace(/\D/g, "")
                                                                },
                                                                onBlur: (ev) => {
                                                                    ev.target.value = Ultis.money(ev.target.value);
                                                                }
                                                            })}
                                                            placeholder="Nhập số tiền vay gốc tối đa" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row e9511926-d208-4129-8033-e3e90de29876" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 9f32aa1f-9bee-4da5-ab7c-a33d214fd999" level={12} cateid={139}>Tổng tiền vay tối đa</div>
                                                {/* <div className="w-text wbaseItem-value f00c6c6f-9ea4-4e72-af93-308c46280e32" level={12} cateid={139}>*</div> */}
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 6a2d4585-b654-4eb5-806f-fd2a244d648e "} level={11} cateid={85} >
                                                <div className="wbaseItem-value fdd8e725-bfa9-4e73-903f-fa77428ff62a" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={30} name="maximumLoanAmountTotal"
                                                            {...register("maximumLoanAmountTotal", {
                                                                onChange: (ev) => {
                                                                    ev.target.value = ev.target.value.replace(/\D/g, "")
                                                                },
                                                                onBlur: (ev) => { ev.target.value = Ultis.money(ev.target.value); }
                                                            })}
                                                            placeholder="Nhập tổng tiền vay tối đa" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row e9511926-d208-4129-8033-e3e90de29876" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 9f32aa1f-9bee-4da5-ab7c-a33d214fd999" level={12} cateid={139}>Độ tuổi tối thiểu</div>
                                                <div className="w-text wbaseItem-value a49c81c0-0cf7-45c1-b737-ae120e2b0c81" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 6a2d4585-b654-4eb5-806f-fd2a244d648e " + (errors.minimumAgeLimit && ' helper-text')} helper-text={errors.minimumAgeLimit && 'Vui lòng nhập độ tuổi tối thiểu'} level={11} cateid={85}>
                                                <div className="wbaseItem-value fdd8e725-bfa9-4e73-903f-fa77428ff62a" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={3} name="minimumAgeLimit"
                                                            {...register("minimumAgeLimit", {
                                                                required: true,
                                                                onChange: (ev) => {
                                                                    ev.target.value = ev.target.value.replace(/\D/g, "")
                                                                },
                                                            })}
                                                            placeholder="Nhập độ tuổi tối thiểu" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row e9511926-d208-4129-8033-e3e90de29876" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 9f32aa1f-9bee-4da5-ab7c-a33d214fd999" level={12} cateid={139}>Độ tuổi tối đa</div>
                                                <div className="w-text wbaseItem-value a49c81c0-0cf7-45c1-b737-ae120e2b0c81" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 6a2d4585-b654-4eb5-806f-fd2a244d648e " + (errors.maximumAge && ' helper-text')} helper-text={errors.maximumAge && 'Vui lòng nhập độ tuổi tối đa'} level={11} cateid={85} >
                                                <div className="wbaseItem-value fdd8e725-bfa9-4e73-903f-fa77428ff62a" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={3} name="maximumAge"
                                                            {...register("maximumAge", {
                                                                required: true,
                                                                onChange: (ev) => {
                                                                    ev.target.value = ev.target.value.replace(/\D/g, "")
                                                                },
                                                            })}
                                                            placeholder="Nhập độ tuổi tối đa" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row e7dedea5-3d0e-43ca-86b9-fdc636530c9c" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 5455e896-536c-4f75-9940-a8ba2129423d" level={12} cateid={139}>Trạng thái</div>
                                            </div>
                                            <div className={'select2-custom status-field' + (id ? "" : " disabled")}>
                                                <Controller
                                                    name="status"
                                                    control={control}
                                                    {...register("status",)}
                                                    style={{ order: 2 }}
                                                    render={({ field }) => (
                                                        <Select2 {...field}
                                                            data={[{ id: 1, name: 'Active' }, { id: 0, name: 'Deactive' }]}
                                                            options={{ placeholder: 'Chọn trạng thái' }}

                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 00134998-6925-4788-8420-076328f168a5" wrap="wrap" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md 4dbb1795-28b5-4191-bfe5-d9f0d1009d99" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row e7dedea5-3d0e-43ca-86b9-fdc636530c9c" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 5455e896-536c-4f75-9940-a8ba2129423d" level={12} cateid={139}>File thông báo phát hành nội bộ</div>
                                            </div>
                                            {
                                                filePath ?
                                                    <div className='w-frame wbaseItem-value w-row 6ac84289-acb3-4274-819b-ee8371f76a61'>
                                                        <div className="w-text wbaseItem-value e6d7d81e-044b-4c01-a317-0ea7a0e746a1" level={11} cateid={139} style={{ color: "#367be2", marginTop: "2px" }}>{filePath}</div>
                                                        <button type='button' onClick={() => {
                                                            setValue("imageProfile", []);
                                                            setfilePath(null);
                                                        }} className='w-button wbaseItem-value w-row 048f37f4-3754-433e-a810-b27ee519f8d2' style={{ backgroundColor: "transparent" }}>
                                                            <div className="w-svg wbaseItem-value b77c8d2c-435b-4b57-bf0f-5c6c035ca28d" level={11} cateid={115}>
                                                                <svg width={20} height={20} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.48302 13.1195C3.51435 13.5394 3.70291 13.932 4.01105 14.2189C4.31918 14.5058 4.72421 14.666 5.14524 14.6673H10.8541C11.2752 14.666 11.6802 14.5058 11.9883 14.2189C12.2965 13.932 12.485 13.5394 12.5164 13.1195L13.0408 5.77843H2.95858L3.48302 13.1195Z" fill="#E60012" />
                                                                    <path d="M14.1108 3.55621H10.7775V1.88954C10.7775 1.7422 10.7189 1.60089 10.6147 1.4967C10.5105 1.39252 10.3692 1.33398 10.2219 1.33398H5.77745C5.63011 1.33398 5.4888 1.39252 5.38462 1.4967C5.28043 1.60089 5.2219 1.7422 5.2219 1.88954V3.55621H1.88856C1.74122 3.55621 1.59991 3.61474 1.49573 3.71893C1.39154 3.82311 1.33301 3.96442 1.33301 4.11176C1.33301 4.2591 1.39154 4.40041 1.49573 4.5046C1.59991 4.60879 1.74122 4.66732 1.88856 4.66732H14.1108C14.2581 4.66732 14.3994 4.60879 14.5036 4.5046C14.6078 4.40041 14.6663 4.2591 14.6663 4.11176C14.6663 3.96442 14.6078 3.82311 14.5036 3.71893C14.3994 3.61474 14.2581 3.55621 14.1108 3.55621ZM6.33301 2.4451H9.66634V3.55621H6.33301V2.4451Z" fill="#E60012" />
                                                                </svg>
                                                            </div>
                                                        </button>
                                                    </div> : <div className={"w-upload-file-field w-frame wbaseItem-value w-row 6ac84289-acb3-4274-819b-ee8371f76a61"} level={10} cateid={140}>
                                                        <input
                                                            {...register("imageProfile", { required: false })}
                                                            type="file"
                                                            accept='.doc,.docx, image/png, image/jpeg,.pdf'
                                                            name='imageProfile'
                                                            style={{ width: 0, height: 0, position: "absolute" }}
                                                            onChange={(ev) => {
                                                                if (ev.target.files[0].size > 10485760) {
                                                                    notify(notificationAlert, ERROR, 'Vui lòng chỉ upload file dung lượng tối đa 10Mb');
                                                                } else {
                                                                    var formdata = new FormData();
                                                                    formdata.append('file', ev.target.files[0]);
                                                                    UploadFiledocuments(formdata).then(res => {
                                                                        if (res.code === '200') {
                                                                            setfilePath(res.data.path);
                                                                            setValue(ev.target.name, [...ev.target.files]);
                                                                        }
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <button type='button' onClick={(ev) => {
                                                            ev.preventDefault();
                                                            ev.target.previousElementSibling.showPicker();
                                                        }} className='w-button wbaseItem-value w-row 048f37f4-3754-433e-a810-b27ee519f8d2'>
                                                            <div className="w-svg wbaseItem-value b77c8d2c-435b-4b57-bf0f-5c6c035ca28d" level={11} cateid={115}>
                                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M7 8.83333L12 3L17 8.83333H12.8333V16.3333H11.1667V8.83333H7Z" fill="#28282999">
                                                                    </path>
                                                                    <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#28282999">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                            <div className="w-text wbaseItem-value e6d7d81e-044b-4c01-a317-0ea7a0e746a1" level={11} cateid={139}>Upload</div>
                                                        </button>
                                                    </div>
                                            }
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row col6-xxl col12-xl col24-lg col24-md 46c032bc-2702-411e-8d82-0428a3378958" level={10} cateid={140}>
                                            <label className="w-switch wbaseItem-value ead4b9b8-068b-4cbd-81cd-fa69240f3747" level={11} cateid={81}>
                                                <input type="checkbox" onChange={(ev) => setAPreferentialLoanProgram(ev.target.checked)} checked={isAPreferentialLoanProgram} name="isAPreferentialLoanProgram" />
                                                <span className="slider">
                                                </span>
                                            </label>
                                            <div className="w-text wbaseItem-value dfed25fd-9bde-4eac-b456-516785c0c4fa" level={11} cateid={139}>Là chương trình ưu đãi</div>
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 5dd7e22c-dea6-4e12-b61c-56fa7ed57b13" wrap="wrap" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md c729a445-fdf5-464b-aac5-08d9b7c5650e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 46659001-49de-4e3a-b256-961540ab2cf5" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value f34de7ec-746a-4682-a8d8-2cedf0e92f82" level={12} cateid={139}>Mức độ ưu tiên</div>
                                            </div>
                                            <div className={'select2-custom'}>
                                                <Controller
                                                    name="priorityLevel"
                                                    control={control}
                                                    {...register("priorityLevel")}
                                                    style={{ order: 2 }}
                                                    render={({ field }) => (
                                                        <Select2 {...field}
                                                            data={priorityLevelList.map((item, _) => { return { id: item.code, name: item.name } })}
                                                            options={{ placeholder: 'Chọn mức độ ưu tiên' }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md c729a445-fdf5-464b-aac5-08d9b7c5650e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Ngày hiệu lực</div>
                                                <div className="w-text wbaseItem-value a49c81c0-0cf7-45c1-b737-ae120e2b0c81" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 54e5d651-53d5-43d6-b61d-ffa9b01c9bd3 " + (errors.effectiveDate && ' helper-text')} helper-text={errors.effectiveDate && errors.effectiveDate.message} level={9} cateid={85} placeholder="Từ ngày">
                                                <div className="wbaseItem-value d37be69e-c37b-4962-9031-b8ea9938843d" level={10} cateid={86} type="date">
                                                    <div className="textfield"><input {...register('effectiveDate', { required: 'Vui lòng nhập ngày hiệu lực' })} name="effectiveDate" placeholder="Từ ngày" /></div>
                                                    <div className="suffix-btn-txtfd calendar-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                                            <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md c729a445-fdf5-464b-aac5-08d9b7c5650e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Ngày hết hiệu lực</div>
                                                <div className="w-text wbaseItem-value a49c81c0-0cf7-45c1-b737-ae120e2b0c81" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 54e5d651-53d5-43d6-b61d-ffa9b01c9bd3" + (errors.expirationDate && ' helper-text')} helper-text={errors.expirationDate && errors.expirationDate?.message} level={9} cateid={85} placeholder="đến ngày">
                                                <div className="wbaseItem-value d37be69e-c37b-4962-9031-b8ea9938843d" level={10} cateid={86} type="date">
                                                    <div className="textfield"><input {...register('expirationDate', { required: 'Vui lòng nhập ngày hết hiệu lực' })} name="expirationDate" placeholder="đến ngày" /></div>
                                                    <div className="suffix-btn-txtfd calendar-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                                            <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md c729a445-fdf5-464b-aac5-08d9b7c5650e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Ngày hiệu lực giải ngân</div>
                                                <div className="w-text wbaseItem-value a49c81c0-0cf7-45c1-b737-ae120e2b0c81" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 54e5d651-53d5-43d6-b61d-ffa9b01c9bd3" + (errors.disbursementExpiryDate && ' helper-text')} helper-text={errors.disbursementExpiryDate && errors.disbursementExpiryDate?.message} level={9} cateid={85} placeholder="DD/MM/YYYY">
                                                <div className="wbaseItem-value d37be69e-c37b-4962-9031-b8ea9938843d" level={10} cateid={86} type="date">
                                                    <div className="textfield"><input {...register('disbursementExpiryDate', { required: 'Vui lòng nhập ngày hiệu lực giải ngân' })} name="disbursementExpiryDate" placeholder="DD/MM/YYYY" /></div>
                                                    <div className="suffix-btn-txtfd calendar-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                                            <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                            <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 5dd7e22c-dea6-4e12-b61c-56fa7ed57b13" wrap="wrap" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- note-form-container" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 46659001-49de-4e3a-b256-961540ab2cf5" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value f34de7ec-746a-4682-a8d8-2cedf0e92f82" level={12} cateid={139}>Mô tả</div>
                                            </div>
                                            <textarea {...register("description")}
                                                className="note-form"
                                                rows={3}
                                                name='description'
                                                placeholder="Nhập văn bản..."
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={"w-frame wbaseItem-value w-col 34ad728f-9eee-42f0-9645-6fe6f5f3c2c8" + (!id && !enabledEdit ? " disabled-edit" : "")} level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 1a8621e1-6b9f-4de1-9d7c-aa75f74a71b3" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 2743937a-9d86-445b-ab90-fd44775c165f" level={10} cateid={139}>Kỳ hạn và lãi suất</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 5dd7e22c-dea6-4e12-b61c-56fa7ed57b13" wrap="wrap" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c729a445-fdf5-464b-aac5-08d9b7c5650e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 46659001-49de-4e3a-b256-961540ab2cf5" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value f34de7ec-746a-4682-a8d8-2cedf0e92f82" level={12} cateid={139}>Loại lãi suất</div>
                                                {/* <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div> */}
                                            </div>
                                            <div className={'select2-custom '}>
                                                <Controller
                                                    name="interestType"
                                                    control={control}
                                                    {...register("interestType", { onChange: (ev) => { setInterest(ev.target.value === "D11") } })}
                                                    style={{ order: 2 }}
                                                    render={({ field }) => (
                                                        <Select2 {...field}
                                                            data={[{ id: 'D11', name: 'Một giai đoạn' }, { id: 'D22', name: 'Hai giai đoạn' }]}
                                                            options={{ placeholder: 'Chọn loại Lãi suất' }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Sản phẩm</div>
                                            </div>
                                            <div className="w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a" level={11} cateid={85}>
                                                <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input disabled value={productList.find(item => item.code === getValues("productCode"))?.name ?? "_"} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Product Code</div>
                                            </div>
                                            {/* <div className={'select2-custom '}>
                                                <Controller
                                                    name="productCode1"
                                                    control={control}
                                                    {...register("productCode1", { required: true })}
                                                    style={{ order: 2 }}
                                                    render={({ field }) => (
                                                        <Select2 {...field}
                                                            data={productList.map((item, _) => { return { id: item.code, name: item.code } })}
                                                            options={{ placeholder: 'Chọn mã sản phẩm' }}
                                                            onChange={onBlurCheckEnabledEdit}
                                                        />
                                                    )}
                                                />
                                            </div> */}
                                            <div className="w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a" level={11} cateid={85}>
                                                <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input disabled value={getValues("productCode") ?? "_"} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            checkType == true ?
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Lãi suất năm</div>
                                                    </div>
                                                    <div className="w-textformfield wbaseItem-value w-row 6a2d4585-b654-4eb5-806f-fd2a244d648e" level={11} cateid={85} placeholder='Nhập lãi suất năm'>
                                                        <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                            <div className="textfield">
                                                                <input name="ỉnterestYear" {...register("ỉnterestYear", {
                                                                    onChange: (ev) => {
                                                                        let splitVl = ev.target.value.replace(/[^\d|.]/g, "").split(".");
                                                                        if (splitVl[0] === "") splitVl.shift();
                                                                        if (splitVl.length > 0) {
                                                                            ev.target.value = splitVl[0] + (splitVl.length > 1 ? `.${splitVl.slice(1).join("").substring(0, 2)}` : "");
                                                                        } else {
                                                                            ev.target.value = "";
                                                                        }
                                                                    },
                                                                })} placeholder='Nhập lãi suất năm' />
                                                            </div>
                                                        </div>
                                                        <div className="w-svg wbaseItem-value d225bad7-7f04-48da-9e11-9e4572a1f06a" level={9} cateid={115}>%</div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Lãi suất năm giai đoạn 1</div>

                                                    </div>
                                                    <div className="w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a" level={11} cateid={85} >
                                                        <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                            <div className="textfield">
                                                                <input name="ỉnterestYear1" {...register("ỉnterestYear1")} placeholder='Nhập Lãi suất năm giai đoạn 1' />
                                                            </div>
                                                        </div>
                                                        <div className="w-text wbaseItem-value d225bad7-7f04-48da-9e11-9e4572a1f06a" level={9} cateid={115}>%</div>
                                                    </div>
                                                </div>

                                        }
                                        {
                                            checkType == false ?
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Kỳ hạn giai đoạn 1</div>
                                                    </div>
                                                    <div className="w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a" level={11} cateid={85}>
                                                        <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                            <div className="textfield">
                                                                <input name="period1" {...register("period1")} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        {
                                            checkType == false ? <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                    <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Lãi suất năm giai đoạn 2</div>
                                                </div>
                                                <div className="w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a" level={11} cateid={85} >
                                                    <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                        <div className="textfield">
                                                            <input name="ỉnterestYear2" {...register("ỉnterestYear2")} placeholder='Nhập Lãi suất năm giai đoạn 2' />
                                                        </div>
                                                    </div>
                                                    <div className="w-svg wbaseItem-value d225bad7-7f04-48da-9e11-9e4572a1f06a" level={9} cateid={115}>
                                                        %
                                                    </div>
                                                </div>
                                            </div>
                                                : null
                                        }
                                        <div style={{ padding: 0 }} className="w-frame wbaseItem-value w-col 707c8a0a-64e3-4f3b-8de9-f051185c882a" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 80cab5fe-d641-4d90-9a27-4ec2cf1cf61f" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value fe7b84f2-fb6d-4707-b841-252c212ab7e0" level={10} cateid={139}></div>
                                                <div className="w-frame wbaseItem-value w-row 3cd63587-ea54-451d-adb3-f2c0b3d0faac" level={10} cateid={140}>
                                                    <button type="button" onClick={() => getFaciList()}
                                                        className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                                                        level={7} cateid={29}>
                                                        <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3"
                                                            level={8} cateid={139}>Lấy danh sách</div>
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                faciList.length > 0 ? <table className="w-table wbaseItem-value acdb17f7-7298-4da8-9589-dbe9ebc03d2d" type={0} level={9} cateid={117}>
                                                    <tbody>
                                                        <tr className="table-row">
                                                            <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 98a29077-b149-4be9-90f6-10a9cbd1ddca" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 659f6468-fe90-4dce-bc21-bb612eccd123" level={11} cateid={139}>STT</div>
                                                                </div>
                                                            </td>
                                                            <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row f9e32d7a-e4cc-4326-9119-392e96344559" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value bc9e8f64-c88f-4d95-baca-cf39b20eb113" level={11} cateid={139}>Facility Code</div>
                                                                </div>
                                                            </td>
                                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row ae22ea9e-077b-432c-bc1c-a9a57538c9fd" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 87de7788-7f34-42fb-8b78-df6a4710564d" level={11} cateid={139}>Loại Lãi suất</div>
                                                                </div>
                                                            </td>
                                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row ae22ea9e-077b-432c-bc1c-a9a57538c9fd" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 87de7788-7f34-42fb-8b78-df6a4710564d" level={11} cateid={139}>Kỳ hạn</div>
                                                                </div>
                                                            </td>
                                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row ae22ea9e-077b-432c-bc1c-a9a57538c9fd" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 87de7788-7f34-42fb-8b78-df6a4710564d" level={11} cateid={139}>Lãi suất năm</div>
                                                                </div>
                                                            </td>
                                                            <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 5116fc61-435f-4195-a91d-55c670d3e7f2" level={10} cateid={140}>
                                                                    <label
                                                                        onClick={() => OnchangeCheckedAll()}
                                                                        className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                                        <input type="checkbox" name="Pick" defaultChecked={CheckAllFaci} />
                                                                        <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                                    </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        {faciList?.map((item, index) => {
                                                            return <tr key={item.id} className="table-row">
                                                                <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                                    <div className="w-frame wbaseItem-value w-row 27a0c60c-9b7a-43ec-a713-a3d89589da97" level={10} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 1fb7132e-3bf7-424b-b832-8f0f73847d52" level={11} cateid={139}>{index + 1}</div>
                                                                    </div>
                                                                </td>
                                                                <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                                    <div className="w-frame wbaseItem-value w-row d5e0c5cf-02b9-42d5-a909-2959f22b7200" level={10} cateid={140}>
                                                                        <div className="w-text wbaseItem-value d56fe0b4-c9b1-4cf8-9fef-704fe17c8929" level={11} cateid={139}>{item.code}</div>
                                                                    </div>
                                                                </td>
                                                                <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                                    <div className="w-frame wbaseItem-value w-row abe4b1fc-38a3-4c48-80f0-fe1e5bc2c05d" level={10} cateid={140}>
                                                                        <div className="w-text wbaseItem-value b728612a-b2fd-4a35-8ec6-1c98652a252c" level={11} cateid={139}>{item.name}</div>
                                                                    </div>
                                                                </td>
                                                                <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                                    <div className="w-frame wbaseItem-value w-row 61a3e312-f25a-4ac9-94ea-d8fb9ccfc65c" level={10} cateid={140}>
                                                                        <label className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                                            <input type="checkbox" onChange={() => OnchangeCheckedFacility(index)} checked={item.check ? true : false} name="Pick" />
                                                                            <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        })}

                                                    </tbody>
                                                </table> : null
                                            }

                                        </div>

                                    </div>
                                </div>
                                <div className={"w-frame wbaseItem-value w-col 707c8a0a-64e3-4f3b-8de9-f051185c882a" + (!id && !enabledEdit ? " disabled-edit" : "")} level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row a5b10dd5-be02-459e-8ca9-d2c1eac23a9b" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 4d7b6dd2-4b71-4688-9223-394c75555b3a" level={10} cateid={139}>Nhãn hiệu</div>
                                        <div className="w-frame wbaseItem-value w-row 3cd63587-ea54-451d-adb3-f2c0b3d0faac" level={10} cateid={140}>
                                            <button type="button" onClick={() => setPopupBranch(true)}
                                                className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                                                level={7} cateid={29}>
                                                <div className="w-svg wbaseItem-value a3e0f6f2-4d22-4977-ab6f-90d0568e534f" level={8}
                                                    cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                                            fill="#FFFFFFFF" />
                                                    </svg>
                                                </div>
                                                <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3"
                                                    level={8} cateid={139}>Thêm</div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='w-frame wbaseItem-value w-row' scroll="true" style={{ width: "100%" }}>
                                        <table className="w-table wbaseItem-value 57481e74-6cc4-46c7-8ef9-7940be03049b" type={0} level={9} cateid={117}>
                                            <tbody>
                                                <tr className="table-row">
                                                    <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 833d1086-744f-4e5b-bce6-8a23fb416c16" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 6401335e-c0cd-4161-9c44-2361b14ced54" level={11} cateid={139}>STT</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Nhà sản xuất</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Mã nhãn hiệu</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Nhãn hiệu</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Tên nhãn hiệu</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x6" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Giá bán tối đa</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 0975bd8f-79a6-4fa0-90fd-a91edd730be8" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 4a31279c-f933-4412-9f5e-c1f4d6823ddc" level={11} cateid={139}>Hành động</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {
                                                    treeDataBranch.filter(e => e.type === BrandType.BRAND_NAME).map((item, index) => {
                                                        let brand = brandListAll.find(e => e.id === item.parentId);
                                                        let brandCode = brandListAll.find(e => e.id === brand.parentId);
                                                        let manufacture = brandListAll.find(e => e.id === brandCode.parentId);
                                                        return (<tr key={item.id} className="table-row">
                                                            <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 214917be-544a-42cb-adf6-71b0c475192b" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value f5157e3e-2efd-449e-abfb-6f8365d654ba" level={11} cateid={139}>{index + 1}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{manufacture?.name ?? "_"}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{brandCode?.name ?? "_"}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{brand?.name ?? "_"}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{item?.name ?? "_"}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x6" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{item.maxPrice ? Ultis.money(item.maxPrice) : "_"}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 35b1bfd5-04ac-412e-ac69-a88954aa0d51" level={10} cateid={140}>
                                                                    <div
                                                                        onClick={() => setconfirmDel({ type: 0, data: item })}
                                                                        className="w-svg wbaseItem-value 3d5d2356-bf4e-4d4c-a639-5ca33740a4f5" level={11} cateid={115}>
                                                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                                            </path>
                                                                            <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                                            </path>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className={"w-frame wbaseItem-value w-col 707c8a0a-64e3-4f3b-8de9-f051185c882a" + (!id && !enabledEdit ? " disabled-edit" : "")} level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row e5e66494-c4d0-4971-9e96-69303f64cdfb" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 8d73e60d-692b-4ada-ad75-f802bf1437bc" level={10} cateid={139}>Bảo hiểm dựa trên khả năng tài chính của khách hàng</div>
                                        <div className="w-frame wbaseItem-value w-row 3cd63587-ea54-451d-adb3-f2c0b3d0faac" level={10} cateid={140}>
                                            <button type="button" onClick={() => setPopupInsurance(true)}
                                                className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                                                level={7} cateid={29}>
                                                <div className="w-svg wbaseItem-value a3e0f6f2-4d22-4977-ab6f-90d0568e534f" level={8}
                                                    cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                                            fill="#FFFFFFFF" />
                                                    </svg>
                                                </div>
                                                <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3"
                                                    level={8} cateid={139}>Thêm</div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='w-frame wbaseItem-value w-row' scroll="true" style={{ width: "100%" }}>
                                        <table style={{ marginBottom: '50px' }} className="w-table wbaseItem-value 57481e74-6cc4-46c7-8ef9-7940be03049b" type={0} level={9} cateid={117}>
                                            <tbody>
                                                <tr className="table-row">
                                                    <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 833d1086-744f-4e5b-bce6-8a23fb416c16" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 6401335e-c0cd-4161-9c44-2361b14ced54" level={11} cateid={139}>STT</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Mã gói bảo hiểm</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Tên gói bảo hiểm</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Nhà cung cấp</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Chu kỳ bảo hiểm</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Tỷ lệ phí bảo hiểm/<br />Số tiền bảo hiểm</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Tỷ lệ phần trăm <br />Số tiền bảo hiểm</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Hình thức<br /> thanh toán</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Sản phẩm áp dụng</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 0975bd8f-79a6-4fa0-90fd-a91edd730be8" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 4a31279c-f933-4412-9f5e-c1f4d6823ddc" level={11} cateid={139}>Hành động</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {
                                                    insuranceTCList.map((item, index) => {
                                                        return (<tr key={item.id} className="table-row">
                                                            <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 214917be-544a-42cb-adf6-71b0c475192b" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value f5157e3e-2efd-449e-abfb-6f8365d654ba" level={11} cateid={139}>{index + 1}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{item.insuranceCode}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{item.insuranceName}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{item.insuranceSupplierName}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{item.insuranceType}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x6" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{item.feeInsuranceRate}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{Ultis.money(item.feeInsurance)}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x8" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{item.paymentTypeName}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x9" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{item.productName}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x10" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 35b1bfd5-04ac-412e-ac69-a88954aa0d51" level={10} cateid={140}>
                                                                    <div
                                                                        onClick={() => setconfirmDel({ type: 1, data: item })}
                                                                        className="w-svg wbaseItem-value 3d5d2356-bf4e-4d4c-a639-5ca33740a4f5" level={11} cateid={115}>
                                                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                                            </path>
                                                                            <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                                            </path>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ order: 3 }} className="w-frame wbaseItem-value w-row e5e66494-c4d0-4971-9e96-69303f64cdfb" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 8d73e60d-692b-4ada-ad75-f802bf1437bc" level={10} cateid={139}>Bảo hiểm tự nguyện</div>
                                        <div className="w-frame wbaseItem-value w-row 3cd63587-ea54-451d-adb3-f2c0b3d0faac" level={10} cateid={140}>
                                            <button type="button" onClick={() => setPopupInsuranceTN(true)}
                                                className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                                                level={7} cateid={29}>
                                                <div className="w-svg wbaseItem-value a3e0f6f2-4d22-4977-ab6f-90d0568e534f" level={8}
                                                    cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                                            fill="#FFFFFFFF" />
                                                    </svg>
                                                </div>
                                                <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3"
                                                    level={8} cateid={139}>Thêm</div>
                                            </button>
                                        </div>
                                    </div>
                                    <table style={{ order: 4 }} className="w-table wbaseItem-value 57481e74-6cc4-46c7-8ef9-7940be03049b" type={0} level={9} cateid={117}>
                                        <tbody>
                                            <tr className="table-row">
                                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 833d1086-744f-4e5b-bce6-8a23fb416c16" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 6401335e-c0cd-4161-9c44-2361b14ced54" level={11} cateid={139}>STT</div>
                                                    </div>
                                                </td>
                                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Mã gói bảo hiểm</div>
                                                    </div>
                                                </td>
                                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Tên gói bảo hiểm</div>
                                                    </div>
                                                </td>
                                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Nhà cung cấp</div>
                                                    </div>
                                                </td>
                                                <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>chu kỳ bảo hiểm</div>
                                                    </div>
                                                </td>
                                                <td id="1x6" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Tỷ lệ phí bảo hiểm/<br />Số tiền bảo hiểm</div>
                                                    </div>
                                                </td>
                                                <td id="1x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Tỷ lệ phần trăm <br />Số tiền bảo hiểm</div>
                                                    </div>
                                                </td>
                                                <td id="1x8" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Hình thức<br /> thanh toán</div>
                                                    </div>
                                                </td>
                                                <td id="1x9" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Sản phẩm áp dụng</div>
                                                    </div>
                                                </td>
                                                <td id="1x10" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 0975bd8f-79a6-4fa0-90fd-a91edd730be8" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 4a31279c-f933-4412-9f5e-c1f4d6823ddc" level={11} cateid={139}>Hành động</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                insuranceTNList?.map((item, index) => {
                                                    return (<tr key={item.id} className="table-row">
                                                        <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 214917be-544a-42cb-adf6-71b0c475192b" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value f5157e3e-2efd-449e-abfb-6f8365d654ba" level={11} cateid={139}>{index + 1}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{item.insuranceCode}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 38132c92-0034-4609-aba9-ebe04081b688" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 064662ca-e57d-411f-914c-dfe947b7043b" level={11} cateid={139}>{item.insuranceName}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 2e5ee414-5c2e-4fd6-9d1a-df751f426721" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 05f829f1-b942-4b43-a7be-2a6167d653c8" level={11} cateid={139}>{item.insuranceSupplierName}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 2e5ee414-5c2e-4fd6-9d1a-df751f426721" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 05f829f1-b942-4b43-a7be-2a6167d653c8" level={11} cateid={139}>{item.insuranceTypeName}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x6" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 2e5ee414-5c2e-4fd6-9d1a-df751f426721" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 05f829f1-b942-4b43-a7be-2a6167d653c8" level={11} cateid={139}>{item.feeInsuranceRate ?? "0"}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 2e5ee414-5c2e-4fd6-9d1a-df751f426721" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 05f829f1-b942-4b43-a7be-2a6167d653c8" level={11} cateid={139}>{Ultis.money(item.feeInsurance)}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x8" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 2e5ee414-5c2e-4fd6-9d1a-df751f426721" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 05f829f1-b942-4b43-a7be-2a6167d653c8" level={11} cateid={139}>{item.paymentTypeName}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x9" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 2e5ee414-5c2e-4fd6-9d1a-df751f426721" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 05f829f1-b942-4b43-a7be-2a6167d653c8" level={11} cateid={139}>{item.productName}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x10" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 35b1bfd5-04ac-412e-ac69-a88954aa0d51" level={10} cateid={140}>
                                                                <div
                                                                    onClick={() => setconfirmDel({ type: 5, data: item })}
                                                                    className="w-svg wbaseItem-value 3d5d2356-bf4e-4d4c-a639-5ca33740a4f5" level={11} cateid={115}>
                                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                                        </path>
                                                                        <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                                        </path>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className={'w-frame wbaseItem-value w-row' + (!id && !enabledEdit ? " disabled-edit" : "")} style={{ order: 5, width: '100%', maxHeight: "668px" }}>
                                    <div className="w-frame wbaseItem-value w-col 707c8a0a-64e3-4f3b-8de9-f051185c882a custom-ctv" level={8} cateid={140} style={{ height: "100%" }}>
                                        <div className="w-frame wbaseItem-value w-row a5b10dd5-be02-459e-8ca9-d2c1eac23a9b" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value 4d7b6dd2-4b71-4688-9223-394c75555b3a" level={10} cateid={139}>Tổ chức</div>
                                        </div>
                                        <div className="w-textformfield wbaseItem-value w-row 8608de10-3a0b-460b-bd02-7daa7e330fbc" level={11} cateid={85} placeholder="Tìm tổ chức">
                                            <div className="w-svg wbaseItem-value 4ebb371d-8444-4a25-880c-7da3b4c28bb4" level={12} cateid={115}>
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#28282999">
                                                    </path>
                                                </svg>
                                            </div>
                                            <div className="wbaseItem-value 7e29fb6d-0f32-4410-a911-845cc65b4ee6" level={12} cateid={86}>
                                                <div className="textfield">
                                                    <input onChange={onchangeSearchOrg} id="textfield-7e29fb6d-0f32-4410-a911-845cc65b4ee6" placeholder="Tìm tổ chức" />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ order: 2 }} className="w-frame wbaseItem-value w-col 001b60de-122d-4e82-8548-3579a39bead6" scroll="true" level={9} cateid={140}>
                                            {renderTreeOrg()}
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col 707c8a0a-64e3-4f3b-8de9-f051185c882a custom-ctv" level={8} cateid={140} style={{ height: "100%" }}>
                                        <div className="w-frame wbaseItem-value w-row a5b10dd5-be02-459e-8ca9-d2c1eac23a9b" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value 4d7b6dd2-4b71-4688-9223-394c75555b3a" level={10} cateid={139}>Đại lý áp dụng</div>
                                        </div>
                                        <div className="w-textformfield wbaseItem-value w-row 8608de10-3a0b-460b-bd02-7daa7e330fbc" level={11} cateid={85} placeholder="Tìm đại lý">
                                            <div className="w-svg wbaseItem-value 4ebb371d-8444-4a25-880c-7da3b4c28bb4" level={12} cateid={115}>
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#28282999">
                                                    </path>
                                                </svg>
                                            </div>
                                            <div className="wbaseItem-value 7e29fb6d-0f32-4410-a911-845cc65b4ee6" level={12} cateid={86}>
                                                <div className="textfield">
                                                    <input id="textfield-7e29fb6d-0f32-4410-a911-845cc65b4ee6" placeholder="Tìm tổ chức" />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ order: 2 }} className="w-frame wbaseItem-value w-col 001b60de-122d-4e82-8548-3579a39bead6" scroll="true" level={9} cateid={140} >
                                            {renderTreeAgent("")}
                                        </div>
                                    </div>
                                </div>
                                <div className={"w-frame wbaseItem-value w-col 826aba44-1ea9-483c-b9b0-677dadbc8288" + (!id && !enabledEdit ? " disabled-edit" : "")} level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 2e393b3c-659b-46f9-9213-57f67c453bc4" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 5d74da7a-1579-4cc3-a895-8afb6aa077cd" level={10} cateid={139}>Danh sách nhân viên không áp dụng</div>
                                        <div className="w-frame wbaseItem-value w-row 46fb0a4b-b188-4e68-a22a-40c88ff8dc71" level={10} cateid={140}>

                                            <button type="button"
                                                onClick={() => setPopupUser(true)}
                                                className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                                                level={7} cateid={29}>
                                                <div className="w-svg wbaseItem-value a3e0f6f2-4d22-4977-ab6f-90d0568e534f" level={8}
                                                    cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                                            fill="#FFFFFFFF" />
                                                    </svg>
                                                </div>
                                                <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3"
                                                    level={8} cateid={139}>Thêm nhân viên</div>
                                            </button>
                                        </div>
                                    </div>
                                    <table className="w-table wbaseItem-value 32b8f7e8-5237-4d18-8d8c-9f6362b09c55" type={0} level={9} cateid={117}>
                                        <tbody>
                                            <tr className="table-row">
                                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 4a106c3d-ff27-483d-8d0e-6b28c3da8e64" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 846a0d51-af18-4e96-b04f-4937b5d8e314" level={11} cateid={139}>STT</div>
                                                    </div>
                                                </td>
                                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row f9392ba7-8529-406b-8674-ce379efbaf08" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 3fd7dce1-2887-42e8-8207-0610daa627d6" level={11} cateid={139}>Mã nhân viên</div>
                                                    </div>
                                                </td>
                                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row bd519971-215b-4976-98e1-8661c34d3d47" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value dffdde43-be90-40a7-8dc8-2b193cdeb80b" level={11} cateid={139}>Tên nhân viên</div>
                                                    </div>
                                                </td>
                                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 4fe398fc-aca2-42db-814b-c2529492b826" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value f6bd29e0-ef19-41bb-bef7-476af7347b1b" level={11} cateid={139}>Tổ chức</div>
                                                    </div>
                                                </td>
                                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 4fe398fc-aca2-42db-814b-c2529492b826" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value f6bd29e0-ef19-41bb-bef7-476af7347b1b" level={11} cateid={139}>Chức danh</div>
                                                    </div>
                                                </td>
                                                <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row b93dd6c3-d37a-440b-8c0e-407d8f650b47" level={10} cateid={140}>
                                                    </div>
                                                </td>
                                            </tr>
                                            {userList?.map((item, index) => {
                                                return <tr key={item.id} className="table-row">
                                                    <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row c43f3880-4310-427a-ba07-f6e122f7651c" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value ae20dc1e-4423-4b02-a019-b20f4c1d6e57" level={11} cateid={139}>{index + 1}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row d0df66d2-d204-42fd-974f-e544f34f080a" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value be667f07-64e3-42bc-8847-652f44a9cbd3" level={11} cateid={139}>{item.staffCode}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row cf8fcd2d-1493-4ae7-8c71-497893931378" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value f237436e-5a8d-473c-a009-dcc35a9a53c7" level={11} cateid={139}>{item.fullName}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 0a499110-76ad-4639-8812-384b690dad3b" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 9e878c94-2ec9-4842-9c68-744270fc5a26" level={11} cateid={139}>
                                                                {item.orgName ?? "_"}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 0a499110-76ad-4639-8812-384b690dad3b" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 9e878c94-2ec9-4842-9c68-744270fc5a26" level={11} cateid={139}>
                                                                {item.orgTitle?.name ?? "_"}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row f39af48d-1e41-41ee-b2d0-de37d7a8fffb" level={10} cateid={140}>
                                                            <div
                                                                onClick={() => setconfirmDel({ type: 2, data: item })}
                                                                className="w-svg wbaseItem-value 3d5d2356-bf4e-4d4c-a639-5ca33740a4f5" level={11} cateid={115}>
                                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                                    </path>
                                                                    <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ order: 7 }} className={"w-frame wbaseItem-value w-col 707c8a0a-64e3-4f3b-8de9-f051185c882a" + (!id && !enabledEdit ? " disabled-edit" : "")} level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row e5e66494-c4d0-4971-9e96-69303f64cdfb" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 8d73e60d-692b-4ada-ad75-f802bf1437bc" level={10} cateid={139}>Chứng từ hồ sơ vay</div>
                                        <div className="w-frame wbaseItem-value w-row 3cd63587-ea54-451d-adb3-f2c0b3d0faac" level={10} cateid={140}>
                                            <button type="button" onClick={() => setPopupDocument(true)}
                                                className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                                                level={7} cateid={29}>
                                                <div className="w-svg wbaseItem-value a3e0f6f2-4d22-4977-ab6f-90d0568e534f" level={8}
                                                    cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                                            fill="#FFFFFFFF" />
                                                    </svg>
                                                </div>
                                                <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3"
                                                    level={8} cateid={139}>Thêm</div>
                                            </button>
                                        </div>
                                    </div>
                                    <table style={{ marginBottom: '50px' }} className="w-table wbaseItem-value 57481e74-6cc4-46c7-8ef9-7940be03049b" type={0} level={9} cateid={117}>
                                        <tbody>
                                            <tr className="table-row">
                                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 833d1086-744f-4e5b-bce6-8a23fb416c16" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 6401335e-c0cd-4161-9c44-2361b14ced54" level={11} cateid={139}>STT</div>
                                                    </div>
                                                </td>
                                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row c98fcf6d-8bd5-4c10-aa89-b18ad0cd05c1" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value f35c4d36-9c5d-4b24-9bf0-bdf353d12cee" level={11} cateid={139}>Mã chứng từ</div>
                                                    </div>
                                                </td>
                                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 8125c8f0-5bdc-46c0-a2d0-81574895ac4f" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value c76117f7-b1c2-4874-8a27-53d9f3da9d5d" level={11} cateid={139}>Tên chứng từ</div>
                                                    </div>
                                                </td>
                                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Loại chứng từ</div>
                                                    </div>
                                                </td>
                                                <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 0975bd8f-79a6-4fa0-90fd-a91edd730be8" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 4a31279c-f933-4412-9f5e-c1f4d6823ddc" level={11} cateid={139}>Hành động</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                documentTCList?.map((item, index) => {
                                                    return (<tr key={item.id} className="table-row">
                                                        <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 214917be-544a-42cb-adf6-71b0c475192b" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value f5157e3e-2efd-449e-abfb-6f8365d654ba" level={11} cateid={139}>{index + 1}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{item.documentCode}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 38132c92-0034-4609-aba9-ebe04081b688" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 064662ca-e57d-411f-914c-dfe947b7043b" level={11} cateid={139}>{item.documentName}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 2e5ee414-5c2e-4fd6-9d1a-df751f426721" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 05f829f1-b942-4b43-a7be-2a6167d653c8" level={11} cateid={139}>{item.documentTypeName}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 35b1bfd5-04ac-412e-ac69-a88954aa0d51" level={10} cateid={140}>
                                                                <div
                                                                    onClick={() => setconfirmDel({ type: 3, data: item })}
                                                                    className="w-svg wbaseItem-value 3d5d2356-bf4e-4d4c-a639-5ca33740a4f5" level={11} cateid={115}>
                                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                                        </path>
                                                                        <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                                        </path>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{ order: 3 }} className="w-frame wbaseItem-value w-row e5e66494-c4d0-4971-9e96-69303f64cdfb" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 8d73e60d-692b-4ada-ad75-f802bf1437bc" level={10} cateid={139}>Chứng từ giải ngân</div>
                                        <div className="w-frame wbaseItem-value w-row 3cd63587-ea54-451d-adb3-f2c0b3d0faac" level={10} cateid={140}>
                                            <button type="button" onClick={() => setPopupDocumentTN(true)}
                                                className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                                                level={7} cateid={29}>
                                                <div className="w-svg wbaseItem-value a3e0f6f2-4d22-4977-ab6f-90d0568e534f" level={8}
                                                    cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z"
                                                            fill="#FFFFFFFF" />
                                                    </svg>
                                                </div>
                                                <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3"
                                                    level={8} cateid={139}>Thêm</div>
                                            </button>
                                        </div>
                                    </div>
                                    <table style={{ order: 4 }} className="w-table wbaseItem-value 57481e74-6cc4-46c7-8ef9-7940be03049b" type={0} level={9} cateid={117}>
                                        <tbody>
                                            <tr className="table-row">
                                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 833d1086-744f-4e5b-bce6-8a23fb416c16" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 6401335e-c0cd-4161-9c44-2361b14ced54" level={11} cateid={139}>STT</div>
                                                    </div>
                                                </td>
                                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row c98fcf6d-8bd5-4c10-aa89-b18ad0cd05c1" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value f35c4d36-9c5d-4b24-9bf0-bdf353d12cee" level={11} cateid={139}>Mã chứng từ</div>
                                                    </div>
                                                </td>
                                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 8125c8f0-5bdc-46c0-a2d0-81574895ac4f" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value c76117f7-b1c2-4874-8a27-53d9f3da9d5d" level={11} cateid={139}>Tên chứng từ</div>
                                                    </div>
                                                </td>
                                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 612f8bf6-9ccb-47e2-9af2-1beeb25e28c3" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 924facca-229e-4cfd-845e-976f4505fd00" level={11} cateid={139}>Loại chứng từ</div>
                                                    </div>
                                                </td>
                                                <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 0975bd8f-79a6-4fa0-90fd-a91edd730be8" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 4a31279c-f933-4412-9f5e-c1f4d6823ddc" level={11} cateid={139}>Hành động</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                documentTNList?.map((item, index) => {
                                                    return (<tr key={item.id} className="table-row">
                                                        <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 214917be-544a-42cb-adf6-71b0c475192b" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value f5157e3e-2efd-449e-abfb-6f8365d654ba" level={11} cateid={139}>{index + 1}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 6670c102-bd8e-43f6-a9ed-d1a7203fd4b4" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value d6818abb-98d4-4b39-b7f8-ae7aa4da0f0e" level={11} cateid={139}>{item.documentCode}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 38132c92-0034-4609-aba9-ebe04081b688" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 064662ca-e57d-411f-914c-dfe947b7043b" level={11} cateid={139}>{item.documentName}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 2e5ee414-5c2e-4fd6-9d1a-df751f426721" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 05f829f1-b942-4b43-a7be-2a6167d653c8" level={11} cateid={139}>{item.documentTypeName ?? ""}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 35b1bfd5-04ac-412e-ac69-a88954aa0d51" level={10} cateid={140}>
                                                                <div
                                                                    onClick={() => setconfirmDel({ type: 3, data: item })}
                                                                    className="w-svg wbaseItem-value 3d5d2356-bf4e-4d4c-a639-5ca33740a4f5" level={11} cateid={115}>
                                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                                        </path>
                                                                        <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                                        </path>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    !id ? null : <div className="w-frame wbaseItem-value w-col 626e9d18-582b-465d-8eb2-45f4cc7f0ae4" level={8} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row 3319e5e4-02f1-40d5-87fe-73153c181d98" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value 900f5c9e-2d75-4fa6-9cf3-b3eaea695116" level={10} cateid={139}>Lịch sử</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 11118142-662b-4709-a44b-5a2cd91a48a8" wrap="wrap" level={9} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl e276a3d2-65d8-4c1c-b518-d02ef3889d20" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value 68b71902-cd86-4180-9336-6c4fc65395f2" level={11} cateid={139}>Người tạo:</div>
                                                <div className="w-text wbaseItem-value 2f808d9c-b47e-48ca-8133-6d67588a7315" level={11} cateid={139}>{data?.data?.createdBy}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 7588ad57-7567-43fe-8a9f-49cfaa1172bb" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value 28129997-3b4e-432b-88a1-a80b26bbae9d" level={11} cateid={139}>Ngày tạo:</div>
                                                <div className="w-text wbaseItem-value a82a9d49-6425-4d05-8ba2-7c67c0e48f96" level={11} cateid={139}>{data?.data?.createdDate ? new Date(data?.data?.createdDate).toLocaleDateString('en-GB') : ''}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl 4be20552-4cee-4471-b9dd-b5ab48f0fcc6" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value bb8eaed1-2c37-484b-83ed-c9734e37df31" level={11} cateid={139}>Người thay đổi cuối:</div>
                                                <div className="w-text wbaseItem-value b9981858-2cd7-44f9-ab7d-bdfd81ff035d" level={11} cateid={139}>{data?.data?.modifiedBy ?? "_"}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 40e66f79-36e0-4978-b62e-aedd5daeaf5d" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value a176e037-444e-4c7e-9dcd-678813be183a" level={11} cateid={139}>Ngày thay đổi cuối:</div>
                                                <div className="w-text wbaseItem-value b25cb0a6-a796-4253-a37f-091689218413" level={11} cateid={139}>{data?.data?.modifiedDate ? new Date(data?.data?.modifiedDate).toLocaleDateString('en-GB') : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-frame wbaseItem-value w-row de68687b-95f6-4c9f-a6e9-31f6165ced53" level={3} cateid={140}>
                <button onClick={() => onCancel(false)} type="button" className="w-button wbaseItem-value w-row 624030d1-8e78-4a06-acef-f3df67b08e8b" level={4} cateid={29}>
                    <div className="w-text wbaseItem-value fec51101-55a6-46c8-91b0-d00b44ded0b5" level={5} cateid={139}>Hủy</div>
                </button>
                <div className="w-frame wbaseItem-value w-row e7817577-026d-4088-b330-1e29b66d873b" level={4} cateid={140}>
                    <button type="submit" className="w-button wbaseItem-value w-row ecfdb3a7-19e7-4a28-8b4d-670404f2c6c8" level={5} cateid={29}>
                        <div className="w-svg wbaseItem-value 2592c61a-086e-4228-91d1-092fb8b35d29" level={6} cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.7337 5.26627C22.0888 5.62129 22.0888 6.19689 21.7337 6.55192L9.91555 18.3701C9.56053 18.7251 8.98493 18.7251 8.6299 18.3701L2.26627 12.0065C1.91124 11.6514 1.91124 11.0758 2.26627 10.7208C2.62129 10.3658 3.19689 10.3658 3.55192 10.7208L9.27273 16.4416L20.4481 5.26627C20.8031 4.91124 21.3787 4.91124 21.7337 5.26627Z" fill="#FFFFFFFF">
                                </path>
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value 4820f7f2-d5f5-4a4b-8ad6-1eaa19c3334e" level={6} cateid={139}>{(!id && !enabledEdit ? "Tiếp" : "Lưu")}</div>
                    </button>
                </div>
            </div>
        </form>


    )
}

