import { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import Select2 from '../../components/select2/Select2';
import { useForm, Controller } from 'react-hook-form';
import '../../css/nhomquyen/masterpage.css';
import Pagination from '../../components/Pagination/Pagination';
import { NavLink, useLocation } from 'react-router-dom';
import { ERROR, notify } from '../../core/AlertNoti';

import { getProductCate, getbproductList } from '../../redux/middleware/api/Filter/Filter';
import loanprogramDA from '../../redux/middleware/api/W_ChuongTrinhVay/loanprogramDA';
import { Ultis } from '../../core/utils';
import { parse, format } from 'date-fns';

export default function Chuongtrinhvay() {
    const { register, handleSubmit, setValue, getValues, control, watch } = useForm({});
    const location = useLocation();
    const [data, setData] = useState([]);
    const [productCateList, setproductCateList] = useState([]);
    const [productList, setproductList] = useState([]);
    const [listCategoryCode, setlistCategoryCode] = useState([]);
    const [listproductCode, setlistproductCode] = useState([]);
    const [total, setTotal] = useState();
    const [pageDetails, setPageDetails] = useState({
        current: 1,
        size: 10
    });
    const notificationAlert = useRef();

    const _InitData = async (page, pageSize, query = location.search) => {
        const effectiveDate = new URLSearchParams(query).get('effectiveDate');
        const expirationDate = new URLSearchParams(query).get('expirationDate');
        const loanProgramCode = new URLSearchParams(query).get('loanProgramCode');
        const loanProgramName = new URLSearchParams(query).get('loanProgramName');
        const listproductCode1 = new URLSearchParams(query).get('listproductCode');
        var listCategoryCode1 = new URLSearchParams(query).get('listCategoryCode');
        const status = new URLSearchParams(query).get('status');
        // listCategoryCode1 = listCategoryCode1?.split(',')
        var obj = {
            "effectiveDate": Ultis.StringDatetoStringDate(effectiveDate, 'dd-MM-yyyy'),
            "loanProgramCode": loanProgramCode ?? "",
            "loanProgramName": loanProgramName ?? "",
            "expirationDate": Ultis.StringDatetoStringDate(expirationDate, 'dd-MM-yyyy'),
            listProductCode: listproductCode1?.split(',') ?? [],
            listCategoryCode: listCategoryCode1?.split(',') ?? [],
            "status": status
        };
        const response = await loanprogramDA.getListLoan(page, pageSize, obj);
        setValue('loanProgramCode', loanProgramCode);
        setValue('effectiveDate', effectiveDate);
        setValue('expirationDate', expirationDate);
        setValue('loanProgramName', loanProgramName);
        // setValue('status', status);
        // setValue('listproductCode', listproductCode1?.split(','));
        setlistproductCode(listproductCode1?.split(',') ?? [])
        setlistCategoryCode(listCategoryCode1?.split(',') ?? [])
        // setValue('listCategoryCode', listCategoryCode1?.split(','));

        if (response != null) {
            setData(response.pageData);
            setTotal(response.total);
        }
    }

    const getAllproduct = async () => {
        const response = await getbproductList(1, 100,);
        if (response != null) {
            setproductList(response.content);
        }
    }
    const getAllproductCate = async () => {
        const response = await getProductCate(1, 100,);
        if (response != null) {
            setproductCateList(response.content);
        }
    }

    const OnchangeSwitch = async (index) => {
        var obj = {
            code: data[index].data.loanProgramCode,
            status: data[index].data.status === 1 ? 0 : 1
        };
        const respone = await loanprogramDA.UpdateStatusloan(obj);
        if (respone.code === '200') {
            var tmp = data[index];
            tmp.data.status = data[index].data.status === 1 ? 0 : 1;
            setData((data) =>
                data.map((item) =>
                    item.data.id === data[index].data.id ? { ...tmp } : item
                ));
        } else {
            notify(notificationAlert, ERROR, respone.message)
        }
    }
    useEffect(() => {
        getAllproduct()
        getAllproductCate()
        setValue('status', 1);
        _InitData(pageDetails.current, pageDetails.size, location.search);
    }, []);
    const onSubmitForm = (event) => {
        const searchParams = new URLSearchParams(location.search)
        if (event.effectiveDate) {
            searchParams.set('effectiveDate', event.effectiveDate);
        } else {
            searchParams.delete('effectiveDate')
        }
        if (event.loanProgramCode)
            searchParams.set('loanProgramCode', event.loanProgramCode.trim());
        else
            searchParams.delete('loanProgramCode')
        if (event.loanProgramName)
            searchParams.set('loanProgramName', event.loanProgramName.trim());
        else
            searchParams.delete('loanProgramName')

        if (event.expirationDate)
            searchParams.set('expirationDate', event.expirationDate);
        else
            searchParams.delete('expirationDate')
        // if (event.productCode)
        //     searchParams.set('productCode', event.productCode);
        // else
        //     searchParams.delete('productCode')
        if (listCategoryCode.length > 0)
            searchParams.set('listCategoryCode', listCategoryCode.join(','));
        else
            searchParams.delete('listCategoryCode')
        if (listproductCode.length > 0)
            searchParams.set('listproductCode', listproductCode.join(','));
        else
            searchParams.delete('listproductCode')
        if (event.status)
            searchParams.set('status', event.status);
        else
            searchParams.delete('status')
        if (event.effectiveDate && event.expirationDate) {
            const from = parse(event.effectiveDate, 'dd/MM/yyyy', new Date());
            const to = parse(event.expirationDate, 'dd/MM/yyyy', new Date());
            if (to < from) {
                notify(notificationAlert, ERROR, 'Ngày hiệu lực phải nhỏ hơn ngày hết hiệu lực')
                return;
            }
        }
        setValue('status', event.status)
        window.history.replaceState(null, null, `?${searchParams.toString()}`);
        _InitData(pageDetails.current, pageDetails.size, searchParams);
    }
    const ressetForm = () => {
        setValue('effectiveDate', null);
        setValue('expirationDate', null);
        setValue('loanProgramCode', null);
        setValue('loanProgramName', null);
        setValue('listCategoryCode', null);
        setValue('listproductCode', null);
        setValue('status', 1);
        setlistCategoryCode([])
        setlistproductCode([])
    }
    return (
        <div className="w-frame wbaseItem-value w-col ea27d422-4911-447e-b1a0-61cc0db85b44" scroll="true" level={3} cateid={140}>
            <NotificationAlert ref={notificationAlert} />
            <div className="w-frame wbaseItem-value w-col 41d2ca9e-2b09-472a-88b5-b6c403239ba1" scroll="true" level={4} cateid={140}>
                <div className="w-frame wbaseItem-value w-row 352ce5fb-2db5-4098-bed5-df59ebef5761" level={5} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col b8eebb9f-5e29-4be1-a46d-a20fa0ace08b" level={6} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row aebfee05-4518-445f-aa7a-ad130cb67a5c" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value eaef1153-2a21-40db-b836-4b668238ec34" level={8} cateid={139}>Chương trình</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row breadcrumb 459f3bd3-9104-4e1c-ac5b-1690fdb622e8" level={7} cateid={140}>
                            <button type="button" className="w-button wbaseItem-value w-row 03005b3e-e132-4ece-a6c3-d67244fcbb0e" name-field="Button" level={8} cateid={29}>
                                <div className="w-text wbaseItem-value abbc3ee8-b129-4537-a565-56ae71e0f086" name-field="Text" level={9} cateid={139}>Quản trị hệ thống</div>
                                <div className="w-svg wbaseItem-value 9dac9b43-147b-4057-85ad-94ddba7400c1" level={9} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6" />
                                    </svg>
                                </div>
                            </button>
                            <div className="w-text wbaseItem-value 8aca59dd-0380-43a5-8cbd-0fdf01ed52b0" level={8} cateid={139}>Chương trình vay</div>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off' className="w-frame w-form wbaseItem-value w-col e3d1eec9-af12-4f81-98de-7b1013b2c45a" level={5} cateid={128}>
                    <div className="w-frame wbaseItem-value w-row b2ec1ec5-af32-4c54-923e-1bdda7a0008e" wrap="wrap" level={6} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 2429e32c-b23c-4fb4-86dd-5e2102fddad3" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 4ee89f5f-68f0-468e-843e-09d9a96bfa8b" level={8} cateid={139}>Mã Chương trình</div>
                            <div className="w-textformfield wbaseItem-value w-row dc1a2708-e1c6-4d7e-b90e-d2a8625a0bb5" name-field="RoleID" level={8} cateid={85} placeholder="Nhập mã Chương trình">
                                <div className="wbaseItem-value 6769e680-9a6d-4aa6-8057-e127a10c8cfd" name-field="Textfield" level={9} cateid={86}>
                                    <div className="textfield">
                                        <input {...register('loanProgramCode')} name="loanProgramCode" placeholder="Nhập mã Chương trình" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 6156f408-3027-4da4-8839-a18ba6bc5467" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value e5b046d1-dcc2-4846-8cec-196e33e62f8c" level={8} cateid={139}>Tên Chương trình</div>
                            <div className="w-textformfield wbaseItem-value w-row 71eadcce-cb12-4862-b797-a38ea6beb51d" name-field="RoleName" level={8} cateid={85} placeholder="Nhập tên Chương trình">
                                <div className="wbaseItem-value 8a2cb047-aaef-4cf4-aa60-7e239596bd52" name-field="Textfield" level={9} cateid={86}>
                                    <div className="textfield">
                                        <input  {...register('loanProgramName')} name="loanProgramName" placeholder="Nhập tên Chương trình" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 77769d60-55f3-4066-b5aa-eae222a0b63d" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 912e095d-5aa5-4cd7-b16b-6f244f51aac0" level={8} cateid={139}>Danh mục sản phẩm</div>
                            <Controller
                                name="listCategoryCode"
                                control={control}
                                {...register("listCategoryCode")}
                                style={{ order: 2 }}
                                render={({ field }) => (
                                    <Select2 {...field}
                                        value={listCategoryCode}
                                        multiple={true}
                                        data={productCateList.map((item, _) => { return { id: item.code, name: item.name } })}
                                        options={{ placeholder: 'Chọn danh mục' }}
                                        // isFirst={true}
                                        // nameFirst={'Chọn danh mục sản phẩm'}
                                        onChange={(event) => {
                                            // if(event.target.value){
                                            //     console.log(event.target.value)
                                            //     setlistCategoryCode([...listCategoryCode,event.target.value])
                                            // }

                                            // if (event.target.value) {
                                            //     debugger;
                                            //     var check = listCategoryCode.some((a) => a === event.target.value);
                                            //     var i = listCategoryCode.indexOf((a) => a === event.target.value);
                                            //     if (check) {
                                            //         var updatelst = [...listCategoryCode];
                                            //         updatelst.splice(i, 1);
                                            //         setlistCategoryCode(updatelst)
                                            //     } else {
                                            //         var updatelst = [...listCategoryCode];
                                            //         updatelst.push(event.target.value)
                                            //         setlistCategoryCode(updatelst)
                                            //     }
                                            //     console.log(event.target.value);                                              
                                            // }
                                            if (event.target.value) {
                                                const selectedValues = Array.from(event.target.options)
                                                    .filter(option => option.selected)
                                                    .map(option => option.value);
                                                setlistCategoryCode(selectedValues);
                                            } else {
                                                setlistCategoryCode([]);
                                            }


                                        }}
                                    />
                                )}
                            />
                            {/* <select multiple onChange={(event) => {
                                debugger;
                                const selectedValues = Array.from(event.target.options)
      .filter(option => option.selected)
      .map(option => option.value);
                                setlistCategoryCode(selectedValues);
                            }}>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select> */}
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 77769d60-55f3-4066-b5aa-eae222a0b63d" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 912e095d-5aa5-4cd7-b16b-6f244f51aac0" level={8} cateid={139}>Sản phẩm</div>

                            <Controller
                                name="listproductCode"
                                control={control}
                                {...register("listproductCode")}
                                style={{ order: 2 }}
                                render={({ field }) => (
                                    <Select2 {...field}
                                        value={listproductCode}
                                        multiple={true}
                                        data={productList.map((item, _) => { return { id: item.code, name: item.name } })}
                                        options={{ placeholder: 'Chọn sản phẩm áp dụng' }}
                                        onChange={(event) => {
                                            // if(event.target.value){
                                            //     console.log(event.target.value)
                                            //     setlistCategoryCode([...listCategoryCode,event.target.value])
                                            // }

                                            // if (event.target.value) {
                                            //     debugger;
                                            //     var check = listCategoryCode.some((a) => a === event.target.value);
                                            //     var i = listCategoryCode.indexOf((a) => a === event.target.value);
                                            //     if (check) {
                                            //         var updatelst = [...listCategoryCode];
                                            //         updatelst.splice(i, 1);
                                            //         setlistCategoryCode(updatelst)
                                            //     } else {
                                            //         var updatelst = [...listCategoryCode];
                                            //         updatelst.push(event.target.value)
                                            //         setlistCategoryCode(updatelst)
                                            //     }
                                            //     console.log(event.target.value);                                              
                                            // }
                                            if (event.target.value) {
                                                debugger;
                                                const selectedValues = Array.from(event.target.options)
                                                    .filter(option => option.selected)
                                                    .map(option => option.value);
                                                setlistproductCode(selectedValues);
                                            } else {
                                                setlistproductCode([]);
                                            }


                                        }}

                                    />
                                )}
                            />
                        </div>
                        {/* <div className="w-frame wbaseItem-value w-col" level={7} cateid={140} id="da4cf8c3-311f-4580-8b87-6709f8160053"> */}
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col12-lg col12-md col24 col24-sm 77769d60-55f3-4066-b5aa-eae222a0b63d" level={7} cateid={140}>


                            <div className="w-frame wbaseItem-value w-row" level={8} cateid={140} id="676792c9-5f4a-45bd-88ce-db3e2dd536a0">
                                <div className="w-text wbaseItem-value" level={8} cateid={139} id="ad483c96-1a4e-4eda-ac8d-9360ed0e6722">Ngày hiệu lực</div>
                                <div className="w-text wbaseItem-value" level={9} cateid={139} id="ebfc245d-bfc0-4f47-9b0c-d7fd80a8e9b6">&nbsp;&nbsp;</div>
                                <div className="w-text wbaseItem-value" level={8} cateid={139} id="ad483c96-1a4e-4eda-ac8d-9360ed0e6722">Ngày hết hiệu lực</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row" level={8} cateid={140} id="676792c9-5f4a-45bd-88ce-db3e2dd536a0">
                                <div className="w-textformfield wbaseItem-value w-row" name-field="createdDateFrom" level={9} cateid={85} id="54e5d651-53d5-43d6-b61d-ffa9b01c9bd3" placeholder="Từ ngày">
                                    <div className="wbaseItem-value d37be69e-c37b-4962-9031-b8ea9938843d" level={10} cateid={86} type="date">
                                        <div className="textfield">
                                            <input name="effectiveDate" placeholder="Từ ngày" {...register("effectiveDate")} />
                                        </div>
                                        <div className="suffix-btn-txtfd calendar-icon"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                            <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" />
                                            <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" />
                                            <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" />
                                            <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                            <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                            <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" />
                                            <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" />
                                            <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                        </svg></div>
                                    </div>
                                </div>
                                <div className="w-text wbaseItem-value" level={9} cateid={139} id="ebfc245d-bfc0-4f47-9b0c-d7fd80a8e9b6">-</div>
                                <div className="w-textformfield wbaseItem-value w-row" name-field="createdDateTo" level={9} cateid={85} id="e6b07ff0-db76-400a-835f-1c9671ea0e1c" placeholder="Đến ngày">
                                    <div className="wbaseItem-value" level={10} cateid={86} id="6da7e7b2-aded-438e-b121-5127229e93c0" type="date" >
                                        <div className="textfield"><input name="expirationDate" placeholder="Đến ngày" {...register("expirationDate")} /></div>
                                        <div className="suffix-btn-txtfd calendar-icon"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                            <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" />
                                            <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" />
                                            <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" />
                                            <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                            <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                            <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" />
                                            <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" />
                                            <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                        </svg></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col12-lg col12-md col24 col24-sm 77769d60-55f3-4066-b5aa-eae222a0b63d" level={7} cateid={140}>
                            <div className="w-text wbaseItem-value 912e095d-5aa5-4cd7-b16b-6f244f51aac0" level={8} cateid={139}>Trạng thái</div>
                            <Controller
                                name="status"
                                control={control}
                                {...register("status")}
                                style={{ order: 2 }}
                                render={({ field }) => (
                                    <Select2 {...field}

                                        data={[{ id: 1, name: 'Active' }, { id: 0, name: 'Deactive' }]}
                                        // options={{ placeholder: 'Chọn trạng thái' }}
                                        isFirst={true}
                                        nameFirst={'Blank'}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row 60eb7691-2d67-402e-a13f-6aba8d5dfe3c" level={6} cateid={140}>
                        <button type="button" onClick={ressetForm} className="w-button wbaseItem-value w-row 8c46835a-80fa-488b-979e-419f7ac8efec" name-field="Button" level={7} cateid={29}>
                            <div className="w-svg wbaseItem-value cef48042-9809-4985-bddf-aa647f81640e" level={8} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.0313 2.00835C21.5288 2.07581 21.8774 2.53382 21.81 3.03135L21.0827 8.39498C21.0153 8.89173 20.5586 9.2402 20.0617 9.17395L14.6072 8.44668C14.1095 8.38032 13.7598 7.92309 13.8262 7.42541C13.8926 6.92774 14.3498 6.57809 14.8475 6.64445L18.3484 7.11124C16.8702 5.25663 14.5726 4.09064 12 4.09064C7.50208 4.09064 3.81818 7.77454 3.81818 12.2725C3.81818 12.7745 3.41117 13.1816 2.90909 13.1816C2.40701 13.1816 2 12.7745 2 12.2725C2 6.77038 6.49792 2.27246 12 2.27246C15.0381 2.27246 17.7813 3.61127 19.6033 5.77412L20.0083 2.78705C20.0757 2.28953 20.5338 1.94089 21.0313 2.00835Z" fill="#28282999" />
                                    <path d="M22 12.2733C22 11.7713 21.5929 11.3643 21.0909 11.3643C20.5888 11.3643 20.1818 11.7713 20.1818 12.2733C20.1818 16.7713 16.4979 20.4552 12 20.4552C9.42714 20.4552 7.12925 19.2889 5.6511 17.434L9.15251 17.9009C9.65018 17.9672 10.1074 17.6176 10.1738 17.1199C10.2401 16.6222 9.89048 16.165 9.39281 16.0986L3.93826 15.3714C3.44137 15.3051 2.98462 15.6536 2.91726 16.1503L2.18999 21.514C2.12253 22.0115 2.47117 22.4695 2.96869 22.537C3.46621 22.6044 3.92423 22.2558 3.99169 21.7583L4.39665 18.7716C6.21865 20.9345 8.96182 22.2733 12 22.2733C17.502 22.2733 22 17.7754 22 12.2733Z" fill="#28282999" />
                                </svg>
                            </div>
                            <div className="w-text wbaseItem-value fb0f0aba-9b42-4b9d-ba05-a185d998a8f7" name-field="Text" level={8} cateid={139}>Xóa bộ lọc</div>
                        </button>
                        <button type="submit" className="w-button wbaseItem-value w-row 38b377c7-da56-44ff-93b4-d77b917d096f" name-field="Button" level={7} cateid={29}>
                            <div className="w-svg wbaseItem-value 8e116fb4-b8c7-4c9d-ad7a-344a0edece09" level={8} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#FFFFFFFF" />
                                </svg>
                            </div>
                            <div className="w-text wbaseItem-value 14b619ba-00f8-4772-b32c-bc35ccb1174d" name-field="Text" level={8} cateid={139}>Tìm kiếm</div>
                        </button>
                    </div>
                </form>
                <div className="w-frame wbaseItem-value w-col e8de9f90-0118-414b-bc69-da273a578779" level={5} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row 6ef2f09f-bb09-4a7f-bca4-ba5fe9a4682c" level={6} cateid={140}>
                        <div className="w-text wbaseItem-value af9be095-13e9-4645-8637-973ae192c9f6" level={7} cateid={139}>
                            Danh sách Chương trình</div>
                        <NavLink to={location.pathname + '/them-moi-chuong-trinh-vay'} type="button" className="nav-link w-button wbaseItem-value w-row fb37cd88-8ee8-40b0-a95a-b2d75b576b2b" name-field="Button" level={7} cateid={29}>
                            <div className="w-svg wbaseItem-value 1710df3c-da56-4872-9f20-1bced4d4431e" level={8} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z" fill="#FFFFFFFF" />
                                </svg>
                            </div>
                            <div className="w-text wbaseItem-value 0e861cd1-5036-4fba-b559-b0ca36be7bb0" name-field="Text" level={8} cateid={139}>Thêm mới</div>
                        </NavLink>
                    </div>
                    <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                        <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>
                            <table className="w-table wbaseItem-value dbe0f04f-f73f-41cf-a94e-988260f1acdf" type={0} level={6} cateid={117}>
                                <tbody>
                                    <tr className="table-row">
                                        <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row e90e5935-90a6-40b5-a7d4-76d681ac32d2" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value 2485e51c-ff7e-44a9-a5d4-466e898c9988" level={8} cateid={139}>STT</div>
                                            </div>
                                        </td>
                                        <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row e064df7b-f2e0-49ef-a2fe-c4bc517fd403" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value c773fe31-0b32-4424-afa2-d9e81b4b309f" level={8} cateid={139}>Mã chương trình</div>
                                            </div>
                                        </td>
                                        <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Tên chương trình</div>
                                            </div>
                                        </td>
                                        <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Danh mục</div>
                                            </div>
                                        </td>
                                        <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Sản phẩm</div>
                                            </div>
                                        </td>
                                        <td id="1x6" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Ngày hiệu lực</div>
                                            </div>
                                        </td>
                                        <td id="1x7" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Ngày hết hiệu lực</div>
                                            </div>
                                        </td>
                                        <td id="1x8" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Trạng thái</div>
                                            </div>
                                        </td>
                                        <td id="1x9" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row d7bfebbd-090e-43bc-a49c-d11c6432a6c7" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value f491a7b4-eafa-4610-bee4-d82b5c97ff3e" level={8} cateid={139}>Hành động</div>
                                                <div className="w-svg wbaseItem-value 7b48f46e-a820-427e-9bd2-e8cadc861ff3" level={8} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.48 2.2162C12.4217 2.1486 12.3484 2.09415 12.2654 2.05677C12.1824 2.01939 12.0918 2 12 2C11.9082 2 11.8176 2.01939 11.7346 2.05677C11.6516 2.09415 11.5783 2.1486 11.52 2.2162L6.12 9.08426C6.05314 9.16929 6.01243 9.2704 6.00242 9.37626C5.99242 9.48212 6.01351 9.58855 6.06334 9.68362C6.11318 9.77869 6.18978 9.85864 6.28456 9.91452C6.37935 9.9704 6.48857 10 6.6 10H17.4C17.5114 10 17.6207 9.9704 17.7154 9.91452C17.8102 9.85864 17.8868 9.77869 17.9367 9.68362C17.9865 9.58855 18.0076 9.48212 17.9976 9.37626C17.9876 9.2704 17.9469 9.16929 17.88 9.08426L12.48 2.2162Z" fill="#28282999" />
                                                        <path d="M11.52 21.7838C11.5783 21.8514 11.6516 21.9059 11.7346 21.9432C11.8176 21.9806 11.9082 22 12 22C12.0918 22 12.1824 21.9806 12.2654 21.9432C12.3484 21.9059 12.4217 21.8514 12.48 21.7838L17.88 14.9157C17.9469 14.8307 17.9876 14.7296 17.9976 14.6237C18.0076 14.5179 17.9865 14.4114 17.9367 14.3164C17.8868 14.2213 17.8102 14.1414 17.7154 14.0855C17.6207 14.0296 17.5114 14 17.4 14H6.6C6.48857 14 6.37935 14.0296 6.28456 14.0855C6.18978 14.1414 6.11318 14.2213 6.06334 14.3164C6.01351 14.4114 5.99242 14.5179 6.00242 14.6237C6.01243 14.7296 6.05314 14.8307 6.12 14.9157L11.52 21.7838Z" fill="#28282999" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        data.map((item, index) => {
                                            return (
                                                <tr key={item.data?.id} className="table-row">
                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row acbebf44-6c58-482d-86c8-31f3d1649f04" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value 51d88187-e142-427e-9963-328e66e429f6" level={8} cateid={139}>{pageDetails.size * (pageDetails.current - 1) + index + 1}</div>
                                                        </div>
                                                    </td>
                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.data?.loanProgramCode}</div>
                                                        </div>
                                                    </td>
                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row fbf833df-8732-40b3-8c84-6647ac56d57a" level={7} cateid={140}>
                                                            <NavLink to={location.pathname + '/' + item.data?.loanProgramCode} className="w-text wbaseItem-value fd4011f7-127d-4174-8747-026e1bafb83d" level={8} cateid={139} >{item.data?.loanProgramName}</NavLink>
                                                        </div>
                                                    </td>
                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.data?.productCategory}</div>
                                                        </div>
                                                    </td>
                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.data?.product}</div>
                                                        </div>
                                                    </td>
                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.data?.effectiveDate ? Ultis.datetoString(new Date(item.data?.effectiveDate), "dd/mm/yyyy hh:mm") : "_"}</div>
                                                        </div>
                                                    </td>
                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.data?.expirationDate ? Ultis.datetoString(new Date(item.data?.expirationDate), "dd/mm/yyyy hh:mm") : "_"}</div>
                                                        </div>
                                                    </td>
                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                            <div className="w-frame wbaseItem-value w-row eadd2c5d-e811-4f3e-9934-2dacf0e9402a" level={2} cateid={29} style={{ backgroundColor: `${item.data?.status === 1 ? "rgb(228, 249, 238)" : "var(--background-color-background-grey-light-2)"}` }}>
                                                                <div className="w-text wbaseItem-value 38662a33-df93-442d-830c-0d71ce5e816f" level={3} cateid={139} >{item.data?.status === 1 ? 'Active' : 'DeActive'}</div>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 7bc608f0-fb0c-41d6-b607-4ea13b26e9f5" level={7} cateid={140}>
                                                            <label onClick={() => { OnchangeSwitch(index) }} htmlFor="input-16f12539-f6fb-4363-849f-6b0b2ead064a" className="w-switch wbaseItem-value 16f12539-f6fb-4363-849f-6b0b2ead064a" level={8} cateid={81}>
                                                                <input
                                                                    type='checkbox'
                                                                    checked={item.data?.status === 1 ? true : false}
                                                                    name="Switch"
                                                                    disabled
                                                                />
                                                                <span className="slider" />
                                                            </label>
                                                        </div>
                                                    </td>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <Pagination
                        currentPage={pageDetails.current}
                        itemPerPage={pageDetails.size}
                        totalItem={total}
                        onChangePage={(page, size) => {
                            if (pageDetails.current !== page || pageDetails.size !== size) {
                                setPageDetails({ current: page, size: size });
                                _InitData(page, size, location.search);
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}