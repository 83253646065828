import '../../css/user/adduser.css';
import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useParams, NavLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { EditUser, UpdateStatusCustomer, createNewUser, getCustomerbyId, getUserbyId, resetPasswordCustomer } from '../../redux/middleware/api/quanlynguoidung/userDA';
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import NotificationAlert from "react-notification-alert";
import { PopupType, WPopup } from '../../components/Popup/Popup';
import Select2 from '../../components/select2/Select2';
export default function CreateAndEditUserMobile() {
    const { register, handleSubmit, control, getValues, setValue, formState: { errors } } = useForm(

    );
    const notificationAlert = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const [isCancel, setisCancel] = useState();
    const [fileAvatar, setfileAvatar] = useState();
    const [fileImg, setfileImg] = useState();
    let { username } = useParams();
    const [data, setData] = useState({});
    const onCancel = (val) => {
        ;
        setisCancel(val);

        if (val == true) {
            navigate('/admin/customer-mobile');
        }
    }
    const onSaveStatus = async () => {
        debugger;
        const res = await UpdateStatusCustomer(data.username, parseInt(getValues('status')))
        if (res.code == '200') {
            notify(notificationAlert, SUCCESS, 'Cập nhật dữ liệu thành công');
        } else {
            notify(notificationAlert, ERROR, res.message);

        }
    }
    const _InitData = async () => {

        if (username) {
            const res = await getCustomerbyId(username);
            if (res != null) {
                setData(res);
                setValue('status', res.status)
            }
        }
    }
    const resetPass = async () => {
        const res = await resetPasswordCustomer(data.username, data.phone);
        if (res.code == '200') {
            notify(notificationAlert, SUCCESS, 'Reset mật khẩu thành công.');

        } else {
            notify(notificationAlert, ERROR, res.message);
        }
    }
    useEffect(() => {
        _InitData();
    }, []);



    return (
        <form autoComplete='off' className="w-frame w-form wbaseItem-value w-col d2252dc0-e647-40a4-aa76-55ec06600a9f" level={2} cateid={128}>
            <NotificationAlert ref={notificationAlert} />
            {
                isCancel == false &&
                <WPopup
                    type={PopupType.WARNING}
                    title={!username ? 'Hủy thêm mới người dùng' : 'Hủy chỉnh sửa người dùng'}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => onCancel(true)}
                    cancelAction={() => onCancel(null)}
                    cancelButton={true}
                    cancelTitle='Giữ lại'
                    message={'Những thông tin vừa bạn vừa nhập sẽ không được lưu lại'}
                />
            }
            <div className="w-frame wbaseItem-value w-col 795a2ade-6baa-44a8-b3e5-a9213019177a" level={3} cateid={140}>
                <div className="w-frame wbaseItem-value w-row ad29caa7-a94a-4fe9-944a-8dde459e8284" level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col 8600343d-6462-40af-856a-3bbf97867a85" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row ad1e5980-a6c8-4455-87c5-d8588869974a" level={6} cateid={140}>
                            <div className="w-text wbaseItem-value a8d58b8f-c3fd-4caf-8cec-4bce84889658" level={7} cateid={139}>
                                {username ? 'Chỉnh sửa thông tin người dùng Mobile' : 'Thêm mới người dùng Mobile'}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row breadcrumb 803e7b28-cce3-4d11-9e4b-81afbe93b4b4" level={6} cateid={140}>
                            <button type="button" className="w-button wbaseItem-value w-row 69e574d8-7ea9-4bd3-b0aa-a19e32788052" name-field="Button" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value 2eb05000-ebfb-4431-a66a-11627398f179" name-field="Text" level={8} cateid={139}>Quản lý người dùng Mobile</div>
                                <div className="w-svg wbaseItem-value 850c9e2f-3dbc-4bfc-b07f-8e9a8d7776bf" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                            <div className="w-text wbaseItem-value a2a92a9e-0a9b-4741-a4e8-d07b2e0bff9b" level={7} cateid={139}>
                                {username ? 'Chỉnh sửa' : 'Thêm mới'}</div>
                        </div>
                    </div>
                </div>
                <div className="w-frame wbaseItem-value w-col eef47bed-52fd-43a0-abdd-9b605e0d38b7" level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row b609857d-fa09-4bc6-8ae7-4d6160ca44b5" level={5} cateid={140}>

                        <div className="w-frame wbaseItem-value w-col 5e84800a-fa74-4776-a146-f94ba5f2b773" scroll="true" level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col 80e9c491-bf4b-430b-bb79-a1ac92fea1bf" level={7} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col 362d74d6-1ecb-40bb-a50d-58420a0490d9" level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 685900b1-b11e-45bc-80ca-2efa31e64214" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value a497858e-2333-43a9-8e82-878b9393f5c4" level={10} cateid={139}>Thông tin chung</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 53d53a87-96d8-4184-9ca3-f07f6fd08dde" level={9} cateid={140}>
                                        <div className="w-rect wbaseItem-value 6c5a0a59-1339-4291-b9b2-70413bce76c1" level={10} cateid={138}>
                                            <img src={fileImg} alt='' />
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 8e00996b-8c11-4866-b75c-d8ad64519f8e" wrap="wrap" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md b13e7dc1-e422-416b-bac7-c371ad190875" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 303bb059-9c36-41da-a86c-036fbac3bddd" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6456431d-807f-4b00-9f3b-9e0bb7d73c4b" level={12} cateid={139}>Họ và tên</div>
                                                <div className="w-text wbaseItem-value 3fff4b86-7df6-4c0a-9751-30d88146f1b8" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row f6920f59-0dd6-4da4-bc86-0c1a77141167 " + (errors.fullName && ' helper-text')} helper-text={errors.fullName && 'Vui lòng nhập họ và tên'} name-field="Fullname" level={11} cateid={85} placeholder="">
                                                <div className="wbaseItem-value a2d6ba06-c61a-46f5-855e-8ebb925bd706" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-a2d6ba06-c61a-46f5-855e-8ebb925bd706" {...register("fullName", { required: true })} disabled name="fullName" value={data?.fullName} placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 066194d9-d876-4aee-9378-e673b594070e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row d9ebfebb-51ec-4aa9-a0f4-30d65d8d334c" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 621bedc1-b67f-4eba-9361-064b2aa6f60d" level={12} cateid={139}>CCCD</div>
                                                <div className="w-text wbaseItem-value df9612a0-c5fb-430c-8511-f59abd9d1039" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 273b6f61-d8ff-45b8-ac25-d8a2b47993b6 "} name-field="PhoneNumber" level={11} cateid={85} placeholder="">
                                                <div className="wbaseItem-value bca63eff-847f-4591-9eee-7c44b4662dee" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input type='tel' name="cccd" disabled {...register("cccd", {
                                                            required: true,
                                                            // pattern: {
                                                            //     value: /^((\+84[1-9][0-9]{7}[0-9]{0,2})|(0[1-9][0-9]{8,9})|([1-9]([0-9]{7}|[0-9]{9})))$/,
                                                            //     message: 'Điện thoại không đúng định dạng',
                                                            // },

                                                        })} value={data?.cccd} placeholder="Nhập CCCD" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c246b023-98b0-456e-8972-c0b962d0429f" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row b628a82a-7327-4458-ad76-bb6b97427e43" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value fa40384f-696e-486c-8f4d-714c85d75638" level={12} cateid={139}>Số điện thoại</div>
                                                <div className="w-text wbaseItem-value a49c81c0-0cf7-45c1-b737-ae120e2b0c81" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row fcb7ea62-8898-4e82-8e2c-46a52340a33f "} level={11} cateid={85}>
                                                <div className="wbaseItem-value 0efdac7a-9468-440b-83b8-9709693d637b" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input maxLength={20} name="phoneNumber" disabled {...register("phoneNumber", { required: true, maxLength: 20 })} value={data?.phone} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c246b023-98b0-456e-8972-c0b962d0429f" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value" level={8} cateid={139} id="ad483c96-1a4e-4eda-ac8d-9360ed0e6722">Ngày sinh</div>
                                            <div className={"w-textformfield wbaseItem-value w-row fcb7ea62-8898-4e82-8e2c-46a52340a33f "} level={11} cateid={85}>
                                                <div className="wbaseItem-value 0efdac7a-9468-440b-83b8-9709693d637b" level={12} cateid={86} type="date">
                                                    <div className="textfield">
                                                        <input maxLength={20} name="birthDay" defaultValue={data?.birthDay} placeholder="Ngày sinh" />
                                                    </div>
                                                    <div className="suffix-btn-txtfd calendar-icon"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                                        <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" />
                                                        <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" />
                                                        <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" />
                                                        <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                                        <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                                        <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" />
                                                        <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" />
                                                        <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                                    </svg></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-frame wbaseItem-value w-row 5dd7e22c-dea6-4e12-b61c-56fa7ed57b13" wrap="wrap" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c729a445-fdf5-464b-aac5-08d9b7c5650e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 46659001-49de-4e3a-b256-961540ab2cf5" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value f34de7ec-746a-4682-a8d8-2cedf0e92f82" level={12} cateid={139}>Giới tính</div>
                                                <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div>
                                            </div>
                                            {/* <div className={'select2-custom '}>
                                                <Controller
                                                    name="gender"
                                                    {...register("gender")}
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    render={({ field }) => (
                                                        <Select2 {...field} isFirst={true} value={data?.gender} nameFirst={'Chọn giới tính'} data={[{ id: 1, name: 'Nam' }, { id: 0, name: 'Nữ' }]} />
                                                    )}
                                                />
                                            </div> */}
                                            <div className="w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a" name-field="Textformfield" level={11} cateid={85} placeholder="Giới tính">
                                                <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-4bc29d32-f178-4161-b6b4-773502e35a80" readOnly name="gender" {...register("gender")} value={data?.gender} placeholder="Giới tính" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Địa chỉ thường trú</div>
                                                <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div>


                                            </div>
                                            <div className="w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a" name-field="Textformfield" level={11} cateid={85} placeholder="Chọn địa chỉ thường trú">
                                                <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-4bc29d32-f178-4161-b6b4-773502e35a80" readOnly name="address" {...register("address")} value={data?.address} placeholder="Chọn địa chỉ thường trú" />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="w-frame wbaseItem-value w-col 34ad728f-9eee-42f0-9645-6fe6f5f3c2c8" level={8} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 1a8621e1-6b9f-4de1-9d7c-aa75f74a71b3" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 2743937a-9d86-445b-ab90-fd44775c165f" level={10} cateid={139}>Tài khoản</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 5dd7e22c-dea6-4e12-b61c-56fa7ed57b13" wrap="wrap" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c729a445-fdf5-464b-aac5-08d9b7c5650e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 46659001-49de-4e3a-b256-961540ab2cf5" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value f34de7ec-746a-4682-a8d8-2cedf0e92f82" level={12} cateid={139}>Tên đăng nhập</div>
                                                <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 41385f1b-a7ed-40c2-ae0c-37627fbd2cf0 "} name-field="Username" level={11} cateid={85} placeholder="Nhập tên đăng nhập">
                                                <div className="wbaseItem-value 00dc0846-a902-4631-9a29-2584c7f11969" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-00dc0846-a902-4631-9a29-2584c7f11969" name="username" disabled value={data?.username} {...register("username", { required: true })} placeholder="Nhập tên đăng nhập" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c729a445-fdf5-464b-aac5-08d9b7c5650e" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 46659001-49de-4e3a-b256-961540ab2cf5" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value f34de7ec-746a-4682-a8d8-2cedf0e92f82" level={12} cateid={139}>Ngày thay đổi mật khẩu gần nhất</div>
                                                {/* <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div> */}
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 41385f1b-a7ed-40c2-ae0c-37627fbd2cf0 "} name-field="lastDayChangePassword" level={11} cateid={85} placeholder="Ngày thay đổi mật khẩu gần nhất">
                                                <div className="wbaseItem-value 00dc0846-a902-4631-9a29-2584c7f11969" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-00dc0846-a902-4631-9a29-2584c7f11969" name="lastDayChangePassword" disabled value={new Date(data?.lastDayChangePassword).toLocaleDateString('en-GB')} {...register("lastDayChangePassword", { required: true })} placeholder="Ngày thay đổi mật khẩu gần nhất" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Mật khẩu</div>
                                                <div className="w-text wbaseItem-value 8969e3c3-3c51-4838-8b22-f01bc82deb54" level={12} cateid={139}>*</div>
                                            </div>
                                            <div className="w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a" name-field="Textformfield" level={11} cateid={85} placeholder="Nhập mật khẩu">
                                                <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-4bc29d32-f178-4161-b6b4-773502e35a80" disabled name="password" {...register("password")} placeholder="Nhập mật khẩu" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div> */}
                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 1ba9d69b-be09-4672-98c4-e23b21c4a316" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row a260715b-ad02-48ee-a076-ab419bd89353" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value e2aff4ad-c4e4-40ce-96ff-0ec3276097e1" level={12} cateid={139}>Trạng thái</div>

                                            </div>
                                            {/* <div className="w-textformfield wbaseItem-value w-row 2773edd0-7248-491b-9f69-08528749b92a" name-field="Textformfield" level={11} cateid={85} placeholder="Chọn trạng thái hoạt động">
                                                <div className="wbaseItem-value 4bc29d32-f178-4161-b6b4-773502e35a80" level={12} cateid={86}>
                                                    <div className="textfield">
                                                        <input id="textfield-4bc29d32-f178-4161-b6b4-773502e35a80" disabled name="status" {...register("status")} placeholder="Chọn trạng thái hoạt động" />
                                                    </div>
                                                </div>
                                            </div>
                                             */}
                                            <div className={'select2-custom '}>
                                                <Controller
                                                    name="status"
                                                    {...register("status")}
                                                    control={control}
                                                    style={{ order: 2 }}
                                                    render={({ field }) => (
                                                        <Select2 {...field}
                                                            // value={data?.status} 
                                                            options={{ placeholder: 'Chọn trạng thái' }}
                                                            data={[{ id: 1, name: 'Hoạt động' }, { id: 0, name: 'Không hoạt động' }]} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        {
                                            !username ? null :
                                                <button onClick={() => resetPass()} type="button" className="w-button wbaseItem-value w-row col- col6-xxl col6-xl col12-lg col24-md ac5ec015-206c-4dbb-9cab-89c543fa5aed" name-field="Button" level={10} cateid={29}>
                                                    <div className="w-text wbaseItem-value 681868a2-ffec-4cb8-9bbe-7167aa413ff5" name-field="Text" level={11} cateid={139}>Reset mật khẩu<br />
                                                    </div>
                                                </button>
                                        }
                                    </div>
                                </div>

                                {
                                    !username ? null : <div className="w-frame wbaseItem-value w-col 626e9d18-582b-465d-8eb2-45f4cc7f0ae4" level={8} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row 3319e5e4-02f1-40d5-87fe-73153c181d98" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value 900f5c9e-2d75-4fa6-9cf3-b3eaea695116" level={10} cateid={139}>Lịch sử</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 11118142-662b-4709-a44b-5a2cd91a48a8" wrap="wrap" level={9} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl e276a3d2-65d8-4c1c-b518-d02ef3889d20" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value 68b71902-cd86-4180-9336-6c4fc65395f2" level={11} cateid={139}>Người tạo:</div>
                                                <div className="w-text wbaseItem-value 2f808d9c-b47e-48ca-8133-6d67588a7315" level={11} cateid={139}>{data?.createdBy}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 7588ad57-7567-43fe-8a9f-49cfaa1172bb" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value 28129997-3b4e-432b-88a1-a80b26bbae9d" level={11} cateid={139}>Ngày tạo:</div>
                                                <div className="w-text wbaseItem-value a82a9d49-6425-4d05-8ba2-7c67c0e48f96" level={11} cateid={139}>{data?.createdDate ? new Date(data?.createdDate).toLocaleDateString('en-GB') : ''}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl 4be20552-4cee-4471-b9dd-b5ab48f0fcc6" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value bb8eaed1-2c37-484b-83ed-c9734e37df31" level={11} cateid={139}>Người cập nhật cuối:</div>
                                                <div className="w-text wbaseItem-value b9981858-2cd7-44f9-ab7d-bdfd81ff035d" level={11} cateid={139}>{data?.modifiedBy}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 40e66f79-36e0-4978-b62e-aedd5daeaf5d" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value a176e037-444e-4c7e-9dcd-678813be183a" level={11} cateid={139}>Ngày cập nhật cuối:</div>
                                                <div className="w-text wbaseItem-value b25cb0a6-a796-4253-a37f-091689218413" level={11} cateid={139}>{data?.modifiedDate ? new Date(data?.modifiedDate).toLocaleDateString('en-GB') : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-frame wbaseItem-value w-row de68687b-95f6-4c9f-a6e9-31f6165ced53" level={3} cateid={140}>
                <button onClick={() => onCancel(false)} type="button" className="w-button wbaseItem-value w-row 624030d1-8e78-4a06-acef-f3df67b08e8b" name-field="Button" level={4} cateid={29}>
                    <div className="w-text wbaseItem-value fec51101-55a6-46c8-91b0-d00b44ded0b5" name-field="Text" level={5} cateid={139}>Thoát</div>
                </button>
                {/* <div className="w-frame wbaseItem-value w-row e7817577-026d-4088-b330-1e29b66d873b" level={4} cateid={140}>
                    <button type="submit" className="w-button wbaseItem-value w-row ecfdb3a7-19e7-4a28-8b4d-670404f2c6c8" name-field="Button" level={5} cateid={29}>
                        <div className="w-svg wbaseItem-value 2592c61a-086e-4228-91d1-092fb8b35d29" level={6} cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.7337 5.26627C22.0888 5.62129 22.0888 6.19689 21.7337 6.55192L9.91555 18.3701C9.56053 18.7251 8.98493 18.7251 8.6299 18.3701L2.26627 12.0065C1.91124 11.6514 1.91124 11.0758 2.26627 10.7208C2.62129 10.3658 3.19689 10.3658 3.55192 10.7208L9.27273 16.4416L20.4481 5.26627C20.8031 4.91124 21.3787 4.91124 21.7337 5.26627Z" fill="#FFFFFFFF">
                                </path>
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value 4820f7f2-d5f5-4a4b-8ad6-1eaa19c3334e" name-field="Text" level={6} cateid={139}>Lưu</div>
                    </button>
                </div> */}
                <div className="w-frame wbaseItem-value w-row e7817577-026d-4088-b330-1e29b66d873b" level={4} cateid={140}>
                    <button onClick={onSaveStatus} type="button" className="w-button wbaseItem-value w-row ecfdb3a7-19e7-4a28-8b4d-670404f2c6c8" name-field="Button" level={5} cateid={29}>
                        <div className="w-svg wbaseItem-value 2592c61a-086e-4228-91d1-092fb8b35d29" level={6} cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.7337 5.26627C22.0888 5.62129 22.0888 6.19689 21.7337 6.55192L9.91555 18.3701C9.56053 18.7251 8.98493 18.7251 8.6299 18.3701L2.26627 12.0065C1.91124 11.6514 1.91124 11.0758 2.26627 10.7208C2.62129 10.3658 3.19689 10.3658 3.55192 10.7208L9.27273 16.4416L20.4481 5.26627C20.8031 4.91124 21.3787 4.91124 21.7337 5.26627Z" fill="#FFFFFFFF">
                                </path>
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value 4820f7f2-d5f5-4a4b-8ad6-1eaa19c3334e" name-field="Text" level={6} cateid={139}>Lưu</div>
                    </button>
                </div>
            </div>
        </form>


    )
}