import Pagination from '../../components/Pagination/Pagination';
import '../../css/user/user.css';
import { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import { NavLink, useLocation } from 'react-router-dom';
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import { ExportFileUser, UpdateStatusUser, getListAllUser } from '../../redux/middleware/api/quanlynguoidung/userDA';
import { getListNhomquyen } from '../../redux/middleware/api/nhomquyen/NhomQuyenDA';
import { getorgList, getorgtitleList } from '../../redux/middleware/api/Filter/Filter';
import { useForm, Controller } from 'react-hook-form';
import Select2 from '../../components/select2/Select2';
import { getListHanmuc } from '../../redux/middleware/api/hanmuc/hanmucDA';
import { PopupUploadUser } from '../CongViecCuaToi/Popup';
import { parse, format } from 'date-fns';

export default function UserList() {
    const { register, handleSubmit, setValue, control } = useForm({ defaultValues: { status: 1 } });
    const location = useLocation();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState();
    const [groupModuleList, setgroupModuleList] = useState([]);
    const [loanLimit, setloanLimit] = useState([]);
    const [isShowpopUpload, setisShowpopUpload] = useState(false);

    const [orgList, setorgList] = useState([]);
    const [orgTitleList, setorgTitleList] = useState([]);
    const [pageDetails, setPageDetails] = useState({
        current: 1,
        size: 10
    });
    const notificationAlert = useRef();

    const _InitData = async (page, pageSize) => {
        const query = window.location.search;
        const response = await getListAllUser(page, pageSize, query);
        if (response != null) {
            setData(response.content);
            setTotal(response.totalElements)
        }
    }

    const OnchangeSwitch = async (index) => {
        const id = data[index].id;
        const status = data[index].status === 1 ? 0 : 1;
        const respone = await UpdateStatusUser(id, status);

        if (respone.code === '200') {
            // setData(data);
            setData((data) =>
                data.map((item) =>
                    item.id === data[index].id ? { ...item, status: data[index].status === 0 ? 1 : 0 } : item
                ));
        } else {
            notify(notificationAlert, ERROR, respone.message)
        }

    }
    const getAllgroupModule = async () => {
        const response = await getListNhomquyen(1, 100, '?status=1');
        if (response != null) {
            setgroupModuleList(response.content);
        }
    }
    const getAllLimit = async () => {
        const response = await getListHanmuc(1, 100, '?status=1');
        if (response != null) {
            setloanLimit(response.content);
        }
    }
    const getAllorgTitle = async (name) => {
        const response = await getorgtitleList(1, 100, name);;
        if (response != null) {
            setorgTitleList(response.content);
        }
    }
    const getAllorgList = async () => {
        const response = await getorgList(1, 100,);

        if (response != null) {
            setorgList(response.content);
        }
    }

    const onSubmitForm = (ev) => {
        const searchParams = new URLSearchParams(location.search);
        if (ev.fullName)
            searchParams.set('fullName', ev.fullName);
        else
            searchParams.delete('fullName');
        if (ev.staffCode)
            searchParams.set('staffCode', ev.staffCode);
        else
            searchParams.delete('staffCode');
        if (ev.username)
            searchParams.set('username', ev.username);
        else
            searchParams.delete('username');
        if (ev.loanLimit)
            searchParams.set('loanLimit', ev.loanLimit);
        else
            searchParams.delete('loanLimit');
        if (ev.groupIds)
            searchParams.set('groupIds', ev.groupIds);
        else
            searchParams.delete('groupIds');
        if (ev.orgId)
            searchParams.set('orgId', ev.orgId);
        else
            searchParams.delete('orgId');
        if (ev.orgTitleId)
            searchParams.set('orgTitleId', ev.orgTitleId);
        else
            searchParams.delete('orgTitleId');
        if (ev.status)
            searchParams.set('status', ev.status);
        else
            searchParams.delete('status');
        if (ev.fromDate)
            searchParams.set('fromDate', ev.fromDate);
        else
            searchParams.delete('fromDate');
        if (ev.toDate)
            searchParams.set('toDate', ev.toDate);
        else
            searchParams.delete('toDate');
        if(ev.fromDate && ev.toDate){
            const from = parse(ev.fromDate, 'dd/MM/yyyy', new Date());
            const to = parse(ev.toDate, 'dd/MM/yyyy', new Date());
            if(to < from){
                notify(notificationAlert,ERROR,'Ngày bắt đầu phải nhỏ hơn ngày kết thúc')
                return;
            }
        }
        window.history.replaceState(null, null, `?${searchParams.toString()}`);
        _InitData(pageDetails.current, pageDetails.size).then(_ => {
            if (ev.fullName) setValue("fullName", ev.fullName);
            if (ev.staffCode) setValue("staffCode", ev.staffCode);
            if (ev.username) setValue("username", ev.username);
            if (ev.loanLimit) setValue("loanLimit", ev.loanLimit);
            if (ev.groupIds) setValue("groupIds", ev.groupIds);
            if (ev.orgId) setValue("orgId", ev.orgId);
            if (ev.orgTitleId) setValue("orgTitleId", ev.orgTitleId);
            if (ev.status) setValue("status", ev.status ?? 1);
            if (ev.fromDate) setValue("fromDate", ev.fromDate);
            if (ev.toDate) setValue("toDate", ev.toDate);
        });
    }
    const ExportFile = async () => {
        const res = await ExportFileUser(location.search)
        debugger;
        if (res.status === 201) {
            var blob = new Blob([res.data], {
                type: res.headers["content-type"],
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.target = '_blank'; // Open the link in a new tab/window
            link.download = 'Danhsach-user.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            notify(notificationAlert, ERROR, res.message ?? 'Đã có lỗi xảy ra.')
        }
        // if (res.code === '200') {
        //     notify(notificationAlert, SUCCESS, 'Export File thành công.')
        // } else {
        //     notify(notificationAlert, ERROR, res.message ?? 'Đã có lỗi xảy ra.')

        // }

    }
    const ressetForm = () => {
        setValue('fullName', null);
        setValue('staffCode', null);
        setValue('username', null);
        setValue('loanLimit', null);
        setValue('groupIds', null);
        setValue('orgId', null);
        setValue('orgTitleId', null);
        setValue('status', 1);
        setValue('fromDate', null);
        setValue('toDate', null);
    }

    useEffect(() => {
        getAllgroupModule();
        getAllorgTitle();
        getAllLimit()
        getAllorgList();
        _InitData(pageDetails.current, pageDetails.size);
    }, []);

    return <div className="w-frame wbaseItem-value w-col ac9373c9-1468-40e8-901a-9ef767660d7a" scroll="true" level={4} cateid={140}>
        <NotificationAlert ref={notificationAlert} />
        {isShowpopUpload && <PopupUploadUser
            notificationAlert={notificationAlert}
            callback_action={
                function () {
                    setisShowpopUpload(false)
                }
            }
            callback_closed={function () {
                setisShowpopUpload(false)
            }}
        />}
        <div className="w-frame wbaseItem-value w-row e5a16764-a41a-41c2-bb63-9b7798d760c3" level={5} cateid={140}>
            <div className="w-frame wbaseItem-value w-col 72d56605-c909-4320-8521-e0dd023109d2" level={6} cateid={140}>
                <div className="w-frame wbaseItem-value w-row dae32852-212b-4656-9630-9c2a99a23671" level={7} cateid={140}>
                    <div className="w-text wbaseItem-value 7b628ee8-df4d-4722-a551-90fbcaebcda8" level={8}
                        cateid={139}>Quản lý người dùng</div>
                </div>
                <div className="w-frame wbaseItem-value w-row breadcrumb 5079aa9c-748c-4ae7-9ca2-3dbd1d833a87" level={7} cateid={140}>
                    <button type="button" className="w-button wbaseItem-value w-row 697c50de-7d5d-41d3-b617-0c3741d1bc24" level={8} cateid={29}>
                        <div className="w-text wbaseItem-value 29185097-d932-4fa0-960c-eb863e5fe0f1" level={9} cateid={139}>Quản trị hệ thống</div>
                        <div className="w-svg wbaseItem-value 2bb71249-6468-4ca9-9918-a13e38ecf880" level={9} cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6" />
                            </svg>
                        </div>
                    </button>
                    <div className="w-text wbaseItem-value b1d398af-a673-4ea7-a4af-6831b0411e15" level={8}
                        cateid={139}>Quản lý người dùng</div>
                </div>
            </div>
        </div>
        <form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off' className="w-frame w-form wbaseItem-value w-col b721ebd4-7990-4086-98b3-6ec8f5aa41bc" level={5} cateid={128}>
            <div className="w-frame wbaseItem-value w-row e4175e48-4d2e-49a7-b4f4-927e559458a1" wrap="wrap" level={6} cateid={140}>
                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 8739a82e-730d-4399-9c99-77799fdfd4a1" level={7} cateid={140}>
                    <div className="w-text wbaseItem-value c34e4304-2103-4001-843c-5d9b8e57f72d" level={8}
                        cateid={139}>Họ và tên</div>
                    <div className="w-textformfield wbaseItem-value w-row f439fd9e-f87f-4c68-ab98-dff2e0c2c44c" name-field="RoleID" level={8} cateid={85}
                        placeholder="Nhập họ và tên nhân viên">
                        <div className="wbaseItem-value 215d6150-22b8-4005-b3d8-049f8762b450" level={9} cateid={86}>
                            <div className="textfield" style={{ marginTop: "2px" }}>
                                <input name="fullName" {...register("fullName")} placeholder="Nhập họ và tên nhân viên" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm f1fa51fe-e167-46e0-9f04-897733680195" level={7} cateid={140}>
                    <div className="w-text wbaseItem-value c3a67008-efbc-42fc-98d3-9787640e278f" level={8}
                        cateid={139}>Mã nhân viên</div>
                    <div className="w-textformfield wbaseItem-value w-row ac565359-b7cc-4a6b-b21a-bccd0bd0b1f0" name-field="EmployeeID" level={8} cateid={85}
                        placeholder="Nhập mã nhân viên">
                        <div className="wbaseItem-value 23336832-81df-4b13-ae98-ac81100c8775" level={9} cateid={86}>
                            <div className="textfield" style={{ marginTop: "2px" }}>
                                <input name="staffCode" {...register("staffCode")} placeholder="Nhập mã nhân viên" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm a8a861c9-968b-4406-96a5-1845b356a424" level={7} cateid={140}>
                    <div className="w-text wbaseItem-value a8afe34e-e3d9-4209-8b04-da2f0f8c7acd" level={8}
                        cateid={139}>Tên đăng nhập</div>
                    <div className="w-textformfield wbaseItem-value w-row 0823d802-baa5-4f32-a0f6-6a0568e18f20" name-field="Username" level={8} cateid={85}
                        placeholder="Tên đăng nhập của nhân viên">
                        <div className="wbaseItem-value 87992e90-5f00-497e-97fd-a7ccdd901c24" level={9} cateid={86}>
                            <div className="textfield" style={{ marginTop: "2px" }}>
                                <input name="username" {...register("username")} placeholder="Tên đăng nhập của nhân viên" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 42e98ce9-bc6b-4d6f-bc47-7a87b70db228" level={7} cateid={140}>
                    <div className="w-text wbaseItem-value e7c160e0-c242-457f-bf73-809fbf2a2dd3" level={8}
                        cateid={139}>Nhóm hạn mức</div>
                    {/* <div className="w-textformfield wbaseItem-value w-row d0cc2c8d-bd85-49a1-89ed-cfb86a1dbfae"                name-field="Status" level={8} cateid={85}
                            placeholder="Chọn nhóm quyền">
                            <div className="wbaseItem-value 5f7ccfe7-8fbe-4ba5-ac6e-34332106949f"
                                level={9} cateid={86}>
                                <div className="textfield">
                                    <input id="textfield-5f7ccfe7-8fbe-4ba5-ac6e-34332106949f" name="groupIds"
                                        placeholder="Chọn nhóm quyền" />
                                </div>
                            </div>
                            <div className="w-svg wbaseItem-value 12031c22-6136-406b-9e34-d1f09e73aa63"
                                level={9} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z"
                                        fill="#28282999" />
                                </svg>
                            </div>
                        </div> */}
                    <Controller
                        name="loanLimit"
                        control={control}
                        {...register("loanLimit")}
                        style={{ order: 2 }}
                        render={({ field }) => (
                            <Select2 {...field}
                                data={loanLimit.map((a,_)=>{return {id: a.id, name:a.code}})}
                                options={{ placeholder: 'Chọn hạn mức' }}
                            />
                        )}
                    />
                </div>

                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 42e98ce9-bc6b-4d6f-bc47-7a87b70db228"
                    level={7} cateid={140}>
                    <div className="w-text wbaseItem-value e7c160e0-c242-457f-bf73-809fbf2a2dd3" level={8}
                        cateid={139}>Nhóm quyền</div>
                    {/* <div className="w-textformfield wbaseItem-value w-row d0cc2c8d-bd85-49a1-89ed-cfb86a1dbfae"
                            name-field="Status" level={8} cateid={85}
                            placeholder="Chọn nhóm quyền">
                            <div className="wbaseItem-value 5f7ccfe7-8fbe-4ba5-ac6e-34332106949f"
                                level={9} cateid={86}>
                                <div className="textfield">
                                    <input id="textfield-5f7ccfe7-8fbe-4ba5-ac6e-34332106949f" name="groupIds"
                                        placeholder="Chọn nhóm quyền" />
                                </div>
                            </div>
                            <div className="w-svg wbaseItem-value 12031c22-6136-406b-9e34-d1f09e73aa63"
                                level={9} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z"
                                        fill="#28282999" />
                                </svg>
                            </div>
                        </div> */}
                    <Controller
                        name="groupIds"
                        control={control}
                        {...register("groupIds")}
                        style={{ order: 2 }}
                        render={({ field }) => (
                            <Select2 {...field}
                                data={groupModuleList.map((item, _) => { return { id: item.id, name: item.groupCode } })}
                                options={{ placeholder: 'Chọn nhóm quyền' }}
                            />
                        )}
                    />
                </div>
                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 42e98ce9-bc6b-4d6f-bc47-7a87b70db228"
                    level={7} cateid={140}>
                    <div className="w-text wbaseItem-value e7c160e0-c242-457f-bf73-809fbf2a2dd3" level={8}
                        cateid={139}>Trạng thái</div>
                    {/* <div className="w-textformfield wbaseItem-value w-row d0cc2c8d-bd85-49a1-89ed-cfb86a1dbfae"
                            name-field="status" level={8} cateid={85}
                            placeholder="Chọn trạng thái">
                            <div className="wbaseItem-value 5f7ccfe7-8fbe-4ba5-ac6e-34332106949f"
                                level={9} cateid={86}>
                                <div className="textfield">
                                    <input id="textfield-5f7ccfe7-8fbe-4ba5-ac6e-34332106949f" name="status"
                                        placeholder="Chọn trạng thái" />
                                </div>
                            </div>
                            <div className="w-svg wbaseItem-value 12031c22-6136-406b-9e34-d1f09e73aa63"
                                level={9} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z"
                                        fill="#28282999" />
                                </svg>
                            </div>
                        </div> */}
                    <Controller
                        name="status"
                        control={control}
                        {...register("status")}
                        style={{ order: 2 }}
                        render={({ field }) => (
                            <Select2 {...field}
                                data={[{ id: 1, name: 'Active' }, { id: 0, name: 'DeActive' }, { id: 2, name: "Block" }]}
                                // options={{ placeholder: 'Chọn trạng thái' }}
                                isFirst={true}
                                nameFirst={'Tất cả'}
                            />
                        )}
                    />
                </div>

                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 42e98ce9-bc6b-4d6f-bc47-7a87b70db228"
                    level={7} cateid={140}>
                    <div className="w-text wbaseItem-value e7c160e0-c242-457f-bf73-809fbf2a2dd3" level={8}
                        cateid={139}>Tổ chức</div>
                    {/* <div className="w-textformfield wbaseItem-value w-row d0cc2c8d-bd85-49a1-89ed-cfb86a1dbfae"
                            name-field="Status" level={8} cateid={85}
                            placeholder="Chọn tổ chức">
                            <div className="wbaseItem-value 5f7ccfe7-8fbe-4ba5-ac6e-34332106949f"
                                level={9} cateid={86}>
                                <div className="textfield">
                                    <input id="textfield-5f7ccfe7-8fbe-4ba5-ac6e-34332106949f" name="orgId"
                                        placeholder="Chọn tổ chức" />
                                </div>
                            </div>
                            <div className="w-svg wbaseItem-value 12031c22-6136-406b-9e34-d1f09e73aa63"
                                level={9} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z"
                                        fill="#28282999" />
                                </svg>
                            </div>
                        </div> */}
                    <Controller
                        name="orgId"
                        control={control}
                        {...register("orgId")}
                        style={{ order: 2 }}
                        render={({ field }) => (
                            <Select2 {...field}
                                data={orgList.map((item, _) => { return { id: item.id, name: item.code } })}
                                options={{ placeholder: 'Chọn tổ chức' }}
                            />
                        )}
                    />
                </div>
                <div className="w-frame wbaseItem-value w-col col- col12-xxl col12-xl col12-lg col24-md col24 col24-sm" level={7} cateid={140} id="da4cf8c3-311f-4580-8b87-6709f8160053">
                    <div className="w-text wbaseItem-value" level={8} cateid={139} id="ad483c96-1a4e-4eda-ac8d-9360ed0e6722">Ngày tạo</div>
                    <div className="w-frame wbaseItem-value w-row" level={8} cateid={140} id="676792c9-5f4a-45bd-88ce-db3e2dd536a0">
                        <div className="w-textformfield wbaseItem-value w-row" name-field="createdDateFrom" level={9} cateid={85} id="54e5d651-53d5-43d6-b61d-ffa9b01c9bd3" placeholder="Từ ngày">
                            <div className="wbaseItem-value" level={10} cateid={86} id="d37be69e-c37b-4962-9031-b8ea9938843d" type="date">
                                <div className="textfield"><input id="textfield-d37be69e-c37b-4962-9031-b8ea9938843d" {...register("fromDate")} name="fromDate" placeholder="Từ ngày" /></div>
                                <div className="suffix-btn-txtfd calendar-icon"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                    <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                    <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                    <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                    <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                    <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                    <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                    <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                    <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                </svg></div>
                            </div>
                        </div>
                        <div className="w-text wbaseItem-value" level={9} cateid={139} id="ebfc245d-bfc0-4f47-9b0c-d7fd80a8e9b6">-</div>
                        <div className="w-textformfield wbaseItem-value w-row" name-field="createdDateTo" level={9} cateid={85} id="e6b07ff0-db76-400a-835f-1c9671ea0e1c" placeholder="Đến ngày">
                            <div className="wbaseItem-value" level={10} cateid={86} id="6da7e7b2-aded-438e-b121-5127229e93c0" type="date" >
                                <div className="textfield"><input id="textfield-6da7e7b2-aded-438e-b121-5127229e93c0" {...register("toDate")} name="toDate" placeholder="Đến ngày" /></div>
                                <div className="suffix-btn-txtfd calendar-icon"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                    <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                    <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                    <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                    <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                    <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                    <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                    <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                    <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                </svg></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 42e98ce9-bc6b-4d6f-bc47-7a87b70db228"
                    level={7} cateid={140}>
                    <div className="w-text wbaseItem-value e7c160e0-c242-457f-bf73-809fbf2a2dd3" level={8}
                        cateid={139}>Chức danh</div>
                    {/* <div className="w-textformfield wbaseItem-value w-row d0cc2c8d-bd85-49a1-89ed-cfb86a1dbfae"
                            name-field="orgTitleId" level={8} cateid={85}
                            placeholder="Chọn chức danh">
                            <div className="wbaseItem-value 5f7ccfe7-8fbe-4ba5-ac6e-34332106949f"
                                level={9} cateid={86}>
                                <div className="textfield">
                                    <input id="textfield-5f7ccfe7-8fbe-4ba5-ac6e-34332106949f" name="orgTitleId"
                                        placeholder="Chọn chức danh" />
                                </div>
                            </div>
                            <div className="w-svg wbaseItem-value 12031c22-6136-406b-9e34-d1f09e73aa63"
                                level={9} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z"
                                        fill="#28282999" />
                                </svg>
                            </div>
                        </div> */}
                    <Controller
                        name="orgTitleId"
                        control={control}
                        {...register("orgTitleId")}
                        style={{ order: 2 }}
                        render={({ field }) => (
                            <Select2 {...field}
                                data={orgTitleList}
                                options={{ placeholder: 'Chọn chức danh' }}
                            />
                        )}
                    />
                </div>


            </div>
            <div className="w-frame wbaseItem-value w-row b45d26d7-cc6f-4d0d-b743-ff5d3951a493"
                level={6} cateid={140}>
                <button type="button" onClick={ressetForm}
                    className="w-button wbaseItem-value w-row 01df81f9-ab23-4de9-a2b7-ddd1c163c199"
                    level={7} cateid={29}>
                    <div className="w-svg wbaseItem-value 2e7370de-b936-4b12-9d10-1a6280b9d506" level={8}
                        cateid={115}>
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.0313 2.00835C21.5288 2.07581 21.8774 2.53382 21.81 3.03135L21.0827 8.39498C21.0153 8.89173 20.5586 9.2402 20.0617 9.17395L14.6072 8.44668C14.1095 8.38032 13.7598 7.92309 13.8262 7.42541C13.8926 6.92774 14.3498 6.57809 14.8475 6.64445L18.3484 7.11124C16.8702 5.25663 14.5726 4.09064 12 4.09064C7.50208 4.09064 3.81818 7.77454 3.81818 12.2725C3.81818 12.7745 3.41117 13.1816 2.90909 13.1816C2.40701 13.1816 2 12.7745 2 12.2725C2 6.77038 6.49792 2.27246 12 2.27246C15.0381 2.27246 17.7813 3.61127 19.6033 5.77412L20.0083 2.78705C20.0757 2.28953 20.5338 1.94089 21.0313 2.00835Z"
                                fill="#28282999" />
                            <path
                                d="M22 12.2733C22 11.7713 21.5929 11.3643 21.0909 11.3643C20.5888 11.3643 20.1818 11.7713 20.1818 12.2733C20.1818 16.7713 16.4979 20.4552 12 20.4552C9.42714 20.4552 7.12925 19.2889 5.6511 17.434L9.15251 17.9009C9.65018 17.9672 10.1074 17.6176 10.1738 17.1199C10.2401 16.6222 9.89048 16.165 9.39281 16.0986L3.93826 15.3714C3.44137 15.3051 2.98462 15.6536 2.91726 16.1503L2.18999 21.514C2.12253 22.0115 2.47117 22.4695 2.96869 22.537C3.46621 22.6044 3.92423 22.2558 3.99169 21.7583L4.39665 18.7716C6.21865 20.9345 8.96182 22.2733 12 22.2733C17.502 22.2733 22 17.7754 22 12.2733Z"
                                fill="#28282999" />
                        </svg>
                    </div>
                    <div className="w-text wbaseItem-value f0397efc-4cc1-4e36-8315-d0676e7fdf1a"
                        level={8} cateid={139}>Xóa bộ lọc</div>
                </button>
                <button type="submit" className="w-button wbaseItem-value w-row 6b399b04-820f-4233-98be-e7cb8f6951de" level={7} cateid={29}>
                    <div className="w-svg wbaseItem-value e78d1a07-f59d-4c7d-addb-a67b40192d7c" level={8} cateid={115}>
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#FFFFFFFF" />
                        </svg>
                    </div>
                    <div className="w-text wbaseItem-value 131937ed-ca0a-4c01-8d22-9f6e2da034f2" level={8} cateid={139}>Tìm kiếm</div>
                </button>
            </div>
        </form>
        <div className="w-frame wbaseItem-value w-col 0cd55978-a21c-44a6-8b39-19dd03771da9"
            level={5} cateid={140}>
            <div className="w-frame wbaseItem-value w-row 88f91b77-e3fb-48e9-a4f7-f2e0417f2f77"
                level={6} cateid={140}>
                <div className="w-text wbaseItem-value 427d16c1-99b5-49b8-8d14-ff5411c96372" level={7}
                    cateid={139}>Danh sách người dùng</div>
                <button type="button" onClick={ExportFile}
                    className="w-button wbaseItem-value w-row 93c1f8de-5697-48eb-970d-44af199ccba7"
                    level={7} cateid={29}>
                    <div className="w-svg wbaseItem-value e5d3faae-f976-479a-a5a9-1b60d6207043" level={8} cateid={115}>
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 10.5L12 16.3333L17 10.5H12.8333V3H11.1667V10.5H7Z" fill="#28282999" />
                            <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#28282999" />
                        </svg>
                    </div>
                    <div className="w-text wbaseItem-value a801ffae-4724-44f0-b178-cf2f1db8bfe4" level={8} cateid={139}>Xuất dữ liệu</div>
                </button>
                <button onClick={() => {
                    setisShowpopUpload(true)
                }} type="button" className="w-button wbaseItem-value w-row 90ea2198-645a-4a1d-a790-b3cd65c9cc76" level={7} cateid={29}>
                    <div className="w-svg wbaseItem-value ee3df05b-bf73-4b9c-80e2-0c3848b2ea07" level={8} cateid={115}>
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 8.83333L12 3L17 8.83333H12.8333V16.3333H11.1667V8.83333H7Z" fill="#28282999" />
                            <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#28282999" />
                        </svg>
                    </div>
                    <div className="w-text wbaseItem-value 652cfb58-5a7e-4182-b0a2-13f7458fedaf" level={8} cateid={139}>Tải lên</div>
                </button>
                <NavLink to={location.pathname + '/add-user'} type="button"
                    className="nav-link w-button wbaseItem-value w-row 1a05b2fd-c5a3-42a7-a1dd-e53b2c2675b5"
                    level={7} cateid={29}>
                    <div className="w-svg wbaseItem-value a3e0f6f2-4d22-4977-ab6f-90d0568e534f" level={8} cateid={115}>
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z" fill="#FFFFFFFF" />
                        </svg>
                    </div>
                    <div className="w-text wbaseItem-value f68a58de-6274-44fb-8b08-e0700506f1c3" level={8} cateid={139}>Thêm mới</div>
                </NavLink>
            </div>
            <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>

                    <table className="w-table wbaseItem-value 248f4c77-5f2a-4eb0-99bd-a17b17d8b856" type={0} level={6} cateid={117}>
                        <tbody>
                            <tr className="table-row">
                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row 096c1a97-489c-4e0d-aa36-7ddaff04bb11" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 2c03f5b3-5f9d-4d48-8c77-8290f2667af8" level={8} cateid={139}>STT</div>
                                    </div>
                                </td>
                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row ffc26590-b353-4888-950a-51f6353e7fb3" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 9bc4b77e-92ef-4e90-914c-a6b0c203dd2b" level={8} cateid={139}>Mã nhân viên</div>
                                    </div>
                                </td>
                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row 92047b9f-64c5-4c8d-b462-eee5792be6ce" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 5e4345e1-d77d-4f8d-9c34-74bf6f740837" level={8} cateid={139}>Họ và tên</div>
                                    </div>
                                </td>
                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row 029ef3a4-22e4-45ad-b208-253297dd02b4" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 5bb0dce2-bbf5-40da-8371-b434e65229ab" level={8} cateid={139}>Tên đăng nhập</div>
                                    </div>
                                </td>
                                <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row b65008d0-9757-4fe3-b009-8e5db84bf789" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 0cd8f1e8-2f6b-49fc-842a-0ebc037f1b13" level={8} cateid={139}>Chức danh</div>
                                    </div>
                                </td>
                                <td id="1x6" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row 57116de3-e977-419b-8cb8-099766e3f794" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 991ed2e9-3d2d-4796-8b4b-fceaecf4a4ab" level={8} cateid={139}>Tổ chức</div>
                                    </div>
                                </td>
                                <td id="1x7" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row 5d693d4b-3ac9-4c91-a385-7ecccd413256" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 14786bc3-5bfc-4aa5-8fb1-a8c99db80e60" level={8} cateid={139}>Nhóm quyền</div>
                                    </div>
                                </td>
                                <td id="1x8" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row 5d693d4b-3ac9-4c91-a385-7ecccd413256" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 14786bc3-5bfc-4aa5-8fb1-a8c99db80e60" level={8} cateid={139}>Trạng thái</div>
                                    </div>
                                </td>
                                <td id="1x9" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row f993f144-38aa-4874-92ff-fee257b23cd2" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value e61020b0-eae1-43f2-9894-d091cceec01c" level={8} cateid={139}>Action</div>
                                    </div>
                                </td>
                            </tr>
                            {
                                data.map((item, index) => {
                                    return (<tr className="table-row" key={item.id}>
                                        <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 42cb3f4e-1f65-4c06-80bc-8340e2dac94c" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value d6a5fbfa-25c8-4a57-a301-9510ec6369b1" level={8} cateid={139}>{pageDetails.size * (pageDetails.current - 1) + index + 1}</div>
                                            </div>
                                        </td>
                                        <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row e05adea9-2a82-4ed2-9e53-9d8074ff2dba" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value 5251a191-4d3a-4152-b711-ca7ca1eab948" level={8} cateid={139}>{item.staffCode}</div>
                                            </div>
                                        </td>
                                        <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 5ada852f-146b-4458-a985-a448ec19bd31" level={7} cateid={140}>
                                                <NavLink to={location.pathname + '/' + item.id} className="w-text wbaseItem-value 4c39cd03-1e0e-4b86-b44a-7078f90c7e18" level={8} cateid={139}>{item.fullName}</NavLink>
                                            </div>
                                        </td>
                                        <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 0e4151d7-3fe4-4bc0-b203-bd6bf54b7964" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value 22af8b54-3aec-485c-a9a1-d47ccda7c584" level={8} cateid={139}>{item.username}</div>
                                            </div>
                                        </td>
                                        <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 506d8a16-afd2-41e2-9e17-dd4e449604b7" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value caa69f60-1e11-44ce-9e51-df966c811d49" level={8} cateid={139}>{item.orgTitle?.name}</div>
                                            </div>
                                        </td>
                                        <td id="2x6" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 4b0dfe68-2cfc-498a-a46f-dc2c9fd9d04a" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value 997b6a86-3bab-41fc-a63d-a079dbf74c17" level={8} cateid={139}>{item.orgInfo?.orgName}</div>
                                            </div>
                                        </td>
                                        <td id="2x7" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 051993c9-2809-4f78-ad77-80293d6252a6" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value 47323d44-5077-427e-878e-2cc8dbe905e0" level={8} cateid={139}>{(item.groups ?? []).map((a, _) => a.name)}</div>
                                            </div>
                                        </td>
                                        <td id="2x7" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 051993c9-2809-4f78-ad77-80293d6252a6" level={7} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-row eadd2c5d-e811-4f3e-9934-2dacf0e9402a" level={2} cateid={29} style={{ backgroundColor: `${item.status === 1 ? "rgb(228, 249, 238)" : "var(--background-color-background-grey-light-2)"}` }}>
                                                    <div className="w-text wbaseItem-value 47323d44-5077-427e-878e-2cc8dbe905e0" level={8} cateid={139} style={{ color: item.status === 1 ? `rgb(0, 153, 68)` : `#262626` }}>{item.status === 1 ? 'Active' : item.status === 2 ? "Block" : 'Deactive'}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td id="2x8" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row e1b0ae35-869a-495a-a32c-2945a1c300e4" level={7} cateid={140}>
                                                <label onClick={() => { OnchangeSwitch(index) }}
                                                    className="w-switch wbaseItem-value cb0b3c21-600e-4c0e-8697-027f60e02d14" level={8} cateid={81}>
                                                    <input type="checkbox" disabled checked={item.status === 1 ? true : false} name="Status" />
                                                    <span className="slider" />
                                                </label>
                                            </div>
                                        </td>
                                    </tr>)
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination
                currentPage={pageDetails.current}
                itemPerPage={pageDetails.size}
                totalItem={total}
                onChangePage={(page, size) => {
                    if (pageDetails.current !== page || pageDetails.size !== size) {
                        setPageDetails({ current: page, size: size });
                        _InitData(page, size);
                    }
                }}
            />
        </div>
    </div>
}