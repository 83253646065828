import './themmoi.css';
import './nhansu.css';
import Select2 from '../../../components/select2/Select2';

import { useForm, Controller } from 'react-hook-form';

import $ from 'jquery';
import { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";

import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { useEffect, useState } from "react";
import { getListAllUser } from '../../../redux/middleware/api/quanlynguoidung/userDA';
import { getorgLeadList, getorgList, getorglevelList } from '../../../redux/middleware/api/Filter/Filter';
import { PopupType, WPopup } from '../../../components/Popup/Popup';
import { PopupSelectUser } from '../../CongViecCuaToi/Popup';
import CocauTochucDA from '../../../redux/middleware/api/CoCauToChuc/CocauTochucDA';
import Pagination from '../../../components/Pagination/Pagination';
import { ERROR, SUCCESS, notify } from '../../../core/AlertNoti';
import { Ultis } from '../../../core/utils';

export default function Themmoicocau() {
    const { register, handleSubmit, control, setError, getValues, setValue, formState: { errors } } = useForm({ shouldFocusError: false });

    const [data, setData] = useState({});
    const [listUser, setlistUser] = useState([]);
    const [listUserNew, setlistUserNew] = useState([]);
    const [listUserNewPage, setlistUserNewPage] = useState([]);
    const [orgList, setorgList] = useState([]);
    const [orgLevelList, setorgLevelList] = useState([]);
    const [tab, setTabs] = useState(0);
    const [isCancel, setisCancel] = useState();
    const [isWanring, setisWanring] = useState();
    const [orgleadItem, setorgleadItem] = useState();
    const [isShowpopUser, setisShowpopUser] = useState();
    const navigate = useNavigate();
    const itemsPerPage = 5;
    let currentPage = 1;
    const [total, setTotal] = useState([]);



    const [pageCount, setPageCount] = useState(0);

    const notificationAlert = useRef();
    const [loading, setLoading] = useState(true);
    let { id } = useParams();
    const initData = async () => {
        if (id) {
            const res = await CocauTochucDA.getDetailOrg(id);
            if (res != null) {
                setData(res);
                setlistUserNew(res.listUser ?? [])
                setlistUserNewPage(res.listUser ?? [])
                setLoading(false)
                setValue('orgName', res.orgName)
                setValue('orgCode', res.orgCode)
                setValue('orgShortName', res.orgShortName)
                setValue('orgParentId', res.orgParentId)
                setValue('orgLevel', res.orgLevel)
                setValue('orgPurpose', res.orgPurpose)
                setValue('orgFunction', res.orgFunction)
                setValue('orgLead', res.orgLead)
                setValue('address', res.address)
                setValue('note', res.note)
            }
        }

    }

    const changeTab = (tab) => {
        setTabs(tab);
        $('.button-tab').removeClass('active');
        $(`.button-tab[tab=${tab}]`).addClass('active');
    }
    const getListUser = async () => {
        const response = await getorgLeadList(1, 100, "");
        if (response != null) {
            setlistUser(response.content ?? []);
        }
    }
    const getAllorgList = async () => {

        const response = await getorgList(1, 100,);
        if (response != null) {
            var lst = response.content;
            debugger;
            if (id) {
                lst = response.content.filter((a) => a.id !== parseInt(id))
            }
            setorgList(lst ?? []);
        }
    }

    const getAllorgLevelList = async () => {
        const response = await getorglevelList();
        if (response != null) {
            setorgLevelList(response ?? []);
        }
    }
    useEffect(() => {
        getListUser()
        getAllorgLevelList()
        getAllorgList()
        initData()
        setLoading(false)

    }, []);
    const handleCheckErrorsAndSubmit = () => {
        // Kiểm tra số lượng lỗi
        // const errorCount = Object.keys(errors).length;
        var lstField = ["orgName", "orgCode", "orgParentId", "orgLevel", "orgPurpose"];
        // Kiểm tra số lượng lỗi bằng cách kiểm tra giá trị của các trường trong formData        
        const errorCount = lstField.filter(field => !getValues(field)).length;

        if (errorCount === 0) {
            // Nếu không có lỗi, thực hiện gửi biểu mẫu
            handleSubmit(onsubmitForm)();
        } else {
            setTabs(0)
            $('.button-tab').removeClass('active');
            $(`.button-tab[tab=${0}]`).addClass('active');
            handleSubmit(onsubmitForm)()
            console.log('Number of errors:', errorCount);
        }
    };
    const onsubmitForm = async (event) => {
        debugger;
        var lstUser = listUserNew.map((a) => a.id);
        event.userIds = lstUser;
        if (event.orgParentId) {
            event.orgParentId = parseInt(event.orgParentId)
        }
        if (!event.orgLead) {
            event.orgLead = null;
        }
        if (id) {
            event.id = id;
            const res = await CocauTochucDA.UpdateOrg(id, event);
            if (res.code == '200') {
                notify(notificationAlert, SUCCESS, 'Chỉnh sửa tổ chức thành công');
                navigate('/admin/co-cau-to-chuc');
                // window.location.reload();
            } else if (res.code == '400') {
                setTabs(0)
                setError("orgCode", {
                    message: "Mã đơn vị đã tồn tại"
                })
            } else {
                notify(notificationAlert, ERROR, res.message);
            }
        } else {
            const res = await CocauTochucDA.CreateOrg(event);
            if (res.code == '200') {
                notify(notificationAlert, SUCCESS, 'Thêm mới tổ chức thành công');
                // window.location.reload();
                navigate('/admin/co-cau-to-chuc');
            }else if (res.code == '400') {
                setTabs(0)
                setError("orgCode", {
                    message: "Mã đơn vị đã tồn tại"
                })
            } else {
                notify(notificationAlert, ERROR, res.message);
            }
        }
    }
    const OnchangeSwitch = async () => {
        const respone = await CocauTochucDA.UpdateStatusOrg(data.id, data.status === 1 ? 0 : 1);
        if (respone.code == '200') {
            setData({ ...data, status: data.status === 1 ? 0 : 1 });
        } else {
            notify(notificationAlert, ERROR, respone.message)
        }
    }
    const onCancel = (val) => {
        setisCancel(val);
        if (val == true) {
            navigate('/admin/co-cau-to-chuc');
        }
    }
    const onDeleteUser = (index) => {
        const updatedItems = [...listUserNew];
        updatedItems.splice(index, 1);
        setlistUserNew(updatedItems);
    }
    return (
        <form autoComplete='off' className="w-frame w-form wbaseItem-value w-col 5c04c564-e500-4a59-8609-0c0d65d23444" level={2} cateid={128}>
            <NotificationAlert ref={notificationAlert} />
            {
                isCancel == false &&
                <WPopup
                    type={PopupType.WARNING}
                    title={!id ? 'Hủy thêm mới tổ chức' : 'Hủy chỉnh sửa tổ chức'}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => onCancel(true)}
                    cancelAction={() => onCancel(null)}
                    cancelButton={true}
                    cancelTitle='Hủy'
                    message={'Những thông tin vừa bạn vừa nhập sẽ không được lưu lại'}
                />
            }
            {
                isWanring == false &&
                <WPopup
                    type={PopupType.WARNING}
                    title={'Đổi tổ chức'}
                    confirmTitle={'Xác nhận'}
                    confirmAction={() => {
                        setisWanring(null)
                        setValue('orgLead', orgleadItem.username)
                        setorgleadItem()
                    }}
                    cancelAction={() => {
                        setisWanring(null)
                        setValue('orgLead', null)
                        setorgleadItem()
                    }}
                    cancelButton={true}
                    cancelTitle='Hủy'
                    message={`Bạn có muốn đổi tổ chức của người dùng này từ ${orgleadItem.orgInfoFilter?.name} sang tổ chức này ?`}
                />
            }
            {
                isShowpopUser &&
                <PopupSelectUser
                    code={listUserNew}
                    callback_action={
                        function (a) {
                            setlistUserNew(a)
                            setTotal(a.length)
                            const count = Math.ceil(a.length / itemsPerPage);
                            setPageCount(count)
                            setisShowpopUser(false)
                        }
                    }
                    notificationAlert={notificationAlert}
                    onClose={function () {
                        setisShowpopUser(false)
                    }}
                />
            }
            <div className="w-frame wbaseItem-value w-col d67bfdc2-b1c0-4467-8c00-6cf118bfe78d" level={3} cateid={140}>
                <div className="w-frame wbaseItem-value w-row 89594030-6aca-4171-a385-2eda06c4395f" level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col 2d31bdc5-1fb6-44dc-81fb-29a7a7f60811" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 35a99f88-7c83-4113-bc75-3d3ad2d87732" level={6} cateid={140}>
                            <div className="w-text wbaseItem-value e84a8e88-df7e-416d-982a-c21017709b20" level={7} cateid={139}>{id ? "Thông tin tổ chức" : "Thêm mới tổ chức"}</div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row breadcrumb aaa9b0c7-11a4-471a-89f5-5351961811ff" level={6} cateid={140}>
                            <button type="button" className="w-button wbaseItem-value w-row 6a3a4c03-2235-462e-9c74-60622e23a13f" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value 47886e2c-9e80-4e68-b25b-249d68b2978d" level={8} cateid={139}>Quản trị hệ thống</div>
                                <div className="w-svg wbaseItem-value 295da048-534a-4b75-95eb-3fdcb4c71963" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                            <button type="button" className="w-button wbaseItem-value w-row 78daea13-3959-471c-b704-978426bee4aa" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value b3077a99-5506-42e3-b18e-b6f54a8f4351" level={8} cateid={139}>Cơ cấu tổ chức</div>
                                <div className="w-svg wbaseItem-value b7aa06ad-f3d0-4c3c-aaad-ce9677b35130" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                            <div className="w-text wbaseItem-value 3721ce64-2e8a-4328-b6be-1dc8c92c2329" level={7} cateid={139}>{id ? "Chỉnh sửa" : "Thêm mới"}</div>
                        </div>
                    </div>
                    {id ? <div className="w-frame wbaseItem-value w-row 0ef46e31-4af4-4b4b-9b9a-64eec3133efb" wrap="nowrap" level={5} cateid={140}>
                        <div className="w-text wbaseItem-value 54368f73-0d68-477e-810b-98623942a257" level={6} cateid={139}>{data?.status === 1 ? 'Active' : 'Deactive'}</div>
                        <label onClick={OnchangeSwitch} className="w-switch wbaseItem-value a6e97769-2e1a-4888-8045-a63e94925bc2" level={6} cateid={81}>
                            <input type="checkbox" disabled checked={data?.status === 1 ? true : false} name="ControlStatus" />
                            <span className="slider">
                            </span>
                        </label>
                    </div> : null}
                </div>
                <div className="w-frame wbaseItem-value w-row 830de131-4616-481b-9ef8-c9484c35603d" level={4} cateid={140}>
                    <button tab={0} onClick={() => changeTab(0)} type="button" className="w-button wbaseItem-value w-row 392eda82-a4f0-456f-abb4-5d1a571f8176 button-tab active" level={5} cateid={29}>
                        <div className="w-text wbaseItem-value b8c59920-7dd8-4d89-a16e-ca28ec4e70de" level={6} cateid={139}>Tổ chức</div>
                    </button>
                    <button tab={1} onClick={() => changeTab(1)} type="button" className="w-button wbaseItem-value w-row e4982fa9-af76-4578-bf82-0a20127dc89b button-tab event-click" level={5} cateid={29}>
                        <div className="w-text wbaseItem-value c56e86fe-5092-42bd-b085-96b74f5a48f0" level={6} cateid={139}>Nhân sự</div>
                    </button>
                </div>
                {
                    loading ? null :
                        <div className={"w-frame wbaseItem-value w-col 615b6e3c-bd77-4b3d-8bbe-3a4f1e5a30fa " + `${tab === 0 ? '' : 'hide'}`} scroll="true" level={4} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col f618570b-28c0-4e2c-abb8-9377979bf848" level={5} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row 80644039-0122-4f3b-b697-5ec4505653fc" level={6} cateid={140}>
                                    <div className="w-text wbaseItem-value 4e87c945-5730-492f-aae5-a76dbe3ff2f4" level={7} cateid={139}>Thông tin tổ chức</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-row 1571c9ad-800a-456f-a06b-f1958776b389" wrap="wrap" level={6} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 134f4836-44f1-41f6-a410-3328e719a0e4" level={7} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row fb112e60-c244-4627-9473-53d9ebc049c4" level={8} cateid={140}>
                                            <div className="w-text wbaseItem-value 9725a70a-8e70-49c7-b8dc-f22b8f8d1cdf" level={9} cateid={139}>Tên đơn vị</div>
                                            <div className="w-text wbaseItem-value 9bcbf237-4328-4cab-9afb-39b6b0130a93" level={9} cateid={139}>*</div>
                                        </div>
                                        <div className={"w-textformfield wbaseItem-value w-row 2dcaf3c9-ba1f-4f95-8768-0af135f680c3 " + (errors.orgName && 'helper-text')} helper-text={errors.orgName && 'Vui lòng nhập tên đơn vị'} level={8} cateid={85} placeholder="Nhập tên đơn vị" validate="[{&quot;Type&quot;:10,&quot;Message&quot;:&quot;Trường không được bỏ trống!&quot;}]">
                                            <div className="wbaseItem-value 58faa0d8-b600-4958-87f6-8b45e6431c73" name-field="Textfield" level={9} cateid={86}>
                                                <div className="textfield">
                                                    <input maxLength={50} name="orgName" {...register('orgName', { required: true, onBlur: (ev) => ev.target.value = ev.target.value.trim() })} placeholder="Nhập tên đơn vị" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 10c5c168-8e3b-4828-8184-d85d560cfde0" level={7} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row bef08d4e-ea4b-413a-b804-cb8ac23835c9" level={8} cateid={140}>
                                            <div className="w-text wbaseItem-value 5111bdc3-13b8-4c74-aca4-e096c182d704" level={9} cateid={139}>Mã đơn vị</div>
                                            <div className="w-text wbaseItem-value 6b80ac65-f73e-4869-9568-c954d49a4c6f" level={9} cateid={139}>*</div>
                                        </div>
                                        <div className={"w-textformfield wbaseItem-value w-row e092ef3e-4bf5-43e5-97c5-c46022bf81bf " + (errors.orgCode && 'helper-text')} helper-text={errors.orgCode && (errors.orgCode.message.length > 0 ? errors.orgCode.message : 'Vui lòng nhập mã đơn vị')} level={8} cateid={85} placeholder="Nhập mã đơn vị" validate="[{&quot;Type&quot;:10,&quot;Message&quot;:&quot;Trường không được bỏ trống!&quot;}]">
                                            <div className="wbaseItem-value 6e49f899-a941-48f2-942e-92fb188b5d42" name-field="Textfield" level={9} cateid={86}>
                                                <div className="textfield">
                                                    <input maxLength={20} name="orgCode" {...register('orgCode', { required: true, onChange: (ev) => { ev.target.value = ev.target.value.replaceAll(" ", "") } })} placeholder="Nhập mã đơn vị" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm daf94c57-4ad2-4129-a88e-85e650a85ffb" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 4c98e5c8-5363-4c07-b7ed-746e491e886f" level={8} cateid={139}>
                                            Tên viết tắt</div>
                                        <div className={"w-textformfield wbaseItem-value w-row 4aec1cf9-eaf3-4378-a5f9-47424d4e11aa "} name-field="orgShortName" level={8} cateid={85} placeholder="Nhập tên viết tắt">
                                            <div className="wbaseItem-value 14c70ac8-1e71-43a5-a9af-8600058b01cc" name-field="Textfield" level={9} cateid={86}>
                                                <div className="textfield">
                                                    <input maxLength={20} name="orgShortName" {...register('orgShortName', { onBlur: (ev) => ev.target.value = ev.target.value.trim() })} placeholder="Nhập tên viết tắt" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 02cb77f8-07e3-4bdc-80a6-3a7534e5ecb3" level={7} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row d068ed33-288b-4e0b-b849-4a2253dad110" level={8} cateid={140}>
                                            <div className="w-text wbaseItem-value 535b9374-2173-42b1-93dc-02b75a8cef95" level={9} cateid={139}>Thuộc đơn vị</div>
                                            <div className="w-text wbaseItem-value 7e132109-88d6-40e5-92fb-2daccbd1cd9c" level={9} cateid={139}>*</div>
                                        </div>
                                        {/* <div className={"w-textformfield wbaseItem-value w-row 5e4a97f0-3ea6-4a70-9e19-6633e0e53e87  " + (errors.orgName && 'helper-text')} helper-text={errors.orgParentId && 'Vui lòng chọn đơn vị'} name-field="Textformfield" level={8} cateid={85} placeholder="Chọn đơn vị" >
                                        <div className="wbaseItem-value 4eb3ef2a-a437-467b-9310-c500870c4bde" name-field="Textfield" level={9} cateid={86}>
                                            <div className="textfield">
                                                <input name="orgParentId" {...register('orgParentId', { required: true })} placeholder="Chọn đơn vị" />
                                            </div>
                                        </div>
                                        <div className="w-svg wbaseItem-value 4aa1604a-9d1e-40bf-82fd-65a68cc339c1" level={9} cateid={115}>
                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z" fill="#28282999">
                                                </path>
                                            </svg>
                                        </div>
                                    </div> */}
                                        <div className={'select2-custom ' + (errors.orgParentId && 'helper-text')} helper-text={errors.orgParentId && 'Vui lòng chọn đơn vị'}>
                                            <Controller
                                                name="orgParentId"
                                                control={control}
                                                {...register("orgParentId")}
                                                style={{ order: 2 }}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Select2 {...field}
                                                        // data={orgLevelList.map((item, _) => { return { id: item.orgLevelCode, name: item.orgLevelName } })}
                                                        data={orgList}
                                                        options={{ placeholder: 'Chọn đơn vị' }}

                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 43e93781-cd9a-4491-aef2-4ceae4f8b2b5" level={7} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row f1b262bf-f2ac-4374-8fd6-b80740ba7a97" level={8} cateid={140}>
                                            <div className="w-text wbaseItem-value 2f6deb57-c8ec-4d13-9554-9498798465f6" level={9} cateid={139}>Cấp tổ chức</div>
                                            <div className="w-text wbaseItem-value d5de0799-47e7-4d80-90e9-55f622452ba4" level={9} cateid={139}>*</div>
                                        </div>
                                        {/* <div className={"w-textformfield wbaseItem-value w-row 46e793da-bd6d-4a88-a0a6-aeb36ad00cc0   "+ (errors.orgName && 'helper-text')} helper-text={errors.orgParentId && 'Vui lòng chọn cấp tổ chức'}   name-field="orgLevel" level={8} cateid={85} placeholder="Cấp tổ chức" >
                            <div className="wbaseItem-value 5ba22fc5-b6f7-4b75-9f2d-0581cb38b887" name-field="Textfield" level={9} cateid={86}>
                              <div className="textfield">
                                <input name="orgLevel" {...register('orgLevel',{required: true})} placeholder="Cấp tổ chức" />
                              </div>
                            </div>
                            <div className="w-svg wbaseItem-value 417c6504-2fe3-4543-bd74-f3dbbb832fbb" level={9} cateid={115}>
                              <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z" fill="#28282999">
                                </path>
                              </svg>
                            </div>
                          </div> */}
                                        <div className={'select2-custom ' + (errors.orgLevel && 'helper-text')} helper-text={errors.orgLevel && 'Vui lòng chọn cấp tổ chức'}>
                                            <Controller
                                                name="orgLevel"
                                                control={control}
                                                {...register("orgLevel")}
                                                style={{ order: 2 }}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Select2 {...field}
                                                        data={orgLevelList.map((item, _) => { return { id: item.code, name: item.name } })}
                                                        // data={orgLevelList}
                                                        options={{ placeholder: 'Chọn chu cấp tổ chức' }}

                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm fe071658-4142-4854-8931-3519b1a7fad9" level={7} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row 7cba4027-b73d-46eb-8d91-73ff0b67ff5f" level={8} cateid={140}>
                                            <div className="w-text wbaseItem-value e6ec7e17-bf26-4565-bb43-818bcf25b98b" level={9} cateid={139}>Lĩnh vực hoạt động</div>
                                            <div className="w-text wbaseItem-value c52fa22a-0317-4dcb-bd8d-c589c85f90bc" level={9} cateid={139}>*</div>
                                        </div>
                                        <div className={"w-textformfield wbaseItem-value w-row e092ef3e-4bf5-43e5-97c5-c46022bf81bf " + (errors.orgPurpose && 'helper-text')} helper-text={errors.orgPurpose && 'Vui lòng chọn lĩnh vực hoạt động'} level={8} cateid={85} placeholder="Nhập lĩnh vực hoạt động">
                                            <div className="wbaseItem-value 6e49f899-a941-48f2-942e-92fb188b5d42" level={9} cateid={86}>
                                                <div className="textfield">
                                                    <input maxLength={50} name="orgPurpose" {...register('orgPurpose', { required: true, onBlur: (ev) => ev.target.value = ev.target.value.trim() })} placeholder="Nhập lĩnh vực hoạt động" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 08a4c14e-ec14-4c7c-9168-8a25bd1bf705" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value af795dde-59f8-41c2-94d6-b94e88ee5079" level={8} cateid={139}>
                                            Chức năng nhiệm vụ</div>
                                        <div className="w-textformfield wbaseItem-value w-row 4466c5fc-ddf1-424f-afbc-29fadbae98f3" name-field="OrgFunction" level={8} cateid={85} placeholder="Nhập chức năng nhiệm vụ">
                                            <div className="wbaseItem-value 9e974f13-e498-44ab-9120-9a3d00d1ed65" name-field="Textfield" level={9} cateid={86}>
                                                <div className="textfield">
                                                    <input maxLength={50} name="OrgFunction" {...register('OrgFunction',{onBlur: (ev) => ev.target.value = ev.target.value.trim() })} placeholder="Nhập chức năng nhiệm vụ" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm a1423a1c-95f2-4a0a-9e94-a975a9b164a7" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 282af92b-fb2b-4943-89c8-ec7bde56d914" level={8} cateid={139}>
                                            Trưởng đơn vị</div>
                                        {/* <div className="w-textformfield wbaseItem-value w-row ee6a5709-362f-400f-9b7d-da22cdc4f117" name-field="orgLead" level={8} cateid={85} placeholder="Nhập tên trưởng đơn vị">
                                        <div className="wbaseItem-value 759c88e2-fac2-45d3-8459-4533910a52c9" name-field="Textfield" level={9} cateid={86}>
                                            <div className="textfield">
                                                <input name="orgLead" {...register('orgLead')}  placeholder="Nhập tên trưởng đơn vị" />
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className={'select2-custom ' + (errors.orgLead && 'helper-text')} helper-text={errors.orgLead && 'Vui lòng chọn trưởng đơn vị'}>
                                            <Controller
                                                name="orgLead"
                                                control={control}
                                                {...register("orgLead")}
                                                style={{ order: 2 }}
                                                // rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Select2 {...field}
                                                        // data={orgLevelList.map((item, _) => { return { id: item.orgLevelCode, name: item.orgLevelName } })}
                                                        data={listUser.map((item, _) => { return { id: item.username, name: item.fullName } })}
                                                        options={{ placeholder: 'Chọn trưởng đơn vị' }}
                                                        onClose={(event) => {
                                                            if (event.target.value) {
                                                                debugger;
                                                                var selectItem = listUser.find((a) => a.username == event.target.value && a.orgId !== parseInt(id));
                                                                if (selectItem?.orgId) {
                                                                    setorgleadItem(selectItem)
                                                                    setisWanring(false)
                                                                }
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col24-xxl col24-xl col24-lg col24-md col24 col24-sm 8ebc8790-436f-4976-bd7d-b716e7b20534" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value bfcb0403-e850-4a01-8ee6-f095b8f1abb8" level={8} cateid={139}>
                                            Địa chỉ</div>
                                        <div className="w-textformfield wbaseItem-value w-row 8f267dd5-70b8-4b22-96a4-4cfdcca892bb" name-field="Address" level={8} cateid={85} placeholder="Nhập địa chỉ">
                                            <div className="wbaseItem-value eed1410d-70d3-4c6a-bd24-a906c023383a" name-field="Textfield" level={9} cateid={86}>
                                                <div className="textfield">
                                                    <input maxLength={100} name="address"  {...register('address',{onBlur: (ev) => ev.target.value = ev.target.value.trim() })} placeholder="Nhập địa chỉ" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col 1d566517-90a6-4b20-a8bf-5a04ec5fab3e" level={5} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row 69615420-cd44-40f6-81fc-37167bc7143d" level={6} cateid={140}>
                                    <div className="w-text wbaseItem-value 498090d9-c9b6-45d8-83dc-84c6ba345cf0" level={7} cateid={139}>Ghi chú</div>
                                </div>
                                {
                                    id ? <div className="w-frame wbaseItem-value w-row 7eafe740-57bd-413e-8055-925e596a3f9e" level={11} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row df921824-0c6c-430b-8491-87bb5f8662b1" level={12} cateid={140}>
                                            <div className="w-svg wbaseItem-value 647d318a-ed7f-461d-bda1-dda057d9db18" level={13} cateid={115}>
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.9997 7C16.9997 9.76142 14.7611 12 11.9997 12C9.23826 12 6.99968 9.76142 6.99968 7C6.99968 4.23858 9.23826 2 11.9997 2C14.7611 2 16.9997 4.23858 16.9997 7Z" fill="#28282999" />
                                                    <path d="M3.66667 19.222C3.66667 17.177 6.375 13.667 12 13.667C17.625 13.667 20.3333 17.177 20.3333 19.222V20.3337C20.3333 20.7757 20.1577 21.1996 19.8452 21.5122C19.5326 21.8247 19.1087 22.0003 18.6667 22.0003H5.33334C4.89131 22.0003 4.46739 21.8247 4.15483 21.5122C3.84227 21.1996 3.66667 20.7757 3.66667 20.3337V19.222Z" fill="#28282999" />
                                                </svg>
                                            </div>
                                            <div className="w-text wbaseItem-value 04e5c11c-0398-48ce-84ce-bdaa22db55b7" level={13} cateid={139}>{data.modifiedBy ?? "_"}</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row fdcad8fb-88d5-4cc0-9433-7563b5d002f2" level={12} cateid={140}>
                                            <div className="w-svg wbaseItem-value 96785fe5-de4f-4bdd-afa2-657299308354" level={13} cateid={115}>
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.16667 11.1667C5.70643 11.1667 5.33333 11.5398 5.33333 12V12.8333C5.33333 13.2936 5.70643 13.6667 6.16667 13.6667H7.83333C8.29357 13.6667 8.66667 13.2936 8.66667 12.8333V12C8.66667 11.5398 8.29357 11.1667 7.83333 11.1667H6.16667Z" fill="#28282999" />
                                                    <path d="M11.1667 11.1667C10.7064 11.1667 10.3333 11.5398 10.3333 12V12.8333C10.3333 13.2936 10.7064 13.6667 11.1667 13.6667H12.8333C13.2936 13.6667 13.6667 13.2936 13.6667 12.8333V12C13.6667 11.5398 13.2936 11.1667 12.8333 11.1667H11.1667Z" fill="#28282999" />
                                                    <path d="M5.33333 16.1667C5.33333 15.7064 5.70643 15.3333 6.16667 15.3333H7.83333C8.29357 15.3333 8.66667 15.7064 8.66667 16.1667V17C8.66667 17.4602 8.29357 17.8333 7.83333 17.8333H6.16667C5.70643 17.8333 5.33333 17.4602 5.33333 17V16.1667Z" fill="#28282999" />
                                                    <path d="M11.1667 15.3333C10.7064 15.3333 10.3333 15.7064 10.3333 16.1667V17C10.3333 17.4602 10.7064 17.8333 11.1667 17.8333H12.8333C13.2936 17.8333 13.6667 17.4602 13.6667 17V16.1667C13.6667 15.7064 13.2936 15.3333 12.8333 15.3333H11.1667Z" fill="#28282999" />
                                                    <path d="M15.3333 12C15.3333 11.5398 15.7064 11.1667 16.1667 11.1667H17.8333C18.2936 11.1667 18.6667 11.5398 18.6667 12V12.8333C18.6667 13.2936 18.2936 13.6667 17.8333 13.6667H16.1667C15.7064 13.6667 15.3333 13.2936 15.3333 12.8333V12Z" fill="#28282999" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.83333 2.83333C7.83333 2.3731 7.46024 2 7 2C6.53976 2 6.16667 2.3731 6.16667 2.83333V3.66667H4.5C3.11929 3.66667 2 4.78595 2 6.16667V18.6667C2 20.0474 3.11929 21.1667 4.5 21.1667H19.5C20.8807 21.1667 22 20.0474 22 18.6667V6.16667C22 4.78595 20.8807 3.66667 19.5 3.66667H17.8333V2.83333C17.8333 2.3731 17.4602 2 17 2C16.5398 2 16.1667 2.3731 16.1667 2.83333V3.66667H7.83333V2.83333ZM4.5 5.33333C4.03976 5.33333 3.66667 5.70643 3.66667 6.16667V7.83333H20.3333V6.16667C20.3333 5.70643 19.9602 5.33333 19.5 5.33333H17.8333C17.8333 5.79357 17.4602 6.16667 17 6.16667C16.5398 6.16667 16.1667 5.79357 16.1667 5.33333H7.83333C7.83333 5.79357 7.46024 6.16667 7 6.16667C6.53976 6.16667 6.16667 5.79357 6.16667 5.33333H4.5ZM4.5 19.5C4.03976 19.5 3.66667 19.1269 3.66667 18.6667V9.5H20.3333V18.6667C20.3333 19.1269 19.9602 19.5 19.5 19.5H4.5Z" fill="#28282999" />
                                                </svg>
                                            </div>
                                            <div className="w-text wbaseItem-value 4be92051-49e9-47f1-8ab9-524f165cfb72" level={13} cateid={139}>{data.modifiedDate ? Ultis.datetoString(new Date(data.modifiedDate), "dd/mm/yyyy hh:mm") : "_"}</div>
                                        </div>
                                    </div> : null
                                }
                                {id ? <div className="w-frame wbaseItem-value w-row 390a300a-54ad-42d5-8e30-901db61ed335" level={10} cateid={140}>
                                    <div className="w-text wbaseItem-value e2118044-7c17-4e96-841a-8acf4f20a207" level={11} cateid={139}>Địa chỉ:</div>
                                    <div className="w-text wbaseItem-value 1a5dc591-e58a-4e47-af20-c8ef5c008f53" level={11} cateid={139}>{data.address ?? "_"}</div>
                                </div> : null}


                                <div className="w-frame wbaseItem-value w-row f0a69b43-676c-4e62-a859-409dbf32ece4" level={7} cateid={140} >
                                    <div className="w-text wbaseItem-value 2b5433d0-5823-4d07-bc83-b606afc6cd27" level={8} cateid={139}>Thêm ghi chú</div>
                                </div>
                                <div className="w-textformfield wbaseItem-value w-row e68acf11-80ee-4c3b-b4e2-3179c975d763" name-field="Note" level={6} cateid={85} placeholder="Nhập ghi chú">
                                    <div className="wbaseItem-value 515026cf-3d37-4c59-be6f-2cfa36fb6321" level={7} cateid={86}>
                                        <div className="textfield">
                                            <textarea maxLength={1000} cols="40" rows="5" name="note" {...register('note', {onBlur: (ev) => ev.target.value = ev.target.value.trim() })} placeholder="Nhập ghi chú" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {id ? <div className="w-frame wbaseItem-value w-col 9eb18653-3703-4f95-a85b-74d4ca8ece6d" level={5} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row 80644039-0122-4f3b-b697-5ec4505653fc" level={6} cateid={140}>
                                    <div className="w-text wbaseItem-value 4e87c945-5730-492f-aae5-a76dbe3ff2f4" level={7} cateid={139}>Lịch sử</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-row 1e7979de-8c4b-44e4-8cd5-f8757b861613" wrap="wrap" level={6} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl 50140cd2-149a-4ead-b0fb-d3f80bfce450" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 1d33454e-07c2-4b49-83dc-02c3bbfa5e90" level={8} cateid={139}>Người tạo:</div>
                                        <div className="w-text wbaseItem-value 82d8c317-da95-41d2-bd17-e5a1a4651a9d" level={8} cateid={139}>{data.createdBy}</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl 02200cc8-9be3-4ce3-9f17-28be0fced6f3" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 8b1c558c-3d19-4994-be37-503a66f5996b" level={8} cateid={139}>Ngày tạo:</div>
                                        <div className="w-text wbaseItem-value 422aca98-e87e-4690-9e53-3ac8648e7e41" level={8} cateid={139}>{data.createdDate ? Ultis.datetoString(new Date(data.createdDate), "dd/mm/yyyy hh:mm") : "_"}</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col12-lg col24-md col12-xl col6-xxl e7040e4d-5db5-44b3-a879-e37e73c8e9c9" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value d692dff2-ab0c-4949-9fba-b6906bce6568" level={8} cateid={139}>Người thay đổi cuối:</div>
                                        <div className="w-text wbaseItem-value 45defc28-b4bd-4186-bc28-a57e350ffd3f" level={8} cateid={139}>{data.modifiedBy}</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col12-xl ad18ff4d-59d6-4ea4-ba2b-2721f6e549c4" level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 907931d7-c4e2-41d0-8daf-1ddf0538948a" level={8} cateid={139}>Ngày thay đổi cuối:</div>
                                        <div className="w-text wbaseItem-value dbb81084-bbd1-4d6f-b581-9e59651fb842" level={8} cateid={139}>{data.modifiedDate ? Ultis.datetoString(new Date(data.modifiedDate), "dd/mm/yyyy hh:mm") : "_"}</div>
                                    </div>
                                </div>
                            </div> : null}

                        </div>
                }
                {
                    loading ? null :
                        <div className={"w-frame wbaseItem-value w-col 1845cb57-2c36-488a-a61f-4be65fbc5d9f " + `${tab === 1 ? '' : 'hide'}`} scroll="true" level={4} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col d02721d5-8b95-4a2d-93a7-522b4530fb92" level={5} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row d1255b35-8cb2-44fa-a3e7-a2d711d833f8" level={6} cateid={140}>
                                    <div className="w-text wbaseItem-value 8136f0d7-1d21-4b87-80fe-5f4a7d9b307f" level={7} cateid={139}>Danh sách
                                        nhân sự</div>
                                    <button onClick={() => setisShowpopUser(true)} type="button" className="w-button wbaseItem-value w-row 90cfdd03-2a3a-4090-b775-8dee0f0284cb event-click" level={7} cateid={29}>
                                        <div className="w-svg wbaseItem-value 0a912fcf-cb49-4561-83ad-8a1d7a9daba0" level={8} cateid={115}>
                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z" fill="#009944FF">
                                                </path>
                                            </svg>
                                        </div>
                                        <div className="w-text wbaseItem-value 5ae1de12-c889-413c-8dae-1ae248273d33" level={8} cateid={139}>Thêm nhân sự</div>
                                    </button>
                                </div>
                                <div className='w-row wbaseItem-value w-frame' scroll="true" style={{ width: "100%" }}>
                                    <table className="w-table wbaseItem-value c7f0b096-995f-4cfe-a6be-4acf70a401a8" type={0} level={6} cateid={117}>
                                        <tbody>
                                            <tr className="table-row">
                                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 1be5a440-21f1-4526-839f-b4557e75a98c" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value 4dc2e471-62b9-44f4-816e-71723434bc5a" level={8} cateid={139}>STT</div>
                                                    </div>
                                                </td>
                                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row e9166c4c-aed5-44c1-a844-316152540395" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value 884db564-7e20-492c-8822-4b8999401e1b" level={8} cateid={139}>Mã nhân viên</div>
                                                    </div>
                                                </td>
                                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 464a000b-216f-4384-bbfe-a824fda202a1" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value b3dbb087-9930-4cca-8bdb-5a58368ea13c" level={8} cateid={139}>Tên nhân viên</div>
                                                    </div>
                                                </td>
                                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 8d98c5e7-b979-4af1-87ff-2cddac48306f" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value dc63a0a5-434c-4902-9858-da20c1b7b38f" level={8} cateid={139}>Tên đăng nhập</div>
                                                    </div>
                                                </td>
                                                <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 74477a35-d457-40c8-834b-31690570c9a2" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value 5ae8215e-4170-461f-bea4-ebe6024d47f7" level={8} cateid={139}>Chức danh</div>
                                                    </div>
                                                </td>
                                                <td id="1x6" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row b30bee88-901f-4b3f-8d86-8a0eb8dfd10c" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value 424a26b4-a301-49bd-87e4-222aa65a14fd" level={8} cateid={139}>Tổ chức</div>
                                                    </div>
                                                </td>
                                                <td id="1x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row c3b24dfd-96de-4359-b2b4-5aa2ca6e2971" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value bb3fc640-0d4b-45c2-873f-f36ad7fb5eff" level={8} cateid={139}>Khu vực<br />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td id="1x8" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row d990e622-24f4-4165-8775-0c823a01d275" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value b680f67b-31a0-42cc-8d3c-398032da9b9e" level={8} cateid={139}>Trạng thái<br />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td id="1x9" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row c975a099-c72b-4e04-a4fa-03582e27a139" level={7} cateid={140}>
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                listUserNew.map((item, index) => {
                                                    return (<tr className="table-row">
                                                        <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row b0c07d69-0e50-4ac7-b549-5d704dc40323" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 8e327db6-0aa1-4d9a-951d-cb42d7a503d8" level={8} cateid={139}>{index + 1}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 1a1a6bf3-b692-48d0-98be-734c510e66a8" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value e310fa04-3edc-40e3-b1b5-5b6c502933d1" level={8} cateid={139}>{item.staffCode}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row d6995898-127c-4643-9c7f-6a4712751f25" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value a601d2b2-dc83-4be9-bf4b-9fd1b9cae995" level={8} cateid={139}>{item.fullName}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row db3cdffb-edca-43c7-a9c3-8511fb41f06a" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 04fcf3c2-424a-4b71-ad1b-9537b4247a67" level={8} cateid={139}>{item.username}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 02adcdee-83af-4703-a610-67e22b60fff2" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value a35836fd-15ed-4551-9d2b-efde7887f8c4" level={8} cateid={139}>{item.orgTitle?.name}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x6" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row b6053ccf-946c-40c0-aa08-fbafbf9d7747" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 4860266c-f17c-49b5-841b-34b6c99412a9" level={8} cateid={139}>{item.orgInfo?.orgName}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 041840dc-cc66-4542-83a5-84b73b1164d2" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value bc8f8562-02ec-4525-b67e-7ef1c00bf984" level={8} cateid={139}>{item.areaInfo?.name}</div>
                                                            </div>
                                                        </td>
                                                        <td id="2x8" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 029df50d-8ecc-4391-abbd-d40863ef789b" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value c94f15ef-07be-40e5-95bc-fca8c14768ad" level={8} cateid={139}>{item.status === 1 ? 'Hoạt động' : 'Không hoạt động'}<br />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td id="2x9" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row cd77c677-f955-442e-9f25-36c46d5fdec4" level={7} cateid={140}>
                                                                <div onClick={() => onDeleteUser(index)} className="w-svg wbaseItem-value d59bbb53-f776-454e-8eb6-13854f317333" level={8} cateid={115}>
                                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF">
                                                                        </path>
                                                                        <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF">
                                                                        </path>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                {/* <div className="w-frame wbaseItem-value w-row 5d3be5fd-db65-48e7-9fec-f00befb14cce" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 0cf7e8ba-66ee-4ddf-93c5-bc5970b4d2b6" level={7} cateid={139}>Hiển thị
                                    1-10 trong tổng số 100 bản ghi</div>
                                <div className="w-frame wbaseItem-value w-row 932d2364-2fa8-4fba-ab0c-8101be449117" level={7} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-row 37574647-2a9c-458c-b5a1-f8f25dbfe913" level={8} cateid={140}>
                                        <div className="w-text wbaseItem-value 58a0538f-844a-4b7b-bd3f-71f949a9e841" level={9} cateid={139}>
                                            Items/page</div>
                                        <div className="w-textformfield wbaseItem-value w-row 01f95cec-db61-4ab9-9a37-4dbcc5f1c647" name-field="Textformfield" level={9} cateid={85} placeholder={10}>
                                            <div className="wbaseItem-value 07c2da2b-e6cf-4638-8a66-a496b5fcc6c6" name-field="Textfield" level={10} cateid={86}>
                                                <div className="textfield">
                                                    <input name="Textformfield" placeholder={10} />
                                                </div>
                                            </div>
                                            <div className="w-svg wbaseItem-value 0eb2b1d6-9590-454b-8e5b-aa99258ddd11" level={10} cateid={115}>
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z" fill="#28282999">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row ead21e9d-6b10-46b2-ba64-5a166ff2fff5" level={8} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row d4fb9e5f-39a9-45c4-9f7e-3dafb4de4135" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value 53a06ce2-b5bf-4562-b014-21c921a170b8" level={10} cateid={139}>Previous</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row be922dea-6118-4a5b-85be-5d3001be63c9" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value 92520adb-1400-4925-b79f-bb2bbd09216b" level={10} cateid={139}>1</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 5409dc2c-6522-4bb4-bc83-1b32b27dcd60" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value b4500649-83ff-413e-b58f-3b028033fb86" level={10} cateid={139}>2</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 46b45c78-579f-473d-b012-e9a78d2ebf80" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value a8334e77-e1c2-48e5-93c1-6da80ff887e3" level={10} cateid={139}>3</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row a5bf4943-f3bf-45f9-867b-203e1ee0e033" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value ca2f4f3a-41d9-4b00-b577-9e831afcf9c5" level={10} cateid={139}>4</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row fce005fb-0488-4649-b7c9-809961804538" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value cfc08995-b83a-4748-b592-53b14d511ccb" level={10} cateid={139}>...</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 078b0354-5ff2-4b41-9af5-24b93bc277a9" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value 017fe475-ac45-4034-85cb-848698231b17" level={10} cateid={139}>5</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 9f1a10cb-9713-431d-b9e7-e4d22d04dfd7" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value e7da9784-0590-45aa-8ad6-f8d4577a3c17" level={10} cateid={139}>50</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 2bea2cdf-7f22-48d6-84a2-e2f72465fc62" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value 465642df-8d32-4cfa-9f8a-9a687fc8f738" level={10} cateid={139}>Next</div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                                {/* <Pagination
                                pageCount={pageCount}
                                action={handlePageClick}
                                itemPerPage={itemsPerPage}
                                totalItem={total}
                                onChangePage={handleChangePage}
                            /> */}
                            </div>
                        </div>
                }
            </div>
            <div className="w-frame wbaseItem-value w-row a4c628c7-e4e4-41bb-9750-082f74bc0f2e" level={3} cateid={140}>
                <button onClick={() => onCancel(false)} type="button" className="w-button wbaseItem-value w-row cf5b4335-5601-46ea-9410-71fc4cba7dbb" level={4} cateid={29}>
                    <div className="w-text wbaseItem-value 684d7306-b7d8-4986-919f-f22930a536df event-click" level={5} cateid={139}>Hủy</div>
                </button>
                <button type="button" onClick={handleCheckErrorsAndSubmit} className="w-button wbaseItem-value w-row c8207b56-4cfd-4627-9e4f-7859ab54e479 event-click" level={4} cateid={29}>
                    <div className="w-svg wbaseItem-value 0090d2dd-9c5f-4b20-8a21-9b2a3889ac52" level={5} cateid={115}>
                        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M14.4893 3.5115C14.726 3.74818 14.726 4.13191 14.4893 4.36859L6.61053 12.2474C6.37385 12.4841 5.99011 12.4841 5.75343 12.2474L1.51101 8.00496C1.27433 7.76828 1.27433 7.38454 1.51101 7.14786C1.74769 6.91118 2.13142 6.91118 2.36811 7.14786L6.18198 10.9617L13.6322 3.5115C13.8689 3.27481 14.2526 3.27481 14.4893 3.5115Z" fill="#FFFFFFFF">
                            </path>
                        </svg>
                    </div>
                    <div className="w-text wbaseItem-value 30a634f9-266d-41d2-aecd-0417dddb5ec0" level={5} cateid={139}>Lưu</div>
                </button>
            </div>
        </form>
    )
}

