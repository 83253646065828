import { InsertInsurance, UpdateInsurance, baseurl, getallInsurance, getalltypeInsurance,getallperiodInsurance, getallsuplierInsurance,  getallpaymenttypeInsurance,getallchargedtypeInsurance, ChangestatusInsurance } from "../../../../config/ConfigAPI";
import { getData, postData, putData } from "../../../../core/BaseDA";

export const getListInsurance = async (page, size, obj) => {
    const url = `${baseurl}${getallInsurance}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
    const response = await postData(url, {obj : obj} );
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const CreateInsurance = async (obj) => {
    const url = `${baseurl}${InsertInsurance}`;
    const response = await postData(url, {obj : obj});
    return response;
};

export const EditInsurance = async (obj,id) => {
    const url = `${baseurl}${UpdateInsurance}/${id}`;
    const response = await putData(url, {obj : obj});
    return response;
};

export const ChangestatusInsur = async (code) => {
    const url = `${baseurl}${ChangestatusInsurance}?code=${code}`;
    const response = await putData(url, {obj : null});
    return response;
};


export const getallInsurancetype = async (page, size) => {
    const url = `${baseurl}${getalltypeInsurance}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getallInsurancesuplier = async (page, size) => {
    const url = `${baseurl}${getallsuplierInsurance}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getallInsuranceperiod = async (page, size) => {
    const url = `${baseurl}${getallperiodInsurance}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

export const getallInsurancepaymenttype = async (page, size) => {
    const url = `${baseurl}${getallpaymenttypeInsurance}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const getallInsurancechargedtype = async (page, size) => {
    const url = `${baseurl}${getallchargedtypeInsurance}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};