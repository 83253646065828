import { Createloanprogram, UpdateStatusloanprogram, Updateloanprogram, baseurl, facilityList, getallloanprogram, Dailyapdung, groupProvinceTreedealer } from "../../../../config/ConfigAPI";
import { getData, postData, putData } from "../../../../core/BaseDA";

export default class loanprogramDA {
    static getListLoan = async (page, size, obj) => {
        const url = `${baseurl}${getallloanprogram}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
        const response = await postData(url, { obj: obj });
        if (response.code === "200") {
            return response.data;
        }
        return null;
    };
    static getListFaci = async (page, size, obj) => {
        const url = `${baseurl}${facilityList}?pageNum=${page ?? 0}&pageSize=${size ?? 0}&sorts=`;
        const response = await postData(url, { obj: obj });
        if (response.code === "200") {
            return response.data;
        }
        return null;
    };
    static TreeDealer = async () => {
        const url = `${baseurl}${groupProvinceTreedealer}`;
        const response = await getData(url);
        if (response.code === "200") {
            return response.data;
        }
        return null;
    };
    static getListDailyapdung = async () => {
        const url = `${baseurl}${Dailyapdung}`;
        const response = await getData(url, { obj: null });
        if (response.code === "200") {
            return response.data;
        }
        return null;
    };
    static Updateloan = async (obj, id) => {
        const url = `${baseurl}${Updateloanprogram}/${id}`;
        const response = await putData(url, { obj: obj });
        return response;
    };
    static UpdateStatusloan = async (obj) => {
        const url = `${baseurl}${UpdateStatusloanprogram}`;
        const response = await putData(url, { obj: obj });
        return response;
    };
    static createloan = async (obj) => {
        const url = `${baseurl}${Createloanprogram}`;
        debugger
        try {
            const response = await postData(url, { obj: obj });
            return response;
        } catch (error) {
            return error;
        }
    };
}