import { baseurl,  detailHanmuc,  listHanmuc, updateStatusHanmuc,  } from "../../../../config/ConfigAPI";
import { getData, postData, putData } from "../../../../core/BaseDA";
export const getListHanmuc = async (page, size, query) => {
    const url = `${baseurl}${listHanmuc}${query ? `${query}&pageNum=${page ?? 0}&pageSize=${size ?? 0}` : `?pageNum=${page ?? 0}&pageSize=${size ?? 0}`}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
// export const getListModue = async () => {
//     const url = `${baseurl}${listmodule}`;
//     const response = await getData(url);
//     if (response.code === "200") {
//         return response.data;
//     }
//     return null;
// };

    export const HanmucCreate = async (obj) => {
        const url = `${baseurl}${listHanmuc}`;
        const response = await postData(url, {obj:obj});    
        return response;
    };

    
    export const HanmucUpdate = async (obj) => {
        const url = `${baseurl}${listHanmuc}`;
        const response = await putData(url, {obj:obj});    
        return response;
    };
    export const updateStatusLimit = async (id,status) => {
        const url = `${baseurl}${updateStatusHanmuc}?id=${id}&status=${status}`;
        const response = await putData(url, {obj:null});    
        return response;
    };

export const getDetailHanmuc = async (id) => {
    
    const url = `${baseurl}${detailHanmuc}?id=${id}`;
    const response = await getData(url);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};

// export const HanmucUpdate = async (id, obj) => {
//     const url = `${baseurl}${updateHanmuc}?groupId=${id}`;
    
//     const response = await putData(url,{obj: obj});
//         return response;
// };