import { ChangeStatusBranchManager, DeleteBranchManager, ListBranchManager, UpdateBranchManager, UpdateBranchnameManager, UpdateManufactureBranchManager, UpdateCodeBranchManager, baseurl, addmanufactureManager } from "../../../../config/ConfigAPI";
import { getData, postData, putData } from "../../../../core/BaseDA";

export default class NhanhieuDA {
    static getListbranch = async (name = "") => {
        const url = `${baseurl}${ListBranchManager}${name !== "" ? `?search=${name}` : ""}`;
        const response = await getData(url);
        if (response.code === "200") {
            return response.data;
        }
        return null;
    };
    static AddManuFacture = async (obj) => {
        const url = `${baseurl}${addmanufactureManager}`;
        const response = await postData(url, { obj: obj });
        return response;
    };
    static UpdateStatusBranch = async (id, status) => {
        const url = `${baseurl}${ChangeStatusBranchManager}?id=${id}&status=${status}`;
        const response = await putData(url, { obj: null });
        return response;
    };
    static DelBranch = async (id) => {
        const url = `${baseurl}${DeleteBranchManager}?id=${id}`;
        const response = await putData(url, { obj: null });
        return response;
    };
    static updateBranch = async (obj) => {
        const url = `${baseurl}${UpdateBranchManager}`;
        const response = await putData(url, { obj: obj });
        return response;
    };
    static updateCodeBranch = async (obj) => {
        const url = `${baseurl}${UpdateCodeBranchManager}`;
        const response = await putData(url, { obj: obj });
        return response;
    };
    static updateNameBranch = async (obj) => {
        const url = `${baseurl}${UpdateBranchnameManager}`;
        const response = await putData(url, { obj: obj });
        return response;
    };
    static updatemanufactureBranch = async (obj) => {
        debugger;
        const url = `${baseurl}${UpdateManufactureBranchManager}`;
        const response = await putData(url, { obj: obj });
        return response;
    };
}