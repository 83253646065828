import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';

import './css/component-style.css';
import './css/responsive-col.css';
import './css/styles.css';
import './css/alertNoti.css';
import './components/select2/select2.css';
// import JlosLayout from './layout/JlosLayout';
import './css/style_project_root.css';
import Login from "./pages/Login/Login/Login";
import ChangePassword from "./pages/Login/ChangePassword/ChangePassword";
import ForgotPassword from "./pages/Login/ForgotPassword/ForgotPassword";

import JlosLayout from "./layout/W_JlosLayout";
// import { useEffect } from "react";
// import { onloadhtml } from "./core/handleData";

const checkToken = () => {
  const token = localStorage.getItem('token');
  return token !== null && token !== undefined;
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/admin/*" element={<JlosLayout />} />

        <Route path="/" element={<Navigate to={!checkToken() ? "/login" : "/admin/danh-sach-ho-so-vay"} replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
