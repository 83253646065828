import ReactDOM from 'react-dom';
import './Popup.css';

export class PopupType {
    static SUCCESS = 1;
    static WARNING = 2;
    static INFO = 3;
    static ERROR = 4;

    static getPopupIcon = (type) => {
        switch (type) {
            case PopupType.SUCCESS:
                return (
                    <svg width={72} height={72} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width={72} height={72} rx={36} fill="#E4F9EE" />
                        <path d="M36 17.25C32.2916 17.25 28.6665 18.3497 25.583 20.4099C22.4996 22.4702 20.0964 25.3986 18.6773 28.8247C17.2581 32.2508 16.8868 36.0208 17.6103 39.6579C18.3337 43.2951 20.1195 46.636 22.7417 49.2582C25.364 51.8805 28.7049 53.6662 32.342 54.3897C35.9792 55.1132 39.7492 54.7419 43.1753 53.3227C46.6014 51.9036 49.5298 49.5004 51.59 46.4169C53.6503 43.3335 54.75 39.7084 54.75 36C54.7356 31.0316 52.7555 26.2709 49.2423 22.7577C45.7291 19.2445 40.9684 17.2644 36 17.25V17.25ZM32.875 44.4594L24.4156 36L26.625 33.7906L32.875 40.0406L45.375 27.5406L47.5844 29.75L32.875 44.4594Z" fill="#009944" />
                    </svg>
                )
            case PopupType.WARNING:
                return (
                    <svg width={72} height={72} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width={72} height={72} rx={36} fill="#FFF3EB" />
                        <path d="M54.1945 46.1414L40.27 19.8178C39.8584 19.0424 39.2435 18.3938 38.4912 17.9414C37.739 17.489 36.8777 17.25 35.9999 17.25C35.1221 17.25 34.2609 17.489 33.5086 17.9414C32.7563 18.3938 32.1414 19.0424 31.7299 19.8178L17.8054 46.1414C17.4181 46.8758 17.2275 47.698 17.252 48.528C17.2766 49.358 17.5154 50.1675 17.9454 50.8778C18.3754 51.5882 18.9818 52.1752 19.7058 52.5817C20.4298 52.9883 21.2466 53.2007 22.077 53.1982H49.9229C50.7532 53.2007 51.5701 52.9883 52.294 52.5817C53.018 52.1752 53.6245 51.5882 54.0544 50.8778C54.4844 50.1675 54.7233 49.358 54.7478 48.528C54.7724 47.698 54.5817 46.8758 54.1945 46.1414ZM35.9999 48.5093C35.5362 48.5093 35.083 48.3717 34.6974 48.1141C34.3119 47.8565 34.0114 47.4904 33.8339 47.062C33.6565 46.6336 33.6101 46.1622 33.7005 45.7074C33.791 45.2526 34.0143 44.8349 34.3421 44.507C34.67 44.1791 35.0878 43.9558 35.5425 43.8654C35.9973 43.7749 36.4687 43.8214 36.8971 43.9988C37.3255 44.1762 37.6917 44.4767 37.9493 44.8623C38.2069 45.2478 38.3444 45.7011 38.3444 46.1648C38.3444 46.7866 38.0974 47.3829 37.6577 47.8226C37.218 48.2622 36.6217 48.5093 35.9999 48.5093ZM37.5629 40.6944H34.437L33.6555 28.1906H38.3444L37.5629 40.6944Z" fill="#FC6B03" />
                    </svg>
                )
            case PopupType.INFO:
                return (
                    <svg width={72} height={72} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width={72} height={72} rx={36} fill="#EEF4FD" />
                        <path
                            d="M36 17.25C32.2916 17.25 28.6665 18.3497 25.5831 20.4099C22.4996 22.4702 20.0964 25.3986 18.6773 28.8247C17.2581 32.2508 16.8868 36.0208 17.6103 39.6579C18.3338 43.2951 20.1195 46.636 22.7418 49.2582C25.364 51.8805 28.7049 53.6662 32.3421 54.3897C35.9792 55.1132 39.7492 54.7419 43.1753 53.3227C46.6014 51.9036 49.5298 49.5004 51.5901 46.4169C53.6503 43.3335 54.75 39.7084 54.75 36C54.7446 31.0288 52.7675 26.2628 49.2523 22.7477C45.7372 19.2325 40.9712 17.2554 36 17.25V17.25ZM37.6063 46.5062H34.3797V32.875H37.6063V46.5062ZM37.3391 28.5922C37.161 28.7553 36.952 28.8809 36.7243 28.9615C36.4966 29.042 36.2551 29.0758 36.0141 29.0609C35.7686 29.0773 35.5224 29.0442 35.29 28.9637C35.0576 28.8832 34.8437 28.7568 34.6609 28.5922C34.4995 28.4184 34.3748 28.2138 34.2943 27.9907C34.2137 27.7676 34.179 27.5306 34.1922 27.2937C34.1758 27.0514 34.209 26.8083 34.2896 26.5792C34.3701 26.35 34.4965 26.1397 34.6609 25.9609C34.8442 25.797 35.0581 25.6712 35.2904 25.5907C35.5227 25.5102 35.7687 25.4767 36.0141 25.4922C36.255 25.4782 36.4963 25.5124 36.7239 25.5929C36.9514 25.6734 37.1605 25.7985 37.3391 25.9609C37.5035 26.1397 37.6299 26.35 37.7105 26.5792C37.791 26.8083 37.8242 27.0514 37.8078 27.2937C37.821 27.5306 37.7863 27.7676 37.7057 27.9907C37.6252 28.2138 37.5005 28.4184 37.3391 28.5922V28.5922Z"
                            fill="#367BE2" />
                    </svg>
                )
            case PopupType.ERROR:
                return (
                    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="72" height="72" rx="36" fill="#FFF1F2"></rect>
                        <path d="M36 17.25C32.2916 17.25 28.6665 18.3497 25.5831 20.4099C22.4996 22.4702 20.0964 25.3986 18.6773 28.8247C17.2581 32.2508 16.8868 36.0208 17.6103 39.6579C18.3338 43.2951 20.1195 46.636 22.7418 49.2582C25.364 51.8805 28.7049 53.6662 32.3421 54.3897C35.9792 55.1132 39.7492 54.7419 43.1753 53.3227C46.6014 51.9036 49.5298 49.5004 51.5901 46.4169C53.6503 43.3335 54.75 39.7084 54.75 36C54.7356 31.0316 52.7555 26.2709 49.2423 22.7577C45.7291 19.2445 40.9684 17.2644 36 17.25V17.25ZM43.7344 41.525L41.525 43.7344L36 38.2094L30.475 43.7344L28.2656 41.525L33.7906 36L28.2656 30.475L30.475 28.2656L36 33.7906L41.525 28.2656L43.7344 30.475L38.2094 36L43.7344 41.525Z" fill="#E60012"></path>
                    </svg>
                )
            default:
                break;
        }
    }

    static addClassType(type) {
        switch (type) {
            case PopupType.SUCCESS:
                return 'success';
            case PopupType.WARNING:
                return 'warning';
            case PopupType.INFO:
                return 'info';
            case PopupType.ERROR:
                return 'error';
            default:
        }
    }
}

export const WPopup = ({ title, message, type = PopupType.WARNING, confirmTitle = 'xác nhận', cancelTitle = 'Hủy', confirmAction, cancelAction, cancelButton = false, confirmButton = true, horizontal = true, popupContent }) => {
    const closePopup = (ev) => {
        if (ev.target.classList.contains("popup-overlay") || ev.target.classList.contains("close-popup")) {
            if (cancelAction) cancelAction();
        }
    }

    return ReactDOM.createPortal(
        <div onClick={closePopup} className='popup-overlay'>
            {
                popupContent ??
                <div className="w-frame wbaseItem-value w-col min-brp 4d77a964-b6b7-4f9b-91f4-32a0a313cb8a" level={1} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col 4354215f-764e-4d7a-abcc-e43f93090f32" level={2} cateid={140}>
                        <div className="w-svg wbaseItem-value 95d830bc-35ca-4036-a270-27b3d1a7af7f" level={3} cateid={115}>
                            {PopupType.getPopupIcon(type)}
                        </div>
                        <div className="w-frame wbaseItem-value w-col fc984ec5-e481-4d9f-ad18-48002ed31856" level={3} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col ef3767c3-dbe3-4c85-91e0-e7c3d66f962c" level={4} cateid={140}>
                                <div className="w-text wbaseItem-value c1bb1099-dbd7-406b-93ba-20212d65f903" level={5} cateid={139}>{title}<br />
                                </div>
                            </div>
                            {message && message
                                // <div div className="w-frame wbaseItem-value w-row f16c43bd-c8d9-49e6-a8ca-89395e760fc2" level={4} cateid={140}>
                                //     <div className="w-text wbaseItem-value 645254bb-1c8c-4033-8dbf-dc84ab2a9944" level={5} cateid={139}>Những thông tin bạn vừa nhập sẽ không được lưu lại.</div>
                                // </div>
                            }
                        </div>
                    </div>
                    <div className={`w-frame wbaseItem-value ddfa721b-a3c1-4c06-95c4-dceadc57acb0 ${horizontal ? " w-row" : "w-col"}`} level={2} cateid={140}>
                        {cancelButton &&
                            <button style={{ order: horizontal !== false ? 1 : 3 }} onClick={cancelAction} type="button" className="w-button wbaseItem-value w-row a584ca2b-cc8d-4d96-9bd5-dcbefffe73c4" level={3} cateid={29}>
                                <div className="w-text wbaseItem-value 8769b1da-5fcc-4959-b482-4aed339785a2" level={4} cateid={139}>{cancelTitle}<br />
                                </div>
                            </button>}
                        {confirmButton === true &&
                            <button onClick={confirmAction} type="button" className={`w-button wbaseItem-value w-row ee63d6cc-2cc8-4ae8-9de8-704f1fb807f4 ${PopupType.addClassType(type)}`} level={3} cateid={29}>
                                <div className="w-text wbaseItem-value 912e836a-73d3-45be-80bf-d8b71dffb2d2" level={4} cateid={139}>{confirmTitle}</div>
                            </button>
                        }
                    </div>
                </div>
            }
        </div >,
        document.body
    )
}