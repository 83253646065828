import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect, useState, useRef } from 'react';
import { getCreditHistories, getDTI, getTaskDetail, getScoring, getCheckS37, getCheckR11, getCheckPCB, hoSo_doneAction, getSubProcessTask } from '../../../redux/middleware/api/congvieccuatoi/CongViecDA';
import inputMoneyPattern, { Ultis } from '../../../core/utils';
import './XuLyThamDinh.css';
import { WPopup } from '../../../components/Popup/Popup';
import PopupXemQuyTrinh from './PopupXemQuyTrinh';
import PopupHoSoBanDau from './PopupHoSoBanDau';
import PopupDanhSachCauHoi from './PopupDanhSachCauHoi';
import { ERROR, notify } from '../../../core/AlertNoti';
import NotificationAlert from "react-notification-alert";
import PopupChonThaoTac from './PopupChonThaoTac';
import FileDA from '../../../redux/middleware/api/FileUpload/FileDA';

export default function ModuleThamDinh({ initScoring = {} }) {
    const navigate = useNavigate();
    const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm();
    const location = useLocation();

    const notificationAlert = useRef();
    const [data, setData] = useState();
    const [settingData, setSettingData] = useState({});
    const [dtiData, setDtiData] = useState({});
    const [jivfData, setJivfData] = useState([]);
    const [scoringData, setScoringData] = useState(initScoring);
    const [scrollIndex, setScroll] = useState(0);
    const [noteList, setNoteList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [showProcess, onShowProcess] = useState(false);
    const [showChonThaoTac, onShowChonThaoTac] = useState(false);
    const [showInitProfile, onShowInitProfile] = useState(false);
    const [showQuestionList, onShowQuestionList] = useState(false);
    const [creditResult, setCreditResult] = useState();
    const [checkCCCD9So, setCheckCCCD9So] = useState({});

    const xulyDetails = ["Thông tin khách hàng", "Thông tin khoản vay", "Tài chính khách hàng", "Ghi chú của SC", "Hồ sơ đính kèm", "Lịch sử tín dụng tại các TCTD", "Lịch sử tín dụng tại JIVF", "DTI", "Xếp hạng tín dụng", "Ý kiến thẩm định"];
    const bosungDetails = ["Thông tin khách hàng", "Thông tin khoản vay", "Tài chính khách hàng", "Thông tin người liên quan", "Ghi chú của SC", "Hồ sơ đính kèm", "Lịch sử tín dụng tại các TCTD", "Lịch sử tín dụng tại JIVF", "DTI", "Xếp hạng tín dụng", "Thẩm định qua điện thoại", "Ý kiến thẩm định"];
    const pheduyetDetails = ["Xếp hạng tín dụng","Thẩm định qua điện thoại","Ý kiến thẩm định","Ý kiến phê duyệt", "Thông tin khách hàng", "Thông tin khoản vay", "Tài chính khách hàng", "Thông tin người liên quan", "Ghi chú của SC", "Hồ sơ đính kèm", "Lịch sử tín dụng tại các TCTD", "Lịch sử tín dụng tại JIVF", "DTI"];

    const subId = location.search.replace("?", "");
    const getContractInfo = async () => {
        let res = await getTaskDetail({ id: subId });
        if (!res["active-user-tasks"] && res["process-instance-state"] === 1) {
            let subProcess = await getSubProcessTask({ id: subId });
            if (subProcess != null) {
                res = await getTaskDetail({ id: subProcess["process-instance-id"] });
            }
        } else if (res['process-instance-state'] === 2 && res['parent-instance-id'] !== -1) {
            res = await getTaskDetail({ id: subId });
        }
        if (res) {
            console.log(res)
            setData(res);
            if (!res['process-instance-variables'].khachHang.cccdCu && res['process-instance-variables'].khachHang.ngaySinh) {
                if (new Date(res['process-instance-variables'].khachHang.ngaySinh).getFullYear() <= 1999) {
                    res.CmndCuInput = true;
                }
            }
            setSettingData(res['process-instance-variables']);
            setNoteList(res['process-instance-variables'].loanApplication?.noteLoanApplication ?? []);
            setFileList(res['process-instance-variables'].loanApplication?.fileLoanApplication ?? []);
            setJivfData(res['process-instance-variables'].cusCreditHistory ?? []);
            let dtiBody = {
                "idCard": res['process-instance-variables'].loanApplication.idKhach,
                "loanApplicationId": res['process-instance-variables'].loanApplication.id,
                "s37ResponseDto": res['process-instance-variables'].s37ResponseDto,
                "r11AResponseDto": res['process-instance-variables'].r11AResponseDto,
                "pcbResponse": res['process-instance-variables'].pcbResponse,
            }
            let resDTI = await getDTI({ obj: dtiBody });
            if (resDTI.data)
                setDtiData(resDTI.data);
        }
    }

    const searchScoringData = () => {
        let body = {
            "idCard": settingData.loanApplication.idKhach,
            "profileId": settingData.loanApplication.id,
            "avalidLifeAddress": settingData.isAppraisal?.checkDcThuongTruCICPCB === 1, //  check địa chỉ CIC/PCB -- EKYC
            "matchCustNameEkycAndCICPCB": settingData.isAppraisal?.checkHoTenCICPCB === 1, //check tên CIC/PCB -- EKYC
            "numberRelatedCreditInstitutions": jivfData.length, // Số tì chính tín dụng quan hệ
            "totalLatePaymentDayJlos": jivfData.length > 0 ? jivfData.map(e => e.totalLatePaymentsDays).reduce((a, b) => a + b) : 0,
            "creditHistoryStatus": !jivfData.length,
            "r11AResponseDto": settingData.r11AResponseDto,
            "s37ResponseDto": settingData.s37ResponseDto,
            "pcbResponse": settingData.pcbResponse
        }
        getScoring({ obj: body }).then(res => {
            if (res.data) {
                if (res.data.scoringValue && res.data.scoringValue.toLowerCase().includes("không đủ điều kiện"))
                    setCreditResult(false);
                setScoringData(res.data);
            } else if (res.code === "API-001") {
                notify(notificationAlert, ERROR, 'Chưa có dữ liệu xếp hạng tín dụng');
            } else if (res.code === "403") {
                notify(notificationAlert, ERROR, res.message);
            }
        });
    }

    const selectCheckCCCD9So = async (type) => {
        switch (type) {
            case 0: // CIC S37
                const s37Data = await getCheckS37({ cccdCu: settingData.khachHang.cccdCu ?? getValues("cccdCu") });
                if(s37Data) {
                    setCheckCCCD9So({
                        keyCheck: type,
                        data: s37Data
                    });
                } else {
                    setCheckCCCD9So({})
                }
                break;
                case 1: // CIC R11
                const r11Data = await getCheckR11({ cccd: settingData.khachHang.cmndHoChieu, diachi: settingData.khachHang.dcThuongTru, tenKH: settingData.khachHang.hoTen });
                if(r11Data) {
                    setCheckCCCD9So({
                        keyCheck: type,
                        data: r11Data
                    });
                } else {
                    setCheckCCCD9So({})
                }
                break;
            case 2: // PCB
                const pcbData = await getCheckPCB({
                    obj: {
                        "contract": {
                            "dateRequestContract": settingData.loanApplication.ngayTaoHopDong.replaceAll("/", ""),
                            "operationType": "11",  // Mặc định
                            "codCurrency": "VND",
                            "instalment": {
                                "amountFinancedCapital": `${parseFloat(settingData.loanApplication.soTienBaoHiem) + parseFloat(settingData.loanApplication.soTienVayGoc)}`,
                                "numTotalInstalment": `${settingData.loanApplication.kyHanVay}`,
                                "codPaymentPeriodicity": "M",  //Mặc định
                                "amountMonthlyInstalment": ""
                            }
                        },
                        "subject": {
                            "fiSubjectCode": settingData.loanApplication.idKhach, //Mã Khách hàng vay tại Bên A
                            "person": {
                                "name": settingData.khachHang.hoTen,
                                "gender": settingData.khachHang.gioiTinh,
                                "dateOfBirth": settingData.khachHang.ngaySinh.replaceAll("/", ""),
                                "placeOfBirth": "",
                                "countryOfBirth": "VN",
                                "idCard": settingData.khachHang.cmndHoChieu,
                                "address": {
                                    "main": {
                                        "fullAddress": settingData.khachHang.dcThuongTru
                                    },
                                    "additional": {
                                        "fullAddress": settingData.loanApplication.dcTamTru
                                    }
                                }
                            }
                        },
                        "role": "A"
                    }
                });
                if(pcbData){
                    setCheckCCCD9So({
                        keyCheck: type,
                        data: pcbData
                    });
                } else {
                    setCheckCCCD9So({})
                }
                break;
            default:
                break;
        }
    }

    const type = location.pathname.split("/").pop().replace("-tham-dinh", "");

    useEffect(() => {
        getContractInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePutData = async (event) => { // Send | Save hồ sơ thẩm định
        let formData = event ?? getValues();
        let dataPut = {}
        switch (type) {
            case "xu-ly":
                dataPut.action = event ? (formData.ykienthamdinh === "Yêu cầu bổ sung" ? "sendSale" : "sendApprover") : "save";
                dataPut.isAppraisal = {
                    "fis.onboarding.process.model.jlos.dto.IsRequestAppraisal": {
                        "dtiInitial": dtiData.dtiInitial,
                        "dtiReality": dtiData.dtiReality,
                        "dtiAdjust": dtiData.dtiAdjust,
                        "scoringPoint": scoringData.scoringPoint,
                        "scoringRank": scoringData.scoringRank,
                        "isLuuNhap": dataPut.action === "save" ? true : false,
                        "checkHoTenCICPCB": parseInt(formData.checkHoTenCICPCB),
                        "checkDcThuongTruCICPCB": parseInt(formData.checkDcThuongTruCICPCB),
                        "checkMucDichVay": settingData.loanApplication.checkMucDichVay,
                        "checkNgheNghiep": settingData.loanApplication.checkNgheNghiep,
                        "checkThuNhapThang": settingData.loanApplication.checkThuNhapThang,
                        "checkSoNguoiPhuThuoc": formData.checkSoNguoiPhuThuoc ? 1 : 0,
                        "tongThanhToanNoVayHangThang": settingData.loanApplication.tongThanhToanNoVayHangThang,
                        "ghiChuThamDinhQuaDt": settingData.loanApplication.ghiChuThamDinhQuaDt,
                        "yKienThamDinh": formData.ykienthamdinh,
                        "ghiChuYKienThamDinh": formData.ghiChuYKienThamDinh,
                        "fileLoanApplication": (settingData.fileLoanApplication ?? []).map(e => {
                            return {
                                ...e,
                                "modifiedBy": formData[`modifiedBy${e.id}`],
                                "danhGiaThamDinh": parseInt(formData[`danhGiaThamDinh${e.id}`])
                            }
                        }),
                        "fileListId": settingData.fileListId,
                        "cusCreditHistory": jivfData
                    }
                }
                break;
            case "bo-sung":
                dataPut.action = event ? (formData.ykienthamdinh === "Yêu cầu bổ sung" ? "sendSale" : "sendApprover") : "save";
                dataPut.isAppraisal = {
                    "fis.onboarding.process.model.jlos.dto.IsRequestAppraisal": {
                        "checkMucDichVay": formData.checkMucDichVay ? 1 : 0,
                        "checkNgheNghiep": formData.checkNgheNghiep ? 1 : 0,
                        "checkThuNhapThang": formData.checkThuNhapThang ? 1 : 0,
                        "checkSoNguoiPhuThuoc": formData.checkSoNguoiPhuThuoc ? 1 : 0,
                        "tongThanhToanNoVayHangThang": parseFloat(formData.tongThanhToanNoVayHangThang.replaceAll(",", "")),
                        "ghiChuThamDinhQuaDt": settingData.loanApplication.ghiChuThamDinhQuaDt ?? formData.ghiChuThamDinhQuaDt,
                        "yKienThamDinh": formData.ykienthamdinh,
                        "ghiChuYKienThamDinh": formData.ghiChuYKienThamDinh,
                        "fileLoanApplication": (settingData.fileLoanApplication ?? []).map(e => {
                            return {
                                ...e,
                                "modifiedBy": formData[`modifiedBy${e.id}`],
                                "danhGiaThamDinh": parseInt(formData[`danhGiaThamDinh${e.id}`])
                            }
                        }),
                        "fileListId": settingData.fileListId,
                        "cusCreditHistory": jivfData
                    }
                }
                break;
            case "cho-phe-duyet":
                dataPut.action = event ? (formData.yKienPheDuyet === "Yêu cầu bổ sung" ? "sendIs" : formData.yKienPheDuyet === "Từ chối" ? "reject" : "approve") : "save";
                dataPut.approverAppraisal = {
                    "fis.onboarding.process.model.jlos.dto.ApproverRequestAppraisal": {
                        "yKienPheDuyet": formData.yKienPheDuyet,
                        "ghiChuYKienPheDuyet": formData.ghiChuYKienPheDuyet,
                        "sanPhamBanKem": "",
                        "maTuChoi": "R4",
                        "lyDoTuChoi": formData.ghiChuYKienPheDuyet,
                        "actionCallFlexcube": formData.actionCallFlexcube,   // 1: Tạo khoản vay, 2: Cập nhật CIF và tạo khoản vay, 3: Tạo CIF và tạo khoản vay. (Xử dụng khi call flexcube)
                        "cifUpdate": formData.cifUpdate,
                    }
                }
                break;
            default:
                break;
        }
        try {
            let res = await hoSo_doneAction(data["active-user-tasks"]["task-summary"][0]["task-id"], dataPut.action, dataPut);
            navigate('/admin/ho-so-cho-xu-ly')
            return res;
        } catch (error) {
            // notify(notificationAlert, ERROR, error)
        }
    }

    const onSend = (event) => {
        if (type === "cho-phe-duyet") {
            onShowChonThaoTac(true);
        } else {
            handlePutData(event);
        }
    }

    const handleScroll = (event) => {
        let children = [...event.target.firstChild.children];
        if(type === "cho-phe-duyet") children = children.sort((a,b) => parseInt(a.style.order) - parseInt(b.style.order));
        let scrollFrom = event.target.getBoundingClientRect().y;
        let scrollTo = children.filter(e => {
            let eRect = e.getBoundingClientRect();
            return (eRect.y + eRect.height / 2) > scrollFrom;
        }).sort((a, b) => {
            let aRect = a.getBoundingClientRect();
            let bRect = b.getBoundingClientRect();
            return (aRect.y + aRect.height / 2) - (bRect.y + bRect.height / 2);
        });
        let index = children.indexOf(scrollTo[0]);
        if (index !== scrollIndex) setScroll(index);
    }

    const clickScrollTo = (event) => {
        let index = [...event.target.parentElement.children].indexOf(event.target);
        let scrollElement = document.querySelector(`.wbaseItem-value[class*="e886086e-80b8-48db-80dc-0aae5d3651d3"]`);
        let children = [...scrollElement.firstChild.children];
        if(type === "cho-phe-duyet") children = children.sort((a,b) => parseInt(a.style.order) - parseInt(b.style.order));
        scrollElement.scrollTo({
            top: children[index].offsetTop,
            behavior: "smooth",
        });
    }

    const renderNoteList = () => {
        let noteCards = [];
        for (let noteItem of noteList) {
            noteCards.push(<div key={noteItem.id} className="w-frame wbaseItem-value w-col ffed7a4e-f730-4048-9ff1-382d089c3be5" level={10} cateid={140}>
                <div className="w-frame wbaseItem-value w-row 7eafe740-57bd-413e-8055-925e596a3f9e" level={11} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row df921824-0c6c-430b-8491-87bb5f8662b1" level={12} cateid={140}>
                        <div className="w-svg wbaseItem-value 647d318a-ed7f-461d-bda1-dda057d9db18" level={13} cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.9997 7C16.9997 9.76142 14.7611 12 11.9997 12C9.23826 12 6.99968 9.76142 6.99968 7C6.99968 4.23858 9.23826 2 11.9997 2C14.7611 2 16.9997 4.23858 16.9997 7Z" fill="#28282999" />
                                <path d="M3.66667 19.222C3.66667 17.177 6.375 13.667 12 13.667C17.625 13.667 20.3333 17.177 20.3333 19.222V20.3337C20.3333 20.7757 20.1577 21.1996 19.8452 21.5122C19.5326 21.8247 19.1087 22.0003 18.6667 22.0003H5.33334C4.89131 22.0003 4.46739 21.8247 4.15483 21.5122C3.84227 21.1996 3.66667 20.7757 3.66667 20.3337V19.222Z" fill="#28282999" />
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value 04e5c11c-0398-48ce-84ce-bdaa22db55b7" level={13} cateid={139}>{noteItem.tenNguoiTao ?? noteItem.maNguoiTao ?? "_"}</div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row fdcad8fb-88d5-4cc0-9433-7563b5d002f2" level={12} cateid={140}>
                        <div className="w-svg wbaseItem-value 96785fe5-de4f-4bdd-afa2-657299308354" level={13} cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.16667 11.1667C5.70643 11.1667 5.33333 11.5398 5.33333 12V12.8333C5.33333 13.2936 5.70643 13.6667 6.16667 13.6667H7.83333C8.29357 13.6667 8.66667 13.2936 8.66667 12.8333V12C8.66667 11.5398 8.29357 11.1667 7.83333 11.1667H6.16667Z" fill="#28282999" />
                                <path d="M11.1667 11.1667C10.7064 11.1667 10.3333 11.5398 10.3333 12V12.8333C10.3333 13.2936 10.7064 13.6667 11.1667 13.6667H12.8333C13.2936 13.6667 13.6667 13.2936 13.6667 12.8333V12C13.6667 11.5398 13.2936 11.1667 12.8333 11.1667H11.1667Z" fill="#28282999" />
                                <path d="M5.33333 16.1667C5.33333 15.7064 5.70643 15.3333 6.16667 15.3333H7.83333C8.29357 15.3333 8.66667 15.7064 8.66667 16.1667V17C8.66667 17.4602 8.29357 17.8333 7.83333 17.8333H6.16667C5.70643 17.8333 5.33333 17.4602 5.33333 17V16.1667Z" fill="#28282999" />
                                <path d="M11.1667 15.3333C10.7064 15.3333 10.3333 15.7064 10.3333 16.1667V17C10.3333 17.4602 10.7064 17.8333 11.1667 17.8333H12.8333C13.2936 17.8333 13.6667 17.4602 13.6667 17V16.1667C13.6667 15.7064 13.2936 15.3333 12.8333 15.3333H11.1667Z" fill="#28282999" />
                                <path d="M15.3333 12C15.3333 11.5398 15.7064 11.1667 16.1667 11.1667H17.8333C18.2936 11.1667 18.6667 11.5398 18.6667 12V12.8333C18.6667 13.2936 18.2936 13.6667 17.8333 13.6667H16.1667C15.7064 13.6667 15.3333 13.2936 15.3333 12.8333V12Z" fill="#28282999" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.83333 2.83333C7.83333 2.3731 7.46024 2 7 2C6.53976 2 6.16667 2.3731 6.16667 2.83333V3.66667H4.5C3.11929 3.66667 2 4.78595 2 6.16667V18.6667C2 20.0474 3.11929 21.1667 4.5 21.1667H19.5C20.8807 21.1667 22 20.0474 22 18.6667V6.16667C22 4.78595 20.8807 3.66667 19.5 3.66667H17.8333V2.83333C17.8333 2.3731 17.4602 2 17 2C16.5398 2 16.1667 2.3731 16.1667 2.83333V3.66667H7.83333V2.83333ZM4.5 5.33333C4.03976 5.33333 3.66667 5.70643 3.66667 6.16667V7.83333H20.3333V6.16667C20.3333 5.70643 19.9602 5.33333 19.5 5.33333H17.8333C17.8333 5.79357 17.4602 6.16667 17 6.16667C16.5398 6.16667 16.1667 5.79357 16.1667 5.33333H7.83333C7.83333 5.79357 7.46024 6.16667 7 6.16667C6.53976 6.16667 6.16667 5.79357 6.16667 5.33333H4.5ZM4.5 19.5C4.03976 19.5 3.66667 19.1269 3.66667 18.6667V9.5H20.3333V18.6667C20.3333 19.1269 19.9602 19.5 19.5 19.5H4.5Z" fill="#28282999" />
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value 4be92051-49e9-47f1-8ab9-524f165cfb72" level={13} cateid={139}>{noteItem.createdDate ?? "_"}</div>
                    </div>
                </div>
                <div className="w-text wbaseItem-value e88f9b26-21ec-47f6-a289-3c4844a2e76f" level={11} cateid={139}>{noteItem.noiDung ?? "_"}</div>
            </div>);
        }
        return noteCards;
    }

    const renderFileList = () => {
        let fileTbRows = [];
        for (let fileItem of fileList) {
            fileTbRows.push(<tr key={fileItem.id} className="table-row">
                <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                    <div className="w-frame wbaseItem-value w-row 5195e955-4ae2-460d-a1ff-b14bfa201fed" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 2958f573-aad0-4164-9c3a-689f602e659c" level={11} cateid={139}>{fileItem.tenFileCtv ?? "_"}</div>
                    </div>
                </td>
                <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                    <div className="w-frame wbaseItem-value w-row cbc2814a-c926-45ef-8915-7b09ca6f3ca0" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 154b83b8-f89a-408b-a4cf-4469aa817073" level={11} cateid={139}>{fileItem.ten ?? "_"}</div>
                    </div>
                </td>
                <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                    <div className="w-frame wbaseItem-value w-row 141e7645-2683-4ad2-beb8-ed125778516e" level={10} cateid={140}>
                        <button type='button' onClick={() => {FileDA.getFile(fileItem.id)}} className="w-button w-svg wbaseItem-value 573f8351-2533-4cac-9a08-34c29a89f84b" level={11} cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10.5L12 16.3333L17 10.5H12.8333V3H11.1667V10.5H7Z" fill="#009944FF" fillOpacity="0.6" />
                                <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#009944FF" fillOpacity="0.6" />
                            </svg>
                        </button>
                    </div>
                </td>
                <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                    <div className="w-frame wbaseItem-value w-row 01cd7052-4f9e-4d2f-b733-a6c8dc17b7ab" level={10} cateid={140} style={{ justifyContent: "center" }}>
                        <label className="w-check-box wbaseItem-value 648b2d5f-dbdf-4f08-bb6d-85d0324b3bb2" level={11} cateid={79}>
                            <input type="checkbox" name={`modifiedBy${fileItem.id}`} {...register(`modifiedBy${fileItem.id}`)} defaultChecked={fileItem.modifiedBy === 1} />
                            <svg width="20" height="20">
                                <path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF">
                                </path>
                            </svg>
                        </label>
                    </div>
                </td>
                <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                    <div className="w-frame wbaseItem-value w-row 82db2a61-63b9-4abe-a0fe-8c311ad8825f" level={10} cateid={140}>
                        <div className={"w-frame wbaseItem-value w-row 301f9c0c-8cfa-4962-be3c-43b7eb1ec9ac " + (errors[`danhGiaThamDinh${fileItem.id}`] && " helper-text")} helper-text={errors[`danhGiaThamDinh${fileItem.id}`] && "Vui lòng chọn hợp lệ/không hợp lệ"} level={11} cateid={128}>
                            <div className="w-frame wbaseItem-value w-row e2e7d2fc-8e34-43e6-8b4b-6d0b2cf660b2" level={12} cateid={140}>
                                <label className="w-radio-btn wbaseItem-value b1f38af6-6295-4a0f-9219-c19589e9ddbe" level={13} cateid={90}>
                                    <input type="radio" name={`danhGiaThamDinh${fileItem.id}`} {...register(`danhGiaThamDinh${fileItem.id}`, {required: true})} value={1} defaultChecked={fileItem.danhGiaThamDinh === 1} />
                                    <span className="checkmark">
                                    </span>
                                </label>
                                <div className="w-text wbaseItem-value ceace47f-a1d9-4542-bb37-f36998c35298" level={13} cateid={139}>Hợp lệ</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row 5be4f45a-e89e-4d58-80d2-8754233f0385" level={12} cateid={140}>
                                <label className="w-radio-btn wbaseItem-value e28a0766-e2ac-4949-9e22-d7bd0991490f" level={13} cateid={90}>
                                    <input type="radio" name={`danhGiaThamDinh${fileItem.id}`} {...register(`danhGiaThamDinh${fileItem.id}`, {required: true})} value={0} defaultChecked={fileItem.danhGiaThamDinh === 0} />
                                    <span className="checkmark">
                                    </span>
                                </label>
                                <div className="w-text wbaseItem-value 44ea9e0b-00ad-4e39-a220-9837b437fddd" level={13} cateid={139}>Không hợp lệ</div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>)
        }
        return fileTbRows;
    }

    const renderRelativeInfor = () => {
        let cardInfor = [];
        for (let i = 1; i <= 2; i++) {
            cardInfor.push(<div key={i} className="w-frame wbaseItem-value w-col c1beaea1-1e1b-47c8-aa46-c6dfd6ddbcb0" level={9} cateid={140}>
                <div className="w-text wbaseItem-value 45519e90-783c-4377-af55-b0aac3bb1ff0" level={10} cateid={139}>Người liên hệ trong trường hợp {i === 1 ? "cần thực hiện nghĩa vụ theo hợp đồng" : "khẩn cấp"}</div>
                <div className="w-frame wbaseItem-value w-row e87c7468-4327-4c6e-b443-9bc13848e875" wrap="wrap" level={10} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 89129c3b-dce1-4bfd-b2fd-22aa12455a4c" level={11} cateid={140}>
                        <div className="w-text wbaseItem-value 1cfa6029-c76a-4251-989e-bcf319294366" level={12} cateid={139}>Họ tên:</div>
                        <div className="w-text wbaseItem-value 15698e14-08cb-4b0f-99d2-710d3bea819a" level={12} cateid={139}>{settingData.loanApplication[`hoTenNguoiLienHe${i}`] ?? "_"}</div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 341a6ceb-8755-4282-bcae-a61d823aea1f" level={11} cateid={140}>
                        <div className="w-text wbaseItem-value f8038c1b-3fcc-47e1-8206-8d5138cf8cf2" level={12} cateid={139}>Mối quan hệ:</div>
                        <div className="w-text wbaseItem-value 233623c5-517b-43f4-9630-608200e2b3f4" level={12} cateid={139}>{settingData.loanApplication[`qhNguoiLienHe${i}`] ?? "_"}</div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 c0b73129-82a3-4868-b2af-8f1ebe990e90" level={11} cateid={140}>
                        <div className="w-text wbaseItem-value 68c8d1fe-93ce-4189-b8b7-0d1ac29902dc" level={12} cateid={139}>Số điện thoại:</div>
                        <div className="w-text wbaseItem-value 1de46877-c917-4aa0-b8c0-f8971baf3c80" level={12} cateid={139}>{settingData.loanApplication[`dtNguoiLienHe${i}`] ?? "_"}</div>
                    </div>
                </div>
            </div>)
        }
        return cardInfor;
    }


    return (
        data != null ? <form onSubmit={handleSubmit(onSend)} className="w-frame w-form wbaseItem-value w-col a6972beb-dc92-42e3-9bba-35ff6f54809d" autoComplete='off' level={2} cateid={128}>
            <NotificationAlert ref={notificationAlert} />
            {showProcess && <WPopup popupContent={<PopupXemQuyTrinh code={subId} loanApplicationId={settingData.loanApplication.id} closePopup={() => onShowProcess(false)} />} />}
            {showInitProfile && <WPopup popupContent={<PopupHoSoBanDau data={settingData} closePopup={() => onShowInitProfile(false)} />} />}
            {showChonThaoTac && <WPopup popupContent={<PopupChonThaoTac
                khachangCif={settingData.khachHang.cif}
                confirm={(actionFlexcube, cifUpdate) => {
                    onShowChonThaoTac(false);
                    setValue("actionCallFlexcube", actionFlexcube);
                    if (cifUpdate) setValue("cifUpdate", cifUpdate);
                handlePutData(getValues());
                }} gifList={jivfData.map(e => e.gif)}
                closePopup={() => onShowChonThaoTac(false)} />} />}
            {showQuestionList && <WPopup popupContent={<PopupDanhSachCauHoi closePopup={() => onShowQuestionList(false)} />} cancelAction={() => { }} />}
            <div className="w-frame wbaseItem-value w-col 50a3f367-cf99-4133-807a-fec455231b36" level={3} cateid={140}>
                <div className="w-frame wbaseItem-value w-row 9d06dc5c-ae5d-48af-acf4-7c7d2c9d900c" level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col b50f9564-ab79-4597-a063-9e4c32970ca1" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row ba604373-2e4a-4b72-b2b6-0e655cee801c" level={6} cateid={140}>
                            <div className="w-text wbaseItem-value 75dd1294-d741-4e66-98cd-5c6da32230fd" level={7} cateid={139}>{type === "xu-ly" ? "Xử lý" : type === "bo-sung" ? "Bổ sung" : "Phê duyệt"} thẩm định<br />
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row breadcrumb 9b10994a-5492-4988-b494-86fbecfebf9a" level={6} cateid={140}>
                            <button type="button" className="w-button wbaseItem-value w-row 995e4b5c-1089-4222-90c0-425bd2c307c7" level={7} cateid={29}>
                                <div className="w-text wbaseItem-value 92bf528a-b1cd-4b84-9374-47364114e669" level={8} cateid={139}>Hồ sơ chờ xử lý</div>
                                <div className="w-svg wbaseItem-value 5772073f-3b62-4ee7-9f11-d29806a6587b" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6" />
                                    </svg>
                                </div>
                            </button>
                            <div className="w-text wbaseItem-value 42276561-5b1c-47b4-9265-140485a0bc5f" level={7} cateid={139}>{settingData.idHoSo}</div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row c2299ccc-ed6a-4397-9917-3b0b80edec22" level={5} cateid={140}>
                        <button type="button" onClick={() => { onShowInitProfile(true) }} className="w-button wbaseItem-value w-row 183e6ff6-6046-465b-9be9-be33631a9316" level={6} cateid={29}>
                            <div className="w-svg wbaseItem-value 5624fa72-1316-47a5-b44c-21db7b95226a" level={7} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.4167 6.33333L9.91667 3H2.83333C2.61232 3 2.40036 3.0878 2.24408 3.24408C2.0878 3.40036 2 3.61232 2 3.83333V18.8333C2 19.4964 2.26339 20.1323 2.73223 20.6011C3.20107 21.0699 3.83696 21.3333 4.5 21.3333H19.5C20.163 21.3333 20.7989 21.0699 21.2678 20.6011C21.7366 20.1323 22 19.4964 22 18.8333V7.16667C22 6.94565 21.9122 6.73369 21.7559 6.57741C21.5996 6.42113 21.3877 6.33333 21.1667 6.33333H12.4167Z" fill="#28282999" />
                                </svg>
                            </div>
                            <div className="w-text wbaseItem-value dfb03958-9de2-42a2-b260-c720e54fcdad" level={7} cateid={139}>Xem hồ sơ ban đầu</div>
                        </button>
                        <button type="button" onClick={() => { onShowProcess(true) }} className="w-button wbaseItem-value w-row 9230e94d-c572-487a-a7ab-9652dfc48e8d" level={6} cateid={29}>
                            <div className="w-svg wbaseItem-value 0089431d-603a-4e2d-a425-e762fc3c3a43" level={7} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 5.33333V7H5.6V12H8V13.6667H5.6V18.6667H8V20.3333H4.8C4.3584 20.3333 4 19.96 4 19.5V2.83333C4 2.37333 4.3584 2 4.8 2C5.2416 2 5.6 2.37333 5.6 2.83333V5.33333H8Z" fill="#009944FF" />
                                    <path d="M10.3996 8.66699H19.1996C19.6412 8.66699 19.9996 8.29366 19.9996 7.83366V4.50033C19.9996 4.04033 19.6412 3.66699 19.1996 3.66699H10.3996C9.95801 3.66699 9.59961 4.04033 9.59961 4.50033V7.83366C9.59961 8.29366 9.95801 8.66699 10.3996 8.66699Z" fill="#009944FF" />
                                    <path d="M19.1996 22H10.3996C9.95801 22 9.59961 21.6275 9.59961 21.1667V17.8333C9.59961 17.3725 9.95801 17 10.3996 17H19.1996C19.6412 17 19.9996 17.3725 19.9996 17.8333V21.1667C19.9996 21.6275 19.6412 22 19.1996 22Z" fill="#009944FF" />
                                    <path d="M10.3996 15.333H19.1996C19.6412 15.333 19.9996 14.9605 19.9996 14.4997V11.1663C19.9996 10.7063 19.6412 10.333 19.1996 10.333H10.3996C9.95801 10.333 9.59961 10.7063 9.59961 11.1663V14.4997C9.59961 14.9605 9.95801 15.333 10.3996 15.333Z" fill="#009944FF" />
                                </svg>
                            </div>
                            <div className="w-text wbaseItem-value ae8a91b6-14d3-4ee5-890e-e2e4e2220e0f" level={7} cateid={139}>Xem quy trình</div>
                        </button>
                    </div>
                </div>
                <div className="w-frame wbaseItem-value w-col 36d3a878-2420-48bd-8250-4f225745ffb5" level={4} cateid={140}>
                    <div className="w-frame wbaseItem-value w-col ed28939a-23e4-495f-b101-a385b79d4606" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row c6cd5857-1cd7-4616-a3c4-f0797018551d" wrap="wrap" level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md 270fcbaa-9a25-4ff5-93e4-680fe1bed4e7" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value ae4c561d-75c7-4168-93bb-11e83fbd8924" level={8} cateid={139}>Trạng thái:</div>
                                <div className="w-text wbaseItem-value 209c07ee-5120-4f90-a96b-b68b7cde4f33" level={8} cateid={139}>{type === "xu-ly" ? "Xử lý" : type === "bo-sung" ? "Bổ sung" : "Phê duyệt"} thẩm định</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md 4e976371-bade-440b-b14c-4b7632c24d9f" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value 80dc68e3-9095-45f8-b016-0a093fbd25e7" level={8} cateid={139}>Người yêu cầu:</div>
                                <div className="w-text wbaseItem-value 098ebe6f-8190-4f7b-a351-a92611d616aa" level={8} cateid={139}>{settingData.loanApplication?.nguoiYeuCau ?? "_"}</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md f1265339-4516-472a-a5ad-06076183e37e" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value 8bad664c-c639-40d8-8d82-ec83e8ca4086" level={8} cateid={139}>Người xử lý:</div>
                                <div className="w-text wbaseItem-value ae81f3e3-0c5c-4372-9e87-50ffb004ee6f" level={8} cateid={139}>{settingData.loanApplication?.nguoiXuLy ?? "_"}</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md 8e8c5829-8ffd-4c86-bda6-c293b992f7c5" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value dc17cd5e-5266-46fc-ada9-4d215dfba20e" level={8} cateid={139}>Ngày khởi tạo:</div>
                                <div className="w-text wbaseItem-value 11d094ec-6910-4d1a-b696-27dfc93a03ad" level={8} cateid={139}>{settingData.loanApplication?.ngayTaoYeuCau ? Ultis.datetoString(new Date(settingData.loanApplication.ngayTaoYeuCau), "dd/mm/yyyy hh:mm") : "_"}</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row 37b1b527-0db7-4339-9107-63673bcbd828" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col 4628504d-0a66-4808-81de-0a7011c1b893" level={6} cateid={140}>
                            {
                                (type === "xu-ly" ? xulyDetails : type === "bo-sung" ? bosungDetails : pheduyetDetails).map((title, index) =>
                                    <button key={`btn-${index}`} type='button' onClick={clickScrollTo} className={"w-button wbaseItem-value w-row 94d4da85-a45b-4ec9-89f1-dc8cbbbd2cc3" + (scrollIndex === index ? " selected-mini-sidebar-option" : "")} level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 4b6c130c-9423-4e4d-bdcb-23cad4d70d57" level={8} cateid={139}>{title}</div>
                                    </button>)
                            }
                        </div>
                        <div onScroll={handleScroll} className="w-frame wbaseItem-value w-col e886086e-80b8-48db-80dc-0aae5d3651d3" scroll="true" level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col 76cf0376-bc53-4ca1-8140-433c960f4aad" level={7} cateid={140}>
                                {/* Thông tin khách hàng */}
                                <div className="w-frame wbaseItem-value w-col 44e5c686-eaca-4a5f-b279-c1fefa5b3406" level={8} cateid={140} style={{order: type === "cho-phe-duyet"? 4 : null}}>
                                    <div className="w-frame wbaseItem-value w-row 79ca1119-73da-4bd7-a933-b53e9db019bf" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value c134830c-6bf4-4732-a918-50d3f2af8f51" level={10} cateid={139}>Thông tin khách hàng</div>
                                        <div className="w-frame wbaseItem-value w-row 26224ac8-6baf-493f-8cf5-3bc705422ca4" level={10} cateid={140}>
                                            {settingData.khachHang.checkEKyc ? <button type="button" className="w-button wbaseItem-value w-row 0fcf92aa-4d12-42e9-ba16-d1b7aefdd0a0" level={11} cateid={29}>
                                                <div className="w-svg wbaseItem-value 6cfb28b7-d5ee-4640-a3c2-1f75cd1b54e7" level={12} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.22093 21L1 13.8069L4.2907 10.529L8.22093 14.4556L19.7093 3L23 6.27799L8.22093 21Z" fill="#009944FF" />
                                                    </svg>
                                                </div>
                                                <div className="w-text wbaseItem-value ad38e6e2-9d72-4a44-aeee-6cedcd56032e" level={12} cateid={139}>eKYC</div>
                                            </button> : <div></div>}
                                            {settingData.khachHang.checkBCA ? <button type="button" className="w-button wbaseItem-value w-row 722a160e-0a58-4088-95ef-d45810850cdc" level={11} cateid={29}>
                                                <div className="w-svg wbaseItem-value ecccecc9-ca81-445b-944f-322d35dc8af2" level={12} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.22093 21L1 13.8069L4.2907 10.529L8.22093 14.4556L19.7093 3L23 6.27799L8.22093 21Z" fill="#009944FF" />
                                                    </svg>
                                                </div>
                                                <div className="w-text wbaseItem-value d5f50824-8b08-4112-b0a8-fe233b9bf97c" level={12} cateid={139}>CIC</div>
                                            </button> : <div></div>}
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row fbb0b676-bb99-4fca-8a1c-a1fb4b5000b6" level={9} cateid={140}>
                                        <div className="w-rect wbaseItem-value 416b295a-9f39-489b-b305-c714ed18dfd3" level={10} cateid={138}>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col 99b3331c-0cd5-47bf-8f03-9c451f1a76e8" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row f7005f28-4052-423f-b139-e48fbf5d5ded" wrap="wrap" level={11} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl 625e9f6a-f8ef-40a8-8e1f-162698d78ae1" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value e9ac0868-2428-49a8-9c07-a37b7b953243" level={13} cateid={139}>Họ và tên:</div>
                                                    <div className="w-text wbaseItem-value e1002fe4-6f1d-41ca-9b24-31157fbffd54" level={13} cateid={139}>
                                                        {settingData.khachHang.hoTen ?? "_"}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl d1d615ba-6309-4699-8366-519f9d00d793" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value 8e8629de-5bf7-4ad4-a151-8d3769afc33d" level={13} cateid={139}>Giới tính:</div>
                                                    <div className="w-text wbaseItem-value b17efbad-8a76-4e49-8b49-f5434fe29568" level={13} cateid={139}>
                                                        {settingData.khachHang.gioiTinh ?? "_"}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl 87cdd122-b796-4cd6-a68a-73e81464294c" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value 9b7dd681-ed24-4448-afb5-e678d00c7a5b" level={13} cateid={139}>Ngày sinh:</div>
                                                    <div className="w-text wbaseItem-value 59cec8b2-8d1b-4650-880e-0bffa0366534" level={13} cateid={139}>
                                                        {settingData.khachHang.ngaySinh ?? "_"}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl f068dbf9-3e12-4b91-ac66-087c50abe8ba" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value f955fc2a-0a7d-4261-985f-022e9e664ae2" level={13} cateid={139}>Số điện thoại di động:</div>
                                                    <div className="w-text wbaseItem-value 749d41df-1d0b-4541-a22e-01bc5d1b5087" level={13} cateid={139}>
                                                        {settingData.khachHang.dtDiDong ?? "_"}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl 8aaeb615-f6fd-4741-9531-104318c6adf2" level={12} cateid={140}>
                                                    <div className="wbaseItem-value bd619192-c9b6-48df-abcf-5c4e9b25f883" level={13} cateid={139}>Số CMND đã được cấp:{data.CmndCuInput ? <span style={{ font: "inherit", color: "red" }}> *</span> : ""}</div>
                                                    {data.CmndCuInput ? <div className={'w-textformfield wbaseItem-value w-row 70dd3510-1d16-4968-9616-2ab50aa43bee' + (errors.cccdCu && ' helper-text')} helper-text={errors.cccdCu && 'Nhập số CMND cũ'} placeholder='Nhập số CMND cũ' name-field="Textformfield" level={2} cateid={85} ><div className="wbaseItem-value 920ce048-f286-4313-b4e6-36ef5aa5f105" name-field="Textfield" level={3} cateid={86}>
                                                        <div className="textfield"><input name="cccdCu" placeholder="Nhập số CMND cũ" {...register("cccdCu", { required: true })} /></div>
                                                    </div>
                                                    </div> : <div className="w-text wbaseItem-value 994450f9-510f-47a1-b655-3d806c621ec5" level={13} cateid={139}>
                                                        {settingData.khachHang.cccdCu ?? "_"}
                                                    </div>}
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl 2cb753d3-1ae0-41b3-bf4a-f52cb97f5239" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value 40631139-6891-4d2f-805c-26569b059f32" level={13} cateid={139}>Số CCCD:</div>
                                                    <div className="w-text wbaseItem-value 2b808a46-1ebe-4520-8673-86ee3aa750a8" level={13} cateid={139}>
                                                        {settingData.khachHang.cmndHoChieu ?? "_"}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl c72c203b-c3f0-48d3-bd02-edb9c8c1ba93" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value fc6ed892-7677-4c13-b13e-6c1065a4b3c7" level={13} cateid={139}>Ngày cấp:</div>
                                                    <div className="w-text wbaseItem-value d9e12cab-2017-4d4c-b9dd-11939e64f1ee" level={13} cateid={139}>
                                                        {settingData.khachHang.ngayCap ?? "_"}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl bd02cdd3-12f2-4532-a1a6-029ef437c9cf" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value 7040e2c0-fbb3-49a7-a8f2-8d0ce91e2174" level={13} cateid={139}>Ngày hết hạn:</div>
                                                    <div className="w-text wbaseItem-value 89a04861-fc1d-499d-92bd-f5e5d4d06f23" level={13} cateid={139}>
                                                        {settingData.khachHang.ngayHetHan ?? "_"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row 4acbd425-cf64-4def-920b-8dcfb7cc56d7" wrap="wrap" level={11} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl 138b3342-b297-403f-967e-d11766bbc618" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value 4b8fc0e9-9a59-4687-bb50-ddaa2b596600" level={13} cateid={139}>Địa chỉ thường trú:</div>
                                                    <div className="w-text wbaseItem-value cf068ff9-55e3-4127-ba5a-9511acd52fb0" level={13} cateid={139}>
                                                        {settingData.khachHang.dcThuongTru ?? <span>_</span>}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl b680ba0d-3e83-443e-977b-94d2ee9d5955" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value 1905ab73-51e7-4847-ae85-42b8afc71d65" level={13} cateid={139}>Tỉnh/Thành phố</div>
                                                    <div className="w-text wbaseItem-value a8835048-7044-4287-b5a1-2604a76aa556" level={13} cateid={139}>
                                                        {settingData.loanApplication.addressProvincesName ?? <span>_</span>}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl 4d44e01f-46ef-49b3-b2e9-72c25627bb8d" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value 6682c334-2e8b-4a8b-be88-b1687437db69" level={13} cateid={139}>Quận/Huyện:</div>
                                                    <div className="w-text wbaseItem-value 63d11a70-ab71-4a67-901b-e2a6a2e7725c" level={13} cateid={139}>
                                                        {settingData.loanApplication.addressDistrictName ?? <span>_</span>}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl 3e5959e7-c058-402f-bfe8-b6853492e044" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value 7eab3810-caae-4ec4-8c7d-6898ce2ce6bc" level={13} cateid={139}>Phường/Xã:</div>
                                                    <div className="w-text wbaseItem-value 30779698-025f-4a67-a4a1-503649bab8b7" level={13} cateid={139}>
                                                        {settingData.loanApplication.addressWardsName ?? <span>_</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row 12edbe7e-eb74-4cce-b7be-60b34a5bdeff" wrap="wrap" level={11} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl c9cfc6b7-a9d2-42f6-a66f-aa4331a5527d" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value 04924a96-f754-4af6-b195-cf3fd52503e8" level={13} cateid={139}>Nơi ở hiện tại:</div>
                                                    <div className="w-text wbaseItem-value dda1b8d0-85e3-49d9-adc3-30f240bf3667" level={13} cateid={139}>
                                                        {settingData.loanApplication.dcTamTru ?? settingData.khachHang.dcThuongTru ?? <span>_</span>}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl e7ff10ff-4aeb-41b4-84e3-8bc6bc49c209" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value 11de4fdc-108d-433d-8016-9ff784f8020c" level={13} cateid={139}>Tỉnh/Thành phố</div>
                                                    <div className="w-text wbaseItem-value 6be33269-7d0c-4f2a-964a-a8162efdd121" level={13} cateid={139}>
                                                        {settingData.loanApplication.currentAddressProvincesName ?? settingData.loanApplication.addressProvincesName ?? <span>_</span>}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl 5a0b48a1-212f-4c11-9efa-b586bb5d8233" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value 0938367d-a857-4f9c-858d-97a915650fcf" level={13} cateid={139}>Quận/Huyện:</div>
                                                    <div className="w-text wbaseItem-value 3e9a6281-c034-49d2-ba2c-1bafdb76db61" level={13} cateid={139}>
                                                        {settingData.loanApplication.currentAddressDistrictName ?? settingData.loanApplication.addressDistrictName ?? <span>_</span>}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl 83a199da-f889-48e1-9e10-2c9c3a0d8be5" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value 645a9cd9-1a06-46f3-93d5-f69e1b7081e5" level={13} cateid={139}>Phường/Xã:</div>
                                                    <div className="w-text wbaseItem-value ad837503-99f2-4259-8689-06dc84fbb0a3" level={13} cateid={139}>
                                                        {settingData.loanApplication.currentAddressWardsName ?? settingData.loanApplication.addressWardsName ?? <span>_</span>}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl e9426c5a-bb10-4159-8e29-2f1901339b63" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value 75c432fb-36ec-4a4f-8485-7eba71ac74dc" level={13} cateid={139}>Thời gian cư trú:</div>
                                                    <div className="w-text wbaseItem-value f67b02bb-458d-45fd-8470-6eea771bd40d" level={13} cateid={139}>{settingData.loanApplication.namCuTru + " năm " + settingData.loanApplication.thangCuTru + " tháng"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col24-md col6-xl 4b8731d9-16e2-40ab-80df-64b285552c63" level={12} cateid={140}>
                                                    <div className="w-text wbaseItem-value c3f742dd-6e17-45bb-9b6c-be26436e7326" level={13} cateid={139}>Tình trạng cư trú:</div>
                                                    <div className="w-text wbaseItem-value 85e958c3-f611-4e6e-ba2b-e4193fff8d56" level={13} cateid={139}>
                                                        {settingData.loanApplication.ttNoiCuTru ?? <span>_</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row 631e4938-c206-479c-994f-5434583d171b" wrap="wrap" level={11} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col12-xxl col12-xl col24-lg col24-md d623b63e-4f42-4764-9c09-20dfdc302481" level={12} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-col 7f2923f0-c75f-4ccc-8427-9616837bfb37" level={13} cateid={140}>
                                                        <div className="w-text wbaseItem-value 32b40a2d-3e2e-4d07-a90d-e62d7f7f42b4" level={14} cateid={139}>Họ tên trên CIC/PCB:</div>
                                                        <div className="w-text wbaseItem-value fdb61b79-be10-49a6-b52c-b3dbbb2acaa2" level={14} cateid={139}>{settingData.r11AResponseDto ? settingData.r11AResponseDto.productResponseDto.h2H_TBLSP_K1.tenkh : (settingData.loanApplication.msResponse?.riReqOutput?.subject?.matched?.person?.tenkh ?? "_")}</div>
                                                    </div>
                                                    <div className={"w-frame wbaseItem-value w-row b0878ac7-92ea-4ecf-b973-f92ac2c0e028 " + (errors.checkHoTenCICPCB && "helper-text")} helper-text={errors.checkHoTenCICPCB && "Vui lòng chọn Hợp lệ/Không hợp lệ/Không xác định"} level={13} cateid={128}>
                                                        <div className="w-frame wbaseItem-value w-row 0188ca4d-8f30-4ca8-9c09-e2abc113e63b" level={14} cateid={140}>
                                                            <label className="w-radio-btn wbaseItem-value 0b591b95-e952-41dd-b944-7b333f18491d" level={15} cateid={90}>
                                                                <input type="radio" name="checkHoTenCICPCB" {...register("checkHoTenCICPCB", { required: true })} value={1} defaultChecked={settingData.isAppraisal?.checkHoTenCICPCB === 1} />
                                                                <span className="checkmark">
                                                                </span>
                                                            </label>
                                                            <div className="w-text wbaseItem-value 531b017f-7934-42a0-aa28-e36ebc8151c0" level={15} cateid={139}>Hợp lệ</div>
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-row 6a298799-299b-4bca-95d3-d478638a3127" level={14} cateid={140}>
                                                            <label className="w-radio-btn wbaseItem-value 7d0879d4-69ba-4bf2-b51c-20b2653e73ee" level={15} cateid={90}>
                                                                <input type="radio" name="checkHoTenCICPCB" {...register("checkHoTenCICPCB", { required: true })} value={0} defaultChecked={settingData.isAppraisal?.checkHoTenCICPCB === 0} />
                                                                <span className="checkmark">
                                                                </span>
                                                            </label>
                                                            <div className="w-text wbaseItem-value e222243b-c3e7-4a83-869d-8447ec38e3eb" level={15} cateid={139}>Không hợp lệ</div>
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-row 6a298799-299b-4bca-95d3-d478638a3127" level={14} cateid={140}>
                                                            <label className="w-radio-btn wbaseItem-value 7d0879d4-69ba-4bf2-b51c-20b2653e73ee" level={15} cateid={90}>
                                                                <input type="radio" name="checkHoTenCICPCB" {...register("checkHoTenCICPCB", { required: true })} value={2} defaultChecked={settingData.isAppraisal?.checkHoTenCICPCB === 2} />
                                                                <span className="checkmark">
                                                                </span>
                                                            </label>
                                                            <div className="w-text wbaseItem-value e222243b-c3e7-4a83-869d-8447ec38e3eb" level={15} cateid={139}>Không xác định</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col12-xxl col12-xl col24-lg col24-md 545ef71e-c4c4-475d-b040-b086d1f7cb70" level={12} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-col d6e4d15b-a4ec-45ee-802f-17493e01d380" level={13} cateid={140}>
                                                        <div className="w-text wbaseItem-value 27b8e892-8374-4cd5-9089-28133d80ef94" level={14} cateid={139}>Địa chỉ thường trú trên CIC/PCB:</div>
                                                        <div className="w-text wbaseItem-value 20c13127-6972-4a5a-aaa6-eb2ea36c7a0d" level={14} cateid={139}>{settingData.r11AResponseDto ? settingData.r11AResponseDto.productResponseDto.h2H_TBLSP_K1.diachi : (settingData.loanApplication.msResponse?.riReqOutput?.subject?.matched?.person?.diachi ?? "_")}</div>
                                                    </div>
                                                    <div className={"w-frame wbaseItem-value w-row ccd076b9-d388-4845-a57b-3444cd87816f " + (errors.checkDcThuongTruCICPCB && "helper-text")} helper-text={errors.checkDcThuongTruCICPCB && "Vui lòng chọn Hợp lệ/Không hợp lệ/Không xác định"} level={13} cateid={128}>
                                                        <div className="w-frame wbaseItem-value w-row a6b69b33-a989-4291-9692-28c7df36f206" level={14} cateid={140}>
                                                            <div className="w-radio-btn wbaseItem-value 3d562bac-0d15-4338-a2e8-2d68cdfb2483" level={15} cateid={90}>
                                                                <input type="radio" name="checkDcThuongTruCICPCB" {...register("checkDcThuongTruCICPCB", { required: true })} value={1} defaultChecked={settingData.isAppraisal?.checkDcThuongTruCICPCB === 1} />
                                                                <span className="checkmark">
                                                                </span>
                                                            </div>
                                                            <div className="w-text wbaseItem-value b38c5772-8b8b-4034-9d44-a0cdc920d32f" level={15} cateid={139}>Hợp lệ</div>
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-row cd890a99-c780-4b71-a5f7-38b75880d568" level={14} cateid={140}>
                                                            <div className="w-radio-btn wbaseItem-value c55ef47b-7a65-4cb9-b09b-148f8a41b8ce" level={15} cateid={90}>
                                                                <input type="radio" name="checkDcThuongTruCICPCB" {...register("checkDcThuongTruCICPCB", { required: true })} value={0} defaultChecked={settingData.isAppraisal?.checkDcThuongTruCICPCB === 0} />
                                                                <span className="checkmark">
                                                                </span>
                                                            </div>
                                                            <div className="w-text wbaseItem-value 4db47c11-47ff-43f3-b781-5da6d4b86211" level={15} cateid={139}>Không hợp lệ</div>
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-row cd890a99-c780-4b71-a5f7-38b75880d568" level={14} cateid={140}>
                                                            <div className="w-radio-btn wbaseItem-value c55ef47b-7a65-4cb9-b09b-148f8a41b8ce" level={15} cateid={90}>
                                                                <input type="radio" name="checkDcThuongTruCICPCB" {...register("checkDcThuongTruCICPCB", { required: true })} value={2} defaultChecked={settingData.isAppraisal?.checkDcThuongTruCICPCB === 2} />
                                                                <span className="checkmark">
                                                                </span>
                                                            </div>
                                                            <div className="w-text wbaseItem-value 4db47c11-47ff-43f3-b781-5da6d4b86211" level={15} cateid={139}>Không xác định</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Thông tin khoản vay */}
                                <div className="w-frame wbaseItem-value w-col 4250b177-6345-4eb7-ac8d-b63b922a32e6" level={8} cateid={140} style={{order: type === "cho-phe-duyet"? 5 : null}}>
                                    <div className="w-frame wbaseItem-value w-row d640148b-c4fd-40f4-a11f-7d605c60eed4" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 8c8f4cf5-2ecf-4820-a175-5cd8fbceb860" level={10} cateid={139}>Thông tin khoản vay</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col 753774a9-e134-4aee-aaf0-01a93c74da56" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row 9bf2aaae-22f4-425d-9dbb-a010cdb20791" wrap="wrap" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md db3a3a2e-2bb4-4258-9046-ef1dea989aa3" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value f0e82fd2-ac79-4839-94b7-7a8ffda54bd3" level={12} cateid={139}>Sản phẩm vay:</div>
                                                <div className="w-text wbaseItem-value 15acbdff-0b64-4cd1-9743-f560864f0db4" level={12} cateid={139}>
                                                    {settingData.loanApplication?.sanPham ?? "_"}
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 65fdbaca-3512-4657-9173-e37cbcdbdaa8" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 564f1a58-f7b3-4c28-b588-6a60ef1d1ef8" level={12} cateid={139}>Đại lý:</div>
                                                <div className="w-text wbaseItem-value 5e8c6d74-c410-4ed6-898c-ddbc720f13a0" level={12} cateid={139}>{settingData.loanApplication?.daiLy ?? "_"}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 64cd7d11-6b3b-4e92-bc56-3f5c1cc50155" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value b3f5fcc3-434e-4b04-985c-c070633ed748" level={12} cateid={139}>Địa chỉ đại lý:</div>
                                                <div className="w-text wbaseItem-value 19165cfc-8b55-455c-82c6-b16cb595b136" level={12} cateid={139}>{settingData.loanApplication?.dcDaiLy ?? "_"}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md d13d25d1-b1b9-4187-b795-d8d4ac91fbe9" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 741f87b3-cdf1-4079-8375-9ac861d36844" level={12} cateid={139}>Chương trình vay:</div>
                                                <div className="w-text wbaseItem-value f9da91e5-cfbd-4564-ab47-09120765d850" level={12} cateid={139}>{settingData.loanApplication?.chuongTrinhVay ?? "_"}</div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 3fcfef55-522f-4fd4-9482-ceffb6223f56" wrap="wrap" level={10} cateid={140}>
                                            {
                                                type === "bo-sung" ? <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col12-lg col24-md 4581f173-8f78-4e61-86f3-d83474273480" level={11} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-col 72054dd6-59f6-4980-ab06-3604296af584" level={12} cateid={140}>
                                                        <div className="w-text wbaseItem-value 4727ebf6-5836-460d-a313-af9dae36601a" level={12} cateid={139}>Mục đích vay:</div>
                                                        <div className="w-text wbaseItem-value 3074116c-d1bc-4444-bd7f-a3269c419565" level={12} cateid={139}>{settingData.loanApplication?.mucDichVay ?? "_"}</div>
                                                    </div>
                                                    <label className="w-check-box wbaseItem-value a56fe8a9-b575-48dd-a74d-3513bc6747bf" level={12} cateid={79}>
                                                        <input type="checkbox" name='checkMucDichVay' {...register("checkMucDichVay")} defaultChecked={settingData.loanApplication.checkMucDichVay === 1} />
                                                        <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                    </label>
                                                </div> :
                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 989da782-9d5d-477c-8801-37193ff51839" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 4727ebf6-5836-460d-a313-af9dae36601a" level={12} cateid={139}>Mục đích vay:</div>
                                                        <div className="w-text wbaseItem-value 3074116c-d1bc-4444-bd7f-a3269c419565" level={12} cateid={139}>{settingData.loanApplication?.mucDichVay ?? "_"}</div>
                                                    </div>
                                            }
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 392d90fd-bf77-43c0-afae-88ae09707758" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value d2100888-9a07-432d-8b79-5e708221cea5" level={12} cateid={139}>Nhãn hiệu:</div>
                                                <div className="w-text wbaseItem-value fc3193c5-4929-4168-b4e9-eee21cea6a8b" level={12} cateid={139}>{settingData.loanApplication?.nhanHieu ?? "_"}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md b6d3f701-944a-4660-bc4d-fc7c15cb944a" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 457b7cf7-14d1-4b18-b1e1-2b69c39c2f67" level={12} cateid={139}>Màu sắc:</div>
                                                <div className="w-text wbaseItem-value 2167e30d-e73b-4ac5-9d15-c0452acea76d" level={12} cateid={139}>{settingData.loanApplication?.mauSac ?? "_"}</div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 9bf3007e-3717-47ff-88a0-7e105963d63d" wrap="wrap" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md fe7fbd67-7888-4651-b43b-7267a0003a4b" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 400f66b1-de3a-4c03-aed2-09bb39a6ae7c" level={12} cateid={139}>Số tiền vay gốc:</div>
                                                <div className="w-text wbaseItem-value 2bb9ed7f-1082-4a51-8fc4-cb6e8ed8a298" level={12} cateid={139}>{settingData.loanApplication?.soTienVayGoc ? Ultis.money(settingData.loanApplication?.soTienVayGoc) : "_"} VND</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 5afcb065-b9da-4d9b-ae2a-8e5981758b70" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 9a588bf4-b62c-405a-bc16-d450e9c1d936" level={12} cateid={139}>Tổng khoản vay bao gồm bảo hiểm:</div>
                                                <div className="w-text wbaseItem-value 2ab3f078-0530-4dc2-8f72-ec277761b2c0" level={12} cateid={139}>{Ultis.money(settingData.loanApplication.soTienVayGoc + settingData.loanApplication.soTienBaoHiem)} VND</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 2a755c24-edf2-4051-b4b9-3de907b307f3" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 351b761d-67e4-4286-8b89-a3229cbf119b" level={12} cateid={139}>Kỳ hạn vay:</div>
                                                <div className="w-text wbaseItem-value efcd490c-b182-4c9d-806a-c3a190c904c4" level={12} cateid={139}>{settingData.loanApplication?.kyHanVay ?? "_"}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 60245420-809e-48e6-b524-25ba148eebd1" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 6243f1a3-4ecc-4501-86bf-d595019bf6ed" level={12} cateid={139}>Ngày thanh toán hàng tháng:</div>
                                                <div className="w-text wbaseItem-value 337d75b8-945b-423b-94c8-4aae25ae2340" level={12} cateid={139}>{settingData.loanApplication?.ngayThanhToanHangThang ?? "_"}</div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 931f3a06-613e-428e-bba2-950ffea3f75b" wrap="wrap" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 4033c1e4-48a6-4a9f-abdb-1cfafd6ab47f" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value c9bc5e7e-b3ef-4388-8c62-a9ca1a31e29f" level={12} cateid={139}>Tổng giá bán:</div>
                                                <div className="w-text wbaseItem-value e490c683-082f-4272-a6de-9919c957a4c5" level={12} cateid={139}>{settingData.loanApplication?.tongGiaBan ? Ultis.money(settingData.loanApplication.tongGiaBan) : "_"} VND</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 467cb9de-4617-478d-9a50-d4974fa47d3c" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value de641ea8-d1c4-4e7a-8301-2d11fed99ccf" level={12} cateid={139}>Tỷ lệ % trả trước:</div>
                                                <div className="w-text wbaseItem-value 2af933a4-0d9b-4022-a8f3-f3c9ba591faa" level={12} cateid={139}>{`${settingData.loanApplication?.tyLeTraTruoc?.toFixed(1) ?? "_"}`.replace(".0", "")}%</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c10326ad-be87-47ca-b0d9-25f3b21d512d" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 2fb78f57-d95e-43c9-9087-8adff01ee789" level={12} cateid={139}>Số tiền trả trước:</div>
                                                <div className="w-text wbaseItem-value 128652a3-7aeb-4808-8447-859bfe09c2e9" level={12} cateid={139}>{settingData.loanApplication?.soTienTraTruoc ? Ultis.money(settingData.loanApplication?.soTienTraTruoc) : "_"}VND</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Tài chính khách hàng */}
                                <div className="w-frame wbaseItem-value w-col 0bcc79e2-2dde-4a76-9841-912b5774e619" level={8} cateid={140} style={{order: type === "cho-phe-duyet"? 6 : null}}>
                                    <div className="w-frame wbaseItem-value w-row 86ae6675-5054-4436-a5d8-a6bcc677a55e" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 6ea25e7c-c69a-4168-baf6-2121041dc550" level={10} cateid={139}>Tài chính khách hàng</div>
                                    </div>
                                    {
                                        type === "bo-sung" ? [
                                            <div key="group-0" className="w-frame wbaseItem-value w-row 0bb165ec-0706-4acd-a30d-9081969f9ffa" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col12-lg col24-md d73659d1-0efd-4b5d-8259-2e1772eba341" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-col 07f6bb85-07d6-433a-b43b-469c01257073" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value e25f9726-afb7-4ebb-bd67-026153844a91" level={12} cateid={139}>Nghề nghiệp</div>
                                                        <div className="w-text wbaseItem-value 81821978-a528-489d-9427-ffc47c340e55" level={12} cateid={139}>{settingData.loanApplication.tenNgheNghiep ?? "_"}</div>
                                                    </div>
                                                    <label className="w-check-box wbaseItem-value ec0bc0fa-2d91-4d6f-9807-3959c061f967" level={11} cateid={79}>
                                                        <input type="checkbox" name="checkNgheNghiep" {...register("checkNgheNghiep")} defaultChecked={settingData.loanApplication.checkNgheNghiep === 1} />
                                                        <svg checkcolor="FFFFFFFF" width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                    </label>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col8-xl col12-lg col24-md 1b6e0418-88e6-448a-8c07-23de8eba2286" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 78969c82-4550-4963-a383-d399441748e5" level={11} cateid={139}>Tên công ty/Nơi làm việc:</div>
                                                    <div className="w-text wbaseItem-value e4ca929c-aa01-4aaa-9231-6b22dcd34e98" level={11} cateid={139}>{settingData.loanApplication.noiLamViec ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col8-xl col12-lg col24-md 6b2af3f6-5357-45e9-a81e-cadbae25e136" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value d985f8cb-b2cd-4411-8f92-4e8f0d0d0ff3" level={11} cateid={139}>Địa chỉ nơi làm việc:</div>
                                                    <div className="w-text wbaseItem-value 0789313e-b072-4e2c-b1b1-9a675652b778" level={11} cateid={139}>{settingData.loanApplication.dcNoiLamViec ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col8-xl col12-lg col24-md e6a7f0f4-49ab-41e4-972a-3ae5bd5c9762" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 881ea5de-d0ff-4c72-8ad2-af14a89f12f4" level={11} cateid={139}>Số điện thoại công ty:</div>
                                                    <div className="w-text wbaseItem-value 60b4556a-555a-4b50-bc80-1e6114b8c04e" level={11} cateid={139}>{settingData.loanApplication.dtNoiLamViec ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col12-lg col24-md 585a9b49-cefc-49fc-88a4-60362e854afd" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-col 16bff9e8-5e91-4c00-b24c-84f7b37ace98" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 5dca8328-aa01-4ddd-a68f-dbf1c7832cd7" level={12} cateid={139}>Thu nhập hàng tháng</div>
                                                        <div className="w-text wbaseItem-value 9359fe80-3b9b-483c-8d94-d01b9e4b3806" level={12} cateid={139}>{settingData.loanApplication.thuNhapThang ? Ultis.money(settingData.loanApplication.thuNhapThang) : "_"} VND</div>
                                                    </div>
                                                    <label className="w-check-box wbaseItem-value 7b8fc5d2-cfb2-4bac-8a62-d1d4c82fa559" level={11} cateid={79}>
                                                        <input type="checkbox" name="checkThuNhapThang" {...register("checkThuNhapThang")} defaultChecked={settingData.loanApplication.checkThuNhapThang === 1} />
                                                        <svg checkcolor="FFFFFFFF" width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                    </label>
                                                </div>
                                            </div>,
                                            <div key="group-1" className="w-frame wbaseItem-value w-row 71945f24-9ebe-47f8-9876-12490243ecfe" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col24-lg col24-md col12-xl e8772c74-1d98-406e-a163-f289c268a147" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row e98ac89f-cb66-4dea-9758-be2bfa534ca2" level={11} cateid={140}>
                                                        <label className="w-check-box wbaseItem-value 92129d49-5ee7-4eb7-aa01-cc9b95be59a0" level={12} cateid={79}>
                                                            <input type="checkbox" name='checkNoVayKhac' {...register("checkNoVayKhac")} defaultChecked={settingData.loanApplication.checkNoVayKhac === 1} />
                                                            <svg checkcolor="FFFFFFFF" width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                        </label>
                                                        <div className="w-text wbaseItem-value 5cb87dec-c695-4537-9baf-b470ffdb57ab" level={12} cateid={139}>Nợ vay khác</div>
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 f526d5d2-ee98-4b89-aecb-8318a171b418" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 936347a6-a06b-4648-9e2c-5ef719e18cef" level={12} cateid={139}>Tổng thanh toán nợ vay hàng tháng:</div>
                                                        <div className="w-text wbaseItem-value 2fdfcec1-e307-43b2-9712-a490d1493a5d" level={12} cateid={139}>{settingData.loanApplication.tongThanhToanNoVayHangThang ? Ultis.money(settingData.loanApplication.tongThanhToanNoVayHangThang) : "_"} VND</div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col24-lg col24-md col12-xl f45b4d41-78b6-4fa4-a10e-bc471fa785d1" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 98caca1d-6e2b-4bb5-8a07-a71e118f4aa7" level={11} cateid={140}>
                                                        <label className="w-check-box wbaseItem-value ebe13113-2746-465d-86a2-8e941df61fa4" level={12} cateid={79}>
                                                            <input type="checkbox" name="checkSoNguoiPhuThuoc" {...register("checkSoNguoiPhuThuoc")} defaultChecked={settingData.loanApplication.checkSoNguoiPhuThuoc === 1} />
                                                            <svg checkcolor="FFFFFFFF" width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                        </label>
                                                        <div className="w-text wbaseItem-value fee0f926-3497-48ca-9cab-92e4c9a91dcb" level={12} cateid={139}>Người phụ thuộc</div>
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 611e7928-2013-4f98-a37f-d9ebee82752e" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 80971b1c-9d9c-43fd-afc8-7855d89e6f67" level={12} cateid={139}>Số người phụ thuộc</div>
                                                        <div className="w-text wbaseItem-value 1393626e-0e21-4fd6-9ce6-2db52b1e9295" level={12} cateid={139}>{settingData.loanApplication.soNguoiPhuThuoc ?? "_"}</div>
                                                    </div>
                                                </div>
                                            </div>,
                                            <div key="group-2" className="w-frame wbaseItem-value w-row ae8faa0d-63af-492a-9d4c-778b052906e7" level={9} cateid={128}>
                                                <div className="w-frame wbaseItem-value w-row ead8634e-c666-4ae8-ab71-ca3e6609ebee" level={10} cateid={140}>
                                                    <label className="w-radio-btn wbaseItem-value 45d2a73f-e914-4fd0-88fc-ba7d377cd995" level={11} cateid={90}>
                                                        <input type="radio" name="c" defaultChecked />
                                                        <span className="checkmark" />
                                                    </label>
                                                    <div className="w-text wbaseItem-value a19e698f-f71a-45a5-a9bc-0db1296fd63c" level={11} cateid={139}>Hợp lệ</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-row 17075970-6fa1-42a3-87b7-1fc8e5e50165" level={10} cateid={140}>
                                                    <label className="w-radio-btn wbaseItem-value 4fcb73c4-c6f1-47e1-989a-d6516944f646" level={11} cateid={90}>
                                                        <input type="radio" name="c" defaultChecked />
                                                        <span className="checkmark" />
                                                    </label>
                                                    <div className="w-text wbaseItem-value 0e59e582-38f1-4fcd-ad26-b0c802e3b0a9" level={11} cateid={139}>Không hợp lệ</div>
                                                </div>
                                            </div>,
                                            <div key="group-3" className="w-frame wbaseItem-value w-row 7b89abbd-7366-4e46-97f8-ad6715efaa6c" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md bde02ed5-2d80-4ea6-8ef0-ec69f52e6b9b" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row aa65b601-ee8d-45e4-a3ac-9481ee17427d" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 38ace4d0-21ac-404e-8919-0d112ec69ba4" level={12} cateid={139}>Tổng thanh toán nợ vay hàng tháng<br />
                                                        </div>
                                                        <div className="w-text wbaseItem-value fe527691-3d85-4ab7-8e04-8e029d3e3f4d" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div className={"w-textformfield wbaseItem-value w-row 526cb9e9-849f-4424-8433-7d170ec4717d" + (errors.tongThanhToanNoVayHangThang && ' helper-text')} helper-text={errors.tongThanhToanNoVayHangThang && 'Nhập tổng số thanh toán nợ hàng tháng'} level={11} cateid={85} placeholder="Nhập số tiền">
                                                        <div className="wbaseItem-value f4c43f96-5bff-4cda-9c1a-cfb641a82e10" level={12} cateid={86}>
                                                            <div className="textfield">
                                                                <input onKeyDown={(ev) => {
                                                                    if (ev.key.toLowerCase() === "backspace" || ev.key.toLowerCase() === "delete") return;
                                                                    if (!ev.key.match(/\d/g)) ev.preventDefault();
                                                                }}
                                                                    onInput={inputMoneyPattern}
                                                                    name="tongThanhToanNoVayHangThang"
                                                                    defaultValue={Ultis.money(settingData.loanApplication.tongThanhToanNoVayHangThang) ?? ""}
                                                                    placeholder="Nhập số tiền"
                                                                    {...register("tongThanhToanNoVayHangThang", { required: true })} />
                                                            </div>
                                                        </div>
                                                        <div className="w-text wbaseItem-value e5ec177a-2ce2-4b61-a9df-425029ad2ca8" level={12} cateid={139}>VND</div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md ae02d106-8ce9-44be-9586-ef735af9a8e9" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 99908306-cfa6-4f24-af23-f534caf528be" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 19640807-441d-40c1-ba11-e4eabdbd6806" level={12} cateid={139}>Số người phụ thuộc</div>
                                                        <div className="w-text wbaseItem-value c570acc7-a34d-4b90-ade7-26691debaf44" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div className={"w-textformfield wbaseItem-value w-row 9c6a74bc-b241-48e5-8ea3-341dca4ca824" + (errors.soNguoiPhuThuoc && ' helper-text')} helper-text={errors.soNguoiPhuThuoc && 'Nhập số người phụ thuộc'} level={11} cateid={85} placeholder="Nhập số người phụ thuộc">
                                                        <div className="wbaseItem-value f0ff11fb-96a0-45a8-8c7b-3aa9bc9bd9a3" level={12} cateid={86}>
                                                            <div className="textfield">
                                                                <input type='number'
                                                                    onKeyDown={(ev) => {
                                                                        if (ev.key.toLowerCase() === "backspace" || ev.key.toLowerCase() === "delete") return;
                                                                        if (!ev.key.match(/\d/g)) ev.preventDefault();
                                                                    }}
                                                                    name="soNguoiPhuThuoc"
                                                                    defaultValue={settingData.loanApplication.soNguoiPhuThuoc}
                                                                    placeholder="Nhập số người phụ thuộc"
                                                                    {...register("soNguoiPhuThuoc", { required: true })} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ] : [
                                            <div key="group-0" className="w-frame wbaseItem-value w-row ac2b6159-2df7-426f-bf63-c6ca5ab0bd90" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col8-xl col12-lg col24-md 60a50200-6ff8-4a88-859f-26cf48b670b1" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value e278fa3c-525b-4f25-88b8-32971a49c5ca" level={11} cateid={139}>Nghề nghiệp:</div>
                                                    <div className="w-text wbaseItem-value 0fcb8d4c-e41f-446c-96c9-cd79301a6cae" level={11} cateid={139}>{settingData.loanApplication?.tenNgheNghiep ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col8-xl col12-lg col24-md 1333aeb4-f739-47b2-a46e-5ac4968d40bf" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value ae3e96c5-33ee-4735-b2dc-64b59e222cae" level={11} cateid={139}>Tên công ty/Nơi làm việc:</div>
                                                    <div className="w-text wbaseItem-value 02405bfa-e862-41d9-8842-95616872f4a6" level={11} cateid={139}>{settingData.loanApplication?.noiLamViec ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col8-xl col12-lg col24-md 71066771-ac2b-4975-8e36-0af3664bf0fd" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value fab4ac15-fe16-45d6-b5a4-66399c7851f8" level={11} cateid={139}>Địa chỉ nơi làm việc:</div>
                                                    <div className="w-text wbaseItem-value 8e6fa6e8-6438-418a-8c40-f6125de3999c" level={11} cateid={139}>{settingData.loanApplication?.dcNoiLamViec ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col8-xl col12-lg col24-md 8de7f490-b42f-44d9-9ecb-68edac541411" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 3f3e8c46-3614-47b4-bd34-243712c9571d" level={11} cateid={139}>Số điện thoại công ty:</div>
                                                    <div className="w-text wbaseItem-value a0a30165-e32e-491b-a5a8-633439e2529c" level={11} cateid={139}>{settingData.loanApplication?.dtNoiLamViec ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col8-xl col12-lg col24-md 90a9ad8c-b63f-4845-93dc-cc9ee8d801c9" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value b4963686-7b2d-4364-aca4-141bbdc82257" level={11} cateid={139}>Thu nhập hàng tháng:</div>
                                                    <div className="w-text wbaseItem-value b27e941c-7271-4675-9c36-1466c3011aa7" level={11} cateid={139}>{settingData.loanApplication?.thuNhapThang ?? "_"} VND</div>
                                                </div>
                                            </div>,
                                            <div key="group-1" className="w-frame wbaseItem-value w-row ec5a5b15-2c12-441e-b84f-ee58ccea1f2e" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col24-lg col24-md col12-xl 3443a253-4dbb-4492-a3e7-cec5a8603042" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 721ca4b1-4ab8-4a9c-a657-fb408b2d4540" level={11} cateid={140}>
                                                        <label className="w-check-box wbaseItem-value ad072876-1569-4870-8802-1b1f08fcf650" value="false" level={12} cateid={79}>
                                                            <input type="checkbox" name="novaykhac" />
                                                            <svg width="20" height="20">
                                                                <path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF">
                                                                </path>
                                                            </svg>
                                                        </label>
                                                        <div className="w-text wbaseItem-value 652498f2-4f65-43e6-a398-42c2428e59ee" level={12} cateid={139}>Nợ vay khác</div>
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 ac841500-b781-4465-a50f-6a749ad9eab4" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 58c20794-0daf-4328-84ea-9224940f59b1" level={12} cateid={139}>Tổng thanh toán nợ vay hàng tháng:</div>
                                                        <div className="w-text wbaseItem-value fbaf3f0e-2ad3-410b-a621-49561d33c484" level={12} cateid={139}>{`${settingData.isAppraisal?.tongThanhToanNoVayHangThang?.toFixed(1) ?? "_"}`.replace(".0", "")} VND</div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col24-lg col24-md col12-xl 2f54328c-59a9-4fcb-9183-7702e64d6f0c" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 3a0054df-c63e-431f-bd5c-6f74927013bf" level={11} cateid={140}>
                                                        <label className="w-check-box wbaseItem-value 5f471c97-1818-4884-b039-3806ab6ebac7" value="false" level={12} cateid={79}>
                                                            <input type="checkbox" name="checkSoNguoiPhuThuoc" defaultChecked={settingData.loanApplication?.checkSoNguoiPhuThuoc === 1} />
                                                            <svg width="20" height="20">
                                                                <path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF">
                                                                </path>
                                                            </svg>
                                                        </label>
                                                        <div className="w-text wbaseItem-value 7acbab49-629b-40ff-85ea-3955e6b32f78" level={12} cateid={139}>Người phụ thuộc</div>
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col8-lg col8-md col12-sm col12 a9021f1d-4fb3-4873-959c-fc7382f9e3e0" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 07ee293a-b234-417d-aeb5-ecde083dd7c3" level={12} cateid={139}>Số người phụ thuộc</div>
                                                        <div className="w-text wbaseItem-value d10a45cd-1ec0-4665-a878-52b941c3db01" level={12} cateid={139}>{settingData.loanApplication?.soNguoiPhuThuoc ?? "_"}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ]
                                    }

                                </div>
                                {/* Thông tin người liên quan */}
                                {
                                    type !== "xu-ly" ? 
                                        <div className="w-frame wbaseItem-value w-col f39df72f-fec7-48b3-9405-3ddf3315347f" level={8} cateid={140} style={{order: type === "cho-phe-duyet"? 7 : null}}>
                                            <div className="w-frame wbaseItem-value w-row f8ad9c08-ab67-4772-b85e-23df0d25addf" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value f854c12d-7b43-4c70-9349-9653bc33af47" level={10} cateid={139}>Thông tin người liên quan</div>
                                            </div>
                                            {renderRelativeInfor()}
                                        </div>
                                     : null}
                                {/* Ghi chú của SC */}
                                <div className="w-frame wbaseItem-value w-col 5d9a634d-7757-4ccc-a27e-2e8de1373523" level={8} cateid={140} style={{order: type === "cho-phe-duyet"? 8 : null}}>
                                    <div className="w-frame wbaseItem-value w-row 3a09baf3-b26b-4959-9926-c53b11ad441f" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value b81f05ac-7222-40e3-b531-ad63edf992b5" level={10} cateid={139}>Ghi chú của SC</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col a24f17d8-564e-4e4f-8561-5fe98585008c" level={9} cateid={140}>
                                        {renderNoteList()}
                                    </div>
                                </div>
                                {/* Hồ sơ đính kèm */}
                                <div className="w-frame wbaseItem-value w-col 2200b7a8-09ef-4f47-bad0-13feba5d8177" level={8} cateid={140} style={{order: type === "cho-phe-duyet"? 9 : null}}>
                                    <div className="w-text wbaseItem-value e5da40db-b7ad-4754-9505-a0d2004b8871" level={9} cateid={139}>Hồ sơ đính kèm</div>
                                    <table className="w-table wbaseItem-value a7b0ca77-ea58-46c5-bce2-31483e4ec6f4" type={0} level={9} cateid={117}>
                                        <tbody>
                                            <tr className="table-row">
                                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 27f377fc-dabe-4da7-8460-f55c5d263ab7" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value defd0e9a-a685-4876-a65d-1f65580c01e9" level={11} cateid={139}>Loại hồ sơ</div>
                                                    </div>
                                                </td>
                                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 52e80f81-6d35-4a12-b7c0-f05a435b2017" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value be7f542b-1527-4a36-b758-bd849dfa81d5" level={11} cateid={139}>Tệp đính kèm</div>
                                                    </div>
                                                </td>
                                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row e0f36481-a5d1-453b-bcaa-d0b583508784" level={10} cateid={140} />
                                                </td>
                                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 3cff2767-21e1-4390-808e-9b076f829e2e" level={10} cateid={140} style={{ justifyContent: "center" }}>
                                                        <div className="w-text wbaseItem-value 6d93dd64-4bc6-40da-8ddb-5b3a94cebadd" level={11} cateid={139}>Yêu cầu sửa</div>
                                                    </div>
                                                </td>
                                                <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 26197070-ca37-43e3-8bc1-e85b525c6cd9" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 6aec3d61-9dd5-4f0e-9c5a-06f81ef82294" level={11} cateid={139}>Thẩm định</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            {renderFileList()}

                                        </tbody>
                                    </table>
                                </div>
                                {/* Lịch sử tín dụng tại các TCTD */}
                                <div className="w-frame wbaseItem-value w-col 65fbbaf9-316b-4a17-abf6-1843f41de97f" level={8} cateid={140} style={{order: type === "cho-phe-duyet"? 10 : null}}>
                                    <div className="w-frame wbaseItem-value w-row ef49df5d-1ba6-4988-be57-41a326e7096c" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value ff079c47-dd6e-44f5-94f1-ec9715c204ec" level={10} cateid={139}>Lịch sử tín dụng tại các TCTD</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col cf6482ee-6578-411f-9e02-1403cdb69026" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 26ea5c68-11aa-4fb9-a820-7cdc4bd34bc6" level={10} cateid={139}>Tra thông tin theo CCCD</div>
                                        <div className="w-frame wbaseItem-value w-row 51853e3c-2935-4065-b19e-9725c5ed1825" wrap="wrap" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24-sm col24 2f3350bb-d5a9-478c-9b67-f572c9842fdd" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 78d8689f-3ba9-44e4-89a7-443dd2385137" level={12} cateid={139}>Tổng dư nợ hiện tại:</div>
                                                <div className="w-text wbaseItem-value e8d8c396-a2c5-456b-962f-c984f2a82934" level={12} cateid={139}>{dtiData.totalCurrentOutstandingBalance ?? "_"}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24-sm col24 2f3350bb-d5a9-478c-9b67-f572c9842fdd" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 78d8689f-3ba9-44e4-89a7-443dd2385137" level={12} cateid={139}>Nhóm nợ cao nhất hiện tại:</div>
                                                <div className="w-text wbaseItem-value e8d8c396-a2c5-456b-962f-c984f2a82934" level={12} cateid={139}>{dtiData.highestDebtGroup?.length ? dtiData.highestDebtGroup : "_"}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col12-xxl col12-xl col24-lg col24-md col24-sm col24 2f3350bb-d5a9-478c-9b67-f572c9842fdd" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 78d8689f-3ba9-44e4-89a7-443dd2385137" level={12} cateid={139}>Khoản thanh toán nợ vay hàng tháng tại tất cả các TCTD (Bao gồm JIVF):</div>
                                                <div className="w-text wbaseItem-value e8d8c396-a2c5-456b-962f-c984f2a82934" level={12} cateid={139}>{dtiData.totalPaymentLoansOtherCreditInstitutions ? Ultis.money(dtiData.totalPaymentLoansOtherCreditInstitutions) : "_"} VND</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row 844a7cfd-f749-4f36-b1d5-832fe2841bd5" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value e4c2210a-f035-4c8d-bfa5-123bb34402e8" level={10} cateid={139}>Tra thông tin theo CMND 9 số</div>
                                                <button type="button" onClick={() => selectCheckCCCD9So(0)} className="w-button wbaseItem-value w-row c002f0d4-3d00-4b9d-88a4-cd56674100b9" style={{ backgroundColor: (settingData.cccdCu ?? getValues("cccdCu")) ? "#009944" : null }} level={10} cateid={29}>
                                                    <div className="w-text wbaseItem-value 50ff093a-6105-4353-9b8e-155300d990c7" style={{ color: checkCCCD9So.keyCheck === 0 ? "white" : null }} level={11} cateid={139}>Thông tin CIC S37</div>
                                                </button>
                                        <button type="button" onClick={() => selectCheckCCCD9So(1)} className="w-button wbaseItem-value w-row c002f0d4-3d00-4b9d-88a4-cd56674100b9" style={{ backgroundColor: (settingData.cccdCu ?? getValues("cccdCu")) ? "#009944" : null }} level={10} cateid={29}>
                                            <div className="w-text wbaseItem-value 50ff093a-6105-4353-9b8e-155300d990c7" style={{ color: checkCCCD9So.keyCheck === 1 ? "white" : null }} level={11} cateid={139}>Thông tin CIC R11
                                            </div>
                                        </button>
                                        <button type="button" onClick={() => selectCheckCCCD9So(2)} className="w-button wbaseItem-value w-row c002f0d4-3d00-4b9d-88a4-cd56674100b9" style={{ backgroundColor: (settingData.cccdCu ?? getValues("cccdCu")) ? "#009944" : null }} level={10} cateid={29}>
                                            <div className="w-text wbaseItem-value 50ff093a-6105-4353-9b8e-155300d990c7" style={{ color: checkCCCD9So.keyCheck === 2 ? "white" : null }} level={11} cateid={139}>Thông tin PCB</div>
                                        </button>
                                    </div>
                                    {
                                        checkCCCD9So.keyCheck === 0 ? <div className="w-frame wbaseItem-value w-col 4d44e01f-46ef-49b3-b2e9-72c25627bb8d" level={12} cateid={140}>
                                            <div className="w-text wbaseItem-value 6682c334-2e8b-4a8b-be88-b1687437db69" level={13} cateid={139}>Nội dung S37:</div>
                                            <div className="w-text wbaseItem-value 63d11a70-ab71-4a67-901b-e2a6a2e7725c" level={13} cateid={139}>
                                                {checkCCCD9So.data.noidung}
                                            </div>
                                        </div> : null
                                    }
                                    {
                                        checkCCCD9So.data ?
                                            <div className="w-frame wbaseItem-value w-row 51853e3c-2935-4065-b19e-9725c5ed1825" wrap="wrap" level={10} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24-sm col24 2f3350bb-d5a9-478c-9b67-f572c9842fdd" level={11} cateid={140}>
                                                    <div className="w-text wbaseItem-value 78d8689f-3ba9-44e4-89a7-443dd2385137" level={12} cateid={139}>Tổng dư nợ hiện tại:</div>
                                                    <div className="w-text wbaseItem-value e8d8c396-a2c5-456b-962f-c984f2a82934" level={12} cateid={139}>{checkCCCD9So.data.totalCurrentOutstandingBalance ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24-sm col24 2f3350bb-d5a9-478c-9b67-f572c9842fdd" level={11} cateid={140}>
                                                    <div className="w-text wbaseItem-value 78d8689f-3ba9-44e4-89a7-443dd2385137" level={12} cateid={139}>Nhóm nợ cao nhất hiện tại:</div>
                                                    <div className="w-text wbaseItem-value e8d8c396-a2c5-456b-962f-c984f2a82934" level={12} cateid={139}>{checkCCCD9So.data.highestDebtGroup ?? "_"}</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col12-xxl col12-xl col24-lg col24-md col24-sm col24 2f3350bb-d5a9-478c-9b67-f572c9842fdd" level={11} cateid={140}>
                                                    <div className="w-text wbaseItem-value 78d8689f-3ba9-44e4-89a7-443dd2385137" level={12} cateid={139}>Khoản thanh toán nợ vay hàng tháng tại tất cả các TCTD (Bao gồm JIVF):</div>
                                                    <div className="w-text wbaseItem-value e8d8c396-a2c5-456b-962f-c984f2a82934" level={12} cateid={139}>{checkCCCD9So.data.monthlyLoanPaymentCreditsFinance ? Ultis.money(checkCCCD9So.data.monthlyLoanPaymentCreditsFinance) : "_"} VND</div>
                                                </div>
                                            </div> : null
                                    }
                                </div>
                                {/* Lịch sử tín dụng tại JIVF */}
                                <div className="w-frame wbaseItem-value w-col 047c4154-f675-40f4-b283-74221b7e0778" level={8} cateid={140} style={{order: type === "cho-phe-duyet"? 11 : null}}>
                                    <div className="w-frame wbaseItem-value w-row bc133534-73cf-42a1-9a11-f9f09c2dcfd8" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 2beb96d0-cc2e-447e-9d43-8dd8a0cf9a1a" level={10} cateid={139}>Lịch sử tín dụng tại JIVF</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col 3a10f54a-c53c-4af1-aaed-debc01278f7a" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row a889f7d9-b6e1-43f0-9813-b8281b945d9c" level={10} cateid={140}>
                                            <div className="w-text wbaseItem-value c8a4a63f-30e1-4ae9-a777-5fb0785af9a8" level={11} cateid={139}>Người vay</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row c1a41d6b-4dd0-4e1b-b23e-fb7519dfe6d4" wrap="wrap" level={10} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col6-xl col24-md 53d2787c-954e-4358-9bf5-9d37fcc82094" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 5f375f9d-f7c0-4f6d-8d59-4bce2d68cd9b" level={12} cateid={139}>Số điện thoại:</div>
                                                <div className="w-text wbaseItem-value 75479bd9-e87e-4abc-bbcd-baa919719049" level={12} cateid={139}>{settingData.khachHang.dtDiDong ?? "_"}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col6-xl col24-md 7c7d04f2-3416-4649-959e-809675f819d3" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value 3e16d2f1-61d9-436d-9be1-c1a603a03597" level={12} cateid={139}>CCCD:</div>
                                                <div className="w-text wbaseItem-value ccca95d3-3ea5-4829-9161-05d996834ea0" level={12} cateid={139}>{settingData.khachHang.cmndHoChieu ?? "_"}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col6-xl col24-md d5de7c60-fa0c-46aa-b49f-5bce706dec37" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value fcf6385a-b4c1-470f-ad6d-e25b2aa714a2" level={12} cateid={139}>CCCD/CMND cũ 1:</div>
                                                <div className="w-text wbaseItem-value 9bb1c77f-d3f5-45b4-96af-f070bd3bf4fa" level={12} cateid={139}>{settingData.khachHang.cccdCu ?? "_"}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col12-lg col6-xl col24-md ef1f6ff4-392f-4cca-b7cc-252ab4563c76" level={11} cateid={140}>
                                                <div className="w-text wbaseItem-value c556e539-eb37-424a-8d76-f7942efa147c" level={12} cateid={139}>CCCD/CMND cũ 2:</div>
                                                <div className="w-frame wbaseItem-value w-row 42b72561-22ea-4ef0-acca-a6d5a4336c9e" level={12} cateid={140}>
                                                    <div className="w-textformfield wbaseItem-value w-row 4b87503e-04f4-403b-adbf-5cff52f2082d" level={13} cateid={85} placeholder="CCCD/CMND cũ 2">
                                                        <div className="wbaseItem-value 3ae44f05-a68c-4815-8569-e42a8d626125" level={14} cateid={86}>
                                                            <div className="textfield">
                                                                <input type='number' className='search-jivf-credit' placeholder="CCCD/CMND cũ 2" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button onClick={(ev) => { setJivfData([...jivfData]); }} type="button" className="w-button wbaseItem-value w-row 0d3eab63-5c40-4767-a5ad-5bd6748b32d6" level={13} cateid={29}>
                                                        <div className="w-svg wbaseItem-value fd9564cf-5c96-4125-a73f-246e109ad00f" level={14} cateid={115}>
                                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#FFFFFFFF" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-frame wbaseItem-value w-row' scroll="true">
                                        <table className="w-table wbaseItem-value 95fd530b-6371-4c8c-b44b-02c0de93a487" type={0} level={9} cateid={117}>
                                            <tbody>
                                                <tr className="table-row">
                                                    <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row dfce72b1-307a-4e29-85e8-b47de3b01745" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 563f1102-1a8f-403c-bf0f-ca0c3132b950" level={11} cateid={139}>STT</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 53fc5a70-0bb3-4879-8aec-f44737c2620d" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 3f5e5aa0-8b07-470b-80e7-6e3be4e420ec" level={11} cateid={139}>Số CCCD/CMND</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row c7f11ddf-79bc-4d6b-80b2-acb6136e21a8" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 14e248d0-6236-495b-95d4-6ea3bbd65c9b" level={11} cateid={139}>CIF</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row e1e67262-c688-49d4-a664-9ae195ceaef7" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 081f2ddd-4ef0-4cc7-92aa-ad5c1d31588f" level={11} cateid={139}>Tên khách hàng</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 1d839ec3-c365-447c-a557-36719f2443a4" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value b38474cd-0709-4ce1-8167-ce854183cf69" level={11} cateid={139}>Số HS bị từ chối</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x6" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 9f096396-a568-4000-9939-07c12716ccda" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 57412a50-ab1c-456a-bbd0-24ce66f9827d" level={11} cateid={139}>Số HS được phê duyệt</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row ef53bdaf-3ab8-4cc9-99b6-9144e146af19" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 075efecb-4ec7-4904-b74c-1acb1d40ea64" level={11} cateid={139}>Số tiền vay được phê duyệt gần nhất</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x8" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 0bfd5056-d038-46e7-beca-84faec713fa2" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value cde82a70-72b4-4674-b85a-0dfe54297625" level={11} cateid={139}>Số kỳ đã trả của&nbsp; khoản vay gần nhất</div>
                                                        </div>
                                                    </td>
                                                    <td id="1x9" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row b424f5f8-0739-4eed-ac0b-1377b7001eb0" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 2dbf8fdb-f9f3-429e-bcdf-ad76a49a55d2" level={11} cateid={139}>Số ngày trễ hạn hiện tại</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {(() => {
                                                    let searchJivFByCCCD = document.body.querySelector(".search-jivf-credit")?.value ?? "";
                                                    return jivfData.filter(e => searchJivFByCCCD.length ? e.cccd.includes(searchJivFByCCCD) : true).map((item, index) => {
                                                        return (<tr key={`historyItem-${index}`} className="table-row">
                                                            <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row d022342d-2902-4d7c-a203-24a133635294" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 3c990c17-7bd6-4a65-8f7f-b7646afcc328" level={11} cateid={139}>{index + 1}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 6673e31a-dd4c-4686-aef1-148b318d7097" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value ed626d83-509d-4f32-9808-ea3910f59e22" level={11} cateid={139}>{item.cccd ?? "_"}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row ffe520bb-7bdc-44cd-b70b-61ec27a76cd1" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value f9a00751-8090-43e5-8e2e-30d67bf77b1d" level={11} cateid={139}>{item.cif ?? "_"}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row e50e0ba9-c7f1-4f93-9e1f-c05dd2efe72e" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value d0d32763-932a-401d-9383-e8f00746cb99" level={11} cateid={139}>{item.customerName ?? "_"}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 81efabf8-2e9b-4ef0-b9b6-a9af6f86cdf8" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 71f60a54-a755-4878-a6e4-4c00b027b3e3" level={11} cateid={139}>{item.noOfAppRejected ?? "_"}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x6" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row c8f8ccd6-bdfb-4027-aab8-5fd84ce4dfbd" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value e8b83c08-b22e-4401-9a45-fa67099886e3" level={11} cateid={139}>{item.noOfAppApproved ?? "_"}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 8de33a02-b416-4203-be6a-30d15aa8d10c" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 7902348d-b133-4f06-b5b3-58e0ca18707b" level={11} cateid={139}>{item.approvedAmount ? Ultis.money(item.approvedAmount) : "_"}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x8" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 9e4915c0-7043-4930-940e-433587ea446e" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 3c3b3d2b-acb1-4b5e-8ac8-5b9684bfb88d" level={11} cateid={139}>{item.noOfPaidPeriod ?? "_"}</div>
                                                                </div>
                                                            </td>
                                                            <td id="2x9" className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 5dd807a5-8801-4357-9b23-7834b1116bfb" level={10} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 88c019e8-ee42-4834-843c-55fd89f48a8a" level={11} cateid={139}>{item.totalLatePaymentsDays ?? "_"}</div>
                                                                </div>
                                                            </td>
                                                        </tr>);
                                                    })
                                                })()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* DTI */}
                                <div className="w-frame wbaseItem-value w-row 897f7285-8862-4750-bdb0-090a1e2097f1" wrap="wrap" level={8} cateid={140} style={{order: type === "cho-phe-duyet"? 12 : null}}>
                                    <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col24-lg col24-md 355aa3b6-d0bc-442f-8ede-55b7e01174c1" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 64106ab5-a6d4-456e-b5cf-a5f89e355411" level={10} cateid={139}>DTI</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col24-lg col24-md 67f48268-a899-4a8e-aa99-ef1a375889e2" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 155986e1-f0d1-4467-93fb-16c1d29b547f" level={10} cateid={139}>DTI ban đầu:<br />
                                        </div>
                                        <div className="w-text wbaseItem-value a65c7533-4897-45ac-86f3-79fec2bdfde3" level={10} cateid={139}>{dtiData.dtiInitial?.toFixed(2) ?? "_"}</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col24-lg col24-md fa577cc1-6795-4dac-8b28-bd1fab5265db" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 02a8288d-3173-4588-b09f-fbf3c4cd99be" level={10} cateid={139}>DTI thực:</div>
                                        <div className="w-text wbaseItem-value a91998e4-de6e-4a29-9b02-3e552617535a" level={10} cateid={139}>{dtiData.dtiReality?.toFixed(2) ?? "_"}</div>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col24-lg col24-md 9022592d-4dce-448a-9d15-1d0a4796ff49" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 5d628e28-f7f1-489f-9039-a918eff5915e" level={10} cateid={139}>DTI điều chỉnh:</div>
                                        <div className="w-text wbaseItem-value 36877028-bfcf-49a0-beb3-25a9d1d3167f" level={10} cateid={139}>{dtiData.dtiAdjust?.toFixed(2) ?? "_"}</div>
                                    </div>
                                </div>
                                {/* Xếp hạng tín dụng */}
                                <div className="w-frame wbaseItem-value w-col c3caf50e-7294-4a07-9d13-2076f3fd136e" level={8} cateid={140} style={{order: type === "cho-phe-duyet"? 0 : null}}>
                                    <div className="w-frame wbaseItem-value w-row 2195c2a3-bc97-4dd1-8e6a-48d991819879" level={9} cateid={140}>
                                        <div className="w-text wbaseItem-value 3c7a5bd4-7126-4591-9661-0fe5fa088f13" level={10} cateid={139}>Xếp hạng tín dụng</div>
                                        <button type="button" onClick={searchScoringData} className="w-button wbaseItem-value w-row 5d3d516c-28d1-49d6-937f-53a446b1034e" level={10} cateid={29}>
                                            <div className="w-svg wbaseItem-value 5a2bb8c9-f9b5-4188-b69a-00808c55ce8f" level={11} cateid={115}>
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#FFFFFFFF" />
                                                </svg>
                                            </div>
                                            <div className="w-text wbaseItem-value e1b4eeef-ca86-4e53-9e1a-3a28c303fff9" level={11} cateid={139}>Xem điểm xếp hạng tín dụng</div>
                                        </button>
                                    </div>
                                    <div className="w-frame wbaseItem-value w-col dd0f005d-5d9c-4738-a4d6-19a2bca42203" level={9} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row 390a300a-54ad-42d5-8e30-901db61ed335" level={10} cateid={140}>
                                            <div className="w-text wbaseItem-value e2118044-7c17-4e96-841a-8acf4f20a207" level={11} cateid={139}>Điểm tín dụng:</div>
                                            <div className="w-text wbaseItem-value 1a5dc591-e58a-4e47-af20-c8ef5c008f53" level={11} cateid={139}>{scoringData.scoringPoint ?? "_"}</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row e4019b27-82bd-4755-944c-b0a12d46762e" level={10} cateid={140}>
                                            <div className="w-text wbaseItem-value a41b716f-2483-48e4-a80d-a2a009235c05" level={11} cateid={139}>Xếp hạng:</div>
                                            <div className="w-text wbaseItem-value d86ff57b-13a8-4e9c-bd92-ad0e29c3db37" level={11} cateid={139}>{scoringData.scoringRank ?? "_"}</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 7037ce16-0b1d-42cc-b993-2fd9a3c98c7c" level={10} cateid={140}>
                                            <div className="w-text wbaseItem-value 857eade4-2110-444a-8a0e-cf9ff36cdadf" level={11} cateid={139}>Kết quả:</div>
                                            <div className="w-text wbaseItem-value a9f79cde-1446-4b29-97f2-0c2aa2ebaf3f" level={11} cateid={139}>{scoringData.scoringValue ?? "_"}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* Thẩm định qua điện thoại */}
                                {
                                    type === "bo-sung" ? 
                                        <div key="group-thẩm-định-qua-điện-thoại" className="w-frame wbaseItem-value w-col d842058e-63f6-478c-8a33-d1679132a675" level={8} cateid={140}>
                                            <div className="w-text wbaseItem-value b02b3d7b-a6a0-402d-a223-00bc2989dc26" level={9} cateid={139}>Thẩm định qua điện thoại</div>
                                            <button onClick={() => { onShowQuestionList(true) }} type="button" className="w-button wbaseItem-value w-row 752f2e87-e95a-46c0-bdf0-a878dd0ea645" level={9} cateid={29}>
                                                <div className="w-text wbaseItem-value 10df3929-063e-4db9-80df-f2596bc04e65" level={10} cateid={139}>Danh sách câu hỏi</div>
                                                <div className="w-svg wbaseItem-value 911854c2-389e-43bf-a628-08b3a9fa1813" level={10} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z" fill="#28282999" />
                                                    </svg>
                                                </div>
                                            </button>
                                            <div className="w-frame wbaseItem-value w-col 677d425c-5428-4eec-912c-6eb4060c6ae9" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-row 17de6baf-efd9-41a3-a915-5a78b2e792ad" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 878a44e9-0c68-4c8c-b9c3-880f33d72e03" level={11} cateid={139}>Ghi chú<br />
                                                    </div>
                                                    <div className="w-text wbaseItem-value 6c3d4930-b267-4320-849e-8f089b811351" level={11} cateid={139}>*</div>
                                                </div>
                                                <div className="w-textformfield wbaseItem-value w-row c6906a0e-7d25-4dd9-b8be-cdb46f959477" level={10} cateid={85}>
                                                    <div className="wbaseItem-value 4c9f25a1-c38e-45c1-b531-5ca1936766d8" level={11} cateid={86}>
                                                        <div className="textfield">
                                                            <textarea maxLength={4000} cols="40" rows="5" {...register("ghiChuThamDinhQuaDt")} placeholder="Thêm ghi chú" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     : null
                                }
                                {/* Ý kiến phê duyệt */}
                                {
                                    type === "cho-phe-duyet" ? [
                                        <div className="w-frame wbaseItem-value w-col ea2e2af6-66f8-48ed-a749-95a301a10cec" level={8} cateid={140} style={{order: 1}}>
                                            <div className="w-frame wbaseItem-value w-row b7f29e08-54aa-4320-9ee2-387e6be1f0f5" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value 0d97424f-e459-4d4e-bbb4-7dca9f7c7995" level={10} cateid={139}>Thẩm định qua điện thoại</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col24-md col6-xl col6-lg 8981dfbf-52de-4494-92db-82646cd6a1d2" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value ecbd486f-69a3-40c7-8fef-d6051b4f64be" level={10} cateid={139}>Ghi chú:</div>
                                                <div className="w-text wbaseItem-value eaeee7ed-b9e9-44a4-aa0a-96167bde3db0" level={10} cateid={139}>{settingData.loanApplication.ghiChuThamDinhQuaDt ?? "_"}</div>
                                            </div>
                                        </div>,
                                        <div key={'group-y-kien-phe-duyet'} className="w-frame wbaseItem-value w-col 5dda886c-dd2c-47fa-861d-305eccb7c0de" level={8} cateid={140} style={{order: 3}}>
                                            <div className="w-frame wbaseItem-value w-row 004450c9-afde-4635-b862-510962cad859" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value bcd281fa-2d86-4926-bdaa-2f871acbddd8" level={10} cateid={139}>Ý kiến phê duyệt</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col 1e3dcb53-8747-4264-8fee-8540875db3a6" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-row 102e0f37-8bcb-4eaf-a39a-7471ac62779a" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 3000bbc7-aa48-4dc7-befa-650cd0bacf9f" level={11} cateid={139}>Sản phẩm chính</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col 5e80df4c-0823-4bee-8874-a1aed218acdc" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row f4d6e11b-0a45-47fc-a3b3-d1dca7ddf2f9" level={11} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-row a0a7dd5d-0e7f-4e57-8487-a0c40c2c8d86" level={12} cateid={140}>
                                                            <div className="w-text wbaseItem-value 15e60717-87e1-4e90-9e24-31e5c7aabf94" level={13} cateid={139}>Tổng số tiền vay:</div>
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-row d8039f13-0115-4bd1-81b7-aa275af60789" level={12} cateid={140}>
                                                            <div className="w-text wbaseItem-value 3ec07732-e17b-48af-adf9-221c06c6a5ab" level={13} cateid={139}>{Ultis.money(settingData.loanApplication.soTienVayGoc + settingData.loanApplication.soTienBaoHiem)}VND</div>
                                                        </div>
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-row 61e29321-5a70-4768-a612-7adb5bec5607" level={11} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-row 27969057-7d41-4bea-83c8-f4db0345ba68" level={12} cateid={140}>
                                                            <div className="w-text wbaseItem-value 4e7d7e4e-c969-44c6-873d-19305807136e" level={13} cateid={139}>Kỳ hạn vay:</div>
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-row 2abc06a1-5681-46e8-90e3-35e6cf792ee8" level={12} cateid={140}>
                                                            <div className="w-text wbaseItem-value f054b91f-5964-400d-b432-daad340b543c" level={13} cateid={139}>{settingData.loanApplication.kyHanVay ?? "_"} tháng</div>
                                                        </div>
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-row 41ef055d-5f0d-463e-9a35-e2d8e674fddd" wrap="wrap" level={11} cateid={140}>
                                                        <div className='w-frame wbaseItem-value w-col col- col6-xxl col12-md col6-xl col12-lg 4ddadae7-7b23-46b6-b69b-0843e96af253'>
                                                            <div className="w-text wbaseItem-value 655d8182-d062-4a37-ac11-dd9303f15b65" level={13} cateid={139}>Kỳ hạn giai đoạn 1:</div>
                                                            <div className="w-frame wbaseItem-value w-row 163cc40a-06cd-4734-9062-781ebfe7e3f1" level={12} cateid={140}>
                                                                <div className="w-text wbaseItem-value 6904b25f-7a62-4b71-9f0f-d1ae59a91717" level={13} cateid={139}>{settingData.loanApplication.kyHanGiaiDoan1?? "_"} tháng</div>
                                                            </div>
                                                        </div>
                                                        <div className='w-frame wbaseItem-value w-col col- col6-xxl col12-md col6-xl col12-lg 4ddadae7-7b23-46b6-b69b-0843e96af253'>
                                                            <div className="w-text wbaseItem-value 655d8182-d062-4a37-ac11-dd9303f15b65" level={13} cateid={139}>Lãi suất giai đoạn 1:</div>
                                                            <div className="w-frame wbaseItem-value w-row 163cc40a-06cd-4734-9062-781ebfe7e3f1" level={12} cateid={140}>
                                                                <div className="w-text wbaseItem-value 6904b25f-7a62-4b71-9f0f-d1ae59a91717" level={13} cateid={139}>{settingData.loanApplication.laiSuatGiaiDoan1 ? `${settingData.loanApplication.laiSuatGiaiDoan1.toFixed(1)}`.replace(".0", "") : "_"}%</div>
                                                            </div>
                                                        </div>
                                                        <div className='w-frame wbaseItem-value w-col col- col6-xxl col12-md col6-xl col12-lg 4ddadae7-7b23-46b6-b69b-0843e96af253'>
                                                            <div className="w-text wbaseItem-value 655d8182-d062-4a37-ac11-dd9303f15b65" level={13} cateid={139}>Kỳ hạn giai đoạn 2:</div>
                                                            <div className="w-frame wbaseItem-value w-row 163cc40a-06cd-4734-9062-781ebfe7e3f1" level={12} cateid={140}>
                                                                <div className="w-text wbaseItem-value 6904b25f-7a62-4b71-9f0f-d1ae59a91717" level={13} cateid={139}>{settingData.loanApplication.kyHanGiaiDoan2?? "_"} tháng</div>
                                                            </div>
                                                        </div>
                                                        <div className='w-frame wbaseItem-value w-col col- col6-xxl col12-md col6-xl col12-lg 4ddadae7-7b23-46b6-b69b-0843e96af253'>
                                                            <div className="w-text wbaseItem-value 655d8182-d062-4a37-ac11-dd9303f15b65" level={13} cateid={139}>Lãi suất giai đoạn 2:</div>
                                                            <div className="w-frame wbaseItem-value w-row 163cc40a-06cd-4734-9062-781ebfe7e3f1" level={12} cateid={140}>
                                                                <div className="w-text wbaseItem-value 6904b25f-7a62-4b71-9f0f-d1ae59a91717" level={13} cateid={139}>{settingData.loanApplication.laiSuatGiaiDoan2 ? `${settingData.loanApplication.laiSuatGiaiDoan2.toFixed(1)}`.replace(".0", "") : "_"}%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col a7ba96e0-5c68-4763-bf07-4a436224d84c" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-row 9af446e4-988c-4b34-8b3b-f84067c04ad0" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 8993c936-5583-4d61-b0b3-1007bc3e9de7" level={11} cateid={139}>Sản phẩm bán kèm</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col 0926b509-f107-47b6-b51b-11a4e67247af" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 3333052a-56ee-4d45-9e81-9f626598724c" level={11} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-row cc495a3e-fcf3-42a8-9ea6-fca93a9126e9" level={12} cateid={140}>
                                                            <div className="w-text wbaseItem-value a7dd4af5-4cdd-4f02-8976-d32835070124" level={13} cateid={139}>Loại thẻ:</div>
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-row 272b17c5-27fc-42f1-aa4b-204d0bf645d3" level={12} cateid={140}>
                                                            <div className="w-text wbaseItem-value d48b27f1-f70c-469a-ba12-4b8ac7ebad89" level={13} cateid={139}>{settingData.loanApplication.loaiTheDangKy ?? "_"}</div>
                                                        </div>
                                                    </div>
                                                    {
                                                        settingData.loanApplication.maLoaiTheDangKy === "CASH_CARD" ? null : [
                                                            <div key={"spbk-hanmuc"} className="w-frame wbaseItem-value w-row 26d2cbbc-15fa-4a66-810d-6f6e83ff9c36" level={11} cateid={140}>
                                                                <div className="w-frame wbaseItem-value w-row e3b204df-9d82-4dc9-a230-bd46f5aec686" level={12} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 0d6466d3-a492-479e-8763-f830a0d3d1fc" level={13} cateid={139}>Hạn mức:</div>
                                                                </div>
                                                                <div className="w-frame wbaseItem-value w-row ccfc1ab3-7ffd-4db7-9bb7-f93cd03e2a99" level={12} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 81a473fa-a865-49e7-80d2-0cbe1f77fea4" level={13} cateid={139}>{settingData.loanApplication.hanMuc ? Ultis.money(settingData.loanApplication.hanMuc) : "_"}VND</div> {/* ????? Ducnm54 loại thẻ là tiền mặt thì có hiển thị hạn mức và DTI thẻ ko */}
                                                                </div>
                                                            </div>,
                                                            <div key={"spbk-dtithe"} className="w-frame wbaseItem-value w-row da725d20-0dfc-45ba-907f-84c354a49b16" level={11} cateid={140}>
                                                                <div className="w-frame wbaseItem-value w-row a94419e0-d95f-48ab-b4e9-f58adb646d25" level={12} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 58902c98-608e-4ed1-9087-0a02f50a9ecb" level={13} cateid={139}>DTI thẻ:</div>
                                                                </div>
                                                                <div className="w-frame wbaseItem-value w-row 27f24e74-bc32-46ab-99b0-b19670253107" level={12} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 17eb18c6-fadf-4863-b27e-b1f3546e88bb" level={13} cateid={139}>xx</div>
                                                                </div>
                                                            </div>
                                                        ]
                                                    }

                                                </div>
                                            </div>
                                            <div className={"w-frame wbaseItem-value w-row 77b31004-2564-424b-a193-0650da5e97a1 " + (errors.checkYKienPheDuyet && "helper-text")} helper-text={errors.checkYKienPheDuyet && "Vui lòng chọn ý kiến"} level={9} cateid={128}>
                                                <div className="w-frame wbaseItem-value w-row 2c6f4012-e209-4ae2-909b-caa39718bd2c" level={10} cateid={140}>
                                                    <label className="w-radio-btn wbaseItem-value f75d2770-a761-4010-85a3-4221cd1dd6ff" level={11} cateid={90}>
                                                        <input type="radio" name="checkYKienPheDuyet" {...register("checkYKienPheDuyet", {required: true})} value={"Yêu cầu bổ sung"} />
                                                        <span className="checkmark" />
                                                    </label>
                                                    <div className="w-text wbaseItem-value cc8ae1bf-00e2-463b-98ab-26269e328e6b" level={11} cateid={139}>Yêu cầu bổ sung
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-row 9d1a8068-df58-48c6-91c2-30cd311ab1b6" level={10} cateid={140}>
                                                    <label className="w-radio-btn wbaseItem-value 9ba557f2-c9c9-4463-88ae-697dc0449182" level={11} cateid={90}>
                                                        <input type="radio" name="checkYKienPheDuyet" {...register("checkYKienPheDuyet", {required: true})} value={"Đồng ý"} />
                                                        <span className="checkmark" />
                                                    </label>
                                                    <div className="w-text wbaseItem-value 8ae244c3-679e-4e21-813f-02d24d6ca854" level={11} cateid={139}>Đồng ý</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-row a7f19407-9a7f-4e4f-867c-8dc93ff8c7c2" level={10} cateid={140}>
                                                    <label className="w-radio-btn wbaseItem-value 928706c6-c970-4847-855d-eb0870a77668" level={11} cateid={90}>
                                                        <input type="radio" name="checkYKienPheDuyet" {...register("checkYKienPheDuyet", {required: true})} value={"Từ chối"} />
                                                        <span className="checkmark" />
                                                    </label>
                                                    <div className="w-text wbaseItem-value d95d483b-86b0-4ed1-b12d-ec3231c1fec6" level={11} cateid={139}>Từ chối</div>
                                                </div>
                                            </div>
                                            <div className="w-textformfield wbaseItem-value w-row text-area-note-input" level={9} cateid={85} >
                                                <div className="wbaseItem-value" level={10} cateid={86}>
                                                    <div className="textfield">
                                                        <textarea maxLength={4000} defaultValue={settingData.loanApplication.ghiChuYKienPheDuyet ?? ""} {...register("ghiChuYKienPheDuyet")} cols="40" rows="5" placeholder="Nhập yêu cầu bổ sung" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ] : []
                                }
                                {/* Ý kiến thẩm định */}
                                <div className="w-frame wbaseItem-value w-col a9a3bd48-0560-49bf-9eef-31e8eb21ae81" level={8} cateid={140} style={{order: type === "cho-phe-duyet"? 2 : null}}>
                                    <div className="w-text wbaseItem-value 510b15bf-f3f3-497b-977d-3858c06c2cf5" level={9} cateid={139}>Ý kiến thẩm định</div>
                                    {
                                        type === "cho-phe-duyet" ?
                                            <div className="w-frame wbaseItem-value w-row 4a0e4928-7520-47fc-afb0-5d1e30ecfad7" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col24-md col6-xl col6-lg 9c15c20a-a104-4381-ac6d-57e62ab1daf9" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 485aaebc-ac05-45a7-9426-bcabc74a5a89" level={11} cateid={139}>Kết quả:
                                                    </div>
                                                    <div className="w-text wbaseItem-value 2151715e-dd25-407f-94af-8488f4f2f2d0" level={11} cateid={139}>{settingData.loanApplication.ykienThamDinh ?? "_"}</div> {/* ?????? Ducnm54 cần hỏi lại */}
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col24-md col6-xl col6-lg d018aed3-664b-4ca7-917f-4f19c465604b" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 39614861-a4e3-4b68-8811-9736614c150d" level={11} cateid={139}>Ý kiến thẩm định:
                                                    </div>
                                                    <div className="w-text wbaseItem-value 47a2bf11-a539-4cc4-a416-eb64c4ebc156" level={11} cateid={139}>{settingData.loanApplication.ghiChuYKienThamDinh ?? "_"}</div>
                                                </div>
                                            </div> : [
                                                <div key={"group-y-kien-tham-dinh-0"} className="w-frame wbaseItem-value w-col b8596c9b-8e18-4a36-8e57-beb3ed04279b" level={9} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row ae782baf-e50b-43df-a6d2-a6abbc2eafaa" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value ffbf1839-ede2-4dd1-8a4f-7d444304d106" level={11} cateid={139}>Tổng số tiền vay:
                                                        </div>
                                                        <div className="w-text wbaseItem-value 6cdb9c12-b87d-4076-bad3-ccd679bea6dc" level={11} cateid={139}>_VND</div>
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-row e86c6536-d326-4220-bbbc-8b99e4b0375a" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 0803312e-ce75-4791-a21b-b090e36e9166" level={11} cateid={139}>Kỳ hạn vay:</div>
                                                        <div className="w-text wbaseItem-value fb962dd7-9e93-4f17-b795-f20925cdf4be" level={11} cateid={139}>{settingData.loanApplication.kyHanVay} tháng</div>
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-row ec8122d5-4aa3-4d8c-ad5a-ca78b2203772" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 9f4a1eb0-db25-4975-91f2-2cdd82b643e0" level={11} cateid={139}>Lãi suất:
                                                        </div>
                                                        <div className="w-text wbaseItem-value 0c38ef96-d11b-4e8b-a751-b97a5db6e1c3" level={11} cateid={139}>{`${settingData.loanApplication.phanTramLaiSuat?.toFixed(1) ?? "0"}`.replace(".0", "")}%<br />
                                                        </div>
                                                    </div>
                                                </div>,
                                                <div key={"group-y-kien-tham-dinh-1"} className={"w-frame wbaseItem-value w-row be06dc60-ccc9-4a4c-922c-74f5fd6d3d3d " + (errors.ykienthamdinh && "helper-text")} helper-text={errors.ykienthamdinh && "Vui lòng tích chọn ý kiến"} level={9} cateid={128}>
                                                    <div className="w-frame wbaseItem-value w-row 53716494-cfc5-4807-8f96-e276e8c9fc9e" level={10} cateid={140}>
                                                        <label className="w-radio-btn wbaseItem-value 3b4172b2-b894-4f46-bc83-e4949a995666" level={11} cateid={90}>
                                                            <input type="radio" name="ykienthamdinh" {...register("ykienthamdinh", { required: true })} value={"Yêu cầu bổ sung"} />
                                                            <span className="checkmark">
                                                            </span>
                                                        </label>
                                                        <div className="w-text wbaseItem-value c7fc8796-b8cb-4bce-9ea1-f1f96afa5cc8" level={11} cateid={139}>Yêu cầu bổ sung
                                                        </div>
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-row e90d5c97-6631-4e25-ae6c-d3ba1b5c62e3" level={10} cateid={140}>
                                                        <label className="w-radio-btn wbaseItem-value ef641e06-5750-4013-bf2a-186a81729a40" level={11} cateid={90}>
                                                            <input type="radio" name="ykienthamdinh" {...register("ykienthamdinh", { required: true })} value={"Đồng ý"} />
                                                            <span className="checkmark">
                                                            </span>
                                                        </label>
                                                        <div className="w-text wbaseItem-value 53b2dae4-03dc-4b17-ad8a-cb0fe459d9a4" level={11} cateid={139}>Đồng ý</div>
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-row 972c6cc3-5d4c-4e58-8a04-a217d00bd780" level={10} cateid={140}>
                                                        <label className="w-radio-btn wbaseItem-value 38338d3e-1b13-400b-a70a-a490b449dd0f" level={11} cateid={90}>
                                                            <input type="radio" name="ykienthamdinh" {...register("ykienthamdinh", { required: true })} value={"Từ chối"} />
                                                            <span className="checkmark">
                                                            </span>
                                                        </label>
                                                        <div className="w-text wbaseItem-value fc46a355-5a98-49c4-80ef-a9fc829f9682" level={11} cateid={139}>Từ chối</div>
                                                    </div>
                                                </div>,
                                                <div key={"group-y-kien-tham-dinh-2"} className="w-textformfield wbaseItem-value w-row 9e7e5e53-0a52-4358-9d20-806288c7000f" level={9} cateid={85} >
                                                    <div className="wbaseItem-value d82a3e0b-3b8a-4a14-b4b3-eb3b2fb6fd1b" level={10} cateid={86}>
                                                        <div className="textfield">
                                                            <textarea maxLength={4000} defaultValue={settingData.loanApplication.ghiChuYKienThamDinh ?? ""} {...register("ghiChuYKienThamDinh")} cols="40" rows="5" placeholder="Nhập yêu cầu bổ sung" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ]
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-frame wbaseItem-value w-row 9bde77cb-d920-496b-8d31-3e58bc7ecf56" level={3} cateid={140}>
                <button type="button" className="w-button wbaseItem-value w-row b0047e2b-bb29-4c6b-a2be-97e134c2127f" level={4} cateid={29}>
                    <div className="w-text wbaseItem-value 201898c0-f586-4f93-a501-6567b2711dee" level={5} cateid={139}>Thoát</div>
                </button>
                <div className="w-frame wbaseItem-value w-row bd1f38cd-46f6-420b-9bd6-cd4f387564b1" level={4} cateid={140}>
                    <button type="button" onClick={() => onSend()} className="w-button wbaseItem-value w-row 14802b97-f9f3-4ec1-93fc-00ed5230302f" level={5} cateid={29}>
                        <div className="w-svg wbaseItem-value 817f17f8-c5a9-4782-98ce-22dbb1d70d52" level={6} cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.1665 2H6.49997C5.83694 2 5.20106 2.26339 4.73223 2.73223C4.26339 3.20106 4 3.83694 4 4.49997V21.5831C3.99996 21.6577 4.01993 21.7309 4.05783 21.7951C4.09572 21.8593 4.15015 21.9122 4.21545 21.9482C4.28074 21.9842 4.35449 22.002 4.42902 21.9998C4.50355 21.9976 4.57612 21.9754 4.63916 21.9356L12.3332 17.0765L20.0273 21.9356C20.0938 21.9779 20.171 22.0002 20.2498 21.9998C20.3603 21.9998 20.4663 21.9559 20.5445 21.8777C20.6226 21.7996 20.6665 21.6936 20.6665 21.5831V4.49997C20.6665 3.83694 20.4031 3.20106 19.9343 2.73223C19.4654 2.26339 18.8295 2 18.1665 2V2Z" fill="#28282999" />
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value bc9164e8-69f3-406d-8f54-13b1e3e172a4" level={6} cateid={139}>Lưu</div>
                    </button>
                    <button type="submit" className="w-button wbaseItem-value w-row 5ab714ad-626b-4865-84c3-85965c4c6176" level={5} cateid={29}>
                        <div className="w-svg wbaseItem-value 4d8afd4d-9a55-4824-95ba-cb80a1639f10" level={6} cateid={115}>
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.38 22.0033C14.2031 22.0033 14.0296 21.954 13.879 21.861C13.7284 21.7681 13.6066 21.6351 13.5272 21.4769L10.7005 15.8207C10.6242 15.6678 10.5904 15.4973 10.6024 15.3268C10.6143 15.1564 10.6718 14.9923 10.7687 14.8515L15.3329 8.66893L9.15029 13.2313C9.00974 13.3282 8.84576 13.3857 8.67546 13.3977C8.50515 13.4097 8.33474 13.3758 8.18199 13.2995L2.52401 10.4728C2.35846 10.3894 2.22085 10.2594 2.12803 10.099C2.03521 9.93849 1.99122 9.75443 2.00145 9.56933C2.01168 9.38422 2.07569 9.20612 2.18562 9.05685C2.29556 8.90758 2.44665 8.79362 2.62038 8.72894L20.7181 2.05901C20.8888 1.99593 21.074 1.98311 21.2518 2.02206C21.4296 2.06102 21.5925 2.15012 21.7212 2.27882C21.8499 2.40751 21.939 2.5704 21.9779 2.74818C22.0169 2.92596 22.0041 3.11118 21.941 3.28189L15.272 21.3805C15.2078 21.5549 15.0939 21.7066 14.9444 21.8169C14.795 21.9273 14.6165 21.9915 14.431 22.0015L14.38 22.0033Z" fill="#FFFFFFFF" />
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value 6da0ae73-dbf1-4ee1-aa60-23d2b62ff791" level={6} cateid={139}>Gửi</div>
                    </button>
                    <button type="button" onClick={() => { navigate(`/admin/ho-so-cho-xu-ly/bo-sung-tham-dinh?${subId}`) }} className="w-button wbaseItem-value w-row 5ab714ad-626b-4865-84c3-85965c4c6176" level={5} cateid={29} style={{ display: creditResult === false && type === "xu-ly" ? "flex" : "none" }}>
                        <div className="w-text wbaseItem-value 6da0ae73-dbf1-4ee1-aa60-23d2b62ff791" level={6} cateid={139}>Thẩm định qua điện thoại</div>
                    </button>
                </div>
            </div>
        </form> : <div></div>
    )
}

