import { NavLink, useNavigate } from 'react-router-dom';
import './Login.css';
import React from 'react';
import { useState } from 'react';
import { handleLogin } from '../../../redux/middleware/api/Login/LoginDA';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { WPopup, PopupType } from '../../../components/Popup/Popup';
import '../../../core/handleData.js';
export default function Login() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({ username: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [loginFalse, setLoginFalse] = useState(false);
    const [errorMess, setErrorMess] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmitLogin = async (event) => {
        event.preventDefault();

        const usernameValue = formData.username;
        const passwordValue = formData.password;

        if (usernameValue?.length && passwordValue?.length > 0) {
            setLoading(true);
            let response = await handleLogin({
                "username": usernameValue,
                "password": passwordValue,
                "workSpace": "WEB_APP"
            });
            if (response?.token) {
                setLoading(false);
                localStorage.setItem("userItem", JSON.stringify(response));
                localStorage.setItem("token", response.token);
                navigate("/admin/dashboard-sc");
            } else {
                setLoginFalse(true);
                setErrorMess(response);
            }

            setLoading(false);
        }
    };



    useEffect(() => {
        localStorage.clear();
    }, []);


    return (
        <React.Fragment>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            <div className="w-frame wbaseItem-value w-row xxl 8ffe3586-77ce-4b78-9059-e54e63899570 w-page" wrap="nowrap" level={1} cateid={140}>
                {
                    loginFalse &&
                    <WPopup
                        type={PopupType.WARNING}
                        title={errorMess?.length > 0 ? errorMess : <span>Tên đăng nhập hoặc mật khẩu <br /> không đúng!</span>}
                        confirmTitle='Đăng nhập lại'
                        confirmAction={() => setLoginFalse(false)}
                        cancelAction={() => setLoginFalse(false)}
                    />
                }
                <div className="w-svg wbaseItem-value 58805328-bf6c-460d-a78d-899e4f4c792f" level={2} cateid={115}>
                    <svg width={695} height={1080} viewBox="0 0 695 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_5896_116012)">
                            <rect width={695} height={1080} fill="#EDC89EFF" />
                            <path d="M722.954 580H0V1080H722.954V580Z" fill="#EDC89EFF" />
                            <path d="M176.433 692.498H47.8677V872.243H176.433V692.498Z" fill="url(#paint0_linear_5896_116012)" />
                            <path d="M647.132 761.01H518.566V940.755H647.132V761.01Z" fill="url(#paint1_linear_5896_116012)" />
                            <path d="M468.545 714.9H253.288V894.645H468.545V714.9Z" fill="url(#paint2_linear_5896_116012)" />
                            <path d="M468.545 637.902H253.288V691.661H468.545V637.902Z" fill="url(#paint3_linear_5896_116012)" />
                            <mask id="mask0_5896_116012" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x={0} y={580} width={695} height={500}>
                                <path d="M695 580H0V1080H695V580Z" fill="#FFFFFFFF" />
                            </mask>
                            <g mask="url(#mask0_5896_116012)">
                                <path d="M705.28 844.276C678.299 826.21 649.456 852.289 648.588 886.413C622.526 888.101 608.973 917.913 610.016 943.225C570.922 951.662 551.636 1034.52 561.54 1080H682.991C682.991 1080 706.794 1044.9 691.852 1001.03C726.428 969.662 715.83 928.037 708.532 913.412C725.559 891.663 724.874 857.397 705.28 844.276Z" fill="url(#paint4_linear_5896_116012)" />
                                <path d="M622.163 1066.18L622.135 1066.18L590.89 954.373C591.393 953.87 591.903 953.382 592.416 952.907L623.235 1063.19L645.607 1001.04L619.071 906.075C619.504 905.352 619.949 904.641 620.412 903.946L646.697 998.008L668.069 938.625L650.105 874.338C650.382 873.129 650.696 871.938 651.044 870.767L669.16 935.597L686.711 886.833L674.025 841.433C674.593 841.16 675.166 840.9 675.746 840.662L687.802 883.805L702.616 842.644C703.162 842.951 703.708 843.277 704.254 843.619L684.249 899.2L720.374 883.488C720.297 884.246 720.208 885.005 720.101 885.764L683.329 901.758L666.799 947.684L713.506 927.37C713.665 928.01 713.819 928.663 713.967 929.326L665.879 950.241L642.938 1013.98L703.717 987.544C702.99 988.602 702.224 989.655 701.418 990.701L642.018 1016.54L621.423 1073.76L696.163 1041.25C696.075 1042.02 695.98 1042.78 695.875 1043.53L620.502 1076.31L619.176 1080H617.187L622.163 1066.18Z" fill="#293D36FF" />
                                <path d="M772.281 948.45C753.232 921.036 717.579 934.018 705.274 965.467C680.427 956.816 657.759 979.272 650.203 1003.26C610.941 995.786 565.063 1065.41 558.971 1111.66L672.114 1159.3C672.114 1159.3 706.112 1135.95 706.971 1089.21C749.747 1073.56 753.894 1030.62 752.022 1014.13C775.212 1000.55 786.117 968.359 772.281 948.45Z" fill="url(#paint5_linear_5896_116012)" />
                                <path d="M620.106 1122.57L620.077 1122.56L628.633 1006.14C629.272 1005.87 629.91 1005.62 630.549 1005.38L622.11 1120.21L663.888 1071.08L671.155 972.205C671.802 971.701 672.456 971.214 673.122 970.747L665.924 1068.68L705.838 1021.75L710.757 954.811C711.424 953.793 712.117 952.807 712.834 951.853L707.873 1019.35L740.65 980.81L744.124 933.539C744.745 933.508 745.366 933.49 745.986 933.495L742.686 978.416L770.352 945.882C770.758 946.382 771.156 946.9 771.548 947.433L734.19 991.365L773.136 990.898C772.809 991.574 772.47 992.246 772.115 992.911L732.471 993.386L701.602 1029.69L751.957 1029.08C751.89 1029.74 751.813 1030.41 751.728 1031.09L699.884 1031.71L657.042 1082.09L722.568 1081.3C721.533 1082 720.466 1082.68 719.362 1083.34L655.323 1084.11L616.862 1129.34L697.44 1128.37C697.099 1129.05 696.753 1129.72 696.403 1130.38L615.144 1131.36L612.666 1134.27L610.813 1133.49L620.106 1122.57Z" fill="#293D36FF" />
                                <path d="M-10.2794 844.276C16.7015 826.21 45.544 852.289 46.4123 886.413C72.4747 888.101 86.0272 917.913 84.9847 943.225C124.078 951.662 143.365 1034.52 133.461 1080H12.0098C12.0098 1080 -11.7935 1044.9 3.14804 1001.03C-31.4277 969.662 -20.8295 928.037 -13.532 913.412C-30.5585 891.663 -29.8737 857.397 -10.2794 844.276Z" fill="url(#paint6_linear_5896_116012)" />
                                <path d="M72.8373 1066.18L72.8651 1066.18L104.11 954.373C103.607 953.87 103.099 953.382 102.584 952.907L71.7643 1063.19L49.3917 1001.04L75.9278 906.074C75.495 905.351 75.0493 904.64 74.5869 903.945L48.302 998.007L26.9293 938.624L44.8937 874.337C44.6166 873.128 44.3025 871.937 43.955 870.766L25.8386 935.596L8.28851 886.831L20.9755 841.431C20.4075 841.158 19.8348 840.898 19.2547 840.66L7.19875 883.803L-7.6168 842.642C-8.16353 842.949 -8.70841 843.275 -9.25422 843.617L10.7507 899.198L-25.3745 883.486C-25.2976 884.244 -25.2086 885.003 -25.1011 885.762L11.6708 901.756L28.2007 947.682L-18.5061 927.368C-18.6654 928.008 -18.8193 928.661 -18.9675 929.324L29.1218 950.239L52.0624 1013.98L-8.71675 987.542C-7.98932 988.6 -7.22297 989.653 -6.41769 990.699L52.9826 1016.54L73.5777 1073.76L-1.16256 1041.25C-1.07453 1042.02 -0.979086 1042.78 -0.874372 1043.53L74.4988 1076.31L75.8249 1080H77.8135L72.8373 1066.18Z" fill="#293D36FF" />
                                <path d="M-77.2811 948.45C-58.2316 921.036 -22.5781 934.018 -10.2738 965.467C14.5738 956.816 37.2419 979.272 44.797 1003.26C84.059 995.786 129.937 1065.41 136.029 1111.66L22.886 1159.31C22.886 1159.31 -11.1115 1135.95 -11.9706 1089.21C-54.7464 1073.56 -58.8942 1030.62 -57.0223 1014.13C-80.2112 1000.55 -91.1162 968.359 -77.2811 948.45Z" fill="url(#paint7_linear_5896_116012)" />
                                <path d="M74.8945 1122.57L74.9232 1122.56L66.3673 1006.14C65.7288 1005.87 65.0903 1005.62 64.4509 1005.38L72.8901 1120.21L31.1113 1071.08L23.8453 972.205C23.1985 971.701 22.5443 971.214 21.8789 970.747L29.0764 1068.68L-10.837 1021.75L-15.7567 954.811C-16.423 953.793 -17.1161 952.807 -17.8334 951.853L-12.8729 1019.35L-45.6491 980.81L-49.1232 933.539C-49.744 933.508 -50.3658 933.49 -50.9858 933.495L-47.685 978.416L-75.3515 945.882C-75.7574 946.382 -76.1559 946.9 -76.5479 947.433L-39.1893 991.365L-78.1353 990.898C-77.8081 991.574 -77.4699 992.246 -77.1141 992.911L-37.4703 993.386L-6.60122 1029.69L-56.9563 1029.08C-56.8896 1029.74 -56.8127 1030.41 -56.7274 1031.09L-4.88318 1031.71L37.9585 1082.09L-27.5671 1081.3C-26.5329 1082 -25.4654 1082.68 -24.3617 1083.34L39.6774 1084.11L78.1387 1129.34L-2.43957 1128.37C-2.09855 1129.05 -1.7529 1129.72 -1.40262 1130.38L79.8568 1131.36L82.3347 1134.27L84.1871 1133.49L74.8945 1122.57Z" fill="#293D36FF" />
                            </g>
                            <path d="M583.524 900.477L602.81 1001.44H476.146L499.342 900.477H583.524Z" fill="#0B2640FF" />
                            <path d="M500.644 883.602L497.778 903.852H585.347L581.959 883.602H500.644Z" fill="#144675FF" />
                            <path d="M519.954 935.164L571.686 971.892L541.912 992.164H460.998L468.799 963.95L519.954 935.164Z" fill="#FAB405FF" />
                            <path d="M550.815 927.193L545.277 941.662L534.168 952.944C533.628 953.492 533.171 954.128 532.811 954.827L525.888 968.319C525.227 969.606 525.804 971.225 527.093 971.705L534.842 974.586C536.34 975.143 537.993 974.469 538.776 972.982L543.832 963.378C543.832 963.378 556.739 959.049 558.458 946.599L565.929 932.115L550.815 927.193Z" fill="#FF7D7DFF" />
                            <path d="M527.66 971.89L535.723 956.006" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M530.909 973.13L538.971 957.246" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M533.849 974.307L541.912 958.422" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M505.684 746.151C511.794 744.569 518.194 741.475 520.453 746.913C522.712 752.351 524.276 754.755 524.276 754.755C524.276 754.755 542.172 757.413 559.721 776.163C577.27 794.913 596.556 831.663 604.374 849.288C612.193 866.913 598.292 905.538 593.427 916.413C588.562 927.288 583.043 931.788 575.725 928.413L571.686 937.788L546.775 929.913L560.176 889.038H504.931C499.659 889.038 495.062 885.166 493.783 879.647L489.873 862.788L477.659 896.519C473.488 908.036 464.996 917.112 454.284 921.501L441.074 926.913L422.806 886.413L437.401 877.676C437.401 877.676 449.541 836.986 458.946 811.788C466.723 790.949 474.061 771.944 488.483 762.663C488.483 762.663 483.184 751.975 505.684 746.151Z" fill="#629180FF" />
                            <path d="M537.838 858.288C542.034 870.992 540.721 881.651 536.48 889.038H560.176L563.93 878.284C564.791 875.816 564.66 873.073 563.566 870.714L543.128 826.602C543.128 826.602 532.636 842.539 537.838 858.288Z" fill="#3A6B5AFF" />
                            <path d="M480.778 852.832C476.761 849.872 473.087 844.577 470.888 838.702C469.81 835.822 465.909 836.32 465.508 839.396C464.797 844.854 464.818 851.43 467.024 857.82C470.96 869.215 477.864 878.58 480.899 887.568L489.872 862.788C489.177 857.445 487.494 857.781 480.778 852.832Z" fill="#3A6B5AFF" />
                            <path d="M521.886 756.617C521.886 756.617 508.985 774.758 491.522 768.711" stroke="#293D36FF" strokeWidth="0.9478" strokeMiterlimit={10} />
                            <path d="M473.019 801.477C473.019 801.477 457.381 840.571 486.311 856.602" stroke="#293D36FF" strokeWidth="0.9478" strokeMiterlimit={10} />
                            <path d="M543.909 769.945C537.914 773.633 529.031 783.664 532.963 805.976" stroke="#293D36FF" strokeWidth="0.9478" strokeMiterlimit={10} />
                            <path d="M567.365 869.015C567.365 869.015 574.142 851.258 588.737 852.945" stroke="#293D36FF" strokeWidth="0.9478" strokeMiterlimit={10} />
                            <path d="M536.391 812.201L560.523 864.147" stroke="#293D36FF" strokeWidth="0.9478" strokeMiterlimit={10} />
                            <path d="M565.801 862.227L576.574 843.852" stroke="#293D36FF" strokeWidth="0.9478" strokeMiterlimit={10} />
                            <path d="M546.775 929.913L571.686 937.788L575.725 928.413L549.774 920.766L546.775 929.913Z" fill="#83C2ABFF" />
                            <path d="M502.82 800.52C502.874 800.341 502.914 800.157 502.936 799.967C503.112 798.442 502.11 797.205 500.698 797.205C499.286 797.205 497.999 798.441 497.823 799.967C497.801 800.156 497.798 800.341 497.811 800.52H489.839L485.862 834.946H506.817L510.793 800.52H502.82Z" fill="#FAFAFAFF" />
                            <path d="M524.274 754.756L500.372 800.028L488.482 762.665" stroke="#FAFAFAFF" strokeWidth="0.7176" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M505.218 832.912H487.931L491.18 804.775H508.467L505.218 832.912Z" fill="#C7A88FFF" />
                            <path d="M493.998 696.992C493.998 696.992 506.508 688.414 513.937 696.992C521.365 705.57 518.237 716.398 520.583 720.336C522.928 724.274 526.968 729.448 526.838 737.548L461.551 748.601C461.551 748.601 462.203 737.617 467.937 720.89C473.67 704.164 483.313 691.929 493.998 696.992Z" fill="#942121FF" />
                            <path d="M506.381 729.691C508.268 730.294 510.411 728.607 511.174 725.906C511.941 723.195 511.028 720.491 509.135 719.868C507.242 719.245 505.085 720.937 504.319 723.649C504.143 724.273 504.065 724.894 504.058 725.492C497.323 719.455 497.533 707.604 497.533 707.604C497.533 707.604 485.788 711.758 478.882 723.289L480.576 731.023C480.576 731.023 479.273 742.273 483.182 746.492C487.092 750.711 493.216 747.756 493.216 747.756L495.959 757.18C495.959 757.18 507.053 759.145 513.036 747.756L506.381 729.691Z" fill="#FF7D7DFF" />
                            <path d="M493.217 747.754C497.746 745.857 500.637 741.797 502.239 738.066C502.611 737.2 503.828 737.481 503.854 738.436C503.98 743.085 502.897 750.731 495.254 754.754L493.217 747.754Z" fill="#ED5A5AFF" />
                            <path d="M486.875 724.414L486.7 731.612C486.679 732.476 487.42 733.128 488.2 732.932L490.09 732.456" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M487.657 735.336L493.217 733.227C493.217 733.227 493.956 740.821 491.871 741.43C489.786 742.039 487.657 735.336 487.657 735.336Z" fill="#FAFAFAFF" />
                            <path d="M493.229 724.746C493.229 725.409 492.73 725.947 492.116 725.947C491.501 725.947 491.003 725.409 491.003 724.746C491.003 724.083 491.501 723.545 492.116 723.545C492.73 723.545 493.229 724.083 493.229 724.746Z" fill="#2E271FFF" />
                            <path d="M484.088 726.398C484.088 727.061 483.589 727.599 482.975 727.599C482.359 727.599 481.862 727.061 481.862 726.398C481.862 725.735 482.36 725.197 482.975 725.197C483.589 725.196 484.088 725.734 484.088 726.398Z" fill="#2E271FFF" />
                            <path d="M489.047 719.07C489.047 719.07 492.044 717.757 494.868 718.648" stroke="#2E271FFF" strokeWidth="0.9478" strokeMiterlimit={10} />
                            <path d="M484.399 719.868C484.399 719.868 482.676 719.458 480.757 720.483" stroke="#2E271FFF" strokeWidth="0.9478" strokeMiterlimit={10} />
                            <path d="M507.551 727.93H505.792V733.836H507.551V727.93Z" fill="#FAFAFAFF" />
                            <path d="M505.407 726.93C505.652 723.683 507.495 721.068 508.908 721.58C510.32 722.092 510.074 724.345 509.46 725.472" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M486.116 726.4C486.116 729.061 484.599 731.218 482.728 731.218C480.857 731.218 479.34 729.061 479.34 726.4C479.34 723.739 480.857 721.582 482.728 721.582C484.599 721.582 486.116 723.739 486.116 726.4Z" stroke="#FAFAFAFF" strokeWidth="0.7176" strokeMiterlimit={10} />
                            <path d="M495.259 724.888C495.259 727.549 493.742 729.706 491.871 729.706C490 729.706 488.483 727.549 488.483 724.888C488.483 722.227 490 720.07 491.871 720.07C493.742 720.07 495.259 722.227 495.259 724.888Z" stroke="#FAFAFAFF" strokeWidth="0.7176" strokeMiterlimit={10} />
                            <path d="M495.259 723.921L504.474 723.168" stroke="#FAFAFAFF" strokeWidth="0.7176" strokeMiterlimit={10} />
                            <path d="M485.945 724.889H488.482" stroke="#FAFAFAFF" strokeWidth="0.7176" strokeMiterlimit={10} />
                            <path d="M222.891 910.883L240.683 995.82H178.454L171.288 952.227H161.558L157.489 995.82H95.1851L104.568 910.883H222.891Z" fill="#144675FF" />
                            <path d="M166.786 933.495C186.291 933.369 213.143 926.437 225.42 922.958L222.892 910.883H104.567L100.26 949.877C128.47 955.632 166.786 933.495 166.786 933.495Z" fill="#0B2640FF" />
                            <path d="M161.558 952.227L169.653 914.164" stroke="#3D74A6FF" strokeWidth="0.8211" strokeMiterlimit={10} />
                            <path d="M177.818 914.164L173.608 931.54C172.558 935.878 169.159 939.084 165.035 939.629L164.213 939.737" stroke="#3D74A6FF" strokeWidth="0.8211" strokeMiterlimit={10} />
                            <path d="M212.347 730.415C212.347 730.415 239.326 749.915 253.226 766.79C267.126 783.665 268.863 840.66 268.863 840.66L307.783 844.039L301.528 868.039L232.028 876.289L227.858 899.914C227.858 899.914 237.588 916.414 219.866 916.414C202.143 916.414 116.487 916.414 105.453 916.414C96.5034 916.414 102.063 902.914 102.063 902.914C102.063 902.914 81.143 905.164 73.5332 886.039C65.9234 866.914 72.1784 849.664 82.9509 823.414C93.7234 797.164 111.098 766.039 121.176 751.039C131.253 736.039 152.467 725.434 177.315 721.656L212.347 730.415Z" fill="#FAFAFAFF" />
                            <path d="M185.116 886.602C198.668 887.352 207.356 874.602 207.356 874.602L208.225 867.852L181.642 872.165L173.649 881.165C157.144 894.29 116.139 902.915 102.064 902.915C102.064 902.915 96.5043 916.415 105.454 916.415C109.303 916.415 122.242 916.415 138.203 916.415C163.992 904.692 185.116 886.602 185.116 886.602Z" fill="#F7C8B7FF" />
                            <path d="M200.656 747.664L173.648 914.163" stroke="#574025FF" strokeMiterlimit={10} />
                            <path d="M198.27 787.462C198.332 787.258 198.377 787.046 198.402 786.83C198.603 785.085 197.457 783.67 195.842 783.67C194.227 783.67 192.753 785.085 192.551 786.83C192.526 787.047 192.522 787.258 192.537 787.462H183.415L178.865 826.855H202.843L207.393 787.462H198.27Z" fill="#F7C8B7FF" />
                            <path d="M186.592 739.32L211.254 741.992L195.469 786.899L186.592 739.32Z" stroke="#F7C8B7FF" strokeWidth="0.7176" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M201.013 824.528H181.232L184.951 792.332H204.732L201.013 824.528Z" fill="#C7A88FFF" />
                            <path d="M128.801 855.314L158.359 857.633C158.359 857.633 152.755 841.145 147.019 834.591C148.368 822.212 148.368 803.644 144.657 800.004L128.801 855.314Z" fill="#F7C8B7FF" />
                            <path d="M245.581 788.914C245.581 788.914 244.191 831.945 232.028 870.804" stroke="#574025FF" strokeMiterlimit={10} />
                            <path d="M260.045 904.673L197.282 902.019L214.922 810.592L277.684 813.245L260.045 904.673Z" fill="#FA8C05FF" />
                            <path d="M264.932 899.69H202.122L216.432 807.588H279.242L264.932 899.69Z" fill="#FAD987FF" />
                            <path d="M264.883 900.553H202.072L219.084 810.756H281.894L264.883 900.553Z" fill="#FA8C05FF" />
                            <path d="M264.883 900.553H202.072L219.084 810.756L246.989 812.459L248.622 818.615L279.048 825.783L264.883 900.553Z" fill="#FAB405FF" />
                            <path d="M186.463 712.18L216.044 722.915L213.784 760.79L201.509 743.915L183.379 758.915L177.315 721.656L186.463 712.18Z" fill="#574025FF" />
                            <path d="M175.256 859.554L115.182 854.07" stroke="#574025FF" strokeMiterlimit={10} />
                            <path d="M150.193 779.727L128.801 855.314" stroke="#574025FF" strokeMiterlimit={10} />
                            <path d="M150.193 773.539C150.193 773.539 154.579 741.055 136.314 736.484" stroke="#574025FF" strokeMiterlimit={10} />
                            <path d="M183.379 860.728H193.129L212.363 847.652C213.74 846.716 215.388 846.359 216.993 846.65L232.316 849.43C234.102 849.754 235.224 851.692 234.708 853.566L234.329 854.948C233.905 856.492 232.499 857.47 231.021 857.248L218.129 855.314L229.924 861.741C230.628 862.125 230.954 863.017 230.681 863.816C230.374 864.715 229.432 865.154 228.616 864.778L216.348 859.133L223.944 866.133C224.586 866.724 224.69 867.752 224.182 868.479C223.647 869.244 222.637 869.39 221.934 868.805L215.218 863.212C215.218 863.212 207.452 877.415 192.983 872.54L181.232 875.728L183.379 860.728Z" fill="#FF7D7DFF" />
                            <path d="M215.652 850.883L234.652 853.773" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M237.024 689.962L244.843 694.321C250.446 684.899 257.874 668.727 238.066 664.509C224.904 649.321 208.355 647.353 203.403 654.665C198.451 661.978 199.493 691.228 199.493 691.228L237.024 689.962Z" fill="#144675FF" />
                            <path d="M237.024 689.962L232.203 687.149C232.203 687.149 237.676 676.743 231.552 670.977C231.552 670.977 222.56 678.711 209.919 675.899C209.919 675.899 207.662 681.146 203.981 686.16C203.891 682.606 201.164 679.763 197.833 679.838C194.652 679.91 191.985 682.805 191.937 686.238C191.885 689.877 194.603 692.844 197.963 692.844C198.231 692.844 198.495 692.818 198.754 692.781L188.273 714.818C187.392 716.671 187.48 718.883 188.51 720.645C191.244 725.323 196.834 734.844 201.188 741.993L210.439 730.18L216.043 716.117C216.043 716.117 221.125 718.086 226.859 713.304C232.593 708.523 232.723 701.632 232.202 698.538L237.024 689.962Z" fill="#FF7D7DFF" />
                            <path d="M194.998 685.04C196.301 680.962 202.687 683.352 201.318 688.696" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M216.044 716.118C209.401 714.684 204.677 709.273 202.603 703.749C202.245 702.793 200.94 703.013 200.846 704.039C199.93 714.062 202.138 723.896 211.292 728.043L216.044 716.118Z" fill="#ED5A5AFF" />
                            <path d="M220.639 689.715C220.187 690.686 219.089 691.078 218.19 690.589C217.29 690.101 216.927 688.917 217.38 687.946C217.832 686.975 218.929 686.583 219.829 687.072C220.729 687.56 221.091 688.743 220.639 689.715Z" fill="#2E271FFF" />
                            <path d="M231.029 695.354C230.577 696.325 229.48 696.717 228.58 696.228C227.68 695.739 227.317 694.556 227.77 693.585C228.223 692.614 229.32 692.222 230.219 692.711C231.119 693.2 231.482 694.383 231.029 695.354Z" fill="#2E271FFF" />
                            <path d="M226.761 689.494L225.135 692.354C224.641 693.223 224.38 694.222 224.38 695.241V697.153C224.38 698.165 223.361 698.794 222.552 698.281L220.833 697.19" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M215.008 696.5L225.193 703.059C225.193 703.059 220.497 712.402 216.116 709.275C211.735 706.148 215.008 696.5 215.008 696.5Z" fill="#FAFAFAFF" />
                            <path d="M218.212 682.189C218.212 682.189 221.97 682.012 223.456 685.427" stroke="#144675FF" strokeMiterlimit={10} />
                            <path d="M227.936 688.697C227.936 688.697 231.693 688.521 233.18 691.935" stroke="#144675FF" strokeMiterlimit={10} />
                            <path d="M175.256 859.555H188.287L185.029 882.055H171.868L175.256 859.555Z" fill="#574025FF" />
                            <path d="M537.057 972.443C545.167 974.22 543.734 992.163 543.734 992.163H521.567C521.567 992.163 520.651 968.848 537.057 972.443Z" fill="#144675FF" />
                            <path d="M334.888 835.413L376.965 833.398L391.53 995.819H354.381L334.888 835.413Z" fill="#0B2640FF" />
                            <path d="M376.965 796.638C383.538 796.638 388.867 791.853 388.867 785.951C388.867 780.048 383.538 775.264 376.965 775.264C370.392 775.264 365.063 780.048 365.063 785.951C365.063 791.853 370.392 796.638 376.965 796.638Z" fill="#3A6B5AFF" />
                            <path d="M365.063 785.952C365.063 790.18 367.803 793.823 371.77 795.556C371.992 795.055 372.212 794.545 372.423 794.01C374.889 787.762 374.637 781.018 370.821 776.811C367.375 778.684 365.063 782.071 365.063 785.952Z" fill="#293D36FF" />
                            <path d="M358.865 803.023C358.865 803.023 364.947 799.039 368.943 788.914C372.939 778.789 369.812 767.351 355.912 767.539C350.005 754.414 338.537 751.227 329.85 757.601C322.01 763.354 322.205 781.414 331.587 788.914L354.382 811.789L358.865 803.023Z" fill="#3A6B5AFF" />
                            <path d="M365.405 821.492L358.865 803.022C357.845 802.388 356.931 801.74 356.085 801.086C358.467 800.444 360.202 798.044 360.026 795.233C359.843 792.318 357.48 789.967 354.772 789.992C352.477 790.013 350.537 791.634 349.858 793.872C345.226 784.592 351.829 775.881 351.829 775.881C342.531 769.261 335.842 776.108 332.955 780.192C331.899 781.686 330.063 782.247 328.433 781.592C327.146 781.075 325.718 781.838 325.335 783.26C324.215 787.411 322.494 795.738 324.464 802.318C327.2 811.459 336.713 811.459 336.713 811.459L340.623 829.178L330.849 832.553C330.849 832.553 332.934 845.772 354.306 845.772C375.678 845.772 380.627 829.999 380.627 829.999L371.604 827.476C368.762 826.681 366.452 824.451 365.405 821.492Z" fill="#FF7D7DFF" />
                            <path d="M336.711 811.459C342.244 811.971 346.433 809.689 348.875 807.815C349.798 807.107 351.055 807.964 350.854 809.167C349.935 814.671 347.272 824.68 339.966 826.209L336.711 811.459Z" fill="#ED5A5AFF" />
                            <path d="M357.114 800.913C357.114 802.231 356.124 803.3 354.902 803.3C353.681 803.3 352.691 802.232 352.691 800.913C352.691 799.595 353.681 798.527 354.902 798.527C356.124 798.527 357.114 799.595 357.114 800.913Z" fill="#FAFAFAFF" />
                            <path d="M335.05 829.859L360.95 995.82H321.682L319.25 948.664C319.25 948.664 297.01 932.164 303.612 895.789L301.23 848.539C301.23 848.539 307.782 841.976 335.05 829.859Z" fill="#3D74A6FF" />
                            <path d="M319.25 948.662L355.607 961.583L360.95 995.818H321.683L319.25 948.662Z" fill="#144675FF" />
                            <path d="M516.6 974.578L548.338 981.662C548.338 981.662 560.352 980.961 570.726 982.608C571.436 982.721 572.127 982.961 572.767 983.313C580.92 987.795 585.346 992.162 585.346 992.162H508.201L516.6 974.578Z" fill="#FF7D7DFF" />
                            <path d="M541.286 987.545L521.568 992.163H553.246C549.818 992.163 546.721 991.716 545.21 989.183C544.382 987.796 542.78 987.195 541.286 987.545Z" fill="#ED5A5AFF" />
                            <path d="M554.419 986.912L562.498 992.162" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M560.174 986.912L568.254 992.162" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M565.93 986.912L574.009 992.162" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M571.685 986.912L579.764 992.162" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M374.053 826.602L384.225 992.163H529.635C529.635 992.163 525.924 972.443 537.058 972.443L474.643 953.351C474.643 953.351 451.027 893.393 442.93 872.277C434.832 851.16 413.968 835.056 374.053 826.602Z" fill="#3D74A6FF" />
                            <path d="M458.597 992.162C445.229 985.825 435.34 973.157 431.822 957.864L422.805 918.662L431.492 992.162H458.597Z" fill="#144675FF" />
                            <path d="M362.253 825.383L359.125 835.086" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M374.053 826.602L380.017 831.988C385.784 837.196 387.922 845.735 385.372 853.368L381.973 863.538L387.33 869.962C391.289 874.712 392.818 881.267 391.414 887.474L380.627 935.163L374.053 826.602Z" fill="#144675FF" />
                            <path d="M334.888 829.859L331.833 833.293C326.25 839.568 325.239 849.094 329.363 856.569L335.006 866.796L332.005 871.666C328.511 877.337 328.041 884.575 330.767 890.717L351.939 938.421L334.888 829.859Z" fill="#144675FF" />
                            <path d="M355.916 872.808C355.853 872.604 355.808 872.392 355.783 872.176C355.582 870.431 356.728 869.016 358.343 869.016C359.959 869.016 361.432 870.431 361.634 872.176C361.659 872.393 361.663 872.604 361.648 872.808H370.77L375.32 912.201H351.342L346.792 872.808H355.916Z" fill="#FAFAFAFF" />
                            <path d="M339.318 828.335L358.717 872.244L368.606 824.533" stroke="#FAFAFAFF" strokeWidth="0.7176" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M353.172 909.874H372.953L369.235 877.678H349.453L353.172 909.874Z" fill="#C7A88FFF" />
                            <path d="M339.247 783.465C338.906 784.542 338.077 785.212 337.396 784.961C336.715 784.71 336.44 783.633 336.781 782.555C337.122 781.478 337.951 780.808 338.632 781.059C339.314 781.311 339.589 782.388 339.247 783.465Z" fill="#2E271FFF" />
                            <path d="M328.024 789.383H335.756C335.756 789.383 336.625 801.008 332.889 800.914C329.154 800.821 328.024 789.383 328.024 789.383Z" fill="#FAFAFAFF" />
                            <path d="M338.015 778.601C338.015 778.601 340.708 777.663 342.88 780.945" stroke="#3A6B5AFF" strokeWidth="0.9478" strokeMiterlimit={10} />
                            <path d="M362.253 965.762V992.164H348.961L362.253 965.762Z" fill="#144675FF" />
                            <path d="M351.801 974.578L383.538 981.662C383.538 981.662 395.552 980.961 405.926 982.608C406.636 982.721 407.327 982.961 407.967 983.313C416.121 987.795 420.546 992.162 420.546 992.162H343.401L351.801 974.578Z" fill="#FF7D7DFF" />
                            <path d="M376.485 987.545L356.768 992.163H388.447C385.018 992.163 381.921 991.716 380.41 989.183C379.582 987.796 377.979 987.195 376.485 987.545Z" fill="#ED5A5AFF" />
                            <path d="M389.619 986.912L397.699 992.162" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M395.375 986.912L403.454 992.162" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M401.129 986.912L409.209 992.162" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M406.884 986.912L414.964 992.162" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M301.23 848.539C288.757 857.726 264.779 906.664 248.621 938.445C232.463 970.226 237.935 992.164 269.993 992.164C302.05 992.164 353.937 992.164 353.937 992.164L362.253 965.761L291.103 953.351L304.219 923.795C304.219 923.795 300.485 913.414 303.613 895.789L301.23 848.539Z" fill="#3D74A6FF" />
                            <path d="M303.612 895.787L302.804 879.773C299.568 885.043 292.236 899.095 294.49 915.24C288.756 929.021 285.629 942.099 291.102 953.349L304.219 923.793C304.219 923.793 300.485 913.412 303.612 895.787Z" fill="#144675FF" />
                            <path d="M291.103 953.351L271.035 948.438" stroke="#144675FF" strokeWidth="0.9478" strokeMiterlimit={10} />
                            <path d="M284.455 948.438L273.439 932.49" stroke="#144675FF" strokeWidth="0.9478" strokeMiterlimit={10} />
                            <path d="M294.527 855.654C294.527 855.654 292.145 850.883 295.794 845.773C298.1 842.544 304.294 839.483 308.539 837.667C311.413 836.438 314.72 837.268 316.709 839.822C318.805 842.512 320.899 846.533 320.552 851.624C320.468 852.87 319.782 853.974 318.792 854.622C316.906 855.856 313.602 858.019 310.999 859.724C309.174 860.919 306.801 860.081 305.971 857.958C305.173 855.915 303.905 853.579 301.955 852.018C300.501 850.854 298.451 851.112 297.217 852.541L294.527 855.654Z" fill="#FF7D7DFF" />
                            <path d="M313.399 842.611C313.399 842.611 317.522 848.965 318.154 855.257" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M309.949 844.879C309.949 844.879 314.072 851.233 314.703 857.525" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M306.539 847.051C306.539 847.051 310.66 853.405 311.292 859.697" stroke="#C43939FF" strokeWidth="0.75" strokeMiterlimit={10} />
                            <path d="M331.761 979.412H188.979V992.162H331.761V979.412Z" fill="#FAFAFAFF" />
                            <path d="M331.761 987.447H188.979V992.162H331.761V987.447Z" fill="#9CBAD6FF" />
                            <path d="M307.003 977.473H194.02V967.414H307.003C309.058 967.414 310.724 969.212 310.724 971.429V973.458C310.724 975.675 309.059 977.473 307.003 977.473Z" fill="#FAFAFAFF" />
                            <path d="M192.582 965.762V968.721H306.414C308.394 968.721 309.998 970.452 309.998 972.588C309.998 974.724 308.394 976.455 306.414 976.455H192.582V979.414H306.311C309.805 979.414 312.637 976.358 312.637 972.588C312.637 968.818 309.805 965.762 306.311 965.762H192.582Z" fill="#FA8C05FF" />
                            <path d="M303.612 954.477H197.282V957.008H303.612V954.477Z" fill="#FAD987FF" />
                            <path d="M303.612 963.23H197.282V965.761H303.612V963.23Z" fill="#FAD987FF" />
                            <path d="M301.23 957.008H199.665V963.23H301.23V957.008Z" fill="#FAFAFAFF" />
                            <path d="M519.92 965.761H374.438L383.549 870.805H529.031L519.92 965.761Z" fill="#FAFAFAFF" />
                            <path d="M379.086 965.761H374.438L383.549 870.805H388.196L379.086 965.761Z" fill="#9CBAD6FF" />
                            <path d="M455.399 992.164H452.272L441.073 941.159V926.914L455.399 992.164Z" fill="#4B84B8FF" />
                            <path d="M480.922 992.164H455.399L441.073 926.914H466.597L480.922 992.164Z" fill="#9CBAD6FF" />
                            <path d="M465.805 944.633C466.862 949.448 464.102 953.352 459.64 953.352C455.178 953.352 450.704 949.448 449.646 944.633C448.589 939.818 451.35 935.914 455.812 935.914C460.273 935.914 464.747 939.818 465.805 944.633Z" fill="#FAFAFAFF" />
                            <path d="M498.984 988.441H396.549V992.163H498.984V988.441Z" fill="#4B84B8FF" />
                            <path d="M498.985 988.441H432.37V992.163H498.985V988.441Z" fill="#9CBAD6FF" />
                            <path d="M179.948 680.436L162.193 674.412C159.911 673.638 158.359 671.356 158.359 668.775V657.063C158.359 653.471 155.661 650.559 152.332 650.559H76.2018C72.8733 650.559 70.1748 653.471 70.1748 657.063V705.677C70.1748 709.269 72.8733 712.181 76.2018 712.181H152.331C155.66 712.181 158.358 709.269 158.358 705.677V693.964C158.358 691.383 159.91 689.101 162.192 688.327L179.947 682.303C180.794 682.016 180.794 680.723 179.948 680.436Z" fill="url(#paint8_linear_5896_116012)" />
                            <path d="M92.7653 681.369C92.7653 683.889 90.8721 685.933 88.536 685.933C86.1998 685.933 84.3066 683.89 84.3066 681.369C84.3066 678.848 86.1998 676.805 88.536 676.805C90.8721 676.805 92.7653 678.849 92.7653 681.369Z" fill="#408DD4FF" />
                            <path d="M109.284 681.369C109.284 683.889 107.391 685.933 105.054 685.933C102.718 685.933 100.825 683.89 100.825 681.369C100.825 678.848 102.718 676.805 105.054 676.805C107.391 676.806 109.284 678.849 109.284 681.369Z" fill="#408DD4FF" />
                            <path d="M125.803 681.369C125.803 683.889 123.91 685.933 121.574 685.933C119.239 685.933 117.345 683.89 117.345 681.369C117.345 678.848 119.238 676.805 121.574 676.805C123.909 676.806 125.803 678.849 125.803 681.369Z" fill="#408DD4FF" />
                            <path d="M142.322 681.369C142.322 683.889 140.429 685.933 138.093 685.933C135.758 685.933 133.864 683.89 133.864 681.369C133.864 678.848 135.757 676.805 138.093 676.805C140.429 676.806 142.322 678.849 142.322 681.369Z" fill="#408DD4FF" />
                            <path d="M390.071 672.77C372.176 674.018 357.726 689.781 356.744 709.104C356.162 720.551 360.212 730.98 367.049 738.425C368.331 739.821 368.79 741.867 368.151 743.707L361.949 761.559C361.686 762.316 362.492 762.981 363.1 762.509L379.853 749.488C380.965 748.624 382.372 748.337 383.701 748.698C387.247 749.662 391.004 750.056 394.884 749.776C412.687 748.495 427.085 732.849 428.142 713.628C429.419 690.391 411.628 671.267 390.071 672.77Z" fill="url(#paint9_linear_5896_116012)" />
                            <path d="M413.386 697.049H371.509V726.375H413.386V697.049Z" fill="#FAB405FF" />
                            <path d="M389.055 708.322L371.509 726.375H413.386L395.841 708.322C393.941 706.367 390.954 706.367 389.055 708.322Z" fill="#FA8C05FF" />
                            <path d="M389.055 715.104L371.509 697.051H413.386L395.841 715.104C393.941 717.058 390.954 717.058 389.055 715.104Z" fill="#FAD987FF" />
                            <path d="M592.162 643.414H534.233C529.674 643.414 525.977 647.403 525.977 652.323V714.837C525.977 719.757 529.674 723.746 534.233 723.746H535.702C539.052 723.746 541.768 726.676 541.768 730.291V749.844C541.768 751.071 543.299 751.464 543.812 750.369L554.586 727.341C555.617 725.136 557.712 723.746 560.001 723.746H592.162C596.722 723.746 600.418 719.757 600.418 714.837V652.323C600.418 647.402 596.722 643.414 592.162 643.414Z" fill="url(#paint10_linear_5896_116012)" />
                            <path d="M563.197 707.093C575.582 707.093 585.623 696.259 585.623 682.893C585.623 669.528 575.582 658.693 563.197 658.693C550.812 658.693 540.772 669.528 540.772 682.893C540.772 696.259 550.812 707.093 563.197 707.093Z" fill="#408DD4FF" />
                            <path d="M563.197 701.005C572.466 701.005 579.981 692.896 579.981 682.893C579.981 672.89 572.466 664.781 563.197 664.781C553.927 664.781 546.413 672.89 546.413 682.893C546.413 692.896 553.927 701.005 563.197 701.005Z" fill="#FAFAFAFF" />
                            <path d="M558.479 667.908L563.197 682.893L558.479 692.44" stroke="#408DD4FF" strokeWidth="0.8666" strokeMiterlimit={10} />
                            <path d="M564.557 667.908C564.557 668.718 563.948 669.375 563.197 669.375C562.447 669.375 561.838 668.718 561.838 667.908C561.838 667.098 562.447 666.441 563.197 666.441C563.948 666.441 564.557 667.098 564.557 667.908Z" fill="#408DD4FF" />
                            <path d="M564.557 697.879C564.557 698.689 563.948 699.346 563.197 699.346C562.447 699.346 561.838 698.689 561.838 697.879C561.838 697.069 562.447 696.412 563.197 696.412C563.948 696.412 564.557 697.069 564.557 697.879Z" fill="#408DD4FF" />
                            <path d="M577.084 684.36C576.334 684.36 575.725 683.703 575.725 682.893C575.725 682.083 576.334 681.426 577.084 681.426C577.835 681.426 578.444 682.083 578.444 682.893C578.444 683.703 577.835 684.36 577.084 684.36Z" fill="#408DD4FF" />
                            <path d="M549.31 684.36C548.559 684.36 547.951 683.703 547.951 682.893C547.951 682.083 548.559 681.426 549.31 681.426C550.061 681.426 550.669 682.083 550.669 682.893C550.669 683.703 550.062 684.36 549.31 684.36Z" fill="#408DD4FF" />
                            <path d="M632.623 992.162H62.3765V1046.4H632.623V992.162Z" fill="#FAFAFAFF" />
                            <path d="M632.623 1035.84H62.3765V1046.4H632.623V1035.84Z" fill="#9CBAD6FF" />
                            <path d="M621.439 1046.4H73.5601V1080H621.439V1046.4Z" fill="#4B84B8FF" />
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear_5896_116012" x1="112.151" y1="669.288" x2="112.151" y2="895.885" gradientUnits="userSpaceOnUse">
                                <stop offset="0.424" stopColor="#E3AF74" />
                                <stop offset="0.9114" stopColor="#EDC89E" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_5896_116012" x1="582.848" y1="737.8" x2="582.848" y2="964.397" gradientUnits="userSpaceOnUse">
                                <stop offset="0.424" stopColor="#E3AF74" />
                                <stop offset="0.9114" stopColor="#EDC89E" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_5896_116012" x1="360.916" y1="691.691" x2="360.916" y2="918.288" gradientUnits="userSpaceOnUse">
                                <stop offset="0.424" stopColor="#E3AF74" />
                                <stop offset="0.9114" stopColor="#EDC89E" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_5896_116012" x1="360.916" y1="630.96" x2="360.916" y2="698.733" gradientUnits="userSpaceOnUse">
                                <stop offset="0.424" stopColor="#E3AF74" />
                                <stop offset="0.9114" stopColor="#EDC89E" />
                            </linearGradient>
                            <linearGradient id="paint4_linear_5896_116012" x1="697.881" y1="864.646" x2="601.596" y2="1063.35" gradientUnits="userSpaceOnUse">
                                <stop offset="0.3958" stopColor="#3A6B5A" />
                                <stop offset="0.488" stopColor="#557964" />
                                <stop offset="0.6869" stopColor="#9B9D7F" />
                                <stop offset="0.9018" stopColor="#EDC89E" />
                            </linearGradient>
                            <linearGradient id="paint5_linear_5896_116012" x1="781.533" y1="921.284" x2="607.723" y2="1099.63" gradientUnits="userSpaceOnUse">
                                <stop offset="0.2018" stopColor="#FA8C05" />
                                <stop offset="0.9018" stopColor="#EDC89E" />
                            </linearGradient>
                            <linearGradient id="paint6_linear_5896_116012" x1="-2.88085" y1="864.646" x2="93.4041" y2="1063.35" gradientUnits="userSpaceOnUse">
                                <stop offset="0.3958" stopColor="#3A6B5A" />
                                <stop offset="0.488" stopColor="#557964" />
                                <stop offset="0.6869" stopColor="#9B9D7F" />
                                <stop offset="0.9018" stopColor="#EDC89E" />
                            </linearGradient>
                            <linearGradient id="paint7_linear_5896_116012" x1="-86.5325" y1="921.284" x2="87.2778" y2="1099.63" gradientUnits="userSpaceOnUse">
                                <stop offset="0.2018" stopColor="#FA8C05" />
                                <stop offset="0.9018" stopColor="#EDC89E" />
                            </linearGradient>
                            <linearGradient id="paint8_linear_5896_116012" x1="125.378" y1="641.164" x2="125.378" y2="732.775" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.171" stopColor="#FDFAF7" />
                                <stop offset="0.4426" stopColor="#F9EDE0" />
                                <stop offset="0.779" stopColor="#F2D8BA" />
                                <stop offset={1} stopColor="#EDC89E" />
                            </linearGradient>
                            <linearGradient id="paint9_linear_5896_116012" x1="352.804" y1="717.678" x2="443.275" y2="717.678" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.171" stopColor="#FDFAF7" />
                                <stop offset="0.4426" stopColor="#F9EDE0" />
                                <stop offset="0.779" stopColor="#F2D8BA" />
                                <stop offset={1} stopColor="#EDC89E" />
                            </linearGradient>
                            <linearGradient id="paint10_linear_5896_116012" x1="563.198" y1="631.275" x2="563.198" y2="766.463" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="0.171" stopColor="#FDFAF7" />
                                <stop offset="0.4426" stopColor="#F9EDE0" />
                                <stop offset="0.779" stopColor="#F2D8BA" />
                                <stop offset={1} stopColor="#EDC89E" />
                            </linearGradient>
                            <clipPath id="clip0_5896_116012">
                                <rect width={695} height={1080} fill="#FFFFFFFF" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <form onSubmit={handleFormSubmitLogin} autoComplete='off' className="w-frame w-form wbaseItem-value w-col ce139852-f36d-4aec-bd9d-a05a04befe8a" wrap="nowrap" level={2} cateid={128}>
                    <div className="w-svg wbaseItem-value 0baa2a10-08b3-4162-ab8d-0ed9fed6042d" level={3} cateid={115}>
                        <svg width={114} height={32} viewBox="0 0 114 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_8_19697)">
                                <path d="M46.8466 16.1337C46.8466 7.69857 53.6825 0.870117 62.127 0.870117H70.7725L67.9577 8.0333H62.194C57.7037 8.0333 54.0847 11.6484 54.0847 16.1337C54.0847 20.6191 57.7037 24.2341 62.194 24.2341H68.8289V31.4643H62.194C53.6825 31.3973 46.8466 24.5689 46.8466 16.1337Z" fill="#009944FF" />
                                <path d="M40.0777 24.2343L34.8501 11.113L26.9419 31.3975H19.1676L30.4939 3.01255C31.2311 1.27197 32.9066 0 34.8501 0C36.7937 0 38.4022 1.27197 39.1394 3.01255L47.8519 24.7699C49.1923 28.0502 46.5785 31.3975 43.6297 31.3975H30.3598L33.1747 24.1674H40.0777V24.2343Z" fill="#009944FF" />
                                <path d="M62.1279 20.7531C64.6818 20.7531 66.7522 18.685 66.7522 16.1339C66.7522 13.5828 64.6818 11.5146 62.1279 11.5146C59.5739 11.5146 57.5035 13.5828 57.5035 16.1339C57.5035 18.685 59.5739 20.7531 62.1279 20.7531Z" fill="#E60012FF" />
                                <path d="M67.9576 16.1337C67.9576 7.69857 74.7936 0.870117 83.2381 0.870117H91.8836L89.0687 8.0333H83.3051C78.8148 8.0333 75.1957 11.6484 75.1957 16.1337C75.1957 20.6191 78.8148 24.2341 83.3051 24.2341H89.94V31.4643H83.3051C74.7936 31.3973 67.9576 24.5689 67.9576 16.1337Z" fill="#009944FF" />
                                <path d="M78.6145 16.1339C78.6145 13.59 80.6921 11.5146 83.2388 11.5146C85.7856 11.5146 87.8632 13.59 87.8632 16.1339C87.8632 18.6778 85.7856 20.7531 83.2388 20.7531C80.6921 20.7531 78.6145 18.6778 78.6145 16.1339Z" fill="#E60012FF" />
                                <path d="M0 21.2887V20.3515H7.17108V21.2887C7.17108 23.2301 8.71252 24.7699 10.6561 24.7699C12.5996 24.7699 14.1411 23.2301 14.1411 21.2887C14.1411 20.1506 14.1411 0 14.1411 0H21.3122C21.3122 0 21.3122 20.3515 21.3122 21.2887C21.3122 27.1799 16.5538 32 10.5891 32C4.75838 32 0 27.1799 0 21.2887Z" fill="#009944FF" />
                                <path d="M91.6151 31.3978V24.2346H104.55C105.823 24.2346 106.829 23.2304 106.829 21.9584C106.829 20.6865 105.823 19.6823 104.55 19.6823H100.194C94.9661 19.6823 90.8109 15.4647 90.8109 10.3099C90.8109 5.08813 95.0331 0.9375 100.194 0.9375H112.793L109.844 8.03373H100.127C98.9203 8.03373 97.915 9.03792 97.915 10.2429C97.915 11.448 98.9203 12.4521 100.127 12.4521H104.483C109.71 12.4521 114 16.6697 114 21.9584C114 27.1802 109.777 31.4647 104.483 31.4647H91.6151V31.3978Z" fill="#009944FF" />
                            </g>
                            <defs>
                                <clipPath id="clip0_8_19697">
                                    <rect width={114} height={32} fill="#000000FF" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="w-frame wbaseItem-value w-col d110ac3f-6bc4-4924-bb11-d73d9f6794be" wrap="nowrap" level={3} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col d5df38a5-a383-4154-a85c-a506ef868c47" wrap="nowrap" name-field="Frame" level={4} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col e5ce3fe7-0e2e-4e70-8e0d-fdd19c66e676" wrap="nowrap" name-field="Frame" level={5} cateid={140}>
                                <div className="w-text wbaseItem-value ed0a3e41-e25f-4251-bed3-ab8aeec1c39d" name-field="Text" level={6} cateid={139}>Đăng nhập</div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col 3560cd47-f40f-4813-b2b8-98674703b7d1" wrap="nowrap" level={4} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col a02f8275-b3a1-476b-8f4d-db1752b6fe4c" wrap="nowrap" level={5} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col d72521ab-eb38-4915-9bef-4dacd804423d" wrap="nowrap" level={6} cateid={140}>
                                    <div className="w-textformfield wbaseItem-value w-row 83a844b3-1e05-4975-9f8c-e9ac668793c5" wrap="nowrap" name-field="Username" level={7} cateid={85} placeholder="Tên đăng nhập">
                                        <div className="wbaseItem-value e6369711-cc5c-41f2-a4fc-21ceb5488d24" name-field="Textfield" level={8} cateid={86}>
                                            <div className="textfield content">
                                                <input
                                                    id="textfield-e6369711-cc5c-41f2-a4fc-21ceb5488d24"
                                                    name="username"
                                                    placeholder="Tên đăng nhập"
                                                    onChange={handleChange}
                                                    value={formData.username}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-textformfield wbaseItem-value w-row 1844ea33-802f-4a03-93bc-e50254e66087" wrap="nowrap" name-field="Password" level={7} cateid={85} placeholder="Mật khẩu">
                                        <div className="wbaseItem-value 2196d976-cf0a-427f-aec6-f14616fe9a8a" name-field="Textfield" level={8} cateid={86}>
                                            <div className="textfield content">
                                                <input
                                                    id="textfield-2196d976-cf0a-427f-aec6-f14616fe9a8a"
                                                    name="password"
                                                    placeholder="Mật khẩu"
                                                    type="password"
                                                    onChange={handleChange}
                                                    value={formData.password}
                                                />
                                            </div>
                                            <div className="suffix-btn-txtfd eye-icon">
                                                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.535 10.5207C20.9132 9.64509 20.2186 8.82344 19.4587 8.0645L16.1085 11.4147C16.1408 11.6043 16.16 11.7958 16.166 11.988C16.166 13.0928 15.7271 14.1524 14.9458 14.9337C14.1645 15.715 13.1049 16.1539 12.0001 16.1539C11.8079 16.1479 11.6164 16.1287 11.4268 16.0964L8.71316 18.81C9.7535 19.25 10.8705 19.4799 12.0001 19.4866C16.7559 19.4866 20.2527 15.269 21.5358 13.4544C21.838 13.0249 22.0002 12.5125 22 11.9874C21.9999 11.4623 21.8374 10.95 21.535 10.5207Z" fill="#282829" fillOpacity="0.6" />
                                                    <path d="M12.0001 4.48931C7.24426 4.48931 3.75156 8.70688 2.46429 10.5216C2.16215 10.9508 2 11.463 2 11.988C2 12.5129 2.16215 13.0251 2.46429 13.4544C3.56373 15.0216 4.90982 16.4003 6.45023 17.5369L9.0531 14.9341C8.6662 14.5472 8.35929 14.088 8.14989 13.5825C7.94048 13.0771 7.83268 12.5354 7.83264 11.9882C7.83257 10.8833 8.27143 9.8236 9.05268 9.04223C9.83393 8.26087 10.8936 7.82186 11.9985 7.82178C13.1035 7.82171 14.1632 8.26057 14.9445 9.04182L9.0531 14.9341L17.5524 6.43562C15.952 5.21981 14.0093 4.53883 12.0001 4.48931Z" fill="#282829" fillOpacity="0.6" />
                                                    <path d="M2.83505 21.9861C2.67029 21.9861 2.50924 21.9372 2.37226 21.8457C2.23527 21.7541 2.12851 21.624 2.06546 21.4718C2.00242 21.3196 1.98592 21.1521 2.01805 20.9905C2.05018 20.8289 2.12951 20.6804 2.24599 20.5639L20.576 2.23388C20.7331 2.08211 20.9436 1.99813 21.1621 2.00003C21.3805 2.00193 21.5895 2.08955 21.744 2.24403C21.8985 2.39851 21.9861 2.60748 21.988 2.82594C21.9899 3.0444 21.9059 3.25486 21.7541 3.412L3.42411 21.742C3.2679 21.8983 3.05601 21.9861 2.83505 21.9861Z" fill="#282829" fillOpacity="0.6" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-frame wbaseItem-value w-row 67715a15-5782-4446-8f81-e8e2ddd85a67" wrap="nowrap" level={6} cateid={140}>
                                    <NavLink to={'/forgot-password'} type="button" className="nav-link w-button wbaseItem-value w-row fc478d5c-aba0-4de9-acf7-73f3dd6b8c27" wrap="nowrap" name-field="Button" level={7} cateid={29}>
                                        <div className="w-text wbaseItem-value 71c96d0c-cbd5-4ba7-af96-3d3cf22c7ba0" name-field="Text" level={8} cateid={139}>Quên mật khẩu?</div>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="w-frame w-stack wbaseItem-value b9ccae6d-8d61-41c2-a0f0-4a69c395c89b" level={5} cateid={140} />
                            <button type="submit" className={`w-button wbaseItem-value w-row 186af8b6-0158-4629-8288-b8f7adcf728b ${loading ? " loading" : ""}`} wrap="nowrap" name-field="Button" level={5} cateid={29}>
                                <div className="w-text wbaseItem-value 716dc4cc-199d-4b93-93bb-238879d52f6a" name-field="Text" level={6} cateid={139}>Đăng nhập</div>
                                <FontAwesomeIcon className='loading-icon' icon={faSpinner} spin style={{ color: "white", order: 2 }} />
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment >
    )
}