import { useEffect, useState } from "react";
import XemHoSoVay from "./xemHoSo-Vay";
import XemHoSoHopDongTinDung from "./xemHoSo-HopDongTinDung";
import XemHoSoGiaiNgan from "./xemHoSo-HoSoGiaiNgan";
import XemHoSoThamDinhGiaiNgan from "./xemHoSo-ThamGinhGiaiNgan";
import { NavLink, useParams } from "react-router-dom";
import { FileStatus } from '../../const/file-status';

import '../../css/Temp.css';
import $ from 'jquery';
import { getDetailHoSovay } from "../../redux/middleware/api/hosovay/HoSoVayDA";
import { WPopup } from "../../components/Popup/Popup";
import PopupXemQuyTrinh from "../CongViecCuaToi/ThamDinh-XuLy/PopupXemQuyTrinh";

export default function Temp() {
    const [tab, setTabs] = useState(0);
    const [showProcess, onShowProcess] = useState(false);

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    let { id } = useParams();

    const tabs_item = [
        {
            tabs: 0,
            title: "Hồ sơ vay",
            view: <XemHoSoVay hoso={data}></XemHoSoVay>
        },
        {
            tabs: 1,
            title: "Hợp đồng tín dụng",
            view: <XemHoSoHopDongTinDung hoso={data}></XemHoSoHopDongTinDung>
        },
        {
            tabs: 2,
            title: "Hồ sơ giải ngân",
            view: <XemHoSoGiaiNgan hoso={data}></XemHoSoGiaiNgan>
        },
        {
            tabs: 3,
            title: "Thẩm định giải ngân",
            view: <XemHoSoThamDinhGiaiNgan hoso={data}></XemHoSoThamDinhGiaiNgan>
        },
    ];

    const changeTab = (tab) => {
        setTabs(tab);
        $('.button-tab').removeClass('active');
        $(`.button-tab[tab=${tab}]`).addClass('active');
    }

    const _getHoso = async () => {
        const response = await getDetailHoSovay(id);
        debugger;
        if (response != null) {
            setData(response);
            setLoading(false);
        }
    }

    useEffect(() => {
        _getHoso();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={3} cateid={140} id="7dcf7726-8f85-4c1e-ad93-0c79208123aa">
            {
                    showProcess &&
                    <WPopup
                        popupContent={
                            <PopupXemQuyTrinh
                                closePopup={() => onShowProcess(false)}
                                code={data?.subProcessInstanceId}
                                loanApplicationId={id}
                            />
                        }
                    />
                }
            <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={4} cateid={140} id="7b371d39-4846-4209-8c3f-17941b7a5c2a">
                <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={5} cateid={140} id="534e7e35-deec-467e-bd1c-e5d7e30c38b6">
                    <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={6} cateid={140} id="8e39e352-3269-4cf9-a1d4-58d3cfeeb4af">
                        <div className="w-text wbaseItem-value" level={7} cateid={139} id="0aa7c9f5-7d39-4d75-b7d3-00e33ce3511e">Chi tiết hồ sơ</div>
                        {/* <button type="button" className="w-button wbaseItem-value w-row" wrap="nowrap" name-field="Button" level={7} cateid={29} id="6379f6d9-a34a-4b67-8bbe-d60d98acd1d0">
                            <div className="w-text wbaseItem-value" name-field="Text" level={8} cateid={139} id="8fffebd5-fc06-4b11-9041-ba175df0f7b9">Hoàn thành</div>
                        </button> */}
                         {FileStatus.get_statusCode(data?.maTrangThaiHoSo)}
                    </div>
                    <NavLink to="/admin/danh-sach-ho-so-vay" className="nav-link  w-frame wbaseItem-value w-row breadcrumb" wrap="nowrap" level={6} cateid={140} id="7a9a238f-d60c-4e6e-b3e9-44603feaca7d">
                        <button type="button" className="w-button wbaseItem-value w-row" wrap="nowrap" name-field="Button" level={7} cateid={29} id="73ebb123-651f-4719-8fd4-3a4d837eacca">
                            <div
                                className="wbaseItem-value event-click" name-field="Text" level={8} cateid={139} id="3619fe4f-d576-47dc-9c86-d26a00fce0ca">
                                Danh sách hồ sơ vay
                            </div>
                            <div className="w-svg wbaseItem-value" level={8} cateid={115} id="f7e236ab-9ff7-435a-a2f3-06196a4aa537">
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                </svg>
                            </div>
                        </button>
                        <div className="w-text wbaseItem-value" level={7} cateid={139} id="97047907-7868-4d03-bd8e-1e6e0c6f4338">{tabs_item[tab].title}</div>
                    </NavLink>
                </div>
                <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={5} cateid={140} id="d93085fe-6f48-493b-b83c-e19c9e37ea9d">
                    <NavLink type="button" to="/ho-so-vay" className="nav-link w-button wbaseItem-value w-row" wrap="nowrap" name-field="Button" level={6} cateid={29} id="861c454f-73bd-4ea8-9d87-54110e9bbdcc">
                        <div className="w-svg wbaseItem-value" level={7} cateid={115} id="2dd7da00-6fc9-45a1-b099-0631bce852f2">
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.4167 6.33333L9.91667 3H2.83333C2.61232 3 2.40036 3.0878 2.24408 3.24408C2.0878 3.40036 2 3.61232 2 3.83333V18.8333C2 19.4964 2.26339 20.1323 2.73223 20.6011C3.20107 21.0699 3.83696 21.3333 4.5 21.3333H19.5C20.163 21.3333 20.7989 21.0699 21.2678 20.6011C21.7366 20.1323 22 19.4964 22 18.8333V7.16667C22 6.94565 21.9122 6.73369 21.7559 6.57741C21.5996 6.42113 21.3877 6.33333 21.1667 6.33333H12.4167Z" fill="#28282999" />
                            </svg>
                        </div>
                        <div className=" w-text wbaseItem-value" name-field="Text" level={7} cateid={139} id="bd446b0b-144f-427e-91b7-4ff0a548295b">Xem hồ sơ ban đầu</div>
                    </NavLink>
                    <button type="button" onClick={()=>onShowProcess(true)} className="w-button wbaseItem-value w-row" wrap="nowrap" name-field="Button" level={6} cateid={29} id="79c4aa9e-434f-4c41-8722-c3988656f4a3">
                        <div className="w-svg wbaseItem-value" level={7} cateid={115} id="ffa7a1f8-6031-4ce6-ab8d-9ae188f894d7">
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 5.33333V7H5.6V12H8V13.6667H5.6V18.6667H8V20.3333H4.8C4.3584 20.3333 4 19.96 4 19.5V2.83333C4 2.37333 4.3584 2 4.8 2C5.2416 2 5.6 2.37333 5.6 2.83333V5.33333H8Z" fill="#009944FF" style={{ mixBlendMode: 'multiply' }} />
                                <path d="M10.3996 8.66699H19.1996C19.6412 8.66699 19.9996 8.29366 19.9996 7.83366V4.50033C19.9996 4.04033 19.6412 3.66699 19.1996 3.66699H10.3996C9.95801 3.66699 9.59961 4.04033 9.59961 4.50033V7.83366C9.59961 8.29366 9.95801 8.66699 10.3996 8.66699Z" fill="#009944FF" style={{ mixBlendMode: 'multiply' }} />
                                <path d="M19.1996 22H10.3996C9.95801 22 9.59961 21.6275 9.59961 21.1667V17.8333C9.59961 17.3725 9.95801 17 10.3996 17H19.1996C19.6412 17 19.9996 17.3725 19.9996 17.8333V21.1667C19.9996 21.6275 19.6412 22 19.1996 22Z" fill="#009944FF" style={{ mixBlendMode: 'multiply' }} />
                                <path d="M10.3996 15.333H19.1996C19.6412 15.333 19.9996 14.9605 19.9996 14.4997V11.1663C19.9996 10.7063 19.6412 10.333 19.1996 10.333H10.3996C9.95801 10.333 9.59961 10.7063 9.59961 11.1663V14.4997C9.59961 14.9605 9.95801 15.333 10.3996 15.333Z" fill="#009944FF" style={{ mixBlendMode: 'multiply' }} />
                            </svg>
                        </div>
                        <div className="w-text wbaseItem-value" name-field="Text" level={7} cateid={139} id="225288cc-95c5-455e-8809-67bdb0b2e4af">Xem quy trình</div>
                    </button>
                </div>
            </div>
            <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={4} cateid={140} id="86e905e6-a1bc-457a-8699-34f1f8e380f1">
                <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={5} cateid={140} id="22c93cea-22e5-46d2-9c6b-d0b3972a742a">
                    <button tab='0' onClick={() => changeTab(0)} type="button" className="nav-link w-button wbaseItem-value w-row button-tab active" wrap="nowrap" name-field="Button" level={6} cateid={29} id="1b266bb2-12cd-4afb-bc1c-7c39240ff4c2">
                        <div className="w-text wbaseItem-value" name-field="Text" level={7} cateid={139} >Hồ sơ vay&nbsp;</div>
                    </button>
                    <button tab='1' onClick={() => changeTab(1)} type="button" className="nav-link w-button wbaseItem-value w-row button-tab event-click" wrap="nowrap" name-field="Button" level={6} cateid={29} id="ee4e39f1-4521-4808-a5ff-0d8c3de5a956">
                        <div className="w-text wbaseItem-value" name-field="Text" level={7} cateid={139}>Hợp đồng tín dụng</div>
                    </button>
                    <button tab='2' onClick={() => changeTab(2)} type="button" className="nav-link w-button wbaseItem-value w-row button-tab event-click" wrap="nowrap" name-field="Button" level={6} cateid={29} id="ee1bc233-ad99-4c7a-8119-f0980a74ba03">
                        <div className="w-text wbaseItem-value" name-field="Text" level={7} cateid={139}>Hồ sơ giải ngân</div>
                    </button>
                    <button tab='3' onClick={() => changeTab(3)} type="button" className="nav-link w-button wbaseItem-value w-row button-tab event-click" wrap="nowrap" name-field="Button" level={6} cateid={29} id="585e7a7e-5d94-46a2-bad6-42d7a25fd996">
                        <div className="w-text wbaseItem-value" name-field="Text" level={7} cateid={139}>Thẩm định giải ngân</div>
                    </button>
                </div>
                {
                    loading ? <div>

                    </div> : <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={5} cateid={140} id="51572277-2d87-4320-acee-4da13b7e5471">
                        {tabs_item[tab ?? 0].view}
                    </div>
                }

            </div>
        </div>
    )
}