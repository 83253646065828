import { BlackList, ExportBlackList, ImportBlackList, baseurl, getalllBlackList, reasonBlackList,templateblacklist } from "../../../../config/ConfigAPI";
import { deleteData, getData, getDataFile, postData, postFormDataFile, putData } from "../../../../core/BaseDA";

export default class BlackListDA {
    static getListBlackList = async (page, size, query) => {
        const url = `${baseurl}${getalllBlackList}${query !== "" ? `${query}&pageNum=${page}&pageSize=${size}` : `?pageNum=${page}&pageSize=${size}`}`;

        const response = await getData(url);
        if (response.code === "200") {
            return response.data;
        }
        return null;
    };
    static getListreasonBlackList = async () => {
        const url = `${baseurl}${reasonBlackList}`;
        const response = await getData(url);
        if (response.code === "200") {
            return response.data;
        }
        return null;
    };

    static DelBlackList = async (Id) => {
        const url = `${baseurl}${BlackList}/${Id}`;
        const response = await deleteData(url, { obj: null });
        return response;
    };

    static UploadFileBlackList = async (obj) => {
        const url = `${baseurl}${ImportBlackList}`;
        const response = await postFormDataFile(url, { obj: obj });
        return response;
    };
    static ExportFileBlackList = async (query) => {
        const UrlFile = `${baseurl}${ExportBlackList}${query ?? ""}`;
        const response = await getDataFile(UrlFile);
        return response;
    };
    static downloadFiletemp = async () => {
        const UrlFile = `${baseurl}${templateblacklist}`;
        const response = await getDataFile(UrlFile);
        return response;
    };
}
