import { base_loginUrl, baseurl } from "../../../../config/ConfigAPI";
import { postData, putData } from "../../../../core/BaseDA";

export const handleLogin = async (data) => {
    const response = await postData(base_loginUrl + "authen/login", { obj: data });
    if (response.code === "200") {
        return response.data;
    }
    return response.message;
};
export const handleCustomerLogin = async (data) => {
    const url = base_loginUrl + "authen/customer/login";
    const response = await postData(url, { obj: data });
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
export const handleForgotPassword = async ({ userName, phoneNumber }) => {
    const response = await putData(`${baseurl}system-management/user/reset-password?username=${userName}`, { obj: {} });

    return response.data;

};
export const handleChangePassword = async ({ userName, phoneNumber }) => {
    const response = await putData(`${baseurl}system-management/user/reset-password?username=${userName}`);
    if (response.code === "200") {
        return response.data;
    }
    return null;
};
