import { containerId, contractUrl, jbpmHost } from "../../../../config/ConfigAPI"
import { getData, postData, postDataFile, putData } from "../../../../core/BaseDA";

export default class ContractDA {
    static layHopDong = async (loanId) => {
        const url = `${contractUrl}eContract/getWebView?loanId=${loanId}`;
        const res = await getData(url);
        if (res.code === "200") {
            return res.data;
        } else {
            return null;
        }
    }

    //TODO: check lại file 
    static taoHopDongPreview = async (data) => {
        const url = `${contractUrl}eContract/createEContract`;
        const res = await postDataFile(url, { obj: data });
        return res;
    }

    static luuHopDong = async (data) => {
        const url = `${contractUrl}eContract/saveDraft`;
        const res = await postData(url, { obj: data });
        return res;
    }
    static layHopDongDaLuu = async (taskId) => {
        const url = `${contractUrl}eContract/getDraft?loanId=${taskId}`;
        const res = await getData(url);
        return res;
    }

    static taoHopDong = async (taskId, data) => {
        const url = `${jbpmHost}/kie-server/services/rest/server/containers/${containerId}/tasks/${taskId}/states/completed?auto-progress=true`;
        const res = await putData(url, { obj: data });
        return res;
    }
    // static taoHopDong = async (data) => {
    //     //data = {
    //     //     "id": "2023080500044",
    //     //     "idKhach": "000090000090",
    //     //     "color": "",
    //     //     "chassisNumber": "",
    //     //     "machinesNumber": "",
    //     //     "loanPeriod": ""
    //     // }
    //     const sendData = {
    //         "jlosHost": `${baseurl}`,
    //         "loanApplication": {
    //             "fis.onboarding.process.model.jlos.dto.LoanApplicationDTO": data
    //         }
    //     }
    //     const url = `${baseurl}/kie-server/services/rest/server/containers/${containerId}/processes/JACCS-LOS.HopDongTinDung/instances`;
    //     const res = await postData(url, { obj: sendData });
    //     if (res.code === "200") {
    //         return res.data;
    //     } else {
    //         return null;
    //     }
    // }
}