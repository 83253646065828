import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import './Nhanhieu.css';
import { NavLink, useLocation } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

import { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import Pagination from '../../components/Pagination/Pagination';
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import NhanhieuDA from '../../redux/middleware/api/NhanHieu/NhanhieuDA';
// import { Ultis } from '../../core/utils';
// import Select2 from '../../components/select2/Select2';
import { BrandType } from '../../const/enum';
import Manufacture from './ManuFacture';
import BranchCode from './BranchCode';
import BranchName from './BranchName';
import Branch from './Branch';
import NameBranch from './NameBranch';

// import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';



export default function DanhSachNhanHieu() {
  const location = useLocation();
  const [treeData, setTreeData] = useState([]);
  const [ChildItem, setChildItem] = useState([]);
  const [ChildItemPage, setChildItemPage] = useState([]);
  // const [parentItem, setparentItem] = useState([]);
  const [selectedItem, setselectedItem] = useState({});
  const [loading, setloading] = useState(true);
  const [total, setTotal] = useState();
  const [parentId, setparentId] = useState();
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 10;
  const [currentPage, setcurrentPage] = useState(1);
  const notificationAlert = useRef();
  const renderTree = (parentID, level = 0) => {
    let tileList = [];
    for (let tileItem of treeData.filter(e => e.parentId === parentID)) {
      tileList.push(<div key={tileItem.id} className="w-tree wbaseItem-value w-row 92f43d0e-9f64-4b82-a967-12e7d64a6bba" level={7} cateid={241} style={{ marginLeft: `${16 * level}px`, width: `calc(100% - ${16 * level}px)` }} tree-height="hug">
        {treeTile(tileItem)}
        {renderTree(tileItem.id, level + 1)}
      </div >
      )
    }
    return tileList;
  }

  const treeTile = (item) => {
    item.toggleShow ??= false;
    return (<div className={"tile-item " + (selectedItem?.id === item.id ? 'active' : '')} data-id={item.id} >
      <button className="w-button w-row btn-tree-action" onClick={() => {
        item.toggleShow = !item.toggleShow;
        setTreeData([...treeData]);
      }}>
        <FontAwesomeIcon icon={item.toggleShow ? faCaretDown : faCaretRight} />
      </button>
      <div className="children-value" data-id={item.id} onClick={onclickTitle} >
        <div className="w-text wbaseItem-value 7b3f76d3-5d9e-4624-8d59-df59fbfa8a26" data-id={item.id} level={8} onClick={onclickTitle} cateid={139}>
          {item.name ?? "_"}
        </div>
      </div>
    </div>);
  }

  useEffect(() => {
    _init();
  }, []);
  const _init = async () => {
    await _InitData("");
  }
  const deleteManuFacture = async (id) => {
    debugger;
    var upDateItem = [...treeData];
    upDateItem = upDateItem.filter((a) => parseInt(a.id) !== parseInt(id));
    setTreeData(upDateItem);
  }
  const AddnewManuFacture = async (item) => {
    debugger;
    var upDateItem = [...treeData];
    upDateItem = upDateItem.push(item);
    setTreeData(upDateItem);
  }
  const _InitData = async (name) => {

    const res = await NhanhieuDA.getListbranch(name);
    if (res != null) {
      const id = new URLSearchParams(location.search).get('id');
      if (id) {
        
        var parent = res.find((a) => a.id == parseInt(id));
        var lstChild = res.filter((a) => a.parentId === parseInt(id));
        setselectedItem(parent)
        setChildItemPage(lstChild)
        setChildItem(lstChild)

      } else {
        var lstChild = res.filter((a) => a.type === BrandType.MANUFACTURE);
        setcurrentPage(1)
        // const count = Math.ceil(lstChild.length / itemsPerPage);
        // setPageCount(count)
        // const displayedItems = lstChild.slice(0, itemsPerPage);
        setChildItemPage(lstChild)
        // setTotal(lstChild.length)
        setChildItem(lstChild)

      }
      var lst = res?.map((a,_)=>{return {...a,parentId: a.parentId ? a.parentId : 0}});
      setTreeData(lst)
      if(res?.some((a)=> !a.parentId)){
        setparentId(0)
      }else{
        res?.sort((a,b)=> (a.parentId < b.parentId) ?1 : -1);
        setparentId(res[0].parentId)
      }
      setloading(false)



    }
  }

  const onclickTitle = (event) => {
    $(".tile-item").removeClass('active');
    event.target.parentNode.parentNode.classList.add('active')
    var id = event.target.getAttribute('data-id');
    var lstChild = treeData.filter((a) => a.parentId == id);
    var parent = treeData.find((a) => a.id == parseInt(id));
    setselectedItem(parent)
    setcurrentPage(1)
    setTotal(lstChild.length)
    // const displayedItems = lstChild.slice(0, itemsPerPage);
    setChildItemPage(lstChild)
    setChildItem(lstChild)
    // const count = Math.ceil(lstChild.length / itemsPerPage);
    // setPageCount(count)
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('id', id);
    window.history.replaceState(null, null, `?${searchParams.toString()}`);
  }
  const onclickEdit = (id) => {
    var parent = treeData.find((a) => a.id === parseInt(id));
    debugger;
    var lstChild = treeData.filter((a) => a.parentId === parseInt(id));
    setChildItemPage(lstChild)
    setChildItem(lstChild)
    setselectedItem(parent)
    var tmp = treeData.map((item) =>
      item.id === parseInt(id) ? { ...item, toggleShow: true } : item
    );
    debugger;
    setTreeData(tmp);
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('id', id);
    window.history.replaceState(null, null, `?${searchParams.toString()}`);
    // setTreeData((treeData)=>{
    //   treeData.map((a)=>{a.id === parseInt(id) ? {...a,toggleShow: true} : a})
    // })

  }
  const onchangeSearch = async (event) => {
    const res = await NhanhieuDA.getListbranch(event.target.value);
    if (res != null) {
      // setTreeData(res)
      var lst = res?.map((a,_)=>{return {...a,parentId: a.parentId ? a.parentId : 0}});
      setTreeData(lst)
      if(res?.some((a)=> !a.parentId)){
        setparentId(0)
      }else{
        res?.sort((a,b)=> (a.parentId > b.parentId) ? 1 : -1);
        setparentId(res[0].parentId)
      }
    }
  }
  const onsubmitFormManu = async (event) => {
    const res = await NhanhieuDA.updatemanufactureBranch(event);
    if (res.code == '200') {
      notify(notificationAlert, SUCCESS, 'Cập nhật dữ liệu thành công.')
      window.location.reload()
    } else {
      notify(notificationAlert, ERROR, res.message)
    }
  }
  const onsubmitFormBranchCode = async (event) => {

    const res = await NhanhieuDA.updateCodeBranch(event);
    if (res.code == '200') {
      notify(notificationAlert, SUCCESS, 'Cập nhật dữ liệu thành công.')
      window.location.reload()
    } else {
      notify(notificationAlert, ERROR, res.message)
    }
  }
  const onsubmitFormBranch = async (event) => {
    const res = await NhanhieuDA.updateBranch(event);
    if (res.code == '200') {
      notify(notificationAlert, SUCCESS, 'Cập nhật dữ liệu thành công.')
      window.location.reload()
    } else {
      notify(notificationAlert, ERROR, res.message)
    }
  }
  const onsubmitFormBranchname = async (event) => {
    const res = await NhanhieuDA.updateNameBranch(event);
    if (res.code == '200') {
      notify(notificationAlert, SUCCESS, 'Cập nhật dữ liệu thành công.')
      window.location.reload()
    } else {
      notify(notificationAlert, ERROR, res.message)
    }
  }

  return (
    <div
      class="w-frame wbaseItem-value w-col 11585551-3457-47b8-8806-9a1507097605"

      level="3"
      cateid="140"
    >
      <NotificationAlert ref={notificationAlert} />

      <div
        class="w-frame wbaseItem-value w-row ba053c92-0d3c-406c-b822-fb382780959d"

        level="4"
        cateid="140"
      >
        <div
          class="w-frame wbaseItem-value w-col b0d0c195-8b8c-4717-bd12-a0da5fd5768c"

          level="5"
          cateid="140"
        >
          <div
            class="w-frame wbaseItem-value w-row c3c56e92-5880-4541-a4a0-22048812dede"

            level="6"
            cateid="140"
          >
            <div
              class="w-text wbaseItem-value 952b5d38-3f8c-4c91-87b0-433ce1618e6e"
              level="7"
              cateid="139"
            >
              Danh sách nhà sản xuất
            </div>
          </div>
          <div
            class="w-frame wbaseItem-value w-row breadcrumb 30d6ba78-edf8-4d86-8d34-e1928a32a803"

            level="6"
            cateid="140"
          >
            <button
              type="button"
              class="w-button wbaseItem-value w-row 6db83589-e70e-4984-b2b3-5d04e04e6f78"

              name-field="Button"
              level="7"
              cateid="29"
            >
              <div
                class="w-text wbaseItem-value 275a05ba-fe4b-4632-80d8-37722c62f707"
                name-field="Text"
                level="8"
                cateid="139"
              >
                Quản trị hệ thống
              </div>
              <div
                class="w-svg wbaseItem-value 679ff436-6837-4124-9e70-08aebbfab12c"
                level="8"
                cateid="115"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z"
                    fill="#28282999"
                    fill-opacity="0.6"
                  ></path>
                </svg>
              </div>
            </button>
            <div
              class="w-text wbaseItem-value 1f209f97-2f9f-4bbb-89e8-8474c1550e55"
              level="7"
              cateid="139"
            >
              Nhãn hiệu
            </div>
          </div>
        </div>

      </div>
      <div class="w-frame wbaseItem-value w-row 441af62a-e046-442c-be35-3cc9ae38d2af" level="4" cateid="140" >
        <div class="w-frame wbaseItem-value w-col 7858631c-4778-4947-9d12-3a204876073e" level="5" cateid="140" >
          <div class="w-frame wbaseItem-value w-row 80a33036-1770-4804-bc7e-8b5f9f8dc699" level="6" cateid="140" >
            <div class="w-textformfield wbaseItem-value w-row 46ef9d10-6026-4440-992e-0f91b477b350" level="7" cateid="85" >
              <div class="w-svg wbaseItem-value 5fb7e9dc-23a0-430d-8f4a-e076b828e94c" level="8" cateid="115" >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <g clip-path="url(#clip0_502_135406)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#28282999" fill-opacity="0.8"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_502_135406">
                      <rect width="24" height="24" rx="4" fill="#28282999" ></rect>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="wbaseItem-value 0dd4aff6-641a-41fd-8a88-8df3f3904957" level="8" cateid="86" > <div class="textfield">
                <input placeholder="Tìm kiếm chức năng" onChange={onchangeSearch} />
              </div>
              </div>
            </div>
          </div>
          <div class="w-frame wbaseItem-value w-col 9ddc8e18-e84a-4816-8d9c-d503d4ffa963" scroll="true" level="6" cateid="140">
            {loading === true ? null : renderTree(parentId)}
          </div>
        </div>
        <div class="w-frame wbaseItem-value w-col 62a2193c-4497-40f1-86bc-38b8183510d0" scroll="true" level="5" cateid="140" >
          {loading === true ? null :
            selectedItem?.id == null ?
              <Manufacture lst={ChildItemPage}
                callback_edit={
                  function (id) {
                    onclickEdit(id)
                  }
                }
                delete_callback={function (index) {
                  deleteManuFacture(index)
                }}
                add_callback={function (item) {
                  AddnewManuFacture(item)
                }}
              />
              :
              <BranchName
                ChildItemPage={ChildItemPage}
                selectedItem={selectedItem}
                callback_edit={
                  function (id) {
                    onclickEdit(id)
                  }
                }
                callback_submit={
                  function (event) {
                    debugger;
                    if (selectedItem.type === BrandType.MANUFACTURE)
                      onsubmitFormManu(event);
                    if (selectedItem.type === BrandType.BRAND_CODE)
                      onsubmitFormBranchCode(event);
                    if (selectedItem.type === BrandType.BRAND)
                      onsubmitFormBranch(event);
                    if (selectedItem.type === BrandType.BRAND_NAME)
                      onsubmitFormBranchname(event);
                  }
                }
                onFilter_callback={
                  function (type) {
                    if (type == 0) {
                      setChildItemPage(ChildItem);
                    }
                    if (type == 1) {
                      var updateItem = [...ChildItem];
                      setChildItemPage(updateItem.filter((a) => a.status == 1));
                    }
                    if (type == 2) {
                      var updateItem = [...ChildItem];
                      setChildItemPage(updateItem.filter((a) => a.status == 0));
                    }
                  }
                }
                AddNew={
                  function (item) {
                    setChildItemPage([item, ...ChildItemPage])
                  }
                }
                onchangeField={
                  function (event, dataType) {
                    const index = parseInt(event.target.getAttribute('data-index'));
                    setChildItemPage((ChildItemPage) =>
                      ChildItemPage.map((item, a) =>
                        a === index ? { ...item, [event.target.name]: dataType === "number" ? parseFloat(event.target.value.replaceAll(",", "")) : event.target.value } : item
                      ));
                  }
                }
                onDeleteItem={
                  function (index) {
                    const updatedItems = [...ChildItemPage];
                    updatedItems.splice(index, 1);
                    setChildItemPage(updatedItems);
                  }
                }
                title={selectedItem.type == BrandType.MANUFACTURE ? 'Nhà sản xuất' : selectedItem.type == BrandType.BRAND_CODE ? 'Mã nhãn hiệu' : selectedItem.type == BrandType.BRAND ? 'Nhãn hiệu' : 'Tên nhãn hiệu'}
              />
            // : selectedItem.type === BrandType.BRAND ?
            //   <Branch
            //     lst={ChildItemPage}
            //     selectedItem={selectedItem}
            //     callback_edit={
            //       function (id) {
            //         onclickEdit(id)
            //       }
            //     }
            //     callback_submit={
            //       function (event) {
            //         onsubmitFormBranch(event)
            //       }
            //     }
            //     title={selectedItem.type == BrandType.MANUFACTURE ? 'Nhà sản xuất' : selectedItem.type == BrandType.BRAND_CODE ? 'Mã nhãn hiệu' : selectedItem.type == BrandType.BRAND ? 'Nhãn hiệu' : 'Tên nhãn hiệu'}
            //   /> : selectedItem.type === BrandType.BRAND_NAME ?
            //     <NameBranch
            //       lst={ChildItemPage}
            //       selectedItem={selectedItem}
            //       callback_edit={
            //         function (id) {
            //           // var parent = treeData.find((a) => a.id === parseInt(id));
            //           // debugger;
            //           // var lstChild = treeData.filter((a) => a.parentId === parseInt(id));
            //           // setChildItemPage(lstChild)
            //           // setselectedItem(parent)
            //           onclickEdit(id)
            //         }
            //       }
            //       callback_submit={
            //         function (event) {
            //           onsubmitFormBranchname(event)
            //         }
            //       }
            //       title={selectedItem.type == BrandType.MANUFACTURE ? 'Nhà sản xuất' : selectedItem.type == BrandType.BRAND_CODE ? 'Mã nhãn hiệu' : selectedItem.type == BrandType.BRAND ? 'Nhãn hiệu' : 'Tên nhãn hiệu'}
            //     /> : null
          }

        </div>
      </div>

    </div >
  )
}