import { NavLink, useNavigate } from 'react-router-dom';
import './ForgotPassword.css';
import React from 'react';
import { useState } from 'react';
import { handleForgotPassword } from '../../../redux/middleware/api/Login/LoginDA';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function ForgotPassword() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({ username: '', phoneNumber: '' });
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmitForgotPassword = async (event) => {
        event.preventDefault();

        const usernameValue = formData.username;
        const phoneNumberValue = formData.phoneNumber;

        if (usernameValue?.length > 0 && phoneNumberValue?.length > 0) {
            setLoading(true);
            let response = await handleForgotPassword({ userName: usernameValue, phoneNumber: phoneNumberValue });
            
            if (response?.code === "200") {
                setLoading(false);
                // localStorage.setItem("userItem", response);
                // localStorage.setItem("token", response.token);
                navigate("/change-password");
            }

            // setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />

            <div className="w-frame wbaseItem-value w-row xxl 14457daf-6307-49c4-8758-ec4a03fcd382 w-page" wrap="nowrap" level={1} cateid={140}>
                <div className="w-svg wbaseItem-value 17916f5e-cf36-4ca0-9bc9-438f78c83573" level={2} cateid={115}></div>
                <form onSubmit={handleFormSubmitForgotPassword} autoComplete='off' className="w-frame w-form wbaseItem-value w-col 98eedac8-5694-4a25-8e1c-600ce85792b3" wrap="nowrap" level={2} cateid={128}>
                    <div className="w-svg wbaseItem-value d543e09f-91b4-4ba7-ad54-d4cbb37b76aa" level={3} cateid={115}>
                        <svg width={114} height={32} viewBox="0 0 114 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_8_19697)">
                                <path d="M46.8466 16.1337C46.8466 7.69857 53.6825 0.870117 62.127 0.870117H70.7725L67.9577 8.0333H62.194C57.7037 8.0333 54.0847 11.6484 54.0847 16.1337C54.0847 20.6191 57.7037 24.2341 62.194 24.2341H68.8289V31.4643H62.194C53.6825 31.3973 46.8466 24.5689 46.8466 16.1337Z" fill="#009944FF" />
                                <path d="M40.0777 24.2343L34.8501 11.113L26.9419 31.3975H19.1676L30.4939 3.01255C31.2311 1.27197 32.9066 0 34.8501 0C36.7937 0 38.4022 1.27197 39.1394 3.01255L47.8519 24.7699C49.1923 28.0502 46.5785 31.3975 43.6297 31.3975H30.3598L33.1747 24.1674H40.0777V24.2343Z" fill="#009944FF" />
                                <path d="M62.1279 20.7531C64.6818 20.7531 66.7522 18.685 66.7522 16.1339C66.7522 13.5828 64.6818 11.5146 62.1279 11.5146C59.5739 11.5146 57.5035 13.5828 57.5035 16.1339C57.5035 18.685 59.5739 20.7531 62.1279 20.7531Z" fill="#E60012FF" />
                                <path d="M67.9576 16.1337C67.9576 7.69857 74.7936 0.870117 83.2381 0.870117H91.8836L89.0687 8.0333H83.3051C78.8148 8.0333 75.1957 11.6484 75.1957 16.1337C75.1957 20.6191 78.8148 24.2341 83.3051 24.2341H89.94V31.4643H83.3051C74.7936 31.3973 67.9576 24.5689 67.9576 16.1337Z" fill="#009944FF" />
                                <path d="M78.6145 16.1339C78.6145 13.59 80.6921 11.5146 83.2388 11.5146C85.7856 11.5146 87.8632 13.59 87.8632 16.1339C87.8632 18.6778 85.7856 20.7531 83.2388 20.7531C80.6921 20.7531 78.6145 18.6778 78.6145 16.1339Z" fill="#E60012FF" />
                                <path d="M0 21.2887V20.3515H7.17108V21.2887C7.17108 23.2301 8.71252 24.7699 10.6561 24.7699C12.5996 24.7699 14.1411 23.2301 14.1411 21.2887C14.1411 20.1506 14.1411 0 14.1411 0H21.3122C21.3122 0 21.3122 20.3515 21.3122 21.2887C21.3122 27.1799 16.5538 32 10.5891 32C4.75838 32 0 27.1799 0 21.2887Z" fill="#009944FF" />
                                <path d="M91.6151 31.3978V24.2346H104.55C105.823 24.2346 106.829 23.2304 106.829 21.9584C106.829 20.6865 105.823 19.6823 104.55 19.6823H100.194C94.9661 19.6823 90.8109 15.4647 90.8109 10.3099C90.8109 5.08813 95.0331 0.9375 100.194 0.9375H112.793L109.844 8.03373H100.127C98.9203 8.03373 97.915 9.03792 97.915 10.2429C97.915 11.448 98.9203 12.4521 100.127 12.4521H104.483C109.71 12.4521 114 16.6697 114 21.9584C114 27.1802 109.777 31.4647 104.483 31.4647H91.6151V31.3978Z" fill="#009944FF" />
                            </g>
                            <defs>
                                <clipPath id="clip0_8_19697">
                                    <rect width={114} height={32} fill="#000000FF" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="w-frame wbaseItem-value w-col d756082d-7305-40aa-8469-9e3ceec8bcf7" wrap="nowrap" level={3} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col 01ea8c33-e1bc-40a0-be45-fe35d1e7a7e5" wrap="nowrap" name-field="Frame" level={4} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col 5c2ce962-d6a8-46d7-91fa-822ee5d6f6ef" wrap="nowrap" name-field="Frame" level={5} cateid={140}>
                                <div className="w-text wbaseItem-value d588e8df-26aa-4a9b-ac53-0e308e111e01" name-field="Text" level={6} cateid={139}>Quên mật khẩu</div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col 736c48f3-97fb-41e5-8b19-6000a3195475" wrap="nowrap" level={4} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col e530488a-260f-42b7-af12-b19e3604dcc6" wrap="nowrap" level={5} cateid={140}>
                                <div className="w-textformfield wbaseItem-value w-row 6b5dc2e2-5709-4ae6-bacb-20ad857773d4" wrap="nowrap" name-field="Username" level={6} cateid={85} placeholder="Tên đăng nhập">
                                    <div className="wbaseItem-value 454a1392-00a5-48b4-9795-8804d16be67c" name-field="Textfield" level={7} cateid={86}>
                                        <div className="textfield">
                                            <input
                                                id="textfield-454a1392-00a5-48b4-9795-8804d16be67c"
                                                name="username"
                                                placeholder="Tên đăng nhập"
                                                onChange={handleChange}
                                                value={formData.username}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-textformfield wbaseItem-value w-row 89128f97-6a49-4c60-9d51-54a77d3bbb42" wrap="nowrap" name-field="PhoneNumber" level={6} cateid={85} placeholder="Số điện thoại">
                                    <div className="wbaseItem-value 7bc320a9-53e6-4d1e-89a9-a863e9397184" name-field="Textfield" level={7} cateid={86}>
                                        <div className="textfield">
                                            <input
                                                id="textfield-7bc320a9-53e6-4d1e-89a9-a863e9397184"
                                                name="phoneNumber"
                                                placeholder="Số điện thoại"
                                                onChange={handleChange}
                                                value={formData.phoneNumber}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className={`w-button wbaseItem-value w-row 9d49a9db-a419-479e-94d8-ce09209c136e ${loading ? " loading" : ""}`} wrap="nowrap" name-field="Button" level={5} cateid={29}>
                                <div className="w-text wbaseItem-value 40b0b81f-aa17-4d39-aca6-e93a8b6efbfd" name-field="Text" level={6} cateid={139}>Quên mật khẩu</div>
                                <FontAwesomeIcon className='loading-icon' icon={faSpinner} spin style={{ color: "white", order: 2 }} />
                            </button>
                            <div className="w-frame wbaseItem-value w-row 7b492b7a-249c-4cf1-8ccc-1205ef8fc103" wrap="nowrap" level={5} cateid={140}>
                                <NavLink to='/login' type="button" className="nav-link w-button wbaseItem-value w-row 9d3efe06-1e1c-44ed-aedd-6cb48ec49816" wrap="nowrap" name-field="Button" level={6} cateid={29}>
                                    <div className="w-text wbaseItem-value 384bf659-e9cf-4071-b7ca-582751e2b759" name-field="Text" level={7} cateid={139}>Quay lại đăng nhập</div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}