import './PopupYeuCauBoSung.css'

export default function PopupYeuCauBoSung({ closePopup, data }) {
    return (
        <div className="w-frame wbaseItem-value w-col sm 9dc557a9-3e95-45ab-bbc6-62a78cb84242 " wrap="nowrap" level={1} cateid={140}>
            <div className="w-frame wbaseItem-value w-row 0987adc4-ec81-46bb-aabb-43dc03b1dde8" wrap="nowrap" level={2} cateid={140}>
                <div className="w-text wbaseItem-value 61e74044-adec-4570-9222-727199a10570" level={3} cateid={139}>Yêu cầu bổ sung</div>
                <button type='button' onClick={closePopup} className="w-button w-svg wbaseItem-value 595648ee-8951-4edf-b933-e00a6c99e1ac" level={3} cateid={115}>
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" />
                    </svg>
                </button>
            </div>
            <div className="w-frame wbaseItem-value w-row 3e470ff2-e761-45f4-a88d-3576bc60c66a" wrap="nowrap" level={2} cateid={140}>
                <div className="w-text wbaseItem-value b2929b1b-cab3-4c39-898e-6f6439c5fb48" level={3} cateid={139}>
                    {data}
                    {/* Bổ sung đầy đủ hồ sơ theo yêu cầu<br />
                    Bổ sung đầy đủ hồ sơ theo yêu cầu<br />
                    Bổ sung đầy đủ hồ sơ theo yêu cầu<br />
                    Bổ sung đầy đủ hồ sơ theo yêu cầu<br />
                    Bổ sung đầy đủ hồ sơ theo yêu cầu<br /> */}
                </div>
            </div>
            <div className="w-frame wbaseItem-value w-row f57a8b77-f86f-4e44-b381-8d7fcee40c5a" wrap="nowrap" level={2} cateid={140}>
                <div className="w-frame wbaseItem-value w-row e76ebfa8-9072-40b2-8c54-6b47881b8d5d" wrap="nowrap" level={3} cateid={140}>
                    <button onClick={closePopup} type="button" className="w-button wbaseItem-value w-row 8e5e269a-99af-425e-b57c-8b8af815d559" wrap="nowrap" name-field="Button" level={4} cateid={29}>
                        <div className="w-text wbaseItem-value 3cc68505-3cdf-4d0f-a047-aa5949a660c0" name-field="Text" level={5} cateid={139}>Đóng</div>
                    </button>
                </div>
            </div>
        </div>
    )
}