import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { getTaskDetail, ngayHopDongDuKien } from '../../../redux/middleware/api/congvieccuatoi/CongViecDA';
import './TaoHopDong.css';
import { ThongTinKhoiTao } from '../CommonView';
import PopupXemQuyTrinh from '../ThamDinh-XuLy/PopupXemQuyTrinh';
import { PopupType, WPopup } from '../../../components/Popup/Popup';
import PopupYeuCauBoSung from '../HoSo-BoSung/PopupYeuCauBoSung';
import ContractDA from '../../../redux/middleware/api/HopDong/ContractDA';
import Select2 from '../../../components/select2/Select2.js';
import { useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import { ERROR, notify } from '../../../core/AlertNoti';
import { base_loginUrl } from '../../../config/ConfigAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function TaoHopDong() {
    const { control, register, handleSubmit, setValue, getValues, setError, formState: { errors } } = useForm({ shouldFocusError: false });
    const navigate = useNavigate();
    const location = useLocation();
    const notificationAlert = useRef();

    const [data, setData] = useState();
    const [settingData, setSettingData] = useState({});

    const [showProcess, onShowProcess] = useState(false);
    const [showYeuCauBoSung, setShowYeuCauBoSung] = useState(false);
    const [thoatKhoiTao, setThoatKhoiTao] = useState(false);
    const [sendloading, setsendloading] = useState(false);
    const [saveloading, setsaveloading] = useState(false);

    const [danhsachNgayThanhToan, setDanhSachNgayThanhToan] = useState([]);

    const [fileHopDong, setFileHopDong] = useState()

    const subId = location.search.replace("?", "");

    const getContractInfo = async () => {
        let res = await getTaskDetail({ id: subId });
        if (res) {
            setData(res);
            const _settingData = res['process-instance-variables'];
            setSettingData(_settingData);

            if (_settingData != null) {
                let ngayThanhToanThang = await ngayHopDongDuKien(1, 100, { 'date': `${_settingData?.loanApplication.ngayHopDongDuKien}`.replaceAll("/", "-") });
                if (ngayThanhToanThang) {
                    setDanhSachNgayThanhToan(ngayThanhToanThang.pageData);
                }

                setValue("idCard", _settingData?.loanApplication?.khachHang.cmndHoChieu);
                setValue("loanApplicationId", _settingData?.loanApplication.id);

                let draft = await ContractDA.layHopDongDaLuu(_settingData?.loanApplication.id);

                if (draft.code === "200") {
                    Object.keys(draft.data).forEach(fieldName => {
                        setValue(fieldName, draft.data[fieldName]);
                    });
                }
            }
        }
    }

    const onCreateContract = async (data) => {
        let res = await ContractDA.taoHopDongPreview(data);
        debugger
        if (!res?.code) {
            const blob = new Blob([res], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(blob);
            setFileHopDong(pdfUrl)
        }
    }

    const luuHopDong = async () => {
        setsaveloading(true)
        let res = await ContractDA.luuHopDong(getValues());
        if (res.code === "200") {
            navigate('/admin/ho-so-cho-xu-ly')
        } else {
            notify(notificationAlert, ERROR, res?.message ?? "Đã có lỗi xảy ra!!")
            setsaveloading(false)
        }
        setsaveloading(false)
    }

    const taoHopDong = async () => {
        setsendloading(true)
        let _data = getValues();
        let filter = false;
        for (let key in _data) {
            if (_data[key] === "") {
                setError(`${key}`, {
                    type: 'manual',
                    message: 'Hãy nhập dữ liệu ',
                });
                filter = true;
            }
        }
        if (filter === false) {
            const sendData = {
                "jlosHost": `${base_loginUrl}`,
                "signResData": {
                    "fis.onboarding.process.model.eContract.SignEcontractReqDto": _data
                }
            }
            let res = await ContractDA.taoHopDong(data['active-user-tasks']['task-summary'][0]['task-id'], sendData);
            if (res === "") {
                navigate('/admin/ho-so-cho-xu-ly')
            } else {
                notify(notificationAlert, ERROR, res ?? "Đã có lỗi xảy ra!!")
            }
        }
        setsendloading(false)
    }

    useEffect(() => {
        getContractInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        data != null ?
            <div className="w-frame w-form wbaseItem-value w-col 4d715fe0-095e-4434-a2f1-3ca7f9fc3bee" level={2} cateid={128}>
                <NotificationAlert ref={notificationAlert} />

                <div className="w-frame wbaseItem-value w-col 5ba58e4b-421d-4b0a-8dc4-b9cc2f469332" level={3} cateid={140}>
                    {
                        thoatKhoiTao &&
                        <WPopup
                            type={PopupType.WARNING}
                            title={"Hủy bổ sung hồ sơ"}
                            message="Các thông tin đã nhập sẽ không được lưu lại"
                            cancelTitle="Tiếp tục nhập"
                            confirmTitle='Xác nhận'
                            cancelAction={() => setThoatKhoiTao(false)}
                            confirmAction={() => { navigate('/admin/ho-so-cho-xu-ly') }}
                            cancelButton={true}
                        />
                    }
                    {
                        showProcess &&
                        <WPopup
                            popupContent={
                                <PopupXemQuyTrinh
                                    closePopup={() => onShowProcess(false)}
                                    code={subId}
                                    loanApplicationId={settingData?.loanApplication?.id}
                                />
                            }
                        />
                    }
                    {
                        showYeuCauBoSung &&
                        <WPopup
                            popupContent={
                                <PopupYeuCauBoSung
                                    closePopup={() => setShowYeuCauBoSung(false)}
                                />
                            }
                            cancelAction={() => setShowYeuCauBoSung(false)}
                        />
                    }
                    <div className="w-frame wbaseItem-value w-row ac845fa1-07d5-4fbd-9bfe-54c59df9854b" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col 3e17e54c-0c6e-43a6-bb92-8bf76cc41b4e" level={5} cateid={140}>
                            <div className="w-frame wbaseItem-value w-row 53a21fea-3f77-4823-96bf-07f4dc1327b0" level={6} cateid={140}>
                                <div className="w-text wbaseItem-value 2455b891-bff7-498a-b813-c816410353e9" level={7} cateid={139}>Tạo hợp đồng tín dụng</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row breadcrumb 9e715141-e92c-4e9d-aa70-b1241a18ceb9" level={6} cateid={140}>
                                <button type="button" className="w-button wbaseItem-value w-row 66f8ee2f-e55c-4e32-a339-925e85451518" level={7} cateid={29}>
                                    <div className="w-text wbaseItem-value 7e0d708a-8238-4939-9bd0-429ee314deba" level={8} cateid={139}>Hồ sơ chờ xử lý</div>
                                    <div className="w-svg wbaseItem-value 4aae2b06-1ca0-48ce-b67f-e7436bfc6863" level={8} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6" />
                                        </svg>
                                    </div>
                                </button>
                                <div className="w-text wbaseItem-value d32e0c17-3e7a-4298-9fb3-8af65a9117e5" level={7} cateid={139}>{settingData?.idHoSo}</div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 8d86de47-87c7-4cc8-888d-70255b6d6723" level={5} cateid={140}>
                            <button onClick={() => { setShowYeuCauBoSung(true) }} type="button" className="w-button wbaseItem-value w-row 2947b28e-93a7-4949-9996-a05cbd89f7db" level={6} cateid={29}>
                                <div className="w-svg wbaseItem-value ffb6b4ef-8510-412c-bf5f-4e44b472aad0" level={7} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 3C6.5 3 2 6.75 2 11.3333C2 15.9167 6.5 19.6667 12 19.6667C12.8333 19.6667 13.6667 19.5833 14.5 19.4167L19.1667 21.25C19.25 21.25 19.3333 21.3333 19.5 21.3333C19.6667 21.3333 19.8333 21.25 20 21.1667C20.25 21 20.3333 20.6667 20.3333 20.4167L19.9167 16.5C21.25 15 22 13.25 22 11.4167C22 6.75 17.5 3 12 3Z" fill="#FC6B03FF" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value 7abe3eaa-0c15-4347-88cc-934a6547c17d" level={7} cateid={139}>Xem yêu cầu bổ sung</div>
                            </button>
                            <button onClick={() => onShowProcess(true)} type="button" className="w-button wbaseItem-value w-row f8bc5a04-81d9-4c22-89f7-55efab685fdf" level={6} cateid={29}>
                                <div className="w-svg wbaseItem-value b8a39fcd-721a-4786-be91-3e18aa2ea845" level={7} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 5.33333V7H5.6V12H8V13.6667H5.6V18.6667H8V20.3333H4.8C4.3584 20.3333 4 19.96 4 19.5V2.83333C4 2.37333 4.3584 2 4.8 2C5.2416 2 5.6 2.37333 5.6 2.83333V5.33333H8Z" fill="#009944FF" />
                                        <path d="M10.3996 8.66699H19.1996C19.6412 8.66699 19.9996 8.29366 19.9996 7.83366V4.50033C19.9996 4.04033 19.6412 3.66699 19.1996 3.66699H10.3996C9.95801 3.66699 9.59961 4.04033 9.59961 4.50033V7.83366C9.59961 8.29366 9.95801 8.66699 10.3996 8.66699Z" fill="#009944FF" />
                                        <path d="M19.1996 22H10.3996C9.95801 22 9.59961 21.6275 9.59961 21.1667V17.8333C9.59961 17.3725 9.95801 17 10.3996 17H19.1996C19.6412 17 19.9996 17.3725 19.9996 17.8333V21.1667C19.9996 21.6275 19.6412 22 19.1996 22Z" fill="#009944FF" />
                                        <path d="M10.3996 15.333H19.1996C19.6412 15.333 19.9996 14.9605 19.9996 14.4997V11.1663C19.9996 10.7063 19.6412 10.333 19.1996 10.333H10.3996C9.95801 10.333 9.59961 10.7063 9.59961 11.1663V14.4997C9.59961 14.9605 9.95801 15.333 10.3996 15.333Z" fill="#009944FF" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value b6ab1c56-297e-40f9-a193-a8b2ba7ed524" level={7} cateid={139}>Xem quy trình</div>
                            </button>
                        </div>
                    </div>
                    {
                        <ThongTinKhoiTao loanId={settingData?.loanApplication?.id} />
                    }

                    <div className="w-frame wbaseItem-value w-row 268a65c7-9b2e-4c2a-bd1d-d2135e9758b9" level={4} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col fb54ba3f-9b04-4dfd-87cb-38025cc5707f" scroll="true" level={5} cateid={140}>

                            <form onSubmit={handleSubmit(onCreateContract)} autoComplete='off' className="w-frame wbaseItem-value w-col 013f403b-c897-4e0d-87ab-ce7ca58ef633" level={6} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col 9e3bb6de-8386-4076-b0bb-29455049381b" level={7} cateid={140}>
                                    <div className="w-text wbaseItem-value 1aefcf82-c089-4dac-ae2e-0f03a53bab78" level={8} cateid={139}>Thông tin hợp đồng</div>
                                    <div className="w-rect wbaseItem-value 89fda672-556d-4791-85c8-3d408c5974d7" level={8} cateid={138} />
                                    <div className="w-frame wbaseItem-value w-col a62bfcf3-aedd-45bb-97ff-1d7dd3379124" level={8} cateid={140}>
                                        <div className="w-frame wbaseItem-value w-row 985cff26-8c7f-48a7-bb32-36ce56bfb19a" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value 100fb070-3633-46ad-aa0d-b901ca281910" level={10} cateid={139}>Số CIF:</div>
                                            <div className="w-text wbaseItem-value b7a3db52-6e32-461e-94b6-9df0e4a30a0f" level={10} cateid={139}>{settingData?.loanApplication?.khachHang?.cif ?? "_"}</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row efaa41a3-8633-4c71-9622-9aabc8b77d7a" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value 100fb070-3633-46ad-aa0d-b901ca281910" level={10} cateid={139}>Số hợp đồng:</div>
                                            <div className="w-text wbaseItem-value b7a3db52-6e32-461e-94b6-9df0e4a30a0f" level={10} cateid={139}>{settingData?.loanApplication?.idHopDong ?? "_"}</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-row 92ff5963-38d2-4675-986b-335d63c40b86" level={9} cateid={140}>
                                            <div className="w-text wbaseItem-value 100fb070-3633-46ad-aa0d-b901ca281910" level={10} cateid={139}>Họ tên KH:</div>
                                            <div className="w-text wbaseItem-value b7a3db52-6e32-461e-94b6-9df0e4a30a0f" level={10} cateid={139}>{settingData?.loanApplication?.customerFullName ?? settingData?.loanApplication?.khachHang.hoTen}</div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col 3e38c299-c0cb-42a5-9146-2a610b897185" level={9} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 1cf4612c-aa7c-4ed0-a662-83505298283c" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value ae6a41b7-96b8-4d99-bcb0-8e3790cf46af" level={11} cateid={139}>Số khung</div>
                                                <div className="w-text wbaseItem-value 8c3b9e11-6ef5-4fcb-b610-5d3439a78c07" level={11} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 4511b64e-0a82-431e-a155-beefb2eeff78" + (errors.chassisNumber && ' helper-text')} helper-text={errors.chassisNumber && 'Nhập số khung'} level={10} cateid={85} placeholder="Số khung">
                                                <div className="wbaseItem-value 6869b307-223d-427d-834f-d68dd3245386" level={11} cateid={86}>
                                                    <div className="textfield">
                                                        <input
                                                            type='text'
                                                            maxLength={17}
                                                            name="chassisNumber"
                                                            placeholder="Số khung"
                                                            {...register("chassisNumber", { required: true })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col 474bd750-2d73-49d0-8bdd-f0096105bf8c" level={9} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 79f8d6d6-4374-41a0-a16f-01af9d774fd4" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value b0a605d8-cd89-4530-89fc-8896553573b5" level={11} cateid={139}>Số máy</div>
                                                <div className="w-text wbaseItem-value a09958ff-9bbf-4586-a75d-1bb5c836ec0d" level={11} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 42aab364-e780-4a2e-8906-b67425b517bb" + (errors.machineNumber && ' helper-text')} helper-text={errors.machineNumber && 'Nhập số máy'} level={10} cateid={85} placeholder="Số máy">
                                                <div className="wbaseItem-value aa26f573-9f51-4ce6-840d-69ac992291d1" level={11} cateid={86}>
                                                    <div className="textfield">
                                                        <input
                                                            type='text'
                                                            maxLength={17}
                                                            name="machineNumber" placeholder="Số máy" {...register("machineNumber", { required: true, maxLength: 200 })} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col f3045621-7695-4ba4-bd18-86c34cd33a85" level={9} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row d5e95b11-f07f-4b9b-a1cc-0f47b0f48c59" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value be34c4b7-4aaa-481c-b86f-69614772276d" level={11} cateid={139}>Màu sắc</div>
                                                <div className="w-text wbaseItem-value b504f9c1-7b73-4ef6-b83a-bb64c9860766" level={11} cateid={139}>*</div>
                                            </div>
                                            <div className={"w-textformfield wbaseItem-value w-row 899e8c90-8ae9-4d4e-9928-50d4910e3f40" + (errors.color && ' helper-text')} helper-text={errors.color && 'Nhập màu sắc'} level={10} cateid={85} placeholder="Màu sắc">
                                                <div className="wbaseItem-value a2fcedce-0f0d-49e7-95a6-8a63feb8b884" level={11} cateid={86}>
                                                    <div className="textfield">
                                                        <input
                                                            type='text'
                                                            maxLength={200} name="color"
                                                            placeholder="Màu sắc" {...register("color", { required: true, maxLength: 200 })} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-frame wbaseItem-value w-col a292f2c9-ab7e-45df-9942-5f812f1b0d5c" level={9} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row b2700d15-adc2-4dc8-b492-3b19338cc2c1" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value 0f20f88b-7d77-448d-80b2-1fbca6dc3a75" level={11} cateid={139}>Ngày thanh toán hàng tháng</div>
                                                <div className="w-text wbaseItem-value 4bfb0fe2-6bbe-4f99-91f8-cb9e01a41a7e" level={11} cateid={139}>*</div>
                                            </div>
                                            <div className={'select2-custom ' + (errors.ngayThanhToanHangThang && 'helper-text')} helper-text={errors.ngayThanhToanHangThang && 'Vui lòng chọn ngày thanh toán'}>
                                                <Controller
                                                    name="ngayThanhToanHangThang"
                                                    control={control}
                                                    {...register("ngayThanhToanHangThang")}
                                                    style={{ order: 2 }}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select2 {...field}
                                                            data={danhsachNgayThanhToan.map((item, _) => { return { name: item.monthlyPaymentDateName, id: item.monthlyPaymentDateCode } })}
                                                            options={{ placeholder: 'Chọn ngày thanh toán' }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="w-button wbaseItem-value w-row 3dd847cb-c72d-4c84-86db-a14c99617286" level={7} cateid={29}>
                                    <div className="w-text wbaseItem-value 2b9db6ee-453e-48c6-96be-2b47be7b3e0a" level={8} cateid={139}>Xem hợp đồng</div>
                                </button>
                            </form>
                        </div>
                        <div className="w-frame w-stack wbaseItem-value c3749a3f-242b-4134-8b46-049afbb76026" level={5} cateid={140} >
                            {/* <iframe
                            title='hopdong'
                            width={"100%"}
                            height={"100%"}
                            src={`https://demo.econtract.fpt.com.vn/app/fi-wv/000011PnIPOaLsQdXxmV1wuDGb/p_001_r_001/act?jwt=${token}`}
                            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin"
                        >
                        </iframe> */}
                            {
                                fileHopDong &&
                                //TODO: check lại file 
                                <iframe
                                    title='img'
                                    src={fileHopDong}
                                    type="application/pdf"
                                    width="100%"
                                    height="100%"
                                    toolbar="none"
                                    style={{ backgroundColor: '#f2f2f2' }}
                                ></iframe>
                                // <Document file={`data:application/pdf;base64,${fileHopDong}`}  />
                            }
                        </div>
                    </div>
                </div>

                <div className="w-frame wbaseItem-value w-row 0989b42b-399f-4f8a-ae1e-4a1fd507d4a7" level={3} cateid={140}>
                    <button onClick={() => setThoatKhoiTao(true)} className="w-button wbaseItem-value debeac30-d6a7-4671-8419-e07cb1b727c7" level={4} cateid={139}>Thoát</button>
                    <div className="w-frame wbaseItem-value w-row eeca5df1-ae41-4324-8b61-c05574b12917" level={4} cateid={140}>
                        <button onClick={saveloading !== true ? () => luuHopDong() : null} type="button" className="w-button wbaseItem-value w-row 56f0d549-696a-486e-98f7-8d5ceda9f318" level={5} cateid={29}>
                            {
                                saveloading === true ?
                                    < FontAwesomeIcon icon={faSpinner} spin style={{ color: "white", order: 1 }} />
                                    :
                                    <div className="w-svg wbaseItem-value eb4ec915-ddd7-4fe0-8c46-0a8d56ff7b17" level={6} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.1665 2H6.49997C5.83694 2 5.20106 2.26339 4.73223 2.73223C4.26339 3.20106 4 3.83694 4 4.49997V21.5831C3.99996 21.6577 4.01993 21.7309 4.05783 21.7951C4.09572 21.8593 4.15015 21.9122 4.21545 21.9482C4.28074 21.9842 4.35449 22.002 4.42902 21.9998C4.50355 21.9976 4.57612 21.9754 4.63916 21.9356L12.3332 17.0765L20.0273 21.9356C20.0938 21.9779 20.171 22.0002 20.2498 21.9998C20.3603 21.9998 20.4663 21.9559 20.5445 21.8777C20.6226 21.7996 20.6665 21.6936 20.6665 21.5831V4.49997C20.6665 3.83694 20.4031 3.20106 19.9343 2.73223C19.4654 2.26339 18.8295 2 18.1665 2V2Z" fill="#28282999" />
                                        </svg>
                                    </div>
                            }
                            <div className="w-text wbaseItem-value 40da156b-0c4a-4973-99c1-91325f63d6da" level={6} cateid={139}>Lưu</div>
                            {sendloading && <FontAwesomeIcon icon={faSpinner} spin style={{ color: "white", order: 3 }} />}
                        </button>
                        <button onClick={sendloading !== true ? () => taoHopDong() : null} type="button" className="w-button wbaseItem-value w-row 23553d54-b44b-46cb-afec-4a183368760a" level={5} cateid={29}>

                            <div className="w-text wbaseItem-value 8117ff72-0ed5-4d2c-ad2a-ef10f4ab0fdb" level={6} cateid={139}>Tạo hợp đồng tín dụng</div>
                            {
                                sendloading === true ?
                                    < FontAwesomeIcon icon={faSpinner} spin style={{ color: "white", order: 1 }} />
                                    :
                                    <div className="w-svg wbaseItem-value 737f8596-683c-4e02-8c5f-934e2390348a" level={6} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M21.7337 5.26627C22.0888 5.62129 22.0888 6.19689 21.7337 6.55192L9.91555 18.3701C9.56053 18.7251 8.98493 18.7251 8.6299 18.3701L2.26627 12.0065C1.91124 11.6514 1.91124 11.0758 2.26627 10.7208C2.62129 10.3658 3.19689 10.3658 3.55192 10.7208L9.27273 16.4416L20.4481 5.26627C20.8031 4.91124 21.3787 4.91124 21.7337 5.26627Z" fill="#FFFFFFFF" />
                                        </svg>
                                    </div>
                            }
                        </button>
                    </div>
                </div>

            </div> : <div></div>
    )
}