import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import '../Nhanhieu/Nhanhieu.css';
import { NavLink, useLocation } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

import { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';
import GroupCer from './group';
import ScoringDA from '../../redux/middleware/api/Scoring/ScoringDA';
import CriterialList from './CriterialList';

// import { ERROR, SUCCESS, notify } from '../../core/AlertNoti';



export default function Tieuchi() {
    const location = useLocation();
    const [ChildItemPage, setChildItemPage] = useState([]);
    const [loading, setloading] = useState(true);
    const notificationAlert = useRef();

    useEffect(() => {
        _init();
    }, []);
    const _init = async () => {
        await _InitData("");
    }

    const _InitData = async () => {
        const res = await ScoringDA.getallCriteriaBygroup()
        if (res != null) {
            setChildItemPage(res)
            setloading(false)
        }
    }


    const onclickEdit = (id) => {

    }

    return (
        <div
            class="w-frame wbaseItem-value w-col 11585551-3457-47b8-8806-9a1507097605"

            level="3"
            cateid="140"
        >
            <NotificationAlert ref={notificationAlert} />

            <div
                class="w-frame wbaseItem-value w-row ba053c92-0d3c-406c-b822-fb382780959d"

                level="4"
                cateid="140"
            >
                <div
                    class="w-frame wbaseItem-value w-col b0d0c195-8b8c-4717-bd12-a0da5fd5768c"
                    level="5"
                    cateid="140"
                >
                    <div
                        class="w-frame wbaseItem-value w-row c3c56e92-5880-4541-a4a0-22048812dede"

                        level="6"
                        cateid="140"
                    >
                        <div
                            class="w-text wbaseItem-value 952b5d38-3f8c-4c91-87b0-433ce1618e6e"
                            level="7"
                            cateid="139"
                        >
                            Danh sách tiêu chí
                        </div>
                    </div>
                    <div
                        class="w-frame wbaseItem-value w-row breadcrumb 30d6ba78-edf8-4d86-8d34-e1928a32a803"

                        level="6"
                        cateid="140"
                    >
                        <button
                            type="button"
                            class="w-button wbaseItem-value w-row 6db83589-e70e-4984-b2b3-5d04e04e6f78"

                            name-field="Button"
                            level="7"
                            cateid="29"
                        >
                            <div
                                class="w-text wbaseItem-value 275a05ba-fe4b-4632-80d8-37722c62f707"
                                name-field="Text"
                                level="8"
                                cateid="139"
                            >
                                Quản trị hệ thống
                            </div>
                            <div
                                class="w-svg wbaseItem-value 679ff436-6837-4124-9e70-08aebbfab12c"
                                level="8"
                                cateid="115"
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z"
                                        fill="#28282999"
                                        fill-opacity="0.6"
                                    ></path>
                                </svg>
                            </div>
                        </button>
                        <div
                            class="w-text wbaseItem-value 1f209f97-2f9f-4bbb-89e8-8474c1550e55"
                            level="7"
                            cateid="139"
                        >
                            Tiêu chí
                        </div>
                    </div>
                </div>

            </div>
                    {
                        loading === true ? null :
                            <CriterialList lst={ChildItemPage}
                                callback_edit={
                                    function (id) {
                                        onclickEdit(id)
                                    }
                                }
                            />
                    }

        </div>
    )
}