import { baseurl } from "../../../../config/ConfigAPI"
import { postData } from "../../../../core/BaseDA";

export default class ChuongTrinhVayDA {
    static getDetailLoanProgramByCode = async (page, size, listCode) => {
        let url = `${baseurl}loanprogram/find-by-loan-program-code-in?pageNum=${page}&pageSize=${size}`;
        let res = await postData(url, {
            obj: {
                "listString": listCode
            }
        });
        return res.data;
    }

    static get_loanprogramItemDetail = async (data) => {
        const url = `${baseurl}loanprogram/search?pageNum=1&pageSize=100`;
        let res = await postData(url, { obj: data });
        if (res?.code === "200") {
            return res.data;
        } else {
            return null;
        }
    }

}