import '../../css/xemHoSo-ThamGinhGiaiNgan.css';

export default function XemHoSoThamDinhGiaiNgan({ hoso }) {
    return (
        <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={4} cateid={140} id="22583c7b-5de4-4ec9-8fdc-633ba9d97581">
            <div className="w-frame wbaseItem-value w-col" wrap="nowrap" level={5} cateid={140} id="3f39d7b6-c00d-492e-a8bf-15cd963217a1">
                <div className="w-frame wbaseItem-value w-row" wrap="nowrap" level={6} cateid={140} id="fec7c798-837e-49d5-b8ab-454ee5838b13">
                    <div className="w-text wbaseItem-value" level={7} cateid={139} id="08531a3d-6a28-4998-a975-f6a263d3cbf5">Kết quả thẩm định</div>
                </div>
                <div className="w-frame wbaseItem-value w-row" wrap="wrap" level={6} cateid={140} id="1d67cefe-723f-41bd-9e13-7aaaf6691e89">
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12" wrap="nowrap" level={7} cateid={140} id="4a241a69-a296-4c68-aca1-e57f56b62bea">
                        <div className="w-text wbaseItem-value" level={8} cateid={139} id="d867196f-32d1-4c50-bb6f-2080e4305042">Kết quả:</div>
                        <div className="w-text wbaseItem-value" level={8} cateid={139} id="138c059f-7ff4-4b94-bcc5-ac669e767b57">
                            {
                                (hoso.maTrangThaiHoSo === "LOAN_006" || hoso.maTrangThaiHoSo === "LOAN_011") ? "Yêu cầu bổ sung" : hoso.maTrangThaiHoSo === "LOAN_016" ? "Đồng ý" : hoso.maTrangThaiHoSo === "LOAN_014" ? "" : "Từ chối"
                            }
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12" wrap="nowrap" level={7} cateid={140} id="2056aaee-517a-459b-84db-8cccdc695fe3">
                        <div className="w-text wbaseItem-value" level={8} cateid={139} id="0b2bd808-b3ec-4715-9cf8-0a463cd6b4b8">Ý kiến thẩm định:</div>
                        <div className="w-text wbaseItem-value" level={8} cateid={139} id="395f16cf-d31a-4956-9bb7-5fb2d54fe8aa">{hoso.ykienThamDinh ?? "_"}</div>
                    </div>
                </div>
            </div>
        </div >
    );
}
