import { useEffect, useState } from "react";
import { getData } from "../../../core/BaseDA";
import "./PopupXemQuyTrinh.css";
import { baseurl } from "../../../config/ConfigAPI";
import { FileStatus } from "../../../const/file-status";

export default function PopupXemQuyTrinh({ closePopup, code, loanApplicationId }) {
    const [svgString, setSvgString] = useState(``);
    const [taskHistory, setTaskHistory] = useState([]);
    const getDiagram = async (code) => {
        debugger;
        let res = await getData(baseurl + "process-instance/diaram-detail/" + code);
        if (res) {
            setSvgString(`${res}`)
        }
    }
    const getTaskHistory = async (loanApplicationId) => {
        let res = await getData(baseurl + "task/history?loanApplicationId=" + loanApplicationId);
        if (res.code === "200") {
            setTaskHistory(res.data);
        }
    }

    useEffect(() => {
        getDiagram(code);
        getTaskHistory(loanApplicationId)
    }, [code, loanApplicationId]);

    return (<div className="w-frame wbaseItem-value w-col xl 2fc5058c-aa70-4190-8928-a229e8870560 w-page no-trans" level={1} cateid={140}>
        <div className="w-frame wbaseItem-value w-row 7f531114-47da-4654-8d27-2a55792430eb" level={2} cateid={140}>
            <div className="w-text wbaseItem-value d2f88554-b49c-4aaf-aaea-6b79b82e0c9c" level={3} cateid={139}>Quy trình</div>
            <button className="w-button wbaseItem-value w-row" onClick={closePopup}>
                <div className="w-svg wbaseItem-value 323b8f9f-82df-47a9-bb29-6fe838ffcc59" level={3} cateid={115}>
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" />
                    </svg>
                </div>
            </button>
        </div>
        <div className="w-frame wbaseItem-value w-col 7d14c2df-d808-4b15-924e-e8c62538c9b4" scroll="true" level={2} cateid={140}>
            <div className="w-frame wbaseItem-value w-col 2d3eefcb-2fd8-4729-937e-2b44f4362ed9" level={3} cateid={140}>
                <div className="w-frame w-row wbaseItem-value 0fd517ff-9b3b-46f6-9d12-caf25d688cf6" level={4} cateid={140} >
                    <img src={`data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`} alt="SVG" />
                </div>
                <div className="w-text wbaseItem-value c69adf6c-3b36-43e6-ba13-0cc1b648b1f5" level={4} cateid={139}>Lịch sử hồ sơ</div>
                <div className="w-frame wbaseItem-value w-row" scroll="true">
                    <table className="w-table wbaseItem-value dc12b0c6-038d-436e-a716-b01eba62b085" type={0} level={4} cateid={117}>
                        <tbody>
                            <tr className="table-row">
                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row 5c65462f-1e20-4ec3-98d8-8619a58e1547" level={5} cateid={140}>
                                        <div className="w-text wbaseItem-value ecde028d-78fb-4bc1-9a6f-3b474f2a0332" level={6} cateid={139}>STT</div>
                                    </div>
                                </td>
                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row 370c3396-4580-4f42-a14c-c5298571d978" level={5} cateid={140}>
                                        <div className="w-text wbaseItem-value a099cf68-2570-4ba8-bb8b-69ec7640d0aa" level={6} cateid={139}>Tên bước</div>
                                    </div>
                                </td>
                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row be47eddb-304f-464c-9f11-cf3e09a31d09" level={5} cateid={140}>
                                        <div className="w-text wbaseItem-value f2a13327-39a6-4d8a-aea6-f11bea92b983" level={6} cateid={139}>Người xử lý</div>
                                    </div>
                                </td>
                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row ba103394-13b8-457f-897d-ddd0df8a2fc3" level={5} cateid={140}>
                                        <div className="w-text wbaseItem-value cc44659c-1c2b-4e81-a257-893ba4221940" level={6} cateid={139}>Thời gian bắt đầu</div>
                                    </div>
                                </td>
                                <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row 92bbe514-e49d-4411-afba-1d9797f3df51" level={5} cateid={140}>
                                        <div className="w-text wbaseItem-value 42f78d21-0064-40eb-84cd-2eb87401f2c3" level={6} cateid={139}>Thời gian kết thúc</div>
                                    </div>
                                </td>
                                <td id="1x6" className="table-cell" rowSpan={1} colSpan={1}>
                                    <div className="w-frame wbaseItem-value w-row 55af58f0-0474-4567-a0cc-c4e2c0e7f2fe" level={5} cateid={140}>
                                        <div className="w-text wbaseItem-value a7417701-b58e-4b20-a496-8c692a56e634" level={6} cateid={139}>Thời gian xử lý (phút)</div>
                                    </div>
                                </td>
                            </tr>
                            {
                                taskHistory.length > 0 &&
                                taskHistory.map((item, index) => {
                                    return (
                                        <tr key={index} className="table-row">
                                            <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row def05c47-da45-439c-a7ed-6dbecb01e9f0" level={5} cateid={140}>
                                                    <div className="w-text wbaseItem-value 9117eefc-b8a6-447e-a014-2d4961cdfadf" level={6} cateid={139}>
                                                        {item.loanApplicationId}
                                                    </div>
                                                </div>
                                            </td>
                                            <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row af3a6b6b-eb04-469b-a8c4-dd7df2a7e2c1" level={5} cateid={140}>
                                                    {
                                                        FileStatus.get_statusCode(item.statusCode)
                                                    }
                                                </div>
                                            </td>
                                            <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 4e4e6109-d29f-48dd-a1ec-fd2f728ff649" level={5} cateid={140}>
                                                    <div className="w-text wbaseItem-value bd21a357-5c90-4373-9646-9c550abb9828" level={6} cateid={139}>
                                                        {item.user.username} - {item.user.name}
                                                    </div>
                                                </div>
                                            </td>
                                            <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 55822655-928d-47d1-80c9-be90ccbc45ec" level={5} cateid={140}>
                                                    <div className="w-text wbaseItem-value 8fa9ac2b-2451-4049-bdcc-32663067b449" level={6} cateid={139}>
                                                        {item.startDate && new Date(item.startDate).toLocaleString()}
                                                    </div>
                                                </div>
                                            </td>
                                            <td id="2x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row f3b3ac31-2b5d-4526-bae8-0ecea56c5be1" level={5} cateid={140}>
                                                    <div className="w-text wbaseItem-value e6c283ab-09ff-4c3e-9de3-9c2ca2f82758" level={6} cateid={139}>
                                                        {item.startDate && new Date(item.startDate).toLocaleString()}
                                                    </div>
                                                </div>
                                            </td>
                                            <td id="2x6" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ae01f68b-2b24-42c4-b473-d353ebc0f430" level={5} cateid={140}>
                                                    <div className="w-text wbaseItem-value 5b6188af-d7f2-4729-a427-e492f8141049" level={6} cateid={139}>
                                                        {(parseInt(item.duration / 60000))}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="w-frame wbaseItem-value w-row 6505fc7a-9dfe-4729-abb4-b08c456eb902" level={2} cateid={140}>
            <div className="w-frame wbaseItem-value w-row 1a595341-f453-4eeb-b7ba-e67154913f08" level={3} cateid={140}>
                <button type="button" onClick={closePopup} className="w-button wbaseItem-value w-row fc4c4113-4940-47ec-8bae-a7f488157632" level={4} cateid={29}>
                    <div className="w-text wbaseItem-value 0176e5e2-7fe2-40ec-bb13-434cc40f4b0a" level={5} cateid={139}>Đóng</div>
                </button>
            </div>
        </div>
    </div>
    );
}
