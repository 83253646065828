import './KhoiTaoHoSo.css';
import './Popup.css';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';

import LayoutCongViec, { ThongTinKhoiTao } from '../CommonView';
import { getTaskDetail, getUserImage, hoSo_doneAction, layTongChiTieuTheoVung, ngayHopDongDuKien } from '../../../redux/middleware/api/congvieccuatoi/CongViecDA';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { getEducationLevelList, getListRelationship, getLoanProgramList, getManufacturesList, getProvincesList, getPurposeList, getbrandList, getcareerList, getdistrictsList, getresidentList, gettitleList, getwardsList, khoiTao_getDealerList } from '../../../redux/middleware/api/Filter/Filter';
import Select2 from '../../../components/select2/Select2.js';
import { Ultis } from '../../../core/utils';
import { PopupType, WPopup } from '../../../components/Popup/Popup';
import { useRef } from 'react';
import FileDA from '../../../redux/middleware/api/FileUpload/FileDA';
import ChuongTrinhVayDA from '../../../redux/middleware/api/W_ChuongTrinhVay/ChuongTrinhVayDA';
import $ from 'jquery';
import NotificationAlert from "react-notification-alert";
import { ERROR, notify } from '../../../core/AlertNoti';
import DTIDA from '../../../redux/middleware/api/DTI/DTIDA';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function KhoiTaoHoSo() {
    // ref
    const notificationAlert = useRef();
    const notificationDTIAlert = useRef();
    // hook form
    const { register, handleSubmit, control, setValue, getValues, setError, clearErrors, watch, formState: { errors } } = useForm({ shouldFocusError: false });
    const { fields: insurance, append: addInsuranceEvent, remove: removeInsuranceEvent } = useFieldArray({
        control,
        name: 'loanApplicationInsurance',
        keyName: 'GID',
    });
    const { fields: fileHoSo, append: addFileHoSoEvent, remove: removeFileHoSoEvent } = useFieldArray({
        control,
        name: 'fileLoanApplication',
        keyName: 'GID',
    });
    //
    const location = useLocation();
    // scroll event
    const [scrollIndex, setScroll] = useState(0);
    const clickScrollTo = (event) => {
        let index = [...event.target.parentElement.children].indexOf(event.target);
        let scrollElement = document.querySelector(`.wbaseItem-value[class*="14d79961-33c6-4084-9373-97ac9758052b"]`);
        scrollElement.scrollTo({
            top: scrollElement.firstChild.children[index].offsetTop,
            behavior: "smooth",
        });
    }
    const handleScroll = (event) => {
        let scrollFrom = event.target.getBoundingClientRect().y;
        let scrollTo = [...event.target.firstChild.children].filter(e => {
            let eRect = e.getBoundingClientRect();
            return (eRect.y + eRect.height / 2) > scrollFrom;
        }).sort((a, b) => {
            let aRect = a.getBoundingClientRect();
            let bRect = b.getBoundingClientRect();
            return (aRect.y + aRect.height / 2) - (bRect.y + bRect.height / 2);
        });
        let index = [...event.target.firstChild.children].indexOf(scrollTo[0]);
        if (index !== scrollIndex) setScroll(index);
    }
    // loading varible
    const [sendloading, setsendloading] = useState(false);
    const [saveloading, setsaveloading] = useState(false);

    //! DATA
    const [data, setData] = useState();
    const [processVariables, setProcessVariables] = useState({});
    const [customerItem, setCustomerItem] = useState({});
    const [settingData, setSettingData] = useState({});

    // dropdown
    const [danhSachChuongTrinhVay, setChuongTrinhVay] = useState([]);
    const [danhSachNhaSanXuat, setNhaSanXuat] = useState([]);
    const [danhSachDaiLy, setDaiLy] = useState([]);
    const [danhSachNhanHieu, setNhanHieu] = useState([]);
    const [danhSachMucDich, setMucDich] = useState([]);
    const [danhSachChucVu, setChucVu] = useState([]);
    const [danhSachNgheNghiep, setNgheNghiep] = useState([]);
    const [danhSachTrinhDoHocVan, setTrinhDoHocVan] = useState([]);
    const [danhSachTinhThanhPho, setTinhThanhPho] = useState([]);
    const [danhSachPhuongXa, setPhuongXa] = useState([]);
    const [danhSachQuanHuyen, setQuanHuyen] = useState([]);
    const [danhSachTinhTrangCuTru, setTinhTrangCuTru] = useState([]);
    const [danhSachQuanHe1, setQuanHe1] = useState([]);
    const [danhSachQuanHe2, setQuanHe2] = useState([]);
    const [danhsachNgayThanhToan, setNgayThanhToan] = useState([]);
    //bảo hiểm
    const [danhSachNhaCungCapBHTN, setdanhSachNhaCungCapBHTN] = useState([]);
    //
    const [loanProgramDetail, setProgramDetail] = useState({});
    // sản phẩm bán kèm
    const [isDangKyThe, setDangKyThe] = useState(true);
    const listThe = [
        { code: "CASH_CARD", name: "Thẻ tiền mặt" },
        { code: "CREDIT_CARD", name: "Thẻ tín dụng" },
    ]
    // thong tin bố sung
    const [isDCTTlikeDCHT, setDCTTlikeDCHT] = useState(false);
    // 
    const [isNguonThuNhapKhac, setNguonThuNhapKhac] = useState(false);
    const [isNoVayKhac, setNoVayKhac] = useState(false);
    const [isNguoiPhuThuoc, setNguoiPhuThuoc] = useState(false);

    const [newNote, setNewNote] = useState("");
    const [nghiNgoLuaDao, setNghiNgoLuaDao] = useState(false);
    // file
    const [listDocument, setListDocument] = useState();
    // popup
    const [thoatKhoiTao, setThoatKhoiTao] = useState(false);
    const [huyKhoiTao, setHuyKhoiTao] = useState(false);
    const [deXuatKhoangVay, setDeXuatKhoangVay] = useState(false);
    const [isDinhDangFile, setDinhDangFile] = useState(false);
    const [isKichThuocFile, setKichThuocFile] = useState(false);

    const [dtiData, setDtiData] = useState({});

    //! INIT DATA
    const subId = location.search.replace("?", "");
    const initData = async () => {
        let res = await getTaskDetail({ id: subId });
        // if (!res["active-user-tasks"]) { //! check lại sau theo status
        //     let subProcess = await getSubProcessTask({ id: subId });
        //     if (subProcess != null) {
        //         res = await getTaskDetail({ id: subProcess["process-instance-id"] });
        //     }
        // }
        if (res) {
            setData(res);
            const _processVariables = res['process-instance-variables'];
            setProcessVariables(_processVariables);

            const _customerItem = _processVariables?.khachHang;
            setCustomerItem(_customerItem);
            // await getUserImage(_customerItem?.cmndHoChieu)
            // debugger

            const _settingData = _processVariables?.loanApplicationResponse?.data;
            setSettingData(_settingData);

            if (_settingData != null) {
                // !d adadasdasdasd
                await getDanhSachChuongTrinhVay(_processVariables?.product?.productCode);

                if (_settingData != null && (settingData.maLoaiTheDangKy !== null || settingData.maLoaiTheDangKy === "")) {
                    setDangKyThe(true);
                    _settingData.maLoaiTheDangKy = "CREDIT_CARD";
                } else {
                    setDangKyThe(false);
                }

                //
                // lấy danh sách phường xã và quận huyện
                if (_settingData.currentAddressProvincesCode != null && _settingData.currentAddressProvincesCode?.length > 0) {
                    getDanhSachQuanHuyen(1, 100, _settingData.currentAddressProvincesCode);
                }

                if (_settingData.currentAddressDistrictCode != null && _settingData.currentAddressDistrictCode?.length > 0) {
                    getDanhSachPhuongXa(1, 100, _settingData.currentAddressDistrictCode);
                    let temp = await layTongChiTieuTheoVung(_settingData?.currentAddressDistrictCode);
                    _settingData.chiPhiSinhHoatHangThang = temp;
                }

                if (_settingData.currentAddressDistrictCode === null || _settingData.currentAddressDistrictCode === '') {
                    let temp = await layTongChiTieuTheoVung(_customerItem?.addressDistrictCode);
                    _settingData.chiPhiSinhHoatHangThang = temp;
                }

                // check data nghi ngờ lừa đảo
                setNghiNgoLuaDao(_settingData?.nghiNgoLuaDao === 1 ?? false);
                // set data cho hook form
                if (_settingData != null) {
                    Object.keys(_settingData).forEach(fieldName => {
                        setValue(fieldName, fieldName.includes("gia") ? Ultis.money(_settingData[fieldName]) : _settingData[fieldName]);
                    });
                }
                // ngày hợp đồng dự kiến, lấy theo ngày thanh toán hàng tháng
                if (settingData.ngayHopDongDuKien === null && settingData.ngayHopDongDuKien === "") {
                    setValue("ngayHopDongDuKien", Ultis.datetoStringDefault().replaceAll('/', '-'));
                    _settingData.ngayHopDongDuKien = Ultis.datetoStringDefault().replaceAll('/', '-');
                }
                let _res = await ngayHopDongDuKien(1, 100, { 'date': `${_settingData.ngayHopDongDuKien}`.replaceAll("/", "-") });
                if (_res) {
                    setNgayThanhToan(_res.pageData);
                    if (_res.pageData?.includes(_settingData.ngayThanhToanHangThang)) {
                        setValue("ngayThanhToanHangThang", '')
                    }
                }

                // chương trình vay, thông tin chi tiết chương trình vay theo mã chương trình vay
                if (_settingData.maChuongTrinhVay != null && _settingData.maChuongTrinhVay !== "") {
                    // handleChangeLoanProgram(_settingData);
                }
                if (_settingData.currentAddressProvincesCode === null || _settingData.currentAddressProvincesCode === '') {
                    setDCTTlikeDCHT(true);
                    setValue("dcTamTru", null)
                    setValue("currentAddressProvincesCode", null)
                    setValue("currentAddressDistrictCode", null)
                    setValue("currentAddressWardsCode", null)
                } else {
                    setDCTTlikeDCHT(false);
                }
                // tài chính khách hàng
                if (_settingData.tongThanhToanNoVayHangThang !== null && _settingData?.tongThanhToanNoVayHangThang !== undefined) {
                    setNoVayKhac(true);
                }
                if (_settingData.thuNhapKhac !== null && _settingData.thuNhapKhac !== undefined) {
                    setNguonThuNhapKhac(true);
                }
                if (_settingData.soNguoiPhuThuoc !== null && _settingData.soNguoiPhuThuoc !== undefined) {
                    setNguoiPhuThuoc(true);
                }
                tinhTongBaoHiem(_settingData.loanApplicationInsurance);
            }

        }
    }
    const [tongBaoHiem, setTongBaoHiem] = useState(0);
    const tinhTongBaoHiem = (insuranceData) => {
        let tong = 0;
        (insuranceData ?? []).forEach((e) => {
            if (e.maHinhThucThanhToan === "TTBH1") {
                tong += parseFloat(e?.soTienBaoHiem ?? 0);
            }
        });
        setValue('soTienBaoHiem', tong);
        setTongBaoHiem(tong)
        return tong;
    }

    const getLSgiaiDoan1 = (listItem, promotionCode) => {
        let output = listItem.find((e) => e.promotionCode === promotionCode && e.udeId === "INTEREST_RATE");
        return output?.udeValue ?? 0
    }

    const handleChangeLoanProgram = async (settingData, updateBH, updateFile) => {
        let data = {
            "loanProgramCode": settingData.maChuongTrinhVay,
            "loanProgramName": settingData.chuongTrinhVay,
            "effectiveDate": "",
            "expirationDate": "",
            "status": "",
            "listCategoryCode": [],
            "listProductCode": []
        }
        let _res = await ChuongTrinhVayDA.get_loanprogramItemDetail(data);
        if (_res) {
            try {
                const loanProgramData = _res.pageData[0];
                setProgramDetail(loanProgramData);
                // update danh sách đại lý theo ctv
                setDaiLy(loanProgramData.dealerDetails);

                //TODO: let _listNSX = danhSachNhaSanXuat.filter(item => item.includes());
                if (updateBH) {
                    let newInsuranceData = [];
                    loanProgramData.insuranceDetails.forEach((item) => {
                        if (item.insuranceType === "L1") {
                            newInsuranceData.push({
                                'idHoSo': settingData.id,
                                'tenBaoHiem': item.insuranceName ?? "",
                                'maBaoHiem': item.insuranceCode ?? "",
                                'maNhaCungCap': item.insuranceSupplier ?? "",
                                'nhaCungCap': item.insuranceSupplierName ?? "",
                                'loaiBaoHiem': item.insuranceTypeName ?? "",
                                'maLoaiBaoHiem': item.insuranceType ?? "",
                                'hinhThucThanhToan': item.paymentTypeName ?? "",
                                'maHinhThucThanhToan': item.paymentType ?? "",
                                'chuKyBaoHiem': item.insurancePeriodName ?? "",
                                'maChuKyBaoHiem': item.insurancePeriod ?? "",
                                'soTienBaoHiem': parseFloat(`${item?.feeInsurance ?? 0}`) + (parseFloat(`${item.feeInsurance ?? 0}`) * parseFloat(`${item.feeInsuranceRate ?? 0}`) / 100),
                                'phiBaoHiem': parseFloat(item.feeInsurance) ?? "",
                                'tyLeBaoHiem': item.feeInsuranceRate ?? "",
                            });
                        }
                    });
                    setValue('loanApplicationInsurance', newInsuranceData);
                    setTongBaoHiem(tinhTongBaoHiem(newInsuranceData));
                }
                // lấy danh sách bảo hiểm tự nguyện
                let _listBHTN = [];
                const uniqueSuppliers = [];
                _listBHTN = loanProgramData.insuranceDetails.filter(item => {
                    if (!uniqueSuppliers.includes(item.insuranceSupplier) && item.insuranceType !== "L1") {
                        uniqueSuppliers.push(item.insuranceSupplier);
                        return true;
                    }
                    return false;
                }).map(item => ({
                    insuranceSupplier: item.insuranceSupplier,
                    insuranceSupplierName: item.insuranceSupplierName
                }));
                setdanhSachNhaCungCapBHTN(_listBHTN);

                setListDocument(loanProgramData.documentDetails);

                //
                let _listCTKBB = [];
                const uniqueFileType = [];
                _listCTKBB = loanProgramData.documentDetails.filter(item => {
                    if (!uniqueFileType.includes(item.documentCode) && item.documentTypeCode !== "CT2") {
                        uniqueFileType.push(item.documentCode);
                        return true;
                    }
                    return false;
                }).map(item => ({
                    documentCode: item.documentCode,
                    documentName: item.documentName
                }));

                setListDocument(_listCTKBB)

                // if (updateFile || (settingData.fileLoanApplication === null || settingData.fileLoanApplication.length === 0)) {
                //     let newFileData = [];
                //     loanProgramData.documentDetails.forEach((item) => {
                //         if (item.documentTypeCode === "CT2") {
                //             newFileData.push({
                //                 "maLoaiHoSo": item.documentCode,
                //                 "tenFileCtv": item.documentName,
                //                 "data": _listCTKBB,
                //                 "isRequired": 1,
                //                 "fileCodeLoanProgram": item.documentCode,
                //             })
                //         }
                //     });
                //     // settingData.fileLoanApplication = newFileData;
                //     setValue('fileLoanApplication', newFileData);
                // }

                if (updateFile || (settingData.fileLoanApplication === null || settingData.fileLoanApplication.length === 0)) {
                    let newFileData = [];
                    loanProgramData.documentDetails.forEach((item) => {
                        if (item.documentTypeCode === "CT2") {
                            newFileData.push({
                                "maLoaiHoSo": item.documentCode,
                                "tenFileCtv": item.documentName,
                                "data": _listCTKBB,
                                "isRequired": 1,
                                "fileCodeLoanProgram": item.documentCode,
                            })
                        }
                    });
                    setValue('fileLoanApplication', newFileData);
                } else {
                    let listFileRequiredCTV = loanProgramData.documentDetails.filter((e) => e.documentTypeCode === "CT2");
                    let listFileRequired = settingData.fileLoanApplication.filter((e) => e.required === 1);

                    if (listFileRequired.length < listFileRequiredCTV.length) {
                        listFileRequiredCTV.forEach((e) => {
                            if (!listFileRequired.some((el) => el.maFileCtv !== e.documentCode)) {
                                settingData.fileLoanApplication.push({
                                    "maLoaiHoSo": e.documentCode,
                                    "tenFileCtv": e.documentName,
                                    "data": _listCTKBB,
                                    "isRequired": 1,
                                    "fileCodeLoanProgram": e.documentCode,
                                })
                            }
                        });
                        setValue('fileLoanApplication', settingData.fileLoanApplication);
                    }
                }
                // setValue('fileLoanApplication', newFile);

                let _selectedLoanTerm = loanProgramData.facilityMas.find((e) => e.promotionCode === settingData.promotionCode);

                if (_selectedLoanTerm) {
                    setValue('kyHanVay', _selectedLoanTerm.stdTenor);
                    setValue('kyHanGiaiDoan1', _selectedLoanTerm.modNo === 1 ? _selectedLoanTerm.stdTenor : _selectedLoanTerm.fixedPeriod ?? "")
                    setValue('laiSuatGiaiDoan1', _selectedLoanTerm.modNo === 1 ? getLSgiaiDoan1(loanProgramDetail.facilityDets, settingData.promotionCode) : _selectedLoanTerm.fixedRate ?? "")
                    setValue('kyHanGiaiDoan2', _selectedLoanTerm.modNo === 1 ? null : _selectedLoanTerm.stdTenor - _selectedLoanTerm.fixedPeriod)
                    setValue('laiSuatGiaiDoan2', _selectedLoanTerm.modNo === 1 ? null : _selectedLoanTerm.intrateRatecode)
                } else {
                    // _selectedLoanTerm ? (_selectedLoanTerm.modNo === 2 ? _selectedLoanTerm.fixedRate : "") : ""
                    setValue('kyHanVay', "");
                    setValue('promotionCode', "");
                    setValue('kyHanGiaiDoan1', "");
                    setValue('laiSuatGiaiDoan1', "");
                    setValue('kyHanGiaiDoan2', "");
                    setValue('laiSuatGiaiDoan2', "");
                }
            } catch (error) {
                console.error("Lỗi data Back End: ", "Thông tin trả về không hợp lệ")
            }
        } else {
            setProgramDetail({});
        }
    }

    // get data dropdown list
    const getDanhSachChuongTrinhVay = async (productCode) => {
        let data = {
            "loanProgramCode": "",
            "loanProgramName": "",
            "effectiveDate": "",
            "expirationDate": "",
            "status": "",
            "listCategoryCode": [],
            "listProductCode": [productCode]
        }
        let _res = await ChuongTrinhVayDA.get_loanprogramItemDetail(data);
        if (_res) {
            setChuongTrinhVay(_res.pageData);
        }
    }

    const getDanhSachNhaSanXuat = async (page, size, name) => {
        let res = await getManufacturesList(1, 100, '');
        if (res != null) {
            setNhaSanXuat(res);
        }
    }
    // const getDanhSachDaiLy = async () => {
    //     let res = await khoiTao_getDealerList();
    //     if (res != null) {
    //         setDaiLy(res.pageData);
    //     }
    // }
    const getDanhSachNhanHieu = async (manufactureId, page, size, name) => {
        let res = await getbrandList(manufactureId, 1, 100, '');
        if (res != null) {
            setNhanHieu(res.content);
        }
    }
    const getDanhSachMucDich = async (page, size, name) => {
        let res = await getPurposeList(1, 100, '');
        if (res != null) {
            setMucDich(res);
        }
    }
    const getDanhSachChucVu = async (page, size, name) => {
        let res = await gettitleList(1, 100);
        if (res != null) {
            setChucVu(res.content);
        }
    }
    const getDanhSachNgheNghiep = async (page, size, name) => {
        let res = await getcareerList(1, 100, '');
        if (res != null) {
            setNgheNghiep(res.content);
        }
    }
    const getDanhSachTrinhDoHocVan = async (page, size, name) => {
        let res = await getEducationLevelList(1, 100, '');
        if (res != null) {
            setTrinhDoHocVan(res.content);
        }
    }
    const getDanhSachTinhThanhPho = async (page, size, name) => {
        let res = await getProvincesList(1, 100, '');
        if (res != null) {
            setTinhThanhPho(res.content);
        }
    }
    const getDanhSachPhuongXa = async (page, size, code) => {
        let res = await getwardsList(1, 100, code);
        if (res != null) {
            setPhuongXa(res.content);
        } else {
            setPhuongXa([])
        }
    }
    const getDanhSachQuanHuyen = async (page, size, code) => {
        let res = await getdistrictsList(1, 100, code);
        if (res != null) {
            setQuanHuyen(res.content);
        } else {
            setQuanHuyen([]);
        }
    }
    const getDanhSachTinhTrangCuTru = async (page, size) => {
        let res = await getresidentList(1, 100);
        if (res != null) {
            setTinhTrangCuTru(res.content);
        }
    }
    const getDanhSachQuanHe = async (page, size) => {
        let res1 = await getListRelationship(1, 100, 1);
        if (res1 != null) {
            setQuanHe1(res1.content);
        }
        let res2 = await getListRelationship(1, 100, 2);
        if (res2 != null) {
            setQuanHe2(res2.content);
        }
    }

    // const getAllInsuranceSubblier = async (page, size) => {
    //     const res = await BaoHiemDA.getListNhaCungCap(1, 100);
    //     if (res != null) {
    //         setInsuranceSubblier(res.pageData);
    //     }
    // }

    // const returnDataInsurance = async (maNhaCungCap) => {
    //     try {
    //         const res = await BaoHiemDA.getListBaoHiemTheoNhaCungCap(1, 100, [maNhaCungCap]);
    //         if (res != null) {
    //             let _list = res.pageData;
    //             return _list.map((el, _) => { return { name: el.insuranceName, id: el.insuranceCode } });
    //         } else {
    //             return [];
    //         }
    //     } catch (error) {
    //         console.error("Error fetching insurance data:", error);
    //         return [];
    //     }
    // }

    // bao hiem
    const addInsurance = () => {
        addInsuranceEvent(
            {
                "maBaoHiem": "",
                "nhaCungCap": "",
                "maNhaCungCap": "",
                "loaiBaoHiem": "",
                "maLoaiBaoHiem": "",
                "tenBaoHiem": "Bảo hiểm tự nguyện",
                "hinhThucThanhToan": "",
                "maHinhThucThanhToan": "",
                "phiBaoHiem": null,
                "chuKyBaoHiem": "",
                "maChuKyBaoHiem": "",
                "tyLeBaoHiem": null,
                "soTienBaoHiem": null,
                "isNew": true,
            }
        );
    }
    const deleteInsurance = (index) => {
        removeInsuranceEvent(index);
    }
    // upload file
    const addFileHoSo = () => {
        let newFileData = [];
        if (listDocument?.length > 0) {
            newFileData = listDocument.map((item, index) => ({ name: item.documentName, id: item?.documentCode ?? index }))
        }

        let newFile = { isNew: true, data: newFileData }
        addFileHoSoEvent(newFile);
    }
    const deleteFileHoSo = (index) => {
        removeFileHoSoEvent(index);
    }

    const fileInputRefs = useRef([]);

    const handleBrowseClick = (index) => {
        fileInputRefs.current[index].click();
    };

    const allowedExtensions = ["doc", "docx", "png", "pdf", "jpg", "jpeg", "xlsx"]
    const handleFileInputChange = async (event, index) => {
        const file = event.target.files[0];
        if (file != null) {
            if (file.size > 5 * 1024 * 1024) {
                setKichThuocFile(true);
                return;
            }
            let _listDot = file.name.split(".");
            if (!allowedExtensions.includes(_listDot[_listDot.length - 1])) {
                setDinhDangFile(true)
                return;
            }
            let formDataFile = new FormData();
            formDataFile.append('file', file);
            let data = getValues();
            formDataFile.append('fileCodeLoanProgram', data.fileLoanApplication[index].fileCodeLoanProgram)
            let res = await FileDA.uploadFile(formDataFile);
            if (res.code === "200") {
                const newList = [...fileHoSo]
                newList[index].id = res.data.id;
                newList[index].name = res.data.ten;
                newList[index].dateCreate = res.data.createdDate;
                newList[index].maNguoiTao = res.data.maNguoiTao;
                newList[index].tenNguoiTao = res.data.tenNguoiTao;
                newList[index].tenFileCtv = res.data.tenFileCtv;
                newList[index].maFileCtv = res.data.maFileCtv;
                newList[index].createdBy = res.data.createdBy;
                newList[index].path = res.data.path;
                newList[index].isRequired = res.data.isRequired;
                newList[index].isNew = false;
                setValue(`fileLoanApplication`, newList)
            }
        }
    };
    //DTI
    const tinhKetQuaDTI = async (show) => {
        const _settingData = getValues();
        const kyHn = parseInt(_settingData.kyHanGiaiDoan1) + parseInt(_settingData.kyHanGiaiDoan2);
        let _data = {
            "prepaymentAmount": parseFloat(_settingData?.soTienTraTruoc), // Số tiền trả trước
            "totalSellingPrice": parseFloat(`${_settingData.giaXe ?? 0}`.replaceAll(',', '')) + parseFloat(`${_settingData.giaPhuTung ?? 0}`.replaceAll(',', '')), //Tổng giá bán
            "soTienBaoHiem": _settingData.soTienBaoHiem, //Số tiền mua bảo hiểm
            "interestRate": parseFloat(`${_settingData.laiSuatGiaiDoan2 == null ? _settingData.laiSuatGiaiDoan1 : _settingData.laiSuatGiaiDoan2}`.replace(" %", "")),  //Phần trăm lãi suất
            "period": kyHn, //Kì hạn
            "monthlyLoanPaymentAtOtherCreditFinance": _settingData.tongThanhToanNoVayHangThang, //Tổng thanh toán nợ vay hằng thang
            "monthlyIncome": _settingData?.thuNhapThang ?? 0, //Thu nhập tháng
            "otherIncome": _settingData?.thuNhapKhac ?? 0, //Thu nhập khác
            "monthlyLivingExpenses": _settingData?.chiPhiSinhHoatHangThang ?? 0,  //Chi phí hằng tháng
            "numberDependents": _settingData?.nguoiPhuThuoc ?? 0, //Số người phụ thuộc
            "minimumPayment": _settingData.minimumPayment, //Khoản thanh toán tối thiểu
        }
        let res = await DTIDA.tinhDTI(_data);
        // console.log(res);
        if (res.code === "200") {
            if (show) setDeXuatKhoangVay(true);
            setDtiData(res.data);
            setSettingData({ ...settingData, "soTienVayGoc": res.data.principalLoanAmount })
        } else if(res.code === "API-001") {
            notify(notificationAlert, ERROR, "Không có dữ liệu DTI")
        } else {
            if (deXuatKhoangVay) notify(notificationDTIAlert, ERROR, res.message)
            else notify(notificationAlert, ERROR, res.message)
        }
    }

    // submit form
    const onSubmit = (data) => {
        handleSendForm();
    };
    const returnData = (action) => {
        let newData = getValues();
        let list_id;
        if (newData.fileLoanApplication?.length > 0)
            list_id = newData.fileLoanApplication
                .map((e) => e.id)
                .filter((id) => id != null && id !== undefined);
        else {
            list_id = []
        }
        if (newNote !== "") {
            let _newObjNote = {
                // "maNguoiTao": settingData.saleUserName,
                "noiDung": newNote,
                "loai": "Hàng hóa",
                "maLoai": "GC1",
                // "tenNguoiTao": settingData.saleName,
                // "createdDate": null,
                // "idHoSo": settingData.id,
                "_action": "edit"
            }
            if (newData.noteLoanApplication?.length > 0) {
                newData.noteLoanApplication.push(_newObjNote);
            } else {
                newData.noteLoanApplication = [_newObjNote];
            }
        }
        let _daily = danhSachDaiLy.find((e) => e.dealerCode === newData.maDaiLy)?.dealerName;
        let _dcDaiLy = danhSachDaiLy.find((e) => e.dealerCode === newData.maDaiLy)?.address;
        let _nhanHieu = danhSachNhanHieu.find((e) => e.id === parseInt(newData.maNhanHieu))?.name;
        let _nhaSanXuat = danhSachNhaSanXuat.find((e) => e.id === parseInt(newData.maNhaSanXuat))?.name;
        let _loaiTheDangKy = listThe.find((e) => e.code === newData.maLoaiTheDangKy)?.name;
        let _ngheNghiep = danhSachNgheNghiep.find((e) => e.code === newData.maNgheNghiep)?.name;
        let _quanHeNLH1 = danhSachQuanHe1.find((e) => e.code === newData.maQhNguoiLienHe1)?.name;
        let _quanHeNLH2 = danhSachQuanHe2.find((e) => e.code === newData.maQhNguoiLienHe2)?.name;
        let _tinhTrangCuTru = danhSachTinhTrangCuTru.find((e) => e.code === newData.maTtNoiCuTru)?.name;
        let _chucVu = danhSachChucVu.find((e) => e.code === newData.maChucVu)?.name;
        let _mucDichVay = danhSachMucDich.find((e) => e.code === newData.maMucDichVay)?.name;
        let _trinhDoHocVan = danhSachTrinhDoHocVan.find((e) => e.code === newData.maTrinhDoHocVan)?.name;
        //
        const mergeData = {
            ...settingData,
            ...newData,
            giaXe: newData.giaXe ? parseFloat(`${newData.giaXe}`.replaceAll(",", "")) : 0,
            giaPhuTung: newData.giaPhuTung ? parseFloat(`${newData.giaPhuTung}`.replaceAll(",", "")) : 0,
            tongGiaBan: parseFloat(`${newData.tongGiaBan}`.replaceAll(",", "").replaceAll(" VND", "")),
            kyHanGiaiDoan1: newData.kyHanGiaiDoan1 ? parseInt(`${newData.kyHanGiaiDoan1}`) : 0,
            kyHanGiaiDoan2: newData.kyHanGiaiDoan2 ? parseInt(`${newData.kyHanGiaiDoan2}`) : 0,
            laiSuatGiaiDoan1: newData.laiSuatGiaiDoan1 ? parseFloat(`${newData.laiSuatGiaiDoan1}`) : 0,
            laiSuatGiaiDoan2: newData.laiSuatGiaiDoan2 ? parseFloat(`${newData.laiSuatGiaiDoan2}`) : 0,
            namCuTru: parseInt(newData.namCuTru ?? "0"),
            thangCuTru: parseInt(newData.thangCuTru ?? "0"),
            maNhanHieu: parseInt(newData.maNhanHieu),
            tongThanhToanNoVayHangThang: parseFloat(newData.tongThanhToanNoVayHangThang),
            thuNhapKhac: parseFloat(newData.thuNhapKhac),
            soNguoiPhuThuoc: parseInt(newData.soNguoiPhuThuoc),
            daiLy: _daily,
            dcDaiLy: _dcDaiLy,
            nhaSanXuat: _nhaSanXuat,
            nhanHieu: _nhanHieu,
            loaiTheDangKy: _loaiTheDangKy,
            tenNgheNghiep: _ngheNghiep,
            qhNguoiLienHe1: _quanHeNLH1,
            qhNguoiLienHe2: _quanHeNLH2,
            ttNoiCuTru: _tinhTrangCuTru,
            tenChucVu: _chucVu,
            mucDichVay: _mucDichVay,
            tenTrinhDoHocVan: _trinhDoHocVan,
        }
        mergeData.fileListId = list_id;
        const sendData = {
            action: action,
            loanApplicationRequest: {
                "fis.onboarding.process.model.jlos.dto.LoanApplicationDTO": {
                    ...mergeData
                },
            }
        }
        return sendData;
    }
    const handleCancelForm = async () => {
        const sendData = returnData("saleCancel");
        try {
            let res = await hoSo_doneAction(data["active-user-tasks"]["task-summary"][0]["task-id"], "saleSend", sendData);
            navigate('/admin/ho-so-cho-xu-ly')
            return res
        } catch (error) {
            // notify(notificationAlert, ERROR, error)
        }
    }


    const handleSendForm = async () => {
        setsendloading(true)
        const sendData = returnData("saleSend");
        try {
            let res = await hoSo_doneAction(data["active-user-tasks"]["task-summary"][0]["task-id"], "saleSend", sendData);
            navigate('/admin/ho-so-cho-xu-ly')
            return res
        } catch (error) {
            // notify(notificationAlert, ERROR, error)
            setsendloading(false)
        }
        setsendloading(false)
    }
    const handleSaveForm = async () => {
        setsaveloading(true)
        const sendData = returnData("saleSave");
        try {
            debugger
            let res = await hoSo_doneAction(data["active-user-tasks"]["task-summary"][0]["task-id"], "saleSave", sendData);
            navigate('/admin/ho-so-cho-xu-ly')
            return res
        } catch (error) {
            // notify(notificationAlert, ERROR, error)
            setsaveloading(false)
        }
        setsaveloading(false)
    }

    // use effect
    useEffect(() => {
        initData();

        getDanhSachNhaSanXuat();
        // getDanhSachDaiLy();
        getDanhSachMucDich();
        getDanhSachChucVu();
        getDanhSachNgheNghiep();
        getDanhSachTrinhDoHocVan();
        getDanhSachTinhThanhPho();
        getDanhSachPhuongXa();
        getDanhSachQuanHuyen();
        getDanhSachTinhTrangCuTru();
        getDanhSachQuanHe();

        // getAllInsuranceSubblier();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ValidateFormField = ({ error, name, placeholder, validate, suffix, onChangeEvent, onBlurEvent, maxLength, validateOnChage }) => {
        return (
            <Controller
                name={name}
                control={control}
                rules={{ required: error && validate ? true : false }}
                render={({ field }) => (
                    <div className={`w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 ${error && 'helper-text'}`}
                        helper-text={error && validate}
                        placeholder={placeholder} level={8} cateid={85} style={{ order: 2 }}>
                        <div className="wbaseItem-value 217b9f90-384d-4edc-845b-7db38fbdc754" level={9} cateid={86}>
                            <div className="textfield">
                                <input {...field}
                                    maxLength={maxLength}
                                    placeholder={placeholder ?? ""}
                                    onChange={(event) => {
                                        if (onChangeEvent != null) validateOnChage(event)
                                        field.onChange(event);
                                        if (onChangeEvent != null) onChangeEvent(event)
                                    }}
                                    onBlur={(ev) => {
                                        if (onChangeEvent != null) onChangeEvent(ev);
                                        if (onBlurEvent != null) onBlurEvent(ev);
                                    }}
                                />
                            </div>
                        </div>
                        {
                            suffix &&
                            <div className="w-text wbaseItem-value 672c0e54-75e0-45cb-b5c0-f912b0deadde" level={12} cateid={139}>
                                {suffix}
                            </div>
                        }
                    </div>
                )}
            />
        )
    }
    const ValidateDropdownFormField = ({ error, name, placeholder, validate, data, onChangeEvent }) => {
        return (
            <Controller
                name={name} control={control}  {...register(`${name}`)} style={{ order: 2 }} rules={{ required: true }}
                render={({ field }) => (
                    <div
                        className={`select2-custom ${error && 'helper-text'}`}
                        helper-text={error && validate}
                        placeholder={placeholder ?? ""}
                    >
                        <Select2 {...field}
                            data={data}
                            options={{ placeholder: placeholder }}
                            onChange={(event) => {
                                if (event.target.value && `${event.target.value}`.length > 0) {
                                    if (onChangeEvent != null) {
                                        onChangeEvent(event)
                                    }
                                    field.onChange(event);
                                }
                                console.log(event.target?.value)
                            }}
                        />
                    </div>
                )}
            />
        )
    }

    const InsuranceItem = ({ item, index }) => {
        const maNhaCungCapFieldName = `loanApplicationInsurance[${index}].maNhaCungCap`;
        const maTenBaoHiem = `loanApplicationInsurance[${index}].maBaoHiem`;

        return (
            <div key={item.id} className="w-frame wbaseItem-value w-col 06a32bdd-a293-44ca-9d43-7e590197131f" level={9} cateid={140}>
                <div className="w-frame wbaseItem-value w-row 5862e631-3e75-42dd-ab3b-a60be9620d45" level={10} cateid={140}>
                    <div className="w-text wbaseItem-value dd79fd22-1879-47d0-8c4a-1917e0dfc778" level={11} cateid={139}>
                        {item.tenBaoHiem}
                    </div>
                    <button onClick={() => deleteInsurance(index)} type="button" className="w-button wbaseItem-value w-row dbf9aef3-44e1-4820-a6c7-b543b345e93b" level={11} cateid={29}>
                        <div className="w-text wbaseItem-value 0392e20b-e79a-4d47-a172-6241ee934899" level={12} cateid={139}>Xóa</div>
                    </button>
                </div>
                <div className="w-frame wbaseItem-value w-row 848909fb-e3b2-4ee7-842f-364a8c7a5a85" level={10} cateid={140}>
                    <div className="w-frame wbaseItem-value w-row 0cb3c276-30d1-46c3-a30a-cb9880cf697c" wrap="wrap" level={11} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col col- col12-lg col12-md col8-xxl col8-xl 74b4e0e0-1aff-4ad2-b1f0-aff1c663f576" level={12} cateid={140}>
                            <div className="w-frame wbaseItem-value w-row c1efbb00-c704-4ab7-b76d-d2df36b4ad2e" level={13} cateid={140}>
                                <div className="w-text wbaseItem-value f1f5986d-6009-4c97-9719-66a26ff0de3a" level={14} cateid={139}>Nhà cung cấp</div>
                                <div className="w-text wbaseItem-value 83f39fbf-2557-4a4c-827c-35fa333a8ef0" level={14} cateid={139}>*</div>
                            </div>
                            {
                                ValidateDropdownFormField({
                                    error: errors[maNhaCungCapFieldName],
                                    name: maNhaCungCapFieldName,
                                    placeholder: "Chọn nhà cung cấp",
                                    validate: "Vui lòng chọn nhà cung cấp",
                                    data: danhSachNhaCungCapBHTN?.length > 0 ? danhSachNhaCungCapBHTN.map((item, _) => { return { name: item.insuranceSupplierName, id: item.insuranceSupplier } }) : [],
                                    onChangeEvent: async (ev) => {
                                        // await getAllInsurance(1, 100, [ev.target.value]);
                                        if (ev.target.value) {
                                            // let data = await returnDataInsurance(ev.target.value);
                                            let danhSachBaoHiem = loanProgramDetail.insuranceDetails.filter((e) => e.insuranceSupplier === ev.target.value && e.insuranceType !== "L1");
                                            let newList = [...insurance];
                                            for (let key in newList[index]) {
                                                if (key !== "isNew")
                                                    newList[index][key] = ''
                                            }
                                            newList[index].maNhaCungCap = ev.target.value;
                                            newList[index].data = danhSachBaoHiem.map((el, _) => { return { name: el.insuranceName, id: el.insuranceCode } });
                                            setValue('loanApplicationInsurance', newList);
                                        }
                                    }
                                })
                            }
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col12-lg col12-md col8-xxl col8-xl 36e8daf0-a595-42dd-b3db-c81f012d08b8" level={12} cateid={140}>
                            <div className="w-frame wbaseItem-value w-row ec3f9caf-70b7-4e01-85fa-ca22d552f16f" level={13} cateid={140}>
                                <div className="w-text wbaseItem-value 3a3677ce-ca31-4b75-a1c4-b8b8a3ec8d29" level={14} cateid={139}>Tên gói bảo hiểm</div>
                                <div className="w-text wbaseItem-value d784c4fb-63c9-446a-8b98-f33db26e8d76" level={14} cateid={139}>*</div>
                            </div>
                            {
                                ValidateDropdownFormField({
                                    error: errors[maTenBaoHiem],
                                    name: maTenBaoHiem,
                                    placeholder: "Chọn nhà bảo hiểm",
                                    validate: "Vui lòng chọn bảo hiểm",
                                    data: item?.data?.length > 0 ? item.data : [],
                                    onChangeEvent: async (ev) => {
                                        if (ev.target.value?.length > 0) {
                                            let _selectedBH = loanProgramDetail.insuranceDetails.find((e) => e.insuranceCode === ev.target.value);
                                            // let _res = await BaoHiemDA.getThongTinBaoHiemTheoTenGoi(1, 100, [ev.target.value]);
                                            // if (_res) {
                                            // let _insuranceInfo = _res?.pageData?.length > 0 ? _res.pageData[0] : [];
                                            // if (_insuranceInfo !== []) {
                                            const newList = [...insurance]
                                            newList[index].idHoSo = settingData.id;
                                            newList[index].tenBaoHiem = _selectedBH.insuranceName ?? "";
                                            newList[index].maBaoHiem = _selectedBH.insuranceCode ?? "";
                                            newList[index].maNhaCungCap = _selectedBH.insuranceSupplier ?? "";
                                            newList[index].nhaCungCap = _selectedBH.insuranceSupplierName ?? "";
                                            newList[index].loaiBaoHiem = _selectedBH.insuranceTypeName ?? "";
                                            newList[index].maLoaiBaoHiem = _selectedBH.insuranceType ?? "";
                                            newList[index].hinhThucThanhToan = _selectedBH.paymentTypeName ?? "";
                                            newList[index].maHinhThucThanhToan = _selectedBH.paymentType ?? "";
                                            newList[index].chuKyBaoHiem = _selectedBH.insurancePeriodName ?? "";
                                            newList[index].maChuKyBaoHiem = _selectedBH.insurancePeriod ?? "";
                                            newList[index].soTienBaoHiem = parseFloat(`${_selectedBH?.feeInsurance ?? 0}`) + (parseFloat(`${_selectedBH.feeInsurance ?? 0}`) * parseFloat(`${_selectedBH.feeInsuranceRate ?? 0}`) / 100);
                                            newList[index].phiBaoHiem = parseFloat(_selectedBH.feeInsurance) ?? "";
                                            newList[index].tyLeBaoHiem = _selectedBH.feeInsuranceRate ?? "";
                                            setValue('loanApplicationInsurance', newList);
                                            // }
                                            // }
                                        }
                                    }
                                })
                            }
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col12-lg col12-md col8-xxl col8-xl 37dfc4d3-59a4-4445-81d3-fb3ae36fe894" level={12} cateid={140}>
                            <div className="w-frame wbaseItem-value w-row 2567b3a7-071e-49cf-9ff3-bd1405abc723" level={13} cateid={140}>
                                <div className="w-text wbaseItem-value a471dc12-607d-4003-b134-4fc5bd14428b" level={14} cateid={139}>Chu kỳ bảo hiểm:</div>
                            </div>
                            <div className="w-textformfield wbaseItem-value w-row c2420bb7-c9ee-4c3f-8ef4-372474f37b72" level={13} cateid={85} placeholder>
                                <div className="wbaseItem-value 75d2002e-a2aa-4d24-b082-6d308a100a7e" level={14} cateid={86}>
                                    <div className="textfield">
                                        <input
                                            value={item.chuKyBaoHiem ?? ""}
                                            name="Textformfield"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col12-lg col12-md col8-xxl col8-xl 46ad985c-9abe-4ce3-a213-ac2affc17eab" level={12} cateid={140}>
                            <div className="w-frame wbaseItem-value w-row e2f1d6b7-077b-4905-b5cd-6eae8e0d6cd2" level={13} cateid={140}>
                                <div className="w-text wbaseItem-value 5e3c0ffd-7e39-47c1-b8c8-c04ca7b8f865" level={14} cateid={139}>Tỷ lệ bảo hiểm (%):</div>
                            </div>
                            <div className="w-textformfield wbaseItem-value w-row 6a407d3d-95f9-43e4-8e2f-cad78b062cbd" level={13} cateid={85} placeholder>
                                <div className="wbaseItem-value 8edd41c5-ad2e-4b9a-96d7-bb00f863768f" level={14} cateid={86}>
                                    <div className="textfield">
                                        <input
                                            value={item.tyLeBaoHiem ?? ""}
                                            name="Textformfield"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-frame wbaseItem-value w-col col- col12-lg col12-md col8-xxl col8-xl 7ae3ba16-05e0-462c-b75e-08fbf7ec67c3" level={12} cateid={140}>
                            <div className="w-frame wbaseItem-value w-row 28ff5be1-a672-44ff-b34e-efdb496bbca5" level={13} cateid={140}>
                                <div className="w-text wbaseItem-value f5745ec9-1ea5-45c0-b600-a9562cc8ea96" level={14} cateid={139}>Hình thức thanh toán:</div>
                            </div>
                            <div className="w-textformfield wbaseItem-value w-row 2dc18c35-a2ec-40af-8cc4-89bf80b4c801" level={13} cateid={85} placeholder>
                                <div className="wbaseItem-value e25bcddc-a135-46ee-bd65-58ee7ef19994" level={14} cateid={86}>
                                    <div className="textfield">
                                        <input
                                            value={item.hinhThucThanhToan ?? ""}
                                            name="Textformfield"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-col 201bd275-94ab-423f-9636-34ae1082f32b" level={11} cateid={140}>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 48533ad5-316a-41e1-9bcc-df82660b6016" level={12} cateid={140}>
                            <div className="w-text wbaseItem-value 455fd82b-9ca3-4399-ac56-e1bb0c8e589c" level={13} cateid={139}>Phí bảo hiểm tạm tính (VND):</div>
                            <div className="w-text wbaseItem-value 4bc34ff8-db55-41cd-8fdd-e5ad4b8bd9f6" level={13} cateid={139}>
                                {Ultis.money(item.phiBaoHiem)}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 ccc05954-4253-4363-906e-9419ea6a9b82" level={12} cateid={140}>
                            <div className="w-text wbaseItem-value 4a123917-1a2b-4bf0-8d84-489ad876952b" level={13} cateid={139}>Số tiền bảo hiểm tạm tính (VND):</div>
                            <div className="w-text wbaseItem-value 3231aa7e-30ef-48ae-9262-31cd706987dd" level={13} cateid={139}>
                                {Ultis.money(item.soTienBaoHiem)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const FileItem = ({ item, index }) => {
        const maLoaiHoSo = `fileLoanApplication[${index}].fileCodeLoanProgram`;
        const tenfile = `fileLoanApplication[${index}].ten`;
        return (
            <tr key={item.id} className={`table-row ${item.isRequired === 1 ? "file_CTV2" : ""}`}>
                <td className="table-cell" rowSpan={1} colSpan={1}>
                    {
                        item.isNew !== true ?
                            <div className="w-frame wbaseItem-value w-row 16806374-e86a-43a2-855a-6ab7c3beb3b2" level={10} cateid={140}>
                                <div className="w-text wbaseItem-value b9f290c2-b63e-4f85-8c44-a02cad758d12" level={11} cateid={139}>{item.tenFileCtv}</div>
                                {
                                    item.isRequired === 1 ? <div className="w-text wbaseItem-value 22b52f72-3bb4-4a34-8600-f4a2ee014bb8" level={11} cateid={139}>*</div> : ""
                                }
                            </div>
                            :
                            <div className="w-frame wbaseItem-value w-row 16806374-e86a-43a2-855a-6ab7c3beb3b2" level={10} cateid={140} style={{ height: "80px" }}>
                                {
                                    ValidateDropdownFormField({
                                        error: errors.fileLoanApplication?.[index]?.fileCodeLoanProgram,
                                        name: maLoaiHoSo,
                                        placeholder: "Chọn loại hồ sơ",
                                        validate: "Vui lòng chọn loại hồ sơ",
                                        data: item.data ?? [],
                                        onChangeEvent: (ev) => {
                                            if (ev.target.value?.length > 0) {
                                                const newFileHoSo = [...fileHoSo];
                                                newFileHoSo[index].fileCodeLoanProgram = ev.target.value;
                                                setValue('fileLoanApplication', newFileHoSo);
                                            }
                                        }
                                    })
                                }
                            </div>
                    }
                </td>
                <td className="table-cell" rowSpan={1} colSpan={1}>
                    <input
                        {...register(`fileLoanApplication[${index}].name`, { required: true })}
                        ref={(el) => (fileInputRefs.current[index] = el)}
                        type="file"
                        style={{ display: 'none' }}
                        name={`${tenfile}`}
                        onChange={(event) => handleFileInputChange(event, index)}
                    />
                    <div className="w-frame wbaseItem-value w-row 162430eb-d099-484f-bc00-1c8f773f26b0" level={10} cateid={140}>
                        {
                            (item.name || item.ten) ?
                                <input
                                    value={item.name ?? item.ten}
                                    style={{ border: 0, backgroundColor: "white" }}
                                    disabled
                                    className="w-text wbaseItem-value 26777ebc-b591-4902-8014-96bf9639613e" level={11} cateid={139}
                                // {...register(`fileLoanApplication[${index}].name`, { required: true })}
                                />
                                :
                                <div
                                    // className={`w-upload-file-field w-frame wbaseItem-value w-row 6ac84289-acb3-4274-819b-ee8371f76a61 ${errors[tenfile] ? "helper-text" : ""}`}
                                    className={"w-upload-file-field w-frame wbaseItem-value w-row 6ac84289-acb3-4274-819b-ee8371f76a61 " + (errors.fileLoanApplication?.[index]?.ten && ' helper-text')}
                                    helper-text={errors.fileLoanApplication?.[index]?.ten && 'Vui lòng upload file'} level={10} cateid={140}>
                                    <button onClick={() => {
                                        if (item.fileCodeLoanProgram?.length > 0) {
                                            handleBrowseClick(index)
                                        } else {
                                            setError(`fileLoanApplication[${index}].fileCodeLoanProgram`, {
                                                type: 'manual',
                                                message: 'Hãy chọn loại hồ sơ',
                                            });
                                        }
                                    }}
                                        type="button" className="w-button wbaseItem-value w-row b085459d-3c5e-4f02-b293-b43dcd2e6825" level={11} cateid={29}>
                                        <div className="w-svg wbaseItem-value b8aefd53-510f-4940-ab2b-a46c5fe69a3e" level={12} cateid={115}>
                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 8.83333L12 3L17 8.83333H12.8333V16.3333H11.1667V8.83333H7Z" fill="#009944FF" />
                                                <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#009944FF" />
                                            </svg>
                                        </div>
                                        <div className="w-text wbaseItem-value 9bfa4e38-4b09-439f-b957-d7fea69801bf" level={12} cateid={139}>Upload<br /></div>
                                    </button>
                                </div>
                        }
                    </div>
                </td>
                <td className="table-cell" rowSpan={1} colSpan={1}>
                    <div className="w-frame wbaseItem-value w-row f0cdac61-b487-4ac4-be5a-93c87d4597db" level={10} cateid={140}>
                        {
                            (item.name || item.ten) ?
                                <>
                                    <button onClick={() => handleBrowseClick(index)} type="button" className="w-button w-svg wbaseItem-value 628d3e27-0619-452e-9b11-a8b26388e197" level={11} cateid={115}>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 8.83333L12 3L17 8.83333H12.8333V16.3333H11.1667V8.83333H7Z" fill="#009944FF" />
                                            <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#009944FF" />
                                        </svg>
                                    </button>
                                    {
                                        item.isRequired === 0 &&
                                        <button onClick={() => deleteFileHoSo(index)} type="button" className="w-button w-svg wbaseItem-value ff04bf15-db3d-44e2-8a27-b1578a338964" level={11} cateid={115}>
                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#E60012FF" />
                                            </svg>
                                        </button>
                                    }
                                </> :
                                <></>
                        }
                    </div>
                </td>
                <td className="table-cell" rowSpan={1} colSpan={1}>
                    <div className="w-frame wbaseItem-value w-row 1519871c-db15-4ad3-8039-1f4dd96c5547" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 0c1c05e8-37b7-429d-8a6e-e493c4732a19" level={11} cateid={139}>
                            {item.maNguoiTao} {item.maNguoiTao ? "-" : ""} {item.tenNguoiTao}
                        </div>
                    </div>
                </td>
                <td className="table-cell" rowSpan={1} colSpan={1}>
                    <div className="w-frame wbaseItem-value w-row 3b7671bc-33f2-4c40-a0b6-d506ce9ad5a0" level={10} cateid={140}>
                        <div className="w-text wbaseItem-value 50fb9203-ce28-48dd-b46e-cc5723bbf6a1" level={11} cateid={139}>
                            {item.dateCreate ? new Date(item.dateCreate).toLocaleDateString() : ""}
                        </div>
                        {
                            item.isNew &&
                            <button type='button' onClick={() => deleteFileHoSo(index)} className="w-button w-svg wbaseItem-value 16928993-0f71-4751-9e9d-b0f0da06b71d" level={11} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.22502 19.6783C5.27202 20.3081 5.55486 20.897 6.01706 21.3274C6.47926 21.7578 7.08681 21.998 7.71835 22H16.2817C16.9132 21.998 17.5208 21.7578 17.983 21.3274C18.4452 20.897 18.728 20.3081 18.775 19.6783L19.5617 8.66667H4.43835L5.22502 19.6783Z" fill="#E60012FF" />
                                    <path d="M21.1667 5.33333H16.1667V2.83333C16.1667 2.61232 16.0789 2.40036 15.9226 2.24408C15.7663 2.0878 15.5543 2 15.3333 2H8.66667C8.44565 2 8.23369 2.0878 8.07741 2.24408C7.92113 2.40036 7.83333 2.61232 7.83333 2.83333V5.33333H2.83333C2.61232 5.33333 2.40036 5.42113 2.24408 5.57741C2.0878 5.73369 2 5.94565 2 6.16667C2 6.38768 2.0878 6.59964 2.24408 6.75592C2.40036 6.9122 2.61232 7 2.83333 7H21.1667C21.3877 7 21.5996 6.9122 21.7559 6.75592C21.9122 6.59964 22 6.38768 22 6.16667C22 5.94565 21.9122 5.73369 21.7559 5.57741C21.5996 5.42113 21.3877 5.33333 21.1667 5.33333ZM9.5 3.66667H14.5V5.33333H9.5V3.66667Z" fill="#E60012FF" />
                                </svg>
                            </button>
                        }
                    </div>
                </td>
            </tr >
        )
    }
    const handleCloseDTI = (event) => {
        if ($('.popup-overlay').is(event.target) || $('.close-popup').is(event.target)) {
            setDeXuatKhoangVay(false);
        }
    };

    const GiaDinhKeQuaDTI = () => {
        let kyhan = parseInt(settingData.kyHanGiaiDoan1) + parseInt(settingData.kyHanGiaiDoan2);
        return ReactDOM.createPortal(
            <div style={{ width: "100%", height: "100%" }}>
                <NotificationAlert ref={notificationDTIAlert} />
                <div onClick={handleCloseDTI} className="popup-overlay" >
                    <div className="w-frame wbaseItem-value w-col min-brp 6206c029-e8f3-492b-9a0e-6300ab372209 no-trans" level={1} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row f2a252a3-beaa-4a0e-b570-27e94543010b" level={2} cateid={140}>
                            <div className="w-text wbaseItem-value dfd9671a-9572-4baf-be4e-cb50a3567528" level={3} cateid={139}>Giả định kết quả DTI</div>
                            <div onClick={handleCloseDTI} className="close-popup nav-link w-svg wbaseItem-value 409238e9-a34f-481e-892f-b59a27643794" level={3} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ pointerEvents: "none" }}>
                                    <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" />
                                </svg>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col 28dc50ae-df83-4604-a493-15b966a8afd6" level={2} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col da98feb3-5798-4b3f-b943-e884eea281d2" level={3} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row 86b65fae-5aa8-48cc-877f-23d0080683cc" level={4} cateid={140}>
                                    <div className="w-text wbaseItem-value f6069550-163e-476b-b5fd-d934f93bcb5a" level={5} cateid={139}>Điểm DTI hiện tại:</div>
                                    <div className="w-text wbaseItem-value c8163621-34fe-4111-a6ec-dd44561af741" level={5} cateid={139}>{settingData.diemDTI?.toFixed(2) ?? "-"}</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-row 9291bca5-8a45-4345-becb-6e9bff82682a" level={4} cateid={140}>
                                    <div className="w-text wbaseItem-value 28d461ae-800d-462f-b9b3-4b77e1be25cd" level={5} cateid={139}>Số tiền vay gốc:</div>
                                    <div className="w-text wbaseItem-value 8d6f2f08-0f3f-4c65-9cca-566c04baa23a" level={5} cateid={139}>{Ultis.money(settingData.soTienVayGoc ?? 0)} VND</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-row 7b2d9a3f-8b67-4dd8-87cd-b6e5eb4a92e9" level={4} cateid={140}>
                                    <div className="w-text wbaseItem-value 3a37c806-4af7-4534-aa47-f6014ec7b0b6" level={5} cateid={139}>Số tiền trả trước:</div>
                                    {
                                        ValidateFormField({
                                            error: errors.soTienTraTruoc,
                                            name: "soTienTraTruoc",
                                            placeholder: "nhập số tiền",
                                            validate: "Vui lòng nhập số tiền",
                                            suffix: "VND",
                                            onblurEvent: () => {
                                                tinhKetQuaDTI(false);
                                            }
                                        })
                                    }
                                    {/* <div className={`w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 ${errors.soTienTraTruoc && 'helper-text'}`}
                                        helper-text={errors.soTienTraTruoc && "Vui lòng nhập năm số tiền"}
                                        placeholder={"Tổng số tiền"} level={8} cateid={85} style={{ order: 2 }}>
                                        <div className="wbaseItem-value 217b9f90-384d-4edc-845b-7db38fbdc754" level={9} cateid={86}>
                                            <div className="textfield">
                                                <input
                                                    {...register('soTienTraTruoc', { required: true })} placeholder="Tổng số tiền"
                                                    onChange={(event) => {
                                                        setValue("soTienTraTruoc", Ultis.money(`${event.target.value}`.replaceAll(',', '')));
                                                    }}
                                                    onBlur={() => { }}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-text wbaseItem-value 672c0e54-75e0-45cb-b5c0-f912b0deadde" level={12} cateid={139}>
                                            VND
                                        </div>
                                    </div> */}
                                </div>
                                <div className="slider-container 0205b5b3-ee73-472c-909b-35151cc83b15" style={{ order: 3 }}>
                                    <div className='gia-tri-dau-cuoi-slide'>
                                        <div className='gia-tri-dau-slide'>0</div>
                                        <div className='gia-tri-cuoi-slide'>1.000.000.000 VND</div>
                                    </div>
                                    <Controller
                                        name="soTienTraTruoc"
                                        control={control}
                                        defaultValue={settingData.soTienTraTruoc}
                                        render={({ field }) => (
                                            <input
                                                type="range"
                                                min="0"
                                                max="1000000000"
                                                value={field.value}
                                                onChange={(ev) => {
                                                    field.onChange(ev);
                                                    setValue("soTienTraTruoc", ev.target.value);
                                                }}
                                                onMouseUp={() => tinhKetQuaDTI(false)}
                                                name='soTienTraTruoc'
                                                className="range-slider"
                                                style={{ '--value': `${field.value / 10000000}%` }}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="w-frame wbaseItem-value w-row af0d7f3b-4b02-4eef-99eb-159c5ebe2cd5" level={4} cateid={140}>
                                    <div className="w-text wbaseItem-value b0ec134c-1eed-44c6-b3a3-4a3af55c878d" level={5} cateid={139}>Kỳ hạn:</div>
                                    {
                                        ValidateFormField({
                                            error: errors.kyHanVay,
                                            name: "kyHanVay",
                                            placeholder: "Nhập kỳ hạn",
                                            validate: "Vui lòng nhập kỳ hạn",
                                            suffix: "Tháng",
                                            onblurEvent: () => { tinhKetQuaDTI(false); }

                                        })
                                    }
                                </div>
                                <div className="slider-container 0205b5b3-ee73-472c-909b-35151cc83b15" style={{ order: 5 }}>
                                    <div className='gia-tri-dau-cuoi-slide'>
                                        <div className='gia-tri-dau-slide'>0</div>
                                        <div className='gia-tri-cuoi-slide'>{kyhan}</div>
                                    </div>
                                    <Controller
                                        name="kyHanVay"
                                        control={control}
                                        defaultValue={settingData.kyHanVay}
                                        render={({ field }) => (
                                            <input
                                                type="range"
                                                min="0"
                                                max={kyhan}
                                                name='kyHanVay'
                                                value={field.value}
                                                onChange={(ev) => {
                                                    field.onChange(ev);
                                                    setValue("kyHanVay", ev.target.value);
                                                }}
                                                onMouseUp={() => tinhKetQuaDTI(false)}
                                                className="range-slider"
                                                style={{ '--value': `${field.value ? parseFloat((field.value / kyhan * 100)) : ""} %` }}
                                            // ${(parseFloat(field.value) / kyhan * 100)?.toFixed(2) ?? 0} %
                                            />
                                        )}
                                    />
                                </div>

                                <div className="w-frame wbaseItem-value w-row 3d698c35-288c-452b-806b-70d86e841819" level={4} cateid={140}>
                                    <div className="w-text wbaseItem-value d82411c2-7c3c-49ca-95df-4fb32b097346" level={5} cateid={139}>Điểm DTI mới:</div>
                                    <div className="w-text wbaseItem-value d0690d82-3a8a-4ba3-a5d6-6e5ca6be83b0" level={5} cateid={139}>{dtiData?.dtiInitialNew?.point?.toFixed(2) ?? "-"}</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-row a5e8413b-6a64-481b-8246-25cf5d3dd602" level={4} cateid={140}>
                                    <div className="w-text wbaseItem-value af99afaa-925d-4235-b2b8-7aac1b3dbe8c" level={5} cateid={139}>Kết quả DTI mới:</div>
                                    <div className={`w-text wbaseItem-value 22b2604d-4118-4b54-8269-1eed36574c39 ${dtiData.dtiResult === "Đạt" ? "pass" : "fail"}`} level={5} cateid={139}>{dtiData.dtiResult === "Đạt" ? "Pass" : "Fail"}</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 536606fe-364d-4f28-9e3a-b4080a654e1c" level={2} cateid={140}>
                            <div onClick={handleCloseDTI} className="close-popup nav-link w-frame wbaseItem-value w-row fa519bd8-bb32-4569-9579-64f7a03de7ba" level={3} cateid={140}>
                                <div className="w-text wbaseItem-value 1816daf4-efe8-4800-9eaa-b99ac6fb7fa6" level={4} cateid={139} style={{ pointerEvents: "none" }}>Thoát</div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row 41d8ea0f-6e8e-41be-98e6-f6009abcff04" level={3} cateid={140}>
                                <button onClick={() => {
                                    // if(dtiData.dtiResult)
                                    setSettingData("diemDTI", dtiData.dtiInitialNew?.point);
                                    setSettingData("ketQuaDTI", dtiData.dtiResult);
                                    setDeXuatKhoangVay(false);
                                }} type="button" className="w-button wbaseItem-value w-row 165d3fb4-0b5b-4a04-9168-829470745e2c" level={4} cateid={29}>
                                    <div className="w-text wbaseItem-value d21811e2-9864-46e2-a2ca-cb9a36aa4f29" level={5} cateid={139}>Áp dụng</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            , document.body
        )
    }

    const navigate = useNavigate();

    return (
        data != null ?
            <>
                {
                    thoatKhoiTao &&
                    <WPopup
                        type={PopupType.WARNING}
                        title={"Hủy bổ sung hồ sơ"}
                        message="Các thông tin đã nhập sẽ không được lưu lại"
                        cancelTitle="Tiếp tục nhập"
                        confirmTitle='Xác nhận'
                        cancelAction={() => setThoatKhoiTao(false)}
                        confirmAction={() => { navigate('/admin/ho-so-cho-xu-ly') }}
                        cancelButton={true}
                    />
                }
                {
                    isKichThuocFile &&
                    <WPopup
                        type={PopupType.WARNING}
                        title={"Tệp tải lên quá lớn"}
                        message={<><span>Dung lượng tối đa cho phép 5MB.</span><span>Vui lòng kiểm tra lại.</span></>}
                        cancelTitle="Đóng"
                        cancelAction={() => setKichThuocFile(false)}
                        cancelButton={true}
                        confirmButton={false}
                    />
                }
                {
                    isDinhDangFile &&
                    <WPopup
                        type={PopupType.WARNING}
                        title={"Tệp tải lên chưa đúng định dạng"}
                        message={<><span>Định dạng file cho phép</span> <span>.doc, .docx,.xls, .xlsx, .jpg, .jpeg, .png, .pdf. </span><span>Vui lòng kiểm tra lại.</span></>}
                        cancelTitle="Đóng"
                        cancelAction={() => setDinhDangFile(false)}
                        cancelButton={true}
                        confirmButton={false}
                    />
                }
                {
                    huyKhoiTao &&
                    <WPopup
                        type={PopupType.ERROR}
                        title={"Hủy hồ sơ"}
                        message="Bạn có chắc muốn hủy hồ sơ"
                        cancelTitle="Tiếp tục nhập"
                        confirmTitle='Xác nhận'
                        cancelAction={() => setHuyKhoiTao(false)}
                        confirmAction={async () => {
                            await handleCancelForm()
                            navigate('/admin/ho-so-cho-xu-ly')
                        }}
                        cancelButton={true}
                    />
                }
                {
                    deXuatKhoangVay &&
                    <GiaDinhKeQuaDTI />
                }
                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' className="w-frame w-form wbaseItem-value w-col fdb8e196-6359-44ef-bc42-d12fe07b14fe" level={2} cateid={140}>
                    <NotificationAlert ref={notificationAlert} />
                    <div className="w-frame wbaseItem-value w-col a76f8f80-ab4f-443b-977c-4cf99ed1ff74" level={3} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row f7afd45b-8834-4960-acc9-6edc88da3ed8" level={4} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col 70971201-bc0f-4e85-9c4a-b0632d6849d0" level={5} cateid={140}>
                                <div className="w-frame wbaseItem-value w-row 89e105c8-9912-493f-a01e-668e7b8b378e" level={6} cateid={140}>
                                    <div className="w-text wbaseItem-value 6dd2565f-4f4a-4af2-81e8-5544f428a565" level={7} cateid={139}>Khởi tạo hồ sơ</div>
                                </div>
                                <div className="w-frame wbaseItem-value w-row breadcrumb 0be06ab7-4fb5-4ee9-bcdc-772b75b72a8a" level={6} cateid={140}>
                                    <button type="button" className="w-button wbaseItem-value w-row dc22605e-5503-452f-8ed8-563fb7a402fe event-click" level={7} cateid={29}>
                                        <div className="w-text wbaseItem-value a077e02e-d416-42e0-bbe9-b6517088d9cc" level={8} cateid={139}>Hồ sơ chờ xử lý</div>
                                        <div className="w-svg wbaseItem-value fd068ddd-5f33-4854-b678-ec9415ce1f50" level={8} cateid={115}>
                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.32236 4.04408C8.96293 3.65197 8.37041 3.65197 8.01097 4.04408C7.66301 4.42368 7.66301 5.03087 8.01097 5.41046L14.0514 12L8.01097 18.5895C7.66301 18.9691 7.66301 19.5763 8.01097 19.9559C8.37041 20.348 8.96293 20.348 9.32236 19.9559L15.989 12.6832C16.337 12.3036 16.337 11.6964 15.989 11.3168L9.32236 4.04408Z" fill="#28282999" fillOpacity="0.6" />
                                            </svg>
                                        </div>
                                    </button>
                                    <div className="w-text wbaseItem-value 4977e1af-a1ec-47e4-95f4-a39a059103b7" level={7} cateid={139}>{settingData?.id}</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col 8c14353a-ab5c-4114-9aea-007e0222672d" level={4} cateid={140}>
                            {<ThongTinKhoiTao loanId={settingData?.id} />}

                            <div className="w-frame wbaseItem-value w-row f3851f2e-2983-46c0-ba07-bd2b2a4bd640" level={5} cateid={140}>
                                <div className="w-frame wbaseItem-value w-col 2b92ef0d-df13-47df-96f3-2ad149cfa688" level={6} cateid={140}>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        className={`ms-ref  w-row 467260d3-7d85-489d-aa09-52d56b72f54b w-button wbaseItem-value ${scrollIndex === 0 ? "selected-mini-sidebar-option" : ""}`}
                                        level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 9c226e46-29cd-47bf-adcf-744e0db33239" level={8} cateid={139}>Thông tin eKYC khách hàng</div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        className={`ms-ref  w-row 467260d3-7d85-489d-aa09-52d56b72f54b w-button wbaseItem-value ${scrollIndex === 1 ? "selected-mini-sidebar-option" : ""}`}
                                        level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 9e9bd59a-104f-45e7-85e8-5ecb32cbc9c4" level={8} cateid={139}>Chương trình vay và sản phẩm</div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        className={`ms-ref  w-row 467260d3-7d85-489d-aa09-52d56b72f54b w-button wbaseItem-value ${scrollIndex === 2 ? "selected-mini-sidebar-option" : ""}`}
                                        level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 2be58a0d-6edc-4c00-9516-c489a230f2c9" level={8} cateid={139}>Thông tin hàng hóa</div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        className={`ms-ref  w-row 467260d3-7d85-489d-aa09-52d56b72f54b w-button wbaseItem-value ${scrollIndex === 3 ? "selected-mini-sidebar-option" : ""}`}
                                        level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value d2025469-bd4c-4b34-8bea-cc003ff40707" level={8} cateid={139}>Thông tin khoản vay</div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        className={`ms-ref  w-row 467260d3-7d85-489d-aa09-52d56b72f54b w-button wbaseItem-value ${scrollIndex === 4 ? "selected-mini-sidebar-option" : ""}`}
                                        level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 0f7ef900-06be-4c4a-9d0a-93c14cbc6238" level={8} cateid={139}>Bảo hiểm</div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        className={`ms-ref  w-row 467260d3-7d85-489d-aa09-52d56b72f54b w-button wbaseItem-value ${scrollIndex === 5 ? "selected-mini-sidebar-option" : ""}`}
                                        level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 41dbe83d-0af0-41ef-bde7-1774be5fd31a" level={8} cateid={139}>Sản phẩm bán kèm</div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        className={`ms-ref  w-row 467260d3-7d85-489d-aa09-52d56b72f54b w-button wbaseItem-value ${scrollIndex === 6 ? "selected-mini-sidebar-option" : ""}`}
                                        level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value ff337cd3-f1f6-44b2-b0a3-b6f0d3297917" level={8} cateid={139}>Thông tin khách hàng bổ sung<br />
                                        </div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        className={`ms-ref  w-row 467260d3-7d85-489d-aa09-52d56b72f54b w-button wbaseItem-value ${scrollIndex === 7 ? "selected-mini-sidebar-option" : ""}`}
                                        level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value e43fac8b-3ab3-484e-86ef-4f21df8194e0" level={8} cateid={139}>Công việc khách hàng</div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        className={`ms-ref  w-row 467260d3-7d85-489d-aa09-52d56b72f54b w-button wbaseItem-value ${scrollIndex === 8 ? "selected-mini-sidebar-option" : ""}`}
                                        level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value d85c462e-0b12-45cf-b9da-f8d0e831fd8d" level={8} cateid={139}>Tài chính khách hàng<br />
                                        </div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        className={`ms-ref  w-row 467260d3-7d85-489d-aa09-52d56b72f54b w-button wbaseItem-value ${scrollIndex === 9 ? "selected-mini-sidebar-option" : ""}`} level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 62ed6a7a-094a-4d95-8679-a9a79b9fc88a" level={8} cateid={139}>Người liên quan</div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        className={`ms-ref  w-row 467260d3-7d85-489d-aa09-52d56b72f54b w-button wbaseItem-value ${scrollIndex === 10 ? "selected-mini-sidebar-option" : ""}`} level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 7832348e-2f6e-4973-b5af-54fb5e930f16" level={8} cateid={139}>Hồ sơ đính kèm</div>
                                    </button>
                                    <button
                                        type='button'
                                        onClick={clickScrollTo}
                                        className={`ms-ref  w-row 467260d3-7d85-489d-aa09-52d56b72f54b w-button wbaseItem-value ${scrollIndex === 11 ? "selected-mini-sidebar-option" : ""}`} level={7} cateid={140}>
                                        <div className="w-text wbaseItem-value 11c483ae-c6fc-4016-864d-8048e2999825" level={8} cateid={139}>Ghi chú<br />
                                        </div>
                                    </button>
                                </div>

                                <div onScroll={handleScroll} className="w-frame wbaseItem-value w-col 14d79961-33c6-4084-9373-97ac9758052b" scroll="true" level={6} cateid={140}>
                                    <div className="w-frame wbaseItem-value w-col 94819c72-1112-4879-b114-f98434cab142" level={7} cateid={140}>
                                        {
                                            LayoutCongViec.EkycKhachHang(customerItem)
                                        }

                                        <div className="w-frame wbaseItem-value w-col fef9d95e-f31c-4f77-87d6-d8ee1bf8f5c2" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 4b42b57a-048b-4cff-9be0-461a4cc157bb" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value adc8c45d-de37-47bb-9a66-83e6ee730677" level={10} cateid={139}>Chương trình vay và sản phẩm</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row 8d765e68-799c-462e-9f19-6e9a0d9a1303" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md a5030a11-faa1-46ec-8898-c0136e5e9eca" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 2b01e941-8f8f-4296-bf2d-a0066368b335" level={11} cateid={139}>Nhóm sản phẩm vay:</div>
                                                    <div className="w-text wbaseItem-value 4d7915e0-364a-470f-830f-35c5e35a3844" level={11} cateid={139}>
                                                        {settingData?.nhomSanPham ?? "_"}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md a8ee8f73-60e9-48ed-a1cb-7323c956e406" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 1da3de22-9659-4b1b-8738-60e8489d01df" level={11} cateid={139}>Sản phẩm vay:</div>
                                                    <div className="w-text wbaseItem-value 8f502fe5-0bdf-4066-befc-544d688fa294" level={11} cateid={139}>
                                                        {settingData?.sanPham ?? "_"}
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md fb33ab0d-d0ea-4ca0-aecc-a5dbf329e551" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 907bcf17-625a-42b7-a1c6-5d12fe5d86a1" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value ba313133-b865-4352-8c31-a21519874b72" level={12} cateid={139}>Chương trình vay</div>
                                                        <div className="w-text wbaseItem-value 2d1f4267-0b4f-4dd3-9de2-1f2c9c3db06d" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {
                                                        ValidateDropdownFormField({
                                                            error: errors.maChuongTrinhVay,
                                                            name: "maChuongTrinhVay",
                                                            placeholder: "Chọn chương trình vay",
                                                            validate: "Vui lòng chọn chương trình vay",
                                                            data: danhSachChuongTrinhVay.filter(item => item.data.status === 1).map((item, _) => { return { id: item.data.loanProgramCode, name: `${item.data.loanProgramCode} - ${item.data.loanProgramName}` } }),
                                                            onChangeEvent: async (ev) => {
                                                                if (ev.target.value?.length > 0 && ev.target.value !== getValues("maChuongTrinhVay")) {
                                                                    let selected = danhSachChuongTrinhVay.find((e) => e.data.loanProgramCode === ev.target.value);
                                                                    setValue('maChuongTrinhVay', ev.target.value);
                                                                    setValue('chuongTrinhVay', selected.data.loanProgramName);
                                                                    setValue('minimumPayment', selected.data.minimumPayment);
                                                                    setValue('promotionCode', '');
                                                                    setValue('maDaiLy', '');
                                                                    await handleChangeLoanProgram(getValues(), true, true);
                                                                }
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md bdbd9cea-b572-4eb3-accb-fb3fc941fc81" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 0e1ff485-04cb-40f5-8e67-5bd5049ffdca" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value dc590596-3681-44e0-874c-33848f6ab75d" level={12} cateid={139}>Đại lý</div>
                                                        <div className="w-text wbaseItem-value 38ed4a08-2b85-4de3-aaef-be100c9ebd8c" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {
                                                        ValidateDropdownFormField({
                                                            error: errors.maDaiLy,
                                                            name: "maDaiLy",
                                                            placeholder: "Chọn đại lý",
                                                            validate: "Vui lòng chọn đại lý",
                                                            data: (danhSachDaiLy ?? []).map((item, _) => { return { name: item.dealerCode + " - " + item.dealerName, id: item.dealerCode } })
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {/* oke */}
                                        <div className="w-frame wbaseItem-value w-col 998fb714-5803-429d-8f72-41d81eba0145" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 7b3c0f9d-4ffd-4f47-9c69-3823fc78cb62" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value 6f167701-0f89-4e27-b219-73817d7a39d0" level={10} cateid={139}>Thông tin hàng hóa</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row 2a041ef9-4833-4cbe-9b2a-5171a8b5cc6a" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md b8580bb7-6a43-4f8c-b6c2-fc905f9d805b" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row f64568c3-b217-4efc-9f0b-b5df2e312f0a" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 4e443db1-34c4-4a2c-a800-5fb6e843188d" level={12} cateid={139}>Nhà sản xuất</div>
                                                        <div className="w-text wbaseItem-value 25729200-e94f-4288-aa52-c15b1e4da148" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {
                                                        ValidateDropdownFormField({
                                                            error: errors.maNhaSanXuat,
                                                            name: "maNhaSanXuat",
                                                            placeholder: "Chọn nhà sản xuất",
                                                            validate: "Vui lòng chọn nhà sản xuất",
                                                            data: danhSachNhaSanXuat.map((item, _) => { return { name: item.name, id: item.id } }),
                                                            onChangeEvent: (ev) => {
                                                                if (ev.target.value > 0) {
                                                                    getDanhSachNhanHieu(ev.target.value);
                                                                }
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 76bc4df7-fa6e-4339-8412-a2f682ccd954" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 44b7c537-45d1-40e6-95ee-3efd9e488c3b" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 0430d7b9-dec4-49b8-836c-7e1ab47b23f2" level={12} cateid={139}>Nhãn hiệu</div>
                                                        <div className="w-text wbaseItem-value fe2b353f-1d61-4648-8fb7-c3a368c04d31" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {
                                                        ValidateDropdownFormField({
                                                            error: errors.maNhanHieu,
                                                            name: "maNhanHieu",
                                                            placeholder: "Chọn nhãn hiệu",
                                                            validate: "Vui lòng chọn nhãn hiệu",
                                                            data: danhSachNhanHieu.map((item, _) => { return { name: item.name, id: item.id } })
                                                        })
                                                    }
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md a8debab2-04fa-4f10-ab99-bbde16e756f3" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row b91ad76c-7e1f-4de4-8e33-48eb2c8d69a3" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 03e41d93-7239-43f7-ab2a-5a9a6db8e915" level={12} cateid={139}>Năm sản xuất</div>
                                                        <div className="w-text wbaseItem-value 9bd254a1-f674-459f-afb4-0730911c09a5" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div className={`w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 ${errors.namSanXuat && 'helper-text'}`}
                                                        helper-text={errors.namSanXuat && "Vui lòng chọn năm sản xuất"}
                                                        placeholder={"Chọn năm sản xuất"} level={8} cateid={85} style={{ order: 2 }}  >
                                                        <div className="wbaseItem-value 217b9f90-384d-4edc-845b-7db38fbdc754" level={9} cateid={86} type="year">
                                                            <div className="textfield">
                                                                <input name='namSanXuat' {...register("namSanXuat", { required: true })} placeholder={"Chọn năm sản xuất"} />
                                                            </div>
                                                            <div className="suffix-btn-txtfd calendar-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                                                    <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" />
                                                                    <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" />
                                                                    <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" />
                                                                    <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                                                    <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                                                    <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" />
                                                                    <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" />
                                                                    <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 51101a10-7a42-4516-9384-ed7ac7382bcb" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 8163018f-3195-40d1-adeb-13108fa73929" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 1ad1c747-1572-4a4d-a6d0-5ab887fafefb" level={12} cateid={139}>Màu sắc</div>
                                                        <div className="w-text wbaseItem-value 9157f35a-d27d-4f06-9754-379af834a83b" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div className={`w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 ${errors.mauSac && 'helper-text'}`}
                                                        helper-text={errors.mauSac && "Vui lòng nhập màu sắc"}
                                                        level={8} cateid={85} style={{ order: 2 }}>
                                                        <div className="wbaseItem-value 217b9f90-384d-4edc-845b-7db38fbdc754" level={9} cateid={86}>
                                                            <div className="textfield">
                                                                <input maxLength={200}
                                                                    name='mauSac'
                                                                    {...register("mauSac")}
                                                                    placeholder={"Nhập màu sắc"}
                                                                    onChange={(ev) => {
                                                                        ev.target.value = ev.target.value.replace(/[&\/\\#,+()$~%.`|!'"\-_=:*?<>{}]/g, "");
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 27de8264-c97d-4ab9-adc6-887789669fa9" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 2ad41019-de2e-4afa-a1c0-dc5e61847ad7" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 90b9838d-e37c-4a6b-9545-4d62f3764ddc" level={12} cateid={139}>Giá bán xe</div>
                                                        <div className="w-text wbaseItem-value 96eac5ca-0aac-4b4b-bd5d-ec365eb9994a" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div className={`w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 ${errors.giaXe && 'helper-text'}`}
                                                        helper-text={errors.giaXe && "Vui lòng nhập giá xe"}
                                                        placeholder={"Vui lòng nhập giá xe"} level={8} cateid={85} style={{ order: 2 }}>
                                                        <div className="wbaseItem-value 217b9f90-384d-4edc-845b-7db38fbdc754" level={9} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    {...register("giaXe", { required: true })}
                                                                    placeholder={"Vui lòng nhập giá xe" ?? ""}
                                                                    onChange={(event) => {
                                                                        setValue("giaXe", Ultis.money(`${event.target.value}`.replaceAll(',', '')));
                                                                        setValue("tongGiaBan", `${Ultis.money(parseFloat(`${event.target.value}`.replaceAll(',', '')) + parseFloat(`${getValues('giaPhuTung')}`.replaceAll(',', '')))} VND`)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="w-text wbaseItem-value 672c0e54-75e0-45cb-b5c0-f912b0deadde" level={12} cateid={139}> VND </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 164a85b7-2d30-4bca-9086-35bfe0d9a4e1" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value b45a4d8a-e55a-45c8-94f0-9c47961100fb" level={12} cateid={139}>Phụ tùng phụ kiện kèm theo</div>
                                                    {
                                                        ValidateFormField({
                                                            name: "phuTung",
                                                            placeholder: "Nhập phụ tùng",
                                                        })
                                                    }
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 82c1c37c-4ecc-4ac0-aa33-0a6f1f039fec" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value f9516cae-1afc-4c1a-9467-3d529b01c91e" level={12} cateid={139}>Giá bán phụ tùng phụ kiện</div>
                                                    <div className={`w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 ${errors.giaPhuTung && 'helper-text'}`} helper-text={errors.giaPhuTung && "Vui lòng nhập giá phụ tùng"}
                                                        placeholder={"Vui lòng nhập giá phụ tùng"} level={8} cateid={85} style={{ order: 2 }}>
                                                        <div className="wbaseItem-value 217b9f90-384d-4edc-845b-7db38fbdc754" level={9} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    {...register("giaPhuTung", { required: true })}
                                                                    placeholder={"Vui lòng nhập giá phụ tùng" ?? ""}
                                                                    onChange={(event) => {
                                                                        if (event.target.value === "") {
                                                                            setValue("giaPhuTung", null);
                                                                        }
                                                                        else {
                                                                            setValue("giaPhuTung", Ultis.money(`${event.target.value}`.replaceAll(',', '')));
                                                                        }
                                                                        setValue("tongGiaBan", `${Ultis.money(parseFloat(`${event.target.value}`.replaceAll(',', '')) + parseFloat(`${getValues('giaXe')}`.replaceAll(',', '')))} VND`)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="w-text wbaseItem-value 672c0e54-75e0-45cb-b5c0-f912b0deadde" level={12} cateid={139}> VND </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md e6545cfa-4e61-4252-b810-61c32a7c22d1" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 87a659d5-6111-43df-a883-44ff77b97ea1" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 3eeee597-a758-4418-9154-39cc74e58a14" level={12} cateid={139}>Tổng giá bán</div>
                                                        <div className="w-text wbaseItem-value 42cd3d29-6f63-4248-8158-fd129ac33e7d" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div style={{ backgroundColor: "white" }} className="w-textformfield wbaseItem-value w-row 40def8c2-11b6-4a9a-99eb-9e73ac2d7958" level={11} cateid={85} placeholder="15.000.000">
                                                        <div className="wbaseItem-value a9394307-c418-4a7c-b1c0-07fe5d9287f9" level={12} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    {...register("tongGiaBan")}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*  */}
                                        <div className="w-frame wbaseItem-value w-col 69ffa95d-7f89-4cc2-a8d7-d78d08511c30" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 2158205c-671f-445f-95a9-304b50b29a87" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value ae988922-e567-4a1a-bb7d-20ea570fb60a" level={10} cateid={139}>Thông tin khoản vay</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row 245a3624-803f-4cd7-90e2-6f3409017c2c" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 133674f4-d7ce-4f3e-8341-a85afff4a27a" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row ed7207e7-4fae-4121-a994-7144ec1e7417" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value d4c12a6b-4b71-4c39-9373-f2157f665f43" level={12} cateid={139}>Tỷ lệ trả trước</div>
                                                        <div className="w-text wbaseItem-value 80538005-8231-4e4f-aa66-08429fd5fe03" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {
                                                        ValidateFormField({
                                                            error: errors.tyLeTraTruoc,
                                                            name: "tyLeTraTruoc",
                                                            placeholder: "Nhập tỷ lệ trả trước",
                                                            validate: errors.tyLeTraTruoc?.message ?? "Vui lòng nhập tỷ lệ trả trước",
                                                            suffix: "%",
                                                            validateOnChage: (ev) => {
                                                                let splitVl = ev.target.value.replace(/[^\d|.]/g, "").split(".");
                                                                    if (splitVl[0] === "") splitVl.shift();
                                                                    if (splitVl.length > 0) {
                                                                        ev.target.value = splitVl[0] + (splitVl.length > 1 ? `.${splitVl.slice(1).join("").substring(0, 2)}` : "");
                                                                    } else {
                                                                        ev.target.value = "";
                                                                    }
                                                            },
                                                            onBlurEvent: (ev) => {
                                                                let percent = parseFloat(ev.target.value);
                                                                if(!isNaN(percent)) {
                                                                    setValue("soTienTraTruoc", (parseFloat(getValues("tongGiaBan")) * percent / 100).toFixed(2).toString().replace(".00",""));
                                                                    setSettingData({...settingData, soTienVayGoc: parseFloat(getValues("tongGiaBan")) * (100 - percent) / 100});
                                                                } else {
                                                                    percent = 0;
                                                                    setValue("soTienTraTruoc", 0);
                                                                    setSettingData({...settingData, soTienVayGoc: parseFloat(getValues("tongGiaBan"))});
                                                                }
                                                                if(percent < loanProgramDetail.data.minimumPrepaymentPercent || percent >  loanProgramDetail.data.maximumPrepaymentPercent) {
                                                                    setError("tyLeTraTruoc", {message: "Tỷ lệ % trả trước không thỏa mãn chương trình vay. Vui lòng thay đổi Tỷ lệ % trả trước hoặc Số tiền trả trước"})
                                                                }
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 8a43e238-1c4f-441f-9f0d-e14ec7dc15c7" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 531c9b01-4556-4f32-a1f1-aa26f709ecf8" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 38678e6e-ca89-480a-bcd5-b3cdfbbad754" level={12} cateid={139}>Số tiền trả trước</div>
                                                        <div className="w-text wbaseItem-value be58fa77-7f9c-4b1b-bc98-9065e25de448" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {
                                                        ValidateFormField({
                                                            error: errors.soTienTraTruoc,
                                                            name: "soTienTraTruoc",
                                                            placeholder: "Nhập số tiền trả trước",
                                                            validate: "Vui lòng nhập số tiền trả trước",
                                                            suffix: "VND",
                                                            validateOnChage: (ev) => {
                                                                ev.target.value = ev.target.value.replace(/\D/g,"");
                                                            },
                                                            onBlurEvent: (ev) => {
                                                                let percent = 0;
                                                                if(!isNaN(parseFloat(ev.target.value))) {
                                                                    percent = (parseFloat(ev.target.value) / parseFloat(getValues("tongGiaBan"))) * 100;
                                                                    setValue("tyLeTraTruoc", (percent).toFixed(1).toString().replace(".0",""));
                                                                    setSettingData({...settingData, soTienVayGoc: parseFloat(getValues("tongGiaBan")) - parseFloat(ev.target.value)});
                                                                } else {
                                                                    setValue("tyLeTraTruoc", 0);
                                                                    setSettingData({...settingData, soTienVayGoc: parseFloat(getValues("tongGiaBan"))});
                                                                }
                                                                if(percent < loanProgramDetail.data.minimumPrepaymentPercent || percent >  loanProgramDetail.data.maximumPrepaymentPercent) {
                                                                    setError("tyLeTraTruoc", {message: "Tỷ lệ % trả trước không thỏa mãn chương trình vay. Vui lòng thay đổi Tỷ lệ % trả trước hoặc Số tiền trả trước"})
                                                                } else if(errors.tyLeTraTruoc) {
                                                                    clearErrors("tyLeTraTruoc")
                                                                }
                                                            }
                                                        })
                                                    }
                                                </div>

                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 52230391-aa78-4671-9ffe-54ab8be1ec75" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 56f055dd-5cb1-4b67-a8fd-5456042d2904" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value d81c8c26-97b9-4af2-af2f-904dfb2448c8" level={12} cateid={139}>Ngày hợp đồng dự kiến</div>
                                                        <div className="w-text wbaseItem-value 3efc0a86-b687-4179-a311-662df2a828c6" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <Controller
                                                        name="ngayHopDongDuKien"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        render={({ field }) => (
                                                            <div className={`w-textformfield wbaseItem-value w-row 92cc4f66-88c7-47b4-a9a7-516c9bd227eb ${errors.ngayHopDongDuKien && 'helper-text'}`} level={11} cateid={85} placeholder="dd/mm/yyyyy">
                                                                <div
                                                                    date-range={Ultis.datetoStringDefault()}
                                                                    className="wbaseItem-value 3acd78ad-5ec5-4a2d-8b2a-d382ca86edaa" level={12} cateid={86} type="date">
                                                                    <div className="textfield">
                                                                        <input
                                                                            {...field}
                                                                            onBlur={async (ev) => {
                                                                                if (ev.target.value?.length > 0 && ev.target.value.replaceAll("/", "-") !== getValues("ngayHopDongDuKien")) {
                                                                                    let tmp = ev;
                                                                                    if (tmp.target.value?.length > 0) {
                                                                                        let _res = await ngayHopDongDuKien(1, 100, { 'date': `${ev.target.value}`.replaceAll("/", "-") });
                                                                                        if (_res) {
                                                                                            setNgayThanhToan(_res.pageData);
                                                                                        }
                                                                                    }
                                                                                    setValue("ngayHopDongDuKien", ev.target.value.replaceAll("/", "-"));
                                                                                    setValue("ngayThanhToanHangThang", "");
                                                                                }
                                                                            }}
                                                                            placeholder="dd-mm-yyyyy"
                                                                        />
                                                                    </div>
                                                                    <div className="suffix-btn-txtfd calendar-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                                                        <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" />
                                                                        <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" />
                                                                        <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" />
                                                                        <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                                                        <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                                                        <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" />
                                                                        <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" />
                                                                        <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                </div>

                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 9e4203e5-21b3-4f31-b376-c2b7adbc2525" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 59b712b3-987a-4823-bffa-27b3a038c177" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 235f5ac5-3f4a-4cc6-84fe-758854da2540" level={12} cateid={139}>Ngày thanh toán hàng tháng</div>
                                                        <div className="w-text wbaseItem-value 30a4c4bc-8321-4219-9991-b9f31c43e0ee" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {
                                                        ValidateDropdownFormField({
                                                            error: errors.ngayThanhToanHangThang,
                                                            name: "ngayThanhToanHangThang",
                                                            placeholder: "Chọn ngày thanh toán",
                                                            validate: "Vui lòng chọn ngày thanh toán",
                                                            data: danhsachNgayThanhToan?.length > 0 ? danhsachNgayThanhToan.map((item, _) => { return { name: item.monthlyPaymentDateName, id: item.monthlyPaymentDateCode } }) : [],
                                                        })
                                                    }
                                                </div>

                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 57e3eb6a-72b2-4fef-b2e3-3240e3429125" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 7faba7fd-f988-4710-9e94-57d21c0e51dd" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 6bb8b092-471f-4cd6-bec5-7ca30afd0a01" level={12} cateid={139}>Số tiền vay gốc</div>
                                                        <div className="w-text wbaseItem-value e50a836b-d26c-4182-8565-2d7a649ec6a6" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div style={{ backgroundColor: "white" }} 
                                                    className={"w-textformfield wbaseItem-value w-row 630c175c-97ba-48f6-a674-7951d2c5df7a " + (settingData?.soTienVayGoc < loanProgramDetail?.data?.minimumLoanAmount || settingData?.soTienVayGoc > loanProgramDetail?.data?.maximumLoanAmount ? "helper-text" : "")} 
                                                    helper-text={settingData?.soTienVayGoc < loanProgramDetail?.data?.minimumLoanAmount || settingData?.soTienVayGoc > loanProgramDetail?.data?.maximumLoanAmount ? "Số tiền vay gốc chưa đạt điều kiện của chương trình vay đã chọn. Vui lòng điều chỉnh giá trị hàng hóa hoặc số tiền trả trước" : ""} 
                                                    level={11} cateid={85}>
                                                        <div className="wbaseItem-value 0000110d-8abd-4f0a-9397-9f4ff19aaa26" level={12} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    value={Ultis.money(settingData?.soTienVayGoc) + " VND"}
                                                                    name="soTienVayGoc"
                                                                    placeholder="0"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md cb9ba298-3d55-419e-b62f-3b6b4881fc57" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 83a8a01a-ccb3-4d9f-98ac-ed7c0ef5a732" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value f2e8cf2e-7060-4c7f-9c46-7737cf449fc3" level={12} cateid={139}>Kỳ hạn vay</div>
                                                        <div className="w-text wbaseItem-value 3b4b82d4-0b95-4e7e-b385-69eb76fc82e2" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {
                                                        ValidateDropdownFormField({
                                                            error: errors.promotionCode,
                                                            name: "promotionCode",
                                                            placeholder: "Chọn kỳ hạn vay",
                                                            validate: "Vui lòng chọn kỳ hạn vay",
                                                            data: loanProgramDetail?.facilityMas?.length > 0 ?
                                                                loanProgramDetail.facilityMas.map((item, _) => {
                                                                    return { name: `${item.stdTenor} tháng`, id: item.promotionCode }
                                                                }) :
                                                                [],
                                                            onChangeEvent: (ev) => {
                                                                if (ev.target.value?.length > 0) {
                                                                    setValue('promotionCode', ev.target.value);
                                                                    let _selectedLoanTerm = loanProgramDetail.facilityMas.find((e) => e.promotionCode === ev.target.value);
                                                                    // setSelectedLoanTerm(selected);
                                                                    setValue('kyHanVay', _selectedLoanTerm.stdTenor)
                                                                    setValue('kyHanGiaiDoan1', _selectedLoanTerm.modNo === 1 ? _selectedLoanTerm.stdTenor : _selectedLoanTerm.fixedPeriod ?? "")
                                                                    setValue('laiSuatGiaiDoan1', _selectedLoanTerm.modNo === 1 ? getLSgiaiDoan1(loanProgramDetail.facilityDets, ev.target.value) : _selectedLoanTerm.fixedRate ?? "")
                                                                    setValue('kyHanGiaiDoan2', _selectedLoanTerm.modNo === 1 ? null : _selectedLoanTerm.stdTenor - _selectedLoanTerm.fixedPeriod)
                                                                    setValue('laiSuatGiaiDoan2', _selectedLoanTerm.modNo === 1 ? null : _selectedLoanTerm.intrateRatecode)
                                                                }
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 28832425-5e2e-4994-b540-9710c07a39b3" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 030a224c-70bf-4f13-8e55-ba8ba4cd29bd" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 2b1effc4-7cfe-4f5b-8718-d60d217c11df" level={12} cateid={139}>Mục đích vay</div>
                                                    </div>
                                                    {
                                                        ValidateDropdownFormField({
                                                            error: errors.maMucDichVay,
                                                            name: "maMucDichVay",
                                                            placeholder: "Chọn mục đích vay",
                                                            validate: "Vui lòng chọn mục đích vay",
                                                            data: danhSachMucDich.map((item, _) => { return { name: item.name, id: item.code } })
                                                        })
                                                    }
                                                </div>
                                                <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col12-lg col24-md 11d6c991-902b-4705-879e-3225930e1407" level={10} cateid={140}>
                                                    <Controller
                                                        name="isThanhToanKhoangVayCu"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <label
                                                                onClick={() => setValue("isThanhToanKhoangVayCu", !field.value)}
                                                                className="w-switch wbaseItem-value 789a2c4f-258c-4ee2-952e-4e8c751157c2" value={field.value}>
                                                                <input
                                                                    {...field}
                                                                    defaultChecked={field.value}
                                                                    type="checkbox"
                                                                    onChange={(event) => {
                                                                        field.value = !field.value;
                                                                        setValue("isThanhToanKhoangVayCu", !field.value)
                                                                    }}
                                                                />
                                                                <span className="slider" />
                                                            </label>
                                                        )}
                                                    />
                                                    <div className="w-frame wbaseItem-value w-row 95d76814-e303-4d0d-8f48-da79fd479b53" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 2f3445de-2608-4ab1-bafe-ece8898b2a96" level={12} cateid={139}>Khách hàng thực hiện thanh toán<br />khoản vay cũ</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-frame wbaseItem-value w-row 245a3624-803f-4cd7-90e2-6f3409017c2c" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 57e3eb6a-72b2-4fef-b2e3-3240e3429125" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 7faba7fd-f988-4710-9e94-57d21c0e51dd" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 6bb8b092-471f-4cd6-bec5-7ca30afd0a01" level={12} cateid={139}>Kỳ hạn giai đoạn 1</div>
                                                    </div>
                                                    <div style={{ backgroundColor: "white" }} className={`w-textformfield wbaseItem-value w-row 630c175c-97ba-48f6-a674-7951d2c5df7a`} level={11} cateid={85}>
                                                        <div className="wbaseItem-value 0000110d-8abd-4f0a-9397-9f4ff19aaa26" level={12} cateid={86}>
                                                            <div className="textfield" >
                                                                <input
                                                                    value={watch('kyHanGiaiDoan1') !== null ? watch('kyHanGiaiDoan1') + " Tháng" : ""}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 57e3eb6a-72b2-4fef-b2e3-3240e3429125" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 7faba7fd-f988-4710-9e94-57d21c0e51dd" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 6bb8b092-471f-4cd6-bec5-7ca30afd0a01" level={12} cateid={139}>lãi suất giai đoạn 1</div>
                                                    </div>
                                                    <div style={{ backgroundColor: "white" }} className={`w-textformfield wbaseItem-value w-row 630c175c-97ba-48f6-a674-7951d2c5df7a`} level={11} cateid={85}>
                                                        <div className="wbaseItem-value 0000110d-8abd-4f0a-9397-9f4ff19aaa26" level={12} cateid={86}>
                                                            <div className="textfield" >
                                                                <input
                                                                    value={watch('laiSuatGiaiDoan1') !== null ? watch('laiSuatGiaiDoan1') + " %" : ""}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 57e3eb6a-72b2-4fef-b2e3-3240e3429125" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 7faba7fd-f988-4710-9e94-57d21c0e51dd" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 6bb8b092-471f-4cd6-bec5-7ca30afd0a01" level={12} cateid={139}>Kỳ hạn giai đoạn 2</div>
                                                    </div>

                                                    <div style={{ backgroundColor: "white" }} className={`w-textformfield wbaseItem-value w-row 630c175c-97ba-48f6-a674-7951d2c5df7a`} level={11} cateid={85}>
                                                        <div className="wbaseItem-value 0000110d-8abd-4f0a-9397-9f4ff19aaa26" level={12} cateid={86}>
                                                            <div className="textfield" >
                                                                <input
                                                                    value={watch('kyHanGiaiDoan2') !== null ? watch('kyHanGiaiDoan2') + " Tháng" : ""}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 57e3eb6a-72b2-4fef-b2e3-3240e3429125" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 7faba7fd-f988-4710-9e94-57d21c0e51dd" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 6bb8b092-471f-4cd6-bec5-7ca30afd0a01" level={12} cateid={139}>lãi suất giai đoạn 2</div>
                                                    </div>
                                                    <div style={{ backgroundColor: "white" }} className={`w-textformfield wbaseItem-value w-row 630c175c-97ba-48f6-a674-7951d2c5df7a`} level={11} cateid={85}>
                                                        <div className="wbaseItem-value 0000110d-8abd-4f0a-9397-9f4ff19aaa26" level={12} cateid={86}>
                                                            <div className="textfield" >
                                                                <input
                                                                    value={watch('laiSuatGiaiDoan2') !== null ? watch('laiSuatGiaiDoan2') + " %" : ""}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/* oke/fail */}
                                        <div className="w-frame wbaseItem-value w-col 6ffb1980-2a8a-4d95-a90e-41251634f17c" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 98d38e6a-b49c-4776-9661-fd856d2b4a8a" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value 35e49c9e-8edc-4f5a-a433-750d22e568dc" level={10} cateid={139}>Bảo hiểm</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col c81ca452-c909-4cf4-894e-c197c091a6c2" level={9} cateid={140}>
                                                {
                                                    insurance?.length > 0 &&
                                                    insurance.filter((e) => e.isNew !== true && e.maLoaiBaoHiem === "L1")?.length > 0 &&
                                                    insurance.filter((e) => e.isNew !== true && e.maLoaiBaoHiem === "L1").map((item, index) => {
                                                        return (
                                                            <div key={item.id} className="w-frame wbaseItem-value w-col 9b033b32-43bd-4dc9-a190-dbe7080db2fc L1" level={10} cateid={140}>
                                                                <div className="w-frame wbaseItem-value w-row 92fd25bc-2ab8-4666-ad41-1adc6231252e" level={11} cateid={140}>
                                                                    <div className="w-text wbaseItem-value bf3f9cda-ee1c-43f6-a836-779a365f2336" level={12} cateid={139}>
                                                                        {item.loaiBaoHiem}
                                                                    </div>
                                                                </div>
                                                                <div className="w-frame wbaseItem-value w-row aede580d-06a0-4e99-bcb3-a872441fbbba" wrap="wrap" level={11} cateid={140}>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 d650b2bf-526f-4ba2-8604-ab80aa7a3fb9" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 71c9c61f-684a-42f1-8b88-09ddc1567b7f" level={13} cateid={139}>Nhà cung cấp</div>
                                                                        <div className="w-text wbaseItem-value 58d630fc-e88a-4ea4-a2af-bf76c9106049" level={13} cateid={139}>
                                                                            {item.nhaCungCap}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 9cea1812-94b6-4d9c-bdbf-5365e3f22d1f" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value d494c4ad-4fe2-46cb-9f1c-605c2a73eee9" level={13} cateid={139}>Tên gói bảo hiểm:</div>
                                                                        <div className="w-text wbaseItem-value 1080ab92-3dc9-4c98-b551-ed7ed9ce8b64" level={13} cateid={139}>
                                                                            {item.tenBaoHiem}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 122d124d-f606-4a1d-8d7b-8bd2cf41c65a" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 3f13def0-7ca6-4c0e-b0aa-41aa0aee4177" level={13} cateid={139}>Hình thức thanh toán:</div>
                                                                        <div className="w-text wbaseItem-value f3779ad6-6e3a-4d6a-a578-94be1759d3a5" level={13} cateid={139}>
                                                                            {item.hinhThucThanhToan}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 2406fc89-740c-46c7-90d9-8a823f4c2f36" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value f9139d0f-e829-4b27-9758-2379035429c2" level={13} cateid={139}>Phí bảo hiểm tạm tính (VND):</div>
                                                                        <div className="w-text wbaseItem-value 0373e5ac-0e46-4839-b753-11ef4106dd00" level={13} cateid={139}>
                                                                            {Ultis.money(item.phiBaoHiem)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 d63587d0-b419-4bb1-9e84-ca7912b2e25a" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 1676f3fd-da3d-470e-b961-2cb748726f4d" level={13} cateid={139}>Chu kỳ bảo hiểm:</div>
                                                                        <div className="w-text wbaseItem-value 5376c706-a6d2-45f9-bceb-ec9cfc11c4c8" level={13} cateid={139}>
                                                                            {item.chuKyBaoHiem}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 6fdd63a4-db20-44df-a152-dabc2267d155" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 7134ff6f-c7ea-466c-a9a8-743d6beb4f6e" level={13} cateid={139}>Tỷ lệ bảo hiểm (%):</div>
                                                                        <div className="w-text wbaseItem-value f76c7502-4bd5-4819-994e-71279c855648" level={13} cateid={139}>
                                                                            {item.tyLeBaoHiem} %
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 723b320e-40d7-4637-beaf-72ffdc3e3c3c" level={12} cateid={140}>

                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 e3ebd81a-307f-431b-b136-7c22eb7f7720" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 59db5e61-beae-4e98-b9df-37635d91b7b2" level={13} cateid={139}>Số tiền bảo hiểm tạm tính (VND):</div>
                                                                        <div className="w-text wbaseItem-value 19beb8c4-3e02-4ace-a4e3-77fcfb890367" level={13} cateid={139}>
                                                                            {Ultis.money(item.soTienBaoHiem)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    insurance?.length > 0 &&
                                                    insurance.filter((e) => e.isNew !== true && e.maLoaiBaoHiem !== "L1")?.length > 0 &&
                                                    insurance.filter((e) => e.isNew !== true && e.maLoaiBaoHiem !== "L1").map((item, index) => {
                                                        return (
                                                            <div key={item.id} className="w-frame wbaseItem-value w-col 9b033b32-43bd-4dc9-a190-dbe7080db2fc L2" level={10} cateid={140}>
                                                                <div className="w-frame wbaseItem-value w-row 92fd25bc-2ab8-4666-ad41-1adc6231252e" level={11} cateid={140}>
                                                                    <div className="w-text wbaseItem-value bf3f9cda-ee1c-43f6-a836-779a365f2336" level={12} cateid={139} style={{ flex: 1 }}>
                                                                        {item?.loaiBaoHiem ?? "Bảo hiểm tự nguyện"}
                                                                    </div>
                                                                    <button onClick={() => deleteInsurance(index)} type="button" className="w-button wbaseItem-value w-row dbf9aef3-44e1-4820-a6c7-b543b345e93b" level={11} cateid={29}>
                                                                        <div className="w-text wbaseItem-value 0392e20b-e79a-4d47-a172-6241ee934899" level={12} cateid={139}>Xóa</div>
                                                                    </button>
                                                                </div>
                                                                <div className="w-frame wbaseItem-value w-row aede580d-06a0-4e99-bcb3-a872441fbbba" wrap="wrap" level={11} cateid={140}>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 d650b2bf-526f-4ba2-8604-ab80aa7a3fb9" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 71c9c61f-684a-42f1-8b88-09ddc1567b7f" level={13} cateid={139}>Nhà cung cấp</div>
                                                                        <div className="w-text wbaseItem-value 58d630fc-e88a-4ea4-a2af-bf76c9106049" level={13} cateid={139}>
                                                                            {item.nhaCungCap}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 9cea1812-94b6-4d9c-bdbf-5365e3f22d1f" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value d494c4ad-4fe2-46cb-9f1c-605c2a73eee9" level={13} cateid={139}>Tên gói bảo hiểm:</div>
                                                                        <div className="w-text wbaseItem-value 1080ab92-3dc9-4c98-b551-ed7ed9ce8b64" level={13} cateid={139}>
                                                                            {item.tenBaoHiem}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 122d124d-f606-4a1d-8d7b-8bd2cf41c65a" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 3f13def0-7ca6-4c0e-b0aa-41aa0aee4177" level={13} cateid={139}>Hình thức thanh toán:</div>
                                                                        <div className="w-text wbaseItem-value f3779ad6-6e3a-4d6a-a578-94be1759d3a5" level={13} cateid={139}>
                                                                            {item.hinhThucThanhToan}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 2406fc89-740c-46c7-90d9-8a823f4c2f36" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value f9139d0f-e829-4b27-9758-2379035429c2" level={13} cateid={139}>Phí bảo hiểm tạm tính (VND):</div>
                                                                        <div className="w-text wbaseItem-value 0373e5ac-0e46-4839-b753-11ef4106dd00" level={13} cateid={139}>
                                                                            {Ultis.money(item.phiBaoHiem)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 d63587d0-b419-4bb1-9e84-ca7912b2e25a" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 1676f3fd-da3d-470e-b961-2cb748726f4d" level={13} cateid={139}>Chu kỳ bảo hiểm:</div>
                                                                        <div className="w-text wbaseItem-value 5376c706-a6d2-45f9-bceb-ec9cfc11c4c8" level={13} cateid={139}>
                                                                            {item.chuKyBaoHiem}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 6fdd63a4-db20-44df-a152-dabc2267d155" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 7134ff6f-c7ea-466c-a9a8-743d6beb4f6e" level={13} cateid={139}>Tỷ lệ bảo hiểm (%):</div>
                                                                        <div className="w-text wbaseItem-value f76c7502-4bd5-4819-994e-71279c855648" level={13} cateid={139}>
                                                                            {item.tyLeBaoHiem} %
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 723b320e-40d7-4637-beaf-72ffdc3e3c3c" level={12} cateid={140}>

                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 e3ebd81a-307f-431b-b136-7c22eb7f7720" level={12} cateid={140}>
                                                                        <div className="w-text wbaseItem-value 59db5e61-beae-4e98-b9df-37635d91b7b2" level={13} cateid={139}>Số tiền bảo hiểm tạm tính (VND):</div>
                                                                        <div className="w-text wbaseItem-value 19beb8c4-3e02-4ace-a4e3-77fcfb890367" level={13} cateid={139}>
                                                                            {Ultis.money(item.soTienBaoHiem)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>


                                            {
                                                insurance?.length > 0 &&
                                                insurance.map((item, index) => {
                                                    return (
                                                        item.isNew === true ?
                                                            InsuranceItem({ item: item, index: index }) :
                                                            <></>
                                                    )
                                                })
                                            }

                                            <button onClick={addInsurance} type="button" className="w-button wbaseItem-value w-row 43407777-94e4-4aa3-8140-6b114310f998" level={9} cateid={29}>
                                                <div className="w-svg wbaseItem-value c6a06ce7-d410-44c0-84ab-768e6c147b9b" level={10} cateid={115}>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z" fill="#FFFFFFFF" />
                                                    </svg>
                                                </div>
                                                <div className="w-text wbaseItem-value 08bff9c8-8fcd-4667-8607-bd4abda0643e" level={10} cateid={139}>Thêm bảo hiểm tự nguyện<br />
                                                </div>
                                            </button>
                                            <div className="w-frame wbaseItem-value w-row 74456bc8-cc2b-4ed0-a083-14130887f36d" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col12-sm col12 7214a72e-a69e-4001-9bfc-e0e960ebe66c" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value e9b3126d-27c5-4218-bfc8-db879e868ae0" level={11} cateid={139}>Tổng số tiền vay mua bảo hiểm:</div>
                                                    <div className="w-text wbaseItem-value 9107cf4c-dace-46d5-9a91-98d2352ace96" level={11} cateid={139}>{Ultis.money(tongBaoHiem)} VND</div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="w-frame wbaseItem-value w-row 047ab5fa-da22-4b70-bf1b-1cb956c430a0" level={8} cateid={140}>
                                            <div className="w-text wbaseItem-value f23ab386-e0d1-4a71-888f-0729f2a54f7a" level={9} cateid={139}>Tổng khoản vay:</div>
                                            <div className="w-text wbaseItem-value a9bff5cb-97ac-4559-b440-2e1f18a99c36" level={9} cateid={139}>{`${Ultis.money(settingData?.soTienVayGoc + tongBaoHiem)} VND`}</div>
                                        </div>


                                        {/* oke */}
                                        <div className="w-frame wbaseItem-value w-col c7ece90d-fc8c-47c3-881d-c95d48ea548f" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 3b62001b-3623-4c08-b76f-60f1d98bf33c" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value bd74bd56-c8e6-4f57-947d-8a67c084dce5" level={10} cateid={139}>Sản phẩm bán kèm</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row 9aff3a24-2dc6-4350-af06-371bfa6a9d60" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col12-lg col24-md 2eb8c683-407f-45e5-a517-d6f31510468f" level={10} cateid={140}>
                                                    <label
                                                        onClick={() => {
                                                            setDangKyThe(!isDangKyThe)
                                                        }}
                                                        className="w-switch wbaseItem-value 327998be-130e-49c5-8ece-ed9b54e6f341" name-field="Switch" level={11} cateid={81}>
                                                        <input
                                                            checked={isDangKyThe}
                                                            type="checkbox"
                                                            name="Switch"
                                                            disabled
                                                        />
                                                        <span className="slider" />
                                                    </label>
                                                    <div className="w-frame wbaseItem-value w-row 348dade6-d0cc-4541-9b45-1cc375c8f2f0" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 8776e251-f74d-4e50-a835-597a02692edc" level={12} cateid={139}>Đăng ký thẻ</div>
                                                    </div>
                                                </div>
                                                {
                                                    isDangKyThe &&
                                                    <>
                                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 90817a10-9c5c-4d46-ab33-f447c5ca837e" level={10} cateid={140}>
                                                            <div className="w-frame wbaseItem-value w-row 16274210-61f9-42d0-b7fb-3cefb385b46f" level={11} cateid={140}>
                                                                <div className="w-text wbaseItem-value 9d5a6c30-af5e-424b-8a45-c18953f64db7" level={12} cateid={139}>Loại thẻ</div>
                                                                {
                                                                    !isDangKyThe && <div className="w-text wbaseItem-value 3c89066b-6657-4509-ba25-eec894b86478" level={12} cateid={139}>*</div>
                                                                }
                                                            </div>
                                                            {
                                                                ValidateDropdownFormField({
                                                                    error: errors.maLoaiTheDangKy,
                                                                    name: "maLoaiTheDangKy",
                                                                    placeholder: "Chọn loại thẻ đăng ký",
                                                                    validate: "Vui lòng chọn loại thẻ đăng ký",
                                                                    data: listThe.map((item, _) => { return { id: item.code, name: item.name } }),
                                                                    onChangeEvent: (ev) => {
                                                                        if (ev.target.value === "CREDIT_CARD") {
                                                                            setValue("theDangKy", "Thẻ tiền mặt")
                                                                        } else {
                                                                            setValue("theDangKy", "Thẻ tín dụng")
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c3ea6dd7-c74b-482e-a0d0-5bbef440b8c6" level={10} cateid={140}>
                                                            <div className="w-frame wbaseItem-value w-row aca1264c-47e9-4247-9ccb-4c70d5defbfb" level={11} cateid={140}>
                                                                <div className="w-text wbaseItem-value 9079b1cc-b727-4b25-90ca-30bfe7c0f9c8" level={12} cateid={139}>Tên in trên thẻ</div>
                                                                <div className="w-text wbaseItem-value 3c89066b-6657-4509-ba25-eec894b86478" level={12} cateid={139}>*</div>
                                                            </div>
                                                            <Controller
                                                                name='tenTheDangKy'
                                                                control={control}
                                                                shouldUnregister={false}
                                                                rules={{ required: true }}
                                                                render={({ field }) => (
                                                                    <div className={`w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 ${errors.tenTheDangKy && 'helper-text'}`}
                                                                        helper-text={errors.tenTheDangKy && "Vui lòng nhập tên in trên thẻ"}
                                                                        placeholder={"Nhập tên in trên thẻ"} name-field="Status" level={8} cateid={85} style={{ order: 2 }}>
                                                                        <div className="wbaseItem-value 217b9f90-384d-4edc-845b-7db38fbdc754" level={9} cateid={86}>
                                                                            <div className="textfield">
                                                                                <input {...field} placeholder={"Nhập tên in trên thẻ"}
                                                                                    value={`${field?.value ?? ""}`.toUpperCase()}
                                                                                    maxLength={"19"}
                                                                                    onChange={(event) => {
                                                                                        field.onChange(event);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </>

                                                }

                                            </div>
                                        </div>

                                        {/* fail */}
                                        <div className="w-frame wbaseItem-value w-col 92e5122a-2b68-4971-921b-786baf686cb8" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 46af7618-475d-42ac-b6f2-d8012bd1e79e" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value f0f60534-813c-4523-91e6-d3b88fc688fb" level={10} cateid={139}>Thông tin khách hàng bổ sung</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row 999f7330-037a-4368-8356-e4f8c562f415" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 45f73610-d264-4eec-b019-cc548be4198e" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 74bee406-ffc3-4400-a75b-af70b1c052be" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value fb1d7791-70bc-458f-9a41-2ca9c7570f0f" level={12} cateid={139}>Tên thường gọi ở nhà</div>
                                                        <div className="w-text wbaseItem-value 6c8a158a-1dd7-4c3d-9760-ba664415e4d3" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {/* {
                                                        ValidateFormField({
                                                            error: errors.tenONha,
                                                            name: "tenONha",
                                                            placeholder: "Nhập tên ở nhà",
                                                            validate: "Vui lòng nhập ten ở nhà"
                                                        })
                                                    } */}
                                                    <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.tenONha && 'helper-text')}
                                                        name-field="RoleID" level={8} cateid={85} placeholder="Nhập tên công ty"
                                                        helper-text={errors.tenONha && 'Vui lòng nhập tên công ty'}
                                                    >
                                                        <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f" name-field="Textfield"
                                                            level={9} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    maxLength="200"
                                                                    name="tenONha"
                                                                    placeholder="Nhập tên công ty"  {...register("tenONha", { required: true })} />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 8f5a4d98-5020-4124-b6a8-abd9801c0584" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row da7478eb-87b4-4eac-917f-c9d63d53e238" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value cb29aeee-8bdd-4b62-ad40-593ef971c5ee" level={12} cateid={139}>Trình độ học vấn</div>
                                                        <div className="w-text wbaseItem-value 752656b5-923a-4499-a2f3-4a3f4b54d86f" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {
                                                        ValidateDropdownFormField({
                                                            error: errors.maTrinhDoHocVan,
                                                            name: "maTrinhDoHocVan",
                                                            placeholder: "Chọn trình độ học vấn",
                                                            validate: "Vui lòng chọn trình độ học vấn",
                                                            data: danhSachTrinhDoHocVan?.length > 0 ? danhSachTrinhDoHocVan.map((item, _) => { return { name: item.name, id: item.code } }) : [],
                                                        })
                                                    }
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 9fce7320-1aa7-47ba-b2f4-b4d64b5ed87d" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row f4fac274-8e7a-426a-9f40-e73e7cb64dde" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 7ec332ff-f6f7-48c9-8d29-af458869e183" level={12} cateid={139}>Số điện thoại di động</div>
                                                        <div className="w-text wbaseItem-value 83ff1f0b-99b5-4e31-b8ce-542f37194d0e" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div className="w-textformfield wbaseItem-value w-row b5374a55-ccd0-41e8-be1c-a11eefc862e6" level={11} cateid={85} placeholder>
                                                        <div className="wbaseItem-value c1e1c0ac-e055-4b71-b99f-80cc7dd1d792" level={12} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    value={customerItem?.dtDiDong}
                                                                    name="Textformfield"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row f824944e-2c8f-4770-bd23-9ff4f2362c7e" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md b4167392-f4f4-413e-b110-02c8a4756fa4" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row b912b24b-fff2-4002-9401-c796772539f7" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 9e5a1b40-edc7-4b05-a977-b8b4ec8f9f2b" level={12} cateid={139}>Tỉnh/Thành phố</div>
                                                        <div className="w-text wbaseItem-value 734dd831-b6fc-46a3-bf18-cab01d56f38b" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div className="w-textformfield wbaseItem-value w-row 74526e37-2348-4139-beda-23ec11814603" level={11} cateid={85} placeholder>
                                                        <div className="wbaseItem-value f55b4a03-2f61-4a4d-98c6-2d1935a67c42" level={12} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    value={settingData?.addressProvincesName}
                                                                    name="Textformfield"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 7d8183d9-7379-4bfd-a140-0bb93601f5e0" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 1fb17aac-47be-43fd-82b3-7a1725e2a4d2" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 6947f263-f00e-4e02-bbd9-677d97e14a40" level={12} cateid={139}>Quận/Huyện</div>
                                                        <div className="w-text wbaseItem-value 1f442f92-704a-43c0-95a8-46c695532d29" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div className="w-textformfield wbaseItem-value w-row b76ecdc0-018c-451a-bee1-97a7cfc08ece" level={11} cateid={85} placeholder>
                                                        <div className="wbaseItem-value 2328de85-bca9-4ea6-8ab6-f440f989081b" level={12} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    value={settingData?.addressDistrictName}
                                                                    name="Textformfield"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md f36a919a-3236-41f9-a459-c613745d4f87" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row d4964a51-0eaa-475d-940c-7f0548f36f71" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 81a3ae7b-e4ce-4a91-bc23-0335328d1f60" level={12} cateid={139}>Địa chỉ thường trú</div>
                                                        <div className="w-text wbaseItem-value c7a3925f-9ee4-4e56-99aa-4a685dda00ec" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div className="w-textformfield wbaseItem-value w-row 4660867d-57ea-4677-a063-b50bf1569874" level={11} cateid={85} placeholder>
                                                        <div className="wbaseItem-value 25e475f7-36ce-4b53-bb44-54dd94cd0efc" level={12} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    value={customerItem?.dcThuongTru}
                                                                    name="Textformfield"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md b312cd68-f342-40f6-aa1f-fa9dce4f2407" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 1c4d3264-2c3f-47d9-8cce-13f3ad1a1e69" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 231bf7bc-0667-4602-9baf-7c62a35cd2b9" level={12} cateid={139}>Phường/Xã</div>
                                                        <div className="w-text wbaseItem-value 638c2e4e-3e7a-4a27-bb59-7c7227a61254" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div className="w-textformfield wbaseItem-value w-row 9fee64a7-4ad4-4ffc-bb4a-455f665e04ca" level={11} cateid={85} placeholder>
                                                        <div className="wbaseItem-value 7ef27c7b-63b5-4e4f-b720-6db9318972a8" level={12} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    value={settingData?.addressWardsName}
                                                                    name="Textformfield"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col12-lg col24-md 84928732-b60a-4c8c-9ea8-69182ae9dba2" level={9} cateid={140}>
                                                <label
                                                    onClick={() => {
                                                        let value = !isDCTTlikeDCHT
                                                        setDCTTlikeDCHT(value);
                                                        if (value) {
                                                            setValue("dcTamTru", getValues("dcThuongTru"))
                                                            setValue("currentAddressProvincesName", getValues("addressProvincesName"));
                                                            setValue("currentAddressDistrictName", getValues("addressDistrictName"));
                                                            setValue("currentAddressWardsName", getValues("addressWardsName"));
                                                            setValue("currentAddressProvincesCode", null);
                                                            setValue("currentAddressDistrictCode", null);
                                                            setValue("currentAddressWardsCode", null);
                                                        }

                                                    }} className="w-switch wbaseItem-value f10b19a9-de21-4b33-ad04-7ae2f2a30dbe" value="false" name-field="Switch" level={10} cateid={81}>
                                                    <input
                                                        checked={isDCTTlikeDCHT}
                                                        type="checkbox"
                                                        name="Switch"
                                                        disabled
                                                    />
                                                    <span className="slider" />
                                                </label>
                                                <div className="w-frame wbaseItem-value w-row adff7cc6-ce76-4008-8460-c82d418c39e6" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 3d63051f-8cb9-4b56-adb8-a6a1646c3f30" level={11} cateid={139}>Địa chỉ thường trú giống với nơi ở hiện tại</div>
                                                </div>
                                            </div>
                                            {
                                                isDCTTlikeDCHT ?
                                                    <div style={{ order: 5 }} className="w-frame wbaseItem-value w-row f824944e-2c8f-4770-bd23-9ff4f2362c7e" wrap="wrap" level={9} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md f36a919a-3236-41f9-a459-c613745d4f87" level={10} cateid={140}>
                                                            <div className="w-frame wbaseItem-value w-row d4964a51-0eaa-475d-940c-7f0548f36f71" level={11} cateid={140}>
                                                                <div className="w-text wbaseItem-value 81a3ae7b-e4ce-4a91-bc23-0335328d1f60" level={12} cateid={139}>Nơi ở hiện tại</div>
                                                                <div className="w-text wbaseItem-value c7a3925f-9ee4-4e56-99aa-4a685dda00ec" level={12} cateid={139}>*</div>
                                                            </div>
                                                            <div className="w-textformfield wbaseItem-value w-row 4660867d-57ea-4677-a063-b50bf1569874" level={11} cateid={85} placeholder>
                                                                <div className="wbaseItem-value 25e475f7-36ce-4b53-bb44-54dd94cd0efc" level={12} cateid={86}>
                                                                    <div className="textfield">
                                                                        <input
                                                                            value={customerItem?.dcThuongTru}
                                                                            name="Textformfield"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md b4167392-f4f4-413e-b110-02c8a4756fa4" level={10} cateid={140}>
                                                            <div className="w-frame wbaseItem-value w-row b912b24b-fff2-4002-9401-c796772539f7" level={11} cateid={140}>
                                                                <div className="w-text wbaseItem-value 9e5a1b40-edc7-4b05-a977-b8b4ec8f9f2b" level={12} cateid={139}>Tỉnh/Thành phố</div>
                                                                <div className="w-text wbaseItem-value 734dd831-b6fc-46a3-bf18-cab01d56f38b" level={12} cateid={139}>*</div>
                                                            </div>
                                                            <div className="w-textformfield wbaseItem-value w-row 74526e37-2348-4139-beda-23ec11814603" level={11} cateid={85} placeholder>
                                                                <div className="wbaseItem-value f55b4a03-2f61-4a4d-98c6-2d1935a67c42" level={12} cateid={86}>
                                                                    <div className="textfield">
                                                                        <input
                                                                            value={settingData?.addressProvincesName}
                                                                            name="Textformfield"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 7d8183d9-7379-4bfd-a140-0bb93601f5e0" level={10} cateid={140}>
                                                            <div className="w-frame wbaseItem-value w-row 1fb17aac-47be-43fd-82b3-7a1725e2a4d2" level={11} cateid={140}>
                                                                <div className="w-text wbaseItem-value 6947f263-f00e-4e02-bbd9-677d97e14a40" level={12} cateid={139}>Quận/Huyện</div>
                                                                <div className="w-text wbaseItem-value 1f442f92-704a-43c0-95a8-46c695532d29" level={12} cateid={139}>*</div>
                                                            </div>
                                                            <div className="w-textformfield wbaseItem-value w-row b76ecdc0-018c-451a-bee1-97a7cfc08ece" level={11} cateid={85} placeholder>
                                                                <div className="wbaseItem-value 2328de85-bca9-4ea6-8ab6-f440f989081b" level={12} cateid={86}>
                                                                    <div className="textfield">
                                                                        <input
                                                                            value={settingData?.addressDistrictName}
                                                                            name="Textformfield"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md b312cd68-f342-40f6-aa1f-fa9dce4f2407" level={10} cateid={140}>
                                                            <div className="w-frame wbaseItem-value w-row 1c4d3264-2c3f-47d9-8cce-13f3ad1a1e69" level={11} cateid={140}>
                                                                <div className="w-text wbaseItem-value 231bf7bc-0667-4602-9baf-7c62a35cd2b9" level={12} cateid={139}>Phường/Xã</div>
                                                                <div className="w-text wbaseItem-value 638c2e4e-3e7a-4a27-bb59-7c7227a61254" level={12} cateid={139}>*</div>
                                                            </div>
                                                            <div className="w-textformfield wbaseItem-value w-row 9fee64a7-4ad4-4ffc-bb4a-455f665e04ca" level={11} cateid={85} placeholder>
                                                                <div className="wbaseItem-value 7ef27c7b-63b5-4e4f-b720-6db9318972a8" level={12} cateid={86}>
                                                                    <div className="textfield">
                                                                        <input
                                                                            value={settingData?.addressWardsName}
                                                                            name="Textformfield"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="w-frame wbaseItem-value w-row 67e0d3ac-536c-4e2c-b46d-62f60d16f472" wrap="wrap" level={9} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 6ca593b8-e9d3-448e-b0b2-2f8e01d9f043" level={10} cateid={140}>
                                                            <div className="w-frame wbaseItem-value w-row fc154698-5d9c-4b65-bebd-f5c2b3fc6a47" level={11} cateid={140}>
                                                                <div className="w-text wbaseItem-value 1c3a75f0-5e7b-462a-988f-aa9e61063100" level={12} cateid={139}>Nơi ở hiện tại<br />
                                                                </div>
                                                                <div className="w-text wbaseItem-value b5dff7ae-5898-4701-8505-892a811788d0" level={12} cateid={139}>*</div>
                                                            </div>
                                                            {
                                                                ValidateFormField({
                                                                    error: errors.dcTamTru,
                                                                    name: "dcTamTru",
                                                                    placeholder: "Nhập địa chỉ hiện tại",
                                                                    validate: "Vui lòng nhập địa chỉ hiện tại",
                                                                    maxLength: 200,
                                                                })
                                                            }
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 480d9be5-db6f-4fdd-b965-e592e8bac251" level={10} cateid={140}>
                                                            <div className="w-frame wbaseItem-value w-row 08703dee-a362-4010-9b72-43db5dc66d13" level={11} cateid={140}>
                                                                <div className="w-text wbaseItem-value 8e0824a6-457a-4ca7-9e40-b5dce9a4249b" level={12} cateid={139}>Tỉnh/Thành phố<br />
                                                                </div>
                                                                <div className="w-text wbaseItem-value 60cc84db-4e46-4d17-be71-40d093fffd2e" level={12} cateid={139}>*</div>
                                                            </div>
                                                            {
                                                                ValidateDropdownFormField({
                                                                    error: errors.currentAddressProvincesCode,
                                                                    name: "currentAddressProvincesCode",
                                                                    placeholder: "Chọn tỉnh/ thành phố",
                                                                    validate: "Vui lòng chọn tỉnh/ thành phố",
                                                                    data: danhSachTinhThanhPho?.length > 0 ? danhSachTinhThanhPho.map((item, _) => { return { name: item.name, id: item.code } }) : [],
                                                                    onChangeEvent: async (ev) => {
                                                                        await getDanhSachQuanHuyen(1, 100, ev.target.value)
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 2e6dafb7-970c-4bcf-a3cf-5eb8568e6b21" level={10} cateid={140}>
                                                            <div className="w-frame wbaseItem-value w-row 3d912628-6891-482d-8527-bbc904799ba2" level={11} cateid={140}>
                                                                <div className="w-text wbaseItem-value 0bc73c21-91a5-4580-a862-97e38d3cfbea" level={12} cateid={139}>Quận/Huyện<br />
                                                                </div>
                                                                <div className="w-text wbaseItem-value 23f564c5-1052-447e-a307-24b427b2d3f1" level={12} cateid={139}>*</div>
                                                            </div>
                                                            {
                                                                ValidateDropdownFormField({
                                                                    error: errors.currentAddressDistrictCode,
                                                                    name: "currentAddressDistrictCode",
                                                                    placeholder: "Chọn quận/ huyện",
                                                                    validate: "Vui lòng chọn quận/ huyện",
                                                                    data: danhSachQuanHuyen?.length > 0 ? danhSachQuanHuyen.map((item, _) => { return { name: item.name, id: item.code } }) : [],
                                                                    onChangeEvent: async (ev) => {
                                                                        if (ev.target.value?.length > 0 && ev.target.value !== getValues("currentAddressDistrictCode")) {
                                                                            await getDanhSachPhuongXa(1, 100, ev.target.value);

                                                                            let temp = await layTongChiTieuTheoVung(ev.target.value);
                                                                            setValue("chiPhiSinhHoatHangThang", `${temp}`)
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                        <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 0e51004f-741e-4730-beab-3e7e85e9eb74" level={10} cateid={140}>
                                                            <div className="w-frame wbaseItem-value w-row 4c7d9103-92db-4f97-98df-d60796882681" level={11} cateid={140}>
                                                                <div className="w-text wbaseItem-value 387c7c48-ce57-4430-99eb-8410469c340c" level={12} cateid={139}>Phường/Xã</div>
                                                                <div className="w-text wbaseItem-value e41302b2-7160-42fb-a2a1-d0e6a6d47203" level={12} cateid={139}>*</div>
                                                            </div>
                                                            {
                                                                ValidateDropdownFormField({
                                                                    error: errors.currentAddressWardsCode,
                                                                    name: "currentAddressWardsCode",
                                                                    placeholder: "Chọn phường/ xã",
                                                                    validate: "Vui lòng chọn phường/ xã",
                                                                    data: danhSachPhuongXa?.length > 0 ? danhSachPhuongXa.map((item, _) => { return { name: item.name, id: item.code } }) : [],
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                            <div className="w-frame wbaseItem-value w-row 67e0d3ac-536c-4e2c-b46d-62f60d16f472" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 0143cf6b-5ce7-4d25-af3e-c4340eaf8c97" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 94293f9f-ca41-4713-a1d3-81f5a004607e" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value ee050f4c-b71c-4943-8d93-19ea8b24626a" level={12} cateid={139}>Thời gian cư trú<br />
                                                        </div>
                                                        <div className="w-text wbaseItem-value 87442c9e-bcfe-4c4e-b442-fd8569e612b4" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-row 9e645799-71bf-473c-9771-63bd53cb431d" level={11} cateid={140}>
                                                        <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.namCuTru && 'helper-text')}
                                                            name-field="RoleID" level={8} cateid={85} placeholder="0"
                                                            helper-text={errors.namCuTru && 'Vui lòng nhập số năm'}
                                                        >
                                                            <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f"
                                                                level={9} cateid={86}>
                                                                <div className="textfield">
                                                                    <input
                                                                        maxLength={2}
                                                                        {...register("namCuTru", { required: true })}
                                                                        placeholder="0"
                                                                        onChange={(event) => {
                                                                            event.target.value = event.target.value.replace(/\D/g, "");
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="w-text wbaseItem-value 672c0e54-75e0-45cb-b5c0-f912b0deadde" level={12} cateid={139}>
                                                                    Năm
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.thangCuTru && 'helper-text')}
                                                            level={8} cateid={85} placeholder="Nhập số tháng"
                                                            helper-text={errors.thangCuTru && 'Vui lòng nhập số tháng'}
                                                        >
                                                            <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f"
                                                                level={9} cateid={86}>
                                                                <div className="textfield">
                                                                    <input
                                                                        {...register("thangCuTru", { required: true })}
                                                                        placeholder="0"
                                                                        onChange={(event) => {
                                                                            event.target.value = event.target.value.replace(/\D/g, "");
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="w-text wbaseItem-value 672c0e54-75e0-45cb-b5c0-f912b0deadde" level={12} cateid={139}>
                                                                    Tháng
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md b1523b3c-a4a3-47d7-bfee-bbf3ab7be4f6" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row d3acf66d-1dc3-4d58-917a-633f4a0da4e2" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value afe374bf-60b1-4cac-a1ef-f856c8776092" level={12} cateid={139}>Tình trạng cư trú</div>
                                                        <div className="w-text wbaseItem-value e9703acd-8259-45d2-9876-80834b5bc259" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {
                                                        ValidateDropdownFormField({
                                                            error: errors.maTtNoiCuTru,
                                                            name: "maTtNoiCuTru",
                                                            placeholder: "Tình trạng cư trú",
                                                            validate: "Vui lòng chọn tình trạng cư trú",
                                                            data: danhSachTinhTrangCuTru?.length > 0 ? danhSachTinhTrangCuTru.map((item, _) => { return { name: item.name, id: item.code } }) : []
                                                        })
                                                    }
                                                </div>
                                            </div>

                                        </div>

                                        {/* fail */}
                                        <div className="w-frame wbaseItem-value w-col aed2a4da-5952-40c2-bb0c-f561602b9e46" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 1f7ffc40-fc92-4556-8bc0-e929ad3b3b53" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value 6cc29a61-1511-49e6-9c8b-5c13f678dcd9" level={10} cateid={139}>Công việc khách hàng</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row 7054c001-aee8-47db-950f-3050cde0629b" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md becae86d-efe5-4f17-ab3d-899932d5c5e3" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 0e42da4d-8967-45ec-9884-05b48b1d278e" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 4a87d573-81b4-4808-a9bb-370b2b0e8b42" level={12} cateid={139}>Nghề nghiệp</div>
                                                        <div className="w-text wbaseItem-value 74a2f295-d11d-4524-b461-5112203cfc81" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {
                                                        ValidateDropdownFormField({
                                                            error: errors.maNgheNghiep,
                                                            name: "maNgheNghiep",
                                                            placeholder: "Chọn nghề nghiệp",
                                                            validate: "Vui lòng chọn nghề nghiệp",
                                                            data: danhSachNgheNghiep?.length > 0 ? danhSachNgheNghiep.map((item, _) => { return { name: item.name, id: item.code } }) : []
                                                        })
                                                    }
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 61a5ca3d-db69-4de7-963e-df3776039836" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row e5010dbf-d5c9-4558-9a83-eba429dabf05" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 81d116ad-c171-4630-ab16-dc9304b39dd8" level={12} cateid={139}>Tên công ty/Nơi làm việc</div>
                                                        <div className="w-text wbaseItem-value 20d74c37-7e29-4463-bc79-6c7f3b37aec2" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.noiLamViec && 'helper-text')}
                                                        name-field="RoleID" level={8} cateid={85} placeholder="Nhập tên công ty"
                                                        helper-text={errors.noiLamViec && 'Vui lòng nhập tên công ty'}
                                                    >
                                                        <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f" name-field="Textfield"
                                                            level={9} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    maxLength="200"
                                                                    name="noiLamViec"
                                                                    placeholder="Nhập tên công ty"  {...register("noiLamViec", { required: true })} />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 819a8c09-f116-4b10-9ee6-5ccfad891588" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row b3719b69-c3cf-42e8-a024-b5df732ddada" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value ef5fa09e-cebd-4d31-a5e6-2644f12bbf08" level={12} cateid={139}>Địa chỉ nơi làm việc</div>
                                                        <div className="w-text wbaseItem-value faebcb25-b17f-4fa2-91e1-f26af2c584c3" level={12} cateid={139}>*</div>
                                                    </div>
                                                    <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.dcNoiLamViec && 'helper-text')}
                                                        level={8} cateid={85}
                                                        helper-text={errors.dcNoiLamViec && 'Vui lòng nhập địa chỉ'}
                                                    >
                                                        <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f"
                                                            level={9} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    maxLength="200"
                                                                    name="dcNoiLamViec"
                                                                    placeholder="Nhập địa chỉ" {...register("dcNoiLamViec", { required: true })} />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 053ae8e1-de6b-4dd0-8e59-b63162a5afa3" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row b53c09f2-4c00-4f3f-8d9d-e8b6a63fa976" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 03219885-ab24-409f-bace-d735105f8a6e" level={12} cateid={139}>Chức vụ</div>
                                                        <div className="w-text wbaseItem-value 0707dbfb-3fa7-4c7e-bbd8-a222c97a475d" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {
                                                        ValidateDropdownFormField({
                                                            error: errors.maChucVu,
                                                            name: "maChucVu",
                                                            placeholder: "Chọn chức vụ",
                                                            validate: "Vui lòng chọn chức vụ",
                                                            data: danhSachChucVu?.length > 0 ? danhSachChucVu.map((item, _) => { return { name: item.name, id: item.code } }) : []
                                                        })
                                                    }
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md d487f790-41bb-4c3d-9e81-6ce797705394" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row a3b313f4-dea5-4b87-ae7c-74f5dfff3394" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 38e3f942-8cb5-4a54-acdf-17c24a48a23d" level={12} cateid={139}>Số điện thoại công ty</div>
                                                        <div className="w-text wbaseItem-value 7ed942ea-028d-4c35-9654-5d866b1ad989" level={12} cateid={139}>*</div>
                                                    </div>
                                                    {/* {
                                                        ValidateFormField({
                                                            error: errors.dtNoiLamViec,
                                                            name: "dtNoiLamViec",
                                                            placeholder: "Nhập số điện thoại công ty",
                                                            validate: "Vui lòng nhập số điện thoại công ty",
                                                        })
                                                    } */}
                                                    <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.dtNoiLamViec && 'helper-text')}
                                                        level={8} cateid={85} placeholder="Nhập số điện thoại"
                                                        helper-text={errors.dtNoiLamViec && 'Vui lòng nhập số điện thoại'}
                                                    >
                                                        <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f"
                                                            level={9} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    maxLength={10}
                                                                    {...register("dtNoiLamViec", { required: true })}
                                                                    placeholder="Nhập số điện thoại"
                                                                    onChange={(event) => {
                                                                        event.target.value = event.target.value.replace(/\D/g, "");
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* oke */}
                                        <div className="w-frame wbaseItem-value w-col 3bc4775c-eeb5-4b27-b82b-bc7410f49183" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row c748ade3-8f23-444e-a1b9-f7a2de479df1" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value e9b918e8-5516-4007-9218-1c7217840aad" level={10} cateid={139}>Tài chính khách hàng</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row 1600d1fd-7046-4f0b-a9cd-89a73cc335fe" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md b1fa5f0a-8f9f-47be-beec-c51d1d23c710" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 116b09da-aa92-4d25-9f19-8a98c9c46e87" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 42b487a9-fe74-416c-8c6b-ca902a6ac7bc" level={12} cateid={139}>Thu nhập hàng tháng</div>
                                                        <div className="w-text wbaseItem-value 93c1ff3c-8b8b-4998-bd6b-55f20183a219" level={12} cateid={139}>*</div>
                                                    </div>

                                                    <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.thuNhapThang && 'helper-text')}
                                                        level={8} cateid={85} placeholder="Nhập thu nhập"
                                                        helper-text={errors.thuNhapThang && 'Vui lòng nhập thu nhập'}
                                                    >
                                                        <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f"
                                                            level={9} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    maxLength={19}
                                                                    {...register("thuNhapThang", { required: true })}
                                                                    placeholder="Nhập thu nhập"
                                                                    onChange={(event) => {
                                                                        event.target.value = event.target.value.replace(/\D/g, "");
                                                                        setValue("thuNhapThang", event.target.value);
                                                                    }}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md dd8e1947-b29c-4e3f-a1d0-d869970cd90b" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-row 9206a033-7e25-4719-9a36-033beabadbb0" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value e7deeb93-ba7d-4ded-a878-a871ae136ce9" level={12} cateid={139}>Chi phí sinh hoạt hàng tháng</div>
                                                    </div>
                                                    <div style={{ backgroundColor: "white" }} className="w-textformfield wbaseItem-value w-row c5bbfac1-79cc-45db-994e-5a7b6b54a62a" level={11} cateid={85} >
                                                        <div className="wbaseItem-value b424939e-93a4-4a28-8637-7a9f244062ce" level={12} cateid={86}>
                                                            <div className="textfield">
                                                                <input
                                                                    value={Ultis.money(watch('chiPhiSinhHoatHangThang')) + " VND"}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row 8c681c44-0025-4718-bbf3-754fcf671826" wrap="wrap" level={9} cateid={140}>
                                                {
                                                    isNguonThuNhapKhac &&
                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md f9bcb5cc-bc71-439d-86e9-e1080d6300cc" level={10} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-row b0dbd11b-3c27-466b-9e03-6612bf457374" level={11} cateid={140}>
                                                            <div className="w-text wbaseItem-value 3e1b2b1b-6ad0-4f80-b43c-e6291e4cd54f" level={12} cateid={139}>Thu nhập khác</div>
                                                            <div className="w-text wbaseItem-value a23e278d-95d6-4644-bc46-93a1fd5fa50c" level={12} cateid={139}>*</div>
                                                        </div>
                                                        <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.thuNhapKhac && 'helper-text')}
                                                            level={8} cateid={85} placeholder="Nhập số tiền"
                                                            helper-text={errors.thuNhapKhac && 'Vui lòng nhập số tiền'}
                                                        >
                                                            <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f"
                                                                level={9} cateid={86}>
                                                                <div className="textfield">
                                                                    <input
                                                                        type='text'
                                                                        maxLength={19}
                                                                        {...register("thuNhapKhac", { required: true })}
                                                                        placeholder="Nhập số tiền"
                                                                        onChange={(event) => {
                                                                            event.target.value = event.target.value.replace(/\D/g, "");
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    isNoVayKhac &&
                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 8a2b632b-9b50-4ce3-b7a0-74694c30c171" level={10} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-row 2a79525f-fdb7-4983-ab7d-9cf512fd2398" level={11} cateid={140}>
                                                            <div className="w-text wbaseItem-value d7fd7ac1-ce28-4cc9-abdf-c6ed4d0dab72" level={12} cateid={139}>Khoản thanh toán nợ vay hàng tháng</div>
                                                            <div className="w-text wbaseItem-value 4650e149-91c5-4c47-852c-f67167028992" level={12} cateid={139}>*</div>
                                                        </div>
                                                        {/* {
                                                            ValidateFormField({
                                                                error: errors.tongThanhToanNoVayHangThang,
                                                                name: "tongThanhToanNoVayHangThang",
                                                                placeholder: "Nhập số tiền",
                                                                validate: "Vui lòng nhập số tiền",
                                                                suffix: "VND"
                                                            })
                                                        } */}
                                                        <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.tongThanhToanNoVayHangThang && 'helper-text')}
                                                            level={8} cateid={85} placeholder="Nhập số tiền"
                                                            helper-text={errors.tongThanhToanNoVayHangThang && 'Vui lòng nhập số tiền'}
                                                        >
                                                            <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f"
                                                                level={9} cateid={86}>
                                                                <div className="textfield">
                                                                    <input
                                                                        type='text'
                                                                        maxLength={19}
                                                                        {...register("tongThanhToanNoVayHangThang", { required: true })}
                                                                        placeholder="Nhập số tiền"
                                                                        onChange={(event) => {
                                                                            event.target.value = event.target.value.replace(/\D/g, "");
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    isNguoiPhuThuoc &&
                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md a23e0a61-1ad5-4d18-94ff-2e185600b714" level={10} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-row 295f061d-2abd-4192-8da2-3c8168a92235" level={11} cateid={140}>
                                                            <div className="w-text wbaseItem-value 6e93129b-5067-4983-9bd5-b3b80dd859a6" level={12} cateid={139}>Số người phụ thuộc</div>
                                                            <div className="w-text wbaseItem-value 19c56de8-56df-488b-a587-74d648cd28ee" level={12} cateid={139}>*</div>
                                                        </div>
                                                        {/* {
                                                            ValidateFormField({
                                                                error: errors.nguoiPhuThuoc,
                                                                name: "nguoiPhuThuoc",
                                                                placeholder: "Nhập số người phụ thuộc",
                                                                validate: "Vui lòng nhập số người phụ thuộc",
                                                            })
                                                        } */}
                                                        <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.soNguoiPhuThuoc && 'helper-text')}
                                                            level={8} cateid={85} placeholder="Nhập số người phụ thuộc"
                                                            helper-text={errors.soNguoiPhuThuoc && 'Vui lòng nhập số người phụ thuộc'}
                                                        >
                                                            <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f"
                                                                level={9} cateid={86}>
                                                                <div className="textfield">
                                                                    <input
                                                                        type='text'
                                                                        maxLength={2}
                                                                        {...register("soNguoiPhuThuoc", { required: true })}
                                                                        placeholder="Nhập số người phụ thuộc"
                                                                        onChange={(event) => {
                                                                            event.target.value = event.target.value.replace(/\D/g, "");
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="w-frame wbaseItem-value w-row 21bc066c-3209-4e02-a916-54496a01da25" wrap="wrap" level={9} cateid={140}>
                                                <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col12-lg col24-md 0b70f967-5801-498b-b04f-bbad34c3cb84" level={10} cateid={140}>
                                                    <label
                                                        // onClick={() => setNoVayKhac(!isNoVayKhac)}
                                                        onClick={() => {
                                                            let value = !isNoVayKhac;
                                                            setNoVayKhac(value);
                                                            if (value === false) {
                                                                setValue("tongThanhToanNoVayHangThang", null);
                                                            }
                                                        }}
                                                        className="w-check-box wbaseItem-value e4626dbd-1ce0-44a0-b063-6cf11e6250fd" level={11} cateid={79}>
                                                        <input type="checkbox" name="Checkbox" disabled checked={isNoVayKhac} />
                                                        <svg width="20" height="20">
                                                            <path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF">
                                                            </path>
                                                        </svg>
                                                    </label>
                                                    <div className="w-text wbaseItem-value 498de35f-c2ac-4c40-b36a-88a5d650f908" level={11} cateid={139}>Nợ vay khác</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col12-lg col24-md 0b70f967-5801-498b-b04f-bbad34c3cb84" level={10} cateid={140}>
                                                    <label
                                                        onClick={() => {
                                                            let value = !isNguonThuNhapKhac;
                                                            setNguonThuNhapKhac(value);
                                                            if (value === false) {
                                                                setValue("thuNhapKhac", null);
                                                            }
                                                        }}
                                                        className="w-check-box wbaseItem-value e4626dbd-1ce0-44a0-b063-6cf11e6250fd" level={11} cateid={79}>
                                                        <input type="checkbox" name="Checkbox" disabled checked={isNguonThuNhapKhac} />
                                                        <svg width="20" height="20">
                                                            <path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF">
                                                            </path>
                                                        </svg>
                                                    </label>
                                                    <div className="w-text wbaseItem-value 498de35f-c2ac-4c40-b36a-88a5d650f908" level={11} cateid={139}>Nguồn thu nhập khác</div>
                                                </div>
                                                <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col12-lg col24-md f93b1436-aed4-4d63-a9d0-1f15099b9104" level={10} cateid={140}>
                                                    <label
                                                        onClick={() => {
                                                            let value = !isNguoiPhuThuoc;
                                                            setNguoiPhuThuoc(value);
                                                            if (value === false) {
                                                                setValue("soNguoiPhuThuoc", null);
                                                            }
                                                        }}
                                                        className="w-check-box wbaseItem-value 8f28d344-0ded-40e8-b549-886b9266f028" level={11} cateid={79}>
                                                        <input type="checkbox" name="Checkbox" disabled checked={isNguoiPhuThuoc} />
                                                        <svg width="20" height="20">
                                                            <path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF">
                                                            </path>
                                                        </svg>
                                                    </label>
                                                    <div className="w-text wbaseItem-value eb4563a1-36e5-49d9-bd00-be441d85d19a" level={11} cateid={139}>Người phụ thuộc</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* oke */}
                                        <div className="w-frame wbaseItem-value w-row b34d481e-f2e3-465e-9be4-da1e0879e6ee" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-col col- 3c89acae-de8a-4879-aed3-f762536c747c" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value 3a93547d-492b-4cd9-9a02-8d3728849045" level={10} cateid={139}>Điểm DTI:</div>
                                                <div className="w-text wbaseItem-value 99c3dbd2-99c0-44f6-bb7c-d2fc78e03f92" level={10} cateid={139}>{settingData?.diemDTI ?? "-"}</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col col- af365d57-f31e-4bee-9f00-857aa620f8ee" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value d529232e-9f7a-4ebc-b007-13f167237db3" level={10} cateid={139}>Kết quả DTI:<br />
                                                </div>
                                                <div className="w-text wbaseItem-value 9dc68d34-d58d-4845-ae69-77362e5e39d5" level={10} cateid={139}>{settingData?.ketQuaDTI ?? "-"}</div>
                                            </div>
                                            <button
                                                onClick={() => { tinhKetQuaDTI(true) }}
                                                type="button" className="w-button wbaseItem-value w-row efd25fa1-d6ed-4d23-a89e-b2309cc08851" level={9} cateid={29}>
                                                <div className="w-text wbaseItem-value 57dd0e16-89c8-45e3-a489-7f47fda59d6c" level={10} cateid={139}>Đề xuất khoản vay<br />
                                                </div>
                                            </button>
                                        </div>

                                        {/* oke */}
                                        <div className="w-frame wbaseItem-value w-col 76223d88-aa8f-4b10-b851-60108459aeb9" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row e0e0bef6-ce13-410d-b41c-2c7c4d34e61b" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value 4c81c3c0-2f05-4065-aebd-2919543763e3" level={10} cateid={139}>Thông tin người liên quan</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col a1cbb1f3-f1cb-4843-8b94-46561afae2f2" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value 3858e791-6e42-4c9a-8c71-09762ca82cf1" level={10} cateid={139}>Người liên hệ trong trường hợp thực hiện nghĩa vụ theo hợp đồng</div>
                                                <div className="w-frame wbaseItem-value w-row 5f3fdadb-7755-436a-a4f7-1f4252630538" wrap="wrap" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 07dd3584-6715-4840-b685-55f4e2343a95" level={11} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-row cda86ad1-f8ca-43fb-a1fd-19928921fce3" level={12} cateid={140}>
                                                            <div className="w-text wbaseItem-value 86603f72-6c06-495a-a177-c78cc49a1205" level={13} cateid={139}>Họ và tên <br />
                                                            </div>
                                                            <div className="w-text wbaseItem-value 3de53883-9259-479e-b6ed-b0635c6da122" level={13} cateid={139}>*</div>
                                                        </div>
                                                        <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.hoTenNguoiLienHe1 && 'helper-text')}
                                                            name-field="RoleID" level={8} cateid={85} placeholder="Nhập họ tên"
                                                            helper-text={errors.hoTenNguoiLienHe1 && 'Vui lòng nhập họ tên'}
                                                        >
                                                            <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f" name-field="Textfield"
                                                                level={9} cateid={86}>
                                                                <div className="textfield">
                                                                    <input
                                                                        maxLength="200"
                                                                        name="hoTenNguoiLienHe1"
                                                                        placeholder="Nhập họ tên"  {...register("hoTenNguoiLienHe1", { required: true })} />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 37b073fc-21eb-4574-a6fe-41de4b232256" level={11} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-row 00c3c6c3-5f34-4337-a032-d77131863a2b" level={12} cateid={140}>
                                                            <div className="w-text wbaseItem-value 6fe0929f-9291-46eb-bc29-3e44b71a806d" level={13} cateid={139}>Mối quan hệ<br />
                                                            </div>
                                                            <div className="w-text wbaseItem-value d3efa0f0-64a3-413c-bed9-380688a47ae6" level={13} cateid={139}>*</div>
                                                        </div>
                                                        {
                                                            ValidateDropdownFormField({
                                                                error: errors.maQhNguoiLienHe1,
                                                                name: "maQhNguoiLienHe1",
                                                                placeholder: "Chọn quan hệ",
                                                                validate: "Vui lòng chọn quan hệ",
                                                                data: danhSachQuanHe1?.length > 0 ? danhSachQuanHe1.map((item, index) => { return { name: item.name, id: item?.code ?? index } }) : []
                                                            })
                                                        }
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md eb46bcfd-15bd-449f-9782-0d2c0faf69dc" level={11} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-row 6b765d36-1395-41b4-b631-e5f551d533ac" level={12} cateid={140}>
                                                            <div className="w-text wbaseItem-value 86b48c8a-f9c8-4874-8524-573bbc254dc7" level={13} cateid={139}>Số điện thoại<br />
                                                            </div>
                                                            <div className="w-text wbaseItem-value 24981368-948e-459e-a62d-1e4ff2b6f67b" level={13} cateid={139}>*</div>
                                                        </div>
                                                        <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.dtNguoiLienHe1 && 'helper-text')}
                                                            name-field="RoleID" level={8} cateid={85} placeholder="Nhập số điện thoại"
                                                            helper-text={errors.dtNguoiLienHe1 && 'Vui lòng nhập số điện thoại'}
                                                        >
                                                            <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f" name-field="Textfield"
                                                                level={9} cateid={86}>
                                                                <div className="textfield">
                                                                    <input
                                                                        placeholder="Nhập số điện thoại" {...register("dtNguoiLienHe1", { required: true })}
                                                                        type='text'
                                                                        maxLength={10}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col af82fd82-580e-4479-ad04-fffd689c3f45" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value 5cc54751-26f3-4a7e-b7a1-f25ed65ae2c6" level={10} cateid={139}>Người liên hệ trong trường hợp khẩn cấp</div>
                                                <div className="w-frame wbaseItem-value w-row 52d35c3f-59dd-4ca7-8cca-5915ce2c147a" wrap="wrap" level={10} cateid={140}>
                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 438ce578-b140-414c-bbeb-89b48d83a190" level={11} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-row 75ed2e23-2e7e-4e97-b84b-b3ff1f29bf8f" level={12} cateid={140}>
                                                            <div className="w-text wbaseItem-value 5df35541-ce4a-47a9-adfa-7c67b864b380" level={13} cateid={139}>Họ và tên<br />
                                                            </div>
                                                            <div className="w-text wbaseItem-value 0bd4d9d0-6984-4842-9d03-74cb21dc776e" level={13} cateid={139}>*</div>
                                                        </div>
                                                        <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.hoTenNguoiLienHe2 && 'helper-text')}
                                                            name-field="RoleID" level={8} cateid={85} placeholder="Nhập họ tên"
                                                            helper-text={errors.hoTenNguoiLienHe2 && 'Vui lòng nhập họ tên'}
                                                        >
                                                            <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f" name-field="Textfield"
                                                                level={9} cateid={86}>
                                                                <div className="textfield">
                                                                    <input
                                                                        maxLength="200"
                                                                        name="hoTenNguoiLienHe2"
                                                                        placeholder="Nhập họ tên"  {...register("hoTenNguoiLienHe2", { required: true })} />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md 8773bb39-8dde-42c2-bc21-fbb4288e02ec" level={11} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-row 1cc82d54-e464-489c-bec0-50f6015442e9" level={12} cateid={140}>
                                                            <div className="w-text wbaseItem-value 4c42254a-93f5-47ca-a335-ed03fd8173a2" level={13} cateid={139}>Mối quan hệ<br />
                                                            </div>
                                                            <div className="w-text wbaseItem-value 1cb49746-39fa-42b6-a486-0fe645d85bb7" level={13} cateid={139}>*</div>
                                                        </div>
                                                        {
                                                            ValidateDropdownFormField({
                                                                error: errors.maQhNguoiLienH21,
                                                                name: "maQhNguoiLienHe2",
                                                                placeholder: "Chọn quan hệ",
                                                                validate: "Vui lòng chọn quan hệ",
                                                                data: danhSachQuanHe2?.length > 0 ? danhSachQuanHe2.map((item, index) => { return { name: item.name, id: item?.code ?? index } }) : []
                                                            })
                                                        }
                                                    </div>
                                                    <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col24-md c0df15a0-4678-4373-94d2-5dda7b6443e6" level={11} cateid={140}>
                                                        <div className="w-frame wbaseItem-value w-row cbb851d0-05ac-490a-964d-43381311cf37" level={12} cateid={140}>
                                                            <div className="w-text wbaseItem-value 498987c8-650c-467a-8a63-eb070935bf58" level={13} cateid={139}>Số điện thoại<br />
                                                            </div>
                                                            <div className="w-text wbaseItem-value cdfc6875-e007-429b-b78c-e5b72783724c" level={13} cateid={139}>*</div>
                                                        </div>
                                                        <div className={"w-textformfield wbaseItem-value w-row 1553307f-fd10-4679-a62d-9b2d1584a217 " + (errors.dtNguoiLienHe2 && 'helper-text')}
                                                            name-field="RoleID" level={8} cateid={85} placeholder="Nhập số điện thoại"
                                                            helper-text={errors.dtNguoiLienHe2 && 'Vui lòng nhập số điện thoại'}
                                                        >
                                                            <div className="wbaseItem-value ee5c5ee0-f72f-480e-9462-53f6362a2d1f" name-field="Textfield"
                                                                level={9} cateid={86}>
                                                                <div className="textfield">
                                                                    <input
                                                                        placeholder="Nhập số điện thoại" {...register("dtNguoiLienHe2", { required: true })}
                                                                        type='text'
                                                                        maxLength={10}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*fail */}
                                        <div className="w-frame wbaseItem-value w-col ac617f9e-54d0-48ec-9be0-a1e05e9433d8" level={8} cateid={140}>
                                            <div className="w-text wbaseItem-value 06d8adb8-aeec-4f0a-b367-29f094fc602b" level={9} cateid={139}>Hồ sơ đính kèm</div>
                                            <table className="w-table wbaseItem-value 5215dfb7-19ac-4959-ad3d-2b99945b5c48" type={0} level={9} cateid={117}>
                                                <tbody>
                                                    <tr className="table-row">
                                                        <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 80db031b-624c-4f65-8249-3bb237fd572a" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 0be4e6dc-7625-4abd-b6d9-5e5ddcac3405" level={11} cateid={139}>Loại hồ sơ</div>
                                                            </div>
                                                        </td>
                                                        <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row a8e37f9f-8dab-4edc-91e7-d9fd7b66ba2a" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 1d0dcf6d-e6af-4598-bee2-aa3613fc0e11" level={11} cateid={139}>Tệp đính kèm</div>
                                                            </div>
                                                        </td>
                                                        <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 31134a57-b5b0-4fae-a462-34bf24e5f767" level={10} cateid={140} />
                                                        </td>
                                                        <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 7fbf7694-9b61-4078-91ee-307c01e4a0f7" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value e16993d8-46ff-4e8a-bd75-ed5af9b1f698" level={11} cateid={139}>Người upload</div>
                                                            </div>
                                                        </td>
                                                        <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 7722f722-19b6-4766-94e0-59feb476ee25" level={10} cateid={140}>
                                                                <div className="w-text wbaseItem-value 196b344b-4ad4-482b-a7be-e184d9bcacc0" level={11} cateid={139}>Thời gian upload</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        fileHoSo.map((item, index) => {
                                                            return (
                                                                FileItem({ item: item, index: index })
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <div className="w-frame wbaseItem-value w-row dd064bc8-12bf-4b65-b58d-4b6b9056caa7" level={9} cateid={140}>
                                                <button onClick={addFileHoSo} type="button" className="w-button wbaseItem-value w-row df9fd95f-e00a-45c4-afc9-f60e2aabbc4b" level={10} cateid={29}>
                                                    <div className="w-svg wbaseItem-value 7aa4de69-904d-4e5a-b273-c7f5cfacd9e0" level={11} cateid={115}>
                                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.9091 2.90909C12.9091 2.40701 12.5021 2 12 2C11.4979 2 11.0909 2.40701 11.0909 2.90909V11.0909H2.90909C2.40701 11.0909 2 11.4979 2 12C2 12.5021 2.40701 12.9091 2.90909 12.9091H11.0909V21.0909C11.0909 21.593 11.4979 22 12 22C12.5021 22 12.9091 21.593 12.9091 21.0909V12.9091H21.0909C21.593 12.9091 22 12.5021 22 12C22 11.4979 21.593 11.0909 21.0909 11.0909H12.9091V2.90909Z" fill="#FFFFFFFF" />
                                                        </svg>
                                                    </div>
                                                    <div className="w-text wbaseItem-value 6b44e751-1c5c-48a0-85b1-bfb89e235470" level={11} cateid={139}>Thêm hồ sơ bổ sung<br />
                                                    </div>
                                                </button>
                                                <div className="w-frame wbaseItem-value w-row 763f0e40-6dc1-4253-9fd4-3602589f439c" level={10} cateid={140}>
                                                    <div className="w-svg wbaseItem-value 20c8196d-e243-4a05-b714-5c1028a16e9e" level={11} cateid={115}>
                                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9971 9.34871 20.9426 6.80684 19.0679 4.9321C17.1932 3.05736 14.6513 2.00287 12 2V2ZM12.8567 17.6033H11.1358V10.3333H12.8567V17.6033ZM12.7142 8.04917C12.6192 8.13618 12.5077 8.20317 12.3863 8.24612C12.2649 8.28908 12.136 8.30712 12.0075 8.29917C11.8766 8.30787 11.7453 8.29023 11.6213 8.24729C11.4974 8.20435 11.3833 8.13698 11.2858 8.04917C11.1997 7.95647 11.1332 7.84737 11.0903 7.72839C11.0473 7.6094 11.0288 7.48297 11.0358 7.35667C11.0271 7.22742 11.0448 7.09775 11.0878 6.97555C11.1307 6.85335 11.1981 6.74117 11.2858 6.64583C11.3836 6.55841 11.4977 6.49129 11.6216 6.44837C11.7455 6.40545 11.8766 6.38759 12.0075 6.39583C12.136 6.38836 12.2647 6.40662 12.3861 6.44955C12.5074 6.49248 12.619 6.55921 12.7142 6.64583C12.8019 6.74117 12.8693 6.85335 12.9122 6.97555C12.9552 7.09775 12.9729 7.22742 12.9642 7.35667C12.9712 7.48297 12.9527 7.6094 12.9097 7.72839C12.8668 7.84737 12.8003 7.95647 12.7142 8.04917V8.04917Z"
                                                                fill="#367BE2FF" />
                                                        </svg>
                                                    </div>
                                                    <div className="w-text wbaseItem-value 7ac5085d-d49e-40a4-9b41-5381f11c5988" level={11} cateid={139}>File upload có định dạng word, excel, .jpg, .jpeg, .png, .pdf và không vượt quá 5MB</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*  */}
                                        <div className="w-frame wbaseItem-value w-col 513b016d-863b-4c2b-a721-8428c2468af1" level={8} cateid={140}>
                                            <div className="w-frame wbaseItem-value w-row 83ba9b55-e4ef-43ce-bca9-165985a9656c" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value 49ae05f1-d4fa-4999-9b2e-91b1373ed8fc" level={10} cateid={139}>Ghi chú</div>
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col 1d414740-6ff1-4da6-ae85-8740f651aa4e" level={9} cateid={140}>
                                                {
                                                    settingData?.noteLoanApplication?.length > 0 &&
                                                    settingData?.noteLoanApplication.map((item, index) => {
                                                        return (
                                                            <div key={item.id} className="w-frame wbaseItem-value w-col bdc6abd6-c3bb-4556-bf5c-bef4b6b780a5" level={10} cateid={140}>
                                                                <div className="w-frame wbaseItem-value w-row 757ba99d-57dc-4959-802e-343dacde166a" level={11} cateid={140}>
                                                                    <div className="w-frame wbaseItem-value w-row 6b9d5d3f-a3a6-4e06-9452-2afef205925d" level={12} cateid={140}>
                                                                        <div className="w-svg wbaseItem-value 4937a4f8-6e6b-4616-b041-8a7916512e86" level={13} cateid={115}>
                                                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 2C6.48583 2 2 6.48583 2 12C2 17.5142 6.48583 22 12 22C17.5142 22 22 17.5142 22 12C22 6.48583 17.5142 2 12 2ZM8.66667 10.3333C8.66667 8.4925 10.2033 7 12 7C13.7967 7 15.3333 8.4925 15.3333 10.3333V11.1667C15.3333 13.0075 13.7967 14.5 12 14.5C10.2033 14.5 8.66667 13.0075 8.66667 11.1667V10.3333ZM12 20.3333C9.9725 20.3333 8.1125 19.6033 6.66583 18.395C7.365 17.0758 8.73583 16.1667 10.3333 16.1667H13.6667C15.2642 16.1667 16.635 17.0758 17.3342 18.395C15.8875 19.6033 14.0275 20.3333 12 20.3333Z" fill="#00204D" fillOpacity="0.6" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className="w-text wbaseItem-value 9e3a53ee-5db9-4c54-a1ec-ac08b4c61cea" level={13} cateid={139}>
                                                                            {item.maNguoiTao} - {item.tenNguoiTao}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-frame wbaseItem-value w-row 4c525dc8-cbbc-4a62-8952-96286db43bc0" level={12} cateid={140}>
                                                                        <div className="w-svg wbaseItem-value ef0e9816-450b-4935-b8af-cc76bfa228b0" level={13} cateid={115}>
                                                                            <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M11.8895 3.00065V1.88954C11.8895 1.7422 11.831 1.60089 11.7268 1.4967C11.6226 1.39252 11.4813 1.33398 11.334 1.33398C11.1866 1.33398 11.0453 1.39252 10.9411 1.4967C10.837 1.60089 10.7784 1.7422 10.7784 1.88954V3.00065H11.8895Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                                                <path d="M5.22287 3.00065V1.88954C5.22287 1.7422 5.16434 1.60089 5.06015 1.4967C4.95597 1.39252 4.81466 1.33398 4.66732 1.33398C4.51998 1.33398 4.37867 1.39252 4.27448 1.4967C4.17029 1.60089 4.11176 1.7422 4.11176 1.88954V3.00065H5.22287Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                                                <path d="M13.0007 14.1118H3.00065C2.55862 14.1118 2.1347 13.9362 1.82214 13.6236C1.50958 13.311 1.33398 12.8871 1.33398 12.4451V5.22287C1.33398 4.78085 1.50958 4.35692 1.82214 4.04436C2.1347 3.7318 2.55862 3.55621 3.00065 3.55621H13.0007C13.4427 3.55621 13.8666 3.7318 14.1792 4.04436C14.4917 4.35692 14.6673 4.78085 14.6673 5.22287V12.4451C14.6673 12.8871 14.4917 13.311 14.1792 13.6236C13.8666 13.9362 13.4427 14.1118 13.0007 14.1118ZM13.5562 6.33398H2.4451V12.4451C2.4451 12.5924 2.50363 12.7337 2.60781 12.8379C2.712 12.9421 2.85331 13.0007 3.00065 13.0007H13.0007C13.148 13.0007 13.2893 12.9421 13.3935 12.8379C13.4977 12.7337 13.5562 12.5924 13.5562 12.4451V6.33398Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                                                <path d="M5.77843 7.4451H3.55621V9.11176H5.77843V7.4451Z" fill="#282829" fillOpacity="0.6" />
                                                                                <path d="M9.11176 7.4451H6.88954V9.11176H9.11176V7.4451Z" fill="#282829" fillOpacity="0.6" />
                                                                                <path d="M5.77843 10.2229H3.55621V11.8895H5.77843V10.2229Z" fill="#282829" fillOpacity="0.6" />
                                                                                <path d="M9.11176 10.2229H6.88954V11.8895H9.11176V10.2229Z" fill="#282829" fillOpacity="0.6" />
                                                                                <path d="M12.4451 7.4451H10.2229V9.11176H12.4451V7.4451Z" fill="#282829" fillOpacity="0.6" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className="w-text wbaseItem-value 2e39abc3-d5cb-4ee3-9f61-b86913593d74" level={13} cateid={139}>
                                                                            {new Date(item.createdDate).toLocaleDateString("en-GB")}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-text wbaseItem-value 25659e4f-248d-4492-9f3f-5bcab0dc70da" level={11} cateid={139}>
                                                                    {item.noiDung}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="w-frame wbaseItem-value w-col f3a66ad9-c326-4e80-b654-b3972e4ad174" level={9} cateid={140}>
                                                <div className="w-text wbaseItem-value 061034cf-a490-44d3-b388-cf3ec090bb51" level={10} cateid={139}>Thêm ghi chú</div>
                                                {/* <div className="w-textformfield wbaseItem-value w-row f86bd019-03f7-4346-a45a-280ae43c6fcf" level={10} cateid={85} placeholder="Thêm ghi chú">
                                                    <div className="wbaseItem-value fd0dfff9-48d3-4e16-9f97-8d4980a5041f" level={11} cateid={86}>
                                                        <div className="textfield">
                                                            <input name="Textformfield" placeholder="Thêm ghi chú" />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div
                                                    className="w-textformfield wbaseItem-value w-row f86bd019-03f7-4346-a45a-280ae43c6fcf" wrap="nowrap"
                                                    level={11} cateid={85}
                                                    placeholder="Thêm ghi chú"
                                                >
                                                    <textarea
                                                        // name="noiDung"
                                                        {...register("noiDung")} rules={{ required: true }}
                                                        value={newNote}
                                                        maxLength={2000}
                                                        style={{ resize: "none", width: "100%", height: "100%", border: 0, outline: 0, fontSize: "14px" }}
                                                        placeholder="Thêm ghi chú"
                                                        onChange={(ev) => {
                                                            setNewNote(ev.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="w-frame wbaseItem-value w-row col- col6-xxl col6-xl col12-lg col24-md 511dd007-dc1e-4ce8-8b46-56d2fa88e6dd" level={10} cateid={140}>
                                                    <label
                                                        onClick={(ev) => {
                                                            setNghiNgoLuaDao(!nghiNgoLuaDao);
                                                            setSettingData({ ...settingData, "nghiNgoLuaDao": nghiNgoLuaDao === true ? 1 : 0 });
                                                        }}
                                                        className="w-switch wbaseItem-value d5c8e978-3c3e-48ef-ab36-c240c382c5fc" name-field="Switch" level={11} cateid={81}>
                                                        <input checked={nghiNgoLuaDao} type="checkbox" name="Switch" disabled />
                                                        <span className="slider" />
                                                    </label>
                                                    <div className="w-frame wbaseItem-value w-row 1884c544-8a5d-4204-8dc5-70b8595809ec" level={11} cateid={140}>
                                                        <div className="w-text wbaseItem-value 52548477-596e-40c1-9fb7-939a8c259cd3" level={12} cateid={139}>*</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="w-frame wbaseItem-value w-row 04f360dd-4290-4536-b6d0-d3e0a14f3787" level={3} cateid={140}>
                        <button onClick={() => setThoatKhoiTao(true)} type="button" className="w-button wbaseItem-value w-row 18c4f8d4-4bdb-4ebb-a1ac-9467de539973" level={4} cateid={29}>
                            <div className="w-text wbaseItem-value a2600654-48d8-45df-9a0a-1a09ae5197ac" level={5} cateid={139}>Thoát</div>
                        </button>
                        <div className="w-frame wbaseItem-value w-row 4b46ec56-fbfc-4ad0-b7bb-addbdfc02a5e" level={4} cateid={140}>
                            <button onClick={() => setHuyKhoiTao(true)} type="button" className="w-button wbaseItem-value w-row 2d240534-996d-41c3-b929-113232c8beb5" level={5} cateid={29}>
                                <div className="w-svg wbaseItem-value dc1e7722-4900-4238-9056-139e57b5ff09" level={6} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value cfc011f4-398b-4803-80bf-66cd3cb287b5" level={6} cateid={139}>Hủy hồ sơ</div>
                            </button>
                            <button type="button" onClick={saveloading !== true ? handleSaveForm : null} className="w-button wbaseItem-value w-row 1ca410af-2283-4f1a-bea8-95b0f251a442" level={5} cateid={29}>
                                {
                                    saveloading === true ?
                                        < FontAwesomeIcon icon={faSpinner} spin style={{ color: "#28282999", order: 1 }} />
                                        :
                                        <div className="w-svg wbaseItem-value fc39e14e-9d67-4c21-9cf3-7ed232e60091" level={6} cateid={115}>
                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.1665 2H6.49997C5.83694 2 5.20106 2.26339 4.73223 2.73223C4.26339 3.20106 4 3.83694 4 4.49997V21.5831C3.99996 21.6577 4.01993 21.7309 4.05783 21.7951C4.09572 21.8593 4.15015 21.9122 4.21545 21.9482C4.28074 21.9842 4.35449 22.002 4.42902 21.9998C4.50355 21.9976 4.57612 21.9754 4.63916 21.9356L12.3332 17.0765L20.0273 21.9356C20.0938 21.9779 20.171 22.0002 20.2498 21.9998C20.3603 21.9998 20.4663 21.9559 20.5445 21.8777C20.6226 21.7996 20.6665 21.6936 20.6665 21.5831V4.49997C20.6665 3.83694 20.4031 3.20106 19.9343 2.73223C19.4654 2.26339 18.8295 2 18.1665 2V2Z" fill="#28282999" />
                                            </svg>
                                        </div>
                                }
                                <div className="w-text wbaseItem-value 1ee79659-30c2-455d-9cb5-6948533eec97" level={6} cateid={139}>Lưu</div>
                            </button>
                            <button type="submit" className="w-button wbaseItem-value w-row 762e00bc-98c6-46c3-b1dd-f965460e6e94" level={5} cateid={29}>
                                {
                                    sendloading === true ?
                                        < FontAwesomeIcon icon={faSpinner} spin style={{ color: "white", order: 1 }} />
                                        :
                                        <div className="w-svg wbaseItem-value 3280e0d2-079e-4fe9-b00b-3973315514b9" level={6} cateid={115}>
                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M21.7337 5.26627C22.0888 5.62129 22.0888 6.19689 21.7337 6.55192L9.91555 18.3701C9.56053 18.7251 8.98493 18.7251 8.6299 18.3701L2.26627 12.0065C1.91124 11.6514 1.91124 11.0758 2.26627 10.7208C2.62129 10.3658 3.19689 10.3658 3.55192 10.7208L9.27273 16.4416L20.4481 5.26627C20.8031 4.91124 21.3787 4.91124 21.7337 5.26627Z" fill="#FFFFFFFF" />
                                            </svg>
                                        </div>
                                }
                                <div className="w-text wbaseItem-value c1c90cfc-dd23-4396-9d0a-2d506a1bdf96" level={6} cateid={139}>Gửi</div>
                            </button>
                        </div>
                    </div>
                </form >
            </>
            :
            <div>

            </div>
    )
}
