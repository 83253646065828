import '../../css/Popup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import ReactDOM from 'react-dom';
import { getData, putData } from '../../core/BaseDA';
import { useState } from 'react';
import $ from 'jquery';
import { useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import NotificationAlert from "react-notification-alert";

import { baseurl } from '../../config/ConfigAPI';
import Select2 from '../../components/select2/Select2';
import { ERROR, notify, SUCCESS } from '../../core/AlertNoti';
import { getListHanmuc } from '../../redux/middleware/api/hanmuc/hanmucDA';
import { getProductCate, getbproductList, getdealersList } from '../../redux/middleware/api/Filter/Filter';
import Pagination from '../../components/Pagination/Pagination';

import { ExportTemplate, getListAllUser, UploadFileUser } from '../../redux/middleware/api/quanlynguoidung/userDA';
import loanprogramDA from '../../redux/middleware/api/W_ChuongTrinhVay/loanprogramDA';

import { ChangestatusInsur, getListInsurance, getallInsurancechargedtype, getallInsurancepaymenttype, getallInsurancesuplier, getallInsurancetype } from '../../redux/middleware/api/insurance/insuranceDA';
import { getListdocuments, gettypedocument } from '../../redux/middleware/api/documents/documentsDA';
import BlackListDA from '../../redux/middleware/api/BlackList/BlackListDA';
import ScoringDA from '../../redux/middleware/api/Scoring/ScoringDA';
import NhanhieuDA from '../../redux/middleware/api/NhanHieu/NhanhieuDA';
import { BrandType } from '../../const/enum';
import { parse, format } from 'date-fns';


export function PopupError({ code, callback_action, notificationAlert }) {
    const rejectFile = async (code) => {
        var response = await putData(`${baseurl}ho-so/user-cancel?idHoSo=${code}`, { obj: {} });

        if (response.code === "200") {
            // TODO: update UI
            callback_action(response.code);
        } else {
            callback_action(response.code);
            notify(notificationAlert, ERROR, response?.message ?? "ERROR")
        }

    }
    return ReactDOM.createPortal(
        <div className='popup-overlay'>
            <div className="w-frame wbaseItem-value w-col min-brp" level={1} cateid={140} id="4abc428d-21b6-4320-a00b-5f29823c2a51">
                <div className="w-frame wbaseItem-value w-col" name-field="Frame" level={2} cateid={140} id="4ba0e206-5729-4c6d-9a0f-259511cf1290">
                    <div className="w-svg wbaseItem-value" level={3} cateid={115} id="064c9e20-4980-44c7-ab0c-d7c3440b2be5">
                        <svg width={72} height={72} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width={72} height={72} rx={36} fill="#FFF1F2" />
                            <path d="M36 17.25C32.2916 17.25 28.6665 18.3497 25.5831 20.4099C22.4996 22.4702 20.0964 25.3986 18.6773 28.8247C17.2581 32.2508 16.8868 36.0208 17.6103 39.6579C18.3338 43.2951 20.1195 46.636 22.7418 49.2582C25.364 51.8805 28.7049 53.6662 32.3421 54.3897C35.9792 55.1132 39.7492 54.7419 43.1753 53.3227C46.6014 51.9036 49.5298 49.5004 51.5901 46.4169C53.6503 43.3335 54.75 39.7084 54.75 36C54.7356 31.0316 52.7555 26.2709 49.2423 22.7577C45.7291 19.2445 40.9684 17.2644 36 17.25V17.25ZM43.7344 41.525L41.525 43.7344L36 38.2094L30.475 43.7344L28.2656 41.525L33.7906 36L28.2656 30.475L30.475 28.2656L36 33.7906L41.525 28.2656L43.7344 30.475L38.2094 36L43.7344 41.525Z" fill="#E60012" />
                        </svg>
                    </div>
                    <div className="w-frame wbaseItem-value w-col" name-field="Frame" level={3} cateid={140} id="ab0f4812-3b03-4546-83f5-c0fd95a00664">
                        <div className="w-frame wbaseItem-value w-col" name-field="Frame" level={4} cateid={140} id="0cbe0414-53ed-47de-8c39-c11f85c265a8">
                            <div className="w-text wbaseItem-value" level={5} cateid={139} id="7c8647c1-675e-4896-8f8b-508efdd9fdb9">Từ chối tiếp nhận hồ sơ<br /></div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row" level={4} cateid={140} id="e6d530e2-d9cc-4b90-a03f-692ea6acb8f4">
                            <div className="w-text wbaseItem-value" level={5} cateid={139} id="b0dee765-fce4-4d9e-add5-ce162177b5b4">Bạn chắc chắn muốn từ chối tiếp nhận hồ sơ?</div>
                        </div>
                    </div>
                </div>
                <div className="w-frame wbaseItem-value w-row" level={2} cateid={140} id="e6fd9c10-f4e8-401a-a33e-dc948db4da4a">
                    <button type="button" className="w-button wbaseItem-value w-row close-popup" level={3} cateid={29} id="c2574f8c-3967-4d83-a805-aa995dc3f3c8">
                        <div className="w-text wbaseItem-value" level={4} cateid={139} id="61883415-46d9-41b2-aed2-5387660c7fa4">Hủy<br /></div>
                    </button>
                    <button onClick={() => rejectFile(code)} type="button" className="w-button wbaseItem-value w-row" level={3} cateid={29} id="4e95b29f-ef32-4f86-a0a6-3e8857c69ff0">
                        <div className="w-text wbaseItem-value" level={4} cateid={139} id="748d9690-13be-401a-b6fa-ede8a77f1d9b">Xác nhận</div>
                    </button>
                </div>
            </div>
        </div>, document.body
    );
}

export function PopupInfo({ code, callback_action, notificationAlert }) {
    const acceptFile = async (code) => {
        var response = await putData(`${baseurl}ho-so/user-accept/${code}`, { obj: {} });

        if (response.code === "200") {
            // TODO: update UI
            callback_action(response.code);
        } else {
            callback_action(response.code);
            notify(notificationAlert, ERROR, response?.message ?? "ERROR")
        }
    }
    return ReactDOM.createPortal(
        <div className='popup-overlay'>
            <div className="w-frame wbaseItem-value w-col min-brp" level={1} cateid={140} id="d59fdfd9-d2ab-4667-905c-ddd5b9f31099">
                <div className="w-frame wbaseItem-value w-col" name-field="Frame" level={2} cateid={140} id="67c7840c-ad4d-4618-a6ed-b101bf7a8509">
                    <div className="w-svg wbaseItem-value" level={3} cateid={115} id="1524bb54-1386-4794-9c45-829ccc6b515e">
                        <svg width={72} height={72} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width={72} height={72} rx={36} fill="#EEF4FD" />
                            <path d="M36 17.25C32.2916 17.25 28.6665 18.3497 25.5831 20.4099C22.4996 22.4702 20.0964 25.3986 18.6773 28.8247C17.2581 32.2508 16.8868 36.0208 17.6103 39.6579C18.3338 43.2951 20.1195 46.636 22.7418 49.2582C25.364 51.8805 28.7049 53.6662 32.3421 54.3897C35.9792 55.1132 39.7492 54.7419 43.1753 53.3227C46.6014 51.9036 49.5298 49.5004 51.5901 46.4169C53.6503 43.3335 54.75 39.7084 54.75 36C54.7446 31.0288 52.7675 26.2628 49.2523 22.7477C45.7372 19.2325 40.9712 17.2554 36 17.25V17.25ZM37.6063 46.5062H34.3797V32.875H37.6063V46.5062ZM37.3391 28.5922C37.161 28.7553 36.952 28.8809 36.7243 28.9615C36.4966 29.042 36.2551 29.0758 36.0141 29.0609C35.7686 29.0773 35.5224 29.0442 35.29 28.9637C35.0576 28.8832 34.8437 28.7568 34.6609 28.5922C34.4995 28.4184 34.3748 28.2138 34.2943 27.9907C34.2137 27.7676 34.179 27.5306 34.1922 27.2937C34.1758 27.0514 34.209 26.8083 34.2896 26.5792C34.3701 26.35 34.4965 26.1397 34.6609 25.9609C34.8442 25.797 35.0581 25.6712 35.2904 25.5907C35.5227 25.5102 35.7687 25.4767 36.0141 25.4922C36.255 25.4782 36.4963 25.5124 36.7239 25.5929C36.9514 25.6734 37.1605 25.7985 37.3391 25.9609C37.5035 26.1397 37.6299 26.35 37.7105 26.5792C37.791 26.8083 37.8242 27.0514 37.8078 27.2937C37.821 27.5306 37.7863 27.7676 37.7057 27.9907C37.6252 28.2138 37.5005 28.4184 37.3391 28.5922V28.5922Z" fill="#367BE2" />
                        </svg>
                    </div>
                    <div className="w-frame wbaseItem-value w-col" name-field="Frame" level={3} cateid={140} id="e2bb993a-7a2a-4feb-a8bf-886e14cbe7f3">
                        <div className="w-frame wbaseItem-value w-col" name-field="Frame" level={4} cateid={140} id="a8661836-f688-40a0-b48c-8e0cdd78e34c">
                            <div className="w-text wbaseItem-value" level={5} cateid={139} id="0dd902c4-c0d7-4690-82c9-0360a5d0d835">Tiếp nhận hồ sơ<br /></div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row" level={4} cateid={140} id="f46014d5-902c-4726-8fdb-94b522f11a6d">
                            <div className="w-text wbaseItem-value" level={5} cateid={139} id="1430b097-f91b-4e41-a828-58d20b76f2b8">Bạn chắc chắn muốn tiếp nhận hồ sơ?</div>
                        </div>
                    </div>
                </div>
                <div className="w-frame wbaseItem-value w-row" level={2} cateid={140} id="19fee093-e31b-4af3-8571-4de84593e68e">
                    <button type="button" className="w-button wbaseItem-value w-row close-popup" level={3} cateid={29} id="142c8601-5bf4-4387-ae98-bbca1f126fb4">
                        <div className="w-text wbaseItem-value" level={4} cateid={139} id="df583baa-5ffb-41fd-8e92-64f8b3e72d70">Hủy<br /></div>
                    </button>
                    <button onClick={() => acceptFile(code)} type="button" className="w-button wbaseItem-value w-row" level={3} cateid={29} id="6519bed4-e52f-404c-b520-01cd3dc70fce">
                        <div className="w-text wbaseItem-value" level={4} cateid={139} id="fccfaf6d-3fa9-4adc-804e-845c719c6adc">Xác nhận</div>
                    </button>
                </div>
            </div>
        </div>, document.body
    );
}

export function PopupTransfer({ code, callback_action, notificationAlert }) {
    const [listAccount, setListAccount] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState();

    const handleGetList = async () => {
        let response = await getData(baseurl + `ho-so/other-emp?idHoSo=${code}`);
        setListAccount(response);
    }

    const handleSelectAccount = (option) => {
        $('.action-confirm').addClass("active");
        if (option !== null) {
            setSelectedAccount(option)
        }
    }

    const handleSearch = (value) => {
        handleGetList({ name: value });
    }

    const handleTransfer = async () => {
        if ($('.action-confirm').hasClass("active")) {
            var response = await putData(baseurl + `ho-so/user-asign?idHoSo=${code}&targetUser=${selectedAccount}`, { obj: {} });
            if (response.code === "200") {
                callback_action(response.code);
            } else {
                callback_action(response.code);
                notify(notificationAlert, ERROR, response?.message ?? "ERROR")
            }
        }
    }

    useEffect(() => {
        handleGetList({ page: 1, size: 10, name: '' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return ReactDOM.createPortal(
        <div className='popup-overlay'>
            <div className='popup-container'>
                <div className='popup-header row'>
                    <div className='popup-name space'>Sản phẩm</div>
                    <div className='close-popup'>
                        {/* <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                        </svg> */}
                    </div>
                </div>
                <div className='popup-body col'>
                    <div className='body-label'>Cán bộ nhận hồ sơ</div>

                    {/* <MultiSelectList
                        list={listAccount}
                        onSelect={handleSelectAccount}
                        onChange={handleSearch}
                        placeholder={"Nhập account cán bộ tiếp nhận hồ sơ"}
                    ></MultiSelectList> */}
                    <div className="w-frame wbaseItem-value w-col" level={7} cateid={140} id="2b82e8e5-531d-4e37-85b1-1e67f9be9b10">
                        <Select2
                            style={{ order: 2 }}
                            data={listAccount.map((item, _) => { return { name: item.staffCodeAndName, id: item.userName } })}
                            value={selectedAccount}
                            options={
                                {
                                    placeholder: 'Nhập account cán bộ tiếp nhận hồ sơ',
                                }
                            }
                            name='dealerId'
                            // onChange={handleSelectAccount}
                            onSelect={handleSelectAccount}
                        />
                    </div>

                </div>

                <div className='popup-footer row'>
                    <button className='close-popup'>Hủy</button>
                    <button
                        onClick={() => handleTransfer()}
                        className='action-confirm'>Xác nhận</button>
                </div>
            </div>
        </div >, document.body
    );
}
export function PopupUploadBlackList({ callback_action, notificationAlert, callback_closed }) {
    const [filepath, setfilepath] = useState([]);

    const handleTransfer = async () => {
        if (filepath.length > 0) {
            var fileUpload = new FormData();
            fileUpload.append('fileUpload', filepath[0]);
            const res = await BlackListDA.UploadFileBlackList(fileUpload);
            if (res.code === '200') {
                notify(notificationAlert, SUCCESS, 'Upload File thành công.')
                callback_action()
            } else {
                notify(notificationAlert, ERROR, res.message ?? 'Đã có lỗi xảy ra.')
            }
        }
    }
    const downloadTemplate = async () => {
        const res = await BlackListDA.downloadFiletemp();
        if (res.status === 201) {
            var blob = new Blob([res.data], {
                type: res.headers["content-type"],
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.target = '_blank'; // Open the link in a new tab/window
            link.download = 'template-blackList.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            notify(notificationAlert, ERROR, res.message ?? 'Đã có lỗi xảy ra.')
        }
    }
    const onchangeFile = (event) => {
        setfilepath([...event.target.files])
    }
    useEffect(() => {

    }, [])

    return ReactDOM.createPortal(
        <div className='popup-overlay'>
            <div className='popup-container'>
                <div className='popup-header row'>
                    <div className='popup-name space'>UploadFile</div>
                    <div className='close-popup'>
                        {/* <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                        </svg> */}
                    </div>
                </div>
                <div className='popup-body col'>
                    <div className='body-label'>Tải file lên</div>
                    {/* <MultiSelectList
                        list={listAccount}
                        onSelect={handleSelectAccount}
                        onChange={handleSearch}
                        placeholder={"Nhập account cán bộ tiếp nhận hồ sơ"}
                    ></MultiSelectList> */}
                    {/* <div className="w-frame wbaseItem-value w-col" level={7} cateid={140} id="2b82e8e5-531d-4e37-85b1-1e67f9be9b10">
                        
                    </div> */}
                    <div className="w-frame wbaseItem-value w-row 00134998-6925-4788-8420-076328f168a5" wrap="wrap" level={9} cateid={140}>
                        <div style={{ height: 30 }} className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md 4dbb1795-28b5-4191-bfe5-d9f0d1009d99" level={10} cateid={140}>
                            {/* <div className="w-frame wbaseItem-value w-row e7dedea5-3d0e-43ca-86b9-fdc636530c9c" level={11} cateid={140}>
                                <div className="w-text wbaseItem-value 5455e896-536c-4f75-9940-a8ba2129423d" level={12} cateid={139}>Upload File</div>
                                <div className="w-text wbaseItem-value 44f27e63-094b-41c9-afad-ae322fe10545" level={12} cateid={139}>*</div>
                            </div> */}
                            {
                                filepath.length > 0 ?
                                    <div style={{ order: 0 }} className='w-frame wbaseItem-value w-row 6ac84289-acb3-4274-819b-ee8371f76a61'>
                                        <div className="w-text wbaseItem-value e6d7d81e-044b-4c01-a317-0ea7a0e746a1" level={11} cateid={139} style={{ color: "#367be2", marginTop: "2px" }}>{filepath[0].name}</div>
                                        <button type='button' onClick={() => {
                                            setfilepath([])
                                        }} className='w-button wbaseItem-value w-row 048f37f4-3754-433e-a810-b27ee519f8d2' style={{ backgroundColor: "transparent" }}>
                                            <div className="w-svg wbaseItem-value b77c8d2c-435b-4b57-bf0f-5c6c035ca28d" level={11} cateid={115}>
                                                <svg width={20} height={20} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.48302 13.1195C3.51435 13.5394 3.70291 13.932 4.01105 14.2189C4.31918 14.5058 4.72421 14.666 5.14524 14.6673H10.8541C11.2752 14.666 11.6802 14.5058 11.9883 14.2189C12.2965 13.932 12.485 13.5394 12.5164 13.1195L13.0408 5.77843H2.95858L3.48302 13.1195Z" fill="#E60012" />
                                                    <path d="M14.1108 3.55621H10.7775V1.88954C10.7775 1.7422 10.7189 1.60089 10.6147 1.4967C10.5105 1.39252 10.3692 1.33398 10.2219 1.33398H5.77745C5.63011 1.33398 5.4888 1.39252 5.38462 1.4967C5.28043 1.60089 5.2219 1.7422 5.2219 1.88954V3.55621H1.88856C1.74122 3.55621 1.59991 3.61474 1.49573 3.71893C1.39154 3.82311 1.33301 3.96442 1.33301 4.11176C1.33301 4.2591 1.39154 4.40041 1.49573 4.5046C1.59991 4.60879 1.74122 4.66732 1.88856 4.66732H14.1108C14.2581 4.66732 14.3994 4.60879 14.5036 4.5046C14.6078 4.40041 14.6663 4.2591 14.6663 4.11176C14.6663 3.96442 14.6078 3.82311 14.5036 3.71893C14.3994 3.61474 14.2581 3.55621 14.1108 3.55621ZM6.33301 2.4451H9.66634V3.55621H6.33301V2.4451Z" fill="#E60012" />
                                                </svg>
                                            </div>
                                        </button>
                                    </div> : <div style={{ order: 0 }} className={"w-upload-file-field w-frame wbaseItem-value w-row 6ac84289-acb3-4274-819b-ee8371f76a61"} level={10} cateid={140}>
                                        <input
                                            type="file"
                                            name='imageProfile'
                                            style={{ width: 0, height: 0, position: "absolute" }}
                                            onChange={onchangeFile}
                                        />
                                        <button type='button' onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.target.previousElementSibling.showPicker();
                                        }} className='w-button wbaseItem-value w-row 048f37f4-3754-433e-a810-b27ee519f8d2'>
                                            <div className="w-svg wbaseItem-value b77c8d2c-435b-4b57-bf0f-5c6c035ca28d" level={11} cateid={115}>
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7 8.83333L12 3L17 8.83333H12.8333V16.3333H11.1667V8.83333H7Z" fill="#28282999">
                                                    </path>
                                                    <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#28282999">
                                                    </path>
                                                </svg>
                                            </div>
                                            <div className="w-text wbaseItem-value e6d7d81e-044b-4c01-a317-0ea7a0e746a1" level={11} cateid={139}>Upload</div>
                                        </button>
                                    </div>
                            }

                        </div>
                        <div style={{ height: 30 }} className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md 4dbb1795-28b5-4191-bfe5-d9f0d1009d99" level={10} cateid={140}>
                            <div className="w-frame wbaseItem-value w-row e7dedea5-3d0e-43ca-86b9-fdc636530c9c" level={11} cateid={140}>
                                <a onClick={downloadTemplate} href='javascript:void(0);' style={{ textDecoration: 'underline !important', color: '#526beb !important', fontSize: 14 }} className="w-text wbaseItem-value" level={12} cateid={139}>Download template</a>
                            </div>
                        </div>

                    </div>


                </div>

                <div className='popup-footer row'>
                    <button onClick={() => callback_closed()} className='close-popup'>Hủy</button>
                    <button
                        onClick={() => handleTransfer()}
                        className='action-confirm'>Tải lên</button>
                </div>
            </div>
        </div >, document.body
    );
}
export function PopupUploadUser({ callback_action, notificationAlert, callback_closed }) {
    const [filepath, setfilepath] = useState([]);

    const handleTransfer = async () => {
        if (filepath.length > 0) {
            var fileUpload = new FormData();
            fileUpload.append('fileUpload', filepath[0]);
            const res = await UploadFileUser(fileUpload);
            if (res.code === '200') {
                notify(notificationAlert, SUCCESS, 'Upload File thành công.')
                callback_action()
            } else {
                notify(notificationAlert, ERROR, res.message ?? 'Đã có lỗi xảy ra.')
            }
        }
    }
    const downloadTemplate = async () => {
        const res = await ExportTemplate();
        if (res.status === 201) {
            var blob = new Blob([res.data], {
                type: res.headers["content-type"],
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.target = '_blank'; // Open the link in a new tab/window
            link.download = 'template-user.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            notify(notificationAlert, ERROR, res.message ?? 'Đã có lỗi xảy ra.')
        }
    }
    const onchangeFile = (event) => {
        setfilepath([...event.target.files])
    }
    useEffect(() => {

    }, [])

    return ReactDOM.createPortal(
        <div className='popup-overlay'>
            <div className='popup-container'>
                <div className='popup-header row'>
                    <div className='popup-name space'>UploadFile</div>
                    <div className='close-popup'>
                        {/* <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                        </svg> */}
                    </div>
                </div>
                <div className='popup-body col'>
                    <div className='body-label'>Tải file lên</div>
                    {/* <MultiSelectList
                        list={listAccount}
                        onSelect={handleSelectAccount}
                        onChange={handleSearch}
                        placeholder={"Nhập account cán bộ tiếp nhận hồ sơ"}
                    ></MultiSelectList> */}
                    {/* <div className="w-frame wbaseItem-value w-col" level={7} cateid={140} id="2b82e8e5-531d-4e37-85b1-1e67f9be9b10">
                        
                    </div> */}
                    <div className="w-frame wbaseItem-value w-row 00134998-6925-4788-8420-076328f168a5" wrap="wrap" level={9} cateid={140}>
                        <div style={{ height: 30 }} className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md 4dbb1795-28b5-4191-bfe5-d9f0d1009d99" level={10} cateid={140}>
                            {/* <div className="w-frame wbaseItem-value w-row e7dedea5-3d0e-43ca-86b9-fdc636530c9c" level={11} cateid={140}>
                                <div className="w-text wbaseItem-value 5455e896-536c-4f75-9940-a8ba2129423d" level={12} cateid={139}>Upload File</div>
                                <div className="w-text wbaseItem-value 44f27e63-094b-41c9-afad-ae322fe10545" level={12} cateid={139}>*</div>
                            </div> */}
                            {
                                filepath.length > 0 ?
                                    <div style={{ order: 0 }} className='w-frame wbaseItem-value w-row 6ac84289-acb3-4274-819b-ee8371f76a61'>
                                        <div className="w-text wbaseItem-value e6d7d81e-044b-4c01-a317-0ea7a0e746a1" level={11} cateid={139} style={{ color: "#367be2", marginTop: "2px" }}>{filepath[0].name}</div>
                                        <button type='button' onClick={() => {
                                            setfilepath([])
                                        }} className='w-button wbaseItem-value w-row 048f37f4-3754-433e-a810-b27ee519f8d2' style={{ backgroundColor: "transparent" }}>
                                            <div className="w-svg wbaseItem-value b77c8d2c-435b-4b57-bf0f-5c6c035ca28d" level={11} cateid={115}>
                                                <svg width={20} height={20} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.48302 13.1195C3.51435 13.5394 3.70291 13.932 4.01105 14.2189C4.31918 14.5058 4.72421 14.666 5.14524 14.6673H10.8541C11.2752 14.666 11.6802 14.5058 11.9883 14.2189C12.2965 13.932 12.485 13.5394 12.5164 13.1195L13.0408 5.77843H2.95858L3.48302 13.1195Z" fill="#E60012" />
                                                    <path d="M14.1108 3.55621H10.7775V1.88954C10.7775 1.7422 10.7189 1.60089 10.6147 1.4967C10.5105 1.39252 10.3692 1.33398 10.2219 1.33398H5.77745C5.63011 1.33398 5.4888 1.39252 5.38462 1.4967C5.28043 1.60089 5.2219 1.7422 5.2219 1.88954V3.55621H1.88856C1.74122 3.55621 1.59991 3.61474 1.49573 3.71893C1.39154 3.82311 1.33301 3.96442 1.33301 4.11176C1.33301 4.2591 1.39154 4.40041 1.49573 4.5046C1.59991 4.60879 1.74122 4.66732 1.88856 4.66732H14.1108C14.2581 4.66732 14.3994 4.60879 14.5036 4.5046C14.6078 4.40041 14.6663 4.2591 14.6663 4.11176C14.6663 3.96442 14.6078 3.82311 14.5036 3.71893C14.3994 3.61474 14.2581 3.55621 14.1108 3.55621ZM6.33301 2.4451H9.66634V3.55621H6.33301V2.4451Z" fill="#E60012" />
                                                </svg>
                                            </div>
                                        </button>
                                    </div> : <div style={{ order: 0 }} className={"w-upload-file-field w-frame wbaseItem-value w-row 6ac84289-acb3-4274-819b-ee8371f76a61"} level={10} cateid={140}>
                                        <input
                                            type="file"
                                            name='imageProfile'
                                            style={{ width: 0, height: 0, position: "absolute" }}
                                            onChange={onchangeFile}
                                        />
                                        <button type='button' onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.target.previousElementSibling.showPicker();
                                        }} className='w-button wbaseItem-value w-row 048f37f4-3754-433e-a810-b27ee519f8d2'>
                                            <div className="w-svg wbaseItem-value b77c8d2c-435b-4b57-bf0f-5c6c035ca28d" level={11} cateid={115}>
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7 8.83333L12 3L17 8.83333H12.8333V16.3333H11.1667V8.83333H7Z" fill="#28282999">
                                                    </path>
                                                    <path d="M20.3333 19.6667H3.66667V14.6667H2V20.5C2 20.96 2.37333 21.3333 2.83333 21.3333H21.1667C21.6267 21.3333 22 20.96 22 20.5V14.6667H20.3333V19.6667Z" fill="#28282999">
                                                    </path>
                                                </svg>
                                            </div>
                                            <div className="w-text wbaseItem-value e6d7d81e-044b-4c01-a317-0ea7a0e746a1" level={11} cateid={139}>Upload</div>
                                        </button>
                                    </div>
                            }

                        </div>
                        <div style={{ height: 30 }} className="w-frame wbaseItem-value w-col col- col6-xxl col12-xl col24-lg col24-md 4dbb1795-28b5-4191-bfe5-d9f0d1009d99" level={10} cateid={140}>
                            <div className="w-frame wbaseItem-value w-row e7dedea5-3d0e-43ca-86b9-fdc636530c9c" level={11} cateid={140}>
                                <a onClick={downloadTemplate} href='javascript:void(0);' style={{ textDecoration: 'underline !important', color: '#526beb !important', fontSize: 14 }} className="w-text wbaseItem-value" level={12} cateid={139}>Download template</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='popup-footer row'>
                    <button onClick={() => callback_closed()} className='close-popup'>Hủy</button>
                    <button
                        onClick={() => handleTransfer()}
                        className='action-confirm'>Tải lên</button>
                </div>
            </div>
        </div >, document.body
    );
}

export function PopupSelectHanmuc({ code, callback_action, onClose }) {
    const [listlimit, setListlimit] = useState([]);
    const [total, setTotal] = useState([]);
    const [pageDetails, setPageDetails] = useState({
        current: 1,
        size: 10
    });

    const _InitData = async (page, pageSize) => {
        const response = await getListHanmuc(page, pageSize);

        if (response != null) {

            setTotal(response.totalElements ?? 0)
            setListlimit((listlimit) =>
                response.content.map((item) =>
                    code?.some((a) => a.id === item.id) ? { ...item, check: true } : item
                ));
            // setListlimit(response.content);
        }
    }


    const OnchangeCheckedgroup = (index) => {
        setListlimit((listlimit) =>
            listlimit.map((item) =>
                item.id === listlimit[index].id ? { ...item, check: !listlimit[index].check } : item
            ));
    }
    const handleTransfer = async () => {

        const lst = listlimit.filter((a) => a.check == true);
        callback_action(lst);
    }
    const onClosePop = async () => {
        onClose();
    }

    useEffect(() => {
        _InitData(pageDetails.current, pageDetails.size);
    }, [])

    return ReactDOM.createPortal(
        <div className='popup-overlay'>
            <div className='popup-container w1200'>
                <div className='popup-header row'>
                    <div className='popup-name space'>Hạn mức</div>
                    <div className='close-popup'>
                        {/* <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                        </svg> */}
                    </div>
                </div>
                <div className='popup-body w-frame wbaseItem-value w-col' scroll="true">
                    <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                        <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>
                            <table className="w-table wbaseItem-value b4130bfc-5ed4-4a69-a0a7-e467c2e51acd" type={0} level={9} cateid={117}>
                                <tbody>
                                    <tr className="table-row">
                                        <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 05c53c57-2e26-46a2-a157-4e230d860992" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value 4847c833-d7af-4fb0-b9df-346aab7a2a87" level={11} cateid={139}>STT</div>
                                            </div>
                                        </td>
                                        <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 3075f97b-e808-446d-876f-20726601c73f" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value a4f4771b-15cf-43e7-b097-515accbd067d" level={11} cateid={139}>Mã hạn mức</div>
                                            </div>
                                        </td>
                                        <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 40083d3d-40ff-469b-9bcd-bdedc106f73a" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value da77403d-1020-4cde-ad85-26bd2227ec89" level={11} cateid={139}>Tên hạn mức</div>
                                            </div>
                                        </td>
                                        <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 2cf0f346-5df2-4f01-b6b2-f590a3b39578" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value c4f87ca8-722b-402a-818a-f0ce5ba15b2b" level={11} cateid={139}>Chọn</div>
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        listlimit.map((item, index) => {
                                            return (<tr className="table-row">
                                                <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row d8da84bc-fed2-40c9-9e83-a39395d69054" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value e983534b-d6ae-4cb9-b002-859a13a36c8d" level={11} cateid={139}>{pageDetails.size * (pageDetails.current - 1) + index + 1}</div>
                                                    </div>
                                                </td>
                                                <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 94f11329-391b-4246-989b-9569c50458ad" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value cfc8fff8-ed9d-4e21-acbb-b1f4a3f2a9c1" level={11} cateid={139}>{item.code}</div>
                                                    </div>
                                                </td>
                                                <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 1422e989-d889-4971-8e01-037e96cdca01" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 3ee92184-53a9-45b5-8ddb-eabeb8dda7a3" level={11} cateid={139}>{item.name}</div>
                                                    </div>
                                                </td>
                                                <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row f133066c-2090-49a5-8203-593c1801a71f" level={10} cateid={140}>
                                                        <label
                                                            onClick={() => OnchangeCheckedgroup(index)}
                                                            className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                            <input type="checkbox" disabled checked={item.check ? true : false} name="Pick" />
                                                            <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>)
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination
                        currentPage={pageDetails.current}
                        itemPerPage={pageDetails.size}
                        totalItem={total}
                        onChangePage={(page, size) => {
                            if (pageDetails.current !== page || pageDetails.size !== size) {
                                setPageDetails({ current: page, size: size });
                                _InitData(page, size);
                            }
                        }}
                    />
                </div>

                <div className='popup-footer row'>
                    <button onClick={onClosePop} className='close-popup'>Hủy</button>
                    <button
                        onClick={handleTransfer}
                        className='action-confirm'>Thêm</button>
                </div>
            </div>
        </div >, document.body
    );
}
export function PopupSelectCriterial({ code, callback_action, onClose, dataold }) {
    const [listCriterial, setlistCriterial] = useState([]);

    const _InitData = async () => {
        const response = await ScoringDA.getallCriteriaBygroup(0);
        if (response != null) {
            debugger;
            var lst = response;
            // lst.push(...code?.filter((a) => a.isold === true));
            lst.push(...dataold);
            setlistCriterial((listCriterial) =>
                lst.map((item) =>
                    code?.some((a) => a.id === item.id) ? { ...item, check: true } : item
                ));
        }
    }


    const OnchangeCheckedgroup = (index) => {
        setlistCriterial((listCriterial) =>
            listCriterial.map((item) =>
                item.id === listCriterial[index].id ? { ...item, check: !listCriterial[index].check } : item
            ));
    }
    const handleTransfer = async () => {

        const lst = listCriterial.filter((a) => a.check == true);
        callback_action(lst);
    }
    const onClosePop = async () => {
        onClose();
    }
    const OnchangeCheckedAll = (ev) => {
        debugger;
        setlistCriterial([...listCriterial.map((item) => { return { ...item, check: ev.target.checked } }
        )]);
    }
    useEffect(() => {
        _InitData();
    }, [])

    return ReactDOM.createPortal(
        <div className='popup-overlay'>
            <div className='popup-container w1200'>
                <div className='popup-header row'>
                    <div className='popup-name space'>Danh sách tiêu chí</div>
                    <div className='close-popup' onClick={onClosePop}>
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                        </svg>
                    </div>
                </div>
                <div className='popup-body w-frame wbaseItem-value w-col' scroll="true">

                    <div className="w-frame wbaseItem-value w-col 4020760b-84ef-4b85-8bb3-e788ded3d298" style={{ padding: 0 }} level={8} cateid={140}>
                        <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                            <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>
                                <table className="w-table wbaseItem-value b4130bfc-5ed4-4a69-a0a7-e467c2e51acd" type={0} name-field="Table" level={9} cateid={117}>
                                    <tbody>
                                        <tr className="table-row">
                                            <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 05c53c57-2e26-46a2-a157-4e230d860992" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value 4847c833-d7af-4fb0-b9df-346aab7a2a87" level={11} cateid={139}>STT</div>
                                                </div>
                                            </td>
                                            <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 3075f97b-e808-446d-876f-20726601c73f" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value a4f4771b-15cf-43e7-b097-515accbd067d" level={11} cateid={139}>Tiêu chí</div>
                                                </div>
                                            </td>
                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row 40083d3d-40ff-469b-9bcd-bdedc106f73a" level={10} cateid={140}>
                                                    <div className="w-text wbaseItem-value da77403d-1020-4cde-ad85-26bd2227ec89" level={11} cateid={139}>Loại</div>
                                                </div>
                                            </td>

                                            <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row d7bfebbd-090e-43bc-a49c-d11c6432a6c7" level={7} cateid={140}>
                                                    {/* <div className="w-text wbaseItem-value f491a7b4-eafa-4610-bee4-d82b5c97ff3e" level={8} cateid={139}>Hành động</div>
                                            <div className="w-svg wbaseItem-value 7b48f46e-a820-427e-9bd2-e8cadc861ff3" level={8} cateid={115}>
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.48 2.2162C12.4217 2.1486 12.3484 2.09415 12.2654 2.05677C12.1824 2.01939 12.0918 2 12 2C11.9082 2 11.8176 2.01939 11.7346 2.05677C11.6516 2.09415 11.5783 2.1486 11.52 2.2162L6.12 9.08426C6.05314 9.16929 6.01243 9.2704 6.00242 9.37626C5.99242 9.48212 6.01351 9.58855 6.06334 9.68362C6.11318 9.77869 6.18978 9.85864 6.28456 9.91452C6.37935 9.9704 6.48857 10 6.6 10H17.4C17.5114 10 17.6207 9.9704 17.7154 9.91452C17.8102 9.85864 17.8868 9.77869 17.9367 9.68362C17.9865 9.58855 18.0076 9.48212 17.9976 9.37626C17.9876 9.2704 17.9469 9.16929 17.88 9.08426L12.48 2.2162Z" fill="#28282999" />
                                                    <path d="M11.52 21.7838C11.5783 21.8514 11.6516 21.9059 11.7346 21.9432C11.8176 21.9806 11.9082 22 12 22C12.0918 22 12.1824 21.9806 12.2654 21.9432C12.3484 21.9059 12.4217 21.8514 12.48 21.7838L17.88 14.9157C17.9469 14.8307 17.9876 14.7296 17.9976 14.6237C18.0076 14.5179 17.9865 14.4114 17.9367 14.3164C17.8868 14.2213 17.8102 14.1414 17.7154 14.0855C17.6207 14.0296 17.5114 14 17.4 14H6.6C6.48857 14 6.37935 14.0296 6.28456 14.0855C6.18978 14.1414 6.11318 14.2213 6.06334 14.3164C6.01351 14.4114 5.99242 14.5179 6.00242 14.6237C6.01243 14.7296 6.05314 14.8307 6.12 14.9157L11.52 21.7838Z" fill="#28282999" />
                                                </svg>
                                            </div> */}
                                                    <label className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                        <input type="checkbox" onChange={OnchangeCheckedAll} name="PickAll" checked={listCriterial?.every(item => item.check)} />
                                                        <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        {
                                            listCriterial?.map((item, index) => {
                                                return (<tr key={item.id} className="table-row">
                                                    <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row d8da84bc-fed2-40c9-9e83-a39395d69054" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value e983534b-d6ae-4cb9-b002-859a13a36c8d" level={11} cateid={139}>{index + 1}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 94f11329-391b-4246-989b-9569c50458ad" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value cfc8fff8-ed9d-4e21-acbb-b1f4a3f2a9c1" level={11} cateid={139}>{item.name}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 1422e989-d889-4971-8e01-037e96cdca01" level={10} cateid={140}>
                                                            <div className="w-text wbaseItem-value 3ee92184-53a9-45b5-8ddb-eabeb8dda7a3" level={11} cateid={139}>{item.type === 1 ? 'Tiêu chí cố định' : 'Tiêu chí không cố định'}</div>
                                                        </div>
                                                    </td>
                                                    <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row f133066c-2090-49a5-8203-593c1801a71f" level={10} cateid={140}>
                                                            <label
                                                                onClick={() => OnchangeCheckedgroup(index)}
                                                                className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                                <input type="checkbox" checked={item.check} disabled name="Pick" />
                                                                <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>)
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='popup-footer row'>
                    <button onClick={onClosePop} className='close-popup'>Hủy</button>
                    <button
                        onClick={handleTransfer}
                        className='action-confirm'>Thêm</button>
                </div>
            </div>
        </div >, document.body
    );
}

export function PopupSelectAgent({ code, callback_action, onClose }) {
    const [listAgent, setListAgent] = useState([]);
    const [total, setTotal] = useState([]);
    const [pageDetails, setPageDetails] = useState({
        current: 1,
        size: 10
    });

    const _InitData = async (page, pageSize) => {
        const response = await getdealersList(page, pageSize);

        if (response != null) {

            setTotal(response.totalElements ?? 0)
            setListAgent((listAgent) =>
                response.content.map((item) =>
                    code?.some((a) => a.id === item.id) ? { ...item, check: true } : item
                ));
            // setListlimit(response.content);
        }
    }


    const OnchangeCheckedgroup = (index) => {
        setListAgent((listAgent) =>
            listAgent.map((item) =>
                item.id === listAgent[index].id ? { ...item, check: !listAgent[index].check } : item
            ));
    }
    const handleTransfer = async () => {

        const lst = listAgent.filter((a) => a.check == true);
        callback_action(lst);
    }
    const onClosePop = async () => {
        onClose();
    }

    useEffect(() => {

        _InitData(pageDetails.current, pageDetails.size);
    }, [])

    return ReactDOM.createPortal(
        <div className='popup-overlay'>
            <div className='popup-container w1200'>
                <div className='popup-header row'>
                    <div className='popup-name space'>Danh sách đại lý</div>
                    <div className='close-popup'>
                        {/* <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                        </svg> */}
                    </div>
                </div>
                <div className='popup-body w-frame wbaseItem-value w-col' scroll="true">

                    <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                        <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>
                            <table className="w-table wbaseItem-value b4130bfc-5ed4-4a69-a0a7-e467c2e51acd" type={0} level={9} cateid={117}>
                                <tbody>
                                    <tr className="table-row">
                                        <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 05c53c57-2e26-46a2-a157-4e230d860992" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value 4847c833-d7af-4fb0-b9df-346aab7a2a87" level={11} cateid={139}>STT</div>
                                            </div>
                                        </td>
                                        <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 3075f97b-e808-446d-876f-20726601c73f" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value a4f4771b-15cf-43e7-b097-515accbd067d" level={11} cateid={139}>Mã đại lý</div>
                                            </div>
                                        </td>
                                        <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 40083d3d-40ff-469b-9bcd-bdedc106f73a" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value da77403d-1020-4cde-ad85-26bd2227ec89" level={11} cateid={139}>Tên đại lý</div>
                                            </div>
                                        </td>
                                        <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 2cf0f346-5df2-4f01-b6b2-f590a3b39578" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value c4f87ca8-722b-402a-818a-f0ce5ba15b2b" level={11} cateid={139}>Chọn</div>
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        listAgent.map((item, index) => {
                                            return (<tr className="table-row">
                                                <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row d8da84bc-fed2-40c9-9e83-a39395d69054" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value e983534b-d6ae-4cb9-b002-859a13a36c8d" level={11} cateid={139}>{pageDetails.size * (pageDetails.current - 1) + index + 1}</div>
                                                    </div>
                                                </td>
                                                <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 94f11329-391b-4246-989b-9569c50458ad" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value cfc8fff8-ed9d-4e21-acbb-b1f4a3f2a9c1" level={11} cateid={139}>{item.code}</div>
                                                    </div>
                                                </td>
                                                <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 1422e989-d889-4971-8e01-037e96cdca01" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 3ee92184-53a9-45b5-8ddb-eabeb8dda7a3" level={11} cateid={139}>{item.name}</div>
                                                    </div>
                                                </td>
                                                <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row f133066c-2090-49a5-8203-593c1801a71f" level={10} cateid={140}>
                                                        <label
                                                            onClick={() => OnchangeCheckedgroup(index)}
                                                            className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                            <input type="checkbox" disabled checked={item.check ? true : false} name="Pick" />
                                                            <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>)
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination
                        currentPage={pageDetails.current}
                        itemPerPage={pageDetails.size}
                        totalItem={total}
                        onChangePage={(page, size) => {
                            if (pageDetails.current !== page || pageDetails.size !== size) {
                                setPageDetails({ current: page, size: size });
                                _InitData(page, size);
                            }
                        }}
                    />
                </div>

                <div className='popup-footer row'>
                    <button onClick={onClosePop} className='close-popup'>Hủy</button>
                    <button
                        onClick={handleTransfer}
                        className='action-confirm'>Thêm</button>
                </div>
            </div>
        </div >, document.body
    );
}

export function PopupSelectProduct({ code, callback_action, onClose }) {
    const [listProduct, setListProduct] = useState([]);
    const [total, setTotal] = useState([]);
    const [pageDetails, setPageDetails] = useState({
        current: 1,
        size: 10
    });

    const _InitData = async (page, pageSize) => {
        const response = await getbproductList(page, pageSize);

        if (response != null) {

            setTotal(response.totalElements ?? 0)
            setListProduct((listProduct) =>
                response.content.map((item) =>
                    code?.some((a) => a.id === item.id) ? { ...item, check: true } : item
                ));
            // setListlimit(response.content);
        }
    }


    const OnchangeCheckedgroup = (index) => {
        setListProduct((listProduct) =>
            listProduct.map((item) =>
                item.id === listProduct[index].id ? { ...item, check: !listProduct[index].check } : item
            ));
    }
    const handleTransfer = async () => {

        const lst = listProduct.filter((a) => a.check === true);
        callback_action(lst);
    }
    const onClosePop = async () => {
        onClose();
    }

    useEffect(() => {

        _InitData(pageDetails.current, pageDetails.size);
    }, [])

    return ReactDOM.createPortal(
        <div className='popup-overlay'>
            <div className='popup-container w1200'>
                <div className='popup-header row'>
                    <div className='popup-name space'>Sản phẩm</div>
                    <div className='close-popup'>
                        {/* <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                        </svg> */}
                    </div>
                </div>
                <div className='popup-body w-frame wbaseItem-value w-col' scroll="true">

                    <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                        <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>
                            <table className="w-table wbaseItem-value b4130bfc-5ed4-4a69-a0a7-e467c2e51acd" type={0} level={9} cateid={117}>
                                <tbody>
                                    <tr className="table-row">
                                        <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 05c53c57-2e26-46a2-a157-4e230d860992" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value 4847c833-d7af-4fb0-b9df-346aab7a2a87" level={11} cateid={139}>STT</div>
                                            </div>
                                        </td>
                                        <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 3075f97b-e808-446d-876f-20726601c73f" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value a4f4771b-15cf-43e7-b097-515accbd067d" level={11} cateid={139}>Mã sản phẩm</div>
                                            </div>
                                        </td>
                                        <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 40083d3d-40ff-469b-9bcd-bdedc106f73a" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value da77403d-1020-4cde-ad85-26bd2227ec89" level={11} cateid={139}>Tên sản phẩm</div>
                                            </div>
                                        </td>
                                        <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row 2cf0f346-5df2-4f01-b6b2-f590a3b39578" level={10} cateid={140}>
                                                <div className="w-text wbaseItem-value c4f87ca8-722b-402a-818a-f0ce5ba15b2b" level={11} cateid={139}>Chọn</div>
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        listProduct.map((item, index) => {
                                            return (<tr className="table-row">
                                                <td id="2x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row d8da84bc-fed2-40c9-9e83-a39395d69054" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value e983534b-d6ae-4cb9-b002-859a13a36c8d" level={11} cateid={139}>{pageDetails.size * (pageDetails.current - 1) + index + 1}</div>
                                                    </div>
                                                </td>
                                                <td id="2x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 94f11329-391b-4246-989b-9569c50458ad" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value cfc8fff8-ed9d-4e21-acbb-b1f4a3f2a9c1" level={11} cateid={139}>{item.code}</div>
                                                    </div>
                                                </td>
                                                <td id="2x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 1422e989-d889-4971-8e01-037e96cdca01" level={10} cateid={140}>
                                                        <div className="w-text wbaseItem-value 3ee92184-53a9-45b5-8ddb-eabeb8dda7a3" level={11} cateid={139}>{item.name}</div>
                                                    </div>
                                                </td>
                                                <td id="2x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row f133066c-2090-49a5-8203-593c1801a71f" level={10} cateid={140}>
                                                        <label
                                                            onClick={() => OnchangeCheckedgroup(index)}
                                                            className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                            <input type="checkbox" disabled checked={item.check ? true : false} name="Pick" />
                                                            <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>)
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination
                        currentPage={pageDetails.current}
                        itemPerPage={pageDetails.size}
                        totalItem={total}
                        onChangePage={(page, size) => {
                            if (pageDetails.current !== page || pageDetails.size !== size) {
                                setPageDetails({ current: page, size: size });
                                _InitData(page, size);
                            }
                        }}
                    />
                </div>

                <div className='popup-footer row'>
                    <button onClick={onClosePop} className='close-popup'>Hủy</button>
                    <button
                        onClick={handleTransfer}
                        className='action-confirm'>Thêm</button>
                </div>
            </div>
        </div >, document.body
    );
}


export function PopupSelectloan({ code, callback_action, onClose }) {
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({});
    const [data, setData] = useState([]);

    // const [listProduct, setListProduct] = useState([]);
    const [total, setTotal] = useState([]);
    const [productCateList, setproductCateList] = useState([]);
    const [productList, setproductList] = useState([]);
    const [pageDetails, setPageDetails] = useState({
        current: 1,
        size: 10
    });
    const [listCategoryCode, setlistCategoryCode] = useState([]);
    const [listproductCode, setlistproductCode] = useState([]);
    const _InitData = async (page, pageSize, obj) => {
        var obj = { "effectiveDate": obj?.effectiveDate ?? "", "loanProgramCode": obj?.loanProgramCode ?? "", "loanProgramName": obj?.loanProgramName ?? "", "expirationDate": obj?.expirationDate ?? "", productCode: obj?.productCode ?? "", productCategoryCode: obj?.productCategoryCode ?? "", "status": obj?.status };
        const response = await loanprogramDA.getListLoan(page, pageSize, obj);
        if (response != null) {
            setData((data) =>
                response.pageData.map((item) =>
                    code?.some((a) => a.data?.id === item.data?.id) ? { ...item, check: true } : item
                ));
            // setData(response.pageData);
            setTotal(response.total)
        }
    }


    const getAllproduct = async () => {
        const response = await getbproductList(1, 100,);
        if (response != null) {
            setproductList(response.content);
        }
    }
    const getAllproductCate = async () => {
        const response = await getProductCate(1, 100,);
        if (response != null) {
            setproductCateList(response.content);
        }
    }
    const OnchangeCheckedgroup = (index) => {
        setData((data) =>
            data.map((item) =>
                item.data?.id === data[index].data?.id ? { ...item, check: !data[index].check } : item
            ));
    }
    const handleTransfer = async () => {

        const lst = data.filter((a) => a.check == true);
        debugger
        callback_action(lst);
    }
    const onClosePop = async () => {
        onClose();
    }

    const onSubmitFormPop = (event) => {
        _InitData(pageDetails.current, pageDetails.size, event);
    }
    const ressetForm = () => {
        setValue('effectiveDate', null);
        setValue('expirationDate', null);
        setValue('loanProgramCode', null);
        setValue('loanProgramName', null);
        setValue('productCategoryCode', null);
        setValue('productCode', null);
        setValue('status', null);
    }
    useEffect(() => {
        getAllproductCate()
        getAllproduct()
        _InitData(pageDetails.current, pageDetails.size);
    }, [])

    return ReactDOM.createPortal(
        <div className='popup-overlay'>
            <div className='popup-container w1500'>
                <div className='popup-header row'>
                    <div className='popup-name space'>Thêm mới Chương trình vay</div>
                    <div className='close-popup'>
                        {/* <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                        </svg> */}
                    </div>
                </div>
                <div className='popup-body w-frame wbaseItem-value w-col' scroll="true">
                    <form autoComplete='off' className="w-frame w-form wbaseItem-value w-col e3d1eec9-af12-4f81-98de-7b1013b2c45a" level={5} cateid={128}>
                        <div className="w-frame wbaseItem-value w-row b2ec1ec5-af32-4c54-923e-1bdda7a0008e" wrap="wrap" level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 2429e32c-b23c-4fb4-86dd-5e2102fddad3" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value 4ee89f5f-68f0-468e-843e-09d9a96bfa8b" level={8} cateid={139}>Mã Chương trình</div>
                                <div className="w-textformfield wbaseItem-value w-row dc1a2708-e1c6-4d7e-b90e-d2a8625a0bb5" name-field="RoleID" level={8} cateid={85} placeholder="Nhập mã Chương trình">
                                    <div className="wbaseItem-value 6769e680-9a6d-4aa6-8057-e127a10c8cfd" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input id="textfield-6769e680-9a6d-4aa6-8057-e127a10c8cfd" {...register('loanProgramCode')} name="loanProgramCode" placeholder="Nhập mã Chương trình" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 6156f408-3027-4da4-8839-a18ba6bc5467" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value e5b046d1-dcc2-4846-8cec-196e33e62f8c" level={8} cateid={139}>Tên Chương trình</div>
                                <div className="w-textformfield wbaseItem-value w-row 71eadcce-cb12-4862-b797-a38ea6beb51d" name-field="RoleName" level={8} cateid={85} placeholder="Nhập tên Chương trình">
                                    <div className="wbaseItem-value 8a2cb047-aaef-4cf4-aa60-7e239596bd52" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input id="textfield-8a2cb047-aaef-4cf4-aa60-7e239596bd52"  {...register('loanProgramName')} name="loanProgramName" placeholder="Nhập tên Chương trình" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 77769d60-55f3-4066-b5aa-eae222a0b63d" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value 912e095d-5aa5-4cd7-b16b-6f244f51aac0" level={8} cateid={139}>Danh mục</div>
                                <Controller
                                    name="listCategoryCode"
                                    control={control}

                                    {...register("listCategoryCode")}
                                    style={{ order: 2 }}
                                    render={({ field }) => (
                                        <Select2 {...field}
                                            options={{ placeholder: 'Chọn danh mục' }}
                                            value={listCategoryCode}
                                            multiple={true}
                                            data={productCateList.map((item, _) => { return { id: item.code, name: item.name } })}
                                            onChange={(event) => {
                                                if (event.target.value) {
                                                    const selectedValues = Array.from(event.target.options)
                                                        .filter(option => option.selected)
                                                        .map(option => option.value);
                                                    setlistCategoryCode(selectedValues);
                                                } else {
                                                    setlistCategoryCode([]);
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 77769d60-55f3-4066-b5aa-eae222a0b63d" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value 912e095d-5aa5-4cd7-b16b-6f244f51aac0" level={8} cateid={139}>Sản phẩm</div>

                                <Controller
                                    name="listproductCode"
                                    control={control}
                                    {...register("listproductCode")}
                                    style={{ order: 2 }}
                                    render={({ field }) => (
                                        <Select2 {...field}
                                            options={{ placeholder: 'Chọn sản phẩm' }}
                                            value={listproductCode}
                                            multiple={true}
                                            data={productList.map((item, _) => { return { id: item.code, name: item.name } })}
                                            onChange={(event) => {
                                                if (event.target.value) {
                                                    const selectedValues = Array.from(event.target.options)
                                                        .filter(option => option.selected)
                                                        .map(option => option.value);
                                                    setproductCateList(selectedValues);
                                                } else {
                                                    setproductCateList([]);
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            {/* <div className="w-frame wbaseItem-value w-col" level={7} cateid={140} id="da4cf8c3-311f-4580-8b87-6709f8160053"> */}

                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 77769d60-55f3-4066-b5aa-eae222a0b63d" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value 912e095d-5aa5-4cd7-b16b-6f244f51aac0" level={8} cateid={139}>Trạng thái</div>
                                <Controller
                                    name="status"
                                    control={control}
                                    {...register("status")}
                                    style={{ order: 2 }}
                                    render={({ field }) => (
                                        <Select2 {...field} isFirst={true} nameFirst={'Chọn trạng thái'}
                                            data={[{ id: 1, name: 'Hoạt động' }, { id: 0, name: 'Không hoạt động' }]}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 60eb7691-2d67-402e-a13f-6aba8d5dfe3c" level={6} cateid={140}>
                            <button type="button" onClick={() => ressetForm()} className="w-button wbaseItem-value w-row 8c46835a-80fa-488b-979e-419f7ac8efec" level={7} cateid={29}>
                                <div className="w-svg wbaseItem-value cef48042-9809-4985-bddf-aa647f81640e" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.0313 2.00835C21.5288 2.07581 21.8774 2.53382 21.81 3.03135L21.0827 8.39498C21.0153 8.89173 20.5586 9.2402 20.0617 9.17395L14.6072 8.44668C14.1095 8.38032 13.7598 7.92309 13.8262 7.42541C13.8926 6.92774 14.3498 6.57809 14.8475 6.64445L18.3484 7.11124C16.8702 5.25663 14.5726 4.09064 12 4.09064C7.50208 4.09064 3.81818 7.77454 3.81818 12.2725C3.81818 12.7745 3.41117 13.1816 2.90909 13.1816C2.40701 13.1816 2 12.7745 2 12.2725C2 6.77038 6.49792 2.27246 12 2.27246C15.0381 2.27246 17.7813 3.61127 19.6033 5.77412L20.0083 2.78705C20.0757 2.28953 20.5338 1.94089 21.0313 2.00835Z" fill="#28282999" />
                                        <path d="M22 12.2733C22 11.7713 21.5929 11.3643 21.0909 11.3643C20.5888 11.3643 20.1818 11.7713 20.1818 12.2733C20.1818 16.7713 16.4979 20.4552 12 20.4552C9.42714 20.4552 7.12925 19.2889 5.6511 17.434L9.15251 17.9009C9.65018 17.9672 10.1074 17.6176 10.1738 17.1199C10.2401 16.6222 9.89048 16.165 9.39281 16.0986L3.93826 15.3714C3.44137 15.3051 2.98462 15.6536 2.91726 16.1503L2.18999 21.514C2.12253 22.0115 2.47117 22.4695 2.96869 22.537C3.46621 22.6044 3.92423 22.2558 3.99169 21.7583L4.39665 18.7716C6.21865 20.9345 8.96182 22.2733 12 22.2733C17.502 22.2733 22 17.7754 22 12.2733Z" fill="#28282999" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value fb0f0aba-9b42-4b9d-ba05-a185d998a8f7" level={8} cateid={139}>Xóa bộ lọc</div>
                            </button>
                            <button type="button" onClick={handleSubmit(onSubmitFormPop)} className="w-button wbaseItem-value w-row 38b377c7-da56-44ff-93b4-d77b917d096f" level={7} cateid={29}>
                                <div className="w-svg wbaseItem-value 8e116fb4-b8c7-4c9d-ad7a-344a0edece09" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#FFFFFFFF" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value 14b619ba-00f8-4772-b32c-bc35ccb1174d" level={8} cateid={139}>Tìm kiếm</div>
                            </button>
                        </div>
                    </form>
                    <div className="w-frame wbaseItem-value w-col e8de9f90-0118-414b-bc69-da273a578779" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 6ef2f09f-bb09-4a7f-bca4-ba5fe9a4682c" level={6} cateid={140}>
                            <div className="w-text wbaseItem-value af9be095-13e9-4645-8637-973ae192c9f6" level={7} cateid={139}>
                                Danh sách Chương trình vay</div>
                        </div>
                        <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                            <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>
                                <table className="w-table wbaseItem-value dbe0f04f-f73f-41cf-a94e-988260f1acdf" type={0} level={6} cateid={117}>
                                    <tbody>
                                        <tr className="table-row">
                                            <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row e90e5935-90a6-40b5-a7d4-76d681ac32d2" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 2485e51c-ff7e-44a9-a5d4-466e898c9988" level={8} cateid={139}>STT</div>
                                                </div>
                                            </td>
                                            <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row e064df7b-f2e0-49ef-a2fe-c4bc517fd403" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value c773fe31-0b32-4424-afa2-d9e81b4b309f" level={8} cateid={139}>Mã chương trình</div>
                                                </div>
                                            </td>
                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Tên chương trình</div>
                                                </div>
                                            </td>
                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Danh mục</div>
                                                </div>
                                            </td>
                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Sản phẩm</div>
                                                </div>
                                            </td>
                                            {/* <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Ngày hiệu lực</div>
                                        </div>
                                    </td>
                                    <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                        <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                            <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Ngày hết hiệu lực</div>
                                        </div>
                                    </td> */}
                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Trạng thái</div>
                                                </div>
                                            </td>
                                            <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row d7bfebbd-090e-43bc-a49c-d11c6432a6c7" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value f491a7b4-eafa-4610-bee4-d82b5c97ff3e" level={8} cateid={139}>Hành động</div>
                                                    <div className="w-svg wbaseItem-value 7b48f46e-a820-427e-9bd2-e8cadc861ff3" level={8} cateid={115}>
                                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.48 2.2162C12.4217 2.1486 12.3484 2.09415 12.2654 2.05677C12.1824 2.01939 12.0918 2 12 2C11.9082 2 11.8176 2.01939 11.7346 2.05677C11.6516 2.09415 11.5783 2.1486 11.52 2.2162L6.12 9.08426C6.05314 9.16929 6.01243 9.2704 6.00242 9.37626C5.99242 9.48212 6.01351 9.58855 6.06334 9.68362C6.11318 9.77869 6.18978 9.85864 6.28456 9.91452C6.37935 9.9704 6.48857 10 6.6 10H17.4C17.5114 10 17.6207 9.9704 17.7154 9.91452C17.8102 9.85864 17.8868 9.77869 17.9367 9.68362C17.9865 9.58855 18.0076 9.48212 17.9976 9.37626C17.9876 9.2704 17.9469 9.16929 17.88 9.08426L12.48 2.2162Z" fill="#28282999" />
                                                            <path d="M11.52 21.7838C11.5783 21.8514 11.6516 21.9059 11.7346 21.9432C11.8176 21.9806 11.9082 22 12 22C12.0918 22 12.1824 21.9806 12.2654 21.9432C12.3484 21.9059 12.4217 21.8514 12.48 21.7838L17.88 14.9157C17.9469 14.8307 17.9876 14.7296 17.9976 14.6237C18.0076 14.5179 17.9865 14.4114 17.9367 14.3164C17.8868 14.2213 17.8102 14.1414 17.7154 14.0855C17.6207 14.0296 17.5114 14 17.4 14H6.6C6.48857 14 6.37935 14.0296 6.28456 14.0855C6.18978 14.1414 6.11318 14.2213 6.06334 14.3164C6.01351 14.4114 5.99242 14.5179 6.00242 14.6237C6.01243 14.7296 6.05314 14.8307 6.12 14.9157L11.52 21.7838Z" fill="#28282999" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        {
                                            data.map((item, index) => {
                                                return (
                                                    <tr key={item.data?.id} className="table-row">
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row acbebf44-6c58-482d-86c8-31f3d1649f04" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 51d88187-e142-427e-9963-328e66e429f6" level={8} cateid={139}>{pageDetails.size * (pageDetails.current - 1) + index + 1}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.data?.loanProgramCode}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row fbf833df-8732-40b3-8c84-6647ac56d57a" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value fd4011f7-127d-4174-8747-026e1bafb83d" level={8} cateid={139} >{item.data?.loanProgramName}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.data?.productCategory}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.data?.product}</div>
                                                            </div>
                                                        </td>
                                                        {/* <td className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.data?.effectiveDate}</div>
                                                    </div>
                                                </td>
                                                <td className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.data?.expirationDate}</div>
                                                    </div>
                                                </td> */}
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                {/* <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.data?.status}</div> */}
                                                                <button type="button" className="w-button wbaseItem-value w-row" level={2} cateid={29} id="eadd2c5d-e811-4f3e-9934-2dacf0e9402a">
                                                                    <div className="w-text wbaseItem-value" level={3} cateid={139} id="38662a33-df93-442d-830c-0d71ce5e816f">{item.data?.status === 1 ? 'Hoạt động' : 'Không hoạt động'}</div>
                                                                </button>
                                                            </div>
                                                        </td>

                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row f133066c-2090-49a5-8203-593c1801a71f" level={10} cateid={140}>
                                                                <label
                                                                    onClick={() => OnchangeCheckedgroup(index)}
                                                                    className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                                    <input type="checkbox" disabled checked={item.check ? true : false} name="Pick" />
                                                                    <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                                </label>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <Pagination
                            currentPage={pageDetails.current}
                            itemPerPage={pageDetails.size}
                            totalItem={total}
                            hiddenPageSize={true}
                            onChangePage={(page, size) => {
                                if (pageDetails.current !== page || pageDetails.size !== size) {
                                    setPageDetails({ current: page, size: size });
                                    _InitData(page, size);
                                }
                            }}
                        />
                    </div>
                </div>

                <div className='popup-footer row'>
                    <button onClick={onClosePop} className='close-popup'>Hủy</button>
                    <button
                        onClick={handleTransfer}
                        className='action-confirm'>Thêm</button>
                </div>
            </div>
        </div >, document.body
    );
}


export function PopupSelectUser({ code, callback_action, onClose, notificationAlert, isCocau, lstorgId }) {
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({});
    const [data, setData] = useState([]);

    // const [listProduct, setListProduct] = useState([]);
    const [total, setTotal] = useState([]);
    const [query, setQuery] = useState("");
    const [pageDetails, setPageDetails] = useState({
        current: 1,
        size: 10
    });
    const _InitData = async (page, pageSize, obj) => {
        const response = await getListAllUser(page, pageSize);
        if (response != null) {
            let filterStaff = response.content;
            if (isCocau === false) {
                if (lstorgId?.length > 0) {
                    filterStaff = filterStaff?.filter(item => lstorgId.some((a) => a === item.orgInfo.id))
                }
            }
            setData(filterStaff.map((item) =>
                code?.some((a) => a.id === item.id) ? { ...item, check: true } : item
            ));
            setTotal(filterStaff.length)
        }
    }



    const OnchangeCheckedgroup = (index) => {
        setData((data) =>
            data.map((item) =>
                item.id === data[index].id ? { ...item, check: !data[index].check } : item
            ));
    }
    const OnchangeCheckedAll = (ev) => {
        setData((data) =>
            data.map((item) => { return { ...item, check: ev.target.checked } }
            ));
    }
    const handleTransfer = async () => {
        const lst = data.filter((a) => a.check === true);
        callback_action(lst);
    }
    const onClosePop = async () => {
        onClose();
    }

    const onSubmitFormPop = (event) => {
        debugger;
        var query = "";
        if (event.fullName) {
            if (query !== "") {
                query += '&fullName=' + event.fullName
            } else {
                query += '?fullName=' + event.fullName
            }
        }
        if (event.username) {
            if (query !== "") {
                query += '&username=' + event.username
            } else {
                query += '?username=' + event.username
            }
        }
        if (event.status) {
            if (query !== "") {
                query += '&status=' + event.status
            } else {
                query += '?status=' + event.status
            }
        }
        if (event.staffCode) {
            if (query !== "") {
                query += '&staffCode=' + event.staffCode
            } else {
                query += '?staffCode=' + event.staffCode
            }
        }
        if (event.fromDate) {
            if (query !== "") {
                query += '&fromDate=' + event.fromDate
            } else {
                query += '?fromDate=' + event.fromDate
            }
        }
        if (event.toDate) {
            if (query !== "") {
                query += '&toDate=' + event.toDate
            } else {
                query += '?toDate=' + event.toDate
            }
        }
        if (event.fromDate && event.toDate) {
            const from = parse(event.fromDate, 'dd/MM/yyyy', new Date());
            const to = parse(event.toDate, 'dd/MM/yyyy', new Date());
            if (to < from) {
                notify(notificationAlert, ERROR, 'Ngày bắt đầu phải nhỏ hơn ngày kết thúc')
                return;
            }
        }
        setQuery(query)
        _InitData(pageDetails.current, pageDetails.size, query);
    }
    const ressetForm = () => {
        setValue('fromDate', null);
        setValue('toDate', null);
        setValue('staffCode', null);
        setValue('fullName', null);
        setValue('username', null);
        setValue('status', null);
    }

    useEffect(() => {
        _InitData(pageDetails.current, pageDetails.size, "");
    }, [])

    return ReactDOM.createPortal(
        <div className='popup-overlay'>

            <div className='popup-container w1500'>
                <NotificationAlert ref={notificationAlert} />
                <div className='popup-header row'>
                    <div className='popup-name space'>Thêm nhân viên vào tổ chức</div>
                    <div className='close-popup'>
                        {/* <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                        </svg> */}
                    </div>
                </div>
                <div className='popup-body w-frame wbaseItem-value w-col' scroll="true">
                    <form autoComplete='off' className="w-frame w-form wbaseItem-value w-col b721ebd4-7990-4086-98b3-6ec8f5aa41bc"
                        level={5} cateid={128}>
                        <div className="w-frame wbaseItem-value w-row e4175e48-4d2e-49a7-b4f4-927e559458a1" wrap="wrap"
                            level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 8739a82e-730d-4399-9c99-77799fdfd4a1"
                                level={7} cateid={140}>
                                <div className="w-text wbaseItem-value c34e4304-2103-4001-843c-5d9b8e57f72d" level={8}
                                    cateid={139}>Tên nhân viên</div>
                                <div className="w-textformfield wbaseItem-value w-row f439fd9e-f87f-4c68-ab98-dff2e0c2c44c"
                                    name-field="RoleID" level={8} cateid={85}
                                    placeholder="Nhập họ và tên nhân viên">
                                    <div className="wbaseItem-value 215d6150-22b8-4005-b3d8-049f8762b450"
                                        level={9} cateid={86}>
                                        <div className="textfield">
                                            <input id="textfield-215d6150-22b8-4005-b3d8-049f8762b450" maxLength={30} name="fullName" {...register('fullName')}
                                                placeholder="Nhập họ và tên nhân viên" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm f1fa51fe-e167-46e0-9f04-897733680195"
                                level={7} cateid={140}>
                                <div className="w-text wbaseItem-value c3a67008-efbc-42fc-98d3-9787640e278f" level={8}
                                    cateid={139}>Mã nhân viên</div>
                                <div className="w-textformfield wbaseItem-value w-row ac565359-b7cc-4a6b-b21a-bccd0bd0b1f0"
                                    name-field="EmployeeID" level={8} cateid={85}
                                    placeholder="Nhập mã nhân viên">
                                    <div className="wbaseItem-value 23336832-81df-4b13-ae98-ac81100c8775"
                                        level={9} cateid={86}>
                                        <div className="textfield">
                                            <input name="staffCode" {...register('staffCode')}
                                                placeholder="Nhập mã nhân viên" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm a8a861c9-968b-4406-96a5-1845b356a424"
                                level={7} cateid={140}>
                                <div className="w-text wbaseItem-value a8afe34e-e3d9-4209-8b04-da2f0f8c7acd" level={8}
                                    cateid={139}>Tên đăng nhập</div>
                                <div className="w-textformfield wbaseItem-value w-row 0823d802-baa5-4f32-a0f6-6a0568e18f20" level={8} cateid={85} >
                                    <div className="wbaseItem-value 87992e90-5f00-497e-97fd-a7ccdd901c24" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input name="username" {...register('username')} maxLength={30} placeholder="Tên đăng nhập của nhân viên" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col" level={7} cateid={140} id="da4cf8c3-311f-4580-8b87-6709f8160053">
                                <div className="w-text wbaseItem-value" level={8} cateid={139} id="ad483c96-1a4e-4eda-ac8d-9360ed0e6722">Ngày tạo</div>
                                <div className="w-frame wbaseItem-value w-row" level={8} cateid={140} id="676792c9-5f4a-45bd-88ce-db3e2dd536a0">
                                    <div className="w-textformfield wbaseItem-value w-row" level={9} cateid={85} id="54e5d651-53d5-43d6-b61d-ffa9b01c9bd3" placeholder="Từ ngày">
                                        <div className="wbaseItem-value d37be69e-c37b-4962-9031-b8ea9938843d" level={10} cateid={86} type="date">
                                            <div className="textfield"><input name="fromDate" placeholder="Từ ngày" {...register('fromDate')} /></div>
                                            <div className="suffix-btn-txtfd calendar-icon"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                                <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div className="w-text wbaseItem-value" level={9} cateid={139} id="ebfc245d-bfc0-4f47-9b0c-d7fd80a8e9b6">-</div>
                                    <div className="w-textformfield wbaseItem-value w-row" level={9} cateid={85} id="e6b07ff0-db76-400a-835f-1c9671ea0e1c" placeholder="Đến ngày">
                                        <div className="wbaseItem-value d37be69e-c37b-4962-9031-b8ea9938843d" level={10} cateid={86} type="date" >
                                            <div className="textfield"><input name="toDate" placeholder="Đến ngày" {...register('toDate')} /></div>
                                            <div className="suffix-btn-txtfd calendar-icon"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="95%" viewBox="0 0 17 16" fill="none">
                                                <path d="M12.3876 2.99967V1.88856C12.3876 1.74122 12.3291 1.59991 12.2249 1.49573C12.1207 1.39154 11.9794 1.33301 11.832 1.33301C11.6847 1.33301 11.5434 1.39154 11.4392 1.49573C11.335 1.59991 11.2765 1.74122 11.2765 1.88856V2.99967H12.3876Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M5.72092 2.99967V1.88856C5.72092 1.74122 5.66239 1.59991 5.5582 1.49573C5.45401 1.39154 5.31271 1.33301 5.16536 1.33301C5.01802 1.33301 4.87671 1.39154 4.77253 1.49573C4.66834 1.59991 4.60981 1.74122 4.60981 1.88856V2.99967H5.72092Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M13.4987 14.1108H3.4987C3.05667 14.1108 2.63275 13.9352 2.32019 13.6226C2.00763 13.3101 1.83203 12.8861 1.83203 12.4441V5.2219C1.83203 4.77987 2.00763 4.35595 2.32019 4.04339C2.63275 3.73082 3.05667 3.55523 3.4987 3.55523H13.4987C13.9407 3.55523 14.3646 3.73082 14.6772 4.04339C14.9898 4.35595 15.1654 4.77987 15.1654 5.2219V12.4441C15.1654 12.8861 14.9898 13.3101 14.6772 13.6226C14.3646 13.9352 13.9407 14.1108 13.4987 14.1108ZM14.0543 6.33301H2.94314V12.4441C2.94314 12.5915 3.00167 12.7328 3.10586 12.837C3.21005 12.9411 3.35136 12.9997 3.4987 12.9997H13.4987C13.646 12.9997 13.7873 12.9411 13.8915 12.837C13.9957 12.7328 14.0543 12.5915 14.0543 12.4441V6.33301Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M6.27648 7.44412H4.05425V9.11079H6.27648V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M9.60981 7.44412H7.38759V9.11079H9.60981V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M6.27648 10.2219H4.05425V11.8886H6.27648V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M9.60981 10.2219H7.38759V11.8886H9.60981V10.2219Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M12.9431 7.44412H10.7209V9.11079H12.9431V7.44412Z" fill="#282829" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                            </svg></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 42e98ce9-bc6b-4d6f-bc47-7a87b70db228"
                                level={7} cateid={140}>
                                <div className="w-text wbaseItem-value e7c160e0-c242-457f-bf73-809fbf2a2dd3" level={8}
                                    cateid={139}>Trạng thái</div>
                                {/* <div className="w-textformfield wbaseItem-value w-row d0cc2c8d-bd85-49a1-89ed-cfb86a1dbfae"
                            name-field="status" level={8} cateid={85}
                            placeholder="Chọn trạng thái">
                            <div className="wbaseItem-value 5f7ccfe7-8fbe-4ba5-ac6e-34332106949f"
                                level={9} cateid={86}>
                                <div className="textfield">
                                    <input id="textfield-5f7ccfe7-8fbe-4ba5-ac6e-34332106949f" name="status"
                                        placeholder="Chọn trạng thái" />
                                </div>
                            </div>
                            <div className="w-svg wbaseItem-value 12031c22-6136-406b-9e34-d1f09e73aa63"
                                level={9} cateid={115}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.41046 8.01097C5.03087 7.66301 4.42368 7.66301 4.04408 8.01097C3.65197 8.37041 3.65197 8.96293 4.04408 9.32236L11.3168 15.989C11.6964 16.337 12.3036 16.337 12.6832 15.989L19.9559 9.32236C20.348 8.96293 20.348 8.37041 19.9559 8.01097C19.5763 7.66301 18.9691 7.66301 18.5895 8.01097L12 14.0514L5.41046 8.01097Z"
                                        fill="#28282999" />
                                </svg>
                            </div>
                        </div> */}
                                {/* <Select2
                                    style={{ order: 2 }}
                                    // isFirst={true}
                                    {...register('status')}
                                    // nameFirst={'Chọn trạng thái'}
                                    data={[{ id: 1, name: 'Active' }, { id: 0, name: 'Block' }]}
                                    // value={new URLSearchParams(location.search).get('status')}
                                    name='status'
                                    options={{ placeholder: 'Chọn trạng thái' }}
                                // onChange={dealerIdChange}
                                /> */}
                                <Controller
                                    name="status"
                                    {...register("status")}
                                    control={control}
                                    style={{ order: 2 }}
                                    // rules={{ required: true }}
                                    // helper-text={errors.orgId && 'Vui lòng chọn tổ chức'}
                                    render={({ field }) => (
                                        <Select2 {...field} options={{ placeholder: 'Chọn trạng thái' }} data={[{ id: 1, name: 'Active' }, { id: 2, name: 'Block' },]} />
                                    )}
                                />
                            </div>

                        </div>
                        <div className="w-frame wbaseItem-value w-row b45d26d7-cc6f-4d0d-b743-ff5d3951a493"
                            level={6} cateid={140}>
                            <button onClick={ressetForm} type="button"
                                className="w-button wbaseItem-value w-row 01df81f9-ab23-4de9-a2b7-ddd1c163c199"
                                level={7} cateid={29}>
                                <div className="w-svg wbaseItem-value 2e7370de-b936-4b12-9d10-1a6280b9d506" level={8}
                                    cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M21.0313 2.00835C21.5288 2.07581 21.8774 2.53382 21.81 3.03135L21.0827 8.39498C21.0153 8.89173 20.5586 9.2402 20.0617 9.17395L14.6072 8.44668C14.1095 8.38032 13.7598 7.92309 13.8262 7.42541C13.8926 6.92774 14.3498 6.57809 14.8475 6.64445L18.3484 7.11124C16.8702 5.25663 14.5726 4.09064 12 4.09064C7.50208 4.09064 3.81818 7.77454 3.81818 12.2725C3.81818 12.7745 3.41117 13.1816 2.90909 13.1816C2.40701 13.1816 2 12.7745 2 12.2725C2 6.77038 6.49792 2.27246 12 2.27246C15.0381 2.27246 17.7813 3.61127 19.6033 5.77412L20.0083 2.78705C20.0757 2.28953 20.5338 1.94089 21.0313 2.00835Z"
                                            fill="#28282999" />
                                        <path
                                            d="M22 12.2733C22 11.7713 21.5929 11.3643 21.0909 11.3643C20.5888 11.3643 20.1818 11.7713 20.1818 12.2733C20.1818 16.7713 16.4979 20.4552 12 20.4552C9.42714 20.4552 7.12925 19.2889 5.6511 17.434L9.15251 17.9009C9.65018 17.9672 10.1074 17.6176 10.1738 17.1199C10.2401 16.6222 9.89048 16.165 9.39281 16.0986L3.93826 15.3714C3.44137 15.3051 2.98462 15.6536 2.91726 16.1503L2.18999 21.514C2.12253 22.0115 2.47117 22.4695 2.96869 22.537C3.46621 22.6044 3.92423 22.2558 3.99169 21.7583L4.39665 18.7716C6.21865 20.9345 8.96182 22.2733 12 22.2733C17.502 22.2733 22 17.7754 22 12.2733Z"
                                            fill="#28282999" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value f0397efc-4cc1-4e36-8315-d0676e7fdf1a" level={8} cateid={139}>Xóa bộ lọc</div>
                            </button>
                            <button type="button" onClick={handleSubmit(onSubmitFormPop)}
                                className="w-button wbaseItem-value w-row 6b399b04-820f-4233-98be-e7cb8f6951de"
                                level={7} cateid={29}>
                                <div className="w-svg wbaseItem-value e78d1a07-f59d-4c7d-addb-a67b40192d7c" level={8}
                                    cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#FFFFFFFF" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value 131937ed-ca0a-4c01-8d22-9f6e2da034f2" level={8} cateid={139}>Tìm kiếm</div>
                            </button>
                        </div>
                    </form>
                    <div className="w-frame wbaseItem-value w-col e8de9f90-0118-414b-bc69-da273a578779" level={5} cateid={140}>
                        <div className="w-frame wbaseItem-value w-row 6ef2f09f-bb09-4a7f-bca4-ba5fe9a4682c" level={6} cateid={140}>
                            <div className="w-text wbaseItem-value af9be095-13e9-4645-8637-973ae192c9f6" level={7} cateid={139}>
                                Kết quả tìm kiếm</div>
                        </div>
                        <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                            <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>
                                <table className="w-table wbaseItem-value dbe0f04f-f73f-41cf-a94e-988260f1acdf" type={0} level={6} cateid={117}>
                                    <tbody>
                                        <tr className="table-row">
                                            <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row e90e5935-90a6-40b5-a7d4-76d681ac32d2" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 2485e51c-ff7e-44a9-a5d4-466e898c9988" level={8} cateid={139}>STT</div>
                                                </div>
                                            </td>
                                            <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row e064df7b-f2e0-49ef-a2fe-c4bc517fd403" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value c773fe31-0b32-4424-afa2-d9e81b4b309f" level={8} cateid={139}>Mã nhân viên</div>
                                                </div>
                                            </td>
                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Tên nhân viên</div>
                                                </div>
                                            </td>
                                            <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Tên đăng nhập</div>
                                                </div>
                                            </td>
                                            <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Chức danh</div>
                                                </div>
                                            </td>
                                            <td id="1x6" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Khu vực</div>
                                                </div>
                                            </td>
                                            <td id="1x7" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Trạng thái</div>
                                                </div>
                                            </td>
                                            <td id="1x8" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row d7bfebbd-090e-43bc-a49c-d11c6432a6c7" level={7} cateid={140}>
                                                    <label className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                        <input type="checkbox" onChange={OnchangeCheckedAll} name="PickAll" checked={data.every(item => item.check)} />
                                                        <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        {
                                            data.map((item, index) => {
                                                return (
                                                    <tr key={item.data?.id} className="table-row">
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row acbebf44-6c58-482d-86c8-31f3d1649f04" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 51d88187-e142-427e-9963-328e66e429f6" level={8} cateid={139}>{pageDetails.size * (pageDetails.current - 1) + index + 1}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.staffCode}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row fbf833df-8732-40b3-8c84-6647ac56d57a" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value fd4011f7-127d-4174-8747-026e1bafb83d" level={8} cateid={139} >{item.fullName}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.username}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.orgTitle?.name}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.areaInfo?.name}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                <button type="button" className="w-button wbaseItem-value w-row" level={2} cateid={29} id="eadd2c5d-e811-4f3e-9934-2dacf0e9402a">
                                                                    <div className="w-text wbaseItem-value" level={3} cateid={139} id="38662a33-df93-442d-830c-0d71ce5e816f">{item.status === 1 ? 'Hoạt động' : 'Không hoạt động'}</div>
                                                                </button>
                                                            </div>
                                                        </td>

                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row f133066c-2090-49a5-8203-593c1801a71f" level={10} cateid={140}>
                                                                <label className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                                    <input type="checkbox" onChange={() => OnchangeCheckedgroup(index)} checked={item.check} name={`Pick-${index}`} />
                                                                    <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                                </label>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <Pagination
                            currentPage={pageDetails.current}
                            itemPerPage={pageDetails.size}
                            totalItem={total}
                            onChangePage={(page, size) => {
                                if (pageDetails.current !== page || pageDetails.size !== size) {
                                    setPageDetails({ current: page, size: size });
                                    _InitData(page, size, query);
                                }
                            }}
                        />
                    </div>
                </div>

                <div className='popup-footer row'>
                    <button onClick={onClosePop} className='close-popup'>Hủy</button>
                    <button
                        onClick={handleTransfer}
                        className='action-confirm'>Thêm</button>
                </div>
            </div>
        </div >, document.body
    );
}


export function PopupSelecDocument({ code, callback_action, onClose }) {
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({});
    const [data, setData] = useState([]);
    const [documentTypeList, setdocumentTypeList] = useState([]);

    const [total, setTotal] = useState([]);
    const [query, setQuery] = useState("");
    const [pageDetails, setPageDetails] = useState({
        current: 1,
        size: 10
    });
    const _InitData = async (page, pageSize) => {
        debugger
        const documentCode = new URLSearchParams(query).get('documentCode');
        const documentName = new URLSearchParams(query).get('documentName');
        const documentTypeCode = new URLSearchParams(query).get('documentTypeCode');
        var obj = { "documentCode": documentCode ?? "", "documentName": documentName ?? "", documentTypeCode: documentTypeCode ?? "" };
        const response = await getListdocuments(page, pageSize, obj);
        if (response != null) {
            setData(response.pageData.map((item) =>
                code?.some((a) => a.id === item.losDocumentDetail?.id) ? { ...item, check: true } : item
            ));
            // setData(response.pageData);
            setTotal(response.total)
        }
    }

    const getAlltype = async () => {
        const response = await gettypedocument(1, 100,);
        if (response != null) {
            setdocumentTypeList(response.pageData);
        }
    }
    const OnchangeCheckedgroup = (index) => {
        setData((data) =>
            data.map((item) =>
                item.losDocumentDetail?.id === data[index].losDocumentDetail?.id ? { ...item, check: !data[index].check } : item
            ));
    }
    const OnchangeCheckedAll = (ev) => {
        setData((data) =>
            data.map((item) => { return { ...item, check: ev.target.value } }
            ));
    }
    const handleTransfer = async () => {
        const lst = data.filter((a) => a.check === true);
        callback_action(lst);
    }
    const onClosePop = async () => {
        onClose();
    }

    const onSubmitFormPop = (event) => {
        var query = "";
        if (event.documentCode) {
            if (query !== "") {
                query += '&documentCode=' + event.documentCode
            } else {
                query += '?documentCode=' + event.documentCode
            }
        }
        if (event.documentName) {
            if (query !== "") {
                query += '&documentName=' + event.documentName
            } else {
                query += '?documentName=' + event.documentName
            }
        }

        if (event.documentTypeCode) {
            if (query !== "") {
                query += '&documentTypeCode=' + event.documentTypeCode
            } else {
                query += '?documentTypeCode=' + event.documentTypeCode
            }
        }
        setQuery(query)
        _InitData(pageDetails.current, pageDetails.size);
    }
    const ressetForm = () => {
        setValue('documentCode', null);
        setValue('documentName', null);
        setValue('documentTypeCode', null);
        // setValue('status', null);
    }
    useEffect(() => {
        getAlltype()
        _InitData(pageDetails.current, pageDetails.size);
    }, [])

    return ReactDOM.createPortal(
        <div className='popup-overlay'>
            <div className='popup-container w1500'>
                <div className='popup-header row'>
                    <div className='popup-name space'>Thêm chứng từ vào chương trình vay</div>
                    <div className='close-popup'>
                        {/* <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                        </svg> */}
                    </div>
                </div>
                <div className='popup-body w-frame wbaseItem-value w-col' scroll="true">
                    <form autoComplete='off' className="w-frame w-form wbaseItem-value w-col e3d1eec9-af12-4f81-98de-7b1013b2c45a" level={5} cateid={128} style={{ order: 0 }}>
                        <div className="w-frame wbaseItem-value w-row b2ec1ec5-af32-4c54-923e-1bdda7a0008e" wrap="wrap" level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 2429e32c-b23c-4fb4-86dd-5e2102fddad3" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value 4ee89f5f-68f0-468e-843e-09d9a96bfa8b" level={8} cateid={139}>Mã Chứng từ</div>
                                <div className="w-textformfield wbaseItem-value w-row dc1a2708-e1c6-4d7e-b90e-d2a8625a0bb5" name-field="RoleID" level={8} cateid={85} placeholder="Nhập mã Chứng từ">
                                    <div className="wbaseItem-value 6769e680-9a6d-4aa6-8057-e127a10c8cfd" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input id="textfield-6769e680-9a6d-4aa6-8057-e127a10c8cfd" {...register('documentCode')} name="documentCode" placeholder="Nhập mã Chứng từ" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 6156f408-3027-4da4-8839-a18ba6bc5467" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value e5b046d1-dcc2-4846-8cec-196e33e62f8c" level={8} cateid={139}>Tên Chứng từ</div>
                                <div className="w-textformfield wbaseItem-value w-row 71eadcce-cb12-4862-b797-a38ea6beb51d" name-field="RoleName" level={8} cateid={85} placeholder="Nhập tên Chứng từ">
                                    <div className="wbaseItem-value 8a2cb047-aaef-4cf4-aa60-7e239596bd52" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input id="textfield-8a2cb047-aaef-4cf4-aa60-7e239596bd52"  {...register('documentName')} name="documentName" placeholder="Nhập tên Chứng từ" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 77769d60-55f3-4066-b5aa-eae222a0b63d" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value 912e095d-5aa5-4cd7-b16b-6f244f51aac0" level={8} cateid={139}>Loại chứng từ</div>
                                <Controller
                                    name="documentTypeCode"
                                    control={control}
                                    {...register("documentTypeCode")}
                                    style={{ order: 2 }}
                                    render={({ field }) => (
                                        <Select2 {...field} isFirst={true} nameFirst={'Chọn Loại chứng từ'}
                                            data={documentTypeList.map((item, _) => { return { id: item.documentTypeCode, name: item.documentTypeName } })}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-row 60eb7691-2d67-402e-a13f-6aba8d5dfe3c" level={6} cateid={140}>
                            <button type="button" onClick={() => ressetForm()} className="w-button wbaseItem-value w-row 8c46835a-80fa-488b-979e-419f7ac8efec" level={7} cateid={29}>
                                <div className="w-svg wbaseItem-value cef48042-9809-4985-bddf-aa647f81640e" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.0313 2.00835C21.5288 2.07581 21.8774 2.53382 21.81 3.03135L21.0827 8.39498C21.0153 8.89173 20.5586 9.2402 20.0617 9.17395L14.6072 8.44668C14.1095 8.38032 13.7598 7.92309 13.8262 7.42541C13.8926 6.92774 14.3498 6.57809 14.8475 6.64445L18.3484 7.11124C16.8702 5.25663 14.5726 4.09064 12 4.09064C7.50208 4.09064 3.81818 7.77454 3.81818 12.2725C3.81818 12.7745 3.41117 13.1816 2.90909 13.1816C2.40701 13.1816 2 12.7745 2 12.2725C2 6.77038 6.49792 2.27246 12 2.27246C15.0381 2.27246 17.7813 3.61127 19.6033 5.77412L20.0083 2.78705C20.0757 2.28953 20.5338 1.94089 21.0313 2.00835Z" fill="#28282999" />
                                        <path d="M22 12.2733C22 11.7713 21.5929 11.3643 21.0909 11.3643C20.5888 11.3643 20.1818 11.7713 20.1818 12.2733C20.1818 16.7713 16.4979 20.4552 12 20.4552C9.42714 20.4552 7.12925 19.2889 5.6511 17.434L9.15251 17.9009C9.65018 17.9672 10.1074 17.6176 10.1738 17.1199C10.2401 16.6222 9.89048 16.165 9.39281 16.0986L3.93826 15.3714C3.44137 15.3051 2.98462 15.6536 2.91726 16.1503L2.18999 21.514C2.12253 22.0115 2.47117 22.4695 2.96869 22.537C3.46621 22.6044 3.92423 22.2558 3.99169 21.7583L4.39665 18.7716C6.21865 20.9345 8.96182 22.2733 12 22.2733C17.502 22.2733 22 17.7754 22 12.2733Z" fill="#28282999" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value fb0f0aba-9b42-4b9d-ba05-a185d998a8f7" level={8} cateid={139}>Xóa bộ lọc</div>
                            </button>
                            <button type="button" onClick={handleSubmit(onSubmitFormPop)} className="w-button wbaseItem-value w-row 38b377c7-da56-44ff-93b4-d77b917d096f" level={7} cateid={29}>
                                <div className="w-svg wbaseItem-value 8e116fb4-b8c7-4c9d-ad7a-344a0edece09" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#FFFFFFFF" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value 14b619ba-00f8-4772-b32c-bc35ccb1174d" level={8} cateid={139}>Tìm kiếm</div>
                            </button>
                        </div>
                    </form>
                    <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1, minHeight: "336px" }}>
                        <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>
                            <table className="w-table wbaseItem-value dbe0f04f-f73f-41cf-a94e-988260f1acdf" type={0} level={6} cateid={117}>
                                <tbody>
                                    <tr className="table-row">
                                        <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row e90e5935-90a6-40b5-a7d4-76d681ac32d2" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value 2485e51c-ff7e-44a9-a5d4-466e898c9988" level={8} cateid={139}>STT</div>
                                            </div>
                                        </td>
                                        <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row e064df7b-f2e0-49ef-a2fe-c4bc517fd403" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value c773fe31-0b32-4424-afa2-d9e81b4b309f" level={8} cateid={139}>Mã Chứng từ</div>
                                            </div>
                                        </td>
                                        <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Tên Chứng từ</div>
                                            </div>
                                        </td>
                                        <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Nhóm chứng từ</div>
                                            </div>
                                        </td>
                                        <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Loại Chứng từ</div>
                                            </div>
                                        </td>

                                        <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                            <div className="w-frame wbaseItem-value w-row d7bfebbd-090e-43bc-a49c-d11c6432a6c7" level={7} cateid={140}>
                                                {/* <div className="w-text wbaseItem-value f491a7b4-eafa-4610-bee4-d82b5c97ff3e" level={8} cateid={139}>Hành động</div>
                                <div className="w-svg wbaseItem-value 7b48f46e-a820-427e-9bd2-e8cadc861ff3" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.48 2.2162C12.4217 2.1486 12.3484 2.09415 12.2654 2.05677C12.1824 2.01939 12.0918 2 12 2C11.9082 2 11.8176 2.01939 11.7346 2.05677C11.6516 2.09415 11.5783 2.1486 11.52 2.2162L6.12 9.08426C6.05314 9.16929 6.01243 9.2704 6.00242 9.37626C5.99242 9.48212 6.01351 9.58855 6.06334 9.68362C6.11318 9.77869 6.18978 9.85864 6.28456 9.91452C6.37935 9.9704 6.48857 10 6.6 10H17.4C17.5114 10 17.6207 9.9704 17.7154 9.91452C17.8102 9.85864 17.8868 9.77869 17.9367 9.68362C17.9865 9.58855 18.0076 9.48212 17.9976 9.37626C17.9876 9.2704 17.9469 9.16929 17.88 9.08426L12.48 2.2162Z" fill="#28282999" />
                                        <path d="M11.52 21.7838C11.5783 21.8514 11.6516 21.9059 11.7346 21.9432C11.8176 21.9806 11.9082 22 12 22C12.0918 22 12.1824 21.9806 12.2654 21.9432C12.3484 21.9059 12.4217 21.8514 12.48 21.7838L17.88 14.9157C17.9469 14.8307 17.9876 14.7296 17.9976 14.6237C18.0076 14.5179 17.9865 14.4114 17.9367 14.3164C17.8868 14.2213 17.8102 14.1414 17.7154 14.0855C17.6207 14.0296 17.5114 14 17.4 14H6.6C6.48857 14 6.37935 14.0296 6.28456 14.0855C6.18978 14.1414 6.11318 14.2213 6.06334 14.3164C6.01351 14.4114 5.99242 14.5179 6.00242 14.6237C6.01243 14.7296 6.05314 14.8307 6.12 14.9157L11.52 21.7838Z" fill="#28282999" />
                                    </svg>
                                </div> */}
                                                <label className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                    <input type="checkbox" name="Pick" onChange={OnchangeCheckedAll} checked={data.every(item => item.check)} />
                                                    <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        data.map((item, index) => {
                                            return (
                                                <tr key={item.losDocumentDetail?.id} className="table-row">
                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row acbebf44-6c58-482d-86c8-31f3d1649f04" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value 51d88187-e142-427e-9963-328e66e429f6" level={8} cateid={139}>{pageDetails.size * (pageDetails.current - 1) + index + 1}</div>
                                                        </div>
                                                    </td>
                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.losDocumentDetail?.documentCode}</div>
                                                        </div>
                                                    </td>
                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row fbf833df-8732-40b3-8c84-6647ac56d57a" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value fd4011f7-127d-4174-8747-026e1bafb83d" level={8} cateid={139} >{item.losDocumentDetail?.documentName}</div>
                                                        </div>
                                                    </td>
                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.losDocumentDetail?.documentGroupName}</div>
                                                        </div>
                                                    </td>
                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                            <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.losDocumentType?.documentTypeName}</div>
                                                        </div>
                                                    </td>

                                                    <td className="table-cell" rowSpan={1} colSpan={1}>
                                                        <div className="w-frame wbaseItem-value w-row 7bc608f0-fb0c-41d6-b607-4ea13b26e9f5" level={7} cateid={140}>
                                                            <label className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                                <input type="checkbox" onChange={() => OnchangeCheckedgroup(index)} checked={item.check} name="Pick" />
                                                                <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                            </label>
                                                        </div>
                                                    </td>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination
                        currentPage={pageDetails.current}
                        itemPerPage={pageDetails.size}
                        totalItem={total}
                        onChangePage={(page, size) => {
                            if (pageDetails.current !== page || pageDetails.size !== size) {
                                setPageDetails({ current: page, size: size });
                                _InitData(page, size);
                            }
                        }}
                    />
                </div >

                <div className='popup-footer row'>
                    <button onClick={onClosePop} className='close-popup'>Hủy</button>
                    <button
                        onClick={handleTransfer}
                        className='action-confirm'>Thêm</button>
                </div>
            </div >
        </div >, document.body
    );
}



export function PopupSelecInsurance({ code, callback_action, onClose }) {
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({});
    const [data, setData] = useState([]);

    // const [listProduct, setListProduct] = useState([]);
    const [supplierList, setsupplierList] = useState([]);
    const [InsuranceTypeList, setInsuranceTypeList] = useState([]);
    const [paymentTypeList, setpaymentTypeList] = useState([]);
    const [productList, setproductList] = useState([]);
    const [total, setTotal] = useState([]);
    const [query, setQuery] = useState("");
    const [pageDetails, setPageDetails] = useState({
        current: 1,
        size: 10
    });
    const _InitData = async (page, pageSize) => {

        const insuranceCode = new URLSearchParams(query).get('insuranceCode');
        const insuranceName = new URLSearchParams(query).get('insuranceName');
        const insuranceSupplier = new URLSearchParams(query).get('insuranceSupplier');
        const insuranceType = new URLSearchParams(query).get('insuranceType');
        const paymentType = new URLSearchParams(query).get('paymentType');
        const productCode = new URLSearchParams(query).get('productCode');
        var obj = { "chargedType": "", "insuranceCode": insuranceCode ?? "", "insuranceName": insuranceName ?? "", "insuranceSupplier": insuranceSupplier ?? "", "insuranceType": insuranceType ?? "", "paymentType": paymentType ?? "", productCode: productCode ?? "", };

        const response = await getListInsurance(page, pageSize, obj);
        if (response != null) {
            setData(response.pageData.map((item) =>
                code.some((a) => a.id === item.losInsuranceDetail?.id) ? { ...item, check: true } : item
            ));
            setTotal(response.total)
        }
    }

    const getAllsupplier = async () => {
        const response = await getallInsurancesuplier(1, 100,);
        if (response != null) {
            setsupplierList(response.pageData);
        }
    }

    const getAllinsuracetype = async () => {
        const response = await getallInsurancetype(1, 100,);
        if (response != null) {
            setInsuranceTypeList(response.pageData);
        }
    }

    const getAllpaymenttype = async () => {
        const response = await getallInsurancepaymenttype(1, 100,);
        if (response != null) {
            setpaymentTypeList(response.pageData);
        }
    }
    const getAllproduct = async () => {
        const response = await getbproductList(1, 100,);
        if (response != null) {
            setproductList(response.content);
        }
    }

    const OnchangeCheckedgroup = (index) => {
        setData((data) =>
            data.map((item) =>
                item.losInsuranceDetail?.id === data[index].losInsuranceDetail?.id ? { ...item, check: !data[index].check } : item
            ));
    }
    const OnchangeCheckedAll = (ev) => {
        setData((data) =>
            data.map((item) => { return { ...item, check: ev.target.checked } }
            ));
    }
    const handleTransfer = async () => {
        const lst = data.filter((a) => a.check === true);
        callback_action(lst);
    }
    const onClosePop = async () => {
        onClose();
    }

    const onSubmitFormPop = (event) => {
        var query = "";
        if (event.insuranceCode) {
            if (query !== "") {
                query += '&insuranceCode=' + event.insuranceCode
            } else {
                query += '?insuranceCode=' + event.insuranceCode
            }
        }
        if (event.insuranceName) {
            if (query !== "") {
                query += '&insuranceName=' + event.insuranceName
            } else {
                query += '?insuranceName=' + event.insuranceName
            }
        }

        if (event.insuranceSupplier) {
            if (query !== "") {
                query += '&insuranceSupplier=' + event.insuranceSupplier
            } else {
                query += '?insuranceSupplier=' + event.insuranceSupplier
            }
        }
        if (event.insuranceType) {
            if (query !== "") {
                query += '&insuranceType=' + event.insuranceType
            } else {
                query += '?insuranceType=' + event.insuranceType
            }
        }
        if (event.paymentType) {
            if (query !== "") {
                query += '&paymentType=' + event.paymentType
            } else {
                query += '?paymentType=' + event.paymentType
            }
        }
        if (event.productCode) {
            if (query !== "") {
                query += '&productCode=' + event.productCode
            } else {
                query += '?productCode=' + event.productCode
            }
        }
        setQuery(query)
        _InitData(pageDetails.current, pageDetails.size);
    }
    const ressetForm = () => {
        setValue('insuranceCode', null);
        setValue('insuranceName', null);
        setValue('insuranceSupplier', null);
        setValue('insuranceType', null);
        setValue('paymentType', null);
        setValue('productCode', null);
    }
    useEffect(() => {
        getAllproduct()
        getAllpaymenttype()
        getAllsupplier()
        getAllinsuracetype()
        _InitData(pageDetails.current, pageDetails.size);
    }, [])

    return ReactDOM.createPortal(
        <div className='popup-overlay'>
            <div className='popup-container w1500'>
                <div className='popup-header row'>
                    <div className='popup-name space'>Thêm bảo hiểm vào chương trình vay</div>
                    <div className='close-popup'>
                        {/* <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4144 12.0002L20.4144 5.00015L19.0002 3.58594L12.0002 10.5859L5.00015 3.58594L3.58594 5.00015L10.5859 12.0002L3.58594 19.0002L5.00015 20.4144L12.0002 13.4144L19.0002 20.4144L20.4144 19.0002L13.4144 12.0002Z" fill="#28282999" style={{ mixBlendMode: 'multiply' }} />
                        </svg> */}
                    </div>
                </div>
                <div className='popup-body w-frame wbaseItem-value w-col' scroll="true">
                    <form onSubmit={handleSubmit(onSubmitFormPop)} autoComplete='off' className="w-frame w-form wbaseItem-value w-col e3d1eec9-af12-4f81-98de-7b1013b2c45a" level={5} cateid={128}>
                        <div className="w-frame wbaseItem-value w-row b2ec1ec5-af32-4c54-923e-1bdda7a0008e" wrap="wrap" level={6} cateid={140}>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 2429e32c-b23c-4fb4-86dd-5e2102fddad3" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value 4ee89f5f-68f0-468e-843e-09d9a96bfa8b" level={8} cateid={139}>Mã Bảo hiểm</div>
                                <div className="w-textformfield wbaseItem-value w-row dc1a2708-e1c6-4d7e-b90e-d2a8625a0bb5" level={8} cateid={85} placeholder="Nhập mã gói bảo hiểm">
                                    <div className="wbaseItem-value 6769e680-9a6d-4aa6-8057-e127a10c8cfd" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input {...register('insuranceCode')} name="insuranceCode" placeholder="Nhập mã gói bảo hiểm" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 6156f408-3027-4da4-8839-a18ba6bc5467" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value e5b046d1-dcc2-4846-8cec-196e33e62f8c" level={8} cateid={139}>Tên Bảo hiểm</div>
                                <div className="w-textformfield wbaseItem-value w-row 71eadcce-cb12-4862-b797-a38ea6beb51d" level={8} cateid={85} placeholder="Nhập tên gói bảo hiểm">
                                    <div className="wbaseItem-value 8a2cb047-aaef-4cf4-aa60-7e239596bd52" level={9} cateid={86}>
                                        <div className="textfield">
                                            <input  {...register('insuranceName')} name="insuranceName" placeholder="Nhập tên gói bảo hiểm" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 77769d60-55f3-4066-b5aa-eae222a0b63d" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value 912e095d-5aa5-4cd7-b16b-6f244f51aac0" level={8} cateid={139}>Nhà cung cấp</div>
                                {/* <Select2
                                style={{ order: 2 }}
                                isFirst={true}
                                {...register('insuranceSupplier')}
                                nameFirst={'Chọn nhà cung cấp'}
                                data={supplierList.map((item, _) => { return { id: item.insuranceSupplierCode, name: item.insuranceSupplierName } })}
                                name='insuranceSupplier'
                            /> */}
                                <Controller
                                    name="insuranceSupplier"
                                    control={control}
                                    {...register("insuranceSupplier")}
                                    style={{ order: 2 }}
                                    render={({ field }) => (
                                        <Select2 {...field} isFirst={true} nameFirst={'Chọn nhà cung cấp'}
                                            data={supplierList.map((item, _) => { return { id: item.insuranceSupplierCode, name: item.insuranceSupplierName } })}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 77769d60-55f3-4066-b5aa-eae222a0b63d" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value 912e095d-5aa5-4cd7-b16b-6f244f51aac0" level={8} cateid={139}>Loại bảo hiểm</div>
                                <Controller
                                    name="insuranceType"
                                    control={control}
                                    {...register("insuranceType")}
                                    style={{ order: 2 }}
                                    render={({ field }) => (
                                        <Select2
                                            {...field}
                                            isFirst={true}
                                            nameFirst={'Chọn Loại bảo hiểm'}
                                            data={InsuranceTypeList.map((item, _) => { return { id: item.insuranceTypeCode, name: item.insuranceTypeName } })}
                                        />
                                    )}
                                />
                            </div>

                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 77769d60-55f3-4066-b5aa-eae222a0b63d" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value 912e095d-5aa5-4cd7-b16b-6f244f51aac0" level={8} cateid={139}>Hình thức thanh toán</div>

                                <Controller
                                    name="paymentType"
                                    control={control}
                                    {...register("paymentType")}
                                    style={{ order: 2 }}
                                    render={({ field }) => (
                                        <Select2 {...field}
                                            data={paymentTypeList.map((item, _) => { return { id: item.losInsurancePaymentTypeCode, name: item.losInsurancePaymentTypeName } })}
                                            options={{ placeholder: 'Chọn hình thức thanh toán' }}


                                        />
                                    )}
                                />
                            </div>
                            <div className="w-frame wbaseItem-value w-col col- col6-xxl col6-xl col12-lg col12-md col24 col24-sm 77769d60-55f3-4066-b5aa-eae222a0b63d" level={7} cateid={140}>
                                <div className="w-text wbaseItem-value 912e095d-5aa5-4cd7-b16b-6f244f51aac0" level={8} cateid={139}>Sản phẩm áp dụng</div>

                                <Controller
                                    name="productCode"
                                    control={control}
                                    {...register("productCode")}
                                    style={{ order: 2 }}
                                    render={({ field }) => (
                                        <Select2 {...field}
                                            data={productList.map((item, _) => { return { id: item.code, name: item.name } })}
                                            options={{ placeholder: 'Chọn sản phẩm áp dụng' }}



                                        />
                                    )}
                                />
                            </div>

                        </div>
                        <div className="w-frame wbaseItem-value w-row 60eb7691-2d67-402e-a13f-6aba8d5dfe3c" level={6} cateid={140}>
                            <button type="button" onClick={() => ressetForm()} className="w-button wbaseItem-value w-row 8c46835a-80fa-488b-979e-419f7ac8efec" level={7} cateid={29}>
                                <div className="w-svg wbaseItem-value cef48042-9809-4985-bddf-aa647f81640e" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.0313 2.00835C21.5288 2.07581 21.8774 2.53382 21.81 3.03135L21.0827 8.39498C21.0153 8.89173 20.5586 9.2402 20.0617 9.17395L14.6072 8.44668C14.1095 8.38032 13.7598 7.92309 13.8262 7.42541C13.8926 6.92774 14.3498 6.57809 14.8475 6.64445L18.3484 7.11124C16.8702 5.25663 14.5726 4.09064 12 4.09064C7.50208 4.09064 3.81818 7.77454 3.81818 12.2725C3.81818 12.7745 3.41117 13.1816 2.90909 13.1816C2.40701 13.1816 2 12.7745 2 12.2725C2 6.77038 6.49792 2.27246 12 2.27246C15.0381 2.27246 17.7813 3.61127 19.6033 5.77412L20.0083 2.78705C20.0757 2.28953 20.5338 1.94089 21.0313 2.00835Z" fill="#28282999" />
                                        <path d="M22 12.2733C22 11.7713 21.5929 11.3643 21.0909 11.3643C20.5888 11.3643 20.1818 11.7713 20.1818 12.2733C20.1818 16.7713 16.4979 20.4552 12 20.4552C9.42714 20.4552 7.12925 19.2889 5.6511 17.434L9.15251 17.9009C9.65018 17.9672 10.1074 17.6176 10.1738 17.1199C10.2401 16.6222 9.89048 16.165 9.39281 16.0986L3.93826 15.3714C3.44137 15.3051 2.98462 15.6536 2.91726 16.1503L2.18999 21.514C2.12253 22.0115 2.47117 22.4695 2.96869 22.537C3.46621 22.6044 3.92423 22.2558 3.99169 21.7583L4.39665 18.7716C6.21865 20.9345 8.96182 22.2733 12 22.2733C17.502 22.2733 22 17.7754 22 12.2733Z" fill="#28282999" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value fb0f0aba-9b42-4b9d-ba05-a185d998a8f7" level={8} cateid={139}>Xóa bộ lọc</div>
                            </button>
                            <button type="button" onClick={handleSubmit(onSubmitFormPop)} className="w-button wbaseItem-value w-row 38b377c7-da56-44ff-93b4-d77b917d096f" level={7} cateid={29}>
                                <div className="w-svg wbaseItem-value 8e116fb4-b8c7-4c9d-ad7a-344a0edece09" level={8} cateid={115}>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#FFFFFFFF" />
                                    </svg>
                                </div>
                                <div className="w-text wbaseItem-value 14b619ba-00f8-4772-b32c-bc35ccb1174d" level={8} cateid={139}>Tìm kiếm</div>
                            </button>
                        </div>
                    </form>
                    <div className="w-frame wbaseItem-value w-col e8de9f90-0118-414b-bc69-da273a578779" level={5} cateid={140}>
                        <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                            <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>
                                <table className="w-table wbaseItem-value dbe0f04f-f73f-41cf-a94e-988260f1acdf" type={0} level={6} cateid={117}>
                                    <tbody>
                                        <tr className="table-row">
                                            <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row e90e5935-90a6-40b5-a7d4-76d681ac32d2" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value 2485e51c-ff7e-44a9-a5d4-466e898c9988" level={8} cateid={139}>STT</div>
                                                </div>
                                            </td>
                                            <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row e064df7b-f2e0-49ef-a2fe-c4bc517fd403" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value c773fe31-0b32-4424-afa2-d9e81b4b309f" level={8} cateid={139}>Mã gói bảo hiểm</div>
                                                </div>
                                            </td>
                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Tên gói bảo hiểm</div>
                                                </div>
                                            </td>
                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Nhà cung cấp</div>
                                                </div>
                                            </td>
                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Loại bảo hiểm</div>
                                                </div>
                                            </td>
                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Kiểu tính phí</div>
                                                </div>
                                            </td>
                                            <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                    <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Trạng thái</div>
                                                </div>
                                            </td>
                                            <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                <div className="w-frame wbaseItem-value w-row d7bfebbd-090e-43bc-a49c-d11c6432a6c7" level={7} cateid={140}>
                                                    <label className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                        <input onChange={OnchangeCheckedAll} type="checkbox" name="Pick" checked={data.every(item => item.check)} />
                                                        <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        {
                                            data.map((item, index) => {
                                                return (
                                                    <tr key={item.id} className="table-row">
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row acbebf44-6c58-482d-86c8-31f3d1649f04" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 51d88187-e142-427e-9963-328e66e429f6" level={8} cateid={139}>{pageDetails.size * (pageDetails.current - 1) + index + 1}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.losInsuranceDetail?.insuranceCode}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row fbf833df-8732-40b3-8c84-6647ac56d57a" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value fd4011f7-127d-4174-8747-026e1bafb83d" level={8} cateid={139} >{item.losInsuranceDetail?.insuranceName}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.supplier?.insuranceSupplierName}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.type?.insuranceTypeName}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.chargedType?.chargedTypeName}</div>
                                                            </div>
                                                        </td>
                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                {/* <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.losInsuranceDetail?.status}</div> */}
                                                                <button type="button" className="w-button wbaseItem-value w-row" level={2} cateid={29} id="eadd2c5d-e811-4f3e-9934-2dacf0e9402a">
                                                                    <div className="w-text wbaseItem-value" level={3} cateid={139} id="38662a33-df93-442d-830c-0d71ce5e816f">{item.losInsuranceDetail?.status === 1 ? 'Hoạt động' : 'Không hoạt động'}</div>
                                                                </button>
                                                            </div>
                                                        </td>

                                                        <td className="table-cell" rowSpan={1} colSpan={1}>
                                                            <div className="w-frame wbaseItem-value w-row 7bc608f0-fb0c-41d6-b607-4ea13b26e9f5" level={7} cateid={140}>
                                                                <label className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                                    <input type="checkbox" onChange={() => OnchangeCheckedgroup(index)} checked={item.check} name={`Pick-${index}`} />
                                                                    <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <Pagination
                            currentPage={pageDetails.current}
                            itemPerPage={pageDetails.size}
                            totalItem={total}
                            onChangePage={(page, size) => {
                                if (pageDetails.current !== page || pageDetails.size !== size) {
                                    setPageDetails({ current: page, size: size });
                                    _InitData(page, size);
                                }
                            }}
                        />
                    </div>
                </div >

                <div className='popup-footer row'>
                    <button onClick={onClosePop} className='close-popup'>Hủy</button>
                    <button
                        onClick={handleTransfer}
                        className='action-confirm'>Thêm</button>
                </div>
            </div >
        </div >, document.body
    );
}

export function PopupSelecBrand({ listBrandAll = [], callback_action, onClose }) {
    const [data, setData] = useState([]);
    const [selectId, setSelectId] = useState();
    const [tableList, setTableList] = useState([]);
    const [listSearch, setlistSearch] = useState([]);
    const [status, setStatus] = useState(null);
    const [parentId, setparentId] = useState();


    const handleTransfer = async () => {
        const lst = data.filter((a) => a.check);
        callback_action(lst);
    }

    const onClosePop = async () => {
        onClose();
    }

    const onchangeSearch = async (event) => {
        // if (event.target.value.trim() === "") {
        //     setlistSearch([]);
        // } else {
            
        // }
        const res = await NhanhieuDA.getListbranch(event.target.value ?? "");
            if (res != null) {
                var lst = res?.map((a, _) => { return { ...a, parentId: a.parentId ? a.parentId : 0 } });
                setlistSearch(lst)
                if (res?.some((a) => !a.parentId)) {
                    setparentId(0)
                } else {
                    res?.sort((a, b) => (a.parentId > b.parentId) ? 1 : -1);
                    setparentId(res[0].parentId)
                }
            }
    }

    const onSelectTile = (ev, id) => {
        // document.body.querySelector(`.popup-body .wbaseItem-value[class*="46ef9d10-6026-4440-992e-0f91b477b350"] input`).value = "";
        // setlistSearch([]);
        // debugger
        setSelectId(parseInt(id));
        // let idList = [...ev.target.closest(".w-tree").querySelectorAll(".tile-item")].map(el => parseInt(el.getAttribute("data-id")));
        var lst = data.filter(e => e.parentId == id);
        setTableList([...lst]);
    }

    const renderTree = (parentID, level = 0) => {
        let tileList = [];
        for (let tileItem of (listSearch.length > 0 ? listSearch : data).filter(e => e.parentId === parentID)) {
            tileList.push(<div key={tileItem.id} className="w-tree wbaseItem-value w-row 92f43d0e-9f64-4b82-a967-12e7d64a6bba" level={7} cateid={241} style={{ marginLeft: `${16 * level}px`, width: `calc(100% - ${16 * level}px)` }} tree-height="hug">
                {treeTile(tileItem)}
                {renderTree(tileItem.id, level + 1)}
            </div>)
        }
        return tileList;
    }

    const treeTile = (item) => {
        item.toggleShow ??= false;
        return (<div onClick={(ev) => { onSelectTile(ev, item.id); }} data-id={item.id} className={"tile-item " + (selectId === item.id ? 'active' : '')} >
            <button className="w-button w-row btn-tree-action" onClick={(e) => {
                item.toggleShow = !item.toggleShow;
                setData([...data]);
            }}>
                <FontAwesomeIcon icon={item.toggleShow ? faCaretDown : faCaretRight} />
            </button>
            <div className="children-value" >
                <div className="w-text wbaseItem-value 7b3f76d3-5d9e-4624-8d59-df59fbfa8a26" level={8} cateid={139}>
                    {item.name ?? "_"}
                </div>
            </div>
        </div>);
    }

    useEffect(() => {
        var lst = listBrandAll?.map((a, _) => { return { ...a, parentId: a.parentId ? a.parentId : 0 } });
        setData([...lst]);
        if (listBrandAll?.some((a) => !a.parentId)) {
            setparentId(0)
        } else {
            listBrandAll?.sort((a, b) => (a.parentId > b.parentId) ? 1 : -1);
            setparentId(listBrandAll[0].parentId)
        }
    }, [])

    return ReactDOM.createPortal(
        <div className='popup-overlay'>
            <div className='popup-container w1500'>
                <div className='popup-header row'>
                    <div className='popup-name space'>Thêm nhãn hiệu vào chương trình vay</div>
                    <div className='close-popup'>
                    </div>
                </div>
                <div className='popup-body w-frame wbaseItem-value w-col' scroll="true">
                    <div class="w-frame wbaseItem-value w-row 441af62a-e046-442c-be35-3cc9ae38d2af" level="4" cateid="140" >
                        <div class="w-frame wbaseItem-value w-col 7858631c-4778-4947-9d12-3a204876073e" level="5" cateid="140" >
                            <div class="w-frame wbaseItem-value w-row 80a33036-1770-4804-bc7e-8b5f9f8dc699" level="6" cateid="140" >
                                <div class="w-textformfield wbaseItem-value w-row 46ef9d10-6026-4440-992e-0f91b477b350" level="7" cateid="85" >
                                    <div class="w-svg wbaseItem-value 5fb7e9dc-23a0-430d-8f4a-e076b828e94c" level="8" cateid="115" >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                            <g clip-path="url(#clip0_502_135406)">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0432 16.3052C13.6693 17.4041 11.9267 18.0612 10.0306 18.0612C5.59543 18.0612 2 14.4658 2 10.0306C2 5.59543 5.59543 2 10.0306 2C14.4658 2 18.0612 5.59543 18.0612 10.0306C18.0612 11.9268 17.4041 13.6694 16.3051 15.0433L21.9999 20.7381L20.738 22L15.0432 16.3052ZM3.78458 10.0306C3.78458 6.58103 6.58103 3.78458 10.0306 3.78458C13.4802 3.78458 16.2767 6.58103 16.2767 10.0306C16.2767 11.7129 15.6116 13.2399 14.53 14.3629L14.3629 14.53C13.2399 15.6116 11.7129 16.2767 10.0306 16.2767C6.58103 16.2767 3.78458 13.4802 3.78458 10.0306Z" fill="#28282999" fill-opacity="0.8"></path>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_502_135406">
                                                    <rect width="24" height="24" rx="4" fill="#28282999" ></rect>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div class="wbaseItem-value 0dd4aff6-641a-41fd-8a88-8df3f3904957" level="8" cateid="86" > <div class="textfield">
                                        <input placeholder="Tìm kiếm chức năng" onChange={onchangeSearch} />
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-frame wbaseItem-value w-col 9ddc8e18-e84a-4816-8d9c-d503d4ffa963" scroll="true" level="6" cateid="140">
                                {renderTree(parentId)}
                            </div>
                        </div>
                        <div className="w-frame wbaseItem-value w-col b94dd398-5cb0-4e26-8bb1-58e97f17132b" level="7" cateid="140" >
                            <div className="w-frame wbaseItem-value w-row d32aac52-a0d0-4106-aa12-7b714ee302c8" level="8" cateid="140" >
                                <div className="w-text wbaseItem-value e7f6592a-4ce6-41f3-81b3-300d0fb07879" level="9" cateid="139">
                                    Danh sách nhãn hiệu
                                </div>
                            </div>
                            <div className="w-frame wbaseItem-value w-row 0caa55f2-fb82-47f0-9361-b88707b6ddf3" level="8" cateid="140" >
                                {[null, 1, 0].map((num, index) =>
                                    <div key={"radio-" + index} className="w-frame wbaseItem-value w-row 878901d6-622d-4b0c-885f-f839600300d0" level="9" cateid="140">
                                        <label className="w-radio-btn wbaseItem-value bda3a5f8-0f44-4287-9ac0-a07a3697468b" level="10" cateid="90" >
                                            <input type="radio" onChange={() => setStatus(num)} name='check-filter' value={num} checked={num === status} /><span className="checkmark" ></span></label>
                                        <div className="w-text wbaseItem-value c1c5deef-c4d7-43cc-a2f9-20036910ac9a" level="10" cateid="139" >{num === 1 ? "Active" : num === 0 ? "Deactive" : "Tất cả"}</div>
                                    </div>)}
                            </div>
                            <div className='w-frame w-col wbaseItem-value' scroll="true" style={{ width: "100%", height: "100%", flex: 1 }}>
                                <div className='w-frame w-row wbaseItem-value' scroll="true" style={{ width: "100%", minHeight: "fit-content" }}>
                                    <table className="w-table wbaseItem-value dbe0f04f-f73f-41cf-a94e-988260f1acdf" type={0} level={6} cateid={117}>
                                        <tbody>
                                            <tr className="table-row">
                                                <td id="1x1" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row e90e5935-90a6-40b5-a7d4-76d681ac32d2" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value 2485e51c-ff7e-44a9-a5d4-466e898c9988" level={8} cateid={139}>STT</div>
                                                    </div>
                                                </td>
                                                <td id="1x2" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row e064df7b-f2e0-49ef-a2fe-c4bc517fd403" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value c773fe31-0b32-4424-afa2-d9e81b4b309f" level={8} cateid={139}>Nhà sản xuất</div>
                                                    </div>
                                                </td>
                                                <td id="1x3" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Mô tả</div>
                                                    </div>
                                                </td>
                                                <td id="1x4" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row ff244354-9e97-4ddc-a5d2-16012986860f" level={7} cateid={140}>
                                                        <div className="w-text wbaseItem-value d7afc10e-9f06-492a-8824-9ae0b3e3fdd4" level={8} cateid={139}>Trạng thái</div>
                                                    </div>
                                                </td>
                                                <td id="1x5" className="table-cell" rowSpan={1} colSpan={1}>
                                                    <div className="w-frame wbaseItem-value w-row d7bfebbd-090e-43bc-a49c-d11c6432a6c7" level={7} cateid={140}>
                                                        <label className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                            <input onChange={(ev) => {
                                                                setTableList([...tableList.map(el => {
                                                                    if ((status !== null && el.status === status) || status === null) {
                                                                        el.check = ev.target.checked;
                                                                    }
                                                                    return el;
                                                                })]);
                                                            }} type="checkbox" checked={(status !== null ? tableList.filter(e => e.status === status) : tableList).every(item => item.check)} />
                                                            <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                (status !== null ? tableList.filter(e => e.status === status) : tableList).map((item, index) => {
                                                    return (
                                                        <tr key={item.id} className="table-row">
                                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row acbebf44-6c58-482d-86c8-31f3d1649f04" level={7} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 51d88187-e142-427e-9963-328e66e429f6" level={8} cateid={139}>{index + 1}</div>
                                                                </div>
                                                            </td>
                                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139}>{item.name}</div>
                                                                </div>
                                                            </td>
                                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row fbf833df-8732-40b3-8c84-6647ac56d57a" level={7} cateid={140}>
                                                                    <div className="w-text wbaseItem-value fd4011f7-127d-4174-8747-026e1bafb83d" level={8} cateid={139} >{item.description}</div>
                                                                </div>
                                                            </td>
                                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 58633351-1c35-4aba-a82b-14729d759141" level={7} cateid={140}>
                                                                    <div className="w-text wbaseItem-value 714d7466-d63f-4270-bc4b-f1d481e101a8" level={8} cateid={139} style={{ textAlign: 'center' }}>{item.status === 1 ? "Active" : "Deactive"}</div>
                                                                </div>
                                                            </td>
                                                            <td className="table-cell" rowSpan={1} colSpan={1}>
                                                                <div className="w-frame wbaseItem-value w-row 7bc608f0-fb0c-41d6-b607-4ea13b26e9f5" level={7} cateid={140}>
                                                                    <label className="w-check-box wbaseItem-value 28fdaadd-4a25-4b01-804a-7ce373f9e0a2" level={11} cateid={79}>
                                                                        <input type="checkbox" onChange={(ev) => {
                                                                            item.check = ev.target.checked;
                                                                            setTableList([...tableList]);
                                                                        }} checked={item.check} />
                                                                        <svg width="20" height="20"><path d="M5.6 9.6 L9.0 13.0 L15.0 6.0" fill="none" strokeLinecap="round" stroke="#FFFFFFFF"></path></svg>
                                                                    </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

                <div className='popup-footer row'>
                    <button onClick={onClosePop} className='close-popup'>Hủy</button>
                    <button
                        onClick={handleTransfer}
                        className='action-confirm'>Thêm</button>
                </div>
            </div >
        </div >, document.body
    );
}
